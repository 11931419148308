var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"limits__container",style:([!_vm.groupp && { width: '700px', margin: '0 auto' }])},[_c('Card',{attrs:{"title":"Список лимитов"},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.$can('LM_C') && _vm.canAddLimits)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ListActionBtn',_vm._g(_vm._b({attrs:{"label":"Добавить","icon":"mdi-plus-box-outline"}},'ListActionBtn',attrs,false),on))]}}],null,false,702889101)},[_c('v-list',_vm._l((_vm.limitTypeNameList),function(ref){
var key = ref[0];
var value = ref[1];
return _c('v-list-item',{key:key,on:{"click":function($event){return _vm.openModalCreate(key)}}},[_c('v-list-item-title',[_vm._v(_vm._s(value))])],1)}),1)],1):_vm._e()]},proxy:true}])},[(
        _vm.groupp && _vm.activeGroupOrgLimits.length && !_vm.$wait('fetchListHandler')
      )?_c('NoDataAlert',[_vm._v(" Невозможно создать лимит для группы организаций, в которых состоят организации с действующими лимитами. "),_c('br'),_vm._v(" Организации с лимитами, состоящие в группе: "),_c('LinkButtonList',{staticClass:"my-2",attrs:{"list":_vm.orgsAsButton}})],1):_vm._e(),(
        !_vm.list.length &&
        !_vm.activeGroupOrgLimits.length &&
        !_vm.forbiddenCreateLimit &&
        !_vm.$wait('fetchListHandler')
      )?_c('NoDataAlert',[_vm._v(" Для "+_vm._s(_vm.groupp ? 'группы' : 'организации')+" нет созданных лимитов ")]):_vm._e(),(_vm.forbiddenCreateLimit)?_c('NoDataAlert',[_vm._v(" Невозможно создать лимит для организации, которая состоит в группах организаций, у которых установлены лимиты. "),_c('br'),_vm._v(" Группы организаций с лимитами, в которых состоит текущая организация: "),_c('LinkButtonList',{staticClass:"my-2",attrs:{"list":_vm.orgGroupsAsButton}})],1):_vm._e(),_c('AsyncWrapper',{class:{ 'limit-card__list': _vm.list.length },attrs:{"handler":_vm.fetchListHandler}},[(_vm.list.length)?_c('div',_vm._l((_vm.list),function(el,index){return _c('LimitCard',{key:el.id,class:index !== _vm.list.length - 1 && 'mb-4',attrs:{"value":el,"groupp":_vm.groupp}})}),1):_c('div')])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }