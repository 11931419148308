import authApi from '@/api/services/auth';
import structApi from '@/api/services/structures';
import medrecApi from '@/api/services/medrec';

import crudListFactory from '@/utils/vuex/crudListFactory';
import { createModule } from '@/utils/vuex/createModule';
import { shortName } from '@/utils/convert';
import { filters } from '../entity';
import { changePages } from '@/utils/vuex/changePages';
import { can } from '@/plugins/can';

const state = {
  employees: {},
  accounts: {},
};

const getters = {
  employees: state => state.employees,
  accounts: state => state.accounts,
};

const mutations = {
  employees: (state, value) => {
    const list = value.reduce((agg, employee) => {
      agg[employee.id] = employee.name;
      return agg;
    }, {});
    state.employees = { ...state.employees, ...list };
  },
  accounts: (state, value) => {
    const list = value.reduce((agg, account) => {
      agg[account.id] = shortName(account.profile);
      return agg;
    }, {});
    state.accounts = { ...state.accounts, ...list };
  },
};

const actions = {
  async fetchList({ commit, getters }, query) {
    await changePages(getters, commit, query);

    try {
      if (getters.listSearch?.length > 100)
        throw Error('Не более 100 символов в поле поиска');

      commit('listFetching');

      const params = { ...getters.listQuery };
      // Удаляем текстовую запись из запроса.
      // Передаются только IDs найденных работников
      delete params.employeeSearch;

      const response = await medrecApi.documentList(params);

      // fetch employee previews to show employee names in list (including
      // employees who approved the document)
      let employeeIds = [];
      let accountIds = [];

      response.items.forEach(item => {
        if (!getters.employees[item.employeeId])
          employeeIds.push(item.employeeId);

        if (item.resolution && !getters.accounts[item.resolution.approvedBy])
          accountIds.push(item.resolution.approvedBy);
      });

      employeeIds = [...new Set(employeeIds)];
      accountIds = [...new Set(accountIds)];

      const promises = [];

      promises.push(
        employeeIds.length
          ? structApi.getEmployeePreviewsBy(employeeIds)
          : Promise.resolve(),
      );

      promises.push(
        accountIds.length && can('AC_PRW')
          ? authApi.getAccountPreviewsBy(accountIds)
          : Promise.resolve(),
      );

      await Promise.all(promises).then(([employees = [], accounts = []]) => {
        commit('employees', employees);
        commit('accounts', accounts);
      });

      commit('listFetched', response);
    } catch (error) {
      commit('listErrorFetched', error);
      throw error;
    }
  },

  async setEmployeeSearch({ commit }, value) {
    commit('listQuery', { employeeSearch: value });
  },
};

export default createModule(
  crudListFactory({
    fetchMethod: medrecApi.documentList,
    createMethod: medrecApi.documentCreate,
    updateMethod: medrecApi.documentUpdate,
    deleteMethod: medrecApi.documentDelete,
    filters,
  }),
  { state, getters, mutations, actions },
);
