<script>
import Vue from 'vue';
import { VRow, VCol } from 'vuetify/lib';
import validationMixin from '@/utils/validation';
import { reduceFieldName } from '../utilities';

export default Vue.extend({
  mixins: [validationMixin],
  props: {
    fields: { type: Array, required: true },
    value: { type: Object, default: () => ({}) },
    noTimezone: Boolean,
    noBatch: Boolean,
  },

  validations() {
    const validations = this.filteredFields.reduce((validationsAcc, field) => {
      if (!field.validation) return validationsAcc;

      return reduceFieldName(field, (acc, name) => ({
        ...validationsAcc,
        ...acc,
        [name]: field.validation,
      }));
    }, {});

    return { value: validations };
  },

  computed: {
    filteredFields() {
      return this.fields.filter(field => (field.is ? field.is(this) : true));
    },
  },

  mounted() {
    const values = this.initializedValues(this.fields, this.value);
    this.$emit('input', values);
  },

  methods: {
    initializedValues(fields, value) {
      // NOTE: работает только с плоскими дефолтными значениями

      const result = fields.reduce(
        (agg, field) => {
          if (field.defaultValue === undefined) return agg;

          // Проверяем наличие дефолтных значений для поля
          const computedValue =
            typeof field.defaultValue === 'function'
              ? field.defaultValue(this)
              : field.defaultValue;

          // Поле может содержать несколько значений.
          // Например: date => dateStart, dateEnd
          const defaultValues = reduceFieldName(field, (acc, name) => ({
            ...acc,
            [name]: computedValue,
          }));

          // Если в компонент получены значения отличающиеся от дефолтных,
          // то используем их
          const mixWithIncomingValues = Object.entries(defaultValues).reduce(
            (agg, [key, defaultValue]) => {
              const outputValue =
                value[key] === undefined ? defaultValue : value[key];
              return { ...agg, [key]: outputValue };
            },
            {},
          );

          return {
            ...agg,
            ...mixWithIncomingValues,
          };
        },
        // На старте учитываем полученные значения
        { ...value },
      );

      return result;
    },

    resolveProps(props) {
      if (typeof props === 'function') return props(this.value, this);

      return props;
    },

    getValidationError(field) {
      return reduceFieldName(field, (acc, name) => [
        ...(acc || []),
        ...this.getValidationErrors('value.' + name),
      ]);
    },

    renderField(field) {
      const props = {
        value: Array.isArray(field.name) ? this.value : this.value[field.name],
        errorMessages: this.getValidationError(field),
        getValidationError: () => this.getValidationError(field),
        ...this.resolveProps(field.props),
      };

      const onInput = event => {
        const newValue = {
          ...this.value,
          ...reduceFieldName(field, (acc, name) => ({
            ...acc,
            [name]: event && event[name] !== undefined ? event[name] : event,
          })),
        };
        this.$emit('input', newValue);
        if (field.watch) this.$nextTick(_ => field.watch(this));
      };

      const componentParams = {
        props,
        on: { input: onInput },
      };

      const { cols = 6, slot, component } = field;

      return this.$createElement(VCol, { props: { cols } }, [
        this.$scopedSlots[slot]
          ? this.$scopedSlots[slot](componentParams.props)
          : this.$createElement(component, componentParams),
      ]);
    },
  },

  render(h) {
    return h(VRow, {}, this.filteredFields.map(this.renderField));
  },
});
</script>
