// api
import structApi from '@/api/services/structures';
import medrecApi from '@/api/services/medrec';

import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

// babel wildcard needs explicit paths
import * as subModules from './submodules';

const getters = {
  status: state => {
    if (!state.singleItem.isEnabled) return 'Неактивен';
    // else if (state.bans.current) return 'Временно заблокирован';
    return 'Активен';
  },
};

const actions = {
  async uploadPhoto({ commit }, { id, photo }) {
    const response = await structApi.uploadPhoto(id, photo);
    commit('singleItem', response);
    return response;
  },

  async archivePhoto({ commit }, id) {
    const response = await structApi.employeeArchivePhoto(id);
    commit('singleItem', response);
    return response;
  },

  async changeStatus({ getters, dispatch }, isEnabled) {
    const id = getters.singleItem.id;
    const action = isEnabled ? 'employeeEnable' : 'employeeDisable';
    await structApi[action](id);
    dispatch('singleItemUpdate');
  },

  async changeGroups({ dispatch, getters }, { groupIds }) {
    const id = getters.singleItem.id;
    await structApi.employeeChangeGroups(id, groupIds);
    dispatch('singleItemUpdate');
  },

  async changeOrg({ dispatch }, { id, orgId }) {
    await structApi.employeeChangeOrg(id, { orgId });
    dispatch('singleItemUpdate');
  },

  // eslint-disable-next-line no-empty-pattern
  async getEmployeeFlagsIgnoreWorkRestBans({}, employeeId) {
    return structApi.getEmployeeFlagsIgnoreWorkRestBans(employeeId);
  },
  // eslint-disable-next-line no-empty-pattern
  async setEmployeeFlagsIgnoreWorkRestBans({}, { employeeId, value }) {
    return structApi.setEmployeeFlagsIgnoreWorkRestBans(employeeId, value);
  },

  async updateListItemPhone({ dispatch }, { id, phone }) {
    await structApi.updateEmployeePhone(id, phone);
    dispatch('singleItemUpdate', id);
  },

  async fetchEmployeeAppointment(_, params) {
    return medrecApi.documentReferralManual(params);
  },
};

export default createModule(
  crudSingleFactory({
    fetchMethod: structApi.employeeGet,
    updateMethod: structApi.employeeUpdate,
    deleteMethod: structApi.employeeDelete,
    linkedList: 'EMPLOYEE_LIST',
  }),
  ...Object.values(subModules),
  { getters, actions },
);
