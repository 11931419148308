var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModernModalLayout',{attrs:{"title":_vm.title,"close-label":"Отмена","size":"large"},scopedSlots:_vm._u([{key:"actions:append",fn:function(){return [_c('v-btn',{staticClass:"px-5",attrs:{"depressed":"","color":"primary","loading":_vm.$wait('submittingSurvey')},on:{"click":_vm.submit}},[_vm._v(" Сохранить ")])]},proxy:true}])},[_c('v-simple-table',{staticClass:"mb-3",attrs:{"dense":""}},[_c('thead',{staticClass:"font-weight-bold"},[_c('tr',[_c('th',{staticClass:"py-3"},[_vm._v("Параметры опроса")]),_c('th',{staticClass:"py-3"})])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"grey--text px-4 py-3",staticStyle:{"width":"60%"}},[_vm._v(" Продолжительность опроса (мин) ")]),_c('td',{staticClass:"px-4 py-3",staticStyle:{"width":"40%"}},[_c('MaskField',{staticStyle:{"max-width":"150px","margin":"auto !important"},attrs:{"mask":_vm.maskPosNumber,"disabled":_vm.$wait('submittingSurvey'),"outlined":"","dense":"","hide-details":"auto","error-messages":_vm.getValidationErrors(
                'data.surveySettings.$each.' + _vm.type + '.timeoutTimespan'
              )},on:{"blur":function($event){return _vm.validateField(
                'data.surveySettings.$each.' + _vm.type + '.timeoutTimespan'
              )}},model:{value:(_vm.data.surveySettings[_vm.type].timeoutTimespan),callback:function ($$v) {_vm.$set(_vm.data.surveySettings[_vm.type], "timeoutTimespan", $$v)},expression:"data.surveySettings[type].timeoutTimespan"}})],1)])])]),(_vm.data.questionsSettings)?_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',{staticClass:"font-weight-bold"},[_c('tr',[_c('th',{staticClass:"py-3"},[_vm._v("Вопросы")]),_c('th',{staticClass:"text-center py-3"},[_vm._v("Доступность (дни)")])])]),_c('tbody',_vm._l((_vm.surveyTypes.schemes[_vm.type].questions),function(val,key){return _c('tr',{key:key},[_c('td',{staticClass:"grey--text px-4 py-3",staticStyle:{"width":"60%"}},[_vm._v(_vm._s(val))]),_c('td',{staticClass:"px-4 py-3",staticStyle:{"width":"40%"}},[(_vm.data.questionsSettings[key])?_c('MaskField',{staticStyle:{"max-width":"150px","margin":"auto !important"},attrs:{"disabled":_vm.$wait('submittingSurvey') || key === 'illegal_drugs',"mask":_vm.maskPosNumber,"outlined":"","dense":"","hide-details":"auto","error-messages":_vm.getValidationErrors(
                'data.questionsSettings.' + key + '.timeoutTimespan'
              )},on:{"blur":function($event){return _vm.validateField(
                'data.questionsSettings.' + key + '.timeoutTimespan'
              )}},model:{value:(_vm.data.questionsSettings[key].timeoutTimespan),callback:function ($$v) {_vm.$set(_vm.data.questionsSettings[key], "timeoutTimespan", $$v)},expression:"data.questionsSettings[key].timeoutTimespan"}}):_vm._e()],1)])}),0)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }