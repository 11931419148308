import { createHTTP } from '../api';
import resourceServiceFactory from '@/utils/resourceServiceFactory';
import env from '@/plugins/env';

export const medrec = createHTTP(env.get('VUE_APP_MEDREC_API') + '/v3');

export default {
  ...resourceServiceFactory(medrec, 'document'),
  ...resourceServiceFactory(medrec, 'profile', ['put']),

  bindOrgsToProfile(id, data) {
    return medrec
      .put(`/profiles/${id}/organizations`, data)
      .then(res => res.data);
  },

  documentOutgoingList(params) {
    return medrec.get('/documents/outgoing', { params }).then(res => res.data);
  },

  documentOutgoingGet(id) {
    return medrec.get(`/documents/outgoing/${id}`).then(res => res.data);
  },

  documentCreate({ id, data }) {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value))
        value.map(item => formData.append(key + '[]', item));
      else formData.append(key, value);
    }

    return medrec
      .post(`/employees/${id}/documents`, formData)
      .then(res => res.data);
  },

  documentPatch({ id, data }) {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value))
        value.map(item => formData.append(key + '[]', item));
      else formData.append(key, value);
    }

    return medrec.patch(`/documents/${id}`, formData).then(res => res.data);
  },

  getDocumentTypes() {
    return medrec.get(`/documents/types`).then(res => res.data);
  },

  getOutgoingDocumentTypes() {
    return medrec.get(`/documents/outgoing/types`).then(res => res.data);
  },

  getInspectionOutgoingDocs(id) {
    return medrec.get(`/inspections/${id}/documents`).then(res => res.data);
  },

  documentApprove({ id, data }) {
    return medrec.post(`/documents/${id}/approve`, data).then(res => res.data);
  },

  documentDecline({ id, data }) {
    return medrec.post(`/documents/${id}/decline`, data).then(res => res.data);
  },

  documentReferralManual(params) {
    return medrec
      .get(`/documents/outgoing/referral/manual`, { params })
      .then(res => res.data);
  },

  getEmployeeDocuments(id, usage, banReason) {
    return medrec
      .get(`/employees/${id}/documents`, { params: { usage, banReason } })
      .then(res => res.data);
  },

  updateRiskGroup(data) {
    return medrec.post(`/risk-group/update`, data).then(res => res.data);
  },

  getRiskGroupEmployee({ orgId, employeeId }) {
    return medrec
      .get(`/risk-group/employee`, { params: { orgId, employeeId } })
      .then(res => res.data);
  },

  setEmployeeBoundaries(id, data) {
    return medrec
      .post(`/employees/${id}/boundaries`, data)
      .then(res => res.data);
  },

  resetEmployeeBoundaries(id) {
    return medrec.delete(`/employees/${id}/boundaries`).then(res => res.data);
  },

  getEmployeeRecord(id, signal) {
    return medrec
      .get(`/employees/${id}/record`, { ...(signal && { signal }) })
      .then(res => res.data);
  },

  fetchRiskFactors(employeeId, orgId) {
    return medrec
      .get(`/risk-factors/employee`, { params: { orgId, employeeId } })
      .then(res => res.data);
  },

  getEmployeeComments(id) {
    return medrec.get(`/employees/${id}/comments`).then(res => res.data);
  },

  sendComment({ id, ...comment }) {
    return medrec
      .post(`/employees/${id}/comments`, comment)
      .then(res => res.data);
  },

  getRecipesList() {
    return medrec.get(`/recipes`).then(res => res.data);
  },

  getOrganizationProfilePreview(id) {
    return medrec
      .get(`/organizations/${id}/profile/preview`)
      .then(res => res.data);
  },

  getProfilesPreview() {
    return medrec.get(`/profiles/previews`).then(res => res.data);
  },

  changeOrganizationProfile(id, profileKey) {
    return medrec
      .put(`/organizations/${id}/profile/${profileKey}`)
      .then(res => res.data);
  },

  getMedicalTestTypes(params) {
    return medrec.get(`/medical_tests/types`, { params }).then(res => res.data);
  },

  changeEmployeeMedicalTest(id, value) {
    return medrec.put(`/medical_tests/${id}`, value).then(res => res.data);
  },

  deleteEmployeeMedicalTest(id, reason) {
    return (
      medrec
        // DELETE по спеке не должен принимать body параметры,
        // иначе ломается openapi. Поэтому POST.
        .post(`/medical_tests/${id}/delete`, { reason })
        .then(res => res.data)
    );
  },

  getEmployeeMedicalTests(id) {
    return medrec.get(`/employees/${id}/medical_tests`).then(res => res.data);
  },

  importEmployeeMedicalTests(orgId, file) {
    const formData = new FormData();
    formData.append('file', file);

    return medrec
      .post(`/organizations/${orgId}/medical_tests`, formData)
      .then(res => res.data);
  },

  addEmployeeMedicalTest(id, orgId, value) {
    return medrec
      .post(`/organizations/${orgId}/employees/${id}/medical_tests`, value)
      .then(res => res.data);
  },
  getDefaultBoundaries() {
    return medrec.get('/boundaries/default');
  },
  getOrganizationBoundaries(id) {
    return medrec.get(`/organizations/${id}/boundaries`).then(res => res.data);
  },
  setOrganizationBoundaries(id, data) {
    return medrec
      .post(`/organizations/${id}/boundaries`, data)
      .then(res => res.data);
  },
  updateOrganizationBoundaries(id, data) {
    return medrec
      .put(`/organizations/${id}/boundaries`, data)
      .then(res => res.data);
  },
  resetOrganizationBoundaries(id) {
    return medrec
      .delete(`/organizations/${id}/boundaries`)
      .then(res => res.data);
  },
  getEmployeeMedicalPrevention(id) {
    return medrec
      .get(`/medical-prevention/employee/${id}`)
      .then(res => res.data);
  },

  changeEmployeeMedicalPrevention(id, value) {
    return medrec
      .put(`/medical-prevention/employee/${id}`, value)
      .then(res => res.data);
  },

  // ORGANIZATION RISK GROUP SETTINGS

  getOrgRiskGroupsSettings(id) {
    return medrec.get(`/org-settings/${id}`).then(res => res.data);
  },
  updateOrgRiskGroupsSettings(id, payload) {
    return medrec.put(`/org-settings/${id}`, payload).then(res => res.data);
  },
  // get orgs available to current user with at least some risk groups enabled
  getUserEnabledRiskOrgs() {
    // FIXME: make backend return me needed format so i don't write new componnt
    return medrec
      .get('/org-settings/user/enabled', { params: { page: 1, limit: 10000 } })
      .then(res => res.data);
  },
};
