import { ModuleDefinition } from '@/models/app/ModuleDefinition';

export default {
  hasChildren: true,
  namespaced: true,
  menu: [
    {
      title: 'Внешние интеграции',
      name: 'integrations',
      priority: 9,
    },
  ],
  routes: [
    {
      path: 'integrations',
      name: 'integrations',
      component: () => import('@/views/Provider.vue'),
      redirect: { name: 'admin:integrations:niiat' },
    },
  ],
} as ModuleDefinition;
