import env from '@/plugins/env';
import { createHTTP } from '../api';

export const semaphore = createHTTP(env.get('VUE_APP_SEMAPHORE_API') + '/v3');

export default {
  /**
   * @action VIDM_R
   */
  matchVideoForInspection(id, signal) {
    return semaphore
      .get(`/inspections/${id}/match`, { ...(signal && { signal }) })
      .then(res => res.data);
  },
};
