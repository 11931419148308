<template>
  <AsyncSelect v-bind="computedProps" v-on="$listeners">
    <template #item:description="{ item }">
      <v-chip v-if="item.organization" x-small>
        {{ item.organization.name }}
      </v-chip>
      <v-chip v-if="item.owner" x-small>
        {{ item.owner.name }}
      </v-chip>
    </template>
  </AsyncSelect>
</template>

<script>
import asyncSelectMixin from '@/components/controls/asyncSelectMixin';

// if you set prop 'idType', component start using another endpoint for fetching data

export default {
  extends: asyncSelectMixin,

  props: {
    // props for the normal endpoint
    accessLevel: {
      validator: value => ['any', 'full'].indexOf(value) !== -1,
      default: 'any',
    },
    orgIds: {
      type: [Array],
      default: null,
    },
    type: {
      validator: value => ['all', 'public', 'system'].indexOf(value) !== -1,
      default: 'public',
    },

    // props for the composite endpoint
    idType: {
      // org, med_org, org_group
      type: String,
      default: null,
    },
    id: {
      type: Number,
      default: null,
    },
  },

  computed: {
    label() {
      return this.multiple ? 'Группы работников' : 'Группа работников';
    },
    query() {
      if (this.idType) {
        return {
          id: this.id,
          type: this.idType,
        };
      }
      return {
        accessLevel: this.accessLevel,
        orgIds: this.orgIds?.join() || undefined,
        type: this.type === 'all' ? undefined : this.type,
      };
    },
  },

  methods: {
    fetcher(params) {
      if (this.idType) {
        return this.$store.dispatch(
          'STRUCTURES/fetchCompositeEmployeeGroupPreviews',
          params,
        );
      }

      return this.$store.dispatch(
        'STRUCTURES/fetchEmployeeGroupPreviews',
        params,
      );
    },

    fetcherBy(params) {
      if (this.idType) {
        return this.$store.dispatch(
          'STRUCTURES/fetchCompositeEmployeeGroupPreviewsBy',
          params,
        );
      }

      return this.$store.dispatch(
        'STRUCTURES/fetchEmployeeGroupPreviewsBy',
        params,
      );
    },
  },
};
</script>
