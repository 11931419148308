export class Token {
  constructor({ type, certificate }) {
    this.type = type;
    this.certificate = certificate;
    this.data = {};
    this.accountCertificate = null;
  }

  get serial() {
    return this.data.serial;
  }

  get isInitialized() {
    return this.accountCertificate?.isInitialized || null;
  }

  generateError(message) {
    return {
      type: 'error',
      message,
    };
  }

  setAccountCertData(data) {
    this.accountCertificate = data;
  }

  async checkIsValid() {
    console.error('This method is not implemented', this);
    return false;
  }
}
