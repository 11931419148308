<template>
  <ModernModalLayout
    size="large"
    :title="modalTitle"
    dense-content
    :watch-for="account"
  >
    <BlockTitle title="Аккаунт" />
    <v-form class="pa-6">
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="account.email"
            label="Email"
            outlined
            hide-details="auto"
            :disabled="$wait('submit')"
            :error-messages="getValidationErrors('account.email')"
            @blur="validateField('account.email')"
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="account.roleKey"
            label="Роль"
            item-value="key"
            item-text="name"
            :item-disabled="item => !item.canBeSet"
            outlined
            hide-details="auto"
            :menu-props="{ offsetY: true }"
            :items="accountRoles"
            :disabled="$wait('submit') || $wait('loadingRoles')"
            :error-messages="getValidationErrors('account.roleKey')"
            @blur="validateField('account.roleKey')"
          />
        </v-col>
      </v-row>
    </v-form>

    <BlockTitle title="Профиль" />
    <ProfileFields
      ref="profile"
      v-model="account.profile"
      class="pa-6"
      :is-submit="$wait('submit')"
    />

    <BlockTitle title="Доступы" />
    <v-form class="pa-6">
      <Spinner v-if="$wait('loadingAccount')" />
      <AccountBindings
        v-else
        :value="account.bindings"
        @input="handleChangeBindings"
      />
    </v-form>

    <template #actions:append>
      <v-btn
        depressed
        color="primary"
        :submit="$wait('submit')"
        @click="submit"
      >
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapActions, mapGetters } from 'vuex';
import validation from '@/utils/validation';
import { required, email } from 'vuelidate/lib/validators';
import { unmaskPhone } from '@/utils/masks';
import waitable from '@/utils/mixins/waitable';

import Spinner from '@/components/Spinner.vue';
import BlockTitle from '@/components/ui/BlockTitle.vue';
import ModernModalLayout from '@/components/layouts/ModernModalLayout.vue';
import AccountBindings from '@/components/bindings/AccountBindings.component.vue';
import ProfileFields from '@/modules/admin/users/components/ProfileFields.vue';

export default {
  components: {
    Spinner,
    BlockTitle,
    ModernModalLayout,
    AccountBindings,
    ProfileFields,
  },
  mixins: [validation, waitable],

  props: {
    action: {
      type: String,
      default: 'create',
    },
    id: {
      type: Number,
      default: null,
    },
  },

  data: () => ({
    account: {
      roleKey: null,
      email: null,
      profile: { type: 'human' },
      bindings: {
        // dynamic changes
        objects: {},
        inspections: [],
      },
    },
  }),

  validations: {
    account: {
      roleKey: { required },
      email: { required, email },
    },
  },

  computed: {
    ...mapGetters('ADMIN/USERS_ITEM', ['singleItem']),
    ...mapGetters('REGISTRY', ['accountRoles']),

    modalTitle() {
      return this.action === 'create'
        ? 'Создать пользователя'
        : 'Копировать пользователя';
    },
  },

  // if we open this modal and there is an account in store
  // means we r currently creating a copy of this user
  async created() {
    this.$can('RL_RP') && (await this.fetchAccountRoles());

    if (this.action === 'copy') {
      await this.$loadingNotify(
        this.$store.dispatch('ADMIN/USERS_ITEM/fetchSingle', this.id),
        'loadingAccount',
        'Ошибка загрузки пользователя',
      );

      this.account = {
        ...cloneDeep(this.singleItem),
        profile: {
          type: this.singleItem.profile.type,
        },
        email: null,
        id: undefined,
      };
    }
  },

  methods: {
    ...mapActions('ADMIN/USERS', ['createItem']),
    ...mapActions('REGISTRY', ['fetchAccountRoles']),

    async submit() {
      if (!this.validate() || !this.$refs.profile.validate()) return;

      const preparedResponse = { ...this.account };

      if (this.account.profile.phone) {
        preparedResponse.profile = {
          ...this.account.profile,
          phone: unmaskPhone(this.account.profile.phone),
        };
      }
      // Если есть добавленные доступы к осмотрам с незаполненными данными, удаляем их
      if (preparedResponse.bindings.inspections?.length) {
        preparedResponse.bindings.inspections =
          preparedResponse.bindings.inspections.filter(
            inspection => inspection.parent?.id,
          );
      }

      await this.$loadingNotify(
        this.createItem(preparedResponse),
        'submit',
        'Произошла ошибка создания пользователя',
        'Пользователь успешно создан',
      )
        .then(() => this.$emit('close'))
        .catch(err => {
          this.$refs.profile.catchServerErrors(err, 'value');
          this.catchServerErrors(err, 'account');
        });
    },

    handleChangeBindings(value) {
      this.account.bindings = value;
    },
  },
};
</script>
