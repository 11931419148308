<template>
  <v-layout wrap>
    <v-row no-gutters>
      <v-col cols="6">
        <v-select
          label="Период"
          outlined
          hide-details="auto"
          :menu-props="{ offsetY: true }"
          :items="types"
          :value="value.type"
          @input="handleChange($event, value.day, value.time)"
        />
      </v-col>
      <v-col
        v-if="value.type === 'WEEKLY' || value.type === 'MONTHLY'"
        cols="3"
      >
        <v-select
          :key="value.type"
          class="ml-4"
          :label="'День ' + (value.type === 'WEEKLY' ? 'недели' : 'месяца')"
          outlined
          hide-details="auto"
          :menu-props="{ offsetY: true }"
          :items="days"
          :value="value.day"
          :error-messages="getValidationErrors('value.day')"
          @blur="validateField('value.day')"
          @input="handleChange(value.type, $event, value.time)"
        />
      </v-col>
      <v-col cols="3">
        <MaskField
          class="ml-4"
          label="Время"
          outlined
          hide-details="auto"
          :value="value.time"
          placeholder="HH:M0"
          first-format
          :error-messages="getValidationErrors('value.time')"
          :mask="maskScheduleTime"
          @blur="validateField('value.time')"
          @input="ev => handleChange(value.type, value.day, ev)"
        />
      </v-col>
    </v-row>
    <v-alert
      v-if="value.type === 'MONTHLY' && value.day > 28"
      dense
      class="mt-1"
      style="width: 100%"
      outlined
      type="info"
    >
      Если в месяце меньше {{ value.day }}
      {{ plural(value.day, 'дня', 'дней', 'дней') }}, то задача не выполнится
    </v-alert>
  </v-layout>
</template>

<script>
import plural from 'plural-ru';
import { required, requiredIf } from 'vuelidate/lib/validators';
import validation from '@/utils/validation';
import { timeScheduler } from '@/utils/validators';
import { maskScheduleTime } from '@/utils/masks';
import {
  SCHEDULE_TYPES,
  SCHEDULE_WEEKLY_UNITS,
  SCHEDULE_MONTHLY_UNITS,
} from '@/modules/scheduler/constants';
import MaskField from '@/components/controls/MaskField';

export default {
  components: { MaskField },
  mixins: [validation],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    types() {
      return SCHEDULE_TYPES;
    },
    days() {
      return this.value.type === 'WEEKLY'
        ? SCHEDULE_WEEKLY_UNITS
        : SCHEDULE_MONTHLY_UNITS;
    },
    maskScheduleTime: () => maskScheduleTime,
  },

  validations() {
    return {
      value: {
        time: { required, timeScheduler },
        day: { required: requiredIf(() => this.value.type !== 'DAILY') },
      },
    };
  },

  methods: {
    plural,

    handleChange(type, day, time) {
      this.$emit('input', {
        type,
        ...{ day: type === 'DAILY' ? undefined : day },
        time,
      });
    },
  },
};
</script>
