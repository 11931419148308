<template>
  <List
    search-filter
    :available-actions="{
      createAction: $can('MR_PC'),
      showAction: $can('MR_PR'),
    }"
    @item:create="
      () => $router.push({ name: 'admin:documents-outgoing:create' })
    "
  />
</template>

<script>
import ListFactory from '@/components/crud/ListFactory';

const messages = {
  resourceName: 'профилей исходящих документов',
  searchFilterLabel: 'Поиск по профилям',
  searchFilterPlaceholder: '',
};

const entity = [
  { key: 'key', label: 'Ключ' },
  { key: 'name', label: 'Название' },
];

const List = ListFactory({
  storeModule: 'ADMIN/DOCS_OUTGOING',
  messages,
  entity,
  viewRoute: 'admin:documents-outgoing:item',
});

export default {
  components: { List },
};
</script>
