<template>
  <v-btn
    class="pa-0"
    text
    small
    color="primary"
    target="_blank"
    :to="to"
    v-bind="$attrs"
  >
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  props: {
    text: {
      required: true,
      type: String,
    },
    to: {
      required: true,
      type: Object,
    },
  },
};
</script>
