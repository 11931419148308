import medrecApi from '@/api/services/medrec';

export default {
  state: {
    boundaries: null,
  },

  getters: {
    boundaries: state => state.boundaries,
  },

  mutations: {
    boundaries: (state, value) => (state.boundaries = value),
  },

  actions: {
    async fetchBoundaries({ getters, commit }) {
      const result = await medrecApi.getOrganizationBoundaries(getters.id);
      commit('boundaries', result);
      return result;
    },
    async setBoundaries({ getters, commit }, data) {
      const result = await medrecApi.setOrganizationBoundaries(
        getters.id,
        data,
      );
      commit('boundaries', result);
      return result;
    },
    async updateBoundaries({ getters, commit }, data) {
      const result = await medrecApi.updateOrganizationBoundaries(
        getters.id,
        data,
      );
      commit('boundaries', result);
      return result;
    },
    async resetBoundaries({ getters, commit }) {
      await medrecApi.resetOrganizationBoundaries(getters.id);
      commit('boundaries', null);
    },
  },
};
