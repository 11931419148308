<template>
  <ModernModalLayout
    :title="
      data.id
        ? 'Редактирование группы работников'
        : 'Добавление группы работников'
    "
    close-label="Отмена"
    :watch-for="data"
  >
    <v-form ref="form" :disabled="isLoading || disabled || $wait('submit')">
      <v-text-field
        v-model="data.name"
        label="Название"
        outlined
        :error-messages="getValidationErrors('data.name')"
        @blur="validateField('data.name')"
      />
      <v-text-field
        v-model="data.description"
        label="Описание"
        outlined
        :error-messages="getValidationErrors('data.description')"
        @blur="validateField('data.description')"
      />
      <OrganizationSelect
        v-if="!data.id"
        v-model="data.orgId"
        access-level="full"
        :error-messages="getValidationErrors('data.orgId')"
        @blur="validateField('data.orgId')"
      />
    </v-form>

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :disabled="$wait('submit')"
        @click="submit"
      >
        {{ data.id ? 'Сохранить' : 'Добавить' }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';

export default {
  components: { ModernModalLayout, OrganizationSelect },
  mixins: [validation, waitable],

  props: {
    value: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },

    predefinedValues: { type: Object, default: () => ({}) },
    storeForCreate: { type: String, default: 'STRUCTURES/EMPLOYEE_GROUPS' },
  },

  data: () => ({
    data: { orgId: null },
  }),

  validations() {
    return {
      data: {
        name: { required },
        description: { required },
        orgId: { required: requiredIf(() => !this.data.id) },
      },
    };
  },

  created() {
    this.value.id && (this.data = { ...this.value });

    !this.data.id &&
      Object.keys(this.predefinedValues).length &&
      Object.keys(this.predefinedValues).forEach(
        key => (this.data[key] = this.predefinedValues[key]),
      );
  },

  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const actionName = this.data.id
        ? 'STRUCTURES/EMPLOYEE_GROUPS_ITEM/updateSingleItem'
        : `${this.storeForCreate}/createListItem`;

      await this.$loadingNotify(
        this.$store.dispatch(actionName, this.data),
        'submit',
        `Произошла ошибка ${
          this.data.id ? 'изменения' : 'создания'
        } группы работников`,
        `Группа работников успешно ${this.data.id ? 'изменена' : 'создана'}`,
      )
        .then(() => this.$emit('close'))
        .catch(err => this.catchServerErrors(err, 'data'));
    },
  },
};
</script>
