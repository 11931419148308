<template>
  <List
    search-filter
    :available-actions="{
      createAction: $can('GT_CPR'),
      showAction: $can('GT_GPR'),
    }"
    @item:create="() => $router.push({ name: 'admin:profiles:create' })"
  >
    <template #filters="query">
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.formFactor"
          label="Тип профиля"
          :items="formFactors"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          multiple
          dense
          :loading="$wait('fetchingProfileTemplates')"
          :menu-props="{ offsetY: true }"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.isCustom"
          label="Вид конфигурации"
          :items="configType"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :menu-props="{ offsetY: true }"
        />
      </v-col>
    </template>
    <template #table.item.formFactor="{ item }">
      {{ getFormFactorName(item) }}
    </template>
  </List>
</template>

<script>
import waitable from '@/utils/mixins/waitable';
import ListFactory from '@/components/crud/ListFactory';
import { messages, entity, filters } from '../entity';
import { mapGetters } from 'vuex';
import { CONFIG_TYPES } from '@/utils/constants';

const List = ListFactory({
  storeModule: 'ADMIN/PROFILES',
  messages,
  entity,
  filters,
  viewRoute: 'admin:profiles:item',
});

export default {
  components: { List },
  mixins: [waitable],
  data: () => ({
    configType: CONFIG_TYPES,
  }),
  computed: {
    ...mapGetters('REGISTRY', ['profileTemplates']),
    formFactors() {
      const ff = this.profileTemplates.formFactor;
      return Object.keys(ff).map(k => ({ name: ff[k], value: k }));
    },
  },
  mounted() {
    this.$loadingNotify(
      this.$store.dispatch('REGISTRY/fetchProfileTemplates'),
      'fetchingProfileTemplates',
      'Произошла ошибка загрузки шаблонов профилей',
    );
  },

  methods: {
    getFormFactorName(item) {
      return this.formFactors?.find(f => f.value === item.formFactor)?.name;
    },
  },
};
</script>
