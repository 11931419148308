import historyApi from '@/api/services/history';
import medrecApi from '@/api/services/medrec';

import { createModule } from '@/utils/vuex/createModule';

const state = {
  measurementsHistory: [],
  employeeRecord: {},
};

const getters = {
  measurementsHistory: state => state.measurementsHistory,
  employeeRecord: state => state.employeeRecord,
};

const mutations = {
  measurementsHistory: (state, value) => (state.measurementsHistory = value),
  employeeRecord: (state, value) => (state.employeeRecord = value),
};

const actions = {
  async fetchMeasurementsHistory({ commit }, id) {
    const response = await historyApi.getEmployeeHistory(id);
    commit('measurementsHistory', response || {});
  },
  async fetchEmployeeRecord({ commit }, id) {
    const response = await medrecApi.getEmployeeRecord(id);
    commit('employeeRecord', response || {});
  },
};

export default createModule({ state, getters, mutations, actions });
