<template>
  <Card title="Настройка авторизации" dense>
    <AsyncWrapper :handler="fetchAuthSettings">
      <v-simple-table dense>
        <tbody>
          <tr v-for="(val, key) in authTypes" :key="key">
            <td class="grey--text px-4 py-3">
              {{ authTypeName(key) || key }}
              <v-tooltip v-if="authHints[key]" right max-width="400px">
                <template #activator="{ on, attrs }">
                  <v-btn small text icon v-bind="attrs" v-on="on">
                    <v-icon small>far fa-question-circle</v-icon>
                  </v-btn>
                </template>
                <span style="width: 200px">{{ authHints[key] }}</span>
              </v-tooltip>
            </td>
            <td class="px-4 py-3" style="width: 50px">
              <v-switch
                :input-value="val"
                class="ma-0 pa-0"
                inset
                :disabled="!$can('U_OSA')"
                hide-details
                :readonly="true"
                :loading="$wait('handleAuthSettingsUpdate' + key)"
                @mousedown="handleAuthSettingsUpdate(key)"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </AsyncWrapper>
    <template #action>
      <v-tooltip bottom max-width="400px">
        <template #activator="{ on, attrs }">
          <v-btn small text icon v-bind="attrs" v-on="on">
            <v-icon small>far fa-question-circle</v-icon>
          </v-btn>
        </template>
        <span style="width: 200px">
          Должен быть включен хотя бы 1 тип авторизации. Если выключилть все
          типы авторизации включится авторизация по табельному номеру.
        </span>
      </v-tooltip>
    </template>
  </Card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import { AUTH_TYPES } from '@/utils/constants';

import AsyncWrapper from '@/components/AsyncWrapper';
import Card from '@/components/ui/Card';

export default {
  components: { AsyncWrapper, Card },
  mixins: [waitable],

  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', ['authTypes']),
    authHints() {
      return {
        faceid:
          'Если лицо не найдено, то авторизация запрещена, без возможности ввода табельного номера.',
        faceid_and_pn:
          'После авторизации по лицу всегда запрашивается ввод табельного номера, после чего происходит сверка фото в базе FindFace и карточке работника. При соответствии фото, осмотр может быть продолжен, при несоответствии - ошибка, и возможность повторного ввода табельного номера. В случае отсутствия фото работника в обоих базах, будет успешная авторизация с последующим созданием фото.',
        faceid_or_pn:
          'Если лицо найдено, то произойдет авторизация. Если лицо не найдено, то предлагается ввод табельного номера. ',
      };
    },
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', [
      'fetchAuthSettings',
      'changeAuthSettings',
    ]),

    authTypeName(key) {
      return AUTH_TYPES[key];
    },

    handleAuthSettingsUpdate(key) {
      if (!this.$can('U_OSA')) {
        return;
      }
      const newStatus = !this.authTypes[key];
      const oldSettings = { ...this.authTypes };
      this.$openModal('prompt', {
        yes: 'Да',
        no: 'Отмена',
        title: 'Подтверждение',
        description: `Вы действительно хотите ${
          newStatus ? 'подключить' : 'отключить'
        } авторизацию "${this.authTypeName(key)}"?`,
        messages: {
          successfulAction: `Авторизация "${this.authTypeName(key)}" успешно ${
            newStatus ? 'подключена' : 'отключена'
          }`,
          errorAction: `Ошибка ${
            newStatus ? 'подключения' : 'отключения'
          } авторизации "${this.authTypeName(key)}"`,
        },
        onSubmit: () =>
          this.$loading(
            this.changeAuthSettings(key).then(() => {
              oldSettings[key] = !oldSettings[key];
              const types = Object.keys(oldSettings).filter(
                el => oldSettings[el],
              );
              if (!types.length) {
                this.$notify({
                  group: 'note',
                  type: 'info',
                  title: 'Авторизация "Табельный номер" успешно подключена',
                });
              }
            }),
            'handleAuthSettingsUpdate' + key,
          ),
      });
    },
  },
};
</script>
