<template>
  <v-simple-table dense class="definition-list" :dark="dark">
    <tbody>
      <tr
        v-for="{ label, value: val, component, props, labelTooltip } in value"
        :key="label"
      >
        <template v-if="val || showEmptyValues || component">
          <td
            :class="{ 'grey--text': true, 'pa-4': !dense }"
            :style="[{ width: widthColumns[0] }]"
          >
            {{ label }}
            <v-tooltip v-if="labelTooltip" right>
              <template #activator="{ on, attrs }">
                <v-btn small text icon v-bind="attrs" v-on="on">
                  <v-icon small>far fa-question-circle</v-icon>
                </v-btn>
              </template>
              <span>{{ labelTooltip }}</span>
            </v-tooltip>
          </td>
          <td
            :class="{ 'pa-4': !dense, 'py-1': dense }"
            :style="[{ width: widthColumns[1] }]"
          >
            <component
              :is="component"
              v-if="component"
              v-bind="{ ...props, value: val }"
            />
            <template v-else> {{ val }} </template>
          </td>
        </template>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    // If Object it may have following structure:
    // { value, component, props }
    value: { type: [Array, Object], required: true },
    dense: Boolean,
    dark: Boolean,
    widthColumns: { type: Array, default: () => ['40%', '60%'] },
    showEmptyValues: Boolean,
  },
};
</script>

<style lang="scss">
.definition-list table {
  table-layout: fixed;
}
</style>
