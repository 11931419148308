import subscribePlugin from '@/store/plugins/subscribePlugin';
import Vue from 'vue';
import Vuex from 'vuex';

import ROOT from './root';
import AUTH from './modules/auth';
import REGISTRY from './modules/registry';
import STRUCTURES from './modules/structures';
import GATE from './modules/gate';
import AUTH_PREVIEWS from './modules/auth-previews';
import SIGNER from './modules/signer';
import TOKENS from './modules/tokens';
import TIME from './modules/time';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [subscribePlugin],
  modules: {
    AUTH,
    REGISTRY,
    STRUCTURES,
    GATE,
    AUTH_PREVIEWS,
    SIGNER,
    TOKENS,
    TIME,
  },
  ...ROOT,
});
