export const messages = {
  resourceName: 'групп ПАК',
  searchFilterLabel: 'Поиск по группам ПАК',
  searchFilterPlaceholder: '',
};

export const entity = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: 'Название', hideInTable: true },
  { key: 'name_org', label: 'Название / Организация', hideInCard: true },
  { key: 'description', label: 'Описание' },
  {
    key: 'itemsCount',
    label: 'Количество ПАК',
    headerProps: { width: '110px', align: 'center' },
  },
];

export const filters = {
  orgIds: { type: Array, default: undefined },

  // fixed filter
  type: {
    type: String,
    default: 'public',
  },
};
