<template>
  <v-card>
    <v-card-title class="subheading font-weight-bold">
      {{ surveyDate }}
    </v-card-title>

    <v-divider />

    <v-list dense>
      <v-list-item
        v-for="item in answers"
        :key="item.key"
        class="flex-nowrap"
        :class="{ 'grey--text': item.status !== 'actual' }"
      >
        <v-list-item-content
          style="flex-basis: 80%"
          class="survey-item__text flex-column align-start py-1"
        >
          <span>{{ question(item.key) }}</span>
          <span class="text-caption">{{ statusText(item.status) }}</span>
        </v-list-item-content>
        <v-list-item-content
          style="flex-basis: 20%"
          class="survey-item__text ml-4 py-1"
        >
          {{ answer(item.value) }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { QUESTIONS, ANSWERS_STATUS } from '@/utils/constants';
import { date as dateConverter } from '@/utils/convert';

const BOOL_ANSWERS = {
  yes: 'Да',
  no: 'Нет',
};

export default {
  name: 'SurveyItem',
  props: {
    date: { type: String, required: true },
    answers: { type: Array, default: () => [] },
  },
  computed: {
    surveyDate() {
      return dateConverter(this.date);
    },
  },
  methods: {
    question(key) {
      return QUESTIONS[key] || key;
    },
    statusText(status) {
      return ANSWERS_STATUS[status] ? `(${ANSWERS_STATUS[status]})` : '';
    },
    answer(value) {
      if (!value) {
        return '-';
      }
      // FIXME:(surveys) Ответ на вопрос "Курите ли Вы?" приходит в формате doyousmoke.yes/doyousmoke.no
      if (value.includes('doyousmoke')) {
        return BOOL_ANSWERS[value.split('.')[1]];
      }
      return value;
    },
  },
};
</script>

<style lang="scss">
.grey--text .survey-item__text {
  color: #9e9e9e;
}
</style>
