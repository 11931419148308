import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import BOUNDARIES from './item.store';

export default {
  namespaced: true,
  store: {
    BOUNDARIES,
  },
  modals: [
    {
      name: 'boundaries',
      component: () => import('./Boundaries.modal.vue'),
    },
  ],
} as ModuleDefinition;
