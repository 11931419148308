import { initStoreInstance } from '@/utils/tests';

// api
import medrecApi from '@/api/services/medrec';
import structApi from '@/api/services/structures';
import bansApi from '@/api/services/bans';

import employeeStore from '@/modules/employees/store/item';

// jest.mock('@/utils/convert/employee', () => ({
//   convertEmployee: item => item,
//   convertMedDocument: item => item,
//   convertBan: item => item,
// }));

const ns = 'EMPLOYEE';
let store;

beforeEach(() => (store = initStoreInstance(employeeStore, true, ns)));

describe('store: employee', () => {
  describe('actions', () => {
    it('fetches single employee', async () => {
      structApi.employeeGet.mockImplementationOnce(async () => ({
        employee: 'yes',
      }));
      medrecApi.getEmployeeRecord.mockImplementationOnce(async () => ({
        medrec: 'yes',
        documents: ['meddoc1', 'meddoc2'],
      }));
      bansApi.getEmployeeBans.mockImplementationOnce(async () => [
        'ban1',
        'ban2',
        'ban3',
      ]);

      await store.dispatch(`${ns}/fetchSingle`);

      expect(structApi.employeeGet).toBeCalledTimes(1);
      expect(medrecApi.getEmployeeRecord).toBeCalledTimes(1);
      expect(bansApi.getEmployeeBans).toBeCalledTimes(1);

      expect(store.getters[`${ns}/singleItem`]).toStrictEqual({
        employee: 'yes',
      });
      expect(store.getters[`${ns}/medRecord`]).toStrictEqual({
        medrec: 'yes',
        documents: ['meddoc1', 'meddoc2'],
      });
      expect(store.getters[`${ns}/bans`]).toStrictEqual([
        'ban1',
        'ban2',
        'ban3',
      ]);
    });
  });
});
