<template>
  <List
    search-filter
    :available-actions="{
      createAction: $can('NF_TC'),
      showAction: $can('NF_TR'),
    }"
    @item:create="
      $router.push({
        name: 'notifications:templates:create',
      })
    "
  />
</template>

<script>
import { RECIPIENT_TYPES } from '@/utils/constants';
import ListFactory from '@/components/crud/ListFactory';
import { messages, entity } from '../entity';

const List = ListFactory({
  storeModule: 'NOTIFICATIONS/TEMPLATES',
  messages,
  entity,
  viewRoute: 'notifications:templates:item',
});

export default {
  components: { List },
  computed: {
    recipientTypes: () => RECIPIENT_TYPES.filter(el => !el?.hideInFilter),
  },
};
</script>
