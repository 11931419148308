import structApi from '@/api/services/structures';
import { createModule } from '@/utils/vuex/createModule';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { filters } from '../entity';

const actions = {
  changeCounter(
    { commit, rootGetters },
    { itemIds, counterField, addCount = 0 },
  ) {
    if (!itemIds || !itemIds.length || !counterField) return;
    commit('changeCounter', { itemIds, counterField, addCount });
    const categorySingleItem =
      rootGetters['STRUCTURES/ORGANIZATION_CATEGORIES_ITEM/singleItem'];
    if (categorySingleItem && itemIds.includes(categorySingleItem.id))
      categorySingleItem.orgsCount += addCount;
  },
};

export default createModule(
  crudListFactory({
    fetchMethod: structApi.categoryList,
    createMethod: structApi.categoryCreate,
    deleteMethod: structApi.categoryDelete,
    filters,
  }),
  { actions },
);
