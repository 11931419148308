import notifApi from '@/api/services/notifications';
import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

export default createModule(
  crudSingleFactory({
    fetchMethod: notifApi.template_setsGet,
    updateMethod: notifApi.template_setsUpdate,
    deleteMethod: notifApi.template_setsDelete,
    linkedList: 'NOTIFICATIONS/TEMPLATES',
  }),
);
