<template>
  <div class="d-flex flex-column" style="gap: 24px">
    <ActionsMenu>
      <ActionsMenuItem
        v-if="$can('I_R')"
        title="История осмотров"
        @click="inspectionsHistoryModal"
      >
        <template #icon>
          <v-icon>mdi-clipboard-text-clock-outline</v-icon>
        </template>
      </ActionsMenuItem>

      <ActionsMenuItem
        v-if="$can('MEAS_R')"
        title="Показать графики"
        subtitle="Изменения давления и пульса"
        @click="chartsModal"
      >
        <template #icon>
          <v-icon>mdi-chart-bell-curve</v-icon>
        </template>
      </ActionsMenuItem>
    </ActionsMenu>

    <ActionsMenu v-if="!orgNoModify">
      <NewStatusChanger
        instance="работника"
        :item="employee"
        :can-enable="$can('EMP_EN')"
        :can-disable="$can('EMP_DS')"
        :disabled="employeeSyncActions.includes('enable')"
        :handler="changeStatusHandler"
      />
    </ActionsMenu>

    <ActionsMenu v-if="isMedic && $can('OUT_REF')">
      <ActionsMenuItem
        title="Сформировать направление"
        @click="appointmentModal"
      >
        <template #icon>
          <v-icon>mdi-text-box-check</v-icon>
        </template>
      </ActionsMenuItem>
    </ActionsMenu>

    <ActionsMenu v-if="$can('GRT')">
      <Rating :item="employee" />
    </ActionsMenu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ActionsMenu from '@/components/ui/ActionsMenu';
import ActionsMenuItem from '@/components/ui/ActionsMenuItem';
import NewStatusChanger from '@/components/controls/NewStatusChanger';
import Rating from '@/modules/employees/components/Rating.vue';

export default {
  name: 'ItemAside',
  components: {
    ActionsMenu,
    ActionsMenuItem,
    NewStatusChanger,
    Rating,
  },
  props: {
    employee: { type: Object, required: true },
    orgNoModify: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters('EMPLOYEE', ['orgSettings']),
    ...mapGetters('AUTH', ['isMedic']),

    employeeSync() {
      return this.orgSettings?.employeeSync;
    },

    employeeSyncActions() {
      return this.employeeSync?.actions || [];
    },
  },

  methods: {
    ...mapActions('EMPLOYEE', ['changeStatus']),
    ...mapActions('STRUCTURES/EMPLOYEE_GROUPS', {
      changeCountOnEmpGroups: 'changeCounter',
    }),
    async changeStatusHandler(newStatus) {
      await this.changeStatus(newStatus);
      let addCount = 1;
      if (!newStatus) addCount = -1;

      if (this.employee.groups.length)
        this.changeCountOnEmpGroups({
          itemIds: this.employee.groups.map(group => group.id),
          counterField: 'itemsCount',
          addCount,
        });
    },
    inspectionsHistoryModal() {
      this.$openModal('inspections/history', {
        employee: this.employee,
      });
    },
    chartsModal() {
      this.$openModal('employees/medrecCharts', {
        id: this.employee.id,
      });
    },
    appointmentModal() {
      this.$openModal('employees/FormAppointment', {
        employee: this.employee,
      });
    },
  },
};
</script>
