import structApi from '@/api/services/structures';

export default {
  mutations: {
    niiatRegistration: state => {
      !state.singleItem.niiatStatus && (state.singleItem.niiatStatus = {});
      state.singleItem.niiatStatus.registered = true;
    },
  },
  actions: {
    async niiatRegistration({ getters, commit }) {
      const result = await structApi.hostNiiatRegistration(getters.id);
      result && commit('niiatRegistration');
    },
  },
};
