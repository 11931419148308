import gateApi from '@/api/services/gate';

// "formFactor": {
//   "anti_vandal": "Антивандал",
//   "cabin": "Кабина",
//   "desktop": "Настольный",
//   "mobile": "Мобильный",
//   "standard": "Стандартный"
// },
export default {
  state: {
    formFactors: {},
  },
  getters: {
    formFactors: state => state.formFactors,
  },
  mutations: {
    formFactors: (state, value) => (state.formFactors = value),
  },
  actions: {
    async fetchFormFactors({ commit }) {
      const { formFactor } = await gateApi.getTemplates();
      commit('formFactors', formFactor);
      return formFactor;
    },
  },
};
