<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-chip
        small
        class="ma-1"
        :color="color"
        :text-color="textColor"
        v-on="on"
      >
        {{ label }}
      </v-chip>
    </template>
    <span>{{ description }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    textColor: {
      type: String,
      default: null,
    },
  },
};
</script>
