import env from '@/plugins/env';
import { createHTTP } from '../api';

export const surveys = createHTTP(env.get('VUE_APP_SURVEYS_API'));

export default {
  /** @action SU_OGS */
  async getSurveys(orgId) {
    const res = await surveys.get(`/v3/surveys/organizations/${orgId}/surveys`);
    return res.data;
  },

  /** @action SUR_GTYP */
  async getTypes() {
    const res = await surveys.get('/v3/surveys/types');
    return res.data;
  },

  /** @action SU_OGE */
  async editSurveys(orgId, payload) {
    const res = await surveys.put(
      `/v3/surveys/organizations/${orgId}/surveys`,
      { ...payload },
    );
    return res.data;
  },

  async getActualAnswers(refererId) {
    const res = await surveys.get('/v3/answers/relevant', {
      params: { refererId },
    });
    return res.data;
  },
  async getEmployeeSurveys(params) {
    const res = await surveys.get(`/v3/surveys/results`, { params });
    return res.data;
  },
  async invalidateAnswer(ids, orgId) {
    const res = await surveys.post(`/v3/answers/invalidate/${orgId}`, {
      ids,
      reason: 'not_trusted',
    });
    return res.data;
  },
};
