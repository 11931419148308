import {
  RELATIVE_REFERENCE_POINTS,
  RELATIVE_OFFSET_UNITS,
} from '@/modules/scheduler/constants';
import plural from 'plural-ru';

export function formatRelativeDate(relativeDate) {
  const start = RELATIVE_REFERENCE_POINTS.find(
    item => item.value === relativeDate.start,
  )?.text;

  const pluralUnits = RELATIVE_OFFSET_UNITS.find(
    item => item.value === relativeDate.offset.units,
  ).plural;

  const value = relativeDate.offset.value;

  if (value === 0) return start;

  const valueSign = value > 0 ? 'плюс' : 'минус';
  const units = plural(Math.abs(value), ...pluralUnits);

  return `${start} ${valueSign} ${Math.abs(value)} ${units}`;
}
