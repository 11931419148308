<template>
  <ItemPageLayout back-route="admin:applications" :title="name">
    <template #header>
      <Card dense hide-title>
        <v-tabs v-model="currentTab">
          <v-tab href="#general">Основное</v-tab>
          <v-tab v-if="$can('BLM_UR')" href="#logs">Лог действий</v-tab>
        </v-tabs>
      </Card>
    </template>

    <div
      v-if="currentTab === 'general'"
      style="display: flex; flex-direction: column; gap: 24px"
    >
      <Card title="Роль">
        <h3>{{ role }}</h3>
        <template #action>
          <CardActionBtn
            v-if="$can('ACC_SR')"
            label="Изменить"
            @click="changeRoleModal"
          />
        </template>
      </Card>

      <Card title="Профиль" dense>
        <DefinitionList :value="profileData" />
        <template #action>
          <CardActionBtn
            v-if="$can('PROF_S')"
            label="Редактировать"
            @click="changeProfileModal"
          />
        </template>
      </Card>

      <Card v-if="pushMethodData.length > 0" title="Push-метод" dense>
        <DefinitionList :value="pushMethodData" />
        <template #action>
          <CardActionBtn
            v-if="$can('WBS_S')"
            label="Редактировать"
            @click="editPushSettingsModal"
          />
        </template>
      </Card>

      <Card title="Доступы" dense>
        <AsyncWrapper :key="singleItem.roleKey" :handler="fetchRole">
          <AccountBindingsPreview
            :bindings="singleItem.bindings"
            :all-available="isAllBindingsAvailable"
          />
        </AsyncWrapper>
        <template #action>
          <v-menu
            v-if="!isAllBindingsAvailable"
            open-on-hover
            top
            max-width="700px"
          >
            <template #activator="{ on, attrs }">
              <v-btn small text icon v-bind="attrs" v-on="on">
                <v-icon small>far fa-question-circle</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text><BindingsHelp two-columns /></v-card-text>
            </v-card>
          </v-menu>
          <v-spacer />
          <CardActionBtn
            v-if="$can('BND_C') && !isAllBindingsAvailable"
            label="Редактировать"
            @click="changeBindingsModal"
          />
        </template>
      </Card>

      <Card title="API ключи" dense>
        <ApplicationKeys
          :value="singleItem.keys"
          @deactivate="deactivateApiKeyModal"
          @muteByExpired="muteByExpiredApiKeyModal"
        />
        <template #action>
          <CardActionBtn
            v-if="$can('APP_AK')"
            label="Добавить"
            @click="addApiKeyModal"
          />
        </template>
      </Card>
    </div>

    <template v-if="currentTab === 'general'" #aside>
      <ActionsMenu>
        <NewStatusChanger
          instance="приложение"
          :item="singleItem"
          store-module="ADMIN/APPLICATIONS_ITEM"
          :can-enable="$can('ACC_EN')"
          :can-disable="$can('ACC_DS')"
        />
        <ActionsMenuItem
          v-if="$can('ACC_D')"
          title="Удалить приложение"
          subtitle="Действие нельзя отменить"
          @click="openDeleteModal"
        >
          <template #icon><RemoveCirc /></template>
        </ActionsMenuItem>
      </ActionsMenu>
    </template>

    <template v-else-if="currentTab === 'logs'" #innerList>
      <Card v-if="$can('BLM_UR')" title="Лог действий" class="mb-4" dense>
        <ChangesList :fetch-function="fetchEventReport" />
      </Card>
    </template>
  </ItemPageLayout>
</template>

<script>
import blameApi from '@/api/services/blame';

import { mapActions, mapGetters } from 'vuex';
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import queryTabSave from '@/utils/mixins/queryTabSave';
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';
import { name } from '@/utils/convert';

import ItemPageLayout from '@/components/layouts/ItemPageLayout';
import Card from '@/components/ui/Card';
import ActionsMenu from '@/components/ui/ActionsMenu';
import ActionsMenuItem from '@/components/ui/ActionsMenuItem';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';
import AccountBindingsPreview from '@/components/bindings/AccountBindingsPreview.component';
import ApplicationKeys from '@/modules/admin/applications/components/ApplicationKeys';
import NewStatusChanger from '@/components/controls/NewStatusChanger';
import DefinitionList from '@/components/DefinitionList';
import AsyncWrapper from '@/components/AsyncWrapper';
import BindingsHelp from '@/components/bindings/BindingsHelp';
import ChangesList from '@/components/ChangesList.vue';

// icons
import RemoveCirc from '@/components/icons/remove-circle-outline.vue';

export default {
  components: {
    AccountBindingsPreview,
    ItemPageLayout,
    NewStatusChanger,
    DefinitionList,
    Card,
    ActionsMenu,
    ActionsMenuItem,
    CardActionBtn,
    ApplicationKeys,
    RemoveCirc,
    AsyncWrapper,
    BindingsHelp,
    ChangesList,
  },
  mixins: [validation, waitable, routeGuardMixin, queryTabSave()],

  data: () => ({
    currentTab: 'general',
    organization: { name: '-' }, // default name to not check it later
  }),

  computed: {
    ...mapGetters('REGISTRY', ['accountRolesApplication']),
    ...mapGetters('ADMIN/APPLICATIONS_ITEM', ['isAllBindingsAvailable']),

    name() {
      return name(this.singleItem.profile);
    },

    role() {
      return (
        this.accountRolesApplication.find(
          role => role.key === this.singleItem.roleKey,
        )?.name || 'id: ' + this.singleItem.roleKey
      );
    },

    profileData() {
      if (!this.singleItem?.profile) return [];
      return [
        { label: 'ID', value: this.singleItem.id },
        { label: 'Название приложения', value: this.singleItem.profile.name },
        { label: 'Организация', value: this.organization.name },
        { label: 'Почта для связи', value: this.singleItem.profile.email },
      ];
    },

    pushMethodData() {
      if (!this.singleItem?.waybillSettings) return [];
      const s = this.singleItem.waybillSettings;
      return [
        { label: 'Статус', value: s.pushMethod ? 'Включен' : 'Выключен' },
        { label: 'URL для отправки Т2', value: s.pushMethod && s.t2Callback },
        { label: 'URL для отправки Т6', value: s.pushMethod && s.t6Callback },
      ];
    },
  },

  watch: {
    'singleItem.profile': {
      deep: true,
      handler(newVal, oldVal) {
        newVal.orgId !== oldVal.orgId && this.fetchOrg();
      },
    },
  },

  async created() {
    await this.fetchOrg();

    this.$can('RL_RP') &&
      this.$loadingNotify(
        this.$store.dispatch('REGISTRY/fetchAccountRolesApplication'),
        'loadingRoles',
        'Ошибка загрузки ролей',
      );
  },

  methods: {
    ...mapActions('ADMIN/APPLICATIONS', ['createItem']),
    ...mapActions('ADMIN/APPLICATIONS_ITEM', [
      'fetchSingle',
      'changeBindings',
      'deactivateApiKey',
      'deleteSingleItem',
      'fetchRole',
    ]),

    // don't move this function to store! we don't need cache in logs.
    fetchEventReport({ page, limit }) {
      const accountId = this.singleItem.id;
      return blameApi.getAccountReport({ page, limit, accountId });
    },

    async fetchOrg() {
      // fetch organization of this particular app to get & show its name
      const organizations = await this.$store.dispatch(
        'STRUCTURES/fetchOrganizationPreviewsBy',
        [this.singleItem.profile.orgId],
      );

      this.organization = organizations[0];
    },

    changeRoleModal() {
      this.$openModal('selectModal', {
        value: this.singleItem.roleKey,
        items: this.accountRolesApplication,
        itemValue: 'key',
        onSubmit: async roleKey => this.changeRole(roleKey),
        itemDisabled: item => !item.canBeSet,
        messages: {
          title: 'Редактирование роли',
        },
      });
    },

    async changeRole(roleKey) {
      await this.$loadingNotify(
        this.$store.dispatch('ADMIN/APPLICATIONS_ITEM/changeRole', roleKey),
        'changingRole',
        'Ошибка изменения роли приложения ' + this.singleItem.profile.name,
        `Роль приложения ${this.singleItem.profile.name} успешно изменена`,
      );
    },

    openDeleteModal() {
      this.$openModal('prompt', {
        yes: 'Удалить',
        no: 'Отмена',
        title: 'Удалить приложение',
        description: 'Вы уверены, что хотите удалить приложение?',
        onSubmit: () =>
          this.deleteSingleItem(this.singleItem.id).then(_ =>
            this.$router.push({ name: 'admin:applications' }),
          ),
      });
    },

    changeProfileModal() {
      this.$openModal('admin/applicationsProfile', {
        id: this.singleItem.id,
        value: this.singleItem.profile,
      });
    },

    editPushSettingsModal() {
      this.$openModal('admin/applicationsPush', {
        id: this.singleItem.id,
        value: this.singleItem.waybillSettings,
      });
    },

    changeBindingsModal() {
      this.$openModal('userBindings', {
        instance: 'приложения',
        id: this.singleItem.id,
        value: this.singleItem.bindings,
        onSubmit: payload => this.changeBindings(payload),
      });
    },

    addApiKeyModal() {
      this.$openModal('admin/applicationsAddAppKey', {
        id: this.singleItem.id,
        email: this.singleItem.profile.email,
      });
    },

    deactivateApiKeyModal(item) {
      this.$openModal('prompt', {
        yes: 'Деактивировать',
        no: 'Отмена',
        title: 'Деактивировать ключ',
        description: `Вы уверены, что хотите деактивировать ключ ${item.uuid}?`,
        onSubmit: () => this.deactivateApiKey(item.id),
      });
    },

    muteByExpiredApiKeyModal(item) {
      this.$openModal('admin/applicationsMuteByExpiredApiKey', { item });
    },
  },
};
</script>
