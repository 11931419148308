<template>
  <v-tooltip bottom :disabled="disabled ? !limit : true">
    <template #activator="{ on, attrs }">
      <!-- div wrapper because we can't listen events on disabled button -->
      <div v-bind="attrs" v-on="on">
        <ListActionBtn
          label="Экспортировать"
          icon="mdi-database-export-outline"
          :disabled="disabled"
          :loading="loading"
          @click="$emit('click')"
        />
      </div>
    </template>
    <span>
      Достигнут лимит в {{ limit }} результатов. <br />
      Чтобы экспортировать, сократите выборку с помощью фильтров.
    </span>
  </v-tooltip>
</template>

<script>
import ListActionBtn from '@/components/controls/buttons/ListActionBtn';
export default {
  components: { ListActionBtn },
  props: {
    limit: {
      type: Number,
      default: null,
    },
    disabled: Boolean,
    loading: Boolean,
  },
};
</script>
