import { createModule } from '@/utils/vuex/createModule';
import listSimpleFactory from '@/utils/vuex/listSimpleFactory';
import structApi from '@/api/services/structures';
import { filters } from '@/modules/structures/organizations/entity';

const getters = {
  listQuery: (state, getters, _, rootGetters) => ({
    ...getters.listFilters,
    ...getters.listOrder,
    search: state.listQuery.search || null,
    groupIds: `${rootGetters['STRUCTURES/ORGANIZATION_GROUPS_ITEM/singleItem'].id}`,
  }),
};
export default createModule(
  listSimpleFactory({
    fetchMethod: structApi.organizationList,
    createMethod: structApi.organizationCreate,
    filters,
    linkedList: 'STRUCTURES/ORGANIZATION_LIST',
  }),
  { getters },
);
