<template>
  <List
    search-filter
    :available-actions="{
      showAction: $can('MED_RD'),
      createAction: $can('MED_C'),
    }"
    @item:create="createModal"
  >
    <template #filters="query">
      <v-col cols="12" md="3" sm="4">
        <v-select
          v-model="query.isEnabled"
          label="Статус медработника"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :items="statusItems"
          :menu-props="{ offsetY: true }"
        />
      </v-col>
      <v-col cols="12" md="3" sm="4">
        <CategorySelect v-model="query.categoryIds" multiple dense />
      </v-col>
      <v-col cols="12" md="3" sm="4">
        <Select
          v-model="query.roleKeys"
          label="Роли"
          item-value="key"
          dense
          clearable
          multiple
          :items="medicalRoles"
          :loading="$wait('fetchMedicalRolesPreviews')"
        />
      </v-col>
      <v-col cols="12" md="3" sm="4">
        <MedicGroupSelect v-model="query.groupIds" multiple dense />
      </v-col>
      <v-col cols="12" md="3" sm="4">
        <OrganizationSelect v-model="query.orgIds" medical multiple dense />
      </v-col>
    </template>

    <template #table.item.name_gender_dateOfBirth="{ item }">
      <b>{{ name(item.medicData) }}</b> <br />
      {{ (medicalRoleByKey(item.medicData.roleKey) || {}).name || '–' }}
      <br />
      {{ GENDER_NOMINATIVE[item.medicData.gender] }},&nbsp;{{
        dateFormatter(item.medicData.dateOfBirth)
      }}
    </template>

    <template #table.item.contacts="{ item }">
      {{ item.medicData.email }}
      <br />
      {{ maskTextPhone(item.medicData.phone) }}
    </template>

    <template #table.item.diagnostic="{ item }">
      <v-tooltip
        right
        content-class="tooltip--show-white-space"
        color="rgba(0,0,0,0)"
      >
        <template #activator="{ on }">
          <div v-on="on">
            <v-icon :color="item.diagnostic.highStatus.color">
              {{ item.diagnostic.highStatus.icon }}
            </v-icon>
          </div>
        </template>
        <MedicDiagnosticTable
          style="max-width: 400px"
          dense
          dark
          :value="item.diagnostic"
          :width-columns="['80%', '20%']"
        />
      </v-tooltip>
    </template>
  </List>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import { STATUS } from '@/utils/constants';

import ListFactory from '@/components/crud/ListFactory';
import Select from '@/components/controls/Select.vue';
import CategorySelect from '@/components/controls/structures/CategorySelect.vue';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';
import MedicGroupSelect from '@/components/controls/structures/MedicGroupSelect.vue';
import MedicDiagnosticTable from '../components/MedicDiagnosticTable.vue';
import { messages, entity, filters } from '../entity';
import { date as dateFormatter, name } from '@/utils/convert';
import { GENDER_NOMINATIVE } from '@/utils/constants.js';
import { maskTextPhone } from '@/utils/masks';

const List = ListFactory({
  storeModule: 'MEDBLOCK/MEDICS',
  messages,
  entity,
  filters,
  viewRoute: 'medblock:medics:item',
});

export default {
  components: {
    List,
    Select,
    CategorySelect,
    OrganizationSelect,
    MedicGroupSelect,
    MedicDiagnosticTable,
  },

  mixins: [waitable],

  data: () => ({
    statusItems: STATUS,
  }),

  computed: {
    ...mapGetters('MEDBLOCK/MEDICS', ['medicalRoles', 'medicalRoleByKey']),
    GENDER_NOMINATIVE: () => GENDER_NOMINATIVE,
  },

  created() {
    this.$loadingNotify(
      this.fetchMedicalRolesPreviews(),
      'fetchMedicalRolesPreviews',
      'Произошла ошибка загрузки медицинских ролей',
    );
    this.$loadingNotify(
      this.fetchOrgCategoryPreviews(),
      'fetchingOrgCategories',
      'Произошла ошибка загрузки медицинских организаций',
    );
  },

  methods: {
    ...mapActions('MEDBLOCK/MEDICS', [
      'fetchMedicalRolesPreviews',
      'fetchOrgCategoryPreviews',
    ]),

    name,
    dateFormatter,
    maskTextPhone,

    createModal() {
      this.$openModal('medblock/medics/form', {
        value: null,
      });
    },
  },
};
</script>

<style>
/* tooltip renderer in root component */
.tooltip--show-white-space {
  white-space: pre-line;
}
</style>
