<template>
  <v-list-item
    :input-value="true"
    :color="color"
    class="pa-0"
    style="min-height: auto"
  >
    <v-list-item-title class="font-weight-bold">{{ text }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: 'ColoredText',
  props: {
    text: { type: String, default: '' },
    color: { type: String, default: 'currentColor' },
  },
};
</script>
