<template>
  <ItemPageLayout back-route="structures:host_groups" :title="singleItem.name">
    <Card title="Данные группы" dense>
      <DefinitionList :value="computedEntity" show-empty-values />
      <template #action>
        <v-btn
          v-if="$can('HSTG_U')"
          depressed
          small
          text
          color="primary"
          @click="editModal"
        >
          Редактировать
        </v-btn>
      </template>
    </Card>

    <template #aside>
      <ActionsMenu>
        <ActionsMenuDelete
          v-if="$can('HSTG_D')"
          :id="singleItem.id"
          store-module="STRUCTURES/HOST_GROUPS"
          back-route="structures:host_groups"
          title="Удалить группу ПАК"
          description="Вы уверены, что хотите удалить группу?"
          :disabled="!!singleItem.itemsCount"
          :subtitle="
            singleItem.itemsCount ? 'Нельзя удалить, в группе есть ПАК' : null
          "
        />
      </ActionsMenu>
    </template>

    <template v-if="$can('HST_R')" #innerList>
      <List
        search-filter
        :available-actions="{
          showAction: $can('HST_RD'),
          createAction: $can('HST_C'),
        }"
        @item:create="openCreateHost"
      >
        <template #filters="query">
          <v-col cols="12" sm="4" md="3">
            <v-select
              v-model="query.isActive"
              label="Статус"
              :items="statusItems"
              item-value="value"
              item-text="name"
              hide-details
              outlined
              dense
              :menu-props="{ offsetY: true }"
            />
          </v-col>
        </template>

        <template #table.item.nameLicense="{ item }">
          <b>{{ item.name }}</b>
          <br />
          {{ item.license }}
        </template>

        <template #table.item.org_releasePoint="{ item }">
          {{ item.organization.shortName || item.organization.name }}
          <br />
          {{ item.releasePoint.address || '#' + item.releasePoint.id }}
        </template>

        <template #table.item.isActive="{ item }">
          <Indicator
            :active="item.isActive"
            :tooltip="item.isActive ? 'Активен' : 'Неактивен'"
          />
        </template>

        <template #table.item.isOpenpoint="{ item }">
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon
                :color="item.isOpenpoint ? 'green lighten-2' : 'red'"
                v-on="on"
              >
                mdi-lock{{ item.isOpenpoint ? '-open' : '' }}-outline
              </v-icon>
            </template>
            {{ item.isOpenpoint ? 'Открытая' : 'Закрытая' }}
          </v-tooltip>
        </template>

        <template #table.item.isNotPrint="{ item }">
          <Indicator
            :active="item.isNotPrint"
            active-color="red"
            passive-color="grey lighten-2"
            :tooltip="item.isNotPrint ? item.notPrintComment : 'Автоматическая'"
          />
        </template>

        <template #table.item.isNotReprint="{ item }">
          <Indicator
            :active="item.isNotReprint"
            active-color="red"
            passive-color="grey lighten-2"
            :tooltip="item.isNotReprint ? item.notPrintComment : 'Разрешено'"
          />
        </template>
      </List>
    </template>
  </ItemPageLayout>
</template>

<script>
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';
import ItemPageLayout from '@/components/layouts/ItemPageLayout';
import DefinitionList from '@/components/DefinitionList';
import ActionsMenuDelete from '@/components/ActionsMenuDelete';
import Card from '@/components/ui/Card';
import Indicator from '@/components/Indicator.vue';
import ActionsMenu from '@/components/ui/ActionsMenu';
import LinkButton from '@/components/controls/buttons/LinkButton';
import { STATUS } from '@/utils/constants.js';

import { entity } from '../entity';

// List section code
import ListFactory from '@/components/crud/ListFactory';
import {
  messages,
  entityEmbedded as embedEntity,
  filters,
} from '@/modules/structures/hosts/entity';
import { mapActions } from 'vuex';
const List = ListFactory({
  storeModule: 'STRUCTURES/EMBEDDED_HOSTS_LIST',
  messages,
  entity: embedEntity.filter(val => !['org'].includes(val.key)),
  filters,
  viewRoute: 'structures:hosts:item',
  embedded: true,
});

export default {
  components: {
    ItemPageLayout,
    DefinitionList,
    Card,
    ActionsMenu,
    ActionsMenuDelete,

    List,
    Indicator,
  },
  mixins: [routeGuardMixin],

  computed: {
    computedEntity() {
      if (!this.singleItem) return [];

      const table = entity
        .filter(el => !el.hideInCard)
        .map(({ key, label, convert }) => ({
          label,
          value: convert ? convert(this.singleItem) : this.singleItem[key],
        }));

      const org = this.singleItem?.organization;
      org &&
        table.push({
          label: 'Организация',
          component: LinkButton,
          props: {
            text: org.name || `#${org.id}`,
            action: this.$can('ORG_RD'),
            to: {
              name: 'structures:organizations:item',
              params: { id: org.id },
            },
          },
        });

      return table;
    },
    statusItems: () => STATUS,
  },

  beforeMount() {
    // we need to reset filters on each 'item page' mount, cut those are being
    // saved in store
    this.resetListFilters();
  },

  methods: {
    ...mapActions('STRUCTURES/EMBEDDED_HOSTS_LIST', ['resetListFilters']),

    editModal() {
      this.$openModal('structures/hostGroupForm', {
        id: this.singleItem.id,
        value: this.singleItem,
      });
    },
    openCreateHost() {
      const id = this.singleItem.id;
      this.$openModal('structures/hostForm', {
        predefinedValues: {
          orgId: this.singleItem.organization.id,
          groupIds: [this.singleItem.id],
        },
        storeForCreating: 'STRUCTURES/EMBEDDED_HOSTS_LIST',
        onSubmit() {
          this.$store.dispatch(
            'STRUCTURES/HOST_GROUPS_ITEM/singleItemUpdate',
            id,
          );
        },
      });
    },
  },
};
</script>
