import env from '@/plugins/env';
import resourceServiceFactory from '@/utils/resourceServiceFactory';
import { createHTTP } from '../api';

export const notifications = createHTTP(
  `${env.get('VUE_APP_NOTIFICATIONS_API')}/v3`,
);

export default {
  ...resourceServiceFactory(notifications, 'profile', ['put']),
  getProfilePreviews(params) {
    return notifications
      .get('/profiles/previews', { params })
      .then(res => res.data);
  },

  ...resourceServiceFactory(notifications, 'template-sets'),

  getTemplateLocals() {
    return notifications.get('/template-sets-locals').then(res => res.data);
  },

  // subscription
  ...resourceServiceFactory(notifications, 'subscription'),
  putGroupHostToSubscription(subscriptionId, list) {
    return notifications
      .put(`/subscriptions/${subscriptionId}/groups/hosts`, {
        hostGroupsIds: list,
      })
      .then(res => res.data);
  },
  deleteGroupHostToSubscription(subscriptionId, list) {
    return notifications.delete(
      `/subscriptions/${subscriptionId}/groups/hosts/`,
      { data: { hostGroupsIds: list } },
    );
  },

  getMySubscriptions() {
    return notifications
      .get(`/subscriptions/accounts/my`)
      .then(res => res.data);
  },

  getAccountSubscriptions(id) {
    return notifications
      .get(`/subscriptions/accounts/${id}`)
      .then(res => res.data);
  },

  putGroupsEmployeesToSubscription(subscriptionId, list) {
    return notifications
      .put(`/subscriptions/${subscriptionId}/groups/employees`, {
        employeeGroupsIds: list,
      })
      .then(res => res.data);
  },
  deleteGroupsEmployeesFromSubscription(subscriptionId, list) {
    return notifications.delete(
      `/subscriptions/${subscriptionId}/groups/employees/`,
      { data: { employeeGroupsIds: list } },
    );
  },
  ...resourceServiceFactory(notifications, 'host-verifications'),
};
