// api
import structApi from '@/api/services/structures';
import integrationsApi from '@/api/services/integrations';
import medrecApi from '@/api/services/medrec';
import gateApi from '@/api/services/gate';

// babel wildcard needs explicit paths
import * as subModules from './submodules';

import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';
import env from '@/plugins/env';

const getters = {
  id: state => state.singleItem.id,

  extrasImportTemplateURL: _ =>
    env.get('VUE_APP_INTEGRATIONS_API') +
    '/v3/employees/extras/import/template',
  medicalTestsImportTemplateURL: _ =>
    process.env.VUE_APP_MEDREC_API + '/v3/medical_tests/import/template',
};

const actions = {
  async updateGroups({ getters, dispatch }, groups) {
    const data = await structApi.organizationUpdateGroups(getters.id, groups);
    await dispatch('singleItemUpdate');
    return data;
  },

  async updateHolding({ getters, dispatch }, holdingId) {
    try {
      await structApi.organizationUpdateHolding(getters.id, holdingId);
      dispatch('singleItemUpdate');
    } catch (error) {
      if (error.response.status === 400) {
        throw new Error(error.response.data.inner[0].errors.uniq);
      } else throw error;
    }
  },

  async updateCategory({ getters, dispatch }, categoryId) {
    await structApi.organizationUpdateCategory(getters.id, categoryId);
    dispatch('singleItemUpdate');
  },

  async changeStatus({ getters, dispatch }, isActive) {
    const action = isActive ? 'organizationEnable' : 'organizationDisable';
    await structApi[action](getters.id);
    dispatch('singleItemUpdate');
  },

  importExtras({ getters }, file) {
    return integrationsApi.importEmployeeExtras(getters.id, file);
  },
  importMedicalTests({ getters }, file) {
    return medrecApi.importEmployeeMedicalTests(getters.id, file);
  },

  resetAgreements({ getters }) {
    return gateApi.resetAgreements(getters.id);
  },
};

export default createModule(
  crudSingleFactory({
    fetchMethod: structApi.organizationGet,
    updateMethod: structApi.organizationUpdate,
    deleteMethod: structApi.organizationDelete,
    linkedList: 'STRUCTURES/ORGANIZATION_LIST',
  }),
  ...Object.values(subModules),
  { getters, actions },
);
