<template>
  <div
    class="scenario__labels__icon"
    :style="[{ backgroundColor: `rgba(${color})` }]"
  >
    <v-icon>mdi-alert-circle-outline</v-icon>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.scenario__labels__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  align-self: flex-end;
  position: absolute;
  margin-top: -12px;
  margin-right: -12px;
}
</style>
