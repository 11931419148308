<template>
  <v-btn
    class="goto"
    :class="{ 'goto--active': isActive }"
    :fixed="isActive"
    bottom
    right
    fab
    dark
    large
    color="primary"
    @click="handleScrollTop"
  >
    <v-icon dark size="26">fa-arrow-up</v-icon>
  </v-btn>
</template>

<script>
import { Sentry } from '@/plugins/sentry';
export default {
  data: () => ({
    isActive: false,
  }),
  created() {
    window.addEventListener('scroll', this.checkOffset);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.checkOffset);
  },

  methods: {
    checkOffset() {
      this.isActive = window.pageYOffset > 200;
    },

    handleScrollTop() {
      Sentry.captureMessage('Click "scroll to top"', scope => {
        scope.setLevel('info');
        scope.clearBreadcrumbs();
      });

      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.goto {
  display: none;
  position: sticky;
  float: right;
  right: 15px;
  bottom: 30px;
  margin-top: -15px;

  &--active {
    display: block;
  }
}
</style>
