<template>
  <v-app>
    <router-view />

    <TheNotSupportedBrowser />
    <TheCheckBrowserAccess />
    <TheServerPing />
    <TheOldAppVersion />
    <TheAuthModal v-if="softSignIn" />

    <notifications
      class="notifications--theme-admin"
      group="note"
      position="top right"
      classes="b-notification b-notification--info b-notification--theme-admin"
    />

    <!-- Группа уведомлений без возможности скрытия по клику -->
    <notifications
      class="notifications--theme-admin"
      group="fatalErrors"
      position="top right"
      classes="b-notification b-notification--info b-notification--theme-admin"
      :close-on-click="false"
    />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import TheServerPing from '@/components/TheServerPing';
import TheOldAppVersion from '@/components/TheOldAppVersion';
import TheAuthModal from '@/components/modals/TheAuthModal';
import TheNotSupportedBrowser from '@/components/modals/TheNotSupportedBrowser';
import TheCheckBrowserAccess from '@/components/modals/TheCheckBrowserAccess';

export default {
  components: {
    TheServerPing,
    TheOldAppVersion,
    TheAuthModal,
    TheNotSupportedBrowser,
    TheCheckBrowserAccess,
  },

  computed: {
    ...mapGetters('AUTH', ['softSignIn']),
  },
};
</script>

<style lang="scss" src="@/styles/master.scss" />
