<template>
  <Card title="Авторизация в АРМ" dense>
    <v-simple-table dense>
      <tbody>
        <tr>
          <td class="grey--text px-4 py-3">
            Авторизация через Госуслуги
            <v-tooltip right max-width="400px">
              <template #activator="{ on, attrs }">
                <v-btn small text icon v-bind="attrs" v-on="on">
                  <v-icon small>far fa-question-circle</v-icon>
                </v-btn>
              </template>
              <span style="width: 200px">
                Авторизация через Госуслуги будет действовать только для
                медработников
              </span>
            </v-tooltip>
          </td>
          <td class="px-4 py-3" style="width: 50px">
            <v-switch
              :input-value="esiaEnabled"
              class="ma-0 pa-0"
              inset
              :disabled="disabled"
              readonly
              hide-details
              :loading="$wait('changingStatus')"
              @mousedown="handlerChange"
            />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </Card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import Card from '@/components/ui/Card';

export default {
  name: 'EsiaAuth',
  components: { Card },
  mixins: [waitable],
  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', [
      'singleItem',
      'esiaEnabled',
    ]),
    disabled() {
      return (
        (this.esiaEnabled && !this.$can('OS_DE')) ||
        (!this.esiaEnabled && !this.$can('OS_EE'))
      );
    },
  },
  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', ['toggleEsia']),

    handlerChange() {
      if (this.disabled) {
        return;
      }
      const newStatus = !this.esiaEnabled;
      this.$openModal('prompt', {
        yes: 'Да',
        no: 'Отмена',
        title: 'Подтверждение',
        description: `Вы действительно хотите ${
          newStatus ? 'включить' : 'отключить'
        } авторизацию через Госуслуги?`,
        messages: {
          successfulAction: `Авторизация через Госуслуги успешно ${
            newStatus ? 'включена' : 'отключена'
          }`,
          errorAction: `Ошибка ${
            newStatus ? 'включения' : 'отключения'
          } авторизации через госуслуги`,
        },
        onSubmit: () =>
          this.$loading(this.toggleEsia(newStatus), 'changingStatus'),
      });
    },
  },
};
</script>
