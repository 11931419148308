import Vue from 'vue';

export default defaultMessages =>
  Vue.extend({
    props: {
      messages: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      message() {
        return key =>
          (this.messages && this.messages[key]) || defaultMessages[key];
      },
    },
  });
