import Bowser from 'bowser';
import Sentry from './sentry';

export const browser = Bowser.getParser(window.navigator.userAgent);

const chromiumVersion = '>=79';
const isSupportedBrowser = browser.satisfies({
  // Chromium based browsers
  chromium: chromiumVersion,
  chrome: chromiumVersion,
  edge: chromiumVersion,
  opera: chromiumVersion,
  yandex: chromiumVersion,
  vivaldi: chromiumVersion,

  // Browsers with own engine
  safari: '>=13',
  firefox: '>=66',
});

export const isUnsupportedBrowser = !isSupportedBrowser;

Sentry.setTag('unsupported_browser', isUnsupportedBrowser);
