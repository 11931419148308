import { Reports } from '../models';
import { compileReportSchemes } from '../utilities';

import fieldPresets from './fieldPresets';
import medJournal from './medJournals';
import summaries from './summaries';
import inspections from './inspections';
import stats from './stats';

export const reportSchemes: Reports = compileReportSchemes(
  {
    ...medJournal,
    ...inspections,
    ...stats,
  },
  fieldPresets,
);

export const summariesSchemes: Reports = compileReportSchemes(
  {
    ...summaries,
  },
  fieldPresets,
);
