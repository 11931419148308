<template>
  <ModernModalLayout size="medium" title="Удалить запись" close-label="Отмена">
    <v-form :disabled="$wait('submit')">
      <v-text-field
        v-model="innerValue"
        label="Укажите причину удаления"
        outlined
        :error-messages="getValidationErrors('innerValue')"
        @blur="handleFieldBlur('innerValue')"
      />
    </v-form>

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="error"
        :loading="$wait('submit')"
        @click="submit"
      >
        Удалить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  components: { ModernModalLayout },

  mixins: [validation, waitable],

  props: {
    handleSubmit: { type: Function, required: true },
  },

  data() {
    return {
      innerValue: '',
    };
  },

  validations() {
    return {
      innerValue: {
        required,
        minSymbolsLength: minLength(3),
        maxSymbolsLength: maxLength(100),
      },
    };
  },

  methods: {
    async submit() {
      if (!this.validate()) return;
      this.setServerValidationErrors([]);

      await this.$loadingNotify(
        this.handleSubmit(this.innerValue),
        'submit',
        'Произошла ошибка удаления медицинского значения',
        `Медицинское значение удалено`,
      )
        .then(() => this.$emit('close'))
        .catch(err => this.catchServerErrors(err, 'innerValue'));
    },
  },
};
</script>
