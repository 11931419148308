import gateApi from '@/api/services/gate';
import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

const actions = {
  async createSingleItem({ dispatch }, payload) {
    const response = await gateApi.createProfile(payload);
    dispatch('pushLinkedList', {
      action: 'listAddItem',
      payload: response,
    });
    return response;
  },

  // Дефолтные профили могут менять только избранные по отдельной ручке
  async changeDefaultSingleItem({ commit, dispatch }, data) {
    const response = await gateApi.editDefaultProfile(data);
    commit('singleItem', response);
    dispatch('pushLinkedList', {
      action: 'listUpdateItem',
      payload: response,
    });
  },
};

export default createModule(
  crudSingleFactory({
    fetchMethod: gateApi.getProfile,
    changeMethod: gateApi.editProfile,
    deleteMethod: gateApi.deleteProfile,
    linkedList: 'ADMIN/PROFILES',
  }),
  { actions },
);
