<template>
  <v-simple-table v-if="value.length">
    <template #default>
      <thead>
        <th class="pa-1 text-left">Время</th>
        <th class="pa-1 text-left">Действие</th>
        <th class="pa-1 text-left">Осмотр</th>
      </thead>
      <tbody>
        <tr
          v-for="(event, eventIndex) in value"
          :key="eventIndex"
          :class="classByEvent(event)"
        >
          <td class="pa-1">
            {{ getTime(event.timestamp) }}
          </td>
          <td class="pa-1">{{ event.name || '–' }}</td>
          <td class="pa-1">
            {{ event.inspectionId ? '#' + event.inspectionId : '–' }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>

  <v-alert v-else type="info" class="ma-0" outlined>Отсутствуют</v-alert>
</template>

<script>
import { getTime } from '@/utils/convert';
import { mapGetters } from 'vuex';
import { COLOR_BY_EVENT_TYPE } from '@/utils/constants';

export default {
  props: {
    value: { required: true, type: Array },
  },

  computed: {
    ...mapGetters(['themeIsDark']),
  },

  methods: {
    getTime,

    classByEvent(event) {
      const light = this.themeIsDark ? 'darken' : 'lighten';
      const { color, lightness } = COLOR_BY_EVENT_TYPE[event.type] || {};
      return color ? [color, `${light}-${lightness}`] : [];
    },
  },
};
</script>
