<template>
  <div
    class="history-photo grey"
    :class="themeIsDark ? 'darken-2' : 'lighten-2'"
  >
    <v-img
      v-if="photo && !error"
      class="history-photo__img"
      :src="photo"
      contain
      @error="error = true"
    >
      <div class="history-photo__desc" :style="textStyle">Эталонное фото</div>
    </v-img>
    <div
      v-else
      class="text-h5 d-flex justify-center align-center text-center pa-1"
      style="height: 100%"
    >
      <span v-if="!error">Нет фото</span>
      <span v-else>Ошибка загрузки фото</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    photo: {
      required: true,
      type: String,
      default: null,
    },
    date: {
      required: true,
      type: String,
      default: null,
    },
    timezoneOffset: {
      type: Number,
      default: null,
    },
    fontSize: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      error: false,
    };
  },

  computed: {
    ...mapGetters(['themeIsDark']),

    textStyle() {
      const styles = [];

      if (this.fontSize) {
        styles.push({
          fontSize: this.fontSize,
        });
      }

      return styles;
    },
  },
};
</script>

<style lang="scss" scoped>
.history-photo {
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
}
.history-photo__img {
  height: 100%;
}

.history-photo__desc {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.4);
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.2;
  font-size: 1.3rem;
}
</style>
