<template>
  <ModernModalLayout
    size="medium"
    title="Добавление нового API ключа"
    close-label="Отмена"
  >
    <div class="text-body-2 pb-2">
      API ключ будет отправлен на почту {{ email }}
    </div>

    <v-radio-group
      v-model="dueDate"
      label="Срок действия:"
      class="mt-0 pt-0"
      row="row"
      hide-details="auto"
      :disabled="$wait('addingAppKey')"
      :error-messages="getValidationErrors('dueDate')"
      @blur="handleFieldBlur('dueDate')"
    >
      <v-radio label="Бессрочно" value="permanently" />
      <v-radio label="1 год" value="oneYear" />
    </v-radio-group>

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :loading="$wait('addingAppKey')"
        @click="submit"
      >
        Добавить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import validation from '@/utils/validation';
import { required } from 'vuelidate/lib/validators';
import waitable from '@/utils/mixins/waitable';
import { currentDateTime } from '@/utils/helpers';

export default {
  components: { ModernModalLayout },

  mixins: [validation, waitable],

  props: {
    id: { type: Number, required: true },
    email: { type: String, required: true },
  },

  data: () => ({
    dueDate: 'oneYear',
  }),

  validations: {
    dueDate: { required },
  },

  created() {},

  methods: {
    async submit() {
      if (!this.validate()) return;

      const expiresAt =
        this.dueDate === 'permanently'
          ? null
          : currentDateTime().plus({ years: 1 }).toISO();

      await this.$loadingNotify(
        this.$store.dispatch('ADMIN/APPLICATIONS_ITEM/addApiKey', expiresAt),
        'addingAppKey',
        'Произошла ошибка добавления API ключа',
        `API ключ успешно добавлен`,
      );

      this.$emit('close');
    },
  },
};
</script>
