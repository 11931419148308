<template>
  <v-card>
    <v-card-title>
      <h5 class="flex-fill">{{ LimitTypeName[value.type] }}</h5>
      <v-card-actions class="pa-0">
        <IconButton
          v-if="$can('LM_D')"
          icon="fa-trash"
          title="Удалить"
          @click="openModalDelete"
        />
        <IconButton
          v-if="$can('LM_U')"
          icon="fa-edit"
          title="Изменить"
          @click="openModalEdit"
        />
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-switch
                v-model="switchValue"
                class="ml-2 mt-0 pa-0"
                inset
                :disabled="
                  switchIsLoading || value.isEnabled
                    ? !$can('LM_EN')
                    : !$can('LM_DS')
                "
                hide-details
                :loading="switchIsLoading"
                @change="changeIsEnableTo(!value.isEnabled)"
              />
            </div>
          </template>
          <span>
            {{ value.isEnabled ? 'Деактивировать' : 'Активировать' }}
          </span>
        </v-tooltip>
      </v-card-actions>
    </v-card-title>
    <v-card-text>
      <LimitCardType :value="value" />
      <LimitCardNotifications
        :type="value.type"
        :value="value.settings.notifications"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { LimitTypeName } from '@/api/services/limits.interfaces';
import waitable from '@/utils/mixins/waitable';

// components go below
import LimitCardType from '@/components/limits/LimitCardType';
import LimitCardNotifications from '@/components/limits/LimitCardNotifications';
import IconButton from '@/components/controls/buttons/IconButton';
import { xhrErrorMessage } from '@/utils/helpers';

export default {
  components: {
    IconButton,
    LimitCardType,
    LimitCardNotifications,
  },
  mixins: [waitable],
  props: {
    value: { required: true, type: Object },
    groupp: { type: Boolean, required: true },
  },
  data() {
    return {
      switchValue: this.value?.isEnabled || false,
      modalIsVisible: false,
    };
  },
  computed: {
    LimitTypeName: () => LimitTypeName,

    switchIsLoading() {
      return this.modalIsVisible || this.$wait('changeIsEnableTo');
    },
  },

  watch: {
    'value.isEnabled': {
      handler(val) {
        this.switchValue = val;
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('STRUCTURES/LIMITS', ['enable', 'disable', 'remove']),

    changeIsEnableTo(newStatus) {
      const onSubmit = () => {
        const action = newStatus ? this.enable : this.disable;
        this.$loadingNotify(
          action(this.value.id),
          'changeIsEnableTo',
          err => {
            // если вернулась ошибка, возвращаем значение свитчера
            this.switchValue = this.value.isEnabled;

            this.$notify({
              group: 'note',
              type: 'error',
              title: 'Не удалось изменить статус активации лимита',
              text: xhrErrorMessage(err),
            });
          },
          `Лимит успешно ${newStatus ? 'активирован' : 'деактивирован'}`,
        );
      };

      this.modalIsVisible = true;
      this.$openModal(
        'prompt',
        {
          title: `${newStatus ? 'Активация' : 'Деактивация'} лимита`,
          description: `Вы точно хотите ${
            newStatus ? 'активировать' : 'деактивировать'
          } лимит?`,
          messages: {
            successfulAction: `Лимит успешно ${
              newStatus ? 'активирован' : 'деактивирован'
            }`,
            errorAction: `Произошла ошибка ${
              newStatus ? 'активации' : 'деактивации'
            } лимита`,
          },
          onSubmit,
        },
        {
          close: () => {
            // Если действие отменили, возвращаем значение свитчера
            if (!this.$wait('changeIsEnableTo'))
              this.switchValue = this.value.isEnabled;
            this.modalIsVisible = false;
          },
        },
      );
    },

    openModalDelete() {
      this.$openModal('prompt', {
        title: 'Удалить лимит',
        description: 'Вы точно хотите удалить лимит?',
        messages: {
          successfulAction: 'Лимит успешно удален',
          errorAction: 'Произошла ошибка удаления лимита',
        },
        onSubmit: () => this.remove(this.value.id),
      });
    },

    openModalEdit() {
      this.$openModal('changeLimit', {
        orgOrGroupId: this.value.orgOrGroupId,
        type: this.value.type,
        value: this.value,
        groupp: this.groupp,
        onSubmit: () => this.init(),
      });
    },
  },
};
</script>
