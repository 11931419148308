import { Line } from 'vue-chartjs';
import { generateColor } from '@/utils/helpers';

// https://github.com/apertureless/vue-chartjs
export default {
  extends: Line,
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    htmlLegend: null,
  }),
  mounted() {
    // create datasets (a set of lines on this chart)
    // set by default one line - sum of all inspections
    const datasets = [
      {
        label: 'Всего осмотров',
        showLine: true,
        fill: false,
        borderColor: '#287BD8',
        backgroundColor: '#287BD8',
        data: [],
      },
    ];
    // here we add all dates from data
    const labels = [];

    this.value.forEach(item => {
      const name = item.result + ' (' + item.type + ')';
      if (!labels.includes(item.date)) labels.push(item.date);

      let dataset = datasets.find(item => item.label === name);
      // if we no have added dataset for this inspection
      if (!dataset) {
        dataset = {
          label: name,
          showLine: true,
          fill: false,
          borderColor: generateColor(datasets.length),
          backgroundColor: generateColor(datasets.length),
          data: [],
        };
        datasets.push(dataset);
      }

      const labelIndex = labels.indexOf(item.date);

      dataset.data[labelIndex] =
        (dataset.data[labelIndex] || 0) + Number(item.count);

      // increase count in "all inspections line"
      datasets[0].data[labelIndex] =
        (datasets[0].data[labelIndex] || 0) + Number(item.count);
    });

    // fill empty values of array with zeros,
    // otherwise line on graph will be interrupted
    datasets.forEach(item => {
      item.data = Array(labels.length)
        .fill(0)
        .map((_, index) => {
          if (item.data[index]) return item.data[index];
          return 0;
        });
    });

    this.renderChart(
      {
        labels,
        datasets,
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Даты',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Количество осмотров',
              },
            },
          ],
        },
      },
    );
  },
};
