export const SINGLE_STATUSES = ['not_loaded', 'loading', 'loaded', 'error'];

const notImplemented = async () => {
  throw new Error('Action is not implemented');
};

export default function fetchSingleFactory({
  fetchMethod = notImplemented,
  // createMethod = notImplemented,
  updateMethod = notImplemented,
  changeMethod = notImplemented,
  deleteMethod = notImplemented,
  linkedList = false,
} = {}) {
  return {
    state: {
      // items
      singleItem: {},
      singleStatus: SINGLE_STATUSES[0],
      singleError: null,
    },
    getters: {
      // items
      singleItem: state => state.singleItem,
      singleStatus: state => state.singleStatus,
      singleIsLoading: state =>
        state.singleStatus === SINGLE_STATUSES[0] ||
        state.singleStatus === SINGLE_STATUSES[1],
    },
    mutations: {
      // items
      singleFetching: state => {
        state.singleItem = {};
        state.singleStatus = SINGLE_STATUSES[1];
        state.singleError = null;
      },
      singleFetched: (state, data) => {
        state.singleItem = data;
        state.singleStatus = SINGLE_STATUSES[2];
        state.singleError = null;
      },
      singleErrorFetched: (state, error) => {
        state.singleItem = {};
        state.singleStatus = SINGLE_STATUSES[3];
        state.singleError = error;
      },
      singleRemoved: state => {
        state.singleItem = {};
        state.singleStatus = SINGLE_STATUSES[0];
        state.singleError = null;
      },
      singleItem: (state, value) => (state.singleItem = value),
      singleItemUpdate: (state, value) =>
        (state.singleItem = { ...state.singleItem, ...value }),
    },
    actions: {
      fetchSingle: async ({ commit }, id) => {
        try {
          commit('singleFetching');
          const response = await fetchMethod(id);
          commit('singleFetched', response);
        } catch (error) {
          commit('singleErrorFetched', error);
          throw error;
        }
      },
      async updateSingleItem({ dispatch, commit }, data) {
        const response = await updateMethod(data);
        commit('singleItem', response);

        if (linkedList)
          dispatch('pushLinkedList', {
            action: 'listUpdateItem',
            payload: response,
          });

        return response;
      },

      // we might sometimes call this function from component, where there is no
      // 'singleItem' and therefore no 'getters.singleItem.id', so we use 'id'
      // param as JUST A FALLBACK, by default there should be NO id param
      async singleItemUpdate({ getters, commit, dispatch }, id) {
        // each time 'singleItem' gets updated we fetch updated version of
        // singleItem and set it to corresponding item in 'linkedList'.
        // This is all to avoid hundreds of 'cache' problems. Not perfect
        // solution at all since we might be requesting same thing multiple
        // times as user keeps working on it, but prevents us from having
        // shit lots of shitcode over the project.
        // NOTE: i am not sure if GETting an item with ID always returns
        // same format object as it is in list, but if it isn't - it's our
        // backend to blame
        if (!fetchMethod) return;

        const response = await fetchMethod(id || getters.singleItem.id);
        commit('singleItemUpdate', response);

        if (linkedList) {
          dispatch('pushLinkedList', {
            action: 'listUpdateItem',
            payload: response,
          });
        }
      },
      async changeSingleItem({ dispatch, commit }, data) {
        // For PUT method
        const response = await changeMethod(data);
        commit('singleItem', response);

        if (linkedList)
          dispatch('pushLinkedList', {
            action: 'listUpdateItem',
            payload: response,
          });
      },
      async deleteSingleItem({ dispatch, commit }, id) {
        await deleteMethod(id);
        commit('singleRemoved');
        if (linkedList)
          dispatch('pushLinkedList', {
            action: 'listRemoveItem',
            payload: id,
          });
      },
      pushLinkedList({ commit }, { action, payload }) {
        if (typeof linkedList === 'string')
          commit(`${linkedList}/${action}`, payload, { root: true });
        else commit(action, payload);
      },
    },
  };
}
