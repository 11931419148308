<template>
  <List
    search-filter
    :available-actions="{
      showAction: $can('OG_RD'),
      createAction: $can('ORGG_C'),
    }"
    @item:create="formModal"
  >
    <template #table.item.name="{ item }">
      {{ item.name }}
      <v-chip v-if="item.isHolding" small>Холдинг</v-chip>
    </template>
    <template #filters="query">
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.isHolding"
          label="Холдинги"
          :items="booleanItems"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :menu-props="{ offsetY: true }"
        />
      </v-col>
    </template>

    <template #table.item.isPrivate="{ item }">
      <v-tooltip left>
        <template #activator="{ on }">
          <v-icon
            :color="!item.isPrivate ? 'green lighten-2' : 'red'"
            v-on="on"
          >
            mdi-lock{{ !item.isPrivate ? '-open' : '' }}-outline
          </v-icon>
        </template>
        {{ !item.isPrivate ? 'Публичная' : 'Приватная' }}
      </v-tooltip>
    </template>
  </List>
</template>

<script>
import { BOOLEANS } from '@/utils/constants.js';

import ListFactory from '@/components/crud/ListFactory';
import { messages, entity, filters } from '../entity';

const List = ListFactory({
  storeModule: 'STRUCTURES/ORGANIZATION_GROUPS',
  messages,
  entity,
  filters,
  viewRoute: 'structures:organization_groups:item',
});

export default {
  components: { List },
  computed: {
    booleanItems: () => BOOLEANS,
  },
  methods: {
    formModal() {
      this.$openModal('structures/orgGroupForm');
    },
  },
};
</script>
