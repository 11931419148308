<template>
  <ModernModalLayout persistent :title="modalTitle" :watch-for="form">
    <v-form :disabled="$wait('submit')">
      <v-row>
        <v-col :cols="12">
          <v-text-field
            v-model="form.surname"
            label="Фамилия"
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('form.surname')"
            @blur="validateField('form.surname')"
          />
        </v-col>
        <v-col :cols="12">
          <v-text-field
            v-model="form.name"
            label="Имя"
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('form.name')"
            @blur="validateField('form.name')"
          />
        </v-col>
        <v-col :cols="12">
          <v-text-field
            v-model="form.patronymic"
            label="Отчество"
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('form.patronymic')"
            @blur="validateField('form.patronymic')"
          />
        </v-col>
        <v-col :cols="12">
          <DatePicker
            v-model="form.dateOfBirth"
            label="Дата рождения"
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('form.dateOfBirth')"
            @blur="validateField('form.dateOfBirth')"
          />
        </v-col>
        <v-col :cols="12" class="py-0">
          <v-radio-group
            v-model="form.gender"
            label="Пол"
            class="mb-6"
            row
            hide-details="auto"
            :error-messages="getValidationErrors('form.gender')"
            @blur="validateField('form.gender')"
          >
            <v-radio value="MALE" label="Мужчина" />
            <v-radio value="FEMALE" label="Женщина" />
          </v-radio-group>
        </v-col>
        <v-col :cols="12">
          <MaskField
            v-model="form.snils"
            label="СНИЛС"
            outlined
            placeholder="000-000-000 00"
            first-format
            hide-details="auto"
            :mask="maskInputSnils"
            :error-messages="getValidationErrors('form.snils')"
            @blur="validateField('form.snils')"
          />
        </v-col>
        <v-col :cols="12">
          <v-text-field
            v-model="form.email"
            label="Email"
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('form.email')"
            @blur="validateField('form.email')"
          />
        </v-col>
        <v-col :cols="12">
          <PhoneField
            :key="phoneKey"
            v-model="form.phone"
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('form.phone')"
            @blur="validateField('form.phone')"
          />
        </v-col>
        <template v-if="!form.id">
          <v-col :cols="12">
            <Select
              v-model="form.roleKey"
              label="Роль"
              item-value="key"
              item-text="name"
              hide-details="auto"
              :items="medicalRoles"
              :error-messages="getValidationErrors('form.roleKey')"
              @blur="validateField('form.roleKey')"
            />
          </v-col>

          <v-col :cols="12">
            <v-divider class="my-2 py-2" />
            <OrganizationSelect
              v-model="form.orgId"
              hide-details="auto"
              medical
              access-level="full"
              :error-messages="getValidationErrors('form.orgId')"
              @blur="validateField('form.orgId')"
              @change="onOrgIdChange"
            />
          </v-col>
          <v-col :cols="12">
            <MedicGroupSelect
              v-model="form.groupIds"
              multiple
              hide-details="auto"
              :med-org-ids="[form.orgId]"
              :disabled="!form.orgId"
              :error-messages="getValidationErrors('form.groupIds')"
              @blur="validateField('form.groupIds')"
            />
          </v-col>
          <v-col :cols="12">
            <CategorySelect
              v-model="form.categoryIds"
              multiple
              hide-details="auto"
              :med-org-ids="[form.orgId]"
              :disabled="!form.orgId"
              :error-messages="getValidationErrors('form.categoryIds')"
              @blur="validateField('form.categoryIds')"
            />
          </v-col>
        </template>
      </v-row>
    </v-form>

    <template #actions:append>
      <v-btn
        depressed
        color="primary"
        :loading="$wait('submit')"
        @click="submit"
      >
        {{ actionText }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import {
  cyrillicNameRules,
  phone,
  date,
  snils,
  minAge,
  maxAge,
} from '@/utils/validators';
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import { maskTextPhone, maskInputSnils, unmaskSnils } from '@/utils/masks';
import authApi from '@/api/services/auth';

import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import Select from '@/components/controls/Select';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';
import MedicGroupSelect from '@/components/controls/structures/MedicGroupSelect.vue';
import CategorySelect from '@/components/controls/structures/CategorySelect';
import MaskField from '@/components/controls/MaskField.vue';
import DatePicker from '@/components/controls/DatePicker';
import PhoneField from '@/components/controls/PhoneField';

export default {
  components: {
    Select,
    OrganizationSelect,
    MedicGroupSelect,
    CategorySelect,
    ModernModalLayout,
    DatePicker,
    PhoneField,
    MaskField,
  },
  mixins: [validation, waitable],

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    form: {
      categoryIds: [],
      groupIds: [],
    },
    phoneKey: null,
    medicalOrganizations: [],
  }),

  validations: {
    form: {
      name: { required, ...cyrillicNameRules },
      surname: { required, ...cyrillicNameRules },
      patronymic: cyrillicNameRules,
      snils: { required, snils },
      email: { required, email },
      roleKey: { required },
      phone: { required, phone },
      orgId: { required },
      dateOfBirth: { required, date, minAge: minAge(18), maxAge: maxAge(100) },
      gender: { required },
    },
  },

  computed: {
    ...mapGetters('MEDBLOCK/MEDICS', ['medicalRoles', 'singleItem']),

    defaultOrg() {
      return this.medicalOrganizations.find(el => el.isDefault);
    },

    isCreate() {
      return !this.form?.id;
    },

    modalTitle() {
      return this.isCreate
        ? 'Создание медработника'
        : 'Редактирование медработника';
    },

    actionText() {
      return this.isCreate ? 'Создать' : 'Сохранить';
    },
    maskInputSnils: () => maskInputSnils,
  },

  watch: {
    'form.patronymic': {
      handler(val) {
        // So we don't send empty patronymic string to backend and get an err
        // tho it's probably the wrong place to do it, but it was late night
        // and i didn't remember where we are preprocessing the payload for
        // this component
        val === '' && (this.form.patronymic = null);
      },
    },
  },

  created() {
    // FIXME: ask backend to add filter by 'isDefault'
    // but for now request preview and pop first element from there
    this.$loadingNotify(
      this.$store
        .dispatch('STRUCTURES/fetchOrganizationPreviews', {
          page: 1,
          limit: 10000,
          isMedical: true,
        })
        .then(res => (this.medicalOrganizations = res.items)),
      'Произошла ошибка загрузки организаций',
    );

    this.fetchMedicalRolesPreviews();

    this.init(this.value?.id);
  },

  methods: {
    ...mapActions('MEDBLOCK/MEDICS', [
      'createItem',
      'updateItem',
      'fetchMedicalRolesPreviews',
    ]),

    async onOrgIdChange() {
      this.form.categoryIds = [];
      this.form.groupIds = [];
    },

    async init(id) {
      if (id) {
        try {
          this.form = JSON.parse(JSON.stringify(this.value.medicData));
        } catch (err) {
          console.error(err);
        }
      }

      if (this.form.phone) {
        this.phoneKey = Math.random();
        this.form.phone = maskTextPhone(this.form.phone);
      }

      if (this.isCreate)
        this.$set(this.form, 'orgId', this.defaultOrg?.id || null);
    },

    setEmailError(message) {
      this.setServerValidationErrors(
        [
          {
            properties: ['email'],
            errors: {
              name: message,
            },
          },
        ],
        'form',
      );
    },

    async checkExists({ email }) {
      const exists = await authApi.getExists({ email });
      if (exists.email) {
        this.setEmailError('Этот email уже занят в системе.');
        throw new Error('Ошибка валидации. Этот email уже занят в системе.');
      }
    },

    async checkAuthWays(medic) {
      if (!medic?.accountData?.authWays.includes('email_pwd')) {
        await this.init(medic.id);
        const errMessage =
          'Медработник не сможет авторизоваться по этому email, ' +
          'попробуйте другой';
        this.setEmailError(errMessage);
        throw new Error(errMessage);
      }
    },

    async submit() {
      if (!(await this.validate())) return;

      if (
        this.isCreate ||
        (!this.isCreate && this.form.email !== this.value?.medicData?.email)
      ) {
        try {
          await this.checkExists({ email: this.form.email });
        } catch (err) {
          console.error(err);
          return;
        }
      }

      const payload = {
        ...this.form,
        snils: !this.form.snils ? null : unmaskSnils(this.form.snils),
      };

      let action = this.createItem;
      if (!this.isCreate) {
        action = this.updateItem;
        delete payload.categoryIds;
        delete payload.groupIds;
        delete payload.roleKey;
      }

      await this.$loadingNotify(
        action(payload).then(data => this.checkAuthWays(data)),
        'submit',
        `Произошла ошибка ${
          this.isCreate ? 'создания' : 'редактирования'
        } медработника`,
        `Медработник успешно ${this.isCreate ? 'создан' : 'изменен'}`,
      )
        .then(() => this.$emit('close'))
        .catch(err => this.catchServerErrors(err, 'form'));
    },
  },
};
</script>
