import authApi from '@/api/services/auth';
import { createModule } from '@/utils/vuex/createModule';

const state = { cachedAccs: {} };

const getters = { cachedAccs: state => state.cachedAccs };

const mutations = {
  cachedAccs: (state, accs) => {
    accs.forEach(acc => (state.cachedAccs[acc.id] = acc));
  },
  clearCachedAccs: state => (state.cachedAccs = {}),
};

const actions = {
  async fetchAccountPreviews({ commit }, params) {
    const accounts = await authApi.getAccountPreviews(params);
    commit('cachedAccs', accounts.items);
    return accounts;
  },
  async fetchAccountPreviewsBy({ getters, commit }, params) {
    const missingIds = params.filter(id => !getters.cachedAccs[id]);
    // do not fetch accounts that are already in cache, fetch missing
    if (missingIds.length) {
      const response = await authApi.getAccountPreviewsBy(missingIds);
      commit('cachedAccs', response);
    }
    // no matter which accs we just fetched - return fetched and cached accs
    // merged together like if it was normal 'fetcherBy' func
    const accounts = params.map(id => getters.cachedAccs[id]);
    return accounts;
  },
  clearCachedAccs({ commit }) {
    commit('clearCachedAccs');
  },
};

export default createModule({ state, getters, mutations, actions });
