import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import RouteGuard from '@/components/RouteGuard';
import DOCS_OUTGOING from './list.store';
import DOCS_OUTGOING_ITEM from './item.store';

export default {
  namespaced: true,
  store: {
    DOCS_OUTGOING,
    DOCS_OUTGOING_ITEM,
  },
  routes: [
    {
      path: 'documents-outgoing',
      name: 'documents-outgoing',
      component: () => import('./list.view.vue'),
      meta: {
        action: 'MR_PF',
      },
    },
    {
      path: 'documents-outgoing/create',
      name: 'documents-outgoing:create',
      component: RouteGuard({
        storeModule: 'ADMIN/DOCS_OUTGOING_ITEM',
        component: () => import('./item.view.vue'),
        skipFetch: true,
      }),
      meta: {
        action: 'MR_PC',
      },
    },
    {
      path: 'documents-outgoing/:key',
      name: 'documents-outgoing:item',
      component: RouteGuard({
        storeModule: 'ADMIN/DOCS_OUTGOING_ITEM',
        component: () => import('./item.view.vue'),
        primaryKeyName: 'key',
        primaryKeyType: 'string',
      }),
      meta: {
        action: 'MR_PR',
      },
    },
  ],
  menu: [
    {
      title: 'Исходящие документы',
      action: 'MR_PF',
      name: 'documents-outgoing',
      priority: 5,
    },
  ],
} as ModuleDefinition;
