export const EXECUTIONS_STATUSES = {
  SUCCESS: {
    color: 'green',
    name: 'Успешно',
    description: 'Задача выполнилась успешно',
  },
  // this status hidden in backend
  // WAITING: {
  //   color: 'yellow',
  //   name: 'Ожидает выполнения',
  //   description: 'Задача ожидает своей очереди',
  // },
  IN_PROGRESS: {
    color: 'grey',
    name: 'Выполняется',
    description: 'Задача выполняется',
  },
  FAILURE: {
    color: 'red',
    name: 'Провалено',
    description: 'Во время выполнения задачи произошла ошибка',
  },
};

export const SCHEDULE_TYPES = [
  { value: 'DAILY', text: 'Ежедневно' },
  { value: 'WEEKLY', text: 'Еженедельно' },
  { value: 'MONTHLY', text: 'Ежемесячно' },
];

export const SCHEDULE_WEEKLY_UNITS = [
  { value: 1, text: 'Пн' },
  { value: 2, text: 'Вт' },
  { value: 3, text: 'Ср' },
  { value: 4, text: 'Чт' },
  { value: 5, text: 'Пт' },
  { value: 6, text: 'Сб' },
  { value: 7, text: 'Вс' },
];

export const SCHEDULE_MONTHLY_UNITS = Array(31)
  .fill(1)
  .map((_, pos) => ({ value: pos + 1, text: pos + 1 }));

export const RELATIVE_REFERENCE_POINTS = [
  { value: 'NOW', text: 'Прямо сейчас' },
  { value: 'START_OF_DAY', text: 'Начало дня' },
  { value: 'END_OF_DAY', text: 'Конец дня' },
  { value: 'START_OF_WEEK', text: 'Начало недели' },
  { value: 'END_OF_WEEK', text: 'Конец недели' },
  { value: 'START_OF_MONTH', text: 'Начало месяца' },
  { value: 'END_OF_MONTH', text: 'Конец месяца' },
];

export const RELATIVE_OFFSET_UNITS = [
  { value: 'hours', text: 'Часы', plural: ['час', 'часа', 'часов'] },
  { value: 'days', text: 'Дни', plural: ['день', 'дня', 'дней'] },
  { value: 'weeks', text: 'Недели', plural: ['неделя', 'недели', 'недель'] },
  { value: 'months', text: 'Месяцы', plural: ['месяц', 'месяца', 'месяцев'] },
];
