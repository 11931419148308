import reportsApi from '@/api/services/reports';
import { summariesSchemes } from '@/modules/reports/schemes';
import { createModule } from '@/utils/vuex/createModule';

const state = {
  summaries: null,
};
const getters = {
  summaries: state => state.summaries || [],
  summaryByKey: state => key =>
    (state.summaries || []).find(item => item.key === key),
};
const mutations = {
  summaries: (state, value) => {
    state.summaries = value;
  },
};
const actions = {
  async fetchSummaries({ state, commit }) {
    if (state.summaries) return;

    let data = await reportsApi.list('summary');
    data = data
      .filter(item => summariesSchemes[item.key])
      .map(item => {
        return { ...item, ...summariesSchemes[item.key] };
      })
      .sort((a, b) => (a.priority || 99) - (b.priority || 99));

    commit('summaries', data);
  },
};

export default createModule({
  state,
  getters,
  mutations,
  actions,
});
