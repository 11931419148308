<template>
  <ModernModalLayout
    size="large"
    title="Медицинские исследования"
    :watch-for="innerValue"
    dense-content
  >
    <div v-if="!settedTypes.length" class="text-center py-3 my-7">
      Медицинские исследования отсутствуют
    </div>

    <v-expansion-panels v-else accordion dense>
      <v-expansion-panel
        v-for="item in settedTypes"
        :key="item.type"
        :readonly="medicalTests[item.type].previous.length === 0"
      >
        <v-expansion-panel-header>
          <div>
            <span class="text-subtitle-1 font-weight-medium mr-2">
              {{ item.name }}:
            </span>
            <span class="mr-2">
              {{ medicalTests[item.type].value }} {{ item.unit }}
            </span>
            <span>
              (от {{ date(medicalTests[item.type].timestamp)
              }}{{ medicalTests[item.type].isExpired ? ', просрочен' : '' }})
            </span>

            <v-tooltip v-if="$can('EMT_U')" bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  v-on="on"
                  @click.stop="editMedicalTestModal(medicalTests[item.type])"
                >
                  <v-icon small>fa-pen</v-icon>
                </v-btn>
              </template>
              <span>Редактировать</span>
            </v-tooltip>
            <v-tooltip v-if="$can('EMT_D')" bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  v-on="on"
                  @click.stop="deleteMedicalTestModal(medicalTests[item.type])"
                >
                  <v-icon small>fa-trash</v-icon>
                </v-btn>
              </template>
              <span>Удалить</span>
            </v-tooltip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table dense>
            <tbody>
              <tr
                v-for="previousItem in medicalTests[item.type].previous"
                :key="previousItem.id"
              >
                <td class="grey--text" style="width: 30%">
                  {{ date(previousItem.timestamp) }}
                </td>
                <td style="width: 30%">
                  {{ previousItem.value }} {{ item.unit }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <template #actions:append>
      <v-btn
        v-if="$can('EMT_A')"
        class="px-5"
        depressed="depressed"
        color="success"
        @click="addMedicalTestModal"
      >
        Добавить исследование
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import waitable from '@/utils/mixins/waitable';
import { mapGetters, mapActions } from 'vuex';
import { date } from '@/utils/convert';

export default {
  components: { ModernModalLayout },

  mixins: [waitable],

  props: {
    types: { type: Array, required: true },
  },

  data: () => ({
    innerValue: {},
  }),

  computed: {
    ...mapGetters('EMPLOYEE', ['medicalTests']),

    settedTypes() {
      return this.types.filter(type => this.medicalTests[type.type] != null);
    },
  },

  methods: {
    ...mapActions('EMPLOYEE', [
      'fetchMedicalTests',
      'addMedicalTest',
      'changeMedicalTest',
      'deleteMedicalTest',
      'fetchHealthStateInfo',
    ]),
    date,

    changeValue(type, value) {
      this.innerValue = {
        ...this.innerValue,
        [type]: value || undefined,
      };
    },

    addMedicalTestModal() {
      const handleSubmit = async data => {
        await this.addMedicalTest(data);
        await this.fetchMedicalTests();
        await this.fetchHealthStateInfo();
      };

      this.$openModal('employees/medicalTest', {
        types: this.types,
        handleSubmit,
      });
    },

    editMedicalTestModal(value) {
      const handleSubmit = async data => {
        await this.changeMedicalTest({ id: value.id, data });
        await this.fetchMedicalTests();
        await this.fetchHealthStateInfo();
      };

      this.$openModal('employees/medicalTest', {
        value,
        types: this.types,
        handleSubmit,
      });
    },

    deleteMedicalTestModal({ id }) {
      const handleSubmit = async reason => {
        await this.deleteMedicalTest({ id, reason });
        await this.fetchMedicalTests();
        await this.fetchHealthStateInfo();
      };

      this.$openModal('employees/deleteMedicalTest', {
        handleSubmit,
      });
    },
  },
};
</script>
