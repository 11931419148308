var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ItemPageLayout',{attrs:{"back-route":"structures:organization_groups","title":_vm.singleItem.name},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('ActionsMenu',[(_vm.$can('ORGG_D'))?_c('ActionsMenuDelete',{attrs:{"id":_vm.singleItem.id,"store-module":"STRUCTURES/ORGANIZATION_GROUPS","back-route":"structures:organization_groups","title":("Удалить " + (_vm.item.isHolding ? 'холдинг' : 'группу организаций')),"description":("Вы уверены, что хотите удалить " + (_vm.item.isHolding ? 'холдинг' : 'группу организаций') + "?"),"disabled":!!_vm.singleItem.allMembersCount,"subtitle":_vm.singleItem.allMembersCount
            ? ("Нельзя удалить, к " + (_vm.item.isHolding ? 'холдингу' : 'группе') + " подключены организации")
            : ''}}):_vm._e()],1)]},proxy:true},(_vm.$can('ORG_R'))?{key:"innerList",fn:function(){return [(_vm.$can('LM_R') && !_vm.singleItem.isHolding)?_c('Limits',{attrs:{"org-or-group-id":_vm.singleItem.id,"groupp":""}}):_vm._e(),_c('List',{attrs:{"search-filter":"","available-actions":{
        showAction: _vm.$can('ORG_RD'),
        createAction: _vm.$can('ORG_C'),
      }},on:{"item:create":_vm.openCreate},scopedSlots:_vm._u([{key:"filters",fn:function(query){return [_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-select',{attrs:{"label":"Статус организаций","items":_vm.statusOrgsItems,"item-value":"value","item-text":"name","hide-details":"","outlined":"","dense":"","menu-props":{ offsetY: true }},model:{value:(query.isActive),callback:function ($$v) {_vm.$set(query, "isActive", $$v)},expression:"query.isActive"}})],1)]}},{key:"table.item.nameShortName",fn:function(ref){
      var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.name))]),(item.shortName)?_c('div',[_vm._v(_vm._s(("Сокр.: " + (item.shortName))))]):_vm._e()]}},{key:"table.item.inn_kpp",fn:function(ref){
      var item = ref.item;
return [_vm._v(" ИНН: "+_vm._s(item.inn)+" "),(item.kpp)?_c('div',[_vm._v("КПП: "+_vm._s(item.kpp))]):_vm._e()]}},{key:"table.item.isActive",fn:function(ref){
      var item = ref.item;
return [_c('Indicator',{attrs:{"active":item.isActive}})]}},{key:"table.item.isMedical",fn:function(ref){
      var item = ref.item;
return [_c('Round',{attrs:{"type":item.isMedical ? 'green lighten-2' : 'grey lighten-1'}})]}}],null,false,1237501080)})]},proxy:true}:null],null,true)},[_c('Card',{attrs:{"title":_vm.item.isHolding ? 'Данные холдинга' : 'Данные группы',"dense":""},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.$can('ORGG_U'))?_c('v-btn',{attrs:{"depressed":"","small":"","text":"","color":"primary"},on:{"click":_vm.editModal}},[_vm._v(" Редактировать ")]):_vm._e()]},proxy:true}])},[_c('DefinitionList',{attrs:{"value":_vm.computedEntity}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }