<template>
  <ModernModalLayout
    title="Завершение смены"
    close-label="Отмена"
    :persistent="disableSubmit"
    :close-disabled="disableSubmit"
  >
    <Spinner v-if="$wait('getTasks')" />

    <template v-else>
      <span class="mb-5">
        Вы уверены, что хотите завершить смену?
        <ul>
          <li v-if="closedInspections">
            За эту смену вы закрыли {{ closedInspections }}
            {{ pluralByType('inspections', closedInspections) }}
          </li>
          <li v-if="countTasks">
            В очереди на подпись {{ countTasks }}
            {{ pluralByType('docs', countTasks) }}
          </li>
        </ul>
      </span>

      <v-alert v-if="$wait('isSubmitting') || isSigning" type="info" text>
        <div v-if="isSigning">
          Сейчас выполняется подпись документов, для продолжения дождитесь
          завершения
        </div>
        <div v-if="$wait('isSubmitting')">
          Проверяем все ли документы подписаны
        </div>
        <template v-if="allCountDocs !== null">
          {{ countSignedDocs || 0 }} из {{ allCountDocs }}
        </template>
        <v-progress-linear indeterminate bottom absolute />
      </v-alert>
    </template>

    <template #actions:append>
      <v-btn
        color="primary"
        data-sentry-mark="medcab:stopShift"
        depressed
        :disabled="disableSubmit"
        @click="onSubmit"
      >
        Завершить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import plural from 'plural-ru';
import signApi from '@/api/services/sign';
import waitable from '@/utils/mixins/waitable';
import Spinner from '@/components/Spinner';
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import { isXhrError, xhrErrorMessage } from '@/utils/helpers';

export default {
  components: {
    Spinner,
    ModernModalLayout,
  },
  mixins: [waitable],

  props: {
    closedInspections: {
      type: Number,
      default: null,
    },
    closeShift: {
      type: Function,
      default: undefined,
    },
  },

  data: () => ({
    countTasks: null,
    error: null,
  }),

  computed: {
    ...mapGetters('SIGNER', ['isSigning', 'countSignedDocs', 'allCountDocs']),

    disableSubmit() {
      return (
        this.$wait('getTasks') || this.isSigning || this.$wait('isSubmitting')
      );
    },
  },

  watch: {
    isSigning(_, oldVal) {
      if (oldVal) this.fetchCountTasks();
    },
  },

  mounted() {
    if (!this.isSigning) this.fetchCountTasks();
  },

  methods: {
    pluralByType(type, count) {
      const params = {
        inspections: ['осмотр', 'осмотра', 'осмотров'],
        docs: ['документ', 'документа', 'документов'],
      };

      return plural(count, ...params[type]);
    },

    async fetchCountTasks() {
      const { count } = await this.$loading(
        signApi.getTasks(),
        'fetchCountTasks',
      );
      this.countTasks = count;
      return count;
    },

    async signForce() {
      const count = await this.fetchCountTasks();
      if (count)
        await this.$store.dispatch('SIGNER/executeTasks', {
          force: true,
        });
    },

    async onSubmit() {
      await this.$loading(this.signForce(), 'isSubmitting')
        .then(async () => {
          if (this.closeShift) await this.closeShift();
          this.$emit('close');
        })
        .catch(err => {
          this.error = isXhrError(err)
            ? xhrErrorMessage(err)
            : err?.message || err;
        });
    },
  },
};
</script>
