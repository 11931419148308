import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import authApi from '@/api/services/auth';
import { createModule } from '@/utils/vuex/createModule';
import { cloneDeep } from 'lodash';

const state = {
  role: null,
};

const getters = {
  id: state => state.singleItem.id,
  isAllBindingsAvailable: state =>
    state.role !== null ? state.role.actionTags.includes('ORG_AL') : false,
};

const mutations = {
  changeBindings: (state, bindings) => {
    state.singleItem.bindings = bindings || {};
  },

  addApiKey: (state, key) => {
    state.singleItem.keys.unshift(key);
  },

  deactivateApiKey: (state, keyId) => {
    const index = state.singleItem.keys.findIndex(item => item.id === keyId);
    if (index === -1) throw Error('Ключ не найден');

    const item = { ...state.singleItem.keys[index] };
    item.isRevoked = true;
    state.singleItem.keys.splice(index, 1, item);
  },

  muteByExpiredApiKey: (state, { keyId, expiresAt }) => {
    const index = state.singleItem.keys.findIndex(item => item.id === keyId);
    if (index === -1) throw Error('Ключ не найден');

    const item = { ...state.singleItem.keys[index] };
    item.ignoreExpiredErrorUntil = expiresAt;
    state.singleItem.keys.splice(index, 1, item);
  },

  role: (state, value) => (state.role = value),
  removeWaybillSettings: state => {
    const item = cloneDeep(state.singleItem);
    delete item.waybillSettings;
    state.singleItem = item;
  },
};

const actions = {
  async changeStatus({ getters, dispatch }, isEnabled) {
    const action = isEnabled ? 'accountEnable' : 'accountDisable';
    await authApi[action](getters.id);
    dispatch('singleItemUpdate');
  },

  async fetchRole({ commit, getters }) {
    const role = await authApi.getRole(getters.singleItem.roleKey);
    commit('role', role);
  },

  async changeRole({ getters, commit, dispatch }, roleKey) {
    await authApi.updateRole(getters.id, roleKey);
    // instead of messing with crudSingleFactory to override old obj instead of
    // merging it with new one it is better to just delete waybillSettings obj
    // on every role change, since it either comes from backend or not, it's
    // gonna always update.
    commit('removeWaybillSettings');
    dispatch('singleItemUpdate');
  },

  async changeProfile({ dispatch, getters }, profile) {
    await authApi.profileUpdate({ id: getters.id, profile });
    dispatch('singleItemUpdate');
  },

  async changePushSettings({ dispatch, getters }, settings) {
    await authApi.profilePushUpdate(getters.id, settings);
    dispatch('singleItemUpdate');
  },

  async changeBindings({ commit }, { id, bindings }) {
    const newBindings = {
      ...bindings,
      inspections: bindings.inspections.filter(item => item.parent?.id),
    };
    const response = await authApi.changeBindings(id, newBindings);
    commit('changeBindings', response);
  },

  async addApiKey({ commit, getters }, expiresAt) {
    const response = await authApi.addApiKey(getters.id, expiresAt);
    commit('addApiKey', response);
  },

  async deactivateApiKey({ commit }, keyId) {
    await authApi.deactivateApiKey(keyId);
    commit('deactivateApiKey', keyId);
  },

  async muteByExpiredApiKey({ commit }, { keyId, expiresAt }) {
    await authApi.ignoreExpiredErrorApiKey(keyId, expiresAt);
    commit('muteByExpiredApiKey', { keyId, expiresAt });
  },
};

export default createModule(
  crudSingleFactory({
    fetchMethod: authApi.appsGet,
    updateMethod: authApi.appUpdate,
    deleteMethod: authApi.accountDelete, // it's not error, it's pretty backend api
    linkedList: 'ADMIN/APPLICATIONS',
  }),
  { state, getters, mutations, actions },
);
