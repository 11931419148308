<template>
  <v-row>
    <v-col
      v-if="$can('RG_SE')"
      :cols="12"
      class="d-flex flex-column"
      style="gap: 24px"
    >
      <Card title="Состояние здоровья">
        <AsyncWrapper :handler="fetchHealthState">
          <HealthState :health-info="risks" :employee="employee" />
        </AsyncWrapper>

        <template #action>
          <div>
            <CardActionBtn
              v-if="showUpdateRisks"
              label="Пересчитать риски"
              @click="updateRisks"
            />
            <CardActionBtn label="Справка" @click="openEventsModal" />
          </div>
        </template>
      </Card>
    </v-col>
    <v-col :cols="6" class="d-flex flex-column" style="gap: 24px">
      <Card v-if="$can('EMT_R')" title="Медицинские исследования" dense>
        <AsyncWrapper :handler="fetchMedicalTestsData">
          <MedicalTestFields
            v-if="medicalTests"
            :value="medicalTests"
            :types="medicalTestTypes"
          />
        </AsyncWrapper>
        <template #action>
          <CardActionBtn
            v-if="$can('EMT_A')"
            label="Открыть"
            @click="editMedicalTestsModal"
          />
        </template>
      </Card>
      <Card v-if="$can('MPR_RD')" title="Профилактический осмотр" dense>
        <AsyncWrapper :handler="fetchMedicalPrevention">
          <MedicalPreventionFields :value="medicalPrevention" />
        </AsyncWrapper>
        <template #action>
          <CardActionBtn
            v-if="$can('MPR_PT')"
            label="Редактировать"
            @click="editMedicalPreventionModal"
          />
        </template>
      </Card>
    </v-col>
    <v-col :cols="6" class="d-flex flex-column" style="gap: 24px">
      <Card v-if="$can('SUR_GET')" title="Опросы" dense>
        <SurveyBlock
          :employee-id="employee.id"
          :organisation-id="employee.organization.id"
        />
      </Card>
      <Card v-if="$can('RG_RFC')" title="Факторы риска">
        <AsyncWrapper :handler="fetchHealthStateInfo">
          <div v-if="computedFactors.length">
            <div
              v-for="(factor, index) in computedFactors"
              :key="factor"
              class="grey--text"
              :class="{ 'mt-4': index !== 0 }"
            >
              {{ factor }}
            </div>
          </div>
          <NoDataAlert v-else> Факторы риска отсутствуют </NoDataAlert>
        </AsyncWrapper>
      </Card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import env from '@/plugins/env';
import medrecApi from '@/api/services/medrec';
import waitable from '@/utils/mixins/waitable';

import Card from '@/components/ui/Card';
import AsyncWrapper from '@/components/AsyncWrapper';
import HealthState from '@/modules/employees/components/HealthState';
import MedicalTestFields from '@/modules/employees/components/MedicalTestFields';
import SurveyBlock from '@/modules/employees/components/SurveyBlock';
import MedicalPreventionFields from '@/modules/employees/components/MedicalPreventionFields';
import NoDataAlert from '@/components/ui/NoDataAlert';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';

export default {
  components: {
    Card,
    AsyncWrapper,
    HealthState,
    MedicalTestFields,
    SurveyBlock,
    MedicalPreventionFields,
    NoDataAlert,
    CardActionBtn,
  },
  mixins: [waitable],

  props: {
    employee: { type: Object, required: true },
  },

  computed: {
    ...mapGetters('EMPLOYEE', [
      'risks',
      'medicalTests',
      'medicalPrevention',
      'riskFactors',
    ]),
    ...mapGetters('REGISTRY', ['medicalTestTypes']),
    ...mapGetters('AUTH', ['isRoot']),

    showUpdateRisks() {
      return (
        ['development', 'integration_v3'].includes(
          env.get('VUE_APP_ENVIRONMENT'),
        ) || this.isRoot
      );
    },

    // REVIEW: should it be in store getter or as computed prop of the component
    computedFactors() {
      return Object.values(this.riskFactors).filter(v => v);
    },
  },

  methods: {
    ...mapActions('EMPLOYEE', [
      'fetchRiskGroupEmployee',
      'fetchMedicalTests',
      'fetchMedicalPrevention',
      'fetchHealthStateInfo',
    ]),
    ...mapActions('REGISTRY', ['fetchMedicalTestTypes']),

    async fetchHealthState() {
      if (this.$wait('fetchHealthState')) return;

      await this.$loading(
        this.fetchRiskGroupEmployee({
          employeeId: this.employee.id,
          orgId: this.employee.organization.id,
        }),
        'fetchHealthState',
      );
    },

    updateRisks() {
      this.$openModal('prompt', {
        yes: 'Пересчитать',
        no: 'Отмена',
        title: 'Пересчитать риски',
        description:
          'Будут пересчитаны группы риска для работника. Это может занять некоторое время. Вы уверены?',
        onSubmit: () =>
          medrecApi.updateRiskGroup({
            orgId: this.employee.organization.id,
            employeeIds: [this.employee.id],
          }),
      });
    },

    async fetchMedicalTestsData() {
      if (this.$can('EMT_T'))
        this.$loadingNotify(
          this.fetchMedicalTestTypes(),
          'fetchMedicalTestTypes',
          'Не удалось загрузить типы медицинских данных работника',
        );
      await this.fetchMedicalTests();
    },

    editMedicalTestsModal() {
      this.$openModal('employees/medicalTests', {
        types: this.medicalTestTypes,
      });
    },

    editMedicalPreventionModal() {
      this.$openModal('employees/medicalPrevention', {
        value: this.medicalPrevention?.inspectionCheckupTakenAt,
        employeeId: this.employee.id,
      });
    },

    openEventsModal() {
      this.$openModal('employees/profEvents');
    },
  },
};
</script>
