import schedulerApi from '@/api/services/scheduler';

export default {
  state: {
    jobs: null,
  },

  getters: {
    jobs: state => state.jobs || [],
  },

  mutations: {
    jobs: (state, value) => (state.jobs = value),
  },

  actions: {
    async fetchJobs({ commit, getters }) {
      const jobs = await schedulerApi.getAccountJobs(getters.id);
      commit('jobs', jobs);
    },
  },
};
