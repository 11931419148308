import notifApi from '@/api/services/notifications';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { createModule } from '@/utils/vuex/createModule';
import { filters } from '../entity';
import { changePages } from '@/utils/vuex/changePages';

const state = {
  previews: {
    orgs: {},
    org_groups: {},
    points: {},
    employee_groups: {},
    host_groups: {},
  },
  orgPreviews: {},
};

const getters = {
  previews: state => state.previews,
  orgPreviews: state => state.orgPreviews,
  getOrgPreviewsById: (_, getters) => id =>
    getters.orgPreviews.find(org => org.id === id),
};

const mutations = {
  previews: (state, value) => (state.previews[value.key] = value.val),
  orgPreviews: (state, value) => (state.orgPreviews = value),
};

/** Загружает связанные поля по ID сущностей */

const actions = {
  async fetchList({ commit, getters, dispatch }, query) {
    await changePages(getters, commit, query);
    try {
      if (getters.listSearch && getters.listSearch.length < 2)
        throw Error('Не менее 2 символов в поле поиска');
      if (getters.listSearch?.length > 100)
        throw Error('Не более 100 символов в поле поиска');

      commit('listFetching');
      const response = await notifApi.subscriptionList(getters.listQuery);

      // Важно загрузить все данные до передачи в таблицу,
      // иначе она не обновит данные
      if (response.items.length) {
        // await dispatch('fetchFiltersPreviews', response.items);
        await dispatch('fetchOrgPreviews', response.items);
      }

      commit('listFetched', { ...response });
    } catch (error) {
      commit('listErrorFetched', error);
      throw error;
    }
  },

  async fetchOrgPreviews({ commit, dispatch }, items) {
    const orgIds = items.map(item => item.orgId);
    const previews = await dispatch(
      'STRUCTURES/fetchOrganizationPreviewsBy',
      orgIds,
      { root: true },
    );
    commit('orgPreviews', previews);
  },

  async fetchFiltersPreviews({ commit, dispatch }, list) {
    const REQUESTS = {
      orgs: 'fetchOrganizationPreviewsBy',
      org_groups: 'fetchOrganizationGroupPreviewsBy',
      points: 'fetchPointPreviewsBy',
      employee_groups: 'fetchEmployeeGroupPreviewsBy',
      host_groups: 'fetchHostGroupPreviewsBy',
    };

    // Раскрываем массивы фильтров до плоского списка
    const filters = list.map(item => item.filters).flat();

    // оставляем только уникальные айди
    const bindingIds = {
      orgs: new Set([
        ...filters
          .filter(item => ['org', 'med_org'].includes(item.parent.type))
          .map(item => item.parent.id),
      ]),
      org_groups: new Set([
        ...filters
          .filter(item => item.parent.type === 'org_group')
          .map(item => item.parent.id),
      ]),
      points: new Set(
        [...filters.map(item => item.points).flat(1)].filter(item => item),
      ),
      host_groups: new Set(
        [...filters.map(item => item.hostGroups).flat(1)].filter(item => item),
      ),
      employee_groups: new Set(
        [...filters.map(item => item.employeeGroups).flat(1)].filter(
          item => item,
        ),
      ),
    };

    await Promise.all(
      Object.entries(REQUESTS).map(async ([key, request]) => {
        // we send requests only if ids for structure instance present in
        // bindings object
        if (!bindingIds[key].size) return;

        const res = await dispatch(
          `STRUCTURES/${request}`,
          Array.from(bindingIds[key]),
          { root: true },
        );
        const val = Object.fromEntries(res.map(item => [item.id, item]));
        commit('previews', { key, val });
      }),
    );
  },
};

export default createModule(
  crudListFactory({
    deleteMethod: notifApi.subscriptionDelete,
    filters,
    defaultSort: { orderBy: 'id', orderType: true },
  }),
  { state, getters, mutations, actions },
);
