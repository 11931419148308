<template>
  <List
    search-filter
    :available-actions="{
      showAction: $can('EMG_RD'),
      createAction: $can('EMPG_C'),
    }"
    @item:create="formModal"
  >
    <template #filters="query">
      <v-col cols="12" sm="4" md="6">
        <OrganizationSelect v-model="query.orgIds" dense multiple />
      </v-col>
    </template>

    <template #table.item.nameOrg="{ item }">
      <b>{{ item.name }}</b>
      <br />
      <span>{{ item.organization.name }}</span>
    </template>
  </List>
</template>

<script>
import ListFactory from '@/components/crud/ListFactory';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';

import { messages, entity, filters } from '../entity';

const List = ListFactory({
  storeModule: 'STRUCTURES/EMPLOYEE_GROUPS',
  messages,
  entity,
  filters,
  viewRoute: 'structures:employee_groups:item',
});

export default {
  components: { List, OrganizationSelect },

  methods: {
    formModal() {
      this.$openModal('structures/employeeGroupForm');
    },
  },
};
</script>
