var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ItemPageLayout',{attrs:{"back-route":"structures:organization_categories","title":_vm.singleItem.name},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('ActionsMenu',[(_vm.$can('ORGC_D'))?_c('ActionsMenuDelete',{attrs:{"id":_vm.singleItem.id,"store-module":"STRUCTURES/ORGANIZATION_CATEGORIES","back-route":"structures:organization_categories","title":"Удалить категорию","description":"Вы уверены, что хотите удалить категорию?","subtitle":_vm.singleItem.orgsCount ? 'В категории есть организации' : null,"disabled":!!_vm.singleItem.orgsCount}}):_vm._e()],1)]},proxy:true},(_vm.$can('ORG_R'))?{key:"innerList",fn:function(){return [_c('List',{attrs:{"search-filter":"","available-actions":{
        showAction: _vm.$can('ORG_RD'),
      }},on:{"item:create":_vm.formModal},scopedSlots:_vm._u([{key:"filters",fn:function(query){return [_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-select',{attrs:{"label":"Статус организации","items":_vm.statusItems,"item-value":"value","item-text":"name","hide-details":"","outlined":"","dense":"","menu-props":{ offsetY: true }},model:{value:(query.isActive),callback:function ($$v) {_vm.$set(query, "isActive", $$v)},expression:"query.isActive"}})],1)]}},{key:"table.item.nameShortName",fn:function(ref){
      var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.name))]),(item.shortName)?_c('div',[_vm._v(_vm._s(("Сокр.: " + (item.shortName))))]):_vm._e(),(item.holding)?_c('div',[_vm._v(_vm._s(("Холдинг: " + (item.holding.name))))]):_vm._e()]}},{key:"table.item.inn_kpp",fn:function(ref){
      var item = ref.item;
return [_vm._v(" ИНН: "+_vm._s(item.inn)+" "),(item.kpp)?_c('div',[_vm._v("КПП: "+_vm._s(item.kpp))]):_vm._e()]}},{key:"table.item.isActive",fn:function(ref){
      var item = ref.item;
return [_c('Indicator',{attrs:{"active":item.isActive}})]}},{key:"table.item.isMedical",fn:function(ref){
      var item = ref.item;
return [_c('Round',{attrs:{"type":item.isMedical ? 'green lighten-2' : 'grey lighten-1'}})]}}],null,false,314017081)})]},proxy:true}:null],null,true)},[_c('Card',{attrs:{"title":"Данные категории","dense":""},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.$can('ORGC_U'))?_c('v-btn',{attrs:{"depressed":"","small":"","text":"","color":"primary"},on:{"click":_vm.editModal}},[_vm._v(" Редактировать ")]):_vm._e()]},proxy:true}])},[_c('DefinitionList',{attrs:{"value":_vm.computedEntity,"show-empty-values":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }