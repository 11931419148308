import integrationsApi from '@/api/services/integrations';

export default {
  state: {
    extras: {},
  },
  getters: {
    extras: state => state.extras,
  },
  mutations: {
    extras: (state, value) => (state.extras = value),
  },
  actions: {
    async fetchExtras({ commit }, id) {
      const extras = await integrationsApi.getEmployeeExtras(id);
      commit('extras', extras || {});
    },

    async changeExtras({ commit }, { orgId, employeeId, extras }) {
      const result = await integrationsApi.changeEmployeeExtras(
        orgId,
        employeeId,
        extras,
      );

      commit('extras', result);
    },
  },
};
