import Vue from 'vue';
import axios from '@/plugins/axios';
import Vuetify from '@/plugins/vuetify';
import { DARK_THEME_KEY } from '@/utils/constants';
import env from '@/plugins/env';
import { WebStorage } from '@/plugins/webStorage';

const APP_VERSION = String(env.get('VUE_APP_VERSION'));

const state = {
  serverAppVersion: APP_VERSION,

  registeredModals: {},
  modals: [],
  menu: [],

  themeIsDark: false,
};

const getters = {
  registeredModals: state => state.registeredModals,
  registeredModal: state => name => state.registeredModals[name],
  modals: state => state.modals,
  menu: state =>
    state.menu.sort(
      (a, b) => (a.priority || Infinity) - (b.priority || Infinity),
    ),

  themeIsDark: state => state.themeIsDark,
  serverAppVersion: state => state.serverAppVersion,
  isUsedOldVersion: state => {
    if (env.get('VUE_APP_ENVIRONMENT') === 'development') return false;
    return APP_VERSION !== state.serverAppVersion;
  },
};

const mutations = {
  registerModal(state, modalDefinition) {
    state.registeredModals = {
      ...state.registeredModals,
      [modalDefinition.name]: modalDefinition,
    };
  },
  addModal(state, modalDefinition) {
    state.modals = [...state.modals, modalDefinition];
  },
  removeModal(state, id) {
    const index = state.modals.findIndex(item => item.id === id);
    Vue.delete(state.modals, index);
  },

  addMenuItems(state, { items, parent }) {
    if (!parent) state.menu = [...state.menu, ...items];
    else {
      let parentsArray = state.menu;
      let parentItem = null;
      const parents = parent.split(':');
      parents.forEach((parentName, index) => {
        parentItem = parentsArray.find(
          item => item.name === parents.slice(0, index + 1).join(':'),
        );
        if (parentItem && parentItem.children) {
          parentsArray = parentItem.children;
        }
      });
      if (!parentItem) return;

      Vue.set(parentItem, 'children', [
        ...(parentItem?.children || []),
        ...items,
      ]);
    }
  },

  themeIsDark(state, value) {
    state.themeIsDark = value;
  },

  serverAppVersion(state, value) {
    state.serverAppVersion = value;
  },
};

const actions = {
  async initApplication({ dispatch }) {
    // unsubscribe all callbacks before new init modules
    this.$unsubscribeAll();

    dispatch('initTheme');
    dispatch('serverAppVersion');

    // fire 'init' function of each module
    if (this._modulesNamespaceMap) {
      for (const moduleName in this._modulesNamespaceMap) {
        const actionName = `${moduleName}init`;
        const actionExists = !!this._actions[actionName];
        actionExists && dispatch(actionName);
      }
    }
  },

  initTheme({ commit }) {
    const theme = JSON.parse(WebStorage.getItem(DARK_THEME_KEY));
    Vuetify.framework.theme.dark = theme || false;
    commit('themeIsDark', theme);
  },

  switchTheme({ commit }) {
    Vuetify.framework.theme.dark = !Vuetify.framework.theme.dark;
    WebStorage.setItem(DARK_THEME_KEY, Vuetify.framework.theme.dark);
    commit('themeIsDark', Vuetify.framework.theme.dark);
  },

  async serverAppVersion({ dispatch, commit }) {
    if (env.get('VUE_APP_ENVIRONMENT') === 'development') return;

    try {
      const version = await axios
        .get('/.version', {
          headers: { 'x-trace-id': null }, // Костыль, иначе NGINX возвращает CORS
        })
        .then(res => res?.data?.version);

      if (APP_VERSION !== version) {
        commit('serverAppVersion', version);
        console.error('Версия клиента и сервера не совпала', {
          client: APP_VERSION,
          server: version,
        });
        return;
      }
    } catch (err) {
      console.error(err);
    }

    // Повторять процедуру раз в 2 минуты
    setTimeout(() => dispatch('serverAppVersion'), 2 * 60_000);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
