import { EXECUTIONS_STATUSES } from '../constants';
import { dateTime } from '@/utils/convert';

export const messages = {
  resourceName: 'лог задач',
};

export const entity = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'status',
    label: 'Статус',
    headerProps: {
      width: '60px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
    convert: item => EXECUTIONS_STATUSES[item.status],
  },
  {
    key: 'job',
    label: 'Задача',
    convert: item => (item.job ? item.job.name : '– DELETED –'),
  },
  {
    key: 'end',
    label: 'Дата',
    convert: item => dateTime(item.end) || '–',
  },
  {
    key: 'isManual',
    label: 'Способ запуска',
  },
];

export const filters = {
  status: {
    type: String,
  },
};
