<template>
  <div class="limits__card__notifications mt-5">
    <div class="subtitle-2">Уведомления</div>
    <div
      v-if="type === LimitType.BY_DATE"
      class="limits__card__notifications__list"
    >
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th class="text-left" width="120">Период</th>
              <th class="text-left" width="50">Значение</th>
              <th v-if="$can('AC_PRW')" class="text-left">Получатели</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(n, index) in value" :key="'type' + index">
              <td>
                {{
                  n.periodSize
                    ? LimitPeriodName[n.period]
                    : 'По факту срабатывания лимита'
                }}
              </td>
              <td>{{ n.periodSize ? numberWithSpaces(n.periodSize) : '-' }}</td>
              <td v-if="$can('AC_PRW')">
                <v-chip v-for="id in n.receivers" :key="id" class="ma-1">
                  {{ getReceiverEmail(id) }}
                </v-chip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <div v-else class="limits__card__notifications__list">
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th class="text-left" style="width: 90px">Лимит, %</th>
              <th class="text-left">Получатели</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(n, index) in value" :key="'type' + index">
              <td>{{ n.threshold }}</td>
              <td>
                <v-chip v-for="id in n.receivers" :key="id" class="ma-1">
                  {{ getReceiverEmail(id) }}
                </v-chip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { LimitType, LimitPeriodName } from '@/api/services/limits.interfaces';
import { numberWithSpaces } from '@/utils/helpers';
import { mapGetters } from 'vuex';
export default {
  props: {
    type: { required: true, type: String },
    value: { required: true, type: Array },
  },
  computed: {
    ...mapGetters('STRUCTURES/LIMITS', ['receivers']),
    LimitType: () => LimitType,
    LimitPeriodName: () => LimitPeriodName,
  },
  methods: {
    numberWithSpaces,
    getReceiverEmail(id) {
      return (
        this.receivers.find(r => r.id === id)?.email || 'Получатель не найден'
      );
    },
  },
};
</script>
