export default {
  props: {
    matchBy: { type: [String, Object], default: null },
    signIn: { type: Function, required: true },
    signOut: { type: Function, default: null },
  },

  mounted() {
    document.addEventListener('keyup', this.onEnter);
  },

  beforeDestroy() {
    document.removeEventListener('keyup', this.onEnter);
  },

  computed: {
    softAuth() {
      return !!this.matchBy;
    },
  },

  methods: {
    onEnter(e) {
      e.keyCode === 13 && this.onSubmit();
    },
    onSubmit() {
      return null;
    },
  },
};
