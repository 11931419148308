<template>
  <DatePicker
    label="Дата"
    :value="date"
    hide-details="auto"
    outlined
    max-current
    :error-messages="errorMessages"
    @input="changeDate"
  />
</template>

<script>
import { DateTime } from '@/plugins/luxon';

import DatePicker from '@/components/controls/DatePicker';

export default {
  components: { DatePicker },
  props: {
    value: { required: true, type: Object },
    errorMessages: { type: Array, default: () => [] },
  },

  computed: {
    date() {
      if (!this.value.dateStart) return null;
      const [year, month, day] = this.value.dateStart.split('-');

      return year + '-' + month + '-' + day.split('T')[0];
    },
  },
  mounted() {
    const previousMonth = DateTime.local()
      .minus({ month: 1 })
      .startOf('month')
      .startOf('day');

    this.$emit('input', {
      dateStart: previousMonth.toISO(),
      dateEnd: previousMonth.endOf('month').toISO(),
    });
  },
  methods: {
    changeDate(value) {
      const [year, month, day] = value.split('-');
      const date = DateTime.local(Number(year), Number(month), Number(day));
      this.$emit('input', {
        dateStart: date.toISO(),
        dateEnd: date.endOf('day').toISO(),
      });
    },
  },
};
</script>
