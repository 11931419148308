import historyApi from '@/api/services/history';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { createModule } from '@/utils/vuex/createModule';
import { getUserTimeZone } from '@/utils/convert';
import { filters } from '../entity';
import { currentDateTime } from '@/utils/helpers';
import { changePages } from '@/utils/vuex/changePages';

const state = {
  exportLimit: null,
  datePlaceholder: null,
};

const getters = {
  exportLimit: state => state.exportLimit,
  datePlaceholder: state => state.datePlaceholder,
};

const mutations = {
  exportLimit: (state, value) => (state.exportLimit = value),
  datePlaceholder: (state, value) => (state.datePlaceholder = value),
};

const actions = {
  async fetchList({ commit, getters }, query) {
    await changePages(getters, commit, query);

    const params = { ...getters.listQuery };

    // Задаем дефолтное значение на выборку осмотров за последние 24 часа
    if (!params?.dateStart && !params?.dateEnd) {
      const dateWithZone = currentDateTime().setZone(
        (params?.timezone || getUserTimeZone()) * 60,
        { keepLocalTime: true },
      );
      params.dateStart = dateWithZone.minus({ days: 1 }).toISO();
      params.dateEnd = dateWithZone.toISO();

      commit('datePlaceholder', {
        dateStart: params.dateStart,
        dateEnd: params.dateEnd,
      });
    } else if (getters.datePlaceholder !== null) {
      commit('datePlaceholder', null);
    }

    // Удаляем timezone из запроса
    delete params.timezone;

    // Удаляем текстовую запись из запроса.
    // Передаются только IDs найденных работников
    delete params.employeeSearch;

    try {
      commit('listFetching');
      const response = await historyApi.inspectionList(params);
      commit('listFetched', response);
    } catch (error) {
      commit('listErrorFetched', error);
      throw error;
    }
  },

  async setEmployeeSearch({ commit }, value) {
    commit('listQuery', { employeeSearch: value });
  },

  async setLicenseFilter({ commit }, maskedLicense) {
    commit(
      'licenses',
      maskedLicense ? [maskedLicense.replace(/[_]/g, '')] : null,
    );
  },

  async fetchExportLimit({ commit }) {
    const { commonExportLimit } = await historyApi.getInspectionLimits();
    commit('exportLimit', commonExportLimit);
  },

  setDateFilters({ commit }, { dateStart, dateEnd }) {
    commit('dateStart', dateStart);
    commit('dateEnd', dateEnd);
  },
};

export default createModule(
  crudListFactory({
    exportMethod: historyApi.inspectionExport,
    defaultSort: {
      orderBy: 'date',
      orderType: true,
    },
    filters,
  }),
  { state, getters, mutations, actions },
);
