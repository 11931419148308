<template>
  <div class="inspection-details">
    <DefinitionList dense :value="currentInfo" />
  </div>
</template>

<script>
import { dateTime, name, duration } from '@/utils/convert';
import { getInspectionBadges } from '@/modules/inspections/badges';

import DefinitionList from '@/components/DefinitionList';
import TerminalDateTime from '@/components/TerminalDateTime.vue';
import LinkButton from '@/components/controls/buttons/LinkButton.vue';
import ResultBadge from '@/modules/inspections/components/ResultBadge';
import DurationTooltip from '@/modules/inspections/components/DurationTooltip';
import FileHash from '@/modules/inspections/components/FileHash';
import Badges from '@/modules/inspections/components/Badges';
// import Integrity from '@/modules/inspections/components/Integrity';

const SIGNED_TYPES = {
  simple: {
    text: 'Подписано ПЭП',
    help: 'Подписано ПЭП (простой электронной подписью)',
  },
  qualified: {
    text: 'Подписано УКЭП',
    help: 'Подписано УКЭП (усиленной квалифицированной электронной подписью"',
  },
};

export default {
  components: { DefinitionList },
  props: {
    remarks: { required: true, type: Array, default: () => [] },
    remarkTypes: { required: true, type: Object },
    inspection: { required: true, type: Object, default: () => ({}) },
    showErrorNotifications: Boolean,
  },

  computed: {
    remarkString() {
      const inspection = this.inspection;
      const name = inspection.resolution.success
        ? 'Метки'
        : 'Причина недопуска';

      const result = {
        label: name,
        value: null,
      };

      const remarks = inspection.resolution.remarks || [];
      if (!remarks.length) return result;

      const remarksFiltered = remarks
        .map(el => this.remarks.find(remark => remark.id === el)?.name)
        .filter(a => a);

      if (!inspection.resolution.success && remarksFiltered.length > 1)
        result.label = 'Причины недопуска';

      result.value = remarksFiltered.join(', ');

      return result;
    },

    currentInfo() {
      const inspection = this.inspection;
      const inspectionBadges = getInspectionBadges(inspection);

      const { resolvedBy } = inspection;
      const resolverName =
        resolvedBy.type === 'human'
          ? name(resolvedBy)
          : (resolvedBy.type === 'bot' && resolvedBy.name) || null;

      const resolverInfo =
        resolvedBy.type === 'human'
          ? {
              label: 'Медработник',
              component: LinkButton,
              props: {
                dense: true,
                text: resolverName || `#${resolvedBy.id}`,
                action: this.$can('MED_RD'),
                blank: true,
                to: {
                  name: 'medblock:medics:item',
                  params: { id: resolvedBy.id },
                },
              },
            }
          : {
              label: 'Бот',
              value: resolverName,
            };

      const {
        id: pointId,
        address: pointAddress,
        isMobile: pointIsMobile,
      } = inspection?.host?.releasePoint || {};

      const data = [
        { label: 'Тип', value: inspection.type.name },
        {
          label: 'Длительность',
          component: DurationTooltip,
          value: duration(
            inspection.timestamps.startedAt,
            inspection.timestamps.finishedAt,
          ),
        },
        {
          label: 'Результат осмотра',
          component: ResultBadge,
          props: {
            result: inspection.resolution.success,
            remarks: inspection.resolution.remarks,
            remarkTypes: this.remarkTypes,
            type: inspection.type,
          },
        },
        inspectionBadges.length
          ? {
              label: 'Доп. метки',
              component: Badges,
              value: inspectionBadges,
            }
          : {},
        inspection.oldId
          ? {
              label: 'Номер в старой системе',
              value: inspection.oldId,
            }
          : {},
        this.remarkString,
        { label: 'Комментарий', value: inspection.resolution.comment || '–' },
        ...[resolverInfo],
        {
          label: 'Дата осмотра',
          value: dateTime(inspection.timestamps.processedAt),
        },
        {
          label: 'Дата на терминале',
          component: TerminalDateTime,
          props: {
            date: inspection.timestamps.processedAt,
            offset: inspection.timezoneOffsetMin,
          },
        },
        {
          label: 'Точка выпуска',
          component: LinkButton,
          props: {
            dense: true,
            blank: true,
            text:
              pointAddress || (pointIsMobile && 'Мобильная') || '#' + pointId,
            action: this.$can('PNT_RD'),
            to: {
              name: 'structures:points:item',
              params: { id: pointId },
            },
          },
        },
        {
          label: 'ПАК',
          component: LinkButton,
          props: {
            dense: true,
            blank: true,
            text: inspection?.host?.license || `#${inspection?.host?.id}`,
            action: this.$can('HST_RD'),
            to: {
              name: 'structures:hosts:item',
              params: { id: inspection.host.id },
            },
          },
        },
        { label: 'Версия ПО ПАК', value: inspection?.hostInfo?.version || '—' },
      ];

      /**
       * Целостность ПАК.
       * Показываем если есть хотя-бы одно устройство
       * */
      // if (inspection.integrity && Object.keys(inspection.integrity).length) {
      //   data.push({
      //     label: 'Целостность ПАК',
      //     component: Integrity,
      //     props: {
      //       status: inspection.integrity,
      //     },
      //   });
      // }

      // Файл результата
      // добавляем только для завершенных осмотров, у незавершенных файл не формируется, и это не ошибка
      if (!inspection?.isCanceled) {
        const signedType = inspection?.resultsSignatureDetails?.type;
        data.push({
          label: 'Файл результата',
          component: FileHash,
          props: {
            dense: true,
            fileLabel: 'Скачать результат',
            file: inspection?.resultsFile,
            fileSignature: inspection?.resultsSignature,
            signedText: SIGNED_TYPES[signedType]?.text,
            helpLabel: SIGNED_TYPES[signedType]?.help,
            isError: !inspection?.resultsFile,
            errorMessage: 'Файл не найден',
          },
        });
      }

      // Файл решения
      if (inspection.resolutionSignature) {
        data.push({
          label: 'Файл решения',
          component: FileHash,
          props: {
            dense: true,
            fileLabel: 'Скачать решение',
            file: inspection?.resolutionFile,
            fileSignature: inspection?.resolutionSignature,
            isError: !inspection?.resolutionFile,
            errorMessage: 'Файл не найден',
          },
        });
      }

      // Ффйл заключения
      if (inspection.medicResolutionSignature) {
        data.push({
          label: 'Файл заключения',
          component: FileHash,
          props: {
            dense: true,
            fileLabel: 'Скачать заключение',
            file: inspection?.medicResolutionFile,
            fileSignature: inspection?.medicResolutionSignature,
            isError: !inspection?.medicResolutionFile,
            errorMessage: 'Файл не найден',
          },
        });
      }

      return data;
    },
  },
};
</script>
