<template>
  <ItemPageLayout back-route="documents:outgoing" title="Просмотр документа">
    <v-card class="pa-6">
      <Item :document="singleItem" :employee="employee" />
    </v-card>
  </ItemPageLayout>
</template>

<script>
import ItemPageLayout from '@/components/layouts/ItemPageLayout.vue';
import Item from '../Item.component';

import waitable from '@/utils/mixins/waitable';
import { mapGetters } from 'vuex';

export default {
  components: { ItemPageLayout, Item },
  mixins: [waitable],

  computed: {
    ...mapGetters('DOCUMENTS/OUTGOING_ITEM', ['singleItem', 'employee']),
  },
};
</script>
