<template>
  <Spinner v-if="$wait('fetchRoleTypes')" />
  <List
    v-else
    :available-actions="{
      showAction: false,
      createAction: $can('RL_C'),
    }"
    :can-show-scoped-actions="$can('RL_U') || $can('RL_AG') || $can('RL_D')"
    @item:create="openForm"
  >
    <template #item.actions.append="{ item }">
      <v-tooltip v-if="$can('RL_U')" left>
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" icon v-on="on" @click="openForm({ item })">
            <v-icon small>fa-edit</v-icon>
          </v-btn>
        </template>
        <span>Редактировать</span>
      </v-tooltip>

      <v-tooltip v-if="$can('RL_AG')" left>
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" icon v-on="on" @click="openEditGroups(item)">
            <v-icon small>mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
        <span>Редактировать группы</span>
      </v-tooltip>

      <v-tooltip v-if="$can('RL_D')" left>
        <template #activator="{ attrs }">
          <v-btn v-bind="attrs" icon color="red" @click="openDeleteModal(item)">
            <v-icon small>fa-trash-alt</v-icon>
          </v-btn>
        </template>
        <span>Удалить</span>
      </v-tooltip>
    </template>
  </List>
</template>

<script>
import waitable from '@/utils/mixins/waitable';
import ListFactory from '@/components/crud/ListFactory';
import authApi from '@/api/services/auth';
import Spinner from '@/components/Spinner.vue';
import GroupsSelect from './modals/GroupsSelect';

const messages = { resourceName: 'ролей пользователей' };

const entity = [
  { key: 'key', label: 'Key' },
  { key: 'name', label: 'Название' },
  {
    key: 'typeKey',
    label: 'Тип роли',
    convert: (item, getters) =>
      getters['ADMIN/ROLES/roleTypeByKey'](item.typeKey),
  },
  { key: 'description', label: 'Описание' },
];

const List = ListFactory({
  storeModule: 'ADMIN/ROLES',
  messages,
  entity,
});

export default {
  components: { List, Spinner },
  mixins: [waitable],
  computed: {
    roleTypes() {
      return this.$store.getters['ADMIN/ROLES/roleTypes'];
    },
  },
  async created() {
    await this.$loading(
      this.$store.dispatch('ADMIN/ROLES/fetchRoleTypes'),
      'fetchRoleTypes',
    );
  },
  methods: {
    fetchItemDetails(item) {
      return authApi.rolesGet(item.key);
    },

    async openEditGroups(item) {
      const itemDetails = await this.fetchItemDetails(item);

      this.$openModal('asyncSelectModal', {
        component: GroupsSelect,
        value: itemDetails.groups,
        multiple: true,
        size: 'large',
        onSubmit: newGroups =>
          this.$loadingNotify(
            this.$store.dispatch('ADMIN/ROLES/bindGroupsInRole', {
              key: item.key,
              newList: newGroups,
            }),
            'bindGroupsInRole',
            'Произошла ошибка изменения групп роли',
            'Группы роли успешно обновлены',
          ),
        messages: {
          title: `Группы роли ${item.name}`,
          warning: `Группы роли ${item.name} отсутствуют`,
        },
      });
    },

    openForm({ item }) {
      this.$openModal('admin/roleForm', {
        value: item || Object.fromEntries(entity.map(field => [field.key])),
      });
    },

    openDeleteModal(item) {
      this.$openModal(() => import('@/components/crud/DeleteModal.vue'), {
        onDelete: data =>
          this.$store.dispatch('ADMIN/ROLES/deleteListItem', data),
        item,
        messages: messages.delete,
      });
    },
  },
};
</script>
