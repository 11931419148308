<template>
  <ModernModalLayout
    size="large"
    title="Редактирование профиля"
    close-label="Отмена"
    :watch-for="profile"
  >
    <ProfileFields
      ref="profile"
      v-model="profile"
      :is-loading="$wait('changingProfile')"
      :disabled="!$can('PROF_S')"
    />

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :disabled="$wait('changingProfile')"
        @click="submit"
      >
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import ModernModalLayout from '@/components/layouts/ModernModalLayout.vue';
import ProfileFields from '@/modules/admin/applications/components/ProfileFields.vue';

export default {
  components: { ModernModalLayout, ProfileFields },

  mixins: [validation, waitable],

  props: {
    id: { type: Number, required: true },
    value: { type: Object, required: true },
  },

  data: () => ({
    profile: {},
  }),

  created() {
    this.profile = this.value;
  },

  methods: {
    async submit() {
      if (!this.$refs.profile.validate()) return;

      await this.$loadingNotify(
        this.$store
          .dispatch('ADMIN/APPLICATIONS_ITEM/changeProfile', this.profile)
          .then(() => {
            this.$emit('close');
          }),
        'changingProfile',
        'Произошла ошибка изменения профиля',
        `Профиль пользователя успешно изменен`,
      );
    },
  },
};
</script>
