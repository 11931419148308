<template>
  <h3 :style="style">
    {{ result ? resolutions.success : resolutions.failure }}
  </h3>
</template>

<script>
import { COLORS } from '@/utils/constants.js';

/** Список цветов типов ремарок */
export const FailReasonColors = {
  FRAUD: COLORS.violet,
  CANCEL: COLORS.purple,
  ADMINISTRATIVE: COLORS.orange,
  TECHNICAL: COLORS.blue,
  INTOXICATION: COLORS.red,
  MEDICAL: COLORS.yellow,
};

/** Список типов ремарок в соответствии с приоритетом */
const FailReasonPriority = [
  'FRAUD',
  'CANCEL',
  'ADMINISTRATIVE',
  'TECHNICAL',
  'INTOXICATION',
  'MEDICAL',
];

export default {
  props: {
    remarkTypes: {
      required: true,
      type: Object,
    },
    type: {
      required: true,
      type: Object,
    },
    result: Boolean,
    remarks: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    style() {
      if (!this.remarks.length) return ` color: rgb(${COLORS.green})`;

      if (this.remarks.length === 1) {
        const color = FailReasonColors[this.remarkTypes[this.remarks[0]]];
        return ` color: rgb(${color})`;
      }

      const priorityType = [
        ...new Set(this.remarks.map(el => this.remarkTypes[el])),
      ].sort(
        (a, b) => FailReasonPriority.indexOf(a) - FailReasonPriority.indexOf(b),
      )[0];

      return ` color: rgb(${FailReasonColors[priorityType]})`;
    },

    resolutions() {
      return (
        this.type?.details?.resolutions || {
          success: 'Успешно',
          failure: 'Неудачно',
        }
      );
    },
  },
};
</script>
