<template>
  <ItemPageLayout back-route="notifications:templates" :title="name">
    <Card :title="isCreate ? 'Новый набор шаблонов' : 'Набор шаблонов'">
      <v-form class="pt-2" :disabled="isLoading || disabled">
        <v-text-field
          v-model="form.key"
          label="Ключ"
          outlined
          dense
          :disabled="!isCreate"
          :error-messages="getValidationErrors('form.key')"
          @blur="validateField('form.key')"
        />
        <v-text-field
          v-model="form.name"
          label="Название"
          outlined
          dense
          :error-messages="getValidationErrors('form.name')"
          @blur="validateField('form.name')"
        />
        <v-textarea
          v-model="form.shortText"
          label="Короткий текст"
          outlined
          dense
          :error-messages="getValidationErrors('form.shortText')"
          @blur="handleFieldBlur('form.shortText')"
        />

        <v-text-field
          v-model="form.templates.email.subject"
          label="Тема"
          outlined
          dense
          :error-messages="getValidationErrors('form.templates.email.subject')"
          @blur="validateField('form.templates.email.subject')"
        />
        <v-textarea
          v-model="form.templates.email.content"
          outlined
          dense
          auto-grow
          hide-details="auto"
          :error-messages="getValidationErrors('form.templates.email.content')"
          @blur="handleFieldBlur('form.templates.email.content')"
        />
      </v-form>
    </Card>

    <div class="text-right">
      <v-btn
        v-if="$can('NF_TD')"
        color="error"
        class="mr-5"
        depressed
        :disabled="isLoading"
        @click="openDeleteModal(item)"
      >
        Удалить
      </v-btn>

      <v-btn
        v-if="$can('NF_TU')"
        class="px-5"
        depressed
        color="primary"
        :disabled="isLoading"
        @click="submit"
      >
        Сохранить
      </v-btn>
    </div>

    <template #aside>
      <Card title="Список переменных">
        <v-card-subtitle style="font-size: 0.8rem">
          Для применения переменной напишите её в теме или сообщении письма в
          двойных фигурных скобках. Например, чтобы добавить в текст письма имя
          работника напишите:
          <b>{{ example }}</b>
        </v-card-subtitle>
        <v-simple-table class="mb-4" dense>
          <template #default>
            <thead>
              <tr>
                <th>Ключ</th>
                <th>Описание</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(data, key) in templateLocals">
                <tr :key="key + '1'">
                  <td>{{ key }}</td>
                  <td>
                    <b>{{ data.name }}</b>
                    <template v-if="data.name !== data.description">
                      <br />
                      {{ data.description }}
                    </template>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </Card>
    </template>
  </ItemPageLayout>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { longUniqueStringKey } from '@/utils/validators';
import cloneDeep from 'lodash/cloneDeep';
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';

import routeGuardMixin from '@/utils/mixins/routeGuardMixin';

import Card from '@/components/ui/Card.vue';
import ItemPageLayout from '@/components/layouts/ItemPageLayout.vue';

export default {
  components: { ItemPageLayout, Card },

  mixins: [validation, waitable, routeGuardMixin],

  props: {
    item: { type: Object, required: true },
    disabled: { type: Boolean },
  },

  data: () => ({
    form: {
      key: '',
      name: '',
      shortText: '',
      templates: { email: {} },
    },
  }),

  validations() {
    return {
      form: {
        key: { required, longUniqueStringKey },
        name: { required },
        shortText: { required },
      },
    };
  },

  computed: {
    ...mapGetters('NOTIFICATIONS/TEMPLATES', ['templateLocals']),

    example: () => '{{EMPLOYEE_FULLNAME}}',

    isLoading() {
      return this.$wait('submit') || this.$wait('loadingAccount');
    },

    isCreate() {
      return this.$route.name.includes('create');
    },

    name() {
      return this.isCreate
        ? 'Добавить набор шаблонов'
        : 'Редактировать набор шаблонов';
    },
  },

  async created() {
    await this.$store.dispatch('NOTIFICATIONS/TEMPLATES/fetchTemplateLocals');

    if (!this.isCreate) {
      this.$store.commit('NOTIFICATIONS/TEMPLATES_ITEM/singleItem', {});
      Vue.set(this, 'form', this.singleItem);
    }

    // if key is null - set it to empty object cuz of v-model
    Object.keys(this.form.templates).forEach(
      key => (this.form.templates[key] = this.form.templates[key] || {}),
    );
  },

  methods: {
    async submit() {
      const data = cloneDeep(this.form);
      data.name = data.name.trim();
      data.shortText = data.shortText.trim();

      Object.keys(data.templates).forEach(key => {
        // trim 'content' and 'subject' fields and if those become empty strings
        // delete fields from object
        Object.keys(data.templates[key]).forEach(k => {
          data.templates[key][k] = data.templates[key][k].trim();
          if (data.templates[key][k] === '') delete data.templates[key][k];
        });

        // check if there are any non-empty objects left and set others to null
        if (!Object.keys(data.templates[key]).length)
          data.templates[key] = null;
      });

      if (!this.validate()) return;

      const action = this.isCreate
        ? 'TEMPLATES/createListItem'
        : 'TEMPLATES_ITEM/updateSingleItem';

      await this.$loadingNotify(
        this.$store.dispatch(`NOTIFICATIONS/${action}`, data).then(_ => {
          this.isCreate &&
            this.$router.push({
              name: 'notifications:templates:item',
              // using 'form.key' notation cuz don't wana write custom 'create'
              // function or alter the one in single resource factory
              params: { key: this.form.key },
            });
        }),
        'submit',
        `Произошла ошибка ${
          this.isCreate ? 'создания' : 'редактирования'
        } набора шаблонов`,
        `Набор шаблонов успешно ${this.isCreate ? 'создан' : 'изменен'} `,
      ).catch(err => this.catchServerErrors(err, 'form'));
    },

    openDeleteModal() {
      this.$openModal('prompt', {
        yes: 'Удалить',
        no: 'Отмена',
        title: 'Удалить набор шаблонов',
        description: `Вы уверены, что хотите удалить набор шаблонов?`,
        onSubmit: () =>
          this.$store
            .dispatch(
              'NOTIFICATIONS/TEMPLATES_ITEM/deleteSingleItem',
              this.form.key,
            )
            .then(_ => this.$router.push({ name: 'notifications:templates' })),
      });
    },

    tolower(str) {
      return str.toLowerCase();
    },
  },
};
</script>
