<template>
  <ModernModalLayout
    title="Архивирование фото"
    close-label="Отмена"
    dense-content
  >
    <div class="ma-6">
      <div>Вы действительно хотите отправить фото в архив?</div>
      <div class="mt-1">
        При следующей авторизации на терминале сотруднику необходимо будет
        сделать новое фото.
      </div>
    </div>

    <template #actions:append>
      <v-btn color="primary" text @click="archivePhoto">Архивировать</v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import waitable from '@/utils/mixins/waitable';
import ModernModalLayout from '@/components/layouts/ModernModalLayout';

export default {
  components: { ModernModalLayout },
  extends: waitable,
  props: {
    item: { type: Object, required: true },
    submitArchive: { type: Function, default: null },
  },
  methods: {
    async archivePhoto() {
      this.$loadingNotify(
        this.submitArchive(this.item.id),
        'archivePhoto',
        'Произошла ошибка архивации фото работника',
        'Фото работника успешно архивировано',
      );
      this.$emit('close');
    },
  },
};
</script>
