<template>
  <div>
    <DefinitionList :value="extrasData" />
    <NoDataAlert v-if="isEmpty" class="ma-4">
      Дополнительные поля не заданы
    </NoDataAlert>
  </div>
</template>

<script>
import DefinitionList from '@/components/DefinitionList';
import NoDataAlert from '@/components/ui/NoDataAlert';

export default {
  components: {
    DefinitionList,
    NoDataAlert,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    types: {
      type: Array,
      required: true,
    },
  },
  computed: {
    extrasData() {
      return this.types.map(type => ({
        label: type.name,
        value: this.value[type.type],
      }));
    },
    isEmpty() {
      const values = Object.values(this.value);

      return values.filter(item => item !== null && item !== '').length === 0;
    },
  },
};
</script>
