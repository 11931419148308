import historyApi from '@/api/services/history';
import { createModule } from '@/utils/vuex/createModule';
import Vue from 'vue';

const state = {
  inspections: [],
  inspectionsTotal: null,
  inspectionItems: {},
  inspectionItem: null,
};

const getters = {
  inspections: store => store.inspections,
  inspectionsTotal: store => store.inspectionsTotal,
  inspectionItems: store => store.inspectionItems,
  inspectionItem: store => store.inspectionItem,
};

const mutations = {
  addInspections: (state, value = {}) => {
    state.inspections = [...state.inspections, ...(value?.items || [])];
    state.inspectionsTotal = value?.total;
  },
  clearInspections: state => {
    state.inspections = [];
    state.inspectionsTotal = null;
  },
  setInspectionItems: (state, value) => {
    Vue.set(state.inspectionItems, value.id, Object.freeze(value));
  },
  setInspectionItem: (state, id) => {
    state.inspectionItem = state.inspectionItems[id];
  },
};

const actions = {
  async fetchInspections({ commit }, params) {
    const data = await historyApi.inspectionList(params);
    commit('addInspections', data);
  },
  async fetchInspectionItem({ getters, commit }, { id, isImported }) {
    let item = getters.inspectionItems[id] || null;
    if (!item) {
      item = isImported
        ? await historyApi.inspectionArchived(id)
        : await historyApi.inspectionGet(id);
      commit('setInspectionItems', item);
    }
    commit('setInspectionItem', item.id);
  },
  clearInspections({ commit }) {
    commit('clearInspections');
    commit('setInspectionItem', null);
    commit('setInspectionItems', {});
  },
};

export default createModule({ state, getters, mutations, actions });
