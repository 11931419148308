import medrecApi from '@/api/services/medrec';

export default {
  state: {
    medRecord: {},
  },
  getters: {
    medRecord: state => state.medRecord,

    documents: state => state.medRecord?.documents || [],
  },
  mutations: {
    medRecord: (state, value) => (state.medRecord = value),

    addComment: (state, value) => {
      state.medRecord = {
        ...state.medRecord,
        comments: [value, ...state.medRecord.comments],
      };
    },
    changeComments: (state, value) => {
      state.medRecord = {
        ...state.medRecord,
        comments: value,
      };
    },
  },
  actions: {
    async fetchMedrec({ commit }, id) {
      const record = await medrecApi.getEmployeeRecord(id);
      commit('medRecord', record || {});
    },
    async sendComment({ getters, commit }, content) {
      const data = await medrecApi.sendComment({
        id: getters.singleItem.id,
        content,
      });

      commit('addComment', data);
    },

    async setBoundaries({ dispatch }, { id, data }) {
      await medrecApi.setEmployeeBoundaries(id, data);
      dispatch('fetchMedrec', id);
    },

    async resetBoundaries({ commit }, id) {
      await medrecApi.resetEmployeeBoundaries(id);
      const record = await medrecApi.getEmployeeRecord(id);
      commit('medRecord', record || {});
    },

    async getDocumentsForUnban(_, { id, banReason }) {
      const response = await medrecApi.getEmployeeDocuments(
        id,
        'unban',
        banReason,
      );

      return response;
    },
  },
};
