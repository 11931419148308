import findfaceApi from '@/api/services/findface';
import { can } from '@/plugins/can';
import env from '@/plugins/env';

export default {
  state: {
    findFace: null,
  },

  getters: {
    findFace: state => state.findFace,
    showFindFace: () => !env.get('VUE_APP_FINDFACE_DISABLE') && can('GSOF'),
  },

  mutations: {
    findFace: (state, data) => (state.findFace = data),
  },

  actions: {
    async fetchFindFaceStatus({ getters, commit }, signal) {
      const result = await findfaceApi.getSyncStatus(getters.id, signal);
      commit('findFace', result);
      return result;
    },

    async enableFindFaceSync({ getters, dispatch }) {
      await findfaceApi.enableSyncOrganization(getters.id);
      setTimeout(() => {
        dispatch('fetchFindFaceStatus');
      }, 1000);
    },

    async disableFindFaceSync({ getters, dispatch }) {
      await findfaceApi.disableSyncOrganization(getters.id);
      setTimeout(() => {
        dispatch('fetchFindFaceStatus');
      }, 1000);
    },
  },
};
