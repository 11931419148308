<template>
  <div
    class="d-flex align-baseline flex-wrap"
    style="column-gap: 8px; row-gap: 4px"
  >
    <span>{{ employeeName }}</span>
    <span v-if="showAge">({{ age.count }}&nbsp;{{ age.text }})</span>
    <v-chip v-if="value.isTest" color="green" small outlined> Тестовый </v-chip>
  </div>
</template>

<script>
import plural from 'plural-ru';
import { name } from '@/utils/convert';
import { diffYears } from '@/utils/helpers';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    showAge: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    employeeName() {
      return name(this.value);
    },

    age() {
      const age = diffYears(this.value.dateOfBirth, new Date());
      return {
        count: age,
        text: plural(age, 'год', 'года', 'лет'),
      };
    },
  },
};
</script>
