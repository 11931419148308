<template>
  <PointsList
    search-filter
    :available-actions="{
      showAction: $can('PNT_RD'),
      createAction: $can('PNT_C'),
    }"
    @item:create="openCreatePoint"
  >
    <template #filters="query">
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.isActive"
          label="Статус"
          :items="statusItems"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :menu-props="{ offsetY: true }"
        />
      </v-col>
    </template>

    <template #table.item.isActive="{ item }">
      <Indicator :active="item.isActive" />
    </template>

    <template #table.item.organization="{ item }">
      <v-tooltip v-if="item.organization.shortName" right>
        <template #activator="{ on }">
          <span v-on="on">Орг.: {{ item.organization.shortName }}</span>
        </template>
        {{ item.organization.name }}
      </v-tooltip>
      <template v-else>
        {{ item.organization.name }}
      </template>
    </template>

    <template #table.item.pointOnMap="{ item }">
      <div v-if="item.address">{{ item.address }}</div>
      <div v-if="(item.coordinates || {}).latitude">
        Координаты:
        {{ item.coordinates.latitude }};&nbsp;{{ item.coordinates.longitude }}
      </div>
      <v-chip v-if="item.isMobile" small>Мобильная</v-chip>
    </template>
  </PointsList>
</template>

<script>
import { STATUS } from '@/utils/constants.js';
import Indicator from '@/components/Indicator.vue';
import {
  messages as pointsMessages,
  entity as pointsEntity,
  filters as pointsFilters,
} from '@/modules/structures/points/entity';
import ListFactory from '@/components/crud/ListFactory';

const PointsList = ListFactory({
  storeModule: 'STRUCTURES/EMBEDDED_POINTS_LIST',
  messages: pointsMessages,
  entity: pointsEntity.filter(val => val.key !== 'organization'),
  filters: pointsFilters,
  viewRoute: 'structures:points:item',
  embedded: true,
});

export default {
  components: { PointsList, Indicator },
  props: {
    orgId: { type: Number, required: true },
  },
  computed: {
    statusItems: () => STATUS,
  },
  methods: {
    openCreatePoint() {
      this.$openModal('structures/pointsForm', {
        predefinedValues: { orgId: this.orgId },
        storeForCreate: 'STRUCTURES/EMBEDDED_POINTS_LIST',
      });
    },
  },
};
</script>
