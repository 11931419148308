<template>
  <v-text-field
    v-model="searchStr"
    label="Поиск работника (ФИО / табельный / телефон)"
    outlined
    dense
    hide-details="auto"
    :clearable="searchStr != null"
    :disabled="disabled"
    :loading="loading"
    :error-messages="computedError"
    @input="handleWorkerInput"
    @click:clear="clear"
  />
</template>

<script>
import debounce from 'lodash/debounce';
import structApi from '@/api/services/structures';
import _isEqual from 'lodash/isEqual';

export default {
  props: {
    value: { type: [Array, Number], default: () => [] },
    // for storing search string in store (to keep it after F5)
    store: { type: String, required: true },
    disabled: Boolean,
    orgGroupsIds: { type: Array, default: () => [] },
    orgIds: { type: Array, default: () => [] },
    groupIds: { type: Array, default: () => [] },
  },

  data: () => ({
    searchStr: null,
    loading: false,
    employeesNotFound: false,
    tooManyMatches: false,
    error: null,
  }),

  computed: {
    computedError() {
      if (!this.searchStr) return '';

      if (this.searchStr?.length >= 100) return 'Не более 100 символов';
      if (this.error?.request?.status === 400)
        return 'Ошибка валидации. Допускаются только цифры и буквы.';
      if (this.error?.request?.status >= 500)
        return `Ошибка сервера ${this.error.request.status}`;
      else if (this.employeesNotFound)
        return 'Работники по таким параметрам не найдены';
      else if (this.tooManyMatches)
        return 'Найдено слишком много совпадений. Уточните запрос.';
      return '';
    },

    query() {
      return {
        orgGroupsIds: this.orgGroupsIds?.join() || undefined,
        orgIds: this.orgIds?.join() || undefined,
        groupIds: this.groupIds?.join() || undefined,
      };
    },
  },

  watch: {
    query(a, b) {
      // need to compare object cuz something is changing 'query' object
      // constantly without actually changing it
      if (!_isEqual(a, b)) this.handleWorkerInput();
    },
  },

  created() {
    // used instead of normal search field on emp list and emp-group item pages
    // that's why we check for 'query.search' if 'employeeSearch' is missing
    this.searchStr =
      this.$route.query.employeeSearch || this.$route.query.search || '';

    this.$watch('$route.query.employeeSearch', value => {
      this.searchStr = value;
    });
  },

  methods: {
    async clear() {
      this.searchStr = null;
      this.$emit('input', null);

      if (this.employeesNotFound) this.employeesNotFound = false;
      if (this.tooManyMatches) this.tooManyMatches = false;
      this.$store.dispatch(this.store + '/setEmployeeSearch', null);
    },

    async searchEmployeeIds(search) {
      if (!search) return [];

      const searchString = search.replace(/\+/g, ' ').trim();

      const response = await structApi.getEmployeePreviews({
        ...this.query,
        search: searchString,
        limit: 50,
        page: 1,
      });

      return response?.items.map(item => item.id) || [];
    },

    handleWorkerInput: debounce.call(
      this,
      async function () {
        this.employeesNotFound = false;
        this.tooManyMatches = false;

        if (this.loading) return;
        else if (this.searchStr === '' && this.value) return this.clear();
        else if (!this.searchStr || this.searchStr?.length >= 100) return;

        this.loading = true;
        try {
          const employeeIds = await this.searchEmployeeIds(this.searchStr);

          if (employeeIds.length >= 49) {
            this.tooManyMatches = true;
            return;
          }

          this.$store.dispatch(
            this.store + '/setEmployeeSearch',
            this.searchStr,
          );

          this.$emit('input', employeeIds.length ? employeeIds : null);

          // Если вернулся пустой список, то нужно вывести предупреждение
          if (!employeeIds.length) this.employeesNotFound = true;
        } catch (err) {
          this.error = err;
        } finally {
          this.loading = false;
        }
      },
      1000,
    ),
  },
};
</script>
