<template>
  <v-btn depressed outlined :color="color" v-bind="$attrs" v-on="$listeners">
    <v-icon :size="iconSize" class="mr-2">{{ icon }}</v-icon>
    {{ label }}
  </v-btn>
</template>

<script>
export default {
  name: 'ListActionBtn',
  props: {
    label: { required: true, type: String },
    icon: { required: true, type: String },
    color: { type: String, default: 'primary' },
    iconSize: { type: Number, default: null },
  },
};
</script>
