<template>
  <PageLayout
    back-tooltip="Вернуться к профилям"
    back-route="admin:bans"
    fluid
    :title="title"
  >
    <v-card class="mt-4 d-flex mx-3" style="width: 100%">
      <v-card-text>
        <div class="d-flex">
          <v-text-field
            v-model="key"
            label="Ключ"
            outlined
            dense
            :readonly="!$can('BPC_U')"
            :disabled="isDefault || !isCreate"
            :error-messages="getValidationErrors('key')"
            @blur="validateField('key')"
          />
          <v-text-field
            v-model="name"
            label="Название профиля"
            outlined
            dense
            class="ml-2"
            :readonly="!$can('BPC_U')"
            :error-messages="getValidationErrors('name')"
            @blur="validateField('name')"
          />
          <div class="d-flex ml-2" style="margin-top: 2px">
            <ListActionBtn
              label="Сохранить"
              icon="fa-save"
              color="success"
              :icon-size="14"
              class="mr-1"
              :disabled="
                isCreate ? !$can('BPC_C') : !$can('BPC_U') || disableActions
              "
              @click="isCreate ? handleCreate() : handleSave()"
            />
            <ListActionBtn
              label="Удалить"
              icon="fa-trash-alt"
              color="error"
              :icon-size="14"
              :disabled="
                !$can('BPC_D') || isDefault || disableActions || isCreate
              "
              @click="handleRemove"
            />
          </div>
        </div>

        <v-text-field
          v-model="description"
          label="Описание"
          outlined
          dense
          :readonly="!$can('BPC_U')"
          :error-messages="getValidationErrors('description')"
          @blur="validateField('description')"
        />
        <v-card v-if="!isDefault" outlined>
          <v-card-text class="d-flex flex-column">
            <div class="d-flex align-center mb-2">
              <h4>Привязанные организации</h4>
              <v-btn
                v-if="$can('BPC_OA')"
                elevation="0"
                class="ml-2"
                small
                @click="editBindedOrgs"
              >
                <v-icon size="14" class="mr-2">fa-edit</v-icon>
                редактировать
              </v-btn>
            </div>
            <div>
              <NoDataAlert v-if="!organizations.length">
                Отсутствуют
              </NoDataAlert>
              <template v-else>
                <v-chip
                  v-for="item in organizations"
                  :key="item.name"
                  small
                  class="mr-2 mb-2"
                >
                  {{ item.name }}
                </v-chip>
              </template>
            </div>
          </v-card-text>
        </v-card>

        <RuleTable
          ref="ruleTable"
          v-model="rules"
          :editing-rule="$can('BPC_U')"
          :header="[
            { key: 'reason', name: 'Причина' },
            { key: 'durationInMin', name: 'Длительность бана (в минутах)' },
            { key: 'unbanCondition', name: 'Условие разблокировки' },
          ]"
          :validation-rules="validationRules"
          @addRow="addRow"
          @cloneRow="cloneRow"
          @removeRow="removeRow"
        >
          <template
            #reason="{
              row,
              index,
              editing,
              getValidationErrors,
              validateField,
            }"
          >
            <Select
              v-if="editing"
              v-model="row.reason"
              :items="reasons"
              dense
              item-value="id"
              item-text="name"
              :error-messages="
                getValidationErrors('value.$each.' + index + '.reason')
              "
              @blur="validateField('value.$each.' + index + '.reason')"
            />
            <div v-else>{{ reasonsKV[row.reason] }}</div>
          </template>

          <template
            #durationInMin="{
              row,
              index,
              editing,
              getValidationErrors,
              validateField,
            }"
          >
            <v-text-field
              v-if="editing"
              v-model.number="row.durationInMin"
              type="number"
              outlined
              dense
              placeholder="Бессрочно"
              hide-details="auto"
              min="0"
              :error-messages="
                getValidationErrors('value.$each.' + index + '.durationInMin')
              "
              @blur="validateField('value.$each.' + index + '.durationInMin')"
            />
            <div v-else>{{ row.durationInMin || 'Бессрочно' }}</div>
          </template>

          <template
            #unbanCondition="{
              row,
              index,
              editing,
              getValidationErrors,
              validateField,
            }"
          >
            <Select
              v-if="editing"
              v-model="row.unbanCondition"
              :items="unbanConditions"
              dense
              item-value="key"
              item-text="name"
              :error-messages="
                getValidationErrors('value.$each.' + index + '.unbanCondition')
              "
              @blur="validateField('value.$each.' + index + '.unbanCondition')"
            />
            <div v-else>{{ unbanConditionsKV[row.unbanCondition] }}</div>
          </template>
        </RuleTable>
      </v-card-text>
    </v-card>
  </PageLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required, minValue } from 'vuelidate/lib/validators';
import { uniqueStringKey } from '@/utils/validators';
import validationMixin from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';
import PageLayout from '@/components/layouts/PageLayout';
import RuleTable from '@/components/RuleTable/RuleTable';
import Select from '@/components/controls/Select';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';
import NoDataAlert from '@/components/ui/NoDataAlert';
import ListActionBtn from '@/components/controls/buttons/ListActionBtn';

import structApi from '@/api/services/structures';
import bansApi from '@/api/services/bans';

const initialDate = () => ({
  key: '',
  name: '',
  description: '',
  rules: [],
  organizations: [],
});

const rulesTemplate = () => ({
  id: Math.random(),
  inspectionTypes: [],
  scenarioType: null,
  isCanceled: null,
  resolvedBy: null,
  result: null,
  visits: [],
  flags: [],
  reason: null,
  durationInMin: null,
  unbanCondition: null,
});

export default {
  // for exclude from keep-alive
  name: 'Item',
  components: {
    NoDataAlert,
    PageLayout,
    RuleTable,
    Select,
    ListActionBtn,
  },
  mixins: [validationMixin, waitable, routeGuardMixin],

  validations() {
    return {
      key: { required, uniqueStringKey },
      name: { required },
      description: { required },
    };
  },

  data: () => initialDate(),

  computed: {
    ...mapGetters('ADMIN/BANS_ITEM', ['reasons', 'unbanConditions']),

    validationRules() {
      return {
        reason: { required },
        durationInMin: { minValue: minValue(1) },
        unbanCondition: { required },
      };
    },

    reasonsKV() {
      return this.reasons.reduce((agg, el) => {
        agg[el.id] = el.name;
        return agg;
      }, {});
    },
    unbanConditionsKV() {
      return this.unbanConditions.reduce((agg, el) => {
        agg[el.key] = el.name;
        return agg;
      }, {});
    },

    disableActions() {
      return this.$wait('createSingleItem') || this.$wait('changeSingleItem');
    },

    isCreate() {
      return this.$route.name.includes('create');
    },

    title() {
      if (this.isCreate) return 'Создание профиля';
      return `Профиль ${this.singleItem ? `"${this.singleItem.key}"` : '...'}`;
    },

    isDefault() {
      if (this.isCreate) return false;
      return this.singleItem?.key === 'default';
    },
  },

  watch: {
    singleItem: {
      immediate: true,
      handler(val) {
        if (val) {
          this.key = val.key;
          this.name = val.name;
          this.description = val.description;

          // another godly crutch due to out backend not prividing us
          // with an unique key/value for each rule
          // .. therefore in order to maintain reactivity we need to
          // generate random id for each rule      \o/
          this.rules =
            val?.rules?.map(el => {
              el.id = Math.random();
              return el;
            }) || [];
          this.organizations = val.organizations || [];
        }
      },
    },
  },

  created() {
    this.$loadingNotify(
      this.fetchReasons(),
      'fetchReasons',
      'Не удалось загрузить список причин бана',
    );

    this.$loadingNotify(
      this.fetchUnbanConditions(),
      'fetchingUnbanConditions',
      'Не удалось загрузить список условий разбана',
    );
  },

  mounted() {
    // set default values
    if (this.isCreate) Object.assign(this.$data, initialDate());
  },

  methods: {
    ...mapActions('ADMIN/BANS_ITEM', [
      'createSingleItem',
      'changeSingleItem',
      'deleteSingleItem',
      'fetchReasons',
      'fetchUnbanConditions',
    ]),

    validate() {
      this.$v.$touch();
      const localFormIsValid = !this.$v.$invalid;
      const tableIsValid = this.$refs.ruleTable.validate();

      if (!(localFormIsValid && tableIsValid)) {
        this.$notify({
          group: 'note',
          type: 'error',
          title: 'Неверно заполнены поля',
          text: 'Исправьте ошибки и повторите снова',
        });
        return false;
      }
      return true;
    },

    addRow() {
      const row = rulesTemplate();
      this.rules.push(row);
      this.$refs.ruleTable.startEditing(row.id);
    },

    cloneRow(id) {
      const index = this.rules.findIndex(el => el.id === id);
      const data = JSON.parse(JSON.stringify(this.rules[index]));
      data.id = Math.random();

      this.rules.splice(index + 1, 0, data);
      this.$refs.ruleTable.startEditing(data.id);
    },

    removeRow(id) {
      const index = this.rules.findIndex(el => el.id === id);
      this.$refs.ruleTable.stopEditing(id);
      this.rules.splice(index, 1);
    },

    generateParams() {
      const copyRules = this.rules.reduce((acc, el) => {
        const copyEl = { ...el };
        // it's generated with a random id for the unique keys of newlines
        delete copyEl.id;
        // if user has set an empty field or 0, the value needed set to null
        copyEl.durationInMin = el.durationInMin || null;
        acc.push(copyEl);
        return acc;
      }, []);

      return {
        key: (this.key || '').trim(),
        name: (this.name || '').trim(),
        description: (this.description || '').trim(),
        rules: copyRules,
      };
    },

    handleCreate() {
      if (!this.validate()) return;

      const data = this.generateParams();

      const createHandler = async () => {
        const item = await this.createSingleItem(data);
        try {
          const orgIds = this.organizations.map(el => el.id);
          if (orgIds.length)
            await bansApi.bindOrgsToProfile(item.key, { orgIds });
        } finally {
          this.$router.push({
            name: 'admin:bans:item',
            params: { key: item.key },
          });
        }
      };

      this.$loadingNotify(
        createHandler(),
        'createSingleItem',
        'Ошибка при создании профиля',
        'Профиль успешно создан',
      );
    },

    handleSave() {
      if (!this.validate()) return;

      const params = this.generateParams();
      const data = {
        id: this.singleItem.key,
        ...params,
      };

      const changedKey = params.key !== this.singleItem?.key;

      this.$loadingNotify(
        this.changeSingleItem(data).then(() => {
          this.$refs.ruleTable.clearEditingList();
          if (changedKey)
            this.$router.push({
              name: 'admin:bans:item',
              params: { key: params.key },
            });
        }),
        'changeSingleItem',
        'Ошибка при изменении профиля',
        'Профиль успешно сохранен',
      );
    },

    handleRemove() {
      this.$openModal(() => import('@/components/crud/DeleteModal.vue'), {
        item: this.singleItem,
        onDelete: async key => {
          await this.deleteSingleItem(key);
          this.$router.push({ name: 'admin:bans' });
        },
        messages: {
          description: 'Вы точно хотите удалить профиль?',
          successfulAction: 'Профиль удален',
          errorAction: 'Произошла ошибка удаления профиля',
        },
      });
    },

    async generateOrgsList(orgIds = []) {
      let data = [];
      if (orgIds?.length)
        data = await structApi.getOrganizationPreviewsBy(orgIds);
      this.organizations = data || [];
    },

    setOrgRelatives(orgIds) {
      this.generateOrgsList(orgIds);

      if (!this.isCreate)
        this.$loadingNotify(
          bansApi.bindOrgsToProfile(this.singleItem.key, { orgIds }),
          'updateBindedOrgs',
          'Произошла ошибка обновления списка привязанных организаций',
          'Привязанные организации изменены',
        );
    },

    editBindedOrgs() {
      this.$openModal('asyncSelectModal', {
        component: OrganizationSelect,
        value: this.organizations,
        multiple: true,
        componentProps: {
          accessLevel: 'full',
        },
        onSubmit: async orgIds => this.setOrgRelatives(orgIds),
        messages: {
          title: 'Редактирование списка организаций',
          warning: 'Список организаций отсутствует',
        },
      });
    },
  },
};
</script>
