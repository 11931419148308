<template>
  <v-layout fill-height justify-center align-center column>
    <Spinner
      v-if="isFetchingShift"
      title="Загрузка данных о смене"
      size="80"
      line-width="7"
    />
    <WarningBlock
      v-else-if="isOutsideShift && !shiftError"
      :title="{ title: 'Готовы начать смену?', content: 'Вы вне смены.' }"
    />
    <WarningBlock
      v-else-if="isOutsideShift && shiftError"
      :title="{
        title: 'Ваша смена завершена',
        content: `Это можно произойти по двум причинам:
              – вы закрыли её в другом окне;
              – её закрыл сервер потому, что прошло более 27 часов с начала смены;`,
      }"
    />
    <WarningBlock v-else-if="isError" :error="error" />

    <v-layout
      v-else-if="isReadList"
      fill-height
      justify-center
      align-center
      column
    >
      <Spinner v-if="isFetchingInspections || loadingPoints" />
      <div v-else class="my-5 d-flex flex-column" style="gap: 14px">
        <v-card v-if="!point && !includeAllPoints">
          <v-alert type="warning" class="ma-0">Выберите точку выпуска</v-alert>
        </v-card>

        <v-card v-else-if="!inspections.length">
          <v-card-text>Список осмотров пуст</v-card-text>
        </v-card>

        <InspectionCard
          v-for="item in inspections"
          v-else
          :key="`${item.id}__${item.status}`"
          :value="item"
          :loading="loadingInspectionId === item.id"
          @click="() => setInspect(item)"
        />
      </div>
    </v-layout>

    <template v-else-if="isInspecting">
      <Inspection />
    </template>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import InspectionCard from './InspectionCard.vue';
import WarningBlock from './WarningBlock.vue';
import Inspection from './Inspection.vue';

export default {
  components: {
    Spinner,
    InspectionCard,
    WarningBlock,
    Inspection,
  },

  data: () => ({
    loadingInspectionId: null,
  }),

  computed: {
    ...mapGetters('MEDCAB_PASSIVE', [
      'isMedcabDebug',

      'isFetchingInspections',
      'isLoadedInspections',

      'isFetchingShift',
      'isOutsideShift',
      'isReadList',
      'isInspecting',
      'isError',
      'error',
      'shiftError',

      'closedInspections',
      'inspections',
      'point',
      'includeAllPoints',
      'loadingPoints',
    ]),
  },

  watch: {
    isInspecting(val) {
      // Если медкаб перешел в состояние осмотра,
      // то сбрасываем блокировку карточки
      if (val) this.loadingInspectionId = null;
    },
  },

  methods: {
    ...mapActions('MEDCAB_PASSIVE', ['take']),
    setInspect(item) {
      if (this.loadingInspectionId) return;

      this.loadingInspectionId = item.id;

      // Сбросить блокировку фрода через 10 сек.
      // Этого времени точно должно хватить для выполнения запроса,
      // иначе это серверная проблема.
      const timeout = setTimeout(() => {
        this.loadingInspectionId = null;
      }, 10_000);

      this.take({ inspectionId: item.id })
        .catch(err => {
          console.error(err);
          this.loadingInspectionId = null;
        })
        .finally(() => clearTimeout(timeout));
    },
  },
};
</script>
