<template>
  <v-simple-table dense>
    <colgroup>
      <col width="30%" />
      <col width="65%" />
      <col width="5%" />
    </colgroup>
    <thead>
      <tr>
        <th class="pa-4">Сервис</th>
        <th class="pa-4">Привязанный профиль</th>
        <th class="pa-4" />
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in items" :key="item.key">
        <td class="pa-4 grey--text">{{ item.label }}</td>
        <td class="pa-4">
          <LinkButton
            :text="item.profileName"
            :action="
              item.routeName &&
              item.rules.linkToDetails &&
              $can(item.rules.linkToDetails)
            "
            :to="{
              name: item.routeName,
              params: { key: item.profileKey },
            }"
          />
          <span
            v-if="item.profileDefault"
            class="text--disabled"
            style="white-space: nowrap"
          >
            (Профиль по умолчанию)
          </span>
        </td>
        <td class="pa-4">
          <div class="d-flex justify-end">
            <v-tooltip v-if="item.rules.change && $can(item.rules.change)" left>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  v-on="on"
                  @click="changeProfile(item.key, item.profileKey)"
                >
                  <v-icon small>fa-edit</v-icon>
                </v-btn>
              </template>
              <span>Изменить</span>
            </v-tooltip>
          </div>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
// api
import processingApi from '@/api/services/processing';
import bansApi from '@/api/services/bans';
import medrecApi from '@/api/services/medrec';
import stickersApi from '@/api/services/stickers';

import waitable from '@/utils/mixins/waitable';
import LinkButton from '@/components/controls/buttons/LinkButton.vue';

const SERVICES = {
  bans: {
    label: 'Блокировки',
    routeName: 'admin:bans:item',
    rules: {
      fetchList: 'BPC_P',
      linkToDetails: 'BPC_R',
      change: 'BPO_BP',
    },
    fetchList: bansApi.getProfilesPreview,
  },
  documentsOutgoing: {
    label: 'Исходящие документы',
    routeName: 'admin:documents-outgoing:item',
    rules: {
      fetchList: 'MR_PP',
      linkToDetails: 'MR_PR',
      change: 'ODCO_B',
    },
    fetchList: medrecApi.getProfilesPreview,
  },
  stickers: {
    label: 'Стикеры',
    routeName: 'admin:stickers:item',
    rules: {
      fetchList: 'ST_PP',
      linkToDetails: 'ST_PR',
      change: 'SOP_B',
    },
    fetchList: stickersApi.getProfilesPreview,
  },
  processing: {
    label: 'Процессинг',
    rules: {
      fetchList: 'PPR_L',
      change: 'PPR_B',
    },
    fetchList: processingApi.getProfilesPreview,
  },
};

export default {
  components: { LinkButton },
  mixins: [waitable],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  computed: {
    items() {
      return Object.keys(SERVICES)
        .filter(key => this.$can(SERVICES[key].rules.fetchList))
        .map(key => ({
          key,
          label: SERVICES[key].label,
          routeName: SERVICES[key].routeName,
          profileKey: this.value[key]?.key,
          profileName: this.value[key]?.name,
          profileDefault: this.value[key]?.key === 'default',
          rules: SERVICES[key].rules,
        }))
        .filter(item => item.profileKey);
    },
  },

  methods: {
    changeProfile(type, value) {
      this.$openModal('selectModal', {
        value,
        itemValue: 'key',
        items: SERVICES[type].fetchList(),
        messages: {
          title: SERVICES[type].label,
          warning: `Профили отсутствуют`,
        },
        onSubmit: key =>
          this.$loadingNotify(
            this.$store.dispatch(
              'STRUCTURES/ORGANIZATION_ITEM/changeRelatedProfile',
              {
                type,
                key,
              },
            ),
            'updateGroups',
            'Произошла ошибка обновления профиля',
            'Профиль успешно обновлен',
          ),
      });
    },
  },
};
</script>
