<template>
  <List
    :available-actions="{
      showAction: false,
      createAction: false,
    }"
  >
    <template #item.actions="{ item }">
      <v-tooltip v-if="$can('P_EVA')" left>
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" icon v-on="on" @click="openWeightForm(item)">
            <v-icon small>mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
        <span>Изменить значение</span>
      </v-tooltip>
    </template>
  </List>
</template>

<script>
import ListFactory from '@/components/crud/ListFactory';
import { messages, entity, filters } from '../entity';

const List = ListFactory({
  storeModule: 'MEDBLOCK/RATINGS',
  messages,
  entity,
  filters,
});

export default {
  components: { List },

  methods: {
    openWeightForm(weight) {
      this.$openModal('medblock/evaluation', { weight });
    },
  },
};
</script>
