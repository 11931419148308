<template>
  <ModernModalLayout
    size="large"
    :title="modalTitle"
    close-label="Отмена"
    :watch-for="data"
  >
    <AsyncWrapper :handler="fetch">
      <v-form>
        <v-autocomplete
          v-if="!document"
          v-model="data.documentId"
          label="Справка"
          item-value="id"
          item-text="name"
          outlined
          dense
          class="mt-1"
          :items="boundariesDocs"
          :error-messages="getValidationErrors('data.documentId')"
          @blur="validateField('data.documentId')"
        >
          <template #item="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle>
                Дата выдачи: {{ item.issuedAt }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="item.diagnosis">
                Диагноз: {{ item.diagnosis }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
        <v-container>
          <v-row>
            <template v-for="field in boundaryFields">
              <v-col :key="field.name + 'field'" class="py-0" cols="4">
                <div class="mb-3 text-body-2">{{ field.name }}</div>
                <div class="d-flex">
                  <v-text-field
                    v-model.number="field.data().lower"
                    label="От"
                    class="mr-2"
                    type="number"
                    dense
                    outlined
                    :error-messages="
                      getValidationErrors(field.validation + '.lower')
                    "
                    @blur="validateField(field.validation + '.lower')"
                  />
                  <v-text-field
                    v-model.number="field.data().upper"
                    label="До"
                    dense
                    outlined
                    type="number"
                    :error-messages="
                      getValidationErrors(field.validation + '.upper')
                    "
                    @blur="validateField(field.validation + '.upper')"
                  />
                </div>
              </v-col>
              <v-divider
                v-if="field.dividerAfter"
                :key="field.name + 'divider'"
                vertical
              />
            </template>
          </v-row>
        </v-container>

        <v-divider />
        <v-tabs v-model="chartsTab" fixed-tabs="fixed-tabs">
          <v-tab key="pressure">Артериальное давление</v-tab>
          <v-tab key="pulse">Пульс</v-tab>
        </v-tabs>
        <v-tabs-items v-model="chartsTab">
          <v-tab-item key="pressure">
            <StatsChart :value="measurementsHistory.measurements" />
          </v-tab-item>
          <v-tab-item key="pulse">
            <StatsChart
              :value="measurementsHistory.measurements"
              type="pulse"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </AsyncWrapper>
    <template #actions:append>
      <v-btn
        class="px-16"
        depressed
        color="primary"
        :loading="$wait('handleSetBoundaries')"
        @click="submit"
      >
        Применить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import validationMixin from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';

import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import AsyncWrapper from '@/components/AsyncWrapper.vue';
import StatsChart from '@/modules/employees/components/StatsChart';

import { mapActions, mapGetters } from 'vuex';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';

export default {
  components: { ModernModalLayout, StatsChart, AsyncWrapper },
  mixins: [validationMixin, waitable],

  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
    employee: {
      type: Object,
      required: true,
    },
    document: {
      type: Object,
      default: null,
    },
  },

  validations: {
    data: {
      values: {
        pressure: {
          systolic: {
            lower: {
              required,
              minValue: minValue(60),
              maxValue: maxValue(220),
            },
            upper: {
              required,
              minValue: minValue(60),
              maxValue: maxValue(220),
            },
          },
          diastolic: {
            lower: {
              required,
              minValue: minValue(40),
              maxValue: maxValue(150),
            },
            upper: {
              required,
              minValue: minValue(40),
              maxValue: maxValue(150),
            },
          },
        },
        pulse: {
          lower: {
            required,
            minValue: minValue(30),
            maxValue: maxValue(150),
          },
          upper: {
            required,
            minValue: minValue(30),
            maxValue: maxValue(150),
          },
        },
      },
      documentId: { required },
    },
  },

  data() {
    return {
      data: {
        documentId: null,
        values: {
          pressure: {
            systolic: { lower: null, upper: null },
            diastolic: { lower: null, upper: null },
          },
          pulse: { lower: null, upper: null },
        },
      },

      chartsTab: 'pressure',
    };
  },

  computed: {
    ...mapGetters('DOCUMENTS/BOUNDARIES', [
      'measurementsHistory',
      'employeeRecord',
    ]),

    modalTitle() {
      return `Установка границ`;
    },

    boundariesDocs() {
      const docs = this.employeeRecord.validCertificates || [];
      return docs.filter(el => el.applicableFor === 'boundaries');
    },

    boundaryFields() {
      return [
        {
          name: 'Систолическое давление',
          data: () => this.data.values.pressure.systolic,
          validation: 'data.values.pressure.systolic',
          dividerAfter: true,
        },
        {
          name: 'Диастолическое давление',
          data: () => this.data.values.pressure.diastolic,
          validation: 'data.values.pressure.diastolic',
          dividerAfter: true,
        },
        {
          name: 'Пульс',
          data: () => this.data.values.pulse,
          validation: 'data.values.pulse',
        },
      ];
    },
  },

  created() {
    if (this.document) this.data.documentId = this.document.id;
  },

  methods: {
    ...mapActions('DOCUMENTS/BOUNDARIES', [
      'fetchMeasurementsHistory',
      'fetchEmployeeRecord',
    ]),

    async fetch() {
      await this.$loadingNotify(
        this.fetchMeasurementsHistory(this.employee.id),
        'fetchingHistory',
        'Произошла ошибка загрузки истории осмотров',
      );
      await this.$loadingNotify(
        this.fetchEmployeeRecord(this.employee.id),
        'fetchingEmployeeRecord',
        'Произошла ошибка загрузки истории осмотров',
      );

      this.data.values = JSON.parse(
        JSON.stringify(this.employeeRecord.boundaries.values),
      );
    },
    async submit() {
      if (!this.validate()) return;

      // If user is STUPID, has 47 chromosomes and enters LOWER value higher
      // then HIGHER value - we do not bother telling him about that, he won't
      // understand it.. Instead we just change values by ourselfes.
      // And im lazy to type much, so i redefine values to type less:
      const sysL = this.data.values.pressure.systolic.lower;
      const sysU = this.data.values.pressure.systolic.upper;
      if (sysL > sysU) {
        this.data.values.pressure.systolic.lower = sysU;
        this.data.values.pressure.systolic.upper = sysL;
      }
      const disL = this.data.values.pressure.diastolic.lower;
      const disU = this.data.values.pressure.diastolic.upper;
      if (disL > disU) {
        this.data.values.pressure.diastolic.lower = disU;
        this.data.values.pressure.diastolic.upper = disL;
      }
      const pulseL = this.data.values.pulse.lower;
      const pulseU = this.data.values.pulse.upper;
      if (pulseL > pulseU) {
        this.data.values.pulse.lower = pulseU;
        this.data.values.pulse.upper = pulseL;
      }

      await this.$loadingNotify(
        this.onSubmit(this.employee.id, this.data),
        'handleSetBoundaries',
        'Произошла ошибка установки границ',
        'Границы успешно установлены',
      );

      this.$emit('close');
    },
  },
};
</script>
