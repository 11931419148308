<template>
  <v-card
    class="d-flex medcab__actions ma-0 mt-3 py-1 grey lighten-4"
    :class="{ 'grey darken-4': themeIsDark, 'grey lighten-4': !themeIsDark }"
  >
    <v-col class="py-0 align-center" cols="3" lg="3">
      <template v-if="actions.fail">
        <v-btn
          v-if="isInspecting"
          data-sentry-mark="medcab:passive:openResolve"
          color="error"
          height="100%"
          width="100%"
          outlined
          elevation="2"
          :disabled="awaitingAction"
          @click="openResolveModal"
        >
          {{ actions.fail }}
        </v-btn>
      </template>

      <div
        class="d-flex flex-column justify-space-between"
        style="margin-top: -50px"
      >
        <v-card v-show="isReadList && !isError" style="width: 100%">
          <PointSelect
            :value="point"
            access-level="full"
            :disabled="!!includeAllPoints || loadingPoints"
            @change="choicePoint"
          />
        </v-card>

        <v-card
          v-show="isReadList && !isError"
          style="width: 100%"
          class="mt-3 pa-3"
        >
          <v-checkbox
            :value="includeAllPoints"
            :disabled="loadingPoints"
            dense
            class="pa-0 ma-0"
            label="По всем точкам"
            hide-details="auto"
            @change="toggleAllPoints"
          />
        </v-card>
      </div>
    </v-col>

    <v-col class="py-0 d-flex justify-center" cols="6" lg="6">
      <template v-if="isOnShift && isError">
        <v-btn
          data-sentry-mark="medcab:passive:backToList"
          height="100%"
          color="primary"
          outlined
          @click="backToList"
        >
          Вернуться <br />
          к списку
        </v-btn>
      </template>

      <template v-if="isOutsideShift">
        <v-btn
          data-sentry-mark="medcab:passive:openShift"
          height="100%"
          color="primary"
          @click="handlerOpenShift"
        >
          Начать смену
        </v-btn>
      </template>

      <template v-if="isReadList">
        <ScoreBlock label="В очереди" :score="inspectionsCount" />
        <ScoreBlock label="Осмотров за смену" :score="closedInspections" />
        <v-btn
          data-sentry-mark="medcab:passive:stopShift"
          height="100%"
          color="warning"
          outlined
          @click="stopShiftModal"
        >
          Окончить <br />
          смену
        </v-btn>
        <FakeInspections v-if="isMedcabDebug && !isError" />
      </template>

      <template v-if="isInspecting">
        <ScoreBlock label="Таймер" :score="timer" />
        <v-btn
          data-sentry-mark="medcab:passive:backToListFromInspection"
          height="100%"
          color="warning"
          outlined
          :disabled="awaitingAction"
          @click="releaseInspection"
        >
          Назад
        </v-btn>
      </template>
    </v-col>

    <v-col class="py-0" cols="3" lg="3">
      <template v-if="actions.pass">
        <v-btn
          v-if="isInspecting"
          data-sentry-mark="medcab:passive:permitInspection"
          color="success"
          height="100%"
          width="100%"
          outlined
          elevation="2"
          :disabled="!canMedicPermit || awaitingAction"
          @click="permitInspection"
        >
          {{ actions.pass }}
        </v-btn>
      </template>

      <template v-if="actions.validate">
        <v-btn
          v-if="isInspecting"
          data-sentry-mark="medcab:passive:openValidate"
          color="primary"
          height="100%"
          width="100%"
          outlined
          elevation="2"
          :disabled="awaitingAction"
          @click="openValidateModal"
        >
          {{ actions.validate }}
        </v-btn>
      </template>
    </v-col>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DISABLED_2FA, FETCH_STATUS } from '@/utils/constants';
import { WebStorage } from '@/plugins/webStorage';
import waitable from '@/utils/mixins/waitable';
import PointSelect from '@/components/controls/structures/PointSelect.vue';
import ScoreBlock from './ScoreBlock.vue';
import FakeInspections from './FakeInspections.vue';
import authApi from '@/api/services/auth';
import signApi from '@/api/services/sign';

export default {
  components: { PointSelect, ScoreBlock, FakeInspections },
  mixins: [waitable],

  data: () => ({
    modalIds: [],
  }),

  computed: {
    ...mapGetters('MEDCAB_PASSIVE', [
      'isMedcabDebug',

      'isError',
      'isFetchingShift',
      'isOnShift',
      'isOutsideShift',
      'isReadList',
      'isInspecting',
      'inspectionId',

      'closedInspections',
      'timer',

      'point',
      'includeAllPoints',
      'loadingPoints',
      'inspections',
      'takeStatus',
    ]),

    ...mapGetters('MEDCAB_INSPECTION', [
      'type',
      'actions',
      'assistantDisabled',
      'assistance',
      'videoMatch',
    ]),

    inspectionsCount() {
      return this.inspections.length;
    },

    themeIsDark() {
      return this.$store.getters.themeIsDark;
    },

    actions() {
      return this.type?.details?.actions || {};
    },

    canMedicPermit() {
      if (this.assistantDisabled) return true;
      return this.assistance.result?.level !== 'obligatory';
    },

    awaitingAction() {
      return (
        this.$wait('resolution') ||
        this.$wait('release') ||
        this.takeStatus === FETCH_STATUS.LOADING
      );
    },
  },

  watch: {
    isInspecting(val) {
      if (!val) this.modalIds.forEach(id => this.$closeModal(id));
    },
  },

  created() {
    this.syncToggle();
  },

  methods: {
    ...mapActions('MEDCAB_PASSIVE', [
      'openShift',
      'closeShift',
      'viewList',
      'clearError',
      'release',
      'choicePoint',
      'toggleAllPoints',
      'syncToggle',
    ]),
    ...mapActions('AUTH', ['getDisabled2fa']),

    backToList() {
      this.viewList();
    },

    async releaseInspection() {
      if (this.inspectionId)
        await this.$loadingNotify(
          this.release(),
          'release',
          'Произошла ошибка, при повторении перезагрузите страницу',
        ).catch(() => {});
      this.viewList();
    },

    handlerOpenShift() {
      const openShift = async () => {
        await this.getDisabled2fa();
        const disabled2fa = WebStorage.getItem(DISABLED_2FA) === 'true';
        // Тестовая подпись
        if (!disabled2fa) {
          const data = await authApi.getCryptoParams({
            thumbprint:
              this.$store.getters['TOKENS/activeToken'].data.thumbprint,
          });
          await signApi.sendTestTaskComplete(data);
        }

        this.openShift();
      };
      this.$loadingNotify(
        openShift(),
        'openShift',
        'Ошибка при подписании тестового документа',
      );
    },

    stopShiftModal() {
      this.$openModal('medcab/closeShift', {
        closedInspections: this.closedInspections,
        closeShift: this.closeShift,
      });
    },

    permitInspection() {
      const actionName = this.actions.pass || this.actions.validate;

      const props = {
        title: actionName + ' работника?',
        assistantDisabled: this.assistantDisabled,
        medcabType: 'passive',
        onSubmit: ({ comment }) =>
          this.$loading(
            this.$store.dispatch('MEDCAB_PASSIVE/result', {
              success: true,
              comment: (comment || '').trim() || null,
              assistantDisabled: this.assistantDisabled,
              remarks: [],
            }),
            'resolution',
          ),
        yes: 'Подтвердить',
      };

      // Сообщение о подлоге на видео
      if (
        this.videoMatch?.status === 'done' &&
        this.videoMatch?.result?.color === 'RED'
      ) {
        props.warningText =
          'Похоже, что на видео другой человек, вы уверены в своем действии?';
        props.warningType = 'error';
      }

      const id = this.$openModal('medcab/prompt', props);
      this.modalIds.push(id);
    },

    openResolveModal() {
      const props = {
        medcabType: 'passive',
        onSubmit: (comment, remarks) =>
          this.$loading(
            this.$store.dispatch('MEDCAB_PASSIVE/result', {
              success: false,
              comment,
              remarks,
              assistantDisabled: this.assistantDisabled,
            }),
            'resolution',
          ),
      };
      const id = this.$openModal('medcab/resolve', props);
      this.modalIds.push(id);
    },

    openValidateModal() {
      const props = {
        medcabType: 'passive',
        onSubmit: (comment, remarks) =>
          this.$loading(
            this.$store.dispatch('MEDCAB_PASSIVE/result', {
              comment,
              remarks,
              assistantDisabled: this.assistantDisabled,
            }),
            'resolution',
          ),
      };
      const id = this.$openModal('medcab/resolve', props);
      this.modalIds.push(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.medcab__actions {
  position: relative;
  z-index: 3;

  & > div {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}
</style>
