/* eslint-disable no-sequences */
import gateApi from '@/api/services/gate';

const EMPTY_PROFILE = {
  devicesSettings: null,
  scenarios: {},
};

export default {
  state: {
    configProfile: EMPTY_PROFILE,
  },
  getters: {
    configProfile: state => state.configProfile,
  },
  mutations: {
    configProfile: (state, value) => (state.configProfile = value),
  },
  actions: {
    async fetchConfigProfile({ getters, commit }) {
      const id = getters.singleItem.id;
      const orgProfileKey = getters.parentOrgProfile.key;

      const res = await gateApi.getHostProfile(id, orgProfileKey);

      commit('configProfile', res);
      return getters.configProfile;
    },

    async updateConfigProfile({ dispatch, getters }, payload) {
      const id = getters.singleItem.id;

      await gateApi.updateHostProfile(id, payload);
      await dispatch('fetchConfigProfile');

      return getters.configProfile;
    },

    async deleteConfigProfile({ commit, getters }) {
      const id = getters.singleItem.id;
      await gateApi.deleteHostProfile(id);
      commit('configProfile', EMPTY_PROFILE);
    },

    async fetchAndSetOrgSettings({ commit, getters }) {
      const orgProfileKey = getters.parentOrgProfile.key;
      const res = await gateApi.getProfile(orgProfileKey);
      // Удаляем типы осмотров, которые выключены в родительской орге
      // их нельзя редактировать
      res.scenarios = Object.keys(res.scenarios).reduce((acc, key) => {
        if (res.scenarios[key].isEnabled) {
          acc[key] = res.scenarios[key];
          // Убираем выключенные шаги в профиле
          acc[key].steps = Object.keys(acc[key].steps)
            .filter(e => acc[key].steps[e].status === true)
            .reduce((a, e) => ((a[e] = acc[key].steps[e]), a), {});
        }

        return acc;
      }, {});

      commit('configProfile', {
        devicesSettings: res.devicesSettings,
        scenarios: res.scenarios,
      });
    },
  },
};
