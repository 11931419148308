<template>
  <ModernModalLayout title="Повторная печать стикера" :loading="loading">
    <v-alert dense outlined type="info">
      Распечатать можно только на ПАК со статусом "Активен" и без запрета на
      повторную печать стикера
    </v-alert>
    <v-form>
      <HostSelect
        v-model="selectedHost"
        :org-ids="[organizationId]"
        is-active
        :is-not-reprint="false"
        outlined
        access-level="any"
        :clearable="false"
        hide-details="auto"
        :error-messages="getValidationErrors('selectedHost')"
      />
    </v-form>

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :disabled="$wait('reprintSticker')"
        @click="onSubmit"
      >
        Печать
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

// api
import stickersApi from '@/api/services/stickers';

import waitable from '@/utils/mixins/waitable';
import validationMixin from '@/utils/validation';
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import HostSelect from '@/components/controls/structures/HostSelect.vue';

export default {
  components: { ModernModalLayout, HostSelect },
  mixins: [validationMixin, waitable],
  props: {
    inspectionId: { required: true, type: Number },
    organizationId: { type: Number, default: null },
    hostId: { type: Number, default: null },
  },

  validations() {
    return {
      selectedHost: { required },
    };
  },

  data: () => ({
    selectedHost: null,
    hosts: [],
    preloader: false,
  }),

  computed: {
    loading() {
      return this.preloader;
    },
  },

  mounted() {
    this.preloading();
  },

  methods: {
    ...mapActions('STRUCTURES', ['fetchHostPreviewsBy']),

    async preloading() {
      if (!this.hostId) return;

      this.preloader = true;
      // inspection might have been done on host that is active, but later
      // the same host might have been deactivated. For such occasions we check
      // if the host is active or not before setting it to component value
      try {
        const hostPreview = await this.$loadingNotify(
          this.fetchHostPreviewsBy([this.hostId]).then(res => res[0]),
          'fetchingHostPreview',
          'Произошла ошибка загрузки превью ПАК',
        );

        const { isActive, isNotReprint } = hostPreview;
        if (isActive && !isNotReprint) this.selectedHost = this.hostId;
      } catch (err) {
        console.error(err);
      } finally {
        this.preloader = false;
      }
    },

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      await this.$loadingNotify(
        stickersApi
          .reprint(this.inspectionId, {
            hostId: this.selectedHost,
          })
          .catch(data => {
            throw new Error(data?.response.data.message || data);
          }),
        'reprintSticker',
        'Ошибка печати стикера',
        `Стикер отправлен на печать для медосмотра #${this.inspectionId}`,
      ).then(() => this.$emit('close'));
    },
  },
};
</script>
