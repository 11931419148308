import medrecApi from '@/api/services/medrec';

export default {
  state: {
    riskGroups: null,
  },

  getters: {
    riskGroups: state => state.riskGroups || {},
  },

  mutations: {
    riskGroups: (state, value) => (state.riskGroups = value),
  },

  actions: {
    async fetchRiskGroups({ getters, commit }) {
      const result = await medrecApi.getOrgRiskGroupsSettings(getters.id);
      commit('riskGroups', result);
      return result;
    },
    async updateRiskGroups({ getters, commit }, riskKey) {
      const payload = { ...getters.riskGroups };

      // if key is boolean means user wants to toggle all risk groups on / off
      typeof riskKey === 'boolean'
        ? Object.keys(payload).forEach(key => (payload[key] = riskKey))
        : (payload[riskKey] = !payload[riskKey]);

      const result = await medrecApi.updateOrgRiskGroupsSettings(
        getters.id,
        payload,
      );
      commit('riskGroups', result);
      return result;
    },
  },
};
