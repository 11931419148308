import { createHTTP } from '../api';
import { pollerServiceFactory } from '@/utils/pollerServiceFactory';
import env from '@/plugins/env';

export const history = createHTTP(env.get('VUE_APP_HISTORY_API') + '/v3');

const poller = pollerServiceFactory(taskId => history.get('/export/' + taskId));

export default {
  inspectionList(params) {
    return history.get('/inspections', { params }).then(res => res.data);
  },

  inspectionGet(id) {
    return history.get(`/inspections/${id}`).then(res => res.data);
  },

  inspectionArchived(id) {
    return history.get(`/archive/inspections/${id}`).then(res => res.data);
  },

  getEmployeeHistory(id, signal) {
    return history
      .get(`/employees/${id}/measurements`, { ...(signal && { signal }) })
      .then(res => res.data);
  },

  async inspectionExport(query) {
    const data = {
      ...query,
      format: 'xlsx',
    };
    const { taskId } = await history
      .post('/export/inspections', data)
      .then(res => res.data);

    return poller(taskId);
  },

  getInspectionLimits() {
    return history.get('/export/limits').then(res => res.data);
  },

  setApproval(inspectionId, params) {
    return history.post(`/inspections/${inspectionId}/approval`, params);
  },
};
