<template>
  <ActionsMenuItem
    :title="title"
    :subtitle="subtitle || 'Действие нельзя отменить'"
    :disabled="disabled"
    @click="openDeleteModal"
  >
    <template #icon>
      <v-icon>mdi-minus-circle-outline</v-icon>
    </template>
  </ActionsMenuItem>
</template>

<script>
import ActionsMenuItem from '@/components/ui/ActionsMenuItem';

export default {
  components: { ActionsMenuItem },

  props: {
    storeModule: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    backRoute: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: 'Удалить сие нечто',
    },
    description: {
      type: String,
      default: 'Ну вы вот прям точно уверены, что хотите удалить?',
    },
    subtitle: {
      type: String,
      default: null,
    },
    disabled: Boolean,
  },

  methods: {
    openDeleteModal() {
      this.$openModal('prompt', {
        yes: 'Удалить',
        no: 'Отмена',
        title: this.title,
        description: this.description,
        onSubmit: () =>
          this.$store
            .dispatch(this.storeModule + '/deleteListItem', this.id)
            .then(() => this.$router.push({ name: this.backRoute })),
      });
    },
  },
};
</script>
