<template>
  <v-select
    :value="value"
    label="Импортированные осмотры"
    item-value="value"
    item-text="name"
    hide-details="auto"
    outlined
    :items="importedStatuses"
    :menu-props="{ offsetY: true }"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    importedStatuses() {
      return [
        { name: 'Все осмотры', value: null },
        { name: 'Только импортированные', value: true },
        { name: 'Только не импортированные', value: false },
      ];
    },
  },
};
</script>
