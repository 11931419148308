import authApi from '@/api/services/auth';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { createModule } from '@/utils/vuex/createModule';

const state = {
  roleTypes: null,
};
const getters = {
  /**
   * @description Backend sets up a unique sort for list, first by name,
   *   then by type
   * @tag REDEFINED
   */
  listOrder: () => ({
    orderType: null,
    orderBy: null,
  }),

  roleTypes: state => state.roleTypes || [],
  roleTypeByKey: state => key =>
    (state.roleTypes || []).find(el => el.key === key)?.name || key,
};

const mutations = {
  setRoleTypes(state, list = []) {
    state.roleTypes = list;
  },
};

const actions = {
  async fetchRoleTypes({ commit }) {
    const data = await authApi.fetchRoleTypes();
    commit('setRoleTypes', data);
  },

  bindGroupsInRole(_, { key, newList = [] } = {}) {
    return authApi.bindGroupsToRole(key, newList);
  },
};

export default createModule(
  crudListFactory({
    fetchMethod: authApi.rolesList,
    createMethod: authApi.rolesCreate,
    updateMethod: authApi.rolesUpdate,
    deleteMethod: authApi.rolesDelete,
  }),
  { state, getters, mutations, actions },
);
