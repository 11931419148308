<template>
  <List
    :available-actions="{
      showAction: $can('DOC_RD'),
    }"
    @filters:resetHook="resetHook"
  >
    <template #filters="query">
      <v-col cols="12" sm="4" md="3">
        <SearchEmployee
          ref="empSearch"
          v-model="query.employeeIds"
          store="DOCUMENTS/INCOMING"
        />
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <Select
          v-model="query.types"
          label="Тип документов"
          :items="incomingDocumentTypes"
          item-value="key"
          dense
          multiple
        />
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <Select
          v-model="query.status"
          label="Статус документов"
          dense
          :items="filterStatuses"
        />
      </v-col>
    </template>

    <template #table.item.status="{ value }">
      <v-tooltip
        v-if="value && documentStatus[value]"
        top
        content-class="tooltip--show-white-space"
      >
        <template #activator="{ on }">
          <v-icon :color="documentStatus[value].color" v-on="on">
            {{ documentStatus[value].icon }}
          </v-icon>
        </template>
        <span>{{ documentStatus[value].title || '–' }}</span>
      </v-tooltip>
    </template>

    <template #table.item.name_type="{ item }">
      <b>{{ item.name }}</b>
      <br />
      {{ (getIncomingDocumentTypes(item.type) || {}).name || '–' }}
      <br />
      с {{ date(item.issuedAt) }}
      {{ item.dueDate ? `по ${date(item.dueDate)}` : '(бессрочно)' }}
    </template>

    <template #table.item.resolutionUser_resolutionDate="{ item }">
      <template v-if="item.resolution">
        {{ accounts[item.resolution.approvedBy] }}
        <br />
        {{ dateTime(item.resolution.date) }}
      </template>
    </template>
  </List>
</template>

<script>
import { mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import { DOCUMENT_STATUSES } from '@/modules/documents/constants';
import { entity, messages, filters } from '../entity';
import { date, dateTime } from '@/utils/convert';

import ListFactory from '@/components/crud/ListFactory';
import Select from '@/components/controls/Select';
import SearchEmployee from '@/modules/inspections/components/controls/SearchEmployee.vue';

const List = ListFactory({
  storeModule: 'DOCUMENTS/INCOMING',
  messages,
  entity,
  filters,
  viewRoute: 'documents:incoming:item',
});

const filterStatuses = [
  { id: null, name: 'Все статусы' },
  { id: 'PENDING', name: 'Ожидает подтверждения' },
  { id: 'VALID', name: 'Подтверждён' },
];

export default {
  components: { List, Select, SearchEmployee },

  extends: waitable,

  computed: {
    ...mapGetters('REGISTRY', [
      'incomingDocumentTypes',
      'getIncomingDocumentTypes',
    ]),
    ...mapGetters('DOCUMENTS/INCOMING_ITEM', ['employee']),
    ...mapGetters('DOCUMENTS/INCOMING', ['accounts']),

    filterStatuses: () => filterStatuses,

    documentStatus() {
      return DOCUMENT_STATUSES.reduce((agg, el) => {
        agg[el.value] = el;
        return agg;
      }, {});
    },
  },

  created() {
    this.$loadingNotify(
      this.$store.dispatch('REGISTRY/fetchIncomingDocumentTypes'),
      'fetchTypes',
      'Произошла ошибка загрузки типов документов',
    );
  },

  methods: {
    date,
    dateTime,

    resetHook() {
      this.$refs.empSearch.clear();
    },
  },
};
</script>
