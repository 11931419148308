<template>
  <ModernModalLayout
    size="full"
    height="100vh"
    :title="`Сформировать направление - ${shortName(employee)}`"
    hide-bottom
    dense-content
  >
    <div class="d-flex flex-column overflow-hidden" style="height: 100%">
      <v-card>
        <v-card-title class="d-flex" style="gap: 20px">
          <span>
            {{ `Выбрано осмотров ${selectedInspections.length}/5` }}
          </span>
          <v-btn
            color="primary"
            :disabled="!selectedInspections.length"
            :loading="$wait('fetchEmployeeAppointment')"
            @click="fetchAppointment"
          >
            Сформировать
          </v-btn>
          <div class="ml-auto d-flex">
            <v-btn
              text
              :disabled="$wait('fetchInspections')"
              @click="resetFilter"
            >
              Сбросить
            </v-btn>
            <v-btn
              :text="!needApplyFilters"
              :color="needApplyFilters ? 'primary' : null"
              :disabled="$wait('fetchInspections')"
              @click="updateList"
            >
              {{ needApplyFilters ? 'Применить' : 'Обновить' }}
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4" md="3">
              <DateRangePicker
                label="Диапазон осмотров"
                show-quick-options
                with-time
                outlined
                dense
                clearable
                :value="dates"
                :timezone="getUserTimeZone()"
                :disabled="$wait('fetchInspections')"
                @input="setDateFilters"
              />
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <Select
                v-model="query.types"
                label="Тип осмотров"
                dense
                multiple
                :items="validInspectionTypes"
                item-text="name"
                item-value="key"
                :disabled="$wait('fetchInspections')"
                @input="needApplyFilters = true"
              />
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <SelectRemarks
                v-model="query.remarks"
                label="Причина недопуска"
                dense
                multiple
                :items="validInspectionRemarks"
                :disabled="$wait('fetchInspections')"
                @input="needApplyFilters = true"
              />
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <v-select
                v-model="query.success"
                label="Пройденные"
                item-value="value"
                item-text="name"
                hide-details
                outlined
                dense
                :items="resultStatuses"
                :menu-props="{ offsetY: true }"
                :disabled="$wait('fetchInspections')"
                @input="needApplyFilters = true"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <div class="overflow-hidden" style="height: 100%">
        <div
          v-if="inspectionsIsNone"
          class="d-flex justify-center align-center"
          style="height: 100%"
        >
          <div class="text-h6 text-center">Осмотры отсутствуют</div>
        </div>
        <ColumnLayout
          v-else-if="inspections.length"
          grid-template-columns="450px 1fr"
          style="gap: 0"
        >
          <template #col-list>
            <v-list
              dense
              class="pa-0"
              color="grey"
              :class="themeIsDark ? 'darken-2' : 'lighten-4'"
              height="100%"
            >
              <div
                v-for="(item, index) in inspections"
                :key="item.id"
                class="d-flex align-center"
                :class="item.id !== currentId && 'grey lighten-4'"
                style="background: #dae5f0"
              >
                <v-checkbox
                  v-model="selectedInspections"
                  class="mt-0 ml-2 pa-0"
                  :value="item.id"
                  hide-details
                  :disabled="
                    selectedInspections.length === 5 &&
                    !selectedInspections.includes(item.id)
                  "
                />
                <InspectionsListItem
                  class="grey"
                  :class="themeIsDark ? 'darken-2' : 'lighten-4'"
                  :item="item"
                  :remarks="inspectionRemarks"
                  :remark-types="inspectionRemarkTypes"
                  :current-id="currentId"
                  @changeCurrent="current = $event"
                />
                <v-divider v-if="index !== inspections.length - 1" />
              </div>
              <v-list-item v-if="!allItemsLoaded" class="d-flex justify-center">
                <Spinner v-if="$wait('fetchInspections')" :size="30" />
                <ErrorBlock
                  v-if="inspectionsError"
                  style="width: 100%"
                  :error="inspectionsError"
                  @refresh="fetchData"
                />
                <div
                  v-else
                  v-show="!$wait('fetchInspections')"
                  v-intersect="fetchNext"
                  class="mt-16"
                  style="height: 1px"
                />
              </v-list-item>
            </v-list>
          </template>

          <template #col-item>
            <div v-if="currentId" style="height: 100%">
              <template v-if="$wait('fetchInspectionItem')">
                <Spinner :size="30" />
              </template>
              <template
                v-else-if="inspectionItemError"
                class="d-flex align-start"
              >
                <ErrorBlock
                  style="width: 100%"
                  :error="inspectionItemError"
                  @refresh="loadInspectionItem(current)"
                />
              </template>
              <template v-else-if="inspectionItem">
                <InspectionInfo
                  style="height: 100%"
                  :remarks="inspectionRemarks"
                  :remark-types="inspectionRemarkTypes"
                  :value="inspectionItem"
                  hide-media
                />
              </template>
            </div>
          </template>
        </ColumnLayout>
        <div v-else-if="inspectionsError" class="d-flex align-start">
          <ErrorBlock
            class="ma-5"
            style="width: 100%"
            :error="inspectionsError"
            @refresh="fetchData"
          />
        </div>
        <Spinner v-else />
      </div>
    </div>
  </ModernModalLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import Spinner from '@/components/Spinner.vue';
import ModernModalLayout from '@/components/layouts/ModernModalLayout.vue';
import ColumnLayout from '@/components/layouts/ColumnLayout.vue';
import InspectionsListItem from '@/modules/inspections/components/InspectionsListItem.vue';
import InspectionInfo from '@/modules/inspections/components/InspectionInfo.vue';
import ErrorBlock from '@/components/ErrorBlock';
import { shortName, getUserTimeZone } from '@/utils/convert';
import DateRangePicker from '@/components/controls/DateRangePicker';
import Select from '@/components/controls/Select';
import SelectRemarks from '@/modules/inspections/components/controls/SelectRemarks.vue';

const defaultFilterValues = [
  { name: 'Неважно', value: null },
  { name: 'Да', value: true },
  { name: 'Нет', value: false },
];

const emptyQuery = {
  dateStart: null,
  dateEnd: null,
  types: null,
  remarks: null,
  success: null,
};

export default {
  components: {
    Spinner,
    ModernModalLayout,
    ColumnLayout,
    InspectionsListItem,
    InspectionInfo,
    ErrorBlock,
    DateRangePicker,
    Select,
    SelectRemarks,
  },

  mixins: [waitable],

  props: {
    employee: { type: Object, required: true },
  },

  data: () => ({
    nextPage: 1,
    current: null,
    inspectionsError: null,
    inspectionItemError: null,
    selectedInspections: [],
    resultStatuses: defaultFilterValues,
    query: { ...emptyQuery },
    needApplyFilters: false,
  }),

  computed: {
    ...mapGetters('REGISTRY', [
      'inspectionRemarks',
      'inspectionRemarkTypes',
      'inspectionTypes',
    ]),
    ...mapGetters('INSPECTION_HISTORY', [
      'inspections',
      'inspectionsTotal',
      'inspectionItem',
    ]),
    ...mapGetters(['themeIsDark']),

    currentId() {
      return this.current?.id || null;
    },

    allItemsLoaded() {
      return this.inspections.length === (this.inspectionsTotal || 0);
    },

    inspectionsIsNone() {
      return this.inspectionsTotal === 0;
    },

    dates() {
      return {
        dateStart: this.query.dateStart || null,
        dateEnd: this.query.dateEnd || null,
      };
    },

    validInspectionTypes() {
      const INSPECTION_TYPE_KEYS = [
        'BEFORE_TRIP',
        'BEFORE_SHIFT',
        'AFTER_TRIP',
        'AFTER_SHIFT',
        'IN_SHIFT',
      ];
      return this.inspectionTypes.filter(type =>
        INSPECTION_TYPE_KEYS.includes(type.key),
      );
    },
    validInspectionRemarks() {
      return this.inspectionRemarks.filter(
        remark => remark.showAs !== 'TECHNICAL' && remark.type !== 'CANCEL',
      );
    },
  },

  watch: {
    current(val) {
      if (val) this.loadInspectionItem(val);
    },
  },

  async mounted() {
    await this.clearInspections();
    await this.fetchInspectionRemarks();
    await this.fetchInspectionTypes();
    await this.fetchData();
  },

  methods: {
    ...mapActions('INSPECTION_HISTORY', [
      'clearInspections',
      'fetchInspections',
      'fetchInspectionItem',
    ]),
    ...mapActions('REGISTRY', [
      'fetchInspectionRemarks',
      'fetchInspectionTypes',
    ]),
    ...mapActions('EMPLOYEE', ['fetchEmployeeAppointment']),

    shortName,
    getUserTimeZone,

    async fetchData() {
      this.inspectionsError = null;
      const types = this.query.types?.length
        ? this.query.types
        : this.validInspectionTypes.map(item => item.key);
      const params = {
        ...this.query,
        isTechError: false,
        isHuman: true,
        isCanceled: false,
        types: types?.length ? types.join(',') : null,
        orderBy: 'date',
        orderType: 'DESC',
        employeeIds: this.employee.id,
        page: this.nextPage,
        limit: 10,
      };
      try {
        await this.$loading(this.fetchInspections(params), 'fetchInspections');
        this.needApplyFilters = false;

        // first element always active
        if (this.nextPage === 1) {
          this.$nextTick(() => {
            if (this.inspections.length) {
              this.current = this.inspections[0];
            }
          });
        }

        this.nextPage++;
      } catch (err) {
        this.inspectionsError = err;
      }
    },

    async fetchNext(evt) {
      if (!evt[0].isIntersecting) return;

      await this.fetchData();
    },

    async loadInspectionItem(item) {
      if (this.$wait('fetchInspectionItem')) return;

      try {
        this.inspectionItemError = null;
        await this.$loading(
          this.fetchInspectionItem({
            id: item.id,
            isImported: item.isImported,
          }),
          'fetchInspectionItem',
          `Не удалось загрузить данные по осмотру #${item.id}`,
        );

        if (this.current.id !== this.inspectionItem.id) {
          this.current = this.inspectionItem || null;
        }
      } catch (err) {
        this.inspectionItemError = err;
      }
    },

    setDateFilters({ dateStart, dateEnd }) {
      this.query.dateStart = dateStart;
      this.query.dateEnd = dateEnd;
      this.needApplyFilters = true;
    },

    async updateList() {
      this.nextPage = 1;
      await this.clearInspections();
      await this.fetchData();
    },

    async resetFilter() {
      this.query = { ...emptyQuery };
      this.nextPage = 1;
      await this.clearInspections();
      await this.fetchData();
    },

    async fetchAppointment() {
      const params = {
        employeeId: this.employee.id,
        inspectionIds: this.selectedInspections.join(','),
      };
      const document = await this.$loadingNotify(
        this.fetchEmployeeAppointment(params),
        'fetchEmployeeAppointment',
        'Не удалось сформировать направление',
      );
      window.open(document.url, '_blank').focus();
      this.$emit('close');
    },
  },
};
</script>
