<template>
  <div class="find-face-sync__container">
    <DefinitionList :value="details" />

    <v-expansion-panels v-if="lastFails.length">
      <v-expansion-panel>
        <v-expansion-panel-header><h4>Ошибки</h4></v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-expansion-panels accordion>
            <v-expansion-panel v-for="(fail, i) in lastFails" :key="i">
              <v-expansion-panel-header>
                {{ FIND_FACE_FAIL_TYPES[fail.type] }} /
                {{ dateTimeSec(fail.timestamp) }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <DefinitionList :value="failToTable(fail)" />
                <pre style="overflow: auto">{{ fail.error }}</pre>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { VBtn } from 'vuetify/lib';
import { dateTime } from '@/utils/convert';
import DefinitionList from '@/components/DefinitionList.vue';

const FIND_FACE_STATUSES = {
  in_progress: 'В работе',
  done: 'Выполнено',
  failed: 'Ошибка',
  undefined: '–',
};

const FIND_FACE_FAIL_TYPES = {
  photo_changed: 'Изменение фото',
  photo_archived: 'Архивация фото',
  employee_enabled: 'Активация работника',
  employee_disabled: 'Деактивация работника',
  undefined: '–',
};

export default {
  components: { DefinitionList },

  props: {
    value: { type: Object, default: null },
  },

  computed: {
    FIND_FACE_FAIL_TYPES: () => FIND_FACE_FAIL_TYPES,

    details() {
      const list = [
        { label: 'Статус', value: this.status },
        {
          label: this.value.isEnabled ? 'Дата активации' : 'Дата отключения',
          value: dateTime(this.value.lastSwitchedAt, { showSeconds: true }),
        },
      ];

      if (!this.value.isEnabled) return list;

      list.push({
        label: 'Статус синхронизации',
        value: this.value.lastBatchSync
          ? `${
              FIND_FACE_STATUSES[this.value.lastBatchSync?.status]
            } (${dateTime(this.value.lastBatchSync?.timestamp, {
              showSeconds: true,
            })})`
          : 'Не выполнялась',
      });

      return list;
    },

    status() {
      if (this.value.status === 'in_progress')
        return this.value.isEnabled ? 'Включается' : 'Выключается';
      else return this.value.isEnabled ? 'Включен' : 'Отключен';
    },

    lastFails() {
      return this.value.lastFails || [];
    },
  },

  methods: {
    dateTimeSec(value) {
      return dateTime(value, { showSeconds: true });
    },

    failToTable(fail) {
      return [
        {
          label: 'ID',
          value: fail.eventId,
        },
        {
          label: 'Время',
          value: dateTime(fail.timestamp, { showSeconds: true }),
        },
        {
          label: 'Тип',
          value: FIND_FACE_FAIL_TYPES[fail.type],
        },
        {
          label: 'FindFace ID досье',
          value: fail.findfaceDossierId || '–',
        },
        {
          label: 'ID работника',
          component: {
            render() {
              return this.$createElement(
                VBtn,
                {
                  props: {
                    class: 'pa-0',
                    text: true,
                    small: true,
                    color: 'primary',
                    target: '_blank',
                    to: {
                      name: 'employees:card',
                      params: {
                        id: fail.employeeId,
                      },
                    },
                  },
                },
                `#${fail.employeeId}`,
              );
            },
          },
        },
      ];
    },
  },
};
</script>
