<template>
  <v-icon :color="color" :size="size">{{ icon }}</v-icon>
</template>

<script>
export default {
  props: {
    icon: {
      required: true,
      type: String,
    },
    color: {
      type: String,
      default: null,
    },
    size: {
      type: Number,
      default: 16,
    },
  },
};
</script>
