<template>
  <v-menu
    ref="menu"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-bind="attrs"
        readonly
        required
        persistent-hint
        :append-icon="$vuetify.icons.values.calendar"
        hide-details="auto"
        :label="label"
        :value="dateRangeText"
        :dense="dense"
        :hint="hint"
        :error-messages="errorMessages"
        :outlined="outlined"
        :disabled="disabled"
        :clearable="clearable"
        v-on="on"
        @click:append="on.click"
        @click:clear="clear"
      />
    </template>
    <v-card>
      <v-card-text class="pa-0 pb-4">
        <div class="d-flex flex-column">
          <v-date-picker
            v-model="date"
            no-title
            class="mb-4 pl-1 pt-2"
            color="primary"
            style="min-height: 300px !important"
            first-day-of-week="1"
          />
          <div class="d-flex justify-space-around px-2">
            <MaskField
              v-model="time"
              placeholder="00:00"
              style="max-width: 120px; text-align: center"
              hide-details
              dense
              outlined
              text-center
              :mask="maskTime"
            />
          </div>
          <div class="d-flex justify-center mt-4">
            <v-btn text color="primary" @click="save">Применить</v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { DateTime } from '@/plugins/luxon';
import { maskTime } from '@/utils/masks';
import validationMixin from '@/utils/validation';
import { dateTime as convertDateTime, timeToObject } from '@/utils/convert';
import MaskField from '@/components/controls/MaskField';

export default {
  components: { MaskField },
  mixins: [validationMixin],

  props: {
    value: { type: null, required: true },
    label: { type: String, default: null },
    errorMessages: { type: [String, Array], default: () => [] },
    outlined: Boolean,
    dense: Boolean,
    disabled: Boolean,
    clearable: Boolean,
    hint: { type: String, default: '' },

    /** Добавляет результату секунды окончания минуты (...:59.999) */
    isEnd: Boolean,
  },

  data: () => ({
    date: null,
    time: null,
  }),

  computed: {
    maskTime: () => maskTime,

    dateRangeText() {
      return convertDateTime(this.value, {
        setZone: true,
        showSeconds: true,
        showMilliseconds: true,
      });
    },

    fullDate() {
      if (!this.date) return null;

      const date = DateTime.fromISO(this.date, { zone: 'utc' });
      let dateWithTime = date.set(timeToObject(this.time));
      if (this.isEnd) dateWithTime = dateWithTime.endOf('minute');

      return dateWithTime.toISO();
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val === this.fullDate) return;

        if (!val || typeof val !== 'string') {
          this.date = DateTime.now().toISODate();
          this.time = null;
          // Делаем подмену данных в родителе
          this.$nextTick(() => this.$emit('input', this.fullDate));
          return;
        }

        const luxonDate = DateTime.fromISO(val, { setZone: true });
        this.date = luxonDate.toISODate();
        this.time = val.includes('T') ? luxonDate.toFormat('T') : null;

        this.saveMenu(luxonDate.toISO());
      },
    },
  },

  methods: {
    save() {
      this.$emit('input', this.fullDate);
      this.saveMenu(this.fullDate);
    },

    clear() {
      this.date = null;
      this.time = null;
      this.saveMenu(null);
    },

    saveMenu(value) {
      this.$refs.menu && this.$refs.menu.save(value);
    },
  },
};
</script>

<style lang="scss">
.picker-list-shadow {
  box-shadow: 0px 2px 4px 0px #00000033 !important;
  box-shadow: 0px 1px 10px 0px #0000001f !important;
  box-shadow: 0px 4px 5px 0px #00000024 !important;
  height: 100%;
}
</style>
