<template>
  <List
    search-filter
    :available-actions="{
      showAction: $can('HST_RD'),
      createAction: $can('HST_C'),
    }"
    @item:create="formModal"
  >
    <template #filters="query">
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.isActive"
          label="Статус ПАК"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :items="statusItems"
          :menu-props="{ offsetY: true }"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.isOpenpoint"
          label="Тип ПАК"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :items="typeItems"
          :menu-props="{ offsetY: true }"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <HostGroupSelect v-model="query.groupIds" dense multiple />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <OrganizationSelect v-model="query.orgIds" multiple dense />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <PointSelect v-model="query.releasePointIds" multiple dense />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <FormFactorSelect v-model="query.formFactor" />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.niiatStatusRegistered"
          label="Зарегистрирован в АИС ЕРП"
          :items="booleanItems"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :menu-props="{ offsetY: true }"
        />
      </v-col>
    </template>

    <template #table.item.nameLicense="{ item }">
      <b>{{ item.name }}</b>
      <br />
      {{ item.license }}
    </template>

    <template #table.item.org_releasePoint="{ item }">
      {{ item.organization.shortName || item.organization.name }}<br />
      {{ item.releasePoint.address || '#' + item.releasePoint.id }}
    </template>

    <template #table.item.isActive="{ item }">
      <Indicator
        :active="item.isActive"
        :tooltip="item.isActive ? 'Активен' : 'Неактивен'"
      />
    </template>

    <template #table.item.isOpenpoint="{ item }">
      <v-tooltip left>
        <template #activator="{ on }">
          <v-icon
            :color="item.isOpenpoint ? 'green lighten-2' : 'red'"
            v-on="on"
          >
            mdi-lock{{ item.isOpenpoint ? '-open' : '' }}-outline
          </v-icon>
        </template>
        {{ item.isOpenpoint ? 'Открытая' : 'Закрытая' }}
      </v-tooltip>
    </template>

    <template #table.item.isNotPrint="{ item }">
      <Indicator
        :active="item.isNotPrint"
        active-color="red"
        passive-color="grey lighten-2"
        :tooltip="item.isNotPrint ? item.notPrintComment : 'Автоматическая'"
      />
    </template>

    <template #table.item.isNotReprint="{ item }">
      <Indicator
        :active="item.isNotReprint"
        active-color="red"
        passive-color="grey lighten-2"
        :tooltip="item.isNotReprint ? item.notPrintComment : 'Разрешено'"
      />
    </template>
  </List>
</template>

<script>
import { STATUS, BOOLEANS } from '@/utils/constants.js';
import { messages, entity, filters } from '../entity';

import ListFactory from '@/components/crud/ListFactory';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect';
import HostGroupSelect from '@/components/controls/structures/HostGroupSelect';
import PointSelect from '@/components/controls/structures/PointSelect';
import FormFactorSelect from '@/components/controls/structures/FormFactorSelect';
import Indicator from '@/components/Indicator.vue';

const List = ListFactory({
  storeModule: 'STRUCTURES/HOSTS',
  messages,
  entity,
  filters,
  viewRoute: 'structures:hosts:item',
});

export default {
  components: {
    List,
    OrganizationSelect,
    PointSelect,
    HostGroupSelect,
    Indicator,
    FormFactorSelect,
  },

  data() {
    return {
      isLoadingChangeStatus: false,
      statusItems: STATUS,
      typeItems: [
        { name: 'Все типы', value: null },
        { name: 'Открытые', value: true },
        { name: 'Закрытые', value: false },
      ],
    };
  },

  computed: {
    booleanItems: () => BOOLEANS,
  },

  methods: {
    formModal() {
      this.$openModal('structures/hostForm');
    },
  },
};
</script>
