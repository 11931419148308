<template>
  <div class="d-flex flex-column overflow-hidden" style="position: relative">
    <v-app-bar
      elevate-on-scroll
      scroll-target="#inspection-info-card"
      absolute
      dense
    >
      <v-app-bar-title class="ml-1">
        {{ value.type.name }} #{{ value.id }}
      </v-app-bar-title>
      <CardActionBtn
        class="ml-auto mr-2"
        label="Открыть карточку"
        :to="{
          name: value.isImported
            ? 'inspections:card:archived'
            : 'inspections:card',
          params: { id: value.id },
        }"
        target="_blank"
      />
    </v-app-bar>
    <v-card
      id="inspection-info-card"
      :elevation="0"
      style="overflow-y: auto; height: 100%"
    >
      <div class="mx-5 mb-5 mt-16">
        <v-card v-if="!hideMedia" class="mb-3" :elevation="0" height="300px">
          <v-card-text class="pa-0" style="height: 100%">
            <v-row class="pa-0 ma-0" style="height: 100%">
              <v-col class="pl-0 py-0" :cols="6" style="height: 100%">
                <HistoryPhoto
                  font-size="1rem"
                  :photo="((value.employee || {}).photo || {}).url || null"
                  :date="value.timestamps.processedAt"
                  :timezone-offset="value.timezoneOffsetMin"
                />
              </v-col>
              <v-col
                class="pa-0 d-flex justify-center align-center overflow-hidden rounded"
                :cols="6"
                style="height: 100%"
              >
                <Plyr
                  v-if="value.video"
                  style="height: 100%"
                  :url="value.video"
                  hide-volume
                  download
                />
                <div
                  v-else
                  class="text-h5 grey d-flex justify-center align-center text-center pa-1 rounded"
                  :class="themeIsDark ? 'darken-2' : 'lighten-2'"
                  style="height: 100%; width: 100%"
                >
                  Видео не получено
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider v-if="!hideMedia" />
        <div class="my-3 d-flex justify-center flex-wrap" style="width: 100%">
          <Scenario
            class="justify-center"
            with-icons
            :steps="value.steps"
            :remarks="value.resolution.remarks"
          />
        </div>
        <v-divider />
        <v-card :elevation="0">
          <v-card-text class="pa-0">
            <InspectionDetails
              :inspection="value"
              :remarks="remarks"
              :remark-types="remarkTypes"
            />
          </v-card-text>
        </v-card>
      </div>
    </v-card>
  </div>
</template>

<script>
import Plyr from '@/components/Plyr';
import HistoryPhoto from '@/components/HistoryPhoto';
import Scenario from '@/modules/medcab/components/Scenario';
import InspectionDetails from '@/modules/inspections/components/InspectionDetails';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';
import { mapGetters } from 'vuex';

export default {
  components: {
    Plyr,
    Scenario,
    InspectionDetails,
    HistoryPhoto,
    CardActionBtn,
  },
  props: {
    remarks: { required: true, type: Array },
    remarkTypes: { required: true, type: Object },
    value: { required: true, type: Object, default: () => ({}) },
    hideMedia: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters(['themeIsDark']),
  },
};
</script>
