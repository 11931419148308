// api
import processingApi from '@/api/services/processing';
import historyApi from '@/api/services/history';
import medrecApi from '@/api/services/medrec';

import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

const state = {
  medRecord: {},
  measurementsHistory: [],
  outgoingDocs: [],
  processingInspectionDetails: null,
};

const getters = {
  medRecord: state => state.medRecord,
  employee: ({ singleItem }) => singleItem.employee || null,
  measurementsHistory: state => state.measurementsHistory,
  outgoingDocs: state => state.outgoingDocs,
  processingInspectionDetails: state => state.processingInspectionDetails,
};

const mutations = {
  medRecord: (state, value) => (state.medRecord = value),
  outgoingDocs: (state, value) => (state.outgoingDocs = value),
  measurementsHistory: (state, value) => {
    state.measurementsHistory = value;
  },
  processingInspectionDetails: (state, value) =>
    (state.processingInspectionDetails = value),
  approval: ({ singleItem }, value) => (singleItem.approval = value),
};

const actions = {
  async getMedRecord({ commit }, id) {
    const response = await medrecApi.getEmployeeRecord(id);
    commit('medRecord', response || []);
  },

  async fetchOutgoingDocs({ getters, commit }) {
    const response = await medrecApi.getInspectionOutgoingDocs(
      getters.singleItem.id,
    );
    commit('outgoingDocs', response);
  },

  async getEmployeeAverages({ commit }, id) {
    const response = await historyApi.getEmployeeHistory(id);
    commit('measurementsHistory', response || []);
  },

  async fetchProcessingInspectionDetails({ commit }, id) {
    const data = await processingApi.inspectionDetails(id);
    commit('processingInspectionDetails', data);
    return data;
  },

  async setApproval({ commit, dispatch, getters }, { inspectionId, params }) {
    const response = await historyApi.setApproval(inspectionId, params);
    commit('approval', response?.data || {});
    dispatch('pushLinkedList', {
      action: 'listUpdateItem',
      payload: getters.singleItem,
    });
  },
};

export default createModule(
  crudSingleFactory({
    fetchMethod: historyApi.inspectionGet,
    linkedList: 'INSPECTION_LIST',
  }),
  { state, getters, mutations, actions },
);
