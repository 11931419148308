import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import HOST_GROUPS from './store/list';
import HOST_GROUPS_ITEM from './store/item';
import EMBEDDED_HOSTS_LIST from './store/embeddedHostsList';
import RouteGuard from '@/components/RouteGuard';

export default {
  namespaced: true,
  store: {
    HOST_GROUPS,
    HOST_GROUPS_ITEM,
    EMBEDDED_HOSTS_LIST,
  },
  modals: [
    {
      name: 'hostGroupForm',
      component: () =>
        import('@/modules/structures/hosts-groups/modals/Form.vue'),
    },
  ],
  routes: [
    {
      path: 'host_groups',
      name: 'host_groups',
      component: () => import('./view/list.vue'),
      meta: {
        title: 'Все группы ПАК',
        action: 'HSTG_R',
      },
    },
    {
      path: 'host_groups/:id',
      name: 'host_groups:item',
      component: RouteGuard({
        storeModule: 'STRUCTURES/HOST_GROUPS_ITEM',
        component: () => import('./view/item.vue'),
        needForceUpdate: true,
      }),
      meta: {
        title: 'Страница группы ПАК',
        action: 'HSG_RD',
        // otherwise when updating embedded lists on tab items
        // the page will jump up
        disableScrollRestoring: true,
      },
    },
  ],
  menu: [
    {
      title: 'Группы ПАК',
      action: 'HSTG_R',
      name: 'host_groups',
      priority: 6,
    },
  ],
} as ModuleDefinition;
