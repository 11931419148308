import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import RouteGuard from '@/components/RouteGuard';
import APPLICATIONS from './store/list';
import APPLICATIONS_ITEM from './store/item';

export default {
  namespaced: true,
  store: { APPLICATIONS, APPLICATIONS_ITEM },
  modals: [
    {
      name: 'applicationsCreate',
      component: () => import('@/modules/admin/applications/modals/Create.vue'),
    },
    {
      name: 'applicationsProfile',
      component: () =>
        import('@/modules/admin/applications/modals/Profile.vue'),
    },
    {
      name: 'applicationsPush',
      component: () =>
        import('@/modules/admin/applications/modals/PushMethod.vue'),
    },
    {
      name: 'applicationsAddAppKey',
      component: () =>
        import('@/modules/admin/applications/modals/AddApplicationKey.vue'),
    },
    {
      name: 'applicationsMuteByExpiredApiKey',
      // eslint-disable-next-line prettier/prettier
      component: () =>
        import(
          '@/modules/admin/applications/modals/MuteApplicationKeyByExpired.vue'
        ),
    },
  ],
  routes: [
    {
      path: 'applications',
      name: 'applications',
      component: () => import('./view/list.vue'),
      meta: {
        title: 'Все приложения',
        action: 'APP_R',
      },
    },
    {
      path: 'applications/:id',
      name: 'applications:item',
      component: RouteGuard({
        storeModule: 'ADMIN/APPLICATIONS_ITEM',
        component: () => import('./view/item.vue'),
      }),
      meta: {
        title: 'Страница приложения',
        action: 'APP_RD',
      },
    },
  ],
  menu: [
    {
      title: 'Приложения',
      action: 'APP_R',
      name: 'applications',
      priority: 2,
    },
  ],
} as ModuleDefinition;
