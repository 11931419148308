import schedulerApi from '@/api/services/scheduler';
import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async retry({ getters }) {
    const id = getters.singleItem.id;

    const result = await schedulerApi.retryExecution(id);
    return result;
  },
};

export default createModule(
  crudSingleFactory({
    fetchMethod: schedulerApi.getExecutionItem,
  }),
  { state, getters, mutations, actions },
);
