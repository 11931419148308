import processingApi from '@/api/services/processing';
import structApi from '@/api/services/structures';

import { createModule } from '@/utils/vuex/createModule';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { filters } from '../entity';
import { changePages } from '@/utils/vuex/changePages';

const state = { medicalOrgs: null };

const getters = {
  medicalOrgs: state => state.medicalOrgs || [],
};

const mutations = {
  medicalOrgs: (state, value) => (state.medicalOrgs = value),

  addMedicalOrg: (state, value) =>
    state.medicalOrgs
      ? state.medicalOrgs.push(value)
      : (state.medicalOrgs = [value]),
};

const actions = {
  /**
   * Метод переопределен из crudListFactory,
   * чтобы можно было запросить список организаций, привязанных к группам
   */
  async fetchList({ commit, getters, dispatch }, query) {
    await changePages(getters, commit, query);

    try {
      if (getters.listSearch && getters.listSearch.length < 2)
        throw Error('Не менее 2 символов в поле поиска');
      if (getters.listSearch?.length > 100)
        throw Error('Не более 100 символов в поле поиска');

      commit('listFetching');
      const response = await processingApi.medicGroupsList(getters.listQuery);
      // Дополнительно запросим данные по мед.организациям
      // т.к. в запросе групп нет имени привязанных организаций
      const orgIds = [
        ...new Set(response.items?.map(item => item.organization.id) || []),
      ];
      dispatch('fetchMedicalOrgsPreviews', orgIds);
      commit('listFetched', response);
    } catch (error) {
      commit('listErrorFetched', error);
      throw error;
    }
  },

  /**
   * Метод переопределен из crudListFactory,
   * чтобы можно было запросить данные связанной организации
   */
  async createListItem({ commit, state, dispatch }, data) {
    const response = await processingApi.medicGroupsCreate(data);
    // Дополнительно запросим данные по мед.организации, если ее нет в ранее запрошенном списке
    // т.к. в данных группы нет имени привязанной организации
    const medOrgId = response.organization.id;
    const medOrg =
      state.medicalOrgs?.find(medOrg => medOrg.id === medOrgId) || null;
    !medOrg && dispatch('fetchMedOrg', medOrgId);

    commit('listAddItem', response);
    return response;
  },

  async fetchMedicalOrgsPreviews({ commit }, ids = []) {
    const response = ids.length
      ? await structApi.getOrganizationPreviewsBy(ids)
      : null;
    commit('medicalOrgs', response);
  },

  async fetchMedOrg({ commit }, id) {
    const response = await structApi.getOrganizationPreviewsBy([id]);
    commit('addMedicalOrg', response[0]);
  },
};

export default createModule(
  crudListFactory({
    fetchMethod: processingApi.medicGroupsList,
    createMethod: processingApi.medicGroupsCreate,
    deleteMethod: processingApi.medicGroupsDelete,
    filters,
  }),
  { state, getters, mutations, actions },
);
