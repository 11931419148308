var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AsyncWrapper',{attrs:{"handler":function () { return _vm.getRating(_vm.item.id); }}},[_c('v-list-item',{staticStyle:{"min-width":"230px"}},[_c('v-list-item-content',[_c('div',{staticClass:"d-flex align-center justify-space-between pr-3"},[_c('div',{staticClass:"mr-3 d-flex align-center"},[_c('span',{staticClass:"text-h6 mr-3",style:(_vm.getRatingColor())},[_vm._v(" "+_vm._s(_vm.rating || '-')+" ")]),_c('span',{staticClass:"mr-3"},[_vm._v("Рейтинг работника")]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help ")])]}}])},[_c('span',[_vm._v(" Цветовая ремарка рейтинга работника:"),_c('br'),_c('br'),_c('Round',{attrs:{"type":"green","size":"0.7rem"}}),_vm._v("  Зеленый - хорошо пройденные осмотры без критических причин недопуска (алкоголь, прохождение посторонним лицом);"),_c('br'),_c('Round',{attrs:{"type":"yellow","size":"0.7rem"}}),_vm._v("  Желтый - есть осмотры с административными причинами недопуска;"),_c('br'),_c('Round',{attrs:{"type":"red","size":"0.7rem"}}),_vm._v("  Красный - есть хотя бы один критический недопуск ")],1)])],1),(_vm.$can('PRT'))?_c('v-tooltip',{staticClass:"justify-right",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.putRating(_vm.item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,1369480027)},[_c('span',[_vm._v("Пересчитать рейтинг")])]):_vm._e(),(_vm.rating != 0 && _vm.$can('DRT'))?_c('v-tooltip',{staticClass:"justify-right",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteRating(_vm.item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,2850209016)},[_c('span',[_vm._v("Удалить рейтинг")])]):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }