import { createHTTP } from '../api';
import resourceServiceFactory from '@/utils/resourceServiceFactory';
import env from '@/plugins/env';

export const bans = createHTTP(env.get('VUE_APP_BANS_API') + '/v3');

export default {
  ...resourceServiceFactory(bans, 'profile', ['put']),

  getReasons() {
    return bans.get('/reasons').then(res => res.data);
  },

  bindOrgsToProfile(id, data) {
    return bans
      .put(`/profiles/${id}/organizations`, data)
      .then(res => res.data);
  },

  getUnbanConditions() {
    return bans.get('/profile_unban_conditions').then(res => res.data.items);
  },

  getEmployeeBans(id, params, signal) {
    return bans
      .get(`/employees/${id}/bans`, { params, ...(signal && { signal }) })
      .then(res => res.data);
  },
  unbanEmployee(id, payload) {
    return bans.patch(`/bans/${id}`, payload).then(res => res.data);
  },

  getOrganizationProfilePreview(id) {
    return bans
      .get(`/organizations/${id}/profile/preview`)
      .then(res => res.data);
  },

  getProfilesPreview() {
    return bans.get(`/profiles/previews`).then(res => res.data);
  },

  changeOrganizationProfile(id, profileKey) {
    return bans
      .put(`/organizations/${id}/profile/${profileKey}`)
      .then(res => res.data);
  },
};
