import { createHTTP } from '../api';
import env from '@/plugins/env';

export const gate = createHTTP(env.get('VUE_APP_GATE_API') + '/v3');

export default {
  // configuration profiles
  getProfiles(params) {
    return gate.get('/arm/profiles', { params }).then(res => res.data);
  },

  getProfilePreviews(params) {
    return gate.get('/arm/profiles/previews', { params }).then(res => res.data);
  },

  getProfile(key) {
    return gate.get(`/arm/profiles/${key}`).then(res => res.data);
  },

  createProfile(data) {
    return gate.post('/arm/profiles', data).then(res => res.data);
  },

  editProfile(payload) {
    return gate
      .put(`/arm/profiles/${payload.key}`, payload)
      .then(res => res.data);
  },

  editDefaultProfile(payload) {
    return gate
      .put(`/arm/profiles/${payload.key}/unrestricted`, payload)
      .then(res => res.data);
  },

  deleteProfile(key) {
    return gate.delete(`/arm/profiles/${key}`).then(res => res.data);
  },

  getTemplates() {
    return gate.get('/arm/templates').then(res => res.data);
  },

  // --- settings ---
  getOrgAuthSettings(id) {
    return gate
      .get(`/arm/organizations/settings/${id}/auth`)
      .then(res => res.data);
  },
  setOrgAuthSettings(id, payload) {
    return gate
      .put(`/arm/organizations/settings/${id}/auth/update`, payload)
      .then(res => res.data);
  },
  getOrgTimeSettings(id) {
    return gate
      .get(`/arm/organizations/settings/${id}/time`)
      .then(res => res.data);
  },
  setOrgTimeSettings(id, payload) {
    return gate
      .put(`/arm/organizations/settings/${id}/time/update`, payload)
      .then(res => res.data);
  },
  getOrgSignSettings(id) {
    return gate
      .get(`/arm/organizations/settings/${id}/sign`)
      .then(res => res.data);
  },
  setOrgSignSettings(id, payload) {
    return gate
      .put(`/arm/organizations/settings/${id}/sign/update`, payload)
      .then(res => res.data);
  },

  // Config profiles

  // organization

  getOrgConfigs(id) {
    return gate.get(`/arm/profiles/organization/${id}`).then(res => res.data);
  },

  editOrgConfig(id, key) {
    return gate
      .patch(`/arm/profiles/${key}/organization/${id}`)
      .then(res => res.data);
  },

  // host
  // TODO: Бэк обещает это исправить позже
  /**
   * Получение настроек пака
   * Зачем-то ему нужен ключ профиля родительской организации
   * @param hostId
   * @param orgProfileKey
   * @returns {Promise<AxiosResponse<any>>}
   */
  getHostProfile(hostId, orgProfileKey) {
    return gate
      .get(`arm/host/settings/${hostId}/${orgProfileKey}`)
      .then(res => res.data);
  },

  /**
   * Обновление кастомных настроек ПАКа
   * @param hostId
   * @param payload
   */
  updateHostProfile(hostId, payload) {
    return gate
      .post(`arm/host/settings/${hostId}`, payload)
      .then(res => res.data);
  },

  /**
   * Удаление кастомных настроек ПАКа
   * @param hostId
   * @returns {Promise<AxiosResponse<any>>}
   */
  deleteHostProfile(hostId) {
    return gate.delete(`arm/host/settings/${hostId}`).then(res => res.data);
  },
  // end config profiles

  // organization agreements

  /**
   * Получение списка соглашений
   * @param orgId
   * @returns {Promise<AxiosResponse<any>>}
   */
  getAgreements(orgId) {
    return gate.get(`agreements-files/${orgId}`).then(res => res.data);
  },
  /**
   * Добавление нового файла соглашения
   * @param orgId
   * @param pdf
   * @param type - personal-data или simple-signature
   * @returns {Promise<AxiosResponse<any>>}
   */
  createAgreement(orgId, pdf, type) {
    const formData = new FormData();
    formData.append('orgId', orgId);
    formData.append('file', pdf);
    formData.append('type', type);

    return gate.post('agreements-files', formData).then(res => res.data);
  },
  /**
   * Изменение файла соглашения
   * @param orgId
   * @param pdf
   * @param type - personal-data или simple-signature
   * @returns {Promise<AxiosResponse<any>>}
   */
  changeAgreement(orgId, pdf, type) {
    const formData = new FormData();
    formData.append('orgId', orgId);
    formData.append('file', pdf);
    formData.append('type', type);

    return gate.put('agreements-files', formData).then(res => res.data);
  },

  /**
   * Сброс подписи всех соглашений всех работников данной организации
   * @param orgId
   */
  resetAgreements(orgId) {
    return gate.post(`agreements-files/${orgId}/reset`).then(res => res.data);
  },
};
