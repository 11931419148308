import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import ROLES from './list.store';

export default {
  namespaced: true,
  store: {
    ROLES,
  },
  modals: [
    {
      name: 'roleForm',
      component: () => import('@/modules/admin/roles/modals/Form.vue'),
    },
  ],
  routes: [
    {
      path: 'roles',
      name: 'roles',
      component: () => import('./list.view.vue'),
      meta: {
        action: 'RL_R',
      },
    },
  ],
  menu: [
    {
      title: 'Роли',
      action: 'RL_R',
      name: 'roles',
      priority: 3,
    },
  ],
} as ModuleDefinition;
