<template>
  <DefinitionList
    :dense="dense"
    :value="table"
    :dark="dark"
    :width-columns="['80%', '20%']"
  />
</template>

<script>
import DefinitionList from '@/components/DefinitionList.vue';
import IconWrapper from '@/components/IconWrapper.vue';

export default {
  components: {
    DefinitionList,
  },

  props: {
    value: {
      required: true,
      type: Object,
    },
    dense: Boolean,
    dark: Boolean,
  },

  computed: {
    table() {
      return (this.value?.list || []).map(({ status, message }) => ({
        component: IconWrapper,
        props: {
          icon: status.icon,
          color: status.color,
          size: 20,
        },
        label: message,
      }));
    },
  },
};
</script>
