<template>
  <div>
    <div v-for="({ name }, local) in value" :key="local">
      <div class="my-3 mx-1 d-flex flex-column align-start">
        <v-chip small> {{ local }} </v-chip>
        <span>{{ name }}</span>
      </div>

      <v-divider />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  table {
    table-layout: fixed;
  }
}
</style>
