<template>
  <ModernModalLayout :title="title" close-label="Отмена" size="large">
    <v-simple-table dense class="mb-3">
      <thead class="font-weight-bold">
        <tr>
          <th class="py-3">Параметры опроса</th>
          <th class="py-3" />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="grey--text px-4 py-3" style="width: 60%">
            Продолжительность опроса (мин)
          </td>
          <td class="px-4 py-3" style="width: 40%">
            <MaskField
              v-model="data.surveySettings[type].timeoutTimespan"
              style="max-width: 150px; margin: auto !important"
              :mask="maskPosNumber"
              :disabled="$wait('submittingSurvey')"
              outlined
              dense
              hide-details="auto"
              :error-messages="
                getValidationErrors(
                  'data.surveySettings.$each.' + type + '.timeoutTimespan',
                )
              "
              @blur="
                validateField(
                  'data.surveySettings.$each.' + type + '.timeoutTimespan',
                )
              "
            />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-simple-table v-if="data.questionsSettings" dense>
      <thead class="font-weight-bold">
        <tr>
          <th class="py-3">Вопросы</th>
          <th class="text-center py-3">Доступность (дни)</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(val, key) in surveyTypes.schemes[type].questions"
          :key="key"
        >
          <td class="grey--text px-4 py-3" style="width: 60%">{{ val }}</td>
          <td class="px-4 py-3" style="width: 40%">
            <MaskField
              v-if="data.questionsSettings[key]"
              v-model="data.questionsSettings[key].timeoutTimespan"
              style="max-width: 150px; margin: auto !important"
              :disabled="$wait('submittingSurvey') || key === 'illegal_drugs'"
              :mask="maskPosNumber"
              outlined
              dense
              hide-details="auto"
              :error-messages="
                getValidationErrors(
                  'data.questionsSettings.' + key + '.timeoutTimespan',
                )
              "
              @blur="
                validateField(
                  'data.questionsSettings.' + key + '.timeoutTimespan',
                )
              "
            />
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :loading="$wait('submittingSurvey')"
        @click="submit"
      >
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import MaskField from '@/components/controls/MaskField';

import Vue from 'vue';
import { cloneDeep } from 'lodash';
import waitable from '@/utils/mixins/waitable';
import { mapGetters } from 'vuex';
import { maskPosNumber } from '@/utils/masks';
import validationMixin from '@/utils/validation';
import { maxValue, minValue, required } from 'vuelidate/lib/validators';

export default {
  components: { ModernModalLayout, MaskField },
  mixins: [waitable, validationMixin],

  validations() {
    return {
      data: {
        questionsSettings: {
          ...this.validateQuestionsSettingsRules,
        },
        surveySettings: {
          $each: {
            timeoutTimespan: {
              required,
              minValue: minValue(1),
              maxValue: maxValue(60),
            },
          },
        },
      },
    };
  },

  props: {
    type: { type: String, required: true },
    onSubmit: { type: Function, required: true },
  },

  data: () => ({
    data: {},
  }),

  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', ['surveys', 'surveyTypes']),
    maskPosNumber: () => maskPosNumber,
    title() {
      return `Настройки опроса "${this.surveyTypes.schemes[this.type].name}"`;
    },
    validateQuestionsSettingsRules() {
      // Dynamic validate options
      return Object.keys(this.data.questionsSettings).reduce((acc, cur) => {
        if (cur === 'illegal_drugs') {
          acc[cur] = {
            timeoutTimespan: {
              maxValue: maxValue(365),
            },
          };
        } else {
          acc[cur] = {
            timeoutTimespan: {
              required,
              minValue: minValue(1),
              maxValue: maxValue(365),
            },
          };
        }
        return acc;
      }, {});
    },
  },

  created() {
    const d = cloneDeep(this.surveys);
    if (!d.questionsSettings) d.questionsSettings = {};

    // set missing question setting object (if one is not set on backend it
    // doesn't return its setting object, so - fill in missing ones)
    Object.keys(this.surveyTypes.schemes).forEach(key => {
      Object.keys(this.surveyTypes.schemes[key].questions).forEach(q => {
        if (!d.questionsSettings[q])
          d.questionsSettings[q] = { timeoutTimespan: '' };
        // else convert strings of type '12d' or '22m' to numbers
        else
          d.questionsSettings[q].timeoutTimespan = parseInt(
            d.questionsSettings[q].timeoutTimespan,
          );
        if (q === 'illegal_drugs') d.questionsSettings[q].timeoutTimespan = 0;
      });
    });

    // convert strings of type '12d' or '22m' to numbers for validator
    Object.keys(d.surveySettings).forEach(key => {
      d.surveySettings[key].timeoutTimespan = parseInt(
        d.surveySettings[key].timeoutTimespan,
      );
    });

    Vue.set(this, 'data', d);
  },

  methods: {
    async submit() {
      const payload = cloneDeep(this.data);

      // convert data for backend-correct format 'timestamp'
      const t = payload.surveySettings[this.type].timeoutTimespan;

      if (!this.validate()) return;

      // in case of trying to convert '33d' to number we will get NaN
      // 33d is a format that comes from backend and if user didn't change it
      // no need to add 'm[inute]' or 'd[ay]' in the end

      if (!isNaN(+t) && +t)
        Object.keys(payload.surveySettings).forEach(key => {
          payload.surveySettings[key].timeoutTimespan =
            payload.surveySettings[key].timeoutTimespan + 'm';
        });

      const q = payload.questionsSettings;
      Object.entries(q).forEach(([key, val]) => {
        if (
          !isNaN(+val.timeoutTimespan) &&
          (+val.timeoutTimespan || +val.timeoutTimespan === 0)
        )
          q[key].timeoutTimespan = +val.timeoutTimespan + 'd';
      });

      await this.$loading(this.onSubmit(payload), 'submittingSurvey').then(() =>
        this.$emit('close'),
      );
    },
  },
};
</script>
