import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import RouteGuard from '@/components/RouteGuard';
import EXECUTION_LIST from './store/list';
import EXECUTION_ITEM from './store/item';

export default {
  namespaced: true,
  store: {
    EXECUTION_LIST,
    EXECUTION_ITEM,
  },
  routes: [
    {
      path: 'executions',
      name: 'executions',
      component: () => import('./view/list.vue'),
      meta: {
        action: 'SE_R',
        title: 'Лог задач',
      },
    },
    {
      path: 'executions/:id',
      name: 'executions:item',
      component: RouteGuard({
        storeModule: 'SCHEDULER/EXECUTION_ITEM',
        component: () => import('./view/item.vue'),
      }),
      meta: {
        action: 'SE_D',
      },
    },
  ],
  menu: [
    {
      title: 'Лог задач',
      action: 'SE_R',
      name: 'executions',
    },
  ],
} as ModuleDefinition;
