<template>
  <v-menu
    ref="menu"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :close-on-content-click="false"
    :return-value.sync="dateValue"
    @input="toggleMenuHandler"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-bind="attrs"
        readonly
        required
        persistent-hint
        :append-icon="$vuetify.icons.values.calendar"
        hide-details="auto"
        :placeholder="
          Boolean(placeholderDateRangeText)
            ? placeholderDateRangeText
            : 'ДД.ММ.ГГГГ – ДД.ММ.ГГГГ'
        "
        :persistent-placeholder="Boolean(placeholderDateRangeText)"
        :label="label"
        :value="dateRangeText"
        :dense="dense"
        :hint="hint"
        :error-messages="errorMessages"
        :outlined="outlined"
        :disabled="disabled"
        :clearable="clearable"
        v-on="on"
        @click:append="on.click"
        @click:clear="clear"
      />
    </template>

    <v-card>
      <v-card-text class="pa-0">
        <div class="d-flex">
          <div v-if="showQuickOptions" style="min-width: 220px">
            <v-list class="mr-1 picker-list-shadow">
              <template v-for="(shortcut, index) in shortcuts">
                <v-list-item
                  :key="index"
                  @click="() => applyShortcut(shortcut.value)"
                >
                  <v-list-item-title>{{ shortcut.text }}</v-list-item-title>
                </v-list-item>
                <v-divider
                  v-if="index < shortcuts.length - 1"
                  :key="shortcut.text"
                />
              </template>
            </v-list>
          </div>

          <div
            align-self="center"
            style="display: inline-block"
            class="mb-4 ml-1 mt-2"
          >
            <v-date-picker
              ref="datePicker"
              v-model="dateValue"
              no-title
              style="min-height: 300px !important"
              range
              show-adjacent-months
              first-day-of-week="1"
              color="primary"
              :min="minComputed"
              :max="maxComputed"
              width="300px"
            />

            <template v-if="withTime">
              <div class="d-flex justify-space-around px-2">
                <MaskField
                  v-model="timeStart"
                  placeholder="00:00"
                  style="max-width: 120px"
                  hide-details
                  dense
                  outlined
                  :mask="maskTime"
                />
                <MaskField
                  v-model="timeEnd"
                  placeholder="23:59"
                  style="max-width: 120px"
                  hide-details
                  dense
                  outlined
                  :mask="maskTime"
                />
              </div>
            </template>

            <div class="d-flex justify-center mt-4">
              <v-btn v-if="clearable" text @click="clear">Сбросить</v-btn>
              <v-btn text color="primary" @click="() => setChange(dateValue)">
                Применить
              </v-btn>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import {
  currentDateTime,
  getHighestDate,
  getLowestDate,
  parseDate,
} from '@/utils/helpers';

import { DateTime } from '@/plugins/luxon';
import { SHORTCUTS } from '@/utils/constants';
import { maskTime } from '@/utils/masks';
import { getUserTimeZone, timeToObject } from '@/utils/convert';
import MaskField from '@/components/controls/MaskField';

const setZoneWithoutChangeTime = (dt, offset) =>
  // set zone from date time
  DateTime.fromISO(dt, { setZone: true })
    // change to zone from offset, without change time (only timezone)
    .setZone(offset, { keepLocalTime: true });

export default {
  components: { MaskField },

  props: {
    value: { type: Object, required: true },
    /**
     * Нужен для случаев, когда есть дефолтное значение на странице
     * (например, за последние 24 часа с текущего момента). Но при этом
     * при открытии DatePicker значения не должны проставляться в форму.
     */
    placeholder: { type: Object, default: () => ({}) },
    min: { type: String, default: '' },
    maxRange: { type: Number, default: null },
    timezone: { type: Number, default: getUserTimeZone() },
    showQuickOptions: { type: Boolean, default: false },
    shortcuts: { type: Array, default: () => SHORTCUTS },
    withTime: { type: Boolean, default: false },
    errorMessages: { type: [String, Array], default: () => [] },
    fullRange: { type: Boolean, default: false },

    // look
    outlined: Boolean,
    dense: Boolean,
    label: { required: true, type: String },
    disabled: Boolean,
    clearable: Boolean,
    hint: { type: String, default: '' },
  },

  data: () => ({
    dateValue: [],
    timeStart: '',
    timeEnd: '',
  }),

  computed: {
    maskTime: () => maskTime,

    minComputed() {
      return getHighestDate(
        parseDate(this.min),
        this.maxRange && this.dateValue[0]
          ? parseDate(this.dateValue[0]).minus({ days: this.maxRange })
          : undefined,
        parseDate('2017-01-01'),
      ).toFormat('yyyy-MM-dd');
    },

    maxComputed() {
      return !this.fullRange
        ? getLowestDate(
            this.maxRange && this.dateValue[0]
              ? parseDate(this.dateValue[0]).plus({ days: this.maxRange })
              : undefined,
            currentDateTime(),
          ).toFormat('yyyy-MM-dd')
        : null;
    },

    timezoneAsOffset() {
      return this.timezone * 60;
    },

    dateRangeText() {
      const { dateStart, dateEnd } = this.value;

      const result = this.generateRangeText({
        dateStart,
        dateEnd,
        timezoneAsOffset: this.timezoneAsOffset,
      });
      return result;
    },

    placeholderDateRangeText() {
      const { dateStart, dateEnd } = this.placeholder || this.value;
      if (!dateStart || !dateEnd) return null;

      const result = this.generateRangeText({
        dateStart,
        dateEnd,
        timezoneAsOffset: this.timezoneAsOffset,
      });
      return result;
    },

    // год и месяц конечной даты выбранного диапазона
    tableDate() {
      return this.dateValue[1]
        ? DateTime.fromISO(this.dateValue[1]).toFormat('yyyy-MM')
        : DateTime.now().toFormat('yyyy-MM');
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        // block below works after filters've been reset
        // so.. if we reset filters - don't forget to reset values of this comp.
        if (!val.dateStart) {
          this.dateValue = [];
          this.timeStart = '';
          this.timeEnd = '';
          return;
        }

        const [dateStart, dateEnd] = [
          setZoneWithoutChangeTime(val.dateStart, this.timezoneAsOffset),
          setZoneWithoutChangeTime(val.dateEnd, this.timezoneAsOffset),
        ];

        if (this.withTime) {
          this.timeStart = dateStart.toFormat('T');
          this.timeEnd = dateEnd.toFormat('T');
        }
        this.dateValue = [dateStart.toISODate(), dateEnd.toISODate()];

        this.$refs.menu && this.$refs.menu.save(this.dateValue);
      },
    },

    timezone(tz) {
      this.changeTimeZone(this.value, tz);
    },
  },

  created() {
    if (this.value.dateStart && !this.dateValue.length)
      this.dateValue = [
        DateTime.fromISO(this.value.dateStart).toISODate(),
        DateTime.fromISO(this.value.dateEnd).toISODate(),
      ];
  },

  methods: {
    generateRangeText({ dateStart, dateEnd, timezoneAsOffset }) {
      const options = this.withTime
        ? { ...DateTime.DATETIME_SHORT, hourCycle: 'h23' }
        : {};

      if (!dateStart || !dateEnd) return '';

      const from = setZoneWithoutChangeTime(
        dateStart,
        timezoneAsOffset,
      ).toLocaleString(options);
      if (!dateEnd) return from;

      const to = setZoneWithoutChangeTime(
        dateEnd,
        timezoneAsOffset,
      ).toLocaleString(options);
      return `${from} - ${to}`;
    },

    update(dates) {
      if (!dates.length) {
        this.$emit('input', { dateStart: null, dateEnd: null });
        return false;
      }

      let from = dates[0];

      // in case user didn't choose 2nd date - choose same day
      let to = dates[1] || dates[0];

      if (dates.length === 2) {
        from = getLowestDate(...dates);
        to = getHighestDate(...dates);
      }

      from = setZoneWithoutChangeTime(from, this.timezoneAsOffset);
      to = setZoneWithoutChangeTime(to, this.timezoneAsOffset);

      if (this.withTime) {
        // how to combine 2 actions below?
        from = from.set(timeToObject(this.timeStart || '00:00'));
        to = to.set(timeToObject(this.timeEnd || '23:59')).endOf('minute');
      } else {
        from = from.startOf('day');
        to = to.endOf('day');
      }

      // If the user set the start later than the end, then swap the variables
      if (from.toMillis() > to.toMillis()) [from, to] = [to, from];

      const newValue = {
        dateStart: from.toISO(),
        dateEnd: to.toISO(),
      };

      this.$emit('input', newValue);
    },

    applyShortcut(shortcut) {
      const dateRange = shortcut || this.dateValue;
      const [start, end] = [
        DateTime.fromISO(dateRange[0]),
        DateTime.fromISO(dateRange[1]),
      ];
      [this.timeStart, this.timeEnd] = [start.toFormat('T'), end.toFormat('T')];

      this.setChange(dateRange);
    },

    setChange(value) {
      this.update(value);
      this.$refs.menu.save(value);
    },

    clear() {
      this.dateValue = [];
      this.update(this.dateValue);
    },

    changeTimeZone(value = this.value) {
      const result = {
        dateStart: setZoneWithoutChangeTime(
          value.dateStart,
          this.timezoneAsOffset,
        ).toISO(),
        dateEnd: setZoneWithoutChangeTime(
          value.dateEnd,
          this.timezoneAsOffset,
        ).toISO(),
      };

      this.$emit('input', result);
    },

    // отображение месяца, соответствующее последней дате текущего диапазона
    resetDatePicker() {
      if (!this.$refs.datePicker || !this.tableDate) return;
      this.$refs.datePicker.monthClick(this.tableDate);
    },
    toggleMenuHandler(isOpen) {
      if (isOpen) {
        this.resetDatePicker();
      } else {
        // Для случаев, когда дата сброшена кнопкой виджета "Сбросить"
        // Само меню не обновляет значение переменной
        this.$refs.menu && this.$refs.menu.save(this.dateValue);
      }
    },
  },
};
</script>

<style lang="scss">
.picker-list-shadow {
  box-shadow: 0px 2px 4px 0px #00000033 !important;
  box-shadow: 0px 1px 10px 0px #0000001f !important;
  box-shadow: 0px 4px 5px 0px #00000024 !important;
  height: 100%;
}
</style>
