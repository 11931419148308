import { ReportSchemes } from '../models';
import { required } from 'vuelidate/lib/validators';

export const schemes: ReportSchemes = {
  'inspections-totals': {
    fields: [
      {
        preset: 'Choose',
        name: 'type',
        props: {
          label: 'Выберите способ формирования:',
          options: [
            { value: 'byOrgGroup', name: 'По группе организаций' },
            { value: 'byOrg', name: 'По организации' },
          ],
        },
        defaultValue: context => (context.value.orgId ? 'byOrg' : 'byOrgGroup'),
        cols: 12,
        watch: context =>
          context.$emit('input', {
            ...context.value,
            orgGroupId: undefined,
            orgId: undefined,
            pointIds: undefined,
            employeeGroupIds: undefined,
            hostGroupId: undefined,
          }),
      },
      {
        is: context => context.value.type === 'byOrgGroup',
        preset: 'OrganizationGroup',
        validation: { required },
        cols: 12,
      },
      {
        is: context => context.value.type === 'byOrg',
        preset: 'Organization',
        validation: { required },
        watch: context =>
          context.$emit('input', {
            ...context.value,
            pointIds: undefined,
          }),
      },
      {
        is: context => context.value.type === 'byOrg',
        preset: 'Points',
        props: value => ({
          disabled: !value.orgId,
          orgIds: value.orgId ? [value.orgId] : null,
        }),
      },
      {
        is: context => context.value.type === 'byOrg',
        preset: 'EmployeeGroups',
        props: value => ({
          disabled: !value.orgId,
          orgIds: value.orgId ? [value.orgId] : null,
        }),
      },
      {
        is: context => context.value.type === 'byOrg',
        preset: 'HostGroup',
        props: value => ({
          disabled: !value.orgId,
          orgIds: value.orgId ? [value.orgId] : null,
        }),
      },

      {
        preset: 'DateRange',
        validation: { required },
        cols: 12,
      },
    ],
  },
};

export default schemes;
