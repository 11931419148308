<script>
import { VCard, VList, VDivider } from 'vuetify/lib';

export default {
  render() {
    const slot = this.$scopedSlots.default && this.$scopedSlots.default();
    if (!slot) return;

    const actions = slot
      .filter(item => item.tag || item.text) // filters empty nodes
      .map((actionsElement, index, array) => [
        actionsElement,
        array.length - 1 === index ? null : <VDivider class="mx-3" />,
      ]);
    if (!actions.length) return;

    return (
      <VCard style="border-radius: 8px">
        <VList>{actions}</VList>
      </VCard>
    );
  },
};
</script>

<style scoped lang="scss">
:empty {
  display: none;
}
</style>
