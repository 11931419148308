var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$can('EBN_R'))?_c('Card',{attrs:{"title":"Блокировки","dense":""}},[(_vm.isLoading)?_c('Spinner'):(_vm.fetchError)?_c('ErrorBlock',{staticClass:"mx-4 mt-4",attrs:{"error":_vm.fetchError},on:{"refresh":function($event){return _vm.fetchBans(_vm.employee.id)}}}):_c('v-data-table',{attrs:{"loading-text":"","item-key":"id","no-data-text":"Блокировки отсутствуют","dense":"","headers":_vm.headers,"options":_vm.options,"items":_vm.bans,"item-class":_vm.getClassesForItem},scopedSlots:_vm._u([{key:"item.inspectionId",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"pa-0",attrs:{"text":"","small":"","color":"primary","target":"_blank","to":{ name: 'inspections:card', params: { id: item.inspectionId } }}},[_vm._v(" #"+_vm._s(item.inspectionId)+" ")])]}},{key:"item.banScenario.scenarioType",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.scenarioType(item.banScenario.scenarioType)))])]}},{key:"item.issuedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateTime(item.issuedAt)))]),(_vm.getMedic(item.medicId))?_c('span',[_vm._v(" Заблокировал: "+_vm._s(_vm.getMedic(item.medicId).name)+" ")]):_vm._e()]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [(item.unban)?[_c('span',[_vm._v(_vm._s(_vm.dateTime(item.unban.date)))]),(_vm.getMedic(item.unban.issuerId))?_c('span',[_vm._v(" Разблокировал: "+_vm._s(_vm.getMedic(item.unban.issuerId).name)+" ")]):_vm._e()]:_c('span',[_vm._v(_vm._s(item.dueDate ? _vm.dateTime(item.dueDate) : '–'))])]}},{key:"item.reason",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.banScenario.reason.description))])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.status(item)))])]}},(_vm.$can('UNBN_F') || _vm.$can('BAN_D'))?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.isUnbanned(item)},on:{"click":function($event){return _vm.unbanModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-user-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Разблокировать")])])],1)]}}:null],null,true)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }