import { createHTTP } from '../api';
import resourceServiceFactory from '@/utils/resourceServiceFactory';
import env from '@/plugins/env';

export const scheduler = createHTTP(env.get('VUE_APP_SCHEDULER_API') + '/v3');

export default {
  ...resourceServiceFactory(scheduler, 'job', ['status']),

  getMyJobs() {
    return scheduler.get('/jobs/accounts/my').then(res => res.data);
  },

  getAccountJobs(id) {
    return scheduler.get(`/jobs/accounts/${id}`).then(res => res.data);
  },

  getLocals() {
    return scheduler.get(`/locals`).then(res => res.data);
  },
  getTypes() {
    return scheduler.get(`/types`).then(res => res.data);
  },

  getExecutionList(params) {
    return scheduler.get(`/executions`, { params }).then(res => res.data);
  },

  getExecutionItem(id) {
    return scheduler.get(`/executions/${id}`).then(res => res.data);
  },

  retryExecution(id) {
    return scheduler.post(`/executions/${id}/retry`).then(res => res.data);
  },

  execute(id) {
    return scheduler.post(`/jobs/${id}/execute`).then(res => res.data);
  },

  testJob(data) {
    return scheduler.post(`/jobs/test`, data).then(res => res.data);
  },
};
