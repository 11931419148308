import gateApi from '@/api/services/gate';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { createModule } from '@/utils/vuex/createModule';
import { filters } from '../entity';

export default createModule(
  crudListFactory({
    fetchMethod: gateApi.getProfiles,
    filters,
  }),
);
