import stickersApi from '@/api/services/stickers';
import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

const state = {
  strategies: [],
};

const getters = {
  strategies: state => state.strategies,
};

const mutations = {
  strategies: (state, val) => (state.strategies = val),
};

const actions = {
  async fetchStrategies({ commit }) {
    const data = await stickersApi.getStrategies();
    commit('strategies', data);
  },

  async createSingleItem({ dispatch }, data) {
    const response = await stickersApi.profileCreate(data);
    dispatch('pushLinkedList', {
      action: 'listAddItem',
      payload: response,
    });
    return response;
  },
};

export default createModule(
  crudSingleFactory({
    fetchMethod: stickersApi.profileGet,
    changeMethod: stickersApi.profileChange,
    deleteMethod: stickersApi.profileDelete,
    linkedList: 'ADMIN/STICKERS',
  }),
  { state, getters, mutations, actions },
);
