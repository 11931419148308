import Vue from 'vue';
import store from '@/store';

export function generateId() {
  return new Date().valueOf();
}

/**
 * Register modal for future use
 * @param {*} definition
 */
export function registerModal(definition) {
  store.commit('registerModal', definition);
}

/**
 * Open component as modal
 * @param {*} component
 * @param {*} props
 * @param {*} listeners
 * @param {*} slots
 */
export function openModal(component, props, listeners, slots) {
  const id = generateId();
  const newListeners = {
    ...listeners,
    close: evt => {
      // Иногда необходимо выполнить какие-то действия перед закрытием модалки
      if (listeners?.close && typeof listeners.close === 'function') {
        listeners.close(evt);
      }
      closeModal(id);
    },
  };

  if (typeof component === 'string') {
    const definition = store.getters.registeredModal(component);
    if (!definition)
      throw new Error(`Cannot open not registered modal: ${component}`);
    component = definition.component;
  }

  // We wrap in state component object to function
  store.commit('addModal', {
    id,
    component: () => component,
    props,
    listeners: newListeners,
    slots,
  });

  // Immediately close modal, when component which opened modal is destroying
  if (this?.$on) this.$on('hook:beforeDestroy', () => closeModal(id));

  return id;
}

/**
 * Close modal
 * @param {*} id
 */
export function closeModal(id) {
  const modalIsOpened = store.getters['modals'].find(el => el.id === id);
  if (modalIsOpened) store.commit('removeModal', id);
}

const DEFAULT_MODALS = [
  {
    name: 'prompt',
    component: () => import('@/components/modals/Prompt.vue'),
  },
  {
    name: 'selectModal',
    component: () => import('@/components/modals/SelectModal.vue'),
  },
  {
    name: 'asyncSelectModal',
    component: () => import('@/components/modals/AsyncSelectModal.vue'),
  },
  {
    name: 'imageEditor',
    component: () => import('@/components/modals/ImageEditor.vue'),
  },
  {
    name: 'support',
    component: () => import('@/components/modals/TheSupport.vue'),
  },
  {
    name: 'chooseSignatureToken',
    component: () => import('@/components/modals/ChooseSignatureToken.vue'),
  },
  {
    name: 'userBindings',
    component: () => import('@/components/bindings/Bindings.modal.vue'),
  },
  {
    name: 'changeLimit',
    component: () => import('@/components/limits/ChangeLimitModal.vue'),
  },
  {
    name: 'uploadHelpFile',
    component: () => import('@/components/modals/UploadHelpFileModal.vue'),
  },
];
DEFAULT_MODALS.forEach(item => registerModal(item));

const canPlugin = {
  install() {
    Vue.prototype.$registerModal = registerModal;
    Vue.prototype.$openModal = openModal;
    Vue.prototype.$closeModal = closeModal;
  },
};

Vue.use(canPlugin);

export default canPlugin;
