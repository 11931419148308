var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"loading-text":"","item-key":"id","no-data-text":"Выполненных задач нет","dense":"","headers":_vm.headers,"options":_vm.options,"items":_vm.value,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.end",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateTime(item.end))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('ExecutionStatus',{attrs:{"value":item.status}})]}},{key:"item.isManual",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isManual ? 'Вручную' : 'Автоматически')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{
              name: 'scheduler:executions:item',
              params: { id: item.id },
            }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Открыть")])])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }