import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import USERS from './store/list';
import USERS_ITEM from './store/item';
import RouteGuard from '@/components/RouteGuard';

export default {
  namespaced: true,
  store: {
    USERS,
    USERS_ITEM,
  },
  modals: [
    {
      name: 'userCreate',
      component: () => import('@/modules/admin/users/modals/Create.vue'),
    },
    {
      name: 'userEmail',
      component: () => import('@/modules/admin/users/modals/Email.vue'),
    },
    {
      name: 'userProfile',
      component: () => import('@/modules/admin/users/modals/Profile.vue'),
    },
  ],
  routes: [
    {
      path: 'users',
      name: 'users',
      component: () => import('./view/list.vue'),
      meta: {
        title: 'Все пользователи',
        action: 'ACC_R',
      },
    },
    {
      path: 'users/:id',
      name: 'users:item',
      component: RouteGuard({
        storeModule: 'ADMIN/USERS_ITEM',
        component: () => import('./view/item.vue'),
      }),
      meta: {
        title: 'Страница пользователя',
        action: 'ACC_R',
      },
    },
  ],
  menu: [
    {
      title: 'Пользователи',
      action: 'ACC_R',
      name: 'users',
      priority: 1,
    },
  ],
} as ModuleDefinition;
