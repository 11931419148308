<template>
  <Card title="QR-код" dense>
    <AsyncWrapper :handler="fetchQrcodeHandler">
      <DefinitionList v-if="qrcodeData.length" :value="qrcodeData" />
      <NoDataAlert v-else class="ma-4">Не сгенерирован</NoDataAlert>
    </AsyncWrapper>
    <template #action>
      <div>
        <v-btn
          v-if="$can('QR_GEN')"
          color="success"
          outlined
          small
          :loading="$wait('postingQrcode') || $wait('deletingQrcode')"
          @click="postQrcodeHandler"
        >
          <v-icon size="16" class="mr-2">fa-qrcode</v-icon>
          <span>
            {{ qrcodeData.length ? 'Сбросить' : 'Сгенерировать' }}
          </span>
        </v-btn>
        <v-btn
          v-if="$can('QR_DEL') && qrcodeData.length"
          outlined
          small
          class="ml-3"
          :loading="$wait('postingQrcode') || $wait('deletingQrcode')"
          @click="deleteQrcodeHandler"
        >
          <v-icon size="16">fa-trash</v-icon>
        </v-btn>
      </div>
    </template>
  </Card>
</template>

<script>
import { dateTime } from '@/utils/convert';
import waitable from '@/utils/mixins/waitable';

import Card from '@/components/ui/Card.vue';
import AsyncWrapper from '@/components/AsyncWrapper.vue';
import DefinitionList from '@/components/DefinitionList.vue';
import NoDataAlert from '@/components/ui/NoDataAlert';
import { VBtn } from 'vuetify/lib';

export default {
  components: {
    Card,
    AsyncWrapper,
    DefinitionList,
    NoDataAlert,
  },

  mixins: [waitable],

  props: {
    qrcode: { type: Object, required: true },
    store: { type: String, required: true },
  },

  computed: {
    qrcodeData() {
      if (!this.qrcode?.link) return [];
      const link = this.qrcode.link;
      return [
        {
          label: 'QR-код',
          component: {
            render() {
              return this.$createElement(
                VBtn,
                {
                  class: ['pa-0'],
                  props: {
                    dense: true,
                    text: true,
                    small: true,
                    color: 'primary',
                    target: '_blank',
                    href: link,
                  },
                },
                'Ссылка на скачивание QR-кода',
              );
            },
          },
        },
        {
          label: 'Срок действия QR-кода',
          value: 'До ' + dateTime(this.qrcode.expiresAt),
        },
      ];
    },
  },

  methods: {
    async fetchQrcodeHandler() {
      await this.$store.dispatch(this.store + '/fetchQrcode', null);
    },

    async postQrcodeHandler() {
      if (!this.qrcodeData.length)
        await this.$loadingNotify(
          this.$store.dispatch(this.store + '/postQrcode', null),
          'postingQrcode',
          'Ошибка генерации QR-кода',
          'QR-код успешно сгенерирован',
        );
      else
        this.$openModal('prompt', {
          yes: 'Сбросить',
          no: 'Отмена',
          title: 'Сброс QR-кода',
          description: 'Вы уверены, что хотите сбросить QR-код?',
          messages: {
            successfulAction: 'QR-код успешно сгенерирован',
            errorAction: 'Ошибка генерации QR-кода',
          },
          onSubmit: () =>
            this.$store.dispatch(this.store + '/postQrcode', null),
        });
    },

    async deleteQrcodeHandler() {
      this.$openModal('prompt', {
        yes: 'Удалить',
        no: 'Отмена',
        title: 'Удаление QR-кода',
        description: 'Вы уверены, что хотите удалить QR-код?',
        messages: {
          errorAction: 'Ошибка удаления QR-кода',
          successfulAction: 'QR-код успешно удален',
        },
        onSubmit: () =>
          this.$store.dispatch(this.store + '/deleteQrcode', null),
      });
    },
  },
};
</script>
