import env from '@/plugins/env';
import { createHTTP } from '../api';

export const scenarios = createHTTP(env.get('VUE_APP_SCENARIOS_API'));

export default {
  getFlags(params) {
    return scenarios.get(`/v3/flags`, { params }).then(res => res.data);
  },
  getTypes() {
    return scenarios.get(`/v3/scenarios/types`).then(res => res.data);
  },
  getAbsenceTime(id) {
    return scenarios.get(`/org-settings/${id}`).then(res => res.data);
  },
  updateAbsenceTime(id, absenceMinutes) {
    return scenarios
      .put(`/org-settings/${id}`, { absenceMinutes })
      .then(res => res.data);
  },
  resetAbsenceTime(id) {
    return scenarios.post(`/org-settings/${id}/reset`).then(res => res.data);
  },
};
