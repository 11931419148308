// api
import schedulerApi from '@/api/services/scheduler';
import authApi from '@/api/services/auth';
import reportsApi from '@/api/services/reports';

import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

const state = {
  lastExecutions: null,
  summaryLocals: null,
  receivers: [],
};

const getters = {
  lastExecutions: state => state.lastExecutions,
  summaryLocals: state => state.summaryLocals,
  receivers: state => state.receivers,
};

const mutations = {
  lastExecutions: (state, value) => (state.lastExecutions = value),
  summaryLocals: (state, value) => (state.summaryLocals = value),
  receivers: (state, value) => (state.receivers = value),
};

const actions = {
  execute({ getters }) {
    const id = getters.singleItem.id;
    return schedulerApi.execute(id);
  },

  testJob(_, data) {
    return schedulerApi.testJob(data);
  },

  async changeStatus({ getters, dispatch }, isActive) {
    const action = isActive ? 'jobEnable' : 'jobDisable';
    const id = getters.singleItem.id;
    await schedulerApi[action](id);
    dispatch('singleItemUpdate');
  },

  async fetchReceiverPreviews({ getters, commit }) {
    if (!getters.singleItem?.params?.receivers?.length) return;

    const receivers = await authApi.getAccountPreviewsBy(
      getters.singleItem.params.receivers,
    );
    commit('receivers', receivers);
  },

  async fetchLastExecutions({ getters, commit }) {
    const id = getters.singleItem.id;
    const result = await schedulerApi.getExecutionList({
      jobId: id,
      page: 1,
      limit: 10,
    });

    commit('lastExecutions', result.items);
  },

  async fetchSummaryLocals({ commit }, keys) {
    const summaryLocals = await reportsApi.getReportLocals(keys);
    commit('summaryLocals', summaryLocals);
  },
  async resetSummaryLocals({ commit }) {
    commit('summaryLocals', null);
  },
};

export default createModule(
  crudSingleFactory({
    fetchMethod: schedulerApi.jobGet,
    updateMethod: schedulerApi.jobUpdate,
    linkedList: 'SCHEDULER/JOB_LIST',
  }),
  { state, getters, mutations, actions },
);
