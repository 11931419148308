import schedulerApi from '@/api/services/scheduler';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { createModule } from '@/utils/vuex/createModule';
import { filters } from '../entity';

export default createModule(
  crudListFactory({
    fetchMethod: schedulerApi.getExecutionList,
    filters,
    defaultSort: {
      orderBy: 'id',
      orderType: true,
    },
  }),
);
