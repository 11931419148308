<template>
  <v-list dense style="width: 100%" class="pt-2 pl-4">
    <v-list-item-group
      color="primary"
      :value="value"
      mandatory
      @change="id => $emit('input', id)"
    >
      <v-list-item v-for="item in items" :key="item.id" :value="item.id">
        <v-list-item-content>
          <v-list-item-title>
            #{{ item.id }}
            <br />
            {{ dateTime(item.openedAt) }} –
            {{ item.closedAt ? dateTime(item.closedAt) : '(еще на смене)' }}
          </v-list-item-title>
          <v-list-item-subtitle>
            Всего {{ item.summary.resolved }} ( {{ item.summary.positive }}
            <Round type="success" size="0.6rem" /> /
            {{ item.summary.negative }} <Round type="error" size="0.6rem" />)
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="justify-center" :disabled="true">
        <div v-intersect="fetch" />
        <Spinner v-if="isLoading" :size="30" />
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { dateTime } from '@/utils/convert';
import Spinner from '@/components/Spinner.vue';
import Round from '@/components/Round.vue';

export default {
  components: {
    Spinner,
    Round,
  },

  props: {
    fetch: {
      required: true,
      type: Function,
    },
    value: {
      type: Number,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    isLoading: Boolean,
  },

  methods: {
    dateTime,
  },
};
</script>
