import Vue from 'vue';

// api
import processingApi from '@/api/services/processing';
// eslint-disable-next-line import/no-named-default
import { default as sseApi, SSETopics } from '@/api/services/sse'; // TF it is a class wut

import { createModule } from '@/utils/vuex/createModule';
import { timer } from '@/utils/timer';
import { isXhrError } from '@/utils/helpers';
import { DateTime } from '@/plugins/luxon';

const STATUSES = {
  fetchingShift: Symbol('fs'),
  outsideShift: Symbol('os'),

  paused: Symbol('p'),
  awaitingServer: Symbol('as'),
  awaitingAcceptance: Symbol('aa'),
  inspecting: Symbol('i'),
};
const TIMER_STATUS = {
  IDLE: 'IDLE',
  RUNNING: 'RUNNING',
  STOPPED: 'STOPPED',
  EXPIRED: 'EXPIRED',
};
const STOP_REASONS = ['not_worked', 'break', 'error'];

const state = {
  fakes: [],

  status: STATUSES.fetchingShift,
  stopReason: STOP_REASONS[0],
  currentShift: null,
  error: null,
  isPullingInspection: false,
  inspectionId: null,

  statisticOnline: null,
  shiftError: null,

  timer: null,
  timerInstance: null,
  timerStatus: TIMER_STATUS.IDLE,

  categories: {},
};

const getters = {
  fakes: state =>
    (state.fakes || []).reduce((acc, value, index) => {
      acc[value.type]
        ? (acc[value.type] = [...acc[value.type], { ...value, id: index }])
        : (acc[value.type] = [{ ...value, id: index }]);
      return acc;
    }, {}),

  isFetchingShift: state => state.status === STATUSES.fetchingShift,
  isOutsideShift: state => state.status === STATUSES.outsideShift,

  isPaused: state => state.status === STATUSES.paused,
  isAwaitingServer: state => state.status === STATUSES.awaitingServer,
  isAwaitingAcceptance: state => state.status === STATUSES.awaitingAcceptance,
  isInspecting: state => state.status === STATUSES.inspecting,
  isOnShift: state =>
    [
      STATUSES.paused,
      STATUSES.awaitingServer,
      STATUSES.awaitingAcceptance,
      STATUSES.inspecting,
    ].includes(state.status),
  isOnlineSSE: state =>
    [
      STATUSES.awaitingServer,
      STATUSES.awaitingAcceptance,
      STATUSES.inspecting,
    ].includes(state.status),

  currentShift: state => state.currentShift,
  categories: state => state.categories,

  timer: state =>
    String(Math.floor(state.timer / 60)).padStart(2, '0') +
    ':' +
    String(state.timer % 60).padStart(2, '0'),

  timerStatus: state => state.timerStatus,
  timeIsOver: state => state.timerStatus === TIMER_STATUS.EXPIRED,

  stopReason: state => state.stopReason,
  isError: state => state.stopReason === STOP_REASONS[2],
  error: state => (state.stopReason === STOP_REASONS[2] ? state.error : null),

  closedInspections: state => state.currentShift?.closedInspections || 0,

  statisticOnline: state => state.statisticOnline,
  shiftError: state => state.shiftError,

  isPullingInspection: state => state.isPullingInspection,
};

const stopTimer = (state, status) => {
  if (state.timerInstance) {
    if (status) state.timerStatus = status;
    state.timerInstance.stop();
  }
};

const mutations = {
  fakes: (state, value) => (state.fakes = value),

  fetchingShift: state => {
    state.status = STATUSES.fetchingShift;
  },
  openShift: (state, value) => {
    state.status = STATUSES.paused;
    state.currentShift = value;
    state.shiftError = null;
  },
  outsideShift: state => {
    state.status = STATUSES.outsideShift;
    state.currentShift = null;
    stopTimer(state, TIMER_STATUS.STOPPED);
  },
  start: state => (state.status = STATUSES.awaitingServer),
  pause: (state, stopReason) => {
    state.status = STATUSES.paused;
    if (stopReason === STOP_REASONS[1]) stopTimer(state, TIMER_STATUS.EXPIRED);
    else stopTimer(state, TIMER_STATUS.STOPPED);

    state.stopReason = stopReason;
    state.inspection = null;
  },
  awaitAcceptance: (state, { inspectionId, timeoutInSec }) => {
    state.status = STATUSES.awaitingAcceptance;
    state.inspectionId = inspectionId;
    state.timer = timeoutInSec;
  },

  categories: (state, value) => {
    value.forEach(cat => Vue.set(state.categories, cat.id, cat));
  },

  inspect: (state, { inspectionId }) => {
    state.status = STATUSES.inspecting;
    state.inspectionId = inspectionId;
  },

  resolution: state => {
    state.status = STATUSES.awaitingServer;
    Vue.set(
      state.currentShift,
      'closedInspections',
      ++state.currentShift.closedInspections,
    );
  },
  error: (state, value) => {
    state.error = value;
    stopTimer(state, TIMER_STATUS.STOPPED);
  },
  setStatisticOnline: (state, value) => (state.statisticOnline = value),
  shiftError: (state, value) => {
    state.shiftError = value;
    stopTimer(state, TIMER_STATUS.STOPPED);
  },

  tickTimer: (state, value) => (state.timer = value),
  timerStatus: (state, value) => (state.timerStatus = value),
  timerInstance: (state, value) => {
    stopTimer(state);
    state.timerStatus = TIMER_STATUS.RUNNING;
    state.timerInstance = value;
  },

  isPullingInspection: (state, value) => (state.isPullingInspection = value),
};

const actions = {
  async fetchFakes({ commit }) {
    const fakes = await processingApi.getFakes();
    commit('fakes', fakes);
  },

  async fetchOrgCategories({ commit, dispatch }, ids) {
    const categories = await dispatch(
      'STRUCTURES/fetchOrganizationCategoryPreviewsBy',
      ids,
      { root: true },
    );
    commit('categories', categories);
  },

  async openSSEConnection({ getters, dispatch, rootGetters }) {
    const medicId = rootGetters['AUTH/referenceId'];
    const callback = event => {
      const shiftId = getters.currentShift?.id;
      if (!event.type) return;

      if (event.type === 'statistics')
        dispatch('pullStatisticOnline', event.payload);
      else if (
        event.type === 'distribution' &&
        event.payload.medicId === medicId
      )
        dispatch('pullInspection', event.payload);
      // По таймауту осмотр уходит на паузу только после того, как кончится
      // время на сервере. Таймаут приходит от всех осмотров в системе на данный
      // момент, так что проверяем shiftId
      else if (event.type === 'timeout' && event.payload.shiftId === shiftId)
        dispatch('pause', STOP_REASONS[1]);
    };

    await sseApi.watchEvents({
      key: SSETopics.ActivePool,
      query: {
        topics: [SSETopics.ActivePool],
        heartbeat: true, // Без него будет разрыв соединения каждые 10 сек
      },
      errCallback: err => dispatch('fireError', err),
      callback,
    });
  },

  async runSession({ dispatch }) {
    try {
      const { openShift, inspectionInWork } =
        await processingApi.getCurrentShiftActive();

      if (openShift?.sessionIsOpen) await dispatch('openSSEConnection');

      await dispatch('restoreShift', { openShift, inspectionInWork });
    } catch (err) {
      dispatch('fireError', err);
    }
  },

  async restoreShift({ dispatch, commit }, { openShift, inspectionInWork }) {
    if (openShift) {
      commit('openShift', openShift);
      if (openShift?.sessionIsOpen) {
        commit('start');
        if (inspectionInWork) {
          const { id, timeoutInSec, status } = inspectionInWork;
          dispatch('pullInspection', {
            inspectionId: id,
            timeoutInSec,
            status,
          });
        }
      }
    } else commit('outsideShift');
  },

  handlerShiftError({ commit, dispatch }, err) {
    if (isXhrError(err) && err?.response?.status === 404) {
      commit('shiftError', err.response.data);
      commit('outsideShift');
      try {
        sseApi.unwatchEvents(SSETopics.ActivePool, err);
      } catch (err) {
        console.error(err);
      }
      dispatch('MEDCAB_INSPECTION/clean', null, { root: true });
    } else {
      dispatch('fireError', err);
      throw err;
    }
  },

  async openShift({ getters, commit, dispatch }) {
    if (!getters.isOutsideShift) return;

    commit('fetchingShift');
    try {
      await dispatch('openSSEConnection');
      const shift = await processingApi.openShiftActive({
        medicTimezoneOffset: DateTime.local().offset,
      });
      commit('openShift', shift);
      commit('start');
    } catch (err) {
      dispatch('fireError', err);
    }
  },

  async closeShift({ commit, getters, dispatch }) {
    if (!getters.isOnShift || !getters.currentShift) return;

    commit('fetchingShift');

    try {
      await processingApi
        .closeShiftActive()
        .catch(err => dispatch('handlerShiftError', err));
      commit('outsideShift');
    } catch (err) {
      dispatch('fireError', err);
    }
  },

  async resumeShift({ getters, dispatch }) {
    if (!getters.isPaused) return;

    try {
      await dispatch('openSSEConnection');
      await processingApi.resumeShiftActive();
      const { openShift, inspectionInWork } =
        await processingApi.getCurrentShiftActive();
      await dispatch('restoreShift', { openShift, inspectionInWork });
    } catch (err) {
      if (isXhrError(err) && err?.response?.status === 404) {
        dispatch('handlerShiftError', err);
      } else {
        dispatch('fireError', err);
      }
    }
  },

  async pause({ commit, getters, dispatch }, stopReason = STOP_REASONS[0]) {
    if (!getters.isOnShift && getters.isPaused) return;

    try {
      const isTimeout = stopReason === STOP_REASONS[1];
      const isError = stopReason === STOP_REASONS[2];
      const action = isTimeout
        ? 'timeout'
        : isError
        ? 'error_in_progress'
        : getters.isAwaitingAcceptance // Если осмотр назначен, это отказ, иначе - пауза.
        ? 'reject'
        : 'pause';
      await sseApi.unwatchEvents(SSETopics.ActivePool);
      await processingApi.pauseShiftActive({ action });
    } catch (err) {
      if (isXhrError(err) && err?.response?.status === 404) {
        sseApi.unwatchEvents(SSETopics.ActivePool, err);
        return dispatch('handlerShiftError', err).catch(err =>
          console.error(err),
        );
      }
      // Empty catch block, because pause error occurs
      // when the session is already paused by processing.
      // This is not critical for client - that's what we wanted.
    }

    commit('pause', stopReason);
    dispatch('MEDCAB_INSPECTION/clean', null, { root: true });
  },

  fireError({ commit, dispatch }, err) {
    commit('error', err);
    dispatch('pause', STOP_REASONS[2]);
  },

  async pullInspection({ getters, dispatch, commit }, payload) {
    const { inspectionId, timeoutInSec, status } = payload;
    if (!getters.isAwaitingServer || getters.isPullingInspection) return;

    commit('isPullingInspection', true);

    let timeLoadingData = 0;

    try {
      const startTime = performance.now();
      await dispatch(
        'MEDCAB_INSPECTION/fetchInspection',
        { id: inspectionId, medcabType: 'active' },
        { root: true },
      );
      const endTime = performance.now();
      timeLoadingData = Math.ceil((endTime - startTime) / 1000);
    } catch (err) {
      commit('isPullingInspection', false);
      return dispatch('fireError', err);
    }

    const params = {
      inspectionId,
      timeoutInSec,
      timeLoadingData,
    };

    if (status === 'assigned') dispatch('awaitAcceptance', params);
    else if (status === 'taken') dispatch('inspect', params);

    commit('isPullingInspection', false);
  },

  async awaitAcceptance(
    { commit, getters, rootGetters },
    { inspectionId, timeoutInSec, timeLoadingData },
  ) {
    if (!getters.isAwaitingServer) return;

    const delay = Math.ceil(
      timeoutInSec - timeLoadingData - rootGetters['TIME/pingInSec'],
    );

    commit(
      'timerInstance',
      timer({
        seconds: delay,
        onTick: time => commit('tickTimer', time),
        onTimeout: () => commit('timerStatus', TIMER_STATUS.EXPIRED),
        isStopped: () => !getters.isAwaitingAcceptance,
      }),
    );

    commit('awaitAcceptance', {
      inspectionId,
      timeoutInSec: delay,
    });
  },

  async take({ state, dispatch }) {
    if (!getters.isAwaitingAcceptance) return;

    const inspectionId = state.inspectionId;

    try {
      const startTime = performance.now();
      const { timeoutInSec } = await processingApi.takeInspectionActive(
        inspectionId,
      );
      const endTime = performance.now();

      dispatch('inspect', {
        inspectionId,
        timeoutInSec,
        timeLoadingData: Math.ceil((endTime - startTime) / 1000),
      });
    } catch (err) {
      dispatch('fireError', err);
    }
  },

  async inspect(
    { getters, commit, dispatch, rootGetters },
    { inspectionId, timeoutInSec, timeLoadingData },
  ) {
    commit('inspect', { inspectionId });

    commit(
      'timerInstance',
      timer({
        seconds: Math.floor(
          timeoutInSec - timeLoadingData - rootGetters['TIME/pingInSec'],
        ),
        onTick: time => commit('tickTimer', time),
        onTimeout: () => commit('timerStatus', TIMER_STATUS.EXPIRED),
        isStopped: () => !getters.isInspecting,
      }),
    );

    await dispatch(
      'MEDCAB_INSPECTION/fetchDetails',
      { id: inspectionId },
      { root: true },
    );
  },

  async permit(
    { state, commit, dispatch, getters },
    { comment, assistantDisabled },
  ) {
    if (!getters.isInspecting) return;
    // NOTE: endpoint "resolution" takes a time, we don't wait for it
    commit('resolution');

    try {
      await dispatch('MEDCAB_INSPECTION/clean', null, { root: true });
      await processingApi.resolveInspectionActive(state.inspectionId, {
        success: true,
        remarks: [],
        comment,
        assistantDisabled,
      });
    } catch (err) {
      dispatch('fireError', err);
      throw err;
    }
  },

  async resolve(
    { state, commit, dispatch, getters },
    { remarks, comment, assistantDisabled },
  ) {
    if (!getters.isInspecting) return;
    // NOTE: endpoint "resolution" takes a time, we don't wait for it
    commit('resolution');

    try {
      await dispatch('MEDCAB_INSPECTION/clean', null, { root: true });
      await processingApi.resolveInspectionActive(state.inspectionId, {
        success: false,
        remarks,
        comment,
        assistantDisabled,
      });
    } catch (err) {
      dispatch('fireError', err);
      throw err;
    }
  },

  async validate(
    { state, commit, dispatch, getters },
    { remarks, comment, assistantDisabled },
  ) {
    if (!getters.isInspecting) return;
    // NOTE: endpoint "resolution" takes a time, we don't wait for it
    commit('resolution');

    try {
      await dispatch('MEDCAB_INSPECTION/clean', null, { root: true });
      await processingApi.resolveInspectionActive(state.inspectionId, {
        remarks,
        comment,
        assistantDisabled,
      });
    } catch (err) {
      dispatch('fireError', err);
      throw err;
    }
  },

  async generateFakeInspection(_, number) {
    processingApi.generateFakeInspection(number);
  },

  async pullStatisticOnline({ getters, dispatch, commit, rootGetters }, data) {
    const orgId = rootGetters['AUTH/medicOrganizationId'];
    if (!orgId) throw Error('Не найден номер организации медработника');

    try {
      const statisticByOrg = data[orgId];
      if (!statisticByOrg) return;

      // get all ids not presenting in store
      const { inspections, medicsOnline } = statisticByOrg;
      const ids = [
        inspections.assigned,
        inspections.inProgress,
        inspections.unassigned,
        medicsOnline.byCategories,
      ]
        ?.flat() // for now ids are [[{categoryId: ..}], [{categoryId: ..}]]
        .map(item => item.categoryId)
        .filter(id => !getters.categories[id]);

      // fetch those
      ids.length && dispatch('fetchOrgCategories', ids);

      commit('setStatisticOnline', statisticByOrg);
    } catch (err) {
      return dispatch('fireError', err);
    }
  },
};

export default createModule({
  state,
  getters,
  mutations,
  actions,
});
