import { initStoreInstance } from '@/utils/tests';

// api
import processingApi from '@/api/services/processing';
import authApi from '@/api/services/auth';

import medicsStore from '@/modules/medblock/medics/store';

const ns = 'MEDICS';
let store;

jest.mock('@/utils/convert/medics', () => ({
  convertMedics: item => item,
}));

// import { diagnosticTheMedic, convertMedics } from '@/utils/convert/medics';

beforeEach(() => (store = initStoreInstance(medicsStore, true, ns)));

describe('store: medics', () => {
  describe('actions', () => {
    describe('new medic creation', () => {
      processingApi.medicCreate.mockImplementation(async () => ({
        id: 13,
      }));
      authApi.createMedic.mockImplementation(async () => 'response');

      it("hardsets 'MP24' as medics organization if 'internal' field is 'true'", async () => {
        // and also removes the 'internal' field

        await store.dispatch(`${ns}/createListItem`, {
          worksFor: 'some org',
          internal: true,
        });
        expect(processingApi.medicCreate).toBeCalledWith({
          worksFor: 'MP24',
        });
      });

      it('creates new medic in both - structures & accounts', async () => {
        await store.dispatch(`${ns}/createListItem`, {
          worksFor: 'MP24',
          internal: true,
        });

        expect(processingApi.medicCreate).toBeCalledTimes(1);
        expect(authApi.createMedic).toBeCalledTimes(1);
        expect(authApi.createMedic).toBeCalledWith(13);
      });
    });
  });
});
