/* ENUM */
export enum LimitType {
  BY_TOTAL = 'by_total',
  BY_PERIOD = 'by_period',
  BY_DATE = 'by_date',
}
export enum LimitPeriod {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

/* USER FRIENDLY */
/** Пользовательские названия типов */
export const LimitTypeName = {
  [LimitType.BY_TOTAL]: 'По общему количеству',
  [LimitType.BY_PERIOD]: 'За период',
  [LimitType.BY_DATE]: 'По дате',
};
/** Пользовательские названия периодов */
export const LimitPeriodName = {
  [LimitPeriod.DAY]: 'За день',
  [LimitPeriod.WEEK]: 'За неделю',
  [LimitPeriod.MONTH]: 'За месяц',
};

/* DTO */
interface NotificationDto {
  receivers: string[];
  templateKey: string;
}

interface NotificationByThresholdDto extends NotificationDto {
  threshold: number;
}

interface NotificationByDateDto extends NotificationDto {
  period: LimitPeriod;
  periodSize: number;
}

export interface CountInspectionsQuery {
  dateStart?: string;
  dateEnd?: string;
}

interface LimitByTotalSettingsDto extends CountInspectionsQuery {
  dateFrom: Date;
  limit: number;
  notifications: NotificationByThresholdDto[];
}
interface LimitByPeriodSettingsDto extends CountInspectionsQuery {
  period: LimitPeriod;
  periodEnd: string;
  limit: number;
  notifications: NotificationByThresholdDto[];
}

interface LimitByDateSettingsDto extends CountInspectionsQuery {
  limit: number;
  dateEnd: string;
  notifications: NotificationByDateDto[];
}

type LimitSettings =
  | LimitByTotalSettingsDto
  | LimitByPeriodSettingsDto
  | LimitByDateSettingsDto;

export interface LimitDto {
  id: number;
  orgId: number;
  type: LimitType;
  settings: LimitSettings[];
  isEnabled: boolean;
  /** Число пройденных на текущий момент осмотров */
  counter: number;
  /** Флаг превышения лимита */
  isReached: boolean;
}

/* COMMANDS */
export interface CreateLimitCommand {
  id: number;
  type: LimitType;
  settings: LimitSettings[];
}

export interface LimitPreviewPayload {
  page: number;
  limit: number;
  inGroupId: number;
}

export interface UpdateLimitCommand {
  type?: LimitType;
  settings?: LimitSettings[];
}
