import gateApi from '@/api/services/gate';
import structApi from '@/api/services/structures';

// "formFactor": {
//   "anti_vandal": "Антивандал",
//   "cabin": "Кабина",
//   "desktop": "Настольный",
//   "mobile": "Мобильный",
//   "standard": "Стандартный"
// },
export default {
  state: {
    formFactors: {},
  },
  getters: {
    formFactors: state => state.formFactors,
  },
  mutations: {
    formFactors: (state, value) => (state.formFactors = value),
  },
  actions: {
    async fetchFormFactors({ commit }) {
      const { formFactor } = await gateApi.getTemplates();
      commit('formFactors', formFactor);
      return formFactor;
    },

    async updateSingleItemFF({ dispatch, commit, getters }, data) {
      const oldFF = getters.singleItem.formFactor;
      // FF for FormFactor, since we have different actions for 2 cases:
      // - when user has rights to also update formfactor is's this endpoint
      // - when user doesn't it's default one
      const response = await structApi.hostUpdateFF(data);
      commit('singleItem', response);

      // Если изменился форм-фактор,
      // нужно обновить данные по настройке ПАКа и профиль организации
      if (oldFF !== response.formFactor) {
        await dispatch('fetchOrgProfile', {
          orgId: getters.singleItem.organization.id,
          formFactor: getters.singleItem.formFactor,
        });
        await dispatch('fetchConfigProfile');
      }

      dispatch('pushLinkedList', {
        action: 'listUpdateItem',
        payload: response,
      });

      return response;
    },
  },
};
