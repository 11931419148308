<template>
  <v-simple-table dense class="definition-list">
    <tbody>
      <tr v-for="{ label, value, unit } in ambientConditionsData" :key="label">
        <td :class="{ 'grey--text': true }">
          {{ label }}
        </td>
        <td v-if="value">{{ value }} {{ unit }}</td>
        <td v-else>-</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    ambientConditions: { required: true, type: Object, default: () => {} },
  },
  computed: {
    ambientConditionsData() {
      return [
        {
          value: this.ambientConditions.humidity,
          label: 'Влажность',
          unit: '%',
        },
        {
          value: this.ambientConditions.lux,
          label: 'Освещенность',
          unit: 'Лк',
        },
        {
          value: this.ambientConditions.temperature,
          label: 'Температура',
          unit: '°C',
        },
      ];
    },
  },
};
</script>
