<template>
  <List
    search-filter
    :available-actions="{
      createAction: $can('APP_C'),
      showAction: $can('APP_RD'),
    }"
    @item:create="createModal"
  >
    <template #filters="query">
      <v-col cols="12" sm="4" md="2">
        <v-select
          v-model="query.isEnabled"
          label="Статус приложения"
          :items="statusItems"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :menu-props="{ offsetY: true }"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <Select
          v-model="query.roleKeys"
          multiple
          label="Роли"
          item-value="key"
          :items="accountRolesApplication"
          dense
          clearable
        />
      </v-col>
    </template>

    <template #table.item.isEnabled="{ item }">
      <Indicator
        :active="item.isEnabled"
        :tooltip="item.isEnabled ? 'Активно' : 'Неактивно'"
      />
    </template>
  </List>
</template>

<script>
// TODO: isEnabled -> isActive (ask backend to change)
import ListFactory from '@/components/crud/ListFactory';

import { mapGetters } from 'vuex';
import { STATUS } from '@/utils/constants';
import waitable from '@/utils/mixins/waitable';
import Select from '@/components/controls/Select';
import { entity, messages, filters } from '../entity';
import Indicator from '@/components/Indicator.vue';

const List = ListFactory({
  storeModule: 'ADMIN/APPLICATIONS',
  messages,
  entity,
  filters,
  viewRoute: 'admin:applications:item',
});

export default {
  components: { List, Select, Indicator },
  extends: waitable,

  data() {
    return {
      statusItems: STATUS,
    };
  },

  computed: {
    ...mapGetters('REGISTRY', ['accountRolesApplication']),
  },

  async created() {
    this.$can('RL_RP') &&
      this.$loadingNotify(
        this.$store.dispatch('REGISTRY/fetchAccountRolesApplication'),
        'loadingRoles',
        'Ошибка загрузки ролей',
      );
  },

  methods: {
    createModal(item, action = 'create') {
      this.$openModal('admin/applicationsCreate', { id: item.id, action });
    },
  },
};
</script>
