import notifApi from '@/api/services/notifications';
import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

const state = {
  templates: null,
};
const getters = {
  templates: state => state.templates || [],
};
const mutations = {
  setTemplates: (state, val) => (state.templates = val),
};
const actions = {
  async fetchTemplates({ commit }) {
    const data = await notifApi.template_setsList({
      limit: 10000,
      page: 1,
    });
    commit('setTemplates', data.items);
  },

  async createSingleItem({ dispatch }, data) {
    const response = await notifApi.profileCreate(data);
    dispatch('pushLinkedList', {
      action: 'listAddItem',
      payload: response,
    });
    return response;
  },
};

export default createModule(
  crudSingleFactory({
    fetchMethod: notifApi.profileGet,
    changeMethod: notifApi.profileChange,
    deleteMethod: notifApi.profileDelete,
    linkedList: 'NOTIFICATIONS/PROFILES',
  }),
  { state, getters, mutations, actions },
);
