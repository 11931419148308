export const messages = {
  searchFilterLabel: 'Поиск по группам организаций',
  searchFilterPlaceholder: '',
  resourceName: 'групп организаций',
};

export const entity = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: 'Название' },
  { key: 'description', label: 'Описание' },
  {
    key: 'isPrivate',
    label: 'Приватность',
    headerProps: {
      align: 'center',
    },
    convert: item => (item.isPrivate ? 'Приватная' : 'Публичная'),
  },
  {
    key: 'membersCount',
    label: 'Кол-во организаций',
    headerProps: {
      width: '110px',
      align: 'center',
    },
  },
];

export const filters = {
  isHolding: {
    type: Boolean,
    default: null,
  },

  // fixed filter
  type: {
    type: String,
    default: 'public',
  },
};
