// example of data that frontend gets from back

export const MOCK = [
  {
    timestamp: '2022-04-30T21:00:00.000Z',
    employeeId: 19162,
    orgId: 1939,
    cardHeader: {
      height: null,
      weight: null,
      resolutionSuccess: 2,
      resolutionFailed: 5,
      resolutionFailedWithMedicalRemarks: {
        pressure: 4,
        pulse: 0,
        temperature: 0,
        complaints: 0,
        skin: 0,
        injury: 0,
        reactions: 0,
      },
    },
    risks: {
      cardioDisease: {
        riskLevel: 'veryHigh',
        isAddictedSmoking: '-',
        hasCertificates: false,
        averageDiastolic: '98',
        averagePulse: '75',
        averageSystolic: '155',
      },
      cardioEvents: {
        riskLevel: 'middle',
        summaryCardicRiskInTenYears: '3%',
        gender: 'мужчина',
        age: 56,
        isAddictedSmoking: '-',
        cholesterol: 4,
        averageDiastolic: '98',
        averageSystolic: '155',
      },
      oldAge: {
        oldAge: 'Возраст старше 55 лет',
      },
      alcohol: {
        failedInspectionsByAlcohol: '13%',
        passedAlcoAmountYear: 16,
        failedAlcoAmountYear: 2,
        passedAlcoAmountMonth: 8,
        failedAlcoAmountMonth: 1,
      },
    },
  },
  {
    timestamp: '2022-05-31T21:00:00.000Z',
    employeeId: 19162,
    orgId: 1939,
    cardHeader: {
      height: null,
      weight: null,
      resolutionSuccess: 2,
      resolutionFailed: 5,
      resolutionFailedWithMedicalRemarks: {
        pressure: 4,
        pulse: 0,
        temperature: 0,
        complaints: 0,
        skin: 0,
        injury: 0,
        reactions: 0,
      },
    },
    risks: {
      cardioDisease: {
        riskLevel: 'veryHigh',
        isAddictedSmoking: '-',
        hasCertificates: false,
        averageDiastolic: '98',
        averagePulse: '75',
        averageSystolic: '155',
      },
      cardioEvents: {
        riskLevel: 'middle',
        summaryCardicRiskInTenYears: '3%',
        gender: 'мужчина',
        age: 56,
        isAddictedSmoking: '-',
        cholesterol: 4,
        averageDiastolic: '98',
        averageSystolic: '155',
      },
      oldAge: {
        oldAge: 'Возраст старше 55 лет',
      },
      alcohol: {
        failedInspectionsByAlcohol: '13%',
        passedAlcoAmountYear: 24,
        failedAlcoAmountYear: 3,
        passedAlcoAmountMonth: 8,
        failedAlcoAmountMonth: 1,
      },
    },
  },
  {
    timestamp: '2022-06-30T21:00:00.000Z',
    employeeId: 19162,
    orgId: 1939,
    cardHeader: {
      height: null,
      weight: null,
      resolutionSuccess: 2,
      resolutionFailed: 5,
      resolutionFailedWithMedicalRemarks: {
        pressure: 4,
        pulse: 0,
        temperature: 0,
        complaints: 0,
        skin: 0,
        injury: 0,
        reactions: 0,
      },
    },
    risks: {
      cardioDisease: {
        riskLevel: 'veryHigh',
        isAddictedSmoking: '-',
        hasCertificates: false,
        averageDiastolic: '98',
        averagePulse: '75',
        averageSystolic: '155',
      },
      cardioEvents: {
        riskLevel: 'middle',
        summaryCardicRiskInTenYears: '3%',
        gender: 'мужчина',
        age: 56,
        isAddictedSmoking: '-',
        cholesterol: 4,
        averageDiastolic: '98',
        averageSystolic: '155',
      },
      oldAge: {
        oldAge: 'Возраст старше 55 лет',
      },
      alcohol: {
        failedInspectionsByAlcohol: '13%',
        passedAlcoAmountYear: 32,
        failedAlcoAmountYear: 4,
        passedAlcoAmountMonth: 8,
        failedAlcoAmountMonth: 1,
      },
    },
  },
  {
    timestamp: '2022-03-31T21:00:00.000Z',
    employeeId: 19162,
    orgId: 1939,
    cardHeader: {
      height: null,
      weight: null,
      resolutionSuccess: 2,
      resolutionFailed: 4,
      resolutionFailedWithMedicalRemarks: {
        pressure: 4,
        pulse: 0,
        temperature: 0,
        complaints: 0,
        skin: 0,
        injury: 0,
        reactions: 0,
      },
    },
    risks: {
      cardioDisease: {
        riskLevel: 'veryHigh',
        isAddictedSmoking: '-',
        hasCertificates: false,
        averageDiastolic: '98',
        averagePulse: '75',
        averageSystolic: '155',
      },
      cardioEvents: {
        riskLevel: 'middle',
        summaryCardicRiskInTenYears: '3%',
        gender: 'мужчина',
        age: 56,
        isAddictedSmoking: '-',
        cholesterol: 4,
        averageDiastolic: '98',
        averageSystolic: '155',
      },
      cardioArrhythmia: {
        verdict: 'Тахиритмики',
        averagePulse: '100',
      },
      alcohol: {
        failedInspectionsByAlcohol: '13%', // будет от 5 и выше
        failedAlcoAmountYear: 1,
        failedAlcoAmountMonth: 1,
      },
      certificate: {
        dueDate: '12.14.15',
        diagnosis: 'Certificate diagnosis',
      },
      smoking: {
        smokingAddictionDegree: '54',
        smokingAddiction: 'курит очень много пацан',
      },
      oldAge: {
        oldAge: 'Возраст старше 55 лет',
      },
      stress: {
        systematicStressIndex: 3.7,
        systematicStress: 'Очень высокий риск. Срыв адаптации',
      },
    },
  },
];
