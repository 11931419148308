import authApi from '@/api/services/auth';

export default {
  state: {
    qrcode: null,
  },

  getters: {
    qrcode: state => state.qrcode || {},
  },

  mutations: {
    qrcode: (state, value) => (state.qrcode = value),
  },

  actions: {
    async fetchQrcode({ commit, getters }) {
      const res = await authApi.getQrcode(getters.id);
      commit('qrcode', res);
    },

    async postQrcode({ getters, commit }) {
      const res = await authApi.postQrcode(getters.id);
      commit('qrcode', res);
    },

    async deleteQrcode({ getters, commit }) {
      const res = await authApi.deleteQrcode(getters.id);
      commit('qrcode', res);
    },
  },
};
