<template>
  <ModernModalLayout
    :title="
      data.id
        ? 'Редактирование группы медработников'
        : 'Добавление группы медработников'
    "
    close-label="Отмена"
  >
    <v-form ref="form" :disabled="isLoading || disabled || $wait('submit')">
      <v-row>
        <v-col :cols="12">
          <v-text-field
            v-model="data.name"
            label="Название"
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('data.name')"
            @blur="validateField('data.name')"
          />
        </v-col>
        <v-col :cols="12">
          <v-text-field
            v-model="data.description"
            label="Описание"
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('data.description')"
            @blur="validateField('data.description')"
          />
        </v-col>
        <v-col v-if="!data.id" :cols="12">
          <OrganizationSelect
            v-model="data.orgId"
            hide-details="auto"
            medical
            access-level="full"
            :error-messages="getValidationErrors('data.orgId')"
            @blur="validateField('data.orgId')"
          />
        </v-col>
      </v-row>
    </v-form>

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :disabled="$wait('submit')"
        @click="submit"
      >
        {{ data.id ? 'Сохранить' : 'Добавить' }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import ModernModalLayout from '@/components/layouts/ModernModalLayout.vue';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';

export default {
  components: { ModernModalLayout, OrganizationSelect },
  mixins: [validation, waitable],

  props: {
    value: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },

  data: () => ({
    data: {},
    medicalOrgs: [],
  }),

  validations() {
    return {
      data: {
        name: { required },
        description: { required },
        orgId: { required: requiredIf(() => !this.data?.id) },
      },
    };
  },

  async created() {
    this.data = {
      id: this.value.id,
      name: this.value.name,
      description: this.value.description,
    };
  },

  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const actionName = this.data.id
        ? 'MEDBLOCK/MEDIC_GROUPS_ITEM/updateSingleItem'
        : 'MEDBLOCK/MEDIC_GROUPS/createListItem';

      await this.$loadingNotify(
        this.$store.dispatch(actionName, this.data),
        'submit',
        `Произошла ошибка ${
          this.data.id ? 'изменения' : 'сохранения'
        } группы медработников`,
        `Группа медработников успешно ${
          this.data.id ? 'изменена' : 'добавлена'
        }`,
      )
        .then(() => this.$emit('close'))
        .catch(err => this.catchServerErrors(err, 'data'));
    },
  },
};
</script>
