<template>
  <PageLayout
    back-tooltip="Вернуться к списку профилей"
    back-route="notifications:profiles"
    fluid
    :title="title"
    :loading="isLoading"
  >
    <v-card class="mt-4 d-flex mx-3" style="width: 100%">
      <v-card-text>
        <div class="d-flex">
          <v-text-field
            v-model="key"
            label="Ключ"
            outlined
            dense
            :readonly="!$can('NF_PU')"
            :disabled="isDefault || !isCreate"
            :error-messages="getValidationErrors('key')"
            @blur="validateField('key')"
          />
          <v-text-field
            v-model="name"
            label="Название профиля"
            outlined
            dense
            class="ml-2"
            :readonly="!$can('NF_PU')"
            :error-messages="getValidationErrors('name')"
            @blur="validateField('name')"
          />
          <div class="d-flex ml-2" style="margin-top: 2px">
            <ListActionBtn
              label="Сохранить"
              icon="fa-save"
              :icon-size="14"
              color="success"
              class="mr-1"
              :disabled="
                isCreate ? !$can('NF_PC') : !$can('NF_PU') || disableActions
              "
              @click="isCreate ? handleCreate() : handleSave()"
            />
            <ListActionBtn
              label="Удалить"
              icon="fa-trash-alt"
              :icon-size="14"
              color="error"
              :disabled="
                !$can('NF_PD') || isDefault || disableActions || isCreate
              "
              @click="handleRemove"
            />
          </div>
        </div>

        <v-text-field
          v-model="description"
          label="Описание"
          outlined
          dense
          :readonly="!$can('NF_PU')"
          :error-messages="getValidationErrors('description')"
          @blur="validateField('description')"
        />
        <RuleTable
          ref="ruleTable"
          v-model="rules"
          :editing-rule="$can('NF_PU')"
          :header="[{ key: 'templateSet', name: 'Наборы шаблонов' }]"
          :validation-rules="validationRules"
          @addRow="addRow"
          @cloneRow="cloneRow"
          @removeRow="removeRow"
        >
          <template
            #templateSet="{
              row,
              index,
              editing,
              getValidationErrors,
              validateField,
            }"
          >
            <Select
              v-if="editing"
              v-model="row.templateSet"
              :items="formattedTemplates"
              dense
              item-value="key"
              item-text="label"
              :error-messages="
                getValidationErrors('value.$each.' + index + '.templateSet')
              "
              @blur="validateField('value.$each.' + index + '.templateSet')"
            />
            <v-chip v-else small class="ma-1">
              {{ formattedTemplatesKV[row.templateSet] }}
            </v-chip>
          </template>
        </RuleTable>
      </v-card-text>
    </v-card>
  </PageLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import { uniqueStringKey } from '@/utils/validators';
import validationMixin from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';
import PageLayout from '@/components/layouts/PageLayout';
import RuleTable from '@/components/RuleTable/RuleTable';
import Select from '@/components/controls/Select';
import ListActionBtn from '@/components/controls/buttons/ListActionBtn';

const initialDate = () => ({
  key: '',
  name: '',
  description: '',
  rules: [],
});

const rulesTemplate = () => ({
  id: Math.random(),
  scenarioType: null,
  inspectionTypes: [],
  isCanceled: null,
  resolvedBy: null,
  result: null,
  visits: [],
  flags: [],
  templateSet: null,
});

export default {
  components: { PageLayout, RuleTable, Select, ListActionBtn },
  mixins: [validationMixin, waitable, routeGuardMixin],

  data: () => initialDate(),

  validations() {
    return {
      key: { required, uniqueStringKey },
      name: { required, maxSymbolsLength: maxLength(500) },
      description: { required, maxSymbolsLength: maxLength(500) },
    };
  },

  computed: {
    ...mapGetters('NOTIFICATIONS/PROFILES_CARD', [
      'singleIsLoading',
      'templates',
    ]),

    validationRules() {
      return {
        templateSet: { required },
      };
    },

    formattedTemplates() {
      return this.templates.map(el => {
        const filledTemplates = Object.keys(el.templates)
          .filter(type => el.templates[type])
          .join(', ');
        el.label = `${el.name} ${
          filledTemplates.length ? '(' + filledTemplates + ')' : ''
        }`;
        return el;
      });
    },

    formattedTemplatesKV() {
      return this.formattedTemplates.reduce((agg, el) => {
        agg[el.key] = el.label;
        return agg;
      }, {});
    },

    disableActions() {
      return this.$wait('createSingleItem') || this.$wait('changeSingleItem');
    },

    isCreate() {
      return this.$route.name.includes('create');
    },

    title() {
      if (this.isCreate) return 'Создание профиля';
      return `Профиль ${this.singleItem ? `"${this.singleItem.key}"` : '...'}`;
    },

    isLoading() {
      if (this.isCreate) return false;
      return this.singleIsLoading;
    },

    isDefault() {
      if (this.isCreate) return false;
      return this.singleItem?.key === 'default';
    },
  },

  watch: {
    singleItem: {
      immediate: true,
      handler(val) {
        if (val) {
          this.key = val.key;
          this.name = val.name;
          this.description = val.description;

          // another godly crutch due to out backend not prividing us
          // with an unique key/value for each rule
          // .. therefore in order to maintain reactivity we need to
          // generate random id for each rule      \o/
          this.rules =
            val?.rules?.map(el => {
              el.id = Math.random();
              return el;
            }) || [];
        }
      },
    },
  },

  created() {
    this.$loadingNotify(
      this.fetchTemplates(),
      'fetchTemplates',
      'Не удалось загрузить наборы шаблонов',
    );
  },

  mounted() {
    // set default values
    if (this.isCreate) Object.assign(this.$data, initialDate());
  },

  methods: {
    ...mapActions('NOTIFICATIONS/PROFILES_CARD', [
      'createSingleItem',
      'changeSingleItem',
      'deleteSingleItem',
      'fetchTemplates',
    ]),

    validate() {
      this.$v.$touch();
      const localFormIsValid = !this.$v.$invalid;
      const tableIsValid = this.$refs.ruleTable.validate();

      if (!(localFormIsValid && tableIsValid)) {
        this.$notify({
          group: 'note',
          type: 'error',
          title: 'Неверно заполнены поля',
          text: 'Исправьте ошибки и повторите снова',
        });
        return false;
      }
      return true;
    },

    addRow() {
      const row = rulesTemplate();
      this.rules.push(row);
      this.$refs.ruleTable.startEditing(row.id);
    },

    cloneRow(id) {
      const index = this.rules.findIndex(el => el.id === id);
      const data = JSON.parse(JSON.stringify(this.rules[index]));
      data.id = Math.random();

      this.rules.splice(index + 1, 0, data);
      this.$refs.ruleTable.startEditing(data.id);
    },

    removeRow(id) {
      const index = this.rules.findIndex(el => el.id === id);
      this.$refs.ruleTable.stopEditing(id);
      this.rules.splice(index, 1);
    },

    generateParams() {
      const copyRules = this.rules.reduce((acc, el) => {
        const copyEl = { ...el };
        // it's generated with a random id for the unique keys of newlines
        delete copyEl.id;
        acc.push(copyEl);
        return acc;
      }, []);

      return {
        key: (this.key || '').trim(),
        name: (this.name || '').trim(),
        description: (this.description || '').trim(),
        rules: copyRules,
      };
    },

    handleCreate() {
      if (!this.validate()) return;

      const data = this.generateParams();

      this.$loadingNotify(
        this.createSingleItem(data).then(res => {
          this.$router.push({
            name: 'notifications:profiles:item',
            params: {
              key: res.key,
            },
          });
        }),
        'createSingleItem',
        'Ошибка при создании профиля',
        'Профиль успешно создан',
      );
    },

    handleSave() {
      if (!this.validate()) return;

      const params = this.generateParams();
      const data = {
        id: this.singleItem.key,
        ...params,
      };

      const changedKey = params.key !== this.singleItem?.key;

      this.$loadingNotify(
        this.changeSingleItem(data).then(() => {
          this.$refs.ruleTable.clearEditingList();
          if (changedKey)
            this.$router.push({
              name: 'notifications:profiles:item',
              params: { key: params.key },
            });
        }),
        'changeSingleItem',
        'Ошибка при изменении профиля',
        'Профиль успешно сохранен',
      );
    },

    handleRemove() {
      this.$openModal('prompt', {
        yes: 'Подтвердить',
        no: 'Отменить',
        title: 'Удалить профиль?',
        description: 'Вы точно хотите удалить профиль?',
        messages: {
          successfulAction: 'Профиль успешно удален',
          errorAction: 'Ошибка при удалении профиля',
        },
        onSubmit: () =>
          this.deleteSingleItem(this.singleItem.key)
            .then(_ => this.$router.push({ name: 'notifications:profiles' }))
            .catch(err => {
              if (err?.response?.status === 500)
                return Promise.reject(
                  new Error('Профиль используется в подписках'),
                );
              return Promise.reject(err);
            }),
      });
    },
  },
};
</script>
