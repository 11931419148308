import { REMARK_TYPES } from '@/utils/constants';

export const messages = { resourceName: 'причин недопуска' };

export const entity = [
  {
    key: 'revision',
    label: 'Тип',
    convert: item => REMARK_TYPES[item.revision],
  },
  {
    key: 'remark',
    label: 'Ремарка',
    convert: (item, getters) =>
      getters['MEDBLOCK/RATINGS/inspectionRemarks'].find(
        val => val.id === item.remark,
      ).name,
  },
  { key: 'value', label: 'Оценка' },
];

export const filters = {};
