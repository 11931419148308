import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import SUBSCRIPTIONS from './store/list';
import SUBSCRIPTIONS_CARD from './store/item';
import RouteGuard from '@/components/RouteGuard';

export default {
  namespaced: true,
  store: { SUBSCRIPTIONS, SUBSCRIPTIONS_CARD },
  menu: [
    {
      title: 'Подписки',
      action: 'NF_SF',
      name: 'subscriptions',
      priority: 1,
    },
  ],
  routes: [
    {
      path: 'subscriptions',
      name: 'subscriptions',
      component: () =>
        import('@/modules/notifications/subscriptions/view/list.vue'),
      meta: {
        action: 'NF_SF',
      },
    },
    {
      path: 'subscriptions/create',
      name: 'subscriptions:create',
      component: RouteGuard({
        storeModule: 'NOTIFICATIONS/SUBSCRIPTIONS_CARD',
        component: () => import('./view/item.vue'),
        skipFetch: true,
      }),
      meta: {
        action: 'NF_SC',
      },
    },
    {
      path: 'subscriptions/:id',
      name: 'subscriptions:item',
      component: RouteGuard({
        storeModule: 'NOTIFICATIONS/SUBSCRIPTIONS_CARD',
        component: () => import('./view/item.vue'),
      }),
      meta: {
        action: 'NF_SR',
      },
    },
  ],
} as ModuleDefinition;
