var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('List',{attrs:{"available-actions":{
    showAction: false,
    createAction: false,
  }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [(_vm.$can('P_EVA'))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openWeightForm(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Изменить значение")])]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }