<template>
  <v-layout class="inspection-video black" column style="height: 100%">
    <v-menu
      v-if="video && videoMatch"
      :disabled="!isShowUnmatchedFrames"
      top
      offset-y
      open-on-hover
      nudge-bottom="-10px"
      close-delay="100"
      max-width="475px"
    >
      <template #activator="{ on, attrs }">
        <div
          v-if="matchState"
          class="font-weight-medium pa-2 inspection-video--match d-flex"
          :class="matchState.color"
          v-bind="attrs"
          v-on="on"
        >
          <span>{{ matchState.text }}</span>
          <v-spacer />
          <v-icon v-if="isShowUnmatchedFrames" dense :class="matchState.color">
            fas fa-exclamation-triangle
          </v-icon>
        </div>
      </template>
      <v-card v-if="isShowUnmatchedFrames" class="pa-3 text-body-2">
        <div class="pb-2 text-caption">Нераспознанные кадры</div>
        <v-layout wrap>
          <v-img
            v-for="item in videoMatch.result.unmatched"
            :key="item.frame"
            :src="item.frame"
            max-width="150px"
            style="cursor: pointer"
            @click="goToTime(item.timePos)"
          >
            <span class="white--text pa-3 bordered-text">
              {{ timeSecondsToString(item.timePos) }}
            </span>
          </v-img>
        </v-layout>
      </v-card>
    </v-menu>
    <template v-if="video">
      <Plyr ref="plyr" :url="video" hide-volume />
    </template>
    <div v-else class="white--text text-center ma-10">Видео отсутствует</div>
  </v-layout>
</template>

<script>
import Plyr from '@/components/Plyr';
import { timeSecondsToString } from '@/utils/helpers';

const STATUSES = {
  pending: {
    text: 'Видео распознается, ожидайте',
    color: 'grey lighten-1',
  },
  failed: {
    text: 'Ошибка распознавания',
    color: 'grey lighten-1',
  },
  'done.GREEN': {
    text: 'Полное соответствие',
    color: 'green lighten-2 white--text',
  },
  'done.YELLOW': {
    text: 'Требует уточнения',
    color: 'warning lighten-2 white--text',
  },
  'done.RED': {
    text: 'Похоже, что на видео другой человек',
    color: 'error lighten-2 white--text',
  },
};

export default {
  components: {
    Plyr,
  },
  props: {
    video: { type: String, default: null },
    videoMatch: { type: Object, default: null },
  },
  computed: {
    matchState() {
      if (this.videoMatch.status !== 'done')
        return STATUSES[this.videoMatch.status];

      return STATUSES[
        this.videoMatch.status + '.' + this.videoMatch.result.color
      ];
    },

    isShowUnmatchedFrames() {
      return !!this.videoMatch?.result?.unmatched.length;
    },
  },

  methods: {
    timeSecondsToString,

    goToTime(seconds) {
      this.$refs.plyr && this.$refs.plyr.goToTime(seconds);
    },
  },
};
</script>

<style lang="scss" scoped>
.inspection-video {
  &--match {
    width: 100%;
  }
}

.bordered-text {
  text-shadow: 1px 0 1px #000, 0 1px 1px #000, -1px 0 1px #000, 0 -1px 1px #000;
}
</style>
