<template>
  <DefinitionList :value="extrasData" :width-columns="['60%', '40%']" />
</template>

<script>
import { date } from '@/utils/convert';
import DefinitionList from '@/components/DefinitionList';

export default {
  name: 'MedicalPreventionFields',
  components: {
    DefinitionList,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    extrasData() {
      return [
        {
          label: 'Дата проведения осмотра',
          value: date(this.value.inspectionCheckupTakenAt) || '–',
        },
        {
          label: 'Дата окончания действия осмотра',
          value: date(this.value.inspectionCheckupExpiredAt) || '–',
        },
      ];
    },
  },
};
</script>
