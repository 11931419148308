import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import EPL_KONTUR from './store/list';

export default {
  namespaced: true,
  store: { EPL_KONTUR },
  menu: [
    {
      title: 'ЭПЛ Контур',
      name: 'epl-kontur',
      action: 'WK_GL',
      priority: 3,
    },
  ],
  routes: [
    {
      path: 'epl-kontur',
      name: 'epl-kontur',
      component: () => import('./views/list.vue'),
    },
  ],
} as ModuleDefinition;
