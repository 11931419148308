<template>
  <span class="link_button__item">
    <template v-if="allowAction">
      <v-btn
        small
        text
        color="primary"
        :min-width="dense ? 'auto' : 45"
        :class="[{ 'px-1': !dense, 'pa-0': dense }]"
        :style="[dense ? { height: 'auto' } : {}]"
        :to="to"
        :target="blank ? '_blank' : '_self'"
      >
        <template v-if="text">{{ text }}</template>
        <slot v-else name="default" />
      </v-btn>
    </template>

    <template v-else>
      <span
        v-if="text"
        class="d-inline-flex"
        :class="[{ 'px-1': !dense, 'pa-0': dense }]"
      >
        <span style="white-space: break-spaces">{{ text }}</span>
      </span>
      <slot v-else name="default" />
    </template>
  </span>
</template>

<script>
export default {
  props: {
    to: { required: true, type: [String, Object] },
    text: { type: String, default: null },
    action: Boolean,
    dense: Boolean,
    blank: Boolean,
  },

  computed: {
    allowAction() {
      // Исключение для ссылок на осмотры для медиков
      const nameNextPage = this.to?.name || this.to;
      if (
        nameNextPage === 'inspections:card' &&
        this.$store.getters['AUTH/isMedic']
      )
        return this.action;

      // Заблокировать кнопку для всех, кроме ролей с разрешением ORG_AL
      if (this.$can('ORG_AL')) return this.action;
      return false;
    },
  },
};
</script>

<style scoped>
.link_button__item::v-deep .v-btn {
  height: auto;
  padding-top: 7px;
  padding-bottom: 7px;
}
.link_button__item::v-deep .v-btn__content {
  white-space: break-spaces;
  flex-shrink: 1;
  text-indent: 0;
}
</style>
