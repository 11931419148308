<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isUsedOldVersion']),
  },

  watch: {
    isUsedOldVersion: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.$notify({
              group: 'fatalErrors', // Группа без возможности скрытия по клику
              type: 'error',
              duration: -1,
              title: 'Вышло обновление',
              text: 'Пожалуйста, перезагрузите страницу для корректной работы',
            });
          });
        }
      },
    },
  },

  render() {
    return null;
  },
};
</script>
