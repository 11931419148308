<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <v-btn depressed small text color="primary" v-bind="$attrs" v-on="$listeners">
    {{ label }}
  </v-btn>
</template>

<script>
export default { props: { label: { required: true, type: String } } };
</script>
