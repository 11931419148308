export function itemFactory({
  name,
  fetchMethod,
  defaultValue = [],
  primaryKey = 'id',
}) {
  if (!name) throw Error('Name not found');
  const nameUpperCase = name[0].toUpperCase() + name.slice(1);

  return {
    state: {
      [name]: null,
    },
    getters: {
      [name]: state => state[name] || defaultValue,
      [`get${nameUpperCase}`]: state => value =>
        (state[name] || []).find(el => el[primaryKey] === value) || null,
    },
    mutations: {
      [`set${nameUpperCase}`]: (state, value) => (state[name] = value),
      [`add${nameUpperCase}`]: (state, value) => {
        if (!state[name]) return;
        state[name].push(value);
      },
      [`update${nameUpperCase}`]: (state, value) => {
        if (!state[name]) return;
        const index = state[name].findIndex(
          el => el[primaryKey] === value[primaryKey],
        );
        if (index === -1) throw Error(`Item in structure ${name} not found`);

        state[name].splice(index, 1, value);
      },
      [`remove${nameUpperCase}`]: (state, primaryKeyValue) => {
        if (!state[name]) return;
        const index = state[name].findIndex(
          el => el[primaryKey] === primaryKeyValue,
        );
        if (index === -1)
          throw Error(`Item for remove from structure ${name} not found`);

        state[name].splice(index, 1);
      },
    },
    actions: {
      async ['fetch' + nameUpperCase]({ commit }) {
        const response = await fetchMethod();
        commit(`set${nameUpperCase}`, response);
      },
    },
  };
}
