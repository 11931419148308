<template>
  <EmployeeList
    search-filter
    :available-actions="{
      showAction: $can('EMP_RD'),
      createAction: $can('EMP_C') && !createForbidden,
      importAction: $can('EMP_I') && !importForbidden,
    }"
    @item:create="openCreateEmployee"
  >
    <template #header.actions>
      <ListActionBtn
        v-if="$can('EMP_CT') && !createForbidden"
        label="Добавить тестового"
        icon="mdi-account-multiple-plus-outline"
        color="success"
        @click="createTestEmployee"
      />
      <ListActionBtn
        v-if="showUpdateRisks"
        label="Пересчитать риски"
        icon="mdi-calculator"
        @click="updateRisks"
      />
    </template>
    <template #filters="query">
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.isEnabled"
          label="Статус работников"
          :items="statusItems"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :menu-props="{ offsetY: true }"
        />
      </v-col>
    </template>

    <template #import.prepend="{ data, getValidationErrors }">
      <v-col class="pb-0" cols="12">
        <OrganizationSelectImport
          v-model="data.orgId"
          :predefined-value="org.id"
          :error-messages="getValidationErrors('data.orgId')"
          dense
        />
      </v-col>
    </template>

    <template #table.item.name_gender_birthDay="{ item }">
      <b><EmployeeName :value="item" /></b>
      {{ GENDER_NOMINATIVE[item.gender] }}, {{ date(item.dateOfBirth) }}
    </template>

    <template #table.item.isEnabled="{ item }">
      <Indicator
        :active="item.isEnabled"
        :tooltip="item.isEnabled ? 'Активен' : 'Неактивен'"
      />
    </template>
  </EmployeeList>
</template>

<script>
import medrecApi from '@/api/services/medrec';
import env from '@/plugins/env';
import { date } from '@/utils/convert';
import { STATUS, GENDER_NOMINATIVE } from '@/utils/constants.js';
import {
  messages as employeesMessages,
  entityEmbedded as employeesEntity,
  filters as employeesFilters,
} from '@/modules/employees/entity';

// components
import Indicator from '@/components/Indicator.vue';
import EmployeeName from '@/modules/employees/components/EmployeeName';
import ListFactory from '@/components/crud/ListFactory';
import OrganizationSelectImport from '@/components/modals/OrganizationSelectImport.vue';
import ListActionBtn from '@/components/controls/buttons/ListActionBtn';

const EmployeeList = ListFactory({
  storeModule: 'STRUCTURES/EMBEDDED_EMPLOYEE_LIST',
  messages: employeesMessages,
  entity: employeesEntity.filter(val => val.key !== 'organization'),
  filters: employeesFilters,
  viewRoute: 'employees:card',
  embedded: true,
});

export default {
  components: {
    Indicator,
    EmployeeList,
    EmployeeName,
    OrganizationSelectImport,
    ListActionBtn,
  },
  props: {
    org: { type: Object, required: true },
  },
  computed: {
    createForbidden() {
      const actions = this.org?.settings?.employeeSync?.actions || [];
      return actions.includes('create') || this.importForbidden;
    },
    importForbidden() {
      return this.org?.settings?.employeeSync?.employeeModify === false;
    },
    statusItems: () => STATUS,
    showUpdateRisks() {
      return (
        ['development', 'integration_v3'].includes(
          env.get('VUE_APP_ENVIRONMENT'),
        ) || this.$store.getters['AUTH/isRoot']
      );
    },
    GENDER_NOMINATIVE: () => GENDER_NOMINATIVE,
  },
  methods: {
    date,

    createTestEmployee() {
      this.$openModal('employees/form', {
        item: {},
        isTestEmployee: true,
        predefinedValues: { orgId: this.org.id },
        onSubmit: data =>
          this.$store.dispatch(
            'STRUCTURES/EMBEDDED_EMPLOYEE_LIST/createTestEmployee',
            data,
          ),
        fetchOrganizationSettings: id =>
          this.$store.dispatch('EMPLOYEE_LIST/fetchOrganizationSettings', id),
      });
    },

    updateRisks() {
      this.$openModal('prompt', {
        yes: 'Пересчитать',
        no: 'Отмена',
        title: 'Пересчитать риски',
        description:
          'Будут пересчитаны группы риска для работников. Это может занять некоторое время. Вы уверены?',
        onSubmit: () => medrecApi.updateRiskGroup({ orgId: this.org.id }),
      });
    },

    openCreateEmployee() {
      this.$openModal('employees/form', {
        // object will b like {id: undefined, name: undefined ...}
        item: Object.fromEntries(employeesEntity.map(field => [field.key])),
        entity: employeesEntity,
        messages: employeesMessages.create,
        fetchOrganizationSettings: id =>
          this.$store.dispatch('EMPLOYEE_LIST/fetchOrganizationSettings', id),
        predefinedValues: { orgId: this.org.id },
        onSubmit: data =>
          this.$store.dispatch(
            'STRUCTURES/EMBEDDED_EMPLOYEE_LIST/createListItem',
            data,
          ),
      });
    },
  },
};
</script>

<style></style>
