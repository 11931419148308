<template>
  <div class="medcab" :style="[boxHeight]">
    <v-layout class="medcab__main">
      <slot />
    </v-layout>
    <v-row class="medcab__actions ma-0 mt-3">
      <v-col class="py-0" cols="3" lg="4">
        <slot name="actions.left" />
      </v-col>
      <v-col class="py-0 d-flex justify-center" cols="6" lg="4">
        <slot name="actions.center" />
      </v-col>
      <v-col class="py-0" cols="3" lg="4">
        <slot name="actions.right" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    headerHeight: 0,
  }),

  computed: {
    boxHeight() {
      return { minHeight: `calc(100vh - ${this.headerHeight})` };
    },
  },

  mounted() {
    const header = document.getElementsByTagName('header')?.[0];
    if (header) this.headerHeight = header.style.height;
  },
};
</script>

<style lang="scss">
.medcab {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 104px;
}
</style>
