import { dateTime } from '@/utils/convert.js';
import { timeStringToSeconds } from '@/utils/helpers.js';
import { Line } from 'vue-chartjs';
import { generateColor } from '@/utils/helpers';

// https://github.com/apertureless/vue-chartjs
// https://www.chartjs.org/docs/latest/
export default {
  extends: Line,
  props: {
    value: { type: Array, required: true },
    timezone: { type: Number, required: true },
  },
  data: () => ({
    htmlLegend: null,
  }),
  mounted() {
    // create datasets (a set of lines on this chart)
    const datasets = [];
    // here we add all dates from data
    const labels = [];

    this.value.forEach(item => {
      // apply timezone from filters
      const currentDate = dateTime(item.dateISO, {
        timezone: this.timezone,
        format: 'dd.MM.y',
      });
      const name = item.orgName;
      const duration = timeStringToSeconds(item.totalDuration);

      if (!labels.includes(currentDate)) labels.push(currentDate);

      const dataset = datasets.find(item => item.label === name);
      const labelIndex = labels.indexOf(currentDate);

      // if we have already added data for this date
      if (dataset) {
        if (dataset.data[labelIndex]) {
          dataset.data[labelIndex].push(duration);
        } else {
          dataset.data[labelIndex] = [duration];
        }
      } else
        datasets.push({
          label: name,
          fill: false,
          backgroundColor: generateColor(datasets.length),
          borderColor: generateColor(datasets.length),
          data: [[duration]],
        });
    });

    // calculate the average per day for every organization
    datasets.forEach(item => {
      item.data = item.data.map(item =>
        Math.round(item.reduce((a, b) => a + b) / item.length),
      );
    });

    // fill empty values of array with zeros,
    // otherwise line on graph will be interrupted
    datasets.forEach(item => {
      item.data = Array(labels.length)
        .fill(0)
        .map((_, index) => {
          if (item.data[index]) return item.data[index];
          return 0;
        });
    });

    this.renderChart(
      { labels, datasets },
      {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index',
          callbacks: {
            label: (tooltipItem, data) => {
              const { label } = data.datasets[tooltipItem.datasetIndex];
              const time = timeToString(tooltipItem.value);
              const sec = '(' + tooltipItem.value + ' сек.)';
              return label + ': ' + time + ' ' + sec;
            },
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: { display: true, labelString: 'Даты' },
            },
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                callback(label) {
                  const sec = label.toFixed(0);
                  let minutes = Math.floor(sec / 60);
                  let seconds = sec % 60;
                  minutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
                  seconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
                  return `${minutes}:${seconds}`;
                },
              },
              scaleLabel: {
                display: true,
                labelString: 'Продолжительность в минутах',
              },
            },
          ],
        },
      },
    );
  },
};

function timeToString(time) {
  const sec = parseInt(time, 10); // don't forget the second param
  // let hours = Math.floor(sec / 3600);
  let minutes = Math.floor(sec / 60);
  let seconds = sec - minutes * 60;

  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return minutes + ' минут и ' + seconds + ' секунд';
}
