<template>
  <v-simple-table dense>
    <thead>
      <tr>
        <th class="py-3">
          Параметры
          <span
            v-if="boundaries.origin === 'individual'"
            class="pl-2 warning--text"
          >
            (Индивидуальные)
          </span>
        </th>
        <th class="py-3">Границы</th>
        <th class="py-3">Среднее</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="py-1">
          Артериальное давление
          <div class="grey--text">Систолическое, мм рт. ст</div>
        </td>
        <td>
          {{ boundaries.values.pressure.systolic.lower }} –
          {{ boundaries.values.pressure.systolic.upper }}
        </td>
        <td>{{ average.pressure.systolic }}</td>
      </tr>
      <tr>
        <td class="py-1">
          Артериальное давление
          <div class="grey--text">Диастолическое, мм рт. ст</div>
        </td>
        <td>
          {{ boundaries.values.pressure.diastolic.lower }} –
          {{ boundaries.values.pressure.diastolic.upper }}
        </td>
        <td>{{ average.pressure.diastolic }}</td>
      </tr>
      <tr>
        <td class="py-1">
          Пульс
          <span class="grey--text">, уд./мин</span>
        </td>
        <td>
          {{ boundaries.values.pulse.lower }} –
          {{ boundaries.values.pulse.upper }}
        </td>
        <td>{{ average.pulse }}</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    boundaries: {
      required: true,
      type: Object,
    },
    average: {
      type: Object,
      default: () => ({
        pressure: { diastolic: '–', systolic: '–' },
        pulse: '–',
      }),
    },
  },
};
</script>
