<template>
  <ModernModalLayout
    class="prophylactic-events"
    size="xlarge"
    title="Общие профилактические мероприятия"
  >
    <v-tabs vertical active-class="prophylactic-events__tab--active">
      <v-tab class="prophylactic-events__tab">Для справки</v-tab>
      <v-tab class="prophylactic-events__tab">
        Советы по здоровому образу жизни и&nbsp;диете
      </v-tab>
      <v-tab class="prophylactic-events__tab">
        Принципы рационального питания
      </v-tab>
      <v-tab class="prophylactic-events__tab">
        Принципы режима труда и отдыха
      </v-tab>
      <v-tab class="prophylactic-events__tab">
        Практические советы избавления от&nbsp;никотиновой зависимости
      </v-tab>
      <v-tab class="prophylactic-events__tab">
        Влияние алкоголя на организм человека
      </v-tab>
      <v-tab class="prophylactic-events__tab">
        Принципы противостояния стрессовым ситуациям
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text class="prophylactic-events__tab-content">
            <p>
              Согласно рекомендациям Всемирной Организации Здравоохранения,
              нормальным считается уровень артериального давления ниже
              130/85&nbsp;мм.рт.ст., уровень давления между 130/85
              и&nbsp;140/90&nbsp;мм.рт.ст. относится к высокому нормальному, а
              давление 140/90&nbsp;мм.рт.ст. и&nbsp;выше считается артериальной
              гипертонией. Большая часть лиц с гипертонией имеет умеренное
              повышение давления и&nbsp;не&nbsp;предъявляет жалоб
              на&nbsp;здоровье, однако бессимптомное течение еще
              не&nbsp;означает отсутствие болезни.
            </p>
            <p>
              Если врач назначает медикаменты, то очень важно, строго следовать
              его рекомендациям. Все советы, описанные в рекомендациях по
              здоровому образу жизни, не являются кратковременными, их
              необходимо принять как новый, более здоровый образ жизни
              и&nbsp;новые привычки.
            </p>
            <p>
              Если артериальное давление повышается незначительно, то соблюдение
              приведенных правил может избавить от серьезных заболевания, а если
              давление склонно к&nbsp;значительным и длительным подъемам и
              требуется прием медикаментов по назначению врача, то соблюдение
              этих советов поможет быстрее добиться снижения давления, уменьшить
              дозы лекарственных средств.
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text class="prophylactic-events__tab-content">
            <ul>
              <li>
                регулярное измерение артериального давления (АД) утром и
                вечером, ведение дневника контроля АД, поддержание АД в целевых
                значениях &lt;&nbsp;130/80&nbsp;мм.рт.ст.;
              </li>
              <li>отказ от курения;</li>
              <li>
                контроль веса, поддержание «здоровых показателей» массы тела,
                т.е. поддержание индекса массы тела (ИМТ) около 25 кг/м2,
                окружность в&nbsp;пределах &lt;&nbsp;102&nbsp;см у мужчин,
                &lt;&nbsp;88&nbsp;см у женщин;
              </li>
              <li>
                достаточная физическая активность: регулярная динамическая
                (аэробная) физическая нагрузка должна составлять не менее
                30&#8209;40&nbsp;мин в день 5&#8209;7&nbsp;раз в нед. (это может
                быть ходьба, скандинавская ходьба, бег, плавание
                в&nbsp;бассейне, катание на&nbsp;велосипеде, кардиотренажеры);
              </li>
              <li>
                соблюдение диеты – ключевые положения диеты заключаются в
                следующем:
                <ul>
                  <li>ограничение поваренной соли менее 5 грамм в сутки;</li>
                  <li>отказ от потребления алкоголя;</li>
                  <li>
                    ограничение «вредных жиров» или, как правильно их называют,
                    насыщенные и&nbsp;транснасыщенные, они должны содержать не
                    более 10% суточного рациона питания (эти жиры содержатся в
                    мясе, молоке, сливках, маргарине, пальмовом и кокосовом
                    масле);
                  </li>
                  <li>
                    «полезные жиры» или мононасыщенные и полинасыщенные должны
                    составлять 10&#8209;13%&nbsp;суточного рациона (они
                    содержатся в&nbsp;орехах, в&nbsp;рыбе, в&nbsp;растительных
                    маслах – оливковом, рапсовом, льняном, кукурузном);
                  </li>
                  <li>
                    употребление в пищу достаточного количества овощей и фруктов
                    (не менее 300&#8209;400&nbsp;грамм в&nbsp;день);
                  </li>
                  <li>
                    является полезным употребление в пищу продуктов с богатым
                    содержанием клетчатки (фасоль, белый рис, оливки, свежие
                    овощи).
                  </li>
                </ul>
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text class="prophylactic-events__tab-content">
            <p class="prophylactic-events__important">
              <b>Рациональное питание</b> — это питание, которое способствует
              поддержанию здоровья, удовлетворяет потребности нашего организма в
              питательных веществах, витаминах, минеральных веществах, энергии.
            </p>
            <p>Основные принципы рационального питания:</p>
            <ul>
              <li>
                сбалансированность суточного поступления энергии с ее суточным
                расходованием в&nbsp;повседневной жизни и работе;
              </li>
              <li>
                сбалансированность питательных веществ (белков - 15%, жиров -
                30%, углеводов - 55% от&nbsp;суточной калорийности), витаминов и
                минеральных веществ;
              </li>
              <li>
                режим питания. Желательно принимать пищу не реже 4-5 раз в день,
                в одно и то же время, не&nbsp;переедая перед сном. Оптимальный
                интервал между ужином и завтраком - не более 10&nbsp;часов.
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text class="prophylactic-events__tab-content">
            <p>
              Для хорошего самочувствия следует хорошо отдыхать, спать не менее
              8-9 часов в сутки, обязательно необходимо отрегулировать
              физиологический суточный ритм - чередование часов бодрствования
              (день) и сна (ночь).
            </p>
            <h3 class="prophylactic-events__subtitle">Практические советы</h3>
            <p>
              Для улучшения засыпания могут быть полезны перед сном некоторые
              "неспецифические" средства:
            </p>
            <ul>
              <li>спокойная пешая прогулка на свежем воздухе;</li>
              <li>теплая ножная или общая ванна;</li>
              <li>отказ от плотного ужина в вечернее время;</li>
              <li>
                отказ от вечерних телевизионных программ и электронных гаджетов.
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text class="prophylactic-events__tab-content">
            <p>
              Курение оказывает отрицательное влияние на здоровье. Никотин,
              содержащийся в сигаретном дыме, увеличивает нагрузку на сердце,
              вызывает сужение сосудов, повышает артериальное давление.
            </p>
            <p>
              Решив отказаться от курения, начните с анализа причин, почему Вы
              курите и так ли уж необходимо курить?
            </p>
            <p>
              Заведите дневник, регистрируя хотя бы в течение нескольких дней
              каждую выкуренную сигарету и&nbsp;ее реальную потребность для Вас.
              Постарайтесь избавиться от автоматического курения, сделав его
              заметным для себя (смените сорт сигарет, место для зажигалки,
              пачки сигарет).
            </p>
            <p>
              Постарайтесь найти замену курению как времяпрепровождению,
              избегайте компаний курящих. Наметьте день полного отказа от
              курения. Если Вы ощущаете потребность в поддержке, объявите
              о&nbsp;своем желании бросить курить друзьям и близким - их
              поддержка поможет сделать решающий шаг. Бросить курить, особенно
              при многолетней приверженности этой привычке, бывает нелегко
              самостоятельно.
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text class="prophylactic-events__tab-content">
            <p>
              Злоупотребление алкоголем сопровождается нарушением обмена
              веществ, снижением активности работы всех органов и систем,
              подавляет интеллект, инициативность, интерес к&nbsp;социальной
              жизни.
            </p>
            <p>
              При регулярном употреблении спиртных напитков повышается
              артериальное давление, как&nbsp;следствие утяжеляется течение
              артериальной гипертонии, развивается кардиомиопатия, нарушается
              ритм сердца. Все это увеличивает риск развития инсульта.
            </p>
            <p><strong>И помните — безвредных доз алкоголя нет!</strong></p>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text class="prophylactic-events__tab-content">
            <p>
              Снижать психоэмоциональное напряжение (стресс) можно научиться, в
              этом помогут способы психической регуляции, аутогенной тренировки
              (тренировка дыхания, волевое расслабление мышц, изменение
              положения тела, концентрация внимания, порой, простой отдых).
              Избегайте состояния переутомления, особенно хронического,
              чередуйте занятия умственной и физической работой. Избежать многих
              стрессовых ситуаций практически невозможно, но научиться правильно
              к ним относиться и нейтрализовать можно.
            </p>
            <h3 class="prophylactic-events__subtitle">Практический совет</h3>
            <p>
              «Метод глубокого дыхания» является важной частью всех
              расслабляющих процедур и стратегией для быстрого снижения
              различных стрессов. Для того чтобы научиться правильно и глубоко
              дышать, существуют специальные правила:
            </p>
            <ul>
              <li>поза должна быть удобной (сидя или стоя), глаза закрыты;</li>
              <li>одну ладонь положить на грудь, другую — на живот;</li>
              <li>вдох через нос должен быть медленным;</li>
              <li>
                при вдохе сначала должна подняться рука на животе, а затем на
                груди;
              </li>
              <li>
                в конце вдоха поднять плечи и ключицы, заполняя легкие до самых
                верхушек;
              </li>
              <li>на несколько секунд задержать дыхание;</li>
              <li>
                постепенно выдыхать через рот, слегка втягивая живот и
                расслабляя плечи.
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </ModernModalLayout>
</template>

<script>
import ModernModalLayout from '@/components/layouts/ModernModalLayout.vue';
export default {
  components: { ModernModalLayout },
};
</script>

<style lang="scss" scoped>
.prophylactic-events {
  &__tab {
    font-size: 12px;
    max-width: 350px;
    white-space: normal;
    justify-content: flex-start;
    text-align: left;

    &--active {
      background: rgba(0, 0, 0, 0.06);
    }
  }
  &__tab-content.v-card__text {
    padding: 0 0 0 16px;
    color: black;

    p {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
      &.prophylactic-events__important {
        background: rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        margin-bottom: 16px;
        padding: 8px 16px;
      }
    }

    li {
      margin-bottom: 4px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__subtitle {
    margin: 16px 0 8px 16px;
  }
}
</style>
