<script>
import waitable from '@/utils/mixins/waitable';
import {
  VListItem,
  VListItemContent,
  VListItemTitle,
  VListItemSubtitle,
  VListItemAction,
  VSimpleCheckbox,
} from 'vuetify/lib';
import Select from './Select';
import debounce from 'lodash/debounce';

export default {
  mixins: [waitable],

  props: {
    value: { type: [String, Number, Array], default: null },
    fetcher: { type: Function, required: true },
    // function that loads data by ids array (might be 1 or many)
    fetcherBy: { type: Function, required: true },
    query: { type: Object, default: null },
    limit: { type: Number, default: 20 },
    label: { type: String, default: null },
    itemValue: { type: [String, Function], default: 'id' },
    itemText: { type: [String, Function], default: 'name' },
    errorMessages: { type: Array, default: null },

    // sometimes we don't want to be able to choose item from select on its' own
    // page so we pass flag 'excludeSelf' (which technically might be omitted
    // since we can only pass 'selfValue', but i implemented this flag for
    // transparency) and value of 'self' to compare with
    excludeSelf: { type: Boolean, default: false },
    selfValue: { type: [Number, String], default: null },

    hint: { type: [Array, String], default: null },
    multiple: Boolean,
    clearable: Boolean,
    dense: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    hideDetails: { type: [Boolean, String], default: 'auto' },
  },

  data: () => ({
    items: [],
    opened: false, // crutch variable, look for comments where it's being used
    // item (or items if choose multiple)
    // that are currently displayed in select (in its input)
    selectedItems: undefined,
    search: undefined,
    searchChanged: false,
    page: 0,
    total: Infinity,
    error: null,
    localReadonly: false,
  }),

  computed: {
    // when selected items are somewhere in the end of display array
    // or when user searches for items in list, selected items are not in array of items
    // so we keep selected items separately (in selectedItems) and then merge them
    computedItems() {
      // prepend chosen items only if no search query presented
      return [...(this.selectedItems || []), ...this.items];
    },

    loading() {
      return this.$wait('fetchingData');
    },
  },

  watch: {
    value(val) {
      this.$nextTick(() => {
        if (val?.length && this.multiple) {
          this.selectedItems = val
            .map(item => this.getItem(item))
            .filter(item => item);
        } else if (val && !this.multiple)
          this.selectedItems = [this.getItem(val)];
        else this.selectedItems = [];
      });
    },
    search() {
      this.searchChanged = true;
    },
    loading(val) {
      // Запрет на ввод во время загрузки нужно установить с небольшой задержкой
      // чтобы успело открыться выпадающее меню
      // у селекта со свойством readonly меню не открывается
      setTimeout(() => (this.localReadonly = val), 100);
    },
  },

  created() {
    // request data only if initial value is not null
    // reports are passing in value [] as initial value, so we also need to
    // check it for empty arrays
    if (Array.isArray(this.value))
      this.value.length && this.everyTimeIsLikeTheFirstTime();
    else this.value && this.everyTimeIsLikeTheFirstTime();
  },

  mounted() {
    // bind scroll handler to autocomplete
    const autocomplete = this.$refs.autocomplete;
    const _onScroll = autocomplete.onScroll;

    autocomplete.onScroll = () => {
      _onScroll.call(autocomplete);

      if (!autocomplete.isMenuActive) return;
      const content = autocomplete.getContent();

      const showMoreItems =
        content.scrollHeight - (content.scrollTop + content.clientHeight) < 200;

      if (!this.disabled && !this.readonly && showMoreItems) this.fetchData();
    };
  },

  methods: {
    // we don't want to cache anything in async selects, so we fetch again every
    // time on 'focus' event. But we store chosen items in component and cache them
    // ... now we wait until some1 will find a bug 'instance does not dissappear
    // from select after it was deleted'
    async everyTimeIsLikeTheFirstTime() {
      this.items = this.selectedItems || [];
      this.page = 0;

      // fetch selected items for preview (didn't see where these 4 lines
      // below are being used, they might be not needed, but i didn't take a
      // risk to remove them)
      if (
        (!this.multiple && this.value) ||
        (this.multiple && this.value?.length)
      )
        this.selectedItems = await this.fetcherBy(
          this.multiple ? this.value : [this.value],
        );

      if (!this.disabled && !this.readonly) this.fetchData();
    },
    renderItemAction(item, on) {
      if (!this.multiple) return null;
      const itemValue =
        typeof this.itemValue === 'function'
          ? this.itemValue(item)
          : this.itemValue;

      return this.$createElement(VListItemAction, {}, [
        this.$createElement(VSimpleCheckbox, {
          props: {
            color: 'primary',
            value: Boolean(this.value?.find(id => id === item[itemValue])),
          },
          on: {
            input: () => on.click(item),
          },
        }),
      ]);
    },
    renderItem({ item, on, attrs }) {
      const itemDescription = this.$scopedSlots['item:description'];
      const itemText = this.getItemText(item);

      return this.$createElement(VListItem, { on, attrs }, [
        this.renderItemAction(item, on),

        this.$createElement(VListItemContent, {}, [
          this.$createElement(VListItemTitle, {}, itemText),
          itemDescription
            ? this.$createElement(
                VListItemSubtitle,
                {},
                itemDescription({ item }),
              )
            : null,
        ]),
      ]);
    },
    async fetchData() {
      const current = this.page * this.limit;
      if (this.loading || current > this.total) return;

      this.error = null;

      if (this.search?.length >= 100) {
        this.error = 'Не более 100 символов';
        return;
      }

      try {
        const { total, items } = await this.$loading(
          this.fetcher({
            ...this.query,
            page: this.page + 1,
            limit: this.limit,
            search: this.search,
          }),
          'fetchingData',
        );

        if (this.excludeSelf) {
          const index = items
            .map(item => item[this.itemValue])
            .indexOf(this.selfValue);
          index >= 0 && items.splice(index, 1);
        }

        this.page += 1;
        this.total = total;
        this.items = [
          // if search search was changed - clear items list
          ...(this.searchChanged ? [] : this.items),
          // the response itself
          ...items,
        ];

        this.searchChanged = false;

        // заново вычислить размеры и расположение меню
        this.$refs.autocomplete &&
          this.$refs.autocomplete.updateMenuDimensions();

        this.$emit('fetched', items);
      } catch (error) {
        this.error = error;
        console.error(error);
      }
    },
    handleSearch: debounce.call(
      this,
      function (value) {
        const isSearching = this.$refs.autocomplete?.isSearching;
        if (!isSearching && !this.search) return;

        this.page = 0;
        this.total = Infinity;
        this.items = [];
        this.search = isSearching ? value?.trim() || undefined : undefined;
        this.fetchData();
      },
      500,
    ),
    getItem(value) {
      return this.computedItems.find(item => {
        const itemValue =
          typeof this.itemValue === 'function'
            ? this.itemValue(item)
            : this.itemValue;

        return item[itemValue] === value;
      });
    },
    getItemText(item) {
      const itemText =
        typeof this.itemText === 'function'
          ? this.itemText(item)
          : item[this.itemText];
      // at the request of Sergey: it shows id of each item before name
      const itemTextPrefix = this.$store.getters['AUTH/isRoot']
        ? '#' + item[this.itemValue] + ' '
        : '';

      return itemTextPrefix + itemText;
    },
  },

  render() {
    return this.$createElement(Select, {
      props: {
        items: this.computedItems,
        loading: this.loading,
        readonly: this.readonly || this.localReadonly,

        // props to props
        value: this.value,
        label: this.label,
        multiple: this.multiple,
        chips: this.multiple,
        itemValue: this.itemValue,
        itemText: this.getItemText,
        clearable: this.clearable,
        dense: this.dense,
        error: Boolean(this.error),
        errorMessages: this.errorMessages,
        disabled: this.disabled,
        hint: this.hint,
        noDataText: this.error
          ? this.error
          : this.loading
          ? 'Данные загружаются...'
          : 'Данные отсутствуют',
        filter: () => true, // DON'T CHANGE DAT FUCING STRING, IT'S A CRUTCH
        // look
        outlined: true,
        hideDetails: this.hideDetails,
        persistentHint: true,
      },
      on: {
        ...this.$listeners,
        'update:search-input': this.handleSearch,
        focus: event => {
          this.error = null;
          this.opened = true;
          this.search = undefined;
          this.everyTimeIsLikeTheFirstTime();
          this.$emit('focus', event);
        },
        blur: event => {
          this.error = null;
          this.opened = false;
          this.$emit('blur', event);
        },
        // NOTE: I don't know how to explain this bug, better see it here:
        // https://jira.medpoint24.ru/browse/SW-6486
        // Sometimes select doesn't register blur/focus events and we need to
        // track it manually
        click: e => {
          if (!this.opened) {
            this.opened = true;
            this.everyTimeIsLikeTheFirstTime();
          }
          this.$emit('click', e);
        },
      },
      scopedSlots: { item: this.renderItem },
      ref: 'autocomplete',
    });
  },
};
</script>
