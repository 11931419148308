import Vue from 'vue';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { ExtraErrorData } from '@sentry/integrations';
import { debug, generateDomElementDescription } from '@/utils/helpers';
import XHRError from '@/models/xhrError';
import { clearString } from '@/utils/convert';
import env from './env';

const IGNORE_ERRORS = [
  // crash connection (including VPN) or failed server
  'Network Error',
  // many page reloads
  'Request aborted',
  // reloading page or move to next page not waiting loading current page
  'Fetch is aborted',
  'Non-Error exception captured',
  "NotAllowedError: play() failed because the user didn't interact with the document first",
];

const snLog = debug('SENTRY', '#776589');

if (env.get('VUE_APP_SENTRY_DSN')) {
  const tunnel = String(env.get('VUE_APP_SENTRY_TUNNEL'));

  Sentry.init({
    Vue,
    dsn: String(env.get('VUE_APP_SENTRY_DSN')),
    ...(tunnel && { tunnel }),
    environment: String(env.get('VUE_APP_ENVIRONMENT') || env.get('NODE_ENV')),
    release: String(env.get('VUE_APP_VERSION') || ''),
    integrations: [
      new ExtraErrorData({ depth: 20 }),
      new Integrations.BrowserTracing(),
    ],
    ignoreErrors: IGNORE_ERRORS,
    normalizeDepth: 11,
    tracesSampleRate: 1.0,
    attachProps: true,
    logErrors: true,
    beforeBreadcrumb,
    beforeSend,
  });

  snLog('Enabled');
} else {
  snLog('Disabled');
}

/**
 * Обработка сообщений попадаемых в консоль
 */
function beforeBreadcrumb(
  breadcrumb: Sentry.Breadcrumb,
  hint?: Sentry.BreadcrumbHint,
): Sentry.Breadcrumb | null {
  if (breadcrumb.category === 'ui.click')
    return handleUiClickBreadcrumb(breadcrumb, hint?.event);
  else if (breadcrumb.category === 'console')
    return handleConsoleBreadcrumb(breadcrumb);
  return breadcrumb;
}

/**
 * Обработка данных перед отправкой на сервер
 */
function beforeSend(event: Sentry.Event): Sentry.Event {
  // add full url to option headers
  if (
    event.request?.headers &&
    !event.request?.headers?.url &&
    event.request?.url
  )
    event.request.headers.url = event.request?.url;

  let data = event?.contexts?.request?.data;
  if (typeof data === 'string')
    try {
      data = JSON.parse(data);
      sliceString(data); // slicing off too huge text chunks like some signatures
      if (event.contexts) event.contexts.request.data = JSON.stringify(data);
    } catch (e) {}

  return event;
}

/**
 * Формирование сообщения после клика пользователя по кнопке
 */
function handleUiClickBreadcrumb(breadcrumb: Sentry.Breadcrumb, event: any) {
  const closestButton = event.target.closest('button');

  // Если пользователь кликнул не по кнопке, возвращаем "родной" объект
  if (!closestButton) return breadcrumb;
  const elementDescription = generateDomElementDescription(closestButton);
  const mark = closestButton.dataset.sentryMark; // get data-sentry-mark attr
  const text = clearString(closestButton.innerText);

  const message = [
    mark ? `Mark: "${mark}"` : null,
    text ? `Inner text: "${text}"` : null,
    'Button target: ' + elementDescription,
    closestButton !== event.target
      ? 'Real target: ' + breadcrumb.message
      : null,
  ]
    .filter(item => item)
    .join('\n');

  return {
    ...breadcrumb,
    category: 'ui.button',
    message,
  };
}

/**
 * Формирование сообщений из консоли
 */
function handleConsoleBreadcrumb(
  breadcrumb: Sentry.Breadcrumb,
): Sentry.Breadcrumb | null {
  const { message } = breadcrumb;
  if (message && /(vue-devtools)|(\[statistics\])/.test(message)) return null;

  // for debugger console lines
  if (message && /^%c\[.*/.test(message)) {
    const tag = message.split(/[[\]]/)[1];
    if (!tag) return null;
  }

  const args =
    message && breadcrumb.data?.arguments
      ? breadcrumb.data?.arguments.slice(2)
      : breadcrumb.data?.arguments;

  return {
    ...breadcrumb,
    message: (message || '')
      .replace(/^%c.*;/, '')
      .replace('[object Object]', '')
      .trim(),
    data: {
      ...breadcrumb.data,
      logger: undefined,
      // remove duplicate text value from args
      arguments: typeof args[0] === 'string' ? args.slice(1) : args,
    },
  };
}

/**
 * Обрезать строки выходящие за разумные границы
 */
function sliceString(obj: any) {
  const maxLength = 200;
  Object.keys(obj).forEach(key => {
    if (obj[key] !== null && typeof obj[key] === 'object')
      sliceString(obj[key]);
    else if (typeof obj[key] === 'string' && obj[key].length > maxLength)
      obj[key] = obj[key].slice(0, maxLength) + '...';
  });
}

/**
 * Отправить в Sentry ошибку XHR запроса
 * @param {AxiosError} err Error
 */
export function reportXhrError(err: AxiosError) {
  console.error('err', err);
  const { config, response, message } = err;
  const method = config.method?.toUpperCase() || '';

  // Ignore client errors
  const isIgnored = IGNORE_ERRORS.some(el => message.indexOf(el) > -1);
  if (
    isIgnored ||
    // token is expired
    (response?.status && [401, 444].includes(response?.status))
  )
    return;

  return Sentry.captureException(new XHRError(err), scope => {
    scope.setContext('request', {
      'x-trace-id': config?.headers && config?.headers['x-trace-id'],
      url: `${config?.baseURL || ''}${config?.url || ''}`,
      method,
      data: ['GET', 'DELETE'].includes(method) ? null : config?.data,
    });
    scope.setContext('response', response?.data);

    return scope;
  });
}

export { Sentry };
export default Sentry;
