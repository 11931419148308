<template>
  <span>
    <v-menu v-if="formatsFiltered.length > 1">
      <template #activator="{ on: menu }">
        <v-btn color="primary" class="px-12" v-on="{ ...menu }">
          Сформировать
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="format in formatsFiltered"
          :key="format"
          @click="$emit('click', format)"
        >
          <v-list-item-title>{{ actions(format) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      v-else
      color="primary"
      class="px-12"
      @click="$emit('click', formats[0])"
    >
      Сформировать отчет
    </v-btn>
  </span>
</template>

<script>
const ACTIONS = {
  csv: 'Сформировать отчет',
  xlsx: 'Сформировать отчет .xlsx',
  pdf: 'Сформировать отчет .pdf',
  html: 'Сформировать отчет .html',
  json: 'Построить график',
};

export default {
  props: {
    formats: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  computed: {
    actions() {
      return format => ACTIONS[format] || format;
    },

    formatsFiltered() {
      const allowedFormats = ['xlsx', 'pdf', 'csv', 'json'];
      return allowedFormats.filter(format => this.formats.includes(format));
    },
  },
};
</script>
