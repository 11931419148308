<template>
  <PageLayout
    class="px-13"
    back-tooltip="Вернуться к списку подписок"
    back-route="notifications:subscriptions"
    :title="title"
    :loading="isLoading"
  >
    <v-col v-if="!isCreate" cols="12" class="d-flex flex-column my-2">
      <Card dense hide-title>
        <v-tabs v-model="currentTab" show-arrows>
          <v-tab href="#general">Основное</v-tab>
          <v-tab v-if="$can('BLM_ER')" href="#logs">Лог изменений</v-tab>
        </v-tabs>
      </Card>
    </v-col>

    <div v-if="currentTab === 'general'">
      <v-card class="mt-4 pt-4 d-flex mx-3">
        <v-card-text>
          <div class="d-flex">
            <v-text-field
              v-model="data.name"
              outlined
              dense
              label="Название"
              :readonly="!hasAction"
              :error-messages="getValidationErrors('data.name')"
              @blur="validateField('data.name')"
            />
            <Select
              v-model="data.profile"
              label="Профиль"
              item-value="key"
              :items="profiles"
              dense
              class="ml-2"
              :readonly="!hasAction"
              :error-messages="getValidationErrors('data.profile')"
              @blur="validateField('data.profile')"
            />
            <OrganizationSelect
              v-model="data.orgId"
              dense
              title="Организация владелец"
              class="ml-2"
              :readonly="!hasAction"
              :clearable="hasAction"
              :error-messages="getValidationErrors('data.orgId')"
              @blur="validateField('data.orgId')"
            />
            <div class="d-flex ml-2" style="margin-top: 2px">
              <ListActionBtn
                label="Сохранить"
                icon="fa-save"
                :icon-size="14"
                color="success"
                class="mr-1"
                :loading="$wait('submit')"
                :disabled="!hasAction || disableActions"
                @click="submit"
              />
              <ListActionBtn
                label="Удалить"
                icon="fa-trash-alt"
                :icon-size="14"
                color="error"
                :disabled="!$can('NF_SD') || disableActions || isCreate"
                @click="handleRemove"
              />
            </div>
          </div>

          <v-card-title class="px-0 pt-1">
            Фильтры осмотров
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon size="18" class="ml-3" v-on="on">
                  fa-question-circle
                </v-icon>
              </template>
              <span>
                Добавьте группы работников или группы точек, по которым хотите
                получать уведомления.
                <br />
                Выбранные вами значения вводятся по правилу "ИЛИ".
              </span>
            </v-tooltip>
          </v-card-title>

          <InspectionFilters
            ref="inspectionFilters"
            :value="data"
            field="filters"
            add-button-text="Добавить фильтр"
            :readonly="!hasAction"
            @input="val => (data.filters = val.filters)"
          />

          <v-divider class="mt-5" />

          <v-card-title class="px-0 pt-3">Получатели</v-card-title>

          <Recipients
            ref="recipients"
            :value="data"
            :readonly="!hasAction"
            @input="val => (data.recipients = val.recipients)"
          />
        </v-card-text>
      </v-card>
    </div>

    <!-- TABS -->
    <v-col v-else cols="12" class="d-flex flex-column">
      <TabLogs v-if="currentTab === 'logs'" :id="singleItem.id" />
    </v-col>
  </PageLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import validationMixin from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';
import queryTabSave from '@/utils/mixins/queryTabSave';

import PageLayout from '@/components/layouts/PageLayout';
import InspectionFilters from '@/components/InspectionFilters';
import Recipients from '../components/Recipients';
import Select from '@/components/controls/Select.vue';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect';
import Card from '@/components/ui/Card';
import ListActionBtn from '@/components/controls/buttons/ListActionBtn';

import TabLogs from './item-tabs/logs';

export default {
  components: {
    PageLayout,
    Select,
    InspectionFilters,
    Recipients,
    OrganizationSelect,
    Card,
    TabLogs,
    ListActionBtn,
  },
  mixins: [validationMixin, waitable, routeGuardMixin, queryTabSave()],

  data: () => ({
    currentTab: 'general',
    data: {
      name: '',
      profile: '',
      filters: [
        {
          parent: {},
          employeeGroups: null,
          hostGroups: null,
          points: null,
        },
      ],
      recipients: [{ accountId: null, channels: null }],
    },
  }),

  validations() {
    return {
      data: {
        name: { required },
        profile: { required },
        orgId: { required },
        recipients: { required },
      },
    };
  },

  computed: {
    ...mapGetters('NOTIFICATIONS/SUBSCRIPTIONS_CARD', ['profiles']),
    ...mapGetters('AUTH_PREVIEWS', ['cachedAccs']),
    ...mapGetters('AUTH', ['accountId']),

    title() {
      if (this.isCreate) return 'Создание подписки';
      return `Подписка ${
        this.singleItem ? `"${this.singleItem.name}"` : '...'
      }`;
    },

    disableActions() {
      return this.$wait('createSingleItem') || this.$wait('changeSingleItem');
    },

    isCreate() {
      return this.$route.name.includes('create');
    },

    isLoading() {
      if (this.isCreate) return false;
      return (
        this.$wait('fetchingAccountPreview') ||
        this.singleItem.recipients.length > Object.keys(this.cachedAccs).length
      );
    },

    hasAction() {
      if (this.isCreate) return this.$can('NF_SC');
      else return this.$can('NF_SU');
    },
  },

  async created() {
    this.clearCachedAccs();

    // fetch profiles always anew (in case new were added)
    this.fetchProfiles();

    // HACK: fetch current user account preview for 'add me as recipient' button
    // in 'components/Recipients' component to work properly, since we need
    // account preview to be loaded in when channel type value will be altered.
    // Otherwise channels select will but out visually and won't update.
    // It IS a 'hack' since we already have current user object in auth store,
    // which is 'currentAccount' and we could merge it to 'cachedAccs' in
    // auth-previews, but i'd rather not do that ugly stuff.
    await this.$loadingNotify(
      this.fetchAccountPreviewsBy([this.accountId]),
      'fetchingSelfPreview',
      'Произошла ошибка загрузки превью вашего аккаунта',
    );

    if (!this.isCreate) {
      this.data = { ...this.singleItem };

      // fetch recipients info to be able to display type selects properly
      // in Recipients.vue
      await this.$loadingNotify(
        this.fetchAccountPreviewsBy(
          this.singleItem.recipients.map(recipient => recipient.accountId),
        ),
        'fetchingAccountPreview',
        'Произошла ошибка загрузки превью аккаунтов',
      );

      // delete empty row of groups if there r alredy some (just for convenience)
      if (this.data.employeeGroupIds?.length || this.data.hostGroupIds?.length)
        this.data.filters.splice(0, 1);

      // delete empty row of groups if there r alredy some (just for convenience)
      if (!this.data.recipients?.length)
        // this.data.recipients = [{ accountId: null, channels: null }];
        this.data.recipients.splice(0, 1);
    }
  },

  methods: {
    ...mapActions('NOTIFICATIONS/SUBSCRIPTIONS_CARD', [
      'fetchProfiles',
      'deleteSingleItem',
    ]),
    ...mapActions('AUTH_PREVIEWS', [
      'clearCachedAccs',
      'fetchAccountPreviewsBy',
    ]),

    async submit() {
      const filtersAreValid =
        this.$refs.inspectionFilters.validate() &&
        this.$refs.recipients.validate();
      if (!this.validate() || !filtersAreValid) return;

      const data = { ...this.data };

      const actionName = this.isCreate
        ? 'NOTIFICATIONS/SUBSCRIPTIONS_CARD/createSingleItem'
        : 'NOTIFICATIONS/SUBSCRIPTIONS_CARD/updateSingleItem';

      await this.$loadingNotify(
        this.$store.dispatch(actionName, data).then(res => {
          this.isCreate &&
            this.$router.push({
              name: 'notifications:subscriptions:item',
              params: {
                id: res.id,
              },
            });
        }),
        'submit',
        `Произошла ошибка ${this.isCreate ? 'создания' : 'изменения'} подписки`,
        `Подписка успешно ${this.isCreate ? 'добавлена' : 'изменена'}`,
      ).catch(err => this.catchServerErrors(err, 'data'));
    },

    handleRemove() {
      this.$openModal('prompt', {
        yes: 'Подтвердить',
        no: 'Отменить',
        title: 'Удалить подписку?',
        description: 'Вы точно хотите удалить подписку?',
        messages: {
          successfulAction: 'Подписка успешно удалена',
          errorAction: 'Ошибка при удалении подписки',
        },
        onSubmit: () =>
          this.deleteSingleItem(this.singleItem.id).then(_ =>
            this.$router.push({ name: 'notifications:subscriptions' }),
          ),
      });
    },
  },
};
</script>
