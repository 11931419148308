import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import RouteGuard from '@/components/RouteGuard';
// for this simple page i decided not to split store in 2, just for the sake
// of simplicity, less file bloat and my lazyness ^^
import OUTGOING from './store/list';
import OUTGOING_ITEM from './store/item';

export default {
  namespaced: true,
  store: { OUTGOING, OUTGOING_ITEM },
  routes: [
    {
      path: 'outgoing',
      name: 'outgoing',
      component: () => import('./views/list.vue'),
      meta: {
        title: 'Список исходящих документов',
        action: 'ODC_R',
      },
    },
    {
      path: 'outgoing/:id',
      name: 'outgoing:item',
      component: RouteGuard({
        storeModule: 'DOCUMENTS/OUTGOING_ITEM',
        component: () => import('./views/item.vue'),
        useCache: false,
      }),
      meta: {
        title: 'Карточка исходящего документа',
        action: 'ODC_RD',
      },
    },
  ],
  menu: [{ title: 'Исходящие', action: 'ODC_R', name: 'outgoing' }],
  modals: [
    { name: 'showOutgoing', component: () => import('./Show.modal.vue') },
  ],
} as ModuleDefinition;
