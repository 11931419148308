<template>
  <List
    :available-actions="{
      showAction: false,
      createAction: $can('GRP_C'),
    }"
    :can-show-scoped-actions="$can('GRP_U') || $can('GP_AA') || $can('GRP_D')"
    @item:create="openForm"
  >
    <template #item.actions.append="{ item }">
      <v-tooltip v-if="$can('GRP_U')" left>
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" icon v-on="on" @click="openForm({ item })">
            <v-icon small>fa-edit</v-icon>
          </v-btn>
        </template>
        <span>Редактировать</span>
      </v-tooltip>

      <v-tooltip v-if="$can('GP_AA')" left>
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" icon v-on="on" @click="openEditActions(item)">
            <v-icon small>mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
        <span>Изменить разрешения</span>
      </v-tooltip>

      <v-tooltip v-if="$can('GRP_D')" left>
        <template #activator="{ attrs }">
          <v-btn v-bind="attrs" icon color="red" @click="openDeleteModal(item)">
            <v-icon small>fa-trash-alt</v-icon>
          </v-btn>
        </template>
        <span>Удалить</span>
      </v-tooltip>
    </template>
  </List>
</template>

<script>
import authApi from '@/api/services/auth';
import waitable from '@/utils/mixins/waitable';
import ListFactory from '@/components/crud/ListFactory';

const messages = { resourceName: 'групп действий' };

const entity = [
  { key: 'key', label: 'Key' },
  { key: 'name', label: 'Название' },
  { key: 'description', label: 'Описание' },
];

const List = ListFactory({ storeModule: 'ADMIN/GROUPS', messages, entity });

export default {
  components: { List },
  mixins: [waitable],

  computed: {
    groupActions() {
      return this.$store.getters['ADMIN/GROUPS/actions'];
    },
  },

  created() {
    this.$can('ACNS_R') && this.$store.dispatch('ADMIN/GROUPS/fetchActions');
  },

  methods: {
    async fetchItemDetails(item) {
      return authApi.groupsGet(item.key);
    },

    openForm({ item }) {
      this.$openModal('admin/groupForm', {
        value: item || Object.fromEntries(entity.map(field => [field.key])),
      });
    },

    openDeleteModal(item) {
      this.$openModal(() => import('@/components/crud/DeleteModal.vue'), {
        onDelete: data =>
          this.$store.dispatch('ADMIN/GROUPS/deleteListItem', data),
        item,
        messages: messages.delete,
      });
    },

    async openEditActions(item) {
      const data = await this.fetchItemDetails(item);
      const oldActions = data.actions;

      this.$openModal('selectModal', {
        value: oldActions,
        items: this.groupActions.map(el => ({
          ...el,
          name: `${el.name}(${el.tag})`,
        })),
        multiple: true,
        size: 'large',
        onSubmit: newActions =>
          this.$loadingNotify(
            this.$store.dispatch('ADMIN/GROUPS/bindActionsInGroup', {
              key: item.key,
              newList: newActions,
            }),
            'bindActionsInGroup',
            'Произошла ошибка изменения разрешений группы',
            'Разрешения группы успешно обновлены',
          ),
        messages: {
          title: `Разрешения группы ${item.name}`,
          warning: `Разрешения группы ${item.name} отсутствуют`,
        },
      });
    },
  },
};
</script>
