import structApi from '@/api/services/structures';
import { createModule } from '@/utils/vuex/createModule';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { filters } from '../entity';

const actions = {
  changeCounter(
    { commit, rootGetters },
    { itemIds, counterField, addCount = 0 },
  ) {
    if (!itemIds || !itemIds.length || !counterField) return;
    commit('changeCounter', { itemIds, counterField, addCount });
    const groupSingleItem =
      rootGetters['STRUCTURES/ORGANIZATION_GROUPS_ITEM/singleItem'];
    if (groupSingleItem && itemIds.includes(groupSingleItem.id)) {
      groupSingleItem.allMembersCount += addCount;
      groupSingleItem.membersCount += addCount;
    }
  },
};

export default createModule(
  crudListFactory({
    fetchMethod: structApi.organizationGroupList,
    createMethod: structApi.organizationGroupCreate,
    deleteMethod: structApi.organizationGroupDelete,
    filters,
  }),
  { actions },
);
