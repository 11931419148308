import authApi from '@/api/services/auth';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { createModule } from '@/utils/vuex/createModule';
import { filters } from '../entity';

const state = {};

const getters = {
  listItems: state => state.listItems || [],
  role: (state, _, __, rootGetters) => (key, field) => {
    const role = rootGetters['REGISTRY/accountRolesApplication'].find(
      role => role.key === key,
    );
    if (!role) return 'key: ' + key; // is it the correct thing to return? unknown for now
    if (!field) return role;
    return role[field];
  },
};

const mutations = {};

const actions = {
  async createItem({ commit }, payload) {
    const account = await authApi.appsCreate(payload);
    commit('listAddItem', account);
  },
};

export default createModule(
  crudListFactory({
    fetchMethod: authApi.appsList,
    createMethod: authApi.appsCreate,
    updateMethod: authApi.appsUpdate,
    deleteMethod: authApi.accountDelete, // it's not error, it's pretty backend api
    filters,
  }),
  { state, getters, mutations, actions },
);
