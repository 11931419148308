<template>
  <AsyncWrapper :handler="() => getRating(item.id)">
    <v-list-item style="min-width: 230px">
      <v-list-item-content>
        <div class="d-flex align-center justify-space-between pr-3">
          <div class="mr-3 d-flex align-center">
            <span :style="getRatingColor()" class="text-h6 mr-3">
              {{ rating || '-' }}
            </span>
            <span class="mr-3">Рейтинг работника</span>

            <v-tooltip bottom max-width="350">
              <template #activator="{ on, attrs }">
                <v-icon small v-bind="attrs" v-on="on"> mdi-help </v-icon>
              </template>
              <span>
                Цветовая ремарка рейтинга работника:<br /><br />
                <Round type="green" size="0.7rem" />
                &nbsp;Зеленый - хорошо пройденные осмотры без критических причин
                недопуска (алкоголь, прохождение посторонним лицом);<br />
                <Round type="yellow" size="0.7rem" />
                &nbsp;Желтый - есть осмотры с административными причинами
                недопуска;<br />
                <Round type="red" size="0.7rem" />
                &nbsp;Красный - есть хотя бы один критический недопуск
              </span>
            </v-tooltip>
          </div>

          <v-tooltip v-if="$can('PRT')" bottom class="justify-right">
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" icon v-on="on" @click="putRating(item.id)">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Пересчитать рейтинг</span>
          </v-tooltip>
          <v-tooltip
            v-if="rating != 0 && $can('DRT')"
            bottom
            class="justify-right"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                icon
                v-on="on"
                @click="deleteRating(item.id)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Удалить рейтинг</span>
          </v-tooltip>
        </div>
      </v-list-item-content>
    </v-list-item>
  </AsyncWrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { COLORS } from '@/utils/constants';

import AsyncWrapper from '@/components/AsyncWrapper.vue';
import Round from '@/components/Round.vue';

export default {
  components: { AsyncWrapper, Round },
  props: {
    item: { type: Object, default: () => ({}) },
  },

  computed: {
    ...mapGetters('EMPLOYEE', ['rating']),
  },

  created() {
    this.$can('PRT') && this.putRating(this.item.id);
  },
  methods: {
    ...mapActions('EMPLOYEE', ['getRating', 'putRating', 'deleteRating']),
    getRatingColor() {
      if (this.rating === 0) return ``;
      else if (this.rating < 4.5) return `color: rgb(${COLORS.red})`;
      else if (this.rating <= 8.5) return `color: rgb(${COLORS.yellow})`;
      else return `color: rgb(${COLORS.green})`;
    },
  },
};
</script>
