<script>
import { VDivider } from 'vuetify/lib';
import Spinner from './Spinner.vue';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    dividers: Boolean,
    loading: Boolean,
    fullWidth: Boolean,
  },
  methods: {
    renderSpinner() {
      if (this.$scopedSlots.loading) return this.$scopedSlots.loading();

      return <Spinner />;
    },
    handleScroll(event) {
      this.$emit('scroll', event);

      const maxScrolledHeight =
        event.target.scrollHeight - event.target.offsetHeight;

      if (maxScrolledHeight - 10 < event.target.scrollTop)
        this.$emit('scroll:end', event);
    },
  },
  render() {
    const content = this.items.map((item, index) => {
      const slotContent = this.$scopedSlots.item({ item });
      return this.dividers && index < this.items.length - 1
        ? [slotContent, <VDivider />]
        : slotContent;
    });

    return (
      <div
        class="scroller"
        style={this.fullWidth && 'width: 100%'}
        onScroll={this.handleScroll}
      >
        {this.$scopedSlots.prepend && this.$scopedSlots.prepend()}
        {content}
        {this.loading && this.renderSpinner()}
        {this.$scopedSlots.append && this.$scopedSlots.append()}
      </div>
    );
  },
};
</script>

<style scoped>
.scroller {
  height: 100%;
  overflow-x: auto;
  z-index: 0;
}
</style>
