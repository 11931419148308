import { filters } from '@/modules/structures/hosts/entity';
import { createModule } from '@/utils/vuex/createModule';
import listSimpleFactory from '@/utils/vuex/listSimpleFactory';
import structApi from '@/api/services/structures';

const getters = {
  listQuery: (state, getters, _, rootGetters) => ({
    ...getters.listFilters,
    ...getters.listOrder,
    search: state.listQuery.search || null,
    releasePointIds: `${rootGetters['STRUCTURES/POINTS_ITEM/singleItem'].id}`,
  }),
};

export default createModule(
  listSimpleFactory({
    fetchMethod: structApi.hostList,
    createMethod: structApi.hostCreate,
    filters,
    linkedList: 'STRUCTURES/HOSTS',
  }),
  { getters },
);
