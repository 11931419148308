<template>
  <ModernModalLayout close-label="Отмена">
    <div>
      Подтвердите, что:
      <ul>
        <li>Номер лицензии совпадает с маркировкой МИ на ПАК</li>
        <li>Форм-фактор и вариант исполнения указаны верно</li>
        <li>Дата поверки указана верно</li>
      </ul>
      <v-checkbox
        v-model="isConfirm"
        :error="isError"
        label="Подтверждаю, что данные ПАК указаны верно"
      />
    </div>
    <template #actions:append>
      <v-btn class="px-10" depressed color="primary" @click="submit">
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import validation from '@/utils/validation';

export default {
  components: { ModernModalLayout },
  mixins: [validation],

  props: {
    onSubmit: { type: Function, required: true },
  },

  data() {
    return {
      isConfirm: false,
      isError: false,
    };
  },

  validations() {
    return {
      isConfirm: { required: () => this.isConfirm },
    };
  },

  methods: {
    async submit() {
      this.isError = !this.validate();
      if (this.isError) return;
      await this.onSubmit();
      this.$emit('close');
    },
  },
};
</script>
