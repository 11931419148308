<template>
  <v-layout>
    <div style="max-width: 40%; min-width: 40%">
      <v-select
        label="Точка отсчета"
        class="pb-2"
        outlined
        hide-details="auto"
        :menu-props="{ offsetY: true }"
        dense
        :items="referencePoints"
        :value="innerValue.start"
        :error-messages="getValidationErrors('innerValue.start')"
        @blur="validateField('innerValue.start')"
        @input="$emit('input', { ...innerValue, start: $event })"
      />
    </div>
    <div style="max-width: 30%; min-width: 30%">
      <v-text-field
        label="Смещение"
        type="number"
        dense
        class="ml-4"
        outlined
        hide-details="auto"
        :value="innerValue.offset.value"
        :error-messages="getValidationErrors('innerValue.offset.value')"
        @blur="validateField('innerValue.offset.value')"
        @input="
          $emit('input', {
            ...innerValue,
            offset: { value: +$event, units: innerValue.offset.units },
          })
        "
      />
    </div>
    <div style="max-width: 30%; min-width: 30%">
      <v-select
        label="Единица смещения"
        class="ml-4"
        outlined
        hide-details="auto"
        :menu-props="{ offsetY: true }"
        dense
        :items="offsetUnits"
        :value="innerValue.offset.units"
        :error-messages="getValidationErrors('innerValue.offset.units')"
        @blur="validateField('innerValue.offset.units')"
        @input="
          $emit('input', {
            ...innerValue,
            offset: { units: $event, value: innerValue.offset.value },
          })
        "
      />
    </div>
  </v-layout>
</template>

<script>
import {
  RELATIVE_REFERENCE_POINTS,
  RELATIVE_OFFSET_UNITS,
} from '@/modules/scheduler/constants';
import { required } from 'vuelidate/lib/validators';
import validation from '@/utils/validation';

export default {
  mixins: [validation],

  props: {
    value: {
      type: [Object, String],
      default: undefined,
    },
  },
  validations: {
    innerValue: {
      start: { required },
      offset: { value: { required }, units: { required } },
    },
  },
  computed: {
    referencePoints() {
      return RELATIVE_REFERENCE_POINTS;
    },
    offsetUnits() {
      return RELATIVE_OFFSET_UNITS;
    },
    innerValue() {
      return !this.value || typeof this.value === 'string'
        ? { start: undefined, offset: {} }
        : this.value;
    },
  },
};
</script>
