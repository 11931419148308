<template>
  <v-list-item
    color="primary"
    :input-value="document.id === value"
    @click="handleChange"
  >
    <v-list-item-content class="text-body-2">
      <b class="mb-1">
        {{ document.name }}
      </b>
      <span>{{ issuedAt }} {{ dueDate }}</span>
      <div class="mt-2">
        <ColoredText
          v-if="documentStatus.text"
          class="float-left"
          v-bind="documentStatus"
        />
        <span
          v-if="documentStatus.text && document.diagnosis"
          style="white-space: pre"
        >
          |
        </span>
        <span v-if="document.diagnosis" style="line-height: 20px">
          {{ document.diagnosis }}
        </span>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { date } from '@/utils/convert';
import { DOCUMENT_STATUSES } from '@/modules/documents/constants';
import ColoredText from '../incoming/components/ColoredText';

export default {
  components: { ColoredText },
  props: {
    value: {
      type: Number,
      default: null,
    },
    document: {
      type: Object,
      required: true,
    },
  },
  computed: {
    issuedAt() {
      return date(this.document.issuedAt);
    },
    dueDate() {
      const dueDate = this.document.dueDate;
      return dueDate ? ` – ${date(dueDate)}` : '';
    },

    documentStatus() {
      const status = DOCUMENT_STATUSES.find(
        ({ value }) => value === this.document.status,
      );
      return {
        text: status?.title || null,
        color: status?.color || undefined,
      };
    },
  },

  methods: {
    handleChange() {
      if (this.value === this.document.id) return;
      this.$emit('input', this.document.id);
    },
  },
};
</script>
