<template>
  <ModernModalLayout size="large" :title="title">
    <v-form :disabled="$wait('fetchDefaultValues')">
      <v-container>
        <v-row>
          <template v-for="field in boundaryFields">
            <v-col
              :key="field.name + 'field'"
              class="d-flex flex-column"
              cols="4"
            >
              <div class="mb-3 text-body-2 flex-grow-1">{{ field.name }}</div>
              <div class="d-flex">
                <v-text-field
                  v-model.number="field.data().lower"
                  label="От"
                  class="mr-2"
                  type="number"
                  dense
                  outlined
                  :error-messages="
                    getValidationErrors(field.validation + '.lower')
                  "
                  @blur="validateField(field.validation + '.lower')"
                />
                <v-text-field
                  v-model.number="field.data().upper"
                  label="До"
                  dense
                  outlined
                  type="number"
                  :error-messages="
                    getValidationErrors(field.validation + '.upper')
                  "
                  @blur="validateField(field.validation + '.upper')"
                />
              </div>
            </v-col>
            <v-divider
              v-if="field.dividerAfter"
              :key="field.name + 'divider'"
              vertical
            />
          </template>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox
              v-model="data.values.checkTemperature"
              class="mt-4"
              label="Пропускать термометрию"
              hide-details="auto"
            />
          </v-col>
          <v-col>
            <v-textarea
              v-model="data.comment"
              class="mt-4"
              rows="3"
              label="Комментарий"
              hide-details="auto"
              :error-messages="getValidationErrors('data.comment')"
              @blur="validateField('data.comment')"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <template #actions:append>
      <v-btn
        class="px-16"
        depressed
        color="primary"
        :loading="$wait('handleSetBoundaries')"
        @click="submit"
      >
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import validationMixin from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import medrecApi from '@/api/services/medrec';

import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import {
  maxLength,
  minLength,
  maxValue,
  minValue,
  required,
} from 'vuelidate/lib/validators';
import _cloneDeep from 'lodash/cloneDeep';

const EMPTY_VALUES = {
  pressure: {
    systolic: { lower: null, upper: null },
    diastolic: { lower: null, upper: null },
  },
  pulse: { lower: null, upper: null },
  checkTemperature: false,
};

export default {
  name: 'OrganizationBoundaries',
  components: { ModernModalLayout },
  mixins: [validationMixin, waitable],

  validations() {
    return {
      data: {
        values: {
          pressure: {
            systolic: {
              lower: {
                required,
                minValue: minValue(60),
                maxValue: maxValue(220),
              },
              upper: {
                required,
                minValue: minValue(60),
                maxValue: maxValue(220),
              },
            },
            diastolic: {
              lower: {
                required,
                minValue: minValue(40),
                maxValue: maxValue(150),
              },
              upper: {
                required,
                minValue: minValue(40),
                maxValue: maxValue(150),
              },
            },
          },
          pulse: {
            lower: {
              required,
              minValue: minValue(30),
              maxValue: maxValue(150),
            },
            upper: {
              required,
              minValue: minValue(30),
              maxValue: maxValue(150),
            },
          },
        },
        comment: {
          required,
          maxSymbolsLength: maxLength(1000),
          minSymbolsLength: minLength(5),
        },
      },
    };
  },

  data() {
    return {
      data: {
        values: EMPTY_VALUES,
        comment: null,
      },
      defaultValues: EMPTY_VALUES,
    };
  },

  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', ['boundaries']),

    isEdit() {
      return !!this.boundaries;
    },

    title() {
      const actionName = !this.isEdit ? 'Установить' : 'Редактировать';
      return `${actionName} границы медицинских показателей`;
    },

    boundaryFields() {
      return [
        {
          name: 'Систолическое артериальное давление',
          data: () => this.data.values.pressure?.systolic,
          validation: 'data.values.pressure.systolic',
          dividerAfter: true,
        },
        {
          name: 'Диастолическое артериальное давление',
          data: () => this.data.values.pressure?.diastolic,
          validation: 'data.values.pressure.diastolic',
          dividerAfter: true,
        },
        {
          name: 'Пульс',
          data: () => this.data.values.pulse,
          validation: 'data.values.pulse',
        },
      ];
    },
  },

  async created() {
    await this.$loadingNotify(
      this.fetchDefaultValues(),
      'fetchDefaultValues',
      'Произошла ошибка получения границ медицинских показателей по умолчанию',
    );
    this.data.values = _cloneDeep(
      this.boundaries?.values || this.defaultValues,
    );
    this.data.comment = this.boundaries?.comment || null;
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', [
      'setBoundaries',
      'updateBoundaries',
    ]),

    async fetchDefaultValues() {
      if (this.$can('GETDBND')) {
        this.defaultValues = await medrecApi
          .getDefaultBoundaries()
          .then(result => result?.data?.values || EMPTY_VALUES);
      }
    },

    // Меняет местами нижнее и верхнее значение, если нижнее больше, чем верхнее
    makeCorrectLimits(data) {
      if (Object.hasOwn(data, 'lower') && Object.hasOwn(data, 'upper')) {
        const { lower, upper } = data;
        if (lower > upper) {
          data.lower = upper;
          data.upper = lower;
        }
        return;
      }
      if (!!data && data.constructor === Object) {
        Object.values(data).forEach(this.makeCorrectLimits);
      }
    },

    async submit() {
      if (!this.validate()) return;

      // If user is STUPID, has 47 chromosomes and enters LOWER value higher
      // then HIGHER value - we do not bother telling him about that, he won't
      // understand it.. Instead, we just change values by ourselfes.
      const correctData = _cloneDeep(this.data);
      this.makeCorrectLimits(correctData);

      const actionName = !this.isEdit ? 'setBoundaries' : 'updateBoundaries';
      await this.$loadingNotify(
        this[actionName](correctData),
        'handleSetBoundaries',
        `Произошла ошибка ${
          !this.isEdit ? 'установки' : 'редактирования'
        } границ`,
        `Границы успешно ${!this.isEdit ? 'установлены' : 'отредактированы'}`,
      );

      this.$emit('close');
    },
  },
};
</script>
