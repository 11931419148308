<template>
  <Card v-if="actions" title="Настройки блокировок" dense>
    <v-simple-table dense>
      <colgroup>
        <col width="35%" />
        <col width="45%" />
        <col width="20%" align="right" />
      </colgroup>
      <tbody>
        <tr v-if="$can('EMP_FR')">
          <td class="py-3">Режим труда и отдыха</td>
          <td>
            <template v-if="$wait('getEmployeeFlagsIgnoreWorkRestBans')">
              <v-progress-circular
                indeterminate
                :size="20"
                :width="2"
                color="primary"
              />
            </template>
            <template v-else>
              <Round
                :type="workRestDisabled ? 'red' : 'green lighten-2'"
                size="10px"
                class="mr-2"
              />
              Блокировка {{ (workRestDisabled && 'не') || '' }} применяется
            </template>
          </td>
          <td>
            <CardActionBtn
              v-if="
                !$wait('getEmployeeFlagsIgnoreWorkRestBans') && $can('EMP_FRS')
              "
              :loading="$wait('setEmployeeFlagsIgnoreWorkRestBans')"
              :label="workRestDisabled ? 'Включить' : 'Отключить'"
              @click="() => promptWorkRestBans()"
            />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </Card>
</template>

<script>
import waitable from '@/utils/mixins/waitable';
import Card from '@/components/ui/Card';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';
import Round from '@/components/Round.vue';

export default {
  components: { Card, CardActionBtn, Round },
  mixins: [waitable],

  props: {
    employeeId: {
      type: Number,
      default: null,
    },
  },

  data: () => ({
    workRestDisabled: false,
  }),

  computed: {
    actions() {
      return this.$can('EMP_FR');
    },
  },

  mounted() {
    this.$can('EMP_FR') && this.fetchWorkRestBans();
  },

  methods: {
    async fetchWorkRestBans() {
      const request = this.$store
        .dispatch(
          'EMPLOYEE/getEmployeeFlagsIgnoreWorkRestBans',
          this.employeeId,
        )
        .then(res => (this.workRestDisabled = res.value));

      await this.$loading(request, 'getEmployeeFlagsIgnoreWorkRestBans');
    },

    async changeWorkRestBans(value) {
      const params = { employeeId: this.employeeId, value };
      const request = this.$store
        .dispatch('EMPLOYEE/setEmployeeFlagsIgnoreWorkRestBans', params)
        .then(res => (this.workRestDisabled = res.value));

      await this.$loadingNotify(
        request,
        'setEmployeeFlagsIgnoreWorkRestBans',
        'Не удалось изменить состояние флага',
      );
    },

    promptWorkRestBans() {
      const status = this.workRestDisabled;

      this.$openModal('prompt', {
        yes: 'Подтвердить',
        no: 'Отменить',
        title: `${status ? 'Включить' : 'Отключить'} блокировку?`,
        description: `Вы действительно хотите ${
          status ? 'включить' : 'отключить'
        } блокировку по "Режиму труда и отдыха" для работника?`,
        messages: {
          successfulAction: `Блокировка ${status ? 'включена' : 'отключена'}`,
          errorAction: `Ошибка при ${
            status ? 'включении' : 'отключении'
          } блокировки`,
        },
        onSubmit: () => this.changeWorkRestBans(!this.workRestDisabled),
      });
    },
  },
};
</script>
