var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('List',{attrs:{"available-actions":{
    showAction: _vm.$can('SJ_RD'),
    createAction: _vm.$can('SJ_C'),
  }},on:{"item:create":function($event){return _vm.$router.push({
      name: 'scheduler:jobs:create',
    })}},scopedSlots:_vm._u([{key:"filters",fn:function(query){return [_c('v-col',{attrs:{"cols":"12","sm":"4","md":"2"}},[_c('v-select',{attrs:{"label":"Статус задачи","items":_vm.statusItems,"item-value":"value","item-text":"name","hide-details":"","outlined":"","dense":"","menu-props":{ offsetY: true }},model:{value:(query.active),callback:function ($$v) {_vm.$set(query, "active", $$v)},expression:"query.active"}})],1)]}},{key:"table.item.activatedAt",fn:function(ref){
    var item = ref.item;
return [_c('Indicator',{attrs:{"active":!!item.activatedAt}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }