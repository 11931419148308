import Vue from 'vue';
import App from './App.vue';
import { router, activateGuardRoutes } from './router';
import store from './store';

import '@/plugins/modules';
import '@/plugins/browser';
import '@/plugins/can';
import '@/plugins/modal';
import '@/plugins/vue-fragment';

import notification from '@/plugins/notification';
import vueplyr from '@/plugins/plyr';
import vuelidate from '@/plugins/vuelidate';
import env from '@/plugins/env';
import vuetify from '@/plugins/vuetify';
import jsonViewer from '@/plugins/json-viewer';
import { debug } from '@/utils/helpers';

Vue.config.productionTip = false;

activateGuardRoutes();

store.dispatch('AUTH/restoreSession');

const gitLog = debug('GIT', '#E24328');

new Vue({
  created() {
    gitLog({
      version: env.get('VUE_APP_VERSION') || '',
    });

    this.$store.dispatch('initApplication');
  },

  router,
  store,
  vuelidate,
  notification,
  vuetify,
  vueplyr,
  jsonViewer,
  render: (h: any) => h(App),
} as any).$mount('#app');
