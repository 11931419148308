<template>
  <v-card :color="`grey ${themeIsDark ? 'darken' : 'lighten'}-3`">
    <v-layout class="pa-3" align-center>
      <div class="text-subtitle-1 font-weight-medium">{{ title }}</div>
      <v-spacer />

      <v-btn v-if="$listeners['edit']" icon @click="$emit('edit')">
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>

      <v-btn v-if="$listeners['remove']" icon @click="$emit('remove')">
        <v-icon>{{ $vuetify.icons.values.close }}</v-icon>
      </v-btn>
    </v-layout>

    <json-viewer
      v-if="description"
      :value="description"
      theme="jv-theme"
      expanded
      :expand-depth="Infinity"
      sort
      :show-array-index="false"
      copyable
    />
  </v-card>
</template>

<script>
import JsonViewer from '@/plugins/json-viewer';
import { mapGetters } from 'vuex';

export default {
  components: { JsonViewer },
  props: {
    title: { type: String, required: true },
    description: { type: Object, default: null },
  },
  computed: {
    ...mapGetters(['themeIsDark']),
  },
};
</script>
