import structApi from '@/api/services/structures';
import listSimpleFactory from '@/utils/vuex/listSimpleFactory';
import { createModule } from '@/utils/vuex/createModule';
import { filters } from '@/modules/structures/hosts-groups/entity';

const getters = {
  listQuery: (state, getters, _, rootGetters) => ({
    ...state.listQuery,
    search: state.listQuery.search || null,
    orgIds: `${rootGetters['STRUCTURES/ORGANIZATION_ITEM/singleItem'].id}`,
  }),
};

export default createModule(
  listSimpleFactory({
    fetchMethod: structApi.hostGroupList,
    createMethod: structApi.hostGroupCreate,
    filters,
    linkedList: 'STRUCTURES/HOST_GROUPS',
  }),
  { getters },
);
