import authApi from '@/api/services/auth';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { createModule } from '@/utils/vuex/createModule';

const state = {
  actions: null,
};

const getters = {
  actions: state => state.actions || [],
  /**
   * @description Backend sets up a sort for list only by name
   * @tag REDEFINED
   */
  listOrder: () => ({
    orderType: null,
    orderBy: null,
  }),
};

const mutations = {
  setActions(state, list = []) {
    state.actions = list;
  },
};

const actions = {
  async fetchActions({ getters, commit }) {
    if (getters.actions.length) return;
    const data = await authApi.fetchActions();
    commit('setActions', data);
  },

  bindActionsInGroup(_, { key, newList = [] } = {}) {
    return authApi.bindActionsToGroup(key, newList);
  },
};

export default createModule(
  crudListFactory({
    fetchMethod: authApi.groupsList,
    createMethod: authApi.groupsCreate,
    updateMethod: authApi.groupsUpdate,
    deleteMethod: authApi.groupsDelete,
  }),
  { state, getters, mutations, actions },
);
