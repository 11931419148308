<template>
  <ModernModalLayout
    size="medium"
    title="Редактирование дополнительных данных"
    close-label="Отмена"
    :watch-for="innerValue"
  >
    <v-form :disabled="$wait('submit')" class="pt-1">
      <template v-for="item in types">
        <MaskField
          v-if="item.type === 'inspectionTakePeriodMonth'"
          :key="item.type"
          :value="innerValue[item.type]"
          :label="item.name"
          :hint="item.description"
          :error-messages="getValidationErrors('innerValue.' + item.type)"
          outlined
          clearable
          :mask="maskPosNumber"
          @blur="validateField('innerValue.' + item.type)"
          @input="changeValue(item.type, $event)"
        />
        <v-text-field
          v-else
          :key="item.type"
          :value="innerValue[item.type]"
          :label="item.name"
          :hint="item.description"
          :error-messages="getValidationErrors('innerValue.' + item.type)"
          outlined
          clearable
          @blur="validateField('innerValue.' + item.type)"
          @input="changeValue(item.type, $event)"
        />
      </template>
    </v-form>

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :loading="$wait('submit')"
        @click="submit"
      >
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import MaskField from '@/components/controls/MaskField';

import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import { maskPosNumber } from '@/utils/masks';

export default {
  components: { ModernModalLayout, MaskField },

  mixins: [validation, waitable],

  props: {
    id: { type: Number, required: true },
    orgId: { type: Number, required: true },
    value: { type: Object, required: true },
    types: { type: Array, required: true },
  },

  data: () => ({
    innerValue: {},
  }),

  validations() {
    const generatedRules = this.types.map(item => [
      item.type,
      {
        rule(value) {
          const pattern = new RegExp(item.pattern);
          return value ? pattern.test(value) : true;
        },
      },
    ]);

    return {
      innerValue: Object.fromEntries(generatedRules),
    };
  },

  computed: {
    validationMessages() {
      const generatedMessages = this.types.map(item => [
        `innerValue.${item.type}.rule`,
        item.patternExplanation,
      ]);
      return Object.fromEntries(generatedMessages);
    },
    maskPosNumber: () => maskPosNumber,
  },

  created() {
    this.innerValue = { ...this.value };
  },

  methods: {
    changeValue(type, value) {
      this.innerValue = {
        ...this.innerValue,
        [type]: value || undefined,
      };
    },

    async submit() {
      if (!this.validate()) return;
      this.setServerValidationErrors([]);

      await this.$loadingNotify(
        this.$store.dispatch('EMPLOYEE/changeExtras', {
          orgId: this.orgId,
          employeeId: this.id,
          extras: this.innerValue,
        }),
        'submit',
        'Произошла ошибка добавления дополнительных данных',
        `Дополнительные данные обновлены`,
      )
        .then(() => this.$emit('close'))
        .catch(err => this.catchServerErrors(err, 'innerValue'));
    },
  },
};
</script>
