import Vue from 'vue';

// api
import processingApi from '@/api/services/processing';
import structApi from '@/api/services/structures';
// TF it is a class wut
// eslint-disable-next-line import/no-named-default
import { default as sseApi, SSETopics, SSE_STATUSES } from '@/api/services/sse';

import env from '@/plugins/env';
import { WebStorage } from '@/plugins/webStorage';
import { createModule } from '@/utils/vuex/createModule';
import { timer } from '@/utils/timer';
import { FETCH_STATUS } from '@/utils/constants';
import { DateTime } from '@/plugins/luxon';

const MEDCAB_PASSIVE_HOST = 'medcab:passive:host';
const MEDCAB_PASSIVE_ALLPOINTS = 'medcab:passive:allhosts';

const StopReasons = {
  error: Symbol('e'),
  notWorked: Symbol('nw'),
  break: Symbol('b'), // REVIEW: is it even being used ? Seems not
};

const PassiveStatuses = {
  fetchingShift: Symbol('fs'),
  outsideShift: Symbol('os'),
  inspecting: Symbol('i'),
  readList: Symbol('rl'),
};

/** Подмена статуса в зависимости от типа */
const listNewStatusesByType = {
  taken: 'taken',
  released: 'ready',
  resolved: 'resolved',
  timeout: 'ready',
};

const state = {
  fakes: [],
  inspections: [],
  inspectionsStatus: FETCH_STATUS.IDLE,
  status: PassiveStatuses.fetchingShift,
  stopReason: StopReasons.notWorked,
  currentShift: undefined,
  inspectionId: undefined,
  error: undefined,
  shiftError: null,
  sseConnect: undefined,
  sseLastEventId: undefined,
  timer: null,
  timerInstance: null,
  point: null,
  points: null,
  loadingPoints: false,
  includeAllPoints: false,
  takeStatus: FETCH_STATUS.IDLE,
  playBell: false,
};

const getters = {
  fakes: state =>
    (state.fakes || []).reduce((acc, value, index) => {
      acc[value.type]
        ? (acc[value.type] = [...acc[value.type], { ...value, id: index }])
        : (acc[value.type] = [{ ...value, id: index }]);
      return acc;
    }, {}),

  inspectionId: state => state.inspectionId,
  inspections: state => {
    if (!state.point && !state.includeAllPoints) return [];
    else if (state.includeAllPoints)
      return state.inspections.filter(inspection =>
        state.points
          ? state.points.includes(inspection.host.releasePoint.id)
          : false,
      );
    return state.inspections;
  },

  isLoadedInspections: state =>
    state.inspectionsStatus === FETCH_STATUS.SUCCEEDED,
  isFetchingInspections: state =>
    state.inspectionsStatus === FETCH_STATUS.LOADING,
  isErrorInspections: state => state.inspectionsStatus === FETCH_STATUS.ERROR,

  // Passive statuses
  isFetchingShift: state => state.status === PassiveStatuses.fetchingShift,
  isOutsideShift: state => state.status === PassiveStatuses.outsideShift,
  isReadList: state => state.status === PassiveStatuses.readList,
  isInspecting: state => state.status === PassiveStatuses.inspecting,

  isOnShift: state => !!state.currentShift,
  isOnlineSSE: state => !!state.sseConnect,
  currentShift: state => state.currentShift,

  // Stop reasons
  stopReason: state => state.stopReason,
  isError: state => state.stopReason === StopReasons.error,
  error: state => (state.stopReason === StopReasons.error ? state.error : null),
  shiftError: state => state.shiftError,

  closedInspections: state => state.currentShift?.closedInspections || 0,

  isMedcabDebug: () => env.get('VUE_APP_MEDCAB_DEBUG'),
  timer: state =>
    String(Math.floor(state.timer / 60)).padStart(2, '0') +
    ':' +
    String(state.timer % 60).padStart(2, '0'),

  sseLastEventId: state => !!state.sseLastEventId,

  point: state => state.point,
  points: state => state.points,
  loadingPoints: state => state.loadingPoints,
  includeAllPoints: state => state.includeAllPoints,
  takeStatus: state => state.takeStatus,
  playBell: state => state.playBell,
};

const mutations = {
  fakes: (state, value) => (state.fakes = value),

  inspections: (state, list) => (state.inspections = list),
  fetchingShift: state => (state.status = PassiveStatuses.fetchingShift),

  outsideShift: state => {
    state.status = PassiveStatuses.outsideShift;
    state.currentShift = undefined;
    state.setSSEConnect = undefined;
  },
  setShift: (state, shift) => {
    state.currentShift = shift;
    state.status = PassiveStatuses.readList;
    state.stopReason = null;
    state.shiftError = null;
  },
  setSSEConnect: (state, connect) => (state.sseConnect = connect),
  inspect: (state, { timeoutInSec, inspectionId }) => {
    state.inspectionId = inspectionId;
    state.status = PassiveStatuses.inspecting;
    state.timer = timeoutInSec;
  },
  viewList: state => (state.status = PassiveStatuses.readList),

  resolution: state => {
    if (state.currentShift)
      Vue.set(
        state.currentShift,
        'closedInspections',
        ++state.currentShift.closedInspections,
      );
    state.timer = null;
  },

  error: (state, value) => {
    state.error = value;
    state.stopReason = StopReasons.error;
    if (state.timerInstance) state.timerInstance.stop();
  },
  shiftError: (state, value) => {
    state.shiftError = value;
    if (state.timerInstance) state.timerInstance.stop();
  },
  clearError: state => {
    state.error = null;
    state.stopReason = null;
    state.shiftError = null;
    state.takeStatus = FETCH_STATUS.IDLE;
  },

  inspectionsStatus: (state, value) => (state.inspectionsStatus = value),

  tickTimer: (state, value) => (state.timer = value),
  timerInstance: (state, value) => {
    if (state.timerInstance) state.timerInstance.stop();
    state.timerInstance = value;
  },

  inspectionAdd: (state, inspection) => {
    const isDuplicated =
      state.inspections.findIndex(({ id }) => id === inspection.id) > -1;

    const fromAllowedPoint = state.includeAllPoints
      ? state.points.includes(inspection.host.releasePoint.id)
      : inspection.host.releasePoint.id === state.point;

    if (isDuplicated || !fromAllowedPoint) return;
    state.inspections.push(inspection);
  },
  inspectionChangeStatus: (state, { inspectionId, newStatus }) => {
    const index = state.inspections.findIndex(({ id }) => id === inspectionId);
    if (index === -1) return;
    const inspection = state.inspections[index];
    inspection.status = newStatus;
    Vue.set(state.inspections, index, inspection);
  },

  sseLastEventId: (state, value) => (state.sseLastEventId = value),

  choicePoint: (state, value) => {
    state.point = value;
    if (value) WebStorage.setItem(MEDCAB_PASSIVE_HOST, value);
    else WebStorage.removeItem(MEDCAB_PASSIVE_HOST);
  },
  points: (state, value) => (state.points = value),
  loadingPoints: (state, value) => (state.loadingPoints = value),
  includeAllPoints: (state, value) => {
    state.includeAllPoints = value;
    if (value) WebStorage.setItem(MEDCAB_PASSIVE_ALLPOINTS, value);
    else WebStorage.removeItem(MEDCAB_PASSIVE_ALLPOINTS);
  },

  takeStatus: (state, value) => (state.takeStatus = value),
  playBell: (state, value) => (state.playBell = value),
};

const actions = {
  init({ commit, rootGetters }) {
    if (!rootGetters['AUTH/isAuthorized']) {
      WebStorage.removeItem(MEDCAB_PASSIVE_HOST);
      WebStorage.removeItem(MEDCAB_PASSIVE_ALLPOINTS);
    }

    let previousHost = WebStorage.getItem(MEDCAB_PASSIVE_HOST);
    if (previousHost) {
      previousHost = +previousHost;
      // Проверим доступна ли эта точка для активного медработника
      structApi
        .getPointPreviewsBy([previousHost])
        .then(data => {
          if (data.length && data[0].id === previousHost)
            commit('choicePoint', +previousHost);
          else throw Error(`Точка #${previousHost} недоступна пользователю`);
        })
        .catch(err => {
          console.error(err);
          WebStorage.removeItem(MEDCAB_PASSIVE_HOST);
        });
    }
  },

  async choicePoint({ dispatch, commit }, point) {
    commit('choicePoint', point);
    dispatch('fetchInspections');
  },

  async syncToggle({ dispatch }) {
    // function needed to be ran at component mount rather than in init action
    // cuz otherwise the checkbox component doesn't update for some reason
    const includeAllPoints = WebStorage.getItem(MEDCAB_PASSIVE_ALLPOINTS);
    if (includeAllPoints) dispatch('toggleAllPoints', includeAllPoints);
  },

  async toggleAllPoints({ commit, dispatch }, value) {
    // points fetching ain't required if user disables all points checkbox
    if (!value) {
      commit('includeAllPoints', value);
      await dispatch('fetchInspections');
      return;
    }

    try {
      // for savety reasons fetch available points for medic every time he
      // toggles all points ON
      commit('loadingPoints', true);
      const { items } = await structApi.getPointPreviews({
        accessLevel: 'full',
        includeGuests: false,
        page: 1,
        limit: 1000,
      });
      // leave out only ids of points, don't need anything else for now
      commit(
        'points',
        items.map(point => point.id),
      );
      commit('includeAllPoints', value);
      await dispatch('fetchInspections');
    } catch (err) {
      console.error(err);
    } finally {
      commit('loadingPoints', false);
    }
  },

  async viewList({ getters, dispatch, commit }) {
    if (getters['isError']) {
      dispatch('restoreShift');
    } else {
      dispatch('openSSEConnection');
      dispatch('fetchInspections');
      commit('viewList');
    }
  },

  fireError({ commit, dispatch }, err) {
    console.error(err);
    commit('error', err);
    dispatch('MEDCAB_INSPECTION/clean', null, { root: true });
    if (state.timerInstance) state.timerInstance.stop();
  },

  clearError({ commit, dispatch }) {
    commit('clearError', null);
    dispatch('MEDCAB_INSPECTION/clean', null, { root: true });
  },

  handlerShiftError({ commit }, err) {
    if (err?.response) {
      if (err.response.data.code === 404) {
        commit('shiftError', err.response.data);
        commit('outsideShift');
      }
    } else {
      throw err;
    }
  },

  async fetchInspections({ getters, dispatch, commit }) {
    if (!getters.point && !getters.includeAllPoints) return;

    const params = {
      pointId: getters.point,
    };

    if (getters.includeAllPoints) params.includeAllPoints = true;

    commit('inspectionsStatus', FETCH_STATUS.LOADING);
    try {
      const data = await processingApi.getInspectionsPassive(params);
      commit('inspections', data.inspections);
      commit('inspectionsStatus', FETCH_STATUS.SUCCEEDED);
    } catch (err) {
      console.error(err);
      dispatch('fireError', err);
      commit('inspectionsStatus', FETCH_STATUS.ERROR);
    }
  },

  async fetchInspection(
    { getters, dispatch, commit, rootGetters },
    { inspectionId, timeoutInSec },
  ) {
    const timeout = Math.floor(timeoutInSec - rootGetters['TIME/pingInSec']);
    commit('inspect', {
      inspectionId,
      timeoutInSec: timeout,
    });

    commit(
      'timerInstance',
      timer({
        seconds: timeout,
        onTick: time => commit('tickTimer', time),
        onTimeout: () => dispatch('viewList'),
        isStopped: () => !getters.isInspecting,
      }),
    );

    await dispatch(
      'MEDCAB_INSPECTION/fetchInspection',
      { id: inspectionId, type: 'passive' },
      { root: true },
    ).catch(err => {
      dispatch('fireError', err);
    });

    await dispatch(
      'MEDCAB_INSPECTION/fetchDetails',
      { id: inspectionId },
      { root: true },
    ).catch(err => {
      dispatch('fireError', err);
    });
  },

  async openSSEConnection({ getters, dispatch, commit, rootGetters }) {
    const status = sseApi.getStatus(SSETopics.PassivePool);
    if (status !== null && status !== SSE_STATUSES[2]) return;

    const categoryIds = rootGetters['AUTH/medicDetails'].categories;
    const callback = event => {
      if (!event.type) return;

      const inspection = event.payload?.inspection;

      if (
        event.type === 'taken' &&
        inspection.relatedShiftId === getters.currentShift.id
      ) {
        dispatch('fetchInspection', {
          inspectionId: inspection.id,
          timeoutInSec: inspection.timeoutInSec,
        });
        commit('sseLastEventId', event.eventId);
        return;
      }

      if (event.type === 'ready') {
        // добавлен в список
        if (categoryIds.includes(inspection.organization.category?.id)) {
          commit('inspectionAdd', inspection);
          commit('playBell', true);
        }
      } else if (
        ['taken', 'released', 'resolved', 'timeout'].includes(event.type)
      ) {
        commit('inspectionChangeStatus', {
          inspectionId: inspection.id,
          newStatus: listNewStatusesByType[event.type],
        });
        if (event.type !== 'taken' && inspection.id === getters.inspectionId)
          dispatch('MEDCAB_INSPECTION/clean', null, { root: true });
      }

      commit('sseLastEventId', event.eventId);
    };

    sseApi.watchEvents({
      key: SSETopics.PassivePool,
      query: {
        topics: [SSETopics.PassivePool, SSETopics.Internal],
        heartbeat: true, // Без него будет разрыв соединения каждые 10 сек
      },
      errCallback: err => dispatch('fireError', err),
      callback,
    });
  },

  stopBell({ commit }) {
    commit('playBell', false);
  },

  async restoreShift({ dispatch, commit }) {
    commit('clearError');

    try {
      const { openShift, inspectionInWork } = await processingApi
        .getCurrentShiftPassive()
        .catch(err => dispatch('handlerShiftError', err));

      if (openShift?.status === 'opened') {
        dispatch('start', { shift: openShift });

        if (inspectionInWork) {
          const { id, timeoutInSec } = inspectionInWork;
          dispatch('fetchInspection', {
            inspectionId: id,
            timeoutInSec,
          });
        } else commit('viewList');
      } else commit('outsideShift');
    } catch (err) {
      console.error(err);
      dispatch('fireError', err);
    }
  },

  async openShift({ getters, commit, dispatch }) {
    if (!getters.isOutsideShift) return;

    commit('clearError');
    commit('fetchingShift');
    try {
      const shift = await processingApi
        .openShiftPassive({ medicTimezoneOffset: DateTime.local().offset })
        .catch(err => dispatch('handlerShiftError', err));
      dispatch('start', { shift });
    } catch (err) {
      dispatch('fireError', err);
    }
  },

  async closeShift({ getters, dispatch, commit }) {
    if (!getters.isOnShift || !getters.currentShift) return;
    try {
      sseApi.unwatchEvents(SSETopics.PassivePool);
      await processingApi
        .closeShiftPassive()
        .catch(err => dispatch('handlerShiftError', err));
      dispatch('MEDCAB_INSPECTION/clean', null, { root: true });
    } catch (err) {
      commit('error', err);
    } finally {
      commit('outsideShift');
    }
  },

  async start({ dispatch, commit }, { shift }) {
    await commit('setShift', shift);
    await dispatch('openSSEConnection');
    dispatch('fetchInspections');
  },

  async take({ dispatch, commit }, { inspectionId }) {
    commit('takeStatus', FETCH_STATUS.LOADING);
    try {
      await processingApi.takeInspectionPassive(inspectionId);
      commit('takeStatus', FETCH_STATUS.SUCCEEDED);
    } catch (err) {
      dispatch('viewList');
      commit('takeStatus', FETCH_STATUS.ERROR);
      return dispatch('fireError', err);
    }
  },

  async release({ getters, dispatch }) {
    try {
      const { inspectionId } = getters;
      await processingApi.releaseInspectionPassive(inspectionId);
      await dispatch('MEDCAB_INSPECTION/clean', null, { root: true });
    } catch (err) {
      return dispatch('fireError', err);
    }
  },

  async result({ state, dispatch, commit }, payload) {
    const params = { ...payload };

    try {
      dispatch('MEDCAB_INSPECTION/clean', undefined, { root: true });
      await processingApi.resolveInspectionPassive(state.inspectionId, params);
    } catch (err) {
      dispatch('viewList');
      dispatch('fireError', err);
      throw err;
    } finally {
      commit('resolution');
      dispatch('viewList');
    }
  },

  // Fakes
  async fetchFakes({ commit }) {
    const fakes = await processingApi.getFakes();
    commit('fakes', fakes);
  },
  async generateFakeInspection(_, number) {
    processingApi.generateFakeInspection(number);
  },
};

export default createModule({
  state,
  getters,
  mutations,
  actions,
});
