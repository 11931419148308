<template>
  <v-row class="px-5">
    <v-col class="d-flex flex-column" cols="4">
      <Card dense>
        <Spinner v-if="!employee" title="Загрузка работника" />
        <Photo
          v-else
          :item="employee"
          :handle-archive="handleArchive"
          height="350px"
        />
      </Card>

      <v-card class="mt-3" style="max-height: 100px">
        <GuardFetch
          :status="bansStatus"
          :error="bansError"
          loading-title="Загрузка блокировок"
          @refresh="refreshDetails('bans')"
        >
          <Bans class="pa-2" :value="bans" :employee="employee" />
        </GuardFetch>
      </v-card>

      <v-layout class="mt-3" style="height: 70px">
        <BigBtn
          text="Предыдущие осмотры"
          data-sentry-mark="medcab:openInspectionsHistory"
          icon="fa-history"
          max-height="80px"
          @click="openInspectionsHistoryModal"
        />
        <BigBtn
          class="ml-5"
          text="Техническая поддержка"
          data-sentry-mark="medcab:openSupport"
          icon="fa-headset"
          max-height="80px"
          @click="openSupportModal"
        />
      </v-layout>
    </v-col>

    <v-col class="d-flex flex-column" cols="4">
      <v-card class="rounded overflow-hidden">
        <Video :video="video" :video-match="videoMatch" />
      </v-card>
    </v-col>

    <v-col class="d-flex flex-column" cols="4" style="gap: 12px">
      <Card
        v-if="$can('SUR_GET') && surveys.length && surveyResult.show"
        title="Прием запрещенных препаратов"
        dense
        :style="surveyResult.style"
        class="pb-3"
      >
        <div :class="surveyResult.classes">
          {{ surveyResult.response }}
        </div>
      </Card>

      <Card
        :loading="averageMeasurementsStatus === FETCH_STATUS.LOADING"
        title="Средние медицинские показатели"
        dense
      >
        <ErrorBlock
          v-if="averageMeasurementsStatus === FETCH_STATUS.ERROR"
          class="mb-0"
          :error="averageMeasurementsError"
          @refresh="refreshDetails('averageMeasurements')"
        />
        <BoundariesTable
          :average="averageMeasurements || undefined"
          :boundaries="boundaries"
        />
      </Card>

      <Card title="Документы" dense>
        <GuardFetch
          :status="recordStatus"
          :error="recordError"
          loading-title="Загрузка документов"
          @refresh="refreshDetails('record')"
        >
          <EmployeeDocuments
            :incoming="validCertificates"
            height="160px"
            :employee="employee"
          />
        </GuardFetch>
        <template #action>
          <CardActionBtn
            label="Посмотреть все"
            data-sentry-mark="medcab:seeAllDocuments"
            @click="openModalDocuments"
          />
        </template>
      </Card>

      <Card title="Комментарии" dense>
        <GuardFetch
          :status="recordStatus"
          :error="recordError"
          loading-title="Загрузка комментариев"
          @refresh="refreshDetails('record')"
        >
          <CommentsBlock
            :value="comments"
            :show-title="false"
            height="160px"
            can-add-comment
            :on-send-comment="sendComment"
            :current-account-id="currentAccount.id"
          />
        </GuardFetch>
      </Card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SUPPORT_TOPICS_TYPES, FETCH_STATUS } from '@/utils/constants';
import { BADGE_COLORS } from '@/modules/medcab/constants.js';

import BoundariesTable from '@/components/BoundariesTable.vue';
import Photo from '@/modules/employees/components/Photo.vue';
import CommentsBlock from '@/modules/employees/components/CommentsBlock.vue';

import BigBtn from '@/components/controls/buttons/BigBtn';
import Spinner from '@/components/Spinner';
import EmployeeDocuments from '@/components/EmployeeDocuments';
import Card from '@/components/ui/Card';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';
import Bans from './Bans';
import GuardFetch from './GuardFetch';
import Video from './Video';
import ErrorBlock from '@/components/ErrorBlock';

export default {
  components: {
    Photo,
    BoundariesTable,
    CommentsBlock,
    BigBtn,
    EmployeeDocuments,
    Card,
    CardActionBtn,
    Bans,
    GuardFetch,
    Video,
    Spinner,
    ErrorBlock,
  },
  computed: {
    ...mapGetters('AUTH', ['currentAccount']),
    ...mapGetters('MEDCAB_INSPECTION', [
      'id',
      'video',
      'videoMatch',
      'type',
      'employee',
      'host',
      'organization',
      'boundaries',
      'validCertificates',
      'comments',
      'inspection',

      'bans',
      'bansStatus',
      'bansError',

      'averageMeasurements',
      'averageMeasurementsStatus',
      'averageMeasurementsError',

      'recordStatus',
      'recordError',

      'surveys',
    ]),
    FETCH_STATUS: () => FETCH_STATUS,

    surveyResult() {
      const key = 'illegal_drugs';
      const answer = this.surveys[0]?.answers.find(a => a.key === key);
      const result = {
        classes: 'mx-4',
        response: '',
        style: {},
        show: !!answer,
      };

      if (answer) {
        if (answer.value) {
          result.response = answer.value;
          result.classes += ' font-weight-bold';
          if (answer.value === 'Да') {
            result.style = {
              color: `rgb(${BADGE_COLORS.alcohol})`,
              background: `rgba(${BADGE_COLORS.alcohol}, 0.1)`,
            };
          }
        } else {
          result.response = '-';
          result.show = false;
        }
      } else {
        result.question = 'Пока нет ни одного опроса.';
      }

      return result;
    },
  },
  methods: {
    ...mapActions('MEDCAB_INSPECTION', ['sendComment', 'refreshDetails']),

    handleArchive(id) {
      return this.$store.dispatch('MEDCAB_INSPECTION/archivePhoto', id);
    },

    openInspectionsHistoryModal() {
      this.$openModal('inspections/history', { employee: this.employee });
    },

    openModalDocuments() {
      this.$openModal('documents/employee', { employee: this.employee });
    },

    openSupportModal() {
      this.$openModal.call(null, 'support', {
        type: SUPPORT_TOPICS_TYPES['INSPECTION'],
        details: this.inspection,
      });
    },
  },
};
</script>
