<template>
  <div>
    <BlockTitle title="Доступ к объектам" class="mb-6" />

    <v-row v-for="(component, key) in fields" :key="key">
      <v-col cols="12">
        <component
          :is="component"
          multiple
          chips
          :medical="key === 'med_orgs' ? true : null"
          :type="
            key === 'org_groups' ||
            key === 'employee_groups' ||
            key === 'host_groups'
              ? 'all'
              : 'public'
          "
          access-level="full"
          small-chips
          deletable-chips
          :value="value.objects[key]"
          @change="handleChangeObjectsBinding(key, $event)"
        />
      </v-col>
    </v-row>

    <BlockTitle title="Доступ к осмотрам" class="my-6" />

    <InspectionFilters :value="value" @input="val => $emit('input', val)" />
  </div>
</template>

<script>
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';
import OrganizationGroupSelect from '@/components/controls/structures/OrganizationGroupSelect';
import PointSelect from '@/components/controls/structures/PointSelect';
import EmployeeGroupSelect from '@/components/controls/structures/EmployeeGroupSelect';
import HostGroupSelect from '@/components/controls/structures/HostGroupSelect';
import InspectionFilters from '@/components/InspectionFilters';
import BlockTitle from '@/components/ui/BlockTitle.vue';

export default {
  components: {
    OrganizationSelect,
    OrganizationGroupSelect,
    PointSelect,
    EmployeeGroupSelect,
    HostGroupSelect,
    BlockTitle,
    InspectionFilters,
  },

  props: {
    value: { type: Object, required: true },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    fields: {
      med_orgs: OrganizationSelect,
      org_groups: OrganizationGroupSelect,
      orgs: OrganizationSelect,
      points: PointSelect,
      employee_groups: EmployeeGroupSelect,
      host_groups: HostGroupSelect,
    },
  }),
  methods: {
    handleChangeObjectsBinding(key, value) {
      this.$emit('input', {
        ...this.value,
        objects: { ...this.value.objects, [key]: value },
      });
    },
    handleAddInspectionsBinding() {
      this.$emit('input', {
        ...this.value,
        inspections: [
          ...this.value.inspections,
          {
            parent: {},
            points: null,
            hostGroups: null,
            employeeGroupIds: null,
          },
        ],
      });
    },
    handleChangeInspectionsBinding(index, inspectionItem) {
      this.$emit('input', {
        ...this.value,
        inspections: [
          ...this.value.inspections.slice(0, index),
          {
            parent: inspectionItem.parent || {},
            points: inspectionItem.points?.length
              ? inspectionItem.points
              : null,
            hostGroups: inspectionItem.hostGroups?.length
              ? inspectionItem.hostGroups
              : null,
            employeeGroups: inspectionItem.employeeGroups?.length
              ? inspectionItem.employeeGroups
              : null,
          },
          ...this.value.inspections.slice(index + 1),
        ],
      });
    },
    handleRemoveInspectionsBinding(index) {
      this.$emit('input', {
        ...this.value,
        inspections: [
          ...this.value.inspections.slice(0, index),
          ...this.value.inspections.slice(index + 1),
        ],
      });
    },
  },
};
</script>
