import { xhrErrorMessage } from '@/utils/helpers';
import { AxiosError } from 'axios';

export default class XHRError extends Error {
  constructor(err: AxiosError) {
    super();

    const { config, response } = err;
    const method = config.method?.toUpperCase() || '';
    const code = response?.status;
    const url = config?.url || '';

    this.name = ['XHR Error', code, method, url].filter(_ => _).join(' ');
    this.message = xhrErrorMessage(err);
    this.stack = err.stack;
  }
}
