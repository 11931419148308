export const messages = {
  resourceName: 'организаций',
  searchFilterLabel: 'Поиск по организациям',
  searchFilterPlaceholder: '',
  create: {
    title: 'Добавление организации',
    actionButton: 'Добавить',
    successfulAction: 'Организация успешно добавлена',
    errorAction: 'Произошла ошибка сохранения организации',
  },
  edit: {
    title: 'Редактирование организации',
    actionButton: 'Сохранить',
    successfulAction: 'Организация успешно изменена',
    errorAction: 'Произошла ошибка изменения организации',
  },
  delete: {
    title: 'Удалить',
    description: 'Вы точно хотите удалить организацию?',
    successfulAction: 'Организация успешно удалена',
    errorAction: 'Произошла ошибка удаления организации',
  },
};

export const entity = [
  { key: 'id', label: 'ID' },
  {
    key: 'isActive',
    label: 'Статус',
    headerProps: {
      width: '48px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
    convert: item => (item.isActive ? 'Активна' : 'Неактивна'),
  },
  { key: 'name', label: 'Название', hideInTable: true },
  { key: 'shortName', label: 'Краткое наименование', hideInTable: true },
  { key: 'nameShortName', label: 'Название', hideInCard: true },
  { key: 'inn', label: 'ИНН', hideInTable: true },
  { key: 'kpp', label: 'КПП', hideInTable: true },
  { key: 'inn_kpp', label: 'Рег. данные', hideInCard: true },
  {
    key: 'category',
    label: 'Категория',
    convert: item => item.category?.name || '–',
  },
  {
    key: 'groups',
    label: 'Группы',
    convert: item => item.groups.map(group => group.name).join(', '),
  },
  {
    key: 'holding',
    label: 'Холдинг',
    hideInTable: true,
    convert: item => item.holding?.name,
  },
  {
    key: 'isMedical',
    label: 'Свои медработники',
    field: 'custom',
    headerProps: {
      width: '100px',
      align: 'center',
    },
    convert: item => (item.isMedical ? 'Да' : 'Нет'),
  },
];

export const filters = {
  isActive: { type: Boolean, default: true },
  categoryId: { type: Number, default: undefined },
  isMedical: { type: Boolean, default: null },
  groupIds: { type: Array, default: undefined },
  holdingIds: { type: Array, default: undefined },
  legalForm: { type: String, default: null },
  niiatStatusEnabled: { type: Boolean, default: null },
  // fixed filter
  includeSystemGroups: { type: Boolean, default: false },
};
