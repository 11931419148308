import processingApi from '@/api/services/processing';

export default {
  state: {
    processingEmployeeDetails: null,
  },
  getters: {
    processingEmployeeDetails: state => state.processingEmployeeDetails,
  },
  mutations: {
    processingEmployeeDetails: (state, value) =>
      (state.processingEmployeeDetails = value),
  },
  actions: {
    async fetchProcessingEmployeeDetails({ commit }, id) {
      const data = await processingApi.employeeCurrentDetails(id);
      commit('processingEmployeeDetails', data);
      return data;
    },
  },
};
