var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('List',{attrs:{"search-filter":"","available-actions":{
    createAction: _vm.$can('NF_SC'),
    showAction: _vm.$can('NF_SR'),
    editAction: false,
    deleteAction: false,
  }},on:{"item:create":function () { return _vm.$router.push({ name: 'notifications:subscriptions:create' }); }},scopedSlots:_vm._u([{key:"filters",fn:function(query){return [_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-select',{attrs:{"label":"Мульти","items":_vm.booleanItems,"item-value":"value","item-text":"name","hide-details":"","outlined":"","dense":"","menu-props":{ offsetY: true }},model:{value:(query.multiOrg),callback:function ($$v) {_vm.$set(query, "multiOrg", $$v)},expression:"query.multiOrg"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"6"}},[_c('AccountSelect',{attrs:{"multiple":"","dense":"","title":"Получатели"},model:{value:(query.accountIds),callback:function ($$v) {_vm.$set(query, "accountIds", $$v)},expression:"query.accountIds"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"6"}},[_c('OrganizationSelect',{attrs:{"multiple":"","dense":"","title":"Организации владельцы"},model:{value:(query.orgIds),callback:function ($$v) {_vm.$set(query, "orgIds", $$v)},expression:"query.orgIds"}})],1)]}},{key:"table.item.name_org",fn:function(ref){
  var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.name))]),_c('br'),_vm._v(" "+_vm._s((_vm.getOrgPreviewsById(item.orgId) || {}).name)+" ")]}},{key:"table.item.multiOrg",fn:function(ref){
  var item = ref.item;
return [_c('Round',{attrs:{"type":item.multiOrg ? 'green lighten-2' : 'grey lighten-2'}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }