<template>
  <ModernModalLayout size="medium" title="Изменить оценку">
    <v-text-field
      v-model="value"
      hide-details="auto"
      outlined
      :disabled="$wait('submit')"
      :error-messages="getValidationErrors('value')"
      @blur="validateField('value')"
    />
    <template #actions:append>
      <v-btn
        depressed
        color="primary"
        outlined
        :loading="$wait('submit')"
        @click="submit"
      >
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import ModernModalLayout from '@/components/layouts/ModernModalLayout.vue';

import validationMixin from '@/utils/validation';
import { required, numeric, maxValue } from 'vuelidate/lib/validators';
import waitable from '@/utils/mixins/waitable';
import { mapActions } from 'vuex';

export default {
  components: { ModernModalLayout },
  mixins: [validationMixin, waitable],
  props: {
    weight: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      value: null,
    };
  },
  watch: {
    'weight.value': {
      immediate: true,
      handler() {
        this.value = this.weight.value;
      },
    },
  },

  validations() {
    return {
      value: { required, numeric, maxValue: maxValue(10) },
    };
  },
  methods: {
    ...mapActions('MEDBLOCK/RATINGS', ['patchEvaluations']),
    async submit() {
      if (!this.validate()) return;

      await this.$loadingNotify(
        this.patchEvaluations({ ...this.weight, value: this.value }),
        'submit',
        `Произошла ошибка изменения оценки`,
        `Оценка успешно изменена`,
      ).then(() => this.$emit('close'));
    },
  },
};
</script>
