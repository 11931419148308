export function entityToHeaders(entity) {
  const action = {
    text: 'Действия',
    value: 'actions',
    align: 'right',
    sortable: false,
  };

  return [
    ...entity
      .filter(field => field.label && !field.hideInTable)
      .map(field => {
        const headerProps = { ...(field?.headerProps || {}) };

        // Фиксированная ширина колонки ID
        if (field.key === 'id') headerProps.width = '70px';

        headerProps.cellClass = [
          // Сделать названия сущностей жирными
          ...((field.key === 'name' &&
            headerProps.cellClass === undefined && ['font-weight-bold']) ||
            []),
          headerProps?.cellClass || '',
        ].join(' ');
        return {
          text: field.label,
          value: field.key,
          convert: field.convert,
          ...headerProps,
        };
      }),
    action,
  ];
}

export function convert(entity, data) {
  return Object.fromEntries(
    entity.map(item => {
      const value = item.convert ? item.convert(data) : data[item.key];
      return [item.key, value];
    }),
  );
}
