<template>
  <Card title="FindFace" dense>
    <AsyncWrapper :handler="fetchFindFaceStatus">
      <FindFaceSync :value="findFace" />
    </AsyncWrapper>
    <template v-if="findFace" #action>
      <FindFaceActions :value="findFace" />
    </template>
  </Card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Card from '@/components/ui/Card';
import AsyncWrapper from '@/components/AsyncWrapper';
import FindFaceSync from '@/modules/structures/organizations/components/FindFaceSync';
import FindFaceActions from '@/modules/structures/organizations/components/FindFaceActions';

export default {
  components: { Card, AsyncWrapper, FindFaceSync, FindFaceActions },
  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', ['findFace']),
  },
  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', ['fetchFindFaceStatus']),
  },
};
</script>
