var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panels',{staticStyle:{"height":"500px"},attrs:{"accordion":"","dense":""}},[_c('AsyncScroller',{attrs:{"full-width":"","fetcher":_vm.fetchFunction,"no-data-message":"Нет данных"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.dateTime(item.date))+" ")]),_c('span',{staticClass:"px-2"},[_vm._v("|")]),_c('span',{staticClass:"operation_row"},[_vm._v(" "+_vm._s(item.operation)+" ")]),(item.actor && !_vm.hideActor)?_c('span',[_c('span',{staticClass:"px-2"},[_vm._v("|")]),(item.actor.type === 'USER')?_c('span',[_vm._v(" Пользователь "),_c('LinkButton',{attrs:{"action":_vm.$can('ACC_R'),"to":{
                    name: 'admin:users:item',
                    params: { id: item.actor.accountId },
                  }}},[_vm._v(" "+_vm._s(item.actor.name)+" ")])],1):(item.actor.type === 'APP')?_c('span',[_vm._v(" Приложение "),_c('LinkButton',{attrs:{"action":_vm.$can('APP_RD'),"to":{
                    name: 'admin:applications:item',
                    params: { id: item.actor.accountId },
                  }}},[_vm._v(" "+_vm._s(item.actor.name)+" ")])],1):(item.actor.type === 'CRON')?_c('span',[_vm._v(" По расписанию (из сервиса "+_vm._s(item.actor.name)+") ")]):(item.actor.type === 'TERMINAL')?_c('span',[_vm._v(" С терминала ")]):_vm._e()]):_vm._e()]),_c('v-spacer')],1),_c('v-expansion-panel-content',[_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',_vm._l((item.data),function(change,dataIndex){return _c('tr',{key:dataIndex},[_c('td',{staticClass:"grey--text",staticStyle:{"width":"30%"}},[_vm._v(" "+_vm._s(change.label)+" ")]),_c('td',[(change.oldValue)?[_c('span',{staticClass:"grey--text text-decoration-line-through"},[_vm._v(" "+_vm._s(change.oldValue)+" ")])]:_vm._e(),(change.oldValue !== undefined)?_c('span',{staticClass:"px-2"},[_vm._v(" ➔ ")]):_vm._e(),(change.type === 'url')?[_c('a',{attrs:{"target":"_blank","href":change.value}},[_vm._v(_vm._s(change.value))])]:(change.type === 'image')?[_c('a',{attrs:{"target":"_blank","href":change.value}},[_c('img',{attrs:{"src":change.value,"width":"150"}})])]:(change.type === 'json')?[_c('json-viewer',{attrs:{"value":_vm.getJson(change.value),"theme":"jv-theme","expanded":"","expand-depth":0,"sort":"","show-array-index":false}})]:[_vm._v(" "+_vm._s(change.value)+" ")]],2)])}),0)])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }