<template>
  <v-list-item @click="openDocumentModal">
    <v-list-item-action class="employee-document__action">
      <v-tooltip
        v-if="value.status && documentStatus[value.status]"
        top
        content-class="tooltip--show-white-space"
      >
        <template #activator="{ on }">
          <v-icon :color="documentStatus[value.status].color" v-on="on">
            {{ documentStatus[value.status].icon }}
          </v-icon>
        </template>
        <span>{{ documentStatus[value.status].title || '–' }}</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-content>
      <b>{{ value.name }}</b>
      <span>{{ issuedAt }} {{ dueDate }}</span>
      <div class="mt-1 body-2 grey--text text-no-wrap">
        <span v-if="value.diagnosis">
          {{ value.diagnosis }}
        </span>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { date } from '@/utils/convert';
import { DOCUMENT_STATUSES } from '@/modules/documents/constants';

export default {
  props: {
    value: { required: true, type: Object, default: () => ({}) },
    showStatus: { type: Boolean, default: false },
    disableOpenModal: { type: Boolean, default: false },
    outgoing: { type: Boolean, default: false },
    employee: { type: Object, required: true },
  },

  computed: {
    issuedAt() {
      return date(this.value.issuedAt);
    },
    dueDate() {
      const dueDate = this.value.dueDate;
      return dueDate ? ` – ${date(dueDate)}` : '';
    },

    documentStatus() {
      return DOCUMENT_STATUSES.reduce((agg, el) => {
        agg[el.value] = el;
        return agg;
      }, {});
    },
  },

  methods: {
    openDocumentModal() {
      if (this.disableOpenModal) return;

      if (this.outgoing)
        this.$openModal('documents/showOutgoing', {
          id: this.value.id,
        });
      else
        this.$openModal('documents/showIncoming', {
          id: this.value.id,
          employee: this.employee,
        });
    },
  },
};
</script>

<style scoped>
.employee-document__action {
  margin-right: 16px !important;
}
</style>
