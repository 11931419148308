<template>
  <v-card class="overflow-y-auto" v-bind="$attrs">
    <v-card-title
      v-if="title"
      class="card__title d-flex justify-space-between"
      style="background: rgba(0, 0, 0, 0.06)"
    >
      <span class="mr-1">{{ title }}</span>
      <slot name="action" />
    </v-card-title>
    <v-card-text :class="{ 'pa-4': !dense, 'pa-0': dense }">
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: { type: String, default: null },
    dense: Boolean,
  },
};
</script>
