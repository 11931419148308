<template>
  <v-tooltip bottom color="rgba(0,0,0,0)">
    <template #activator="{ on }">
      <v-icon size="14" class="ml-1" v-on="on">{{ icon }}</v-icon>
    </template>

    <v-simple-table dense dark>
      <template #default>
        <tbody>
          <tr v-for="(data, index) in list" :key="index">
            <slot name="row" v-bind="{ data }" />
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    list: {
      required: true,
      type: [Array, Object],
    },
    icon: {
      type: String,
      default: 'fa-question-circle',
    },
  },
};
</script>
