import { delay } from './helpers';

export function pollerServiceFactory(checkTask, delayMs = 3000) {
  const poller = async taskId => {
    const result = await checkTask(taskId).then(res => res.data);

    if (result.status === 'done') {
      return result.data;
    } else if (result.status === 'waiting' || result.status === 'inProgress') {
      await delay(delayMs);
      return poller(taskId);
    } else {
      throw new Error(result?.error?.message || result);
    }
  };

  return poller;
}
