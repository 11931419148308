export const messages = {
  resourceName: 'профилей конфигураций',
  searchFilterLabel: 'Поиск по профилям',
  searchFilterPlaceholder: '',
};

export const entity = [
  { key: 'key', label: 'Ключ' },
  { key: 'formFactor', label: 'Тип профиля' },
  { key: 'name', label: 'Название' },
  {
    key: 'isCustomProfile',
    label: 'Вид конфигурации',
    convert: item => (item.isDefault ? 'По умолчанию' : 'Настраеваемый'),
  },
  { key: 'description', label: 'Описание' },
];

export const filters = {
  formFactor: { type: Array, default: undefined },
  binding: { type: Array, default: undefined },
  isCustom: { type: Boolean, default: null },
};
