import { RU_TIMEZONES } from '@/utils/constants';

export const messages = {
  resourceName: 'точек',
  searchFilterLabel: 'Поиск по точкам выпуска',
  searchFilterPlaceholder: '',
};

export const entity = [
  { key: 'id', label: 'ID' },
  {
    key: 'isActive',
    label: 'Статус',
    headerProps: {
      width: '60px',
      align: 'center',
      cellClass: 'pa-0',
      class: 'pa-0',
    },
    convert: item => (item.isActive ? 'Активна' : 'Неактивна'),
  },
  {
    key: 'isMobile',
    label: 'Мобильная',
    hideInTable: true,
    headerProps: {
      width: '90px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
    convert: item => (item.isMobile ? 'Да' : 'Нет'),
  },
  {
    key: 'pointOnMap',
    label: 'Местоположение',
    hideInCard: true,
  },
  {
    key: 'address',
    label: 'Адрес',
    hideInTable: true,
    convert: item => item.address || '',
  },
  {
    key: 'coordinates',
    label: 'Координаты',
    hideInTable: true,
    convert: item =>
      item.coordinates?.latitude || item.coordinates?.latitude === 0
        ? `${item.coordinates.latitude}; ${item.coordinates.longitude}`
        : '',
  },
  {
    key: 'timezone',
    label: 'Часовой пояс',
    hideInTable: true,
    convert: item =>
      RU_TIMEZONES.find(timezone => timezone.value === item.timezone)?.text,
  },
  {
    key: 'organization',
    label: 'Организация',
    convert: item => item.organization.name,
  },
  {
    key: 'hostsCount',
    label: 'Кол-во ПАК',
    headerProps: { width: '80px', align: 'center' },
  },
  { key: 'comment', label: 'Комментарий' },
];

export const filters = {
  isActive: { type: Boolean, default: true },
  orgIds: { type: Array, default: undefined },
  isMobile: { type: Boolean, default: null },
};
