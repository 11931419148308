/**
 * Хак изменяющий страницу в URL.
 *
 * Короч, менять входящий параметр query плохо,
 * и чтобы этот хак лежал не пачкал код, решил вынести его в отдельную функцию.
 */
export const changePages = (getters, commit, query) => {
  if (getters.needResetPage) {
    query.page = 1;
    commit('needResetPage', false);
  }

  commit('listQuery', query);
  return query;
};
