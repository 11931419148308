import structApi from '@/api/services/structures';
import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

export default createModule(
  crudSingleFactory({
    fetchMethod: structApi.organizationGroupGet,
    updateMethod: structApi.organizationGroupUpdate,
    linkedList: 'STRUCTURES/ORGANIZATION_GROUPS',
  }),
);
