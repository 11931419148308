import structApi from '@/api/services/structures';
import env from '@/plugins/env';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { createModule } from '@/utils/vuex/createModule';
import { filters } from '../entity';

const state = {
  organizations: [],
  groups: [],
  exportLimit: null,
  isImporting: false,
};

const getters = {
  organizations: state => state.organizations,
  groups: state => state.groups,
  importTemplateURL: _ =>
    env.get('VUE_APP_STRUCTURES_API') + '/v3/employees/import/template',
  exportLimit: state => state.exportLimit,
  isImporting: state => state.isImporting,
};

const mutations = {
  organizations: (state, value) => (state.organizations = value),
  groups: (state, value) => (state.groups = value),
  exportLimit: (state, value) => (state.exportLimit = value),

  resetEmpGroups: state => (state.listQuery.groupIds = []),
  isImporting: (state, value) => (state.isImporting = value),
};

const actions = {
  async createTestEmployee({ commit }, data) {
    const response = await structApi.createTestEmployee(data);
    commit('listAddItem', response);
    return response;
  },

  async fetchOrganizationSettings(_, id) {
    const settings = await structApi.getOrganizationSettings(id);
    return settings;
  },

  async fetchExportLimit({ commit }) {
    const { commonExportLimit } = await structApi.getExportLimits();
    commit('exportLimit', commonExportLimit);
  },

  resetEmpGroups: ({ commit }) => {
    commit('resetEmpGroups');
  },

  async setEmployeeSearch({ commit }, value) {
    commit('listQuery', { search: value });
  },

  async importList({ commit, dispatch }, data) {
    try {
      commit('isImporting', true);
      const response = await structApi.employeeImport(data);
      await dispatch('fetchList');
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      commit('isImporting', false);
    }
  },
};

export default createModule(
  crudListFactory({
    fetchMethod: structApi.employeeList,
    createMethod: structApi.employeeCreate,
    updateMethod: structApi.employeeUpdate,
    deleteMethod: structApi.employeeDelete,
    exportMethod: structApi.employeeExport,
    filters,
  }),
  { state, getters, mutations, actions },
);
