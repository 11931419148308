<template>
  <div class="mb-n1">
    <template v-for="chip in value">
      <!-- If chip include description show tooltip -->
      <v-tooltip v-if="chip.description" :key="chip.key" top>
        <template #activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <BadgesChip class="mr-1 mb-1" v-bind="chip" />
          </div>
        </template>
        <span style="white-space: pre-line">{{ getDescription(chip) }}</span>
      </v-tooltip>

      <!-- If the chip does not include a description, then only show the chip -->
      <BadgesChip v-else :key="chip.key" v-bind="chip" class="mr-1 mb-1" />
    </template>
  </div>
</template>

<script>
import BadgesChip from './BadgesChip.vue';

export default {
  components: { BadgesChip },
  props: {
    value: {
      required: true,
      type: Array,
    },
  },

  methods: {
    getDescription(chip) {
      const description = chip.description || '';
      if (Array.isArray(description))
        return description.length > 1
          ? description.map(el => '- ' + el).join('\n')
          : description[0];
      return description;
    },
  },
};
</script>
