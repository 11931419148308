import structApi from '@/api/services/structures';
import listSimpleFactory from '@/utils/vuex/listSimpleFactory';
import { createModule } from '@/utils/vuex/createModule';
import { filters } from '@/modules/employees/entity';
import env from '@/plugins/env';

const state = {
  isImporting: false,
};

const getters = {
  listQuery: (state, getters, _, rootGetters) => ({
    ...getters.listFilters,
    ...getters.listOrder,
    search: state.listQuery.search || null,
    orgIds: `${rootGetters['STRUCTURES/ORGANIZATION_ITEM/singleItem'].id}`,
  }),
  // for employee import on employees tab
  importTemplateURL: _ =>
    env.get('VUE_APP_STRUCTURES_API') + '/v3/employees/import/template',
  isImporting: state => state.isImporting,
};

const mutations = {
  isImporting: (state, value) => (state.isImporting = value),
};

const actions = {
  async createTestEmployee({ commit }, data) {
    const response = await structApi.createTestEmployee(data);
    commit('listAddItem', response);
    return response;
  },
  async importList({ commit, dispatch }, data) {
    try {
      commit('isImporting', true);
      const response = await structApi.employeeImport(data);
      await dispatch('fetchList');
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      commit('isImporting', false);
    }
  },
};

export default createModule(
  listSimpleFactory({
    fetchMethod: structApi.employeeList,
    createMethod: structApi.employeeCreate,
    filters,
    linkedList: 'EMPLOYEE_LIST',
  }),
  { state, getters, mutations, actions },
);
