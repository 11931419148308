import env from '@/plugins/env';
import { createHTTP } from '../api';

export const integrations = createHTTP(
  env.get('VUE_APP_INTEGRATIONS_API') + '/v3',
);

export default {
  getEmployeeExtrasTypes() {
    return integrations.get('/employees/extras/types').then(res => res.data);
  },

  getEmployeeExtras(id) {
    return integrations.get(`/employees/${id}/extras`).then(res => res.data);
  },

  changeEmployeeExtras(orgId, employeeId, data) {
    return integrations
      .put(`/organizations/${orgId}/employees/${employeeId}/extras`, data)
      .then(res => res.data);
  },

  importEmployeeExtras(orgId, file) {
    const formData = new FormData();
    formData.append('file', file);

    return integrations
      .post(`/organizations/${orgId}/employees/extras/import`, formData)
      .then(res => res.data);
  },

  /**
   * Получение осмотров в НИИАТ
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getNiiatInspections(params) {
    return integrations
      .get('/niiat/inspections', { params })
      .then(res => res.data);
  },

  /**
   * Получение json (отправляемый в НИИАТ) осмотра
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  getNiiatJson(id) {
    return integrations.get(`/niiat/inspection/${id}`).then(res => res.data);
  },

  /**
   * Переотправка осмотра в НИИАТ
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  resetNiiatInspection(id) {
    return integrations
      .post('/niiat/reset-inspections', { ids: [id] })
      .then(res => res.data);
  },

  /**
   * Получение осмотров в ЭПЛ
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getEplList(params) {
    return integrations
      .get(`/ewb`, { params: { ...params } })
      .then(res => res.data);
  },

  /**
   * Получение осмотров в ЭПЛ Контур
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getEplKonturList(params) {
    return integrations.get('/kontur/ewb', { params }).then(res => res.data);
  },
};
