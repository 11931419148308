import { minLength, maxLength, helpers } from 'vuelidate/lib/validators';
import { diffYears } from './helpers';
const { regex, withParams } = helpers;

/** Checks, if value has csv file extension */
export const fileCsv = regex('fileCsv', /^.*\.csv$/);

/** Checks, if value is cyrillic name, surname or patronymic */
export const cyrillicName = regex(
  'cyrillicName',
  /^[А-ЯЁ][а-яё]*(?:[\s-][А-яёЁ]+[а-яё]*){0,2}$/,
);

/** Checks, if value is date in format yyyy-mm-dd */
export const date = regex('date', /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/);

/** Checks, if value is host license */
export const hostLicense = regex('hostLicense', /^A270920181900-(\d{5})$/);

export const kpp = regex('kpp', /^\d{9}$/);
export const snils = regex('snils', /^\d{3}-\d{3}-\d{3} \d{2}$/);

/** Checks, if value is phone in format +7 (000) 000-00-00 */
export const phone = regex(
  'phone',
  /^\+7\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{2}-[0-9]{2}$/,
);

/** Checks, if different between current date and date in value more than min */
export const minAge = min =>
  withParams(
    { type: 'minAge', min },
    value => diffYears(value, new Date()) >= min,
  );

/** Checks different between current date and date in value less than max */
export const maxAge = max =>
  withParams(
    { type: 'maxAge', max },
    value => diffYears(value, new Date()) < max,
  );

/**
 * Checks, if value is string key
 * and contains latin symbols, digits and underscores
 */
export const uniqueStringKey = regex('uniqueStringKey', /^[0-9a-z_-]{3,15}$/);

/**
 * Checks, if value is string key
 * and contains latin symbols, digits and underscores
 */
export const longUniqueStringKey = regex(
  'uniqueStringKey',
  /^[0-9a-z_-]{3,50}$/,
);

/** Validation rules for name, surname and patronymic */
export const cyrillicNameRules = {
  cyrillicName,
  minSymbolsLength: minLength(1),
  maxSymbolsLength: maxLength(30),
};

/** Check time pattern */
export const time = regex('time', /^([0-1]\d|2[0-3]):[0-5]\d$/);
export const timeScheduler = regex(
  'timeScheduler',
  /^([0-1]\d|2[0-3]):[0-5]0$/,
);

/**
 * Checks, if value is filename
 * and contains only latin and cyrillic symbols, digits and underscores
 */
export const fileName = regex('fileName', /^[a-zA-Zа-яёА-ЯЁ0-9-_]*$/);

/**
 * Checks, if value is personnel number
 * and contains only latin and cyrillic symbols, digits and dash
 */
export const personnelNumber = regex(
  'personnelNumber',
  /^[a-zA-Zа-яёА-ЯЁ0-9-]*$/,
);
