<template>
  <Card title="Настройка подписи осмотра" dense>
    <AsyncWrapper :handler="fetch">
      <v-expansion-panels v-model="panel" accordion focusable>
        <v-expansion-panel v-for="(settings, type) in sign" :key="type">
          <v-expansion-panel-header>
            <div class="d-flex justify-space-between align-center">
              <span>{{ profileTemplates.inspectionType[type] }}</span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table dense>
              <tbody>
                <tr v-for="(option, key) in signOptions" :key="key">
                  <td class="grey--text px-4 py-3">
                    {{ option.label }}
                    <v-tooltip v-if="option.hint" right max-width="400px">
                      <template #activator="{ on, attrs }">
                        <v-btn small text icon v-bind="attrs" v-on="on">
                          <v-icon small>far fa-question-circle</v-icon>
                        </v-btn>
                      </template>
                      <span style="width: 200px">{{ option.hint }}</span>
                    </v-tooltip>
                  </td>
                  <td class="px-4 py-3" style="width: 50px">
                    <v-switch
                      :input-value="
                        key === 'signingSimple'
                          ? !settings.signingRequired
                          : settings[key]
                      "
                      class="ma-0 pa-0"
                      inset
                      :disabled="switchDisabled(type, key)"
                      hide-details
                      :readonly="true"
                      :loading="$wait('handleSignSettingsUpdate')"
                      @mousedown="handleSignSettingsUpdate(type, key)"
                    />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </AsyncWrapper>
  </Card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import cloneDeep from 'lodash/cloneDeep';

import AsyncWrapper from '@/components/AsyncWrapper';
import Card from '@/components/ui/Card';

export default {
  components: { AsyncWrapper, Card },
  mixins: [waitable],

  data: () => ({
    loading: false,
    data: {},
    panel: null,
  }),

  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', ['sign']),
    ...mapGetters('REGISTRY', ['profileTemplates']),
    // a bit of hardcode to save up lines
    signOptions() {
      return {
        signingRequired: {
          label: 'Подпись (пин-код)',
          approval: status =>
            `Вы действительно хотите ${
              status ? 'подключить' : 'отключить'
            } подпись (пин-код)?`,
          successfulText: status =>
            `Подпись (пин-код) успешно ${status ? 'подключена' : 'отключена'}`,
          errorText: status =>
            `Ошибка ${status ? 'подключения' : 'отключения'} подписи (пин-код)`,
        },
        signingSimple: {
          label: 'Упрощенная подпись',
          hint: 'Действует всегда при отключенной подписи с пин-кодом',
          approval: status =>
            `Вы действительно хотите ${
              status ? 'отключить' : 'подключить'
            } упрощенную подпись?`,
          successfulText: status =>
            `Упрощенная подпись успешно ${status ? 'отключена' : 'подключена'}`,
          errorText: status =>
            `Ошибка ${
              status ? 'отключения' : 'подключения'
            } упрощенной подписи`,
        },
        signingCancellable: {
          label: 'Прервать подпись',
          approval: status =>
            `Вы действительно хотите ${
              status ? 'включить' : 'отключить'
            } прерывание подписи?`,
          successfulText: status =>
            `Прерывание подписи успешно ${status ? 'включено' : 'отключено'}`,
          errorText: status =>
            `Ошибка ${status ? 'включения' : 'отключения'} прерывания подписи`,
        },
        signingVideo: {
          label: 'Запись видео подписи',
          approval: status =>
            `Вы действительно хотите ${
              status ? 'включить' : 'отключить'
            } запись видео подписи?`,
          successfulText: status =>
            `Запись видео подписи успешно ${status ? 'включена' : 'отключена'}`,
          errorText: status =>
            `Ошибка ${
              status ? 'включения' : 'отключения'
            } записи видео подписи`,
        },
      };
    },
    switchDisabled() {
      return (inspType, signOption) =>
        !this.$can('U_OSS') ||
        ((signOption === 'signingSimple' || signOption === 'signingVideo') &&
          !this.sign[inspType].signingRequired);
    },
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', [
      'fetchSignSettings',
      'setSignSettings',
    ]),
    ...mapActions('REGISTRY', ['fetchProfileTemplates']),
    async fetch() {
      this.loading = true;
      await Promise.all([
        this.fetchSignSettings(),
        this.fetchProfileTemplates(),
      ]);
      this.loading = false;
    },

    handleSignSettingsUpdate(inspType, signOption) {
      if (this.switchDisabled(inspType, signOption)) return;

      let opt = signOption;
      if (signOption === 'signingSimple') opt = 'signingRequired';
      const newStatus = !this.sign[inspType][opt];
      const payload = cloneDeep(this.sign);
      payload[inspType][opt] = newStatus;
      // Записывать видео подписи можно только при включенной ПЭП
      if (!payload[inspType].signingRequired) {
        payload[inspType].signingVideo = false;
      }

      this.$openModal('prompt', {
        yes: 'Да',
        no: 'Отмена',
        title: 'Подтверждение',
        description: this.signOptions[signOption].approval(newStatus),
        messages: {
          successfulAction:
            this.signOptions[signOption].successfulText(newStatus),
          errorAction: this.signOptions[signOption].errorText(newStatus),
        },
        onSubmit: () =>
          this.$loading(
            this.setSignSettings({ sign: payload }),
            'handleSignSettingsUpdate',
          ),
      });
    },
  },
};
</script>
