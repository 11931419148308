<template>
  <List
    search-filter
    :available-actions="{
      showAction: $can('HSG_RD'),
      createAction: $can('HSTG_C'),
    }"
    @item:create="formModal"
  >
    <template #filters="query">
      <v-col cols="12" sm="4" md="6">
        <OrganizationSelect v-model="query.orgIds" multiple dense />
      </v-col>
    </template>

    <template #table.item.name_org="{ item }">
      <b>{{ item.name }}</b>
      <br />
      {{ item.organization.name }}
    </template>
  </List>
</template>

<script>
import ListFactory from '@/components/crud/ListFactory';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';
import { messages, entity, filters } from '../entity';

const List = ListFactory({
  storeModule: 'STRUCTURES/HOST_GROUPS',
  messages,
  entity,
  filters,
  viewRoute: 'structures:host_groups:item',
});

export default {
  components: { List, OrganizationSelect },
  methods: {
    formModal() {
      this.$openModal('structures/hostGroupForm');
    },
  },
};
</script>
