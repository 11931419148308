import processingApi from '@/api/services/processing';
import structApi from '@/api/services/structures';

import { createModule } from '@/utils/vuex/createModule';

const state = {
  helpFiles: null,
};

const getters = {
  helpFiles: state => state.helpFiles,
};

const mutations = {
  helpFiles: (state, files) => {
    state.helpFiles = files;
  },
  updateHelpFile: (state, file) => {
    const i = state.helpFiles.findIndex(el => el.key === file.key);
    state.helpFiles[i] = file;
  },
  createHelpFile: (state, file) => {
    state.helpFiles.push(file);
  },
  removeHelpFile: (state, key) => {
    const i = state.helpFiles.findIndex(el => el.key === key);
    state.helpFiles.splice(i, 1);
  },
};

const actions = {
  async fetchHelpFiles({ commit }) {
    const files = await structApi.getHelpFiles();
    await commit('helpFiles', files);
  },
  async uploadHelpFile({ commit }, { key, pdf, name }) {
    const response = await structApi.putHelpFile(key, pdf, name);
    commit('updateHelpFile', response);
    return response;
  },
  async createHelpFile({ commit }, { key, pdf, name }) {
    const response = await structApi.createHelpFile(key, pdf, name);
    commit('createHelpFile', response);
    return response;
  },

  async removeHelpFile({ commit }, { key, pdf, name }) {
    const response = await structApi.removeHelpFile(key, pdf, name);
    commit('removeHelpFile', key);
    return response;
  },

  fetchOrganizationPreviews: (_, params) =>
    structApi.getOrganizationPreviews(params),
  fetchOrganizationPreviewsBy: (_, params) =>
    structApi.getOrganizationPreviewsBy(params),

  fetchOrganizationCategoryPreviews: (_, params) =>
    structApi.getCategoryPreviews(params),
  fetchOrganizationCategoryPreviewsBy: (_, params) =>
    structApi.getCategoryPreviewsBy(params),

  fetchOrganizationGroupPreviews: (_, params) =>
    structApi.getOrganizationGroupPreviews(params),
  fetchOrganizationGroupPreviewsBy: (_, params) =>
    structApi.getOrganizationGroupPreviewsBy(params),

  fetchHostPreviews: (_, params) => structApi.getHostPreviews(params),
  fetchHostPreviewsBy: (_, params) => structApi.getHostPreviewsBy(params),

  fetchHostGroupPreviews: (_, params) => structApi.getHostGroupPreviews(params),
  fetchHostGroupPreviewsBy: (_, params) =>
    structApi.getHostGroupPreviewsBy(params),

  fetchPointPreviews: (_, params) => structApi.getPointPreviews(params),
  fetchPointPreviewsBy: (_, params) => structApi.getPointPreviewsBy(params),

  fetchEmployeeGroupPreviews: (_, params) =>
    structApi.getEmployeeGroupPreviews(params),
  fetchEmployeeGroupPreviewsBy: (_, params) =>
    structApi.getEmployeeGroupPreviewsBy(params),

  fetchMedicGroupPreviews: (_, params) =>
    processingApi.getMedicGroupPreviews(params),
  fetchMedicGroupPreviewsBy: (_, params) =>
    processingApi.getMedicGroupPreviewsBy(params),

  fetchOrgsAndOrgGroupsPreviews: (_, params) =>
    structApi.getOrgsAndOrgGroupsPreviews(params),
  fetchOrgsAndOrgGroupsPreviewsBy: (_, params) =>
    structApi.getOrgsAndOrgGroupsPreviewsBy(params),

  fetchHostGroupsAndPointsPreviews: (_, { type, id, ...params }) =>
    structApi.getHostGroupsAndPointsPreviews(type, id, params),
  fetchHostGroupsAndPointsPreviewsBy: (_, params) =>
    structApi.getHostGroupsAndPointsPreviewsBy(params),

  fetchCompositeEmployeeGroupPreviews: (_, { type, id, ...params }) =>
    structApi.getCompositeEmployeeGroupPreviews(type, id, params),
  fetchCompositeEmployeeGroupPreviewsBy: (_, params) =>
    structApi.getCompositeEmployeeGroupPreviewsBy(params),
};

export default createModule({ state, getters, mutations, actions });
