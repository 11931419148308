import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import RouteGuard from '@/components/RouteGuard';
import TEMPLATES from './store/list';
import TEMPLATES_ITEM from './store/item';

export default {
  namespaced: true,
  store: { TEMPLATES, TEMPLATES_ITEM },
  menu: [
    {
      title: 'Наборы шаблонов',
      action: 'NF_TF',
      name: 'templates',
      priority: 4,
    },
  ],
  routes: [
    {
      path: 'templates',
      name: 'templates',
      component: () => import('./view/list.vue'),
      meta: {
        title: 'Все наборы шаблонов',
        action: 'NF_TF',
      },
    },
    {
      path: 'templates/create',
      name: 'templates:create',
      component: RouteGuard({
        storeModule: 'NOTIFICATIONS/TEMPLATES_ITEM',
        component: () => import('./view/form.vue'),
        skipFetch: true,
      }),
      meta: { action: 'NF_TC' },
    },
    {
      path: 'templates/:key',
      name: 'templates:item',
      component: RouteGuard({
        storeModule: 'NOTIFICATIONS/TEMPLATES_ITEM',
        component: () => import('./view/form.vue'),
        primaryKeyName: 'key',
        primaryKeyType: 'string',
      }),
      meta: { action: 'NF_TU' },
    },
  ],
} as ModuleDefinition;
