var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('List',{attrs:{"search-filter":"","available-actions":{
    showAction: _vm.$can('OG_RD'),
    createAction: _vm.$can('ORGG_C'),
  }},on:{"item:create":_vm.formModal},scopedSlots:_vm._u([{key:"table.item.name",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(item.isHolding)?_c('v-chip',{attrs:{"small":""}},[_vm._v("Холдинг")]):_vm._e()]}},{key:"filters",fn:function(query){return [_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-select',{attrs:{"label":"Холдинги","items":_vm.booleanItems,"item-value":"value","item-text":"name","hide-details":"","outlined":"","dense":"","menu-props":{ offsetY: true }},model:{value:(query.isHolding),callback:function ($$v) {_vm.$set(query, "isHolding", $$v)},expression:"query.isHolding"}})],1)]}},{key:"table.item.isPrivate",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":!item.isPrivate ? 'green lighten-2' : 'red'}},on),[_vm._v(" mdi-lock"+_vm._s(!item.isPrivate ? '-open' : '')+"-outline ")])]}}],null,true)},[_vm._v(" "+_vm._s(!item.isPrivate ? 'Публичная' : 'Приватная')+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }