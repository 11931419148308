// api
import processingApi from '@/api/services/processing';
import structApi from '@/api/services/structures';
import notifApi from '@/api/services/notifications';
import authApi from '@/api/services/auth';
import schedulerApi from '@/api/services/scheduler';

import { createModule } from '@/utils/vuex/createModule';

const state = {
  item: null,
  org: null,
  medic: null,
  medicalCategories: null,
  subscriptions: null,
  jobs: null,
  qrcode: null,
};

const getters = {
  id: (_, { item }) => item?.id,
  item: state => state.item,
  org: state => state.org,
  medic: state => state.medic || {},
  medicalGroups: (_, { medic }) => medic.groups || [],
  medicalCategories: state => state.medicalCategories || [],
  subscriptions: state => state.subscriptions || [],
  jobs: state => state.jobs || [],
  qrcode: state => state.qrcode || {},
};

const mutations = {
  item: (state, value) => (state.item = value),
  medic: (state, value) => (state.medic = value),
  medicalCategories: (state, value) => (state.medicalCategories = value),
  org: (state, value) => (state.org = value),
  subscriptions: (state, value) => (state.subscriptions = value),
  jobs: (state, value) => (state.jobs = value),
  qrcode: (state, value) => (state.qrcode = value),
};

const actions = {
  async fetchCurrentAccount({ commit, rootGetters }) {
    const account = await authApi.getMyAccount();
    commit('item', account);

    const actions = [];

    if (rootGetters['AUTH/isMedic'])
      // Получаем мед данные только для аккаунта медика
      actions.push(
        processingApi.myMedic().then(medic => commit('medic', medic)),
      );

    actions.push(
      structApi
        .getOrganizationPreviewsBy([account.profile.orgId])
        // Должен вернуться один элемент - организация аккаунта
        .then(([org]) => commit('org', org)),
    );

    await Promise.all(actions);
  },

  async fetchMedicalCategories({ commit, getters }) {
    const { categories } = getters.medic;
    if (!categories.length) return;

    const medicalCategories = await structApi.getCategoryPreviewsBy(categories);
    commit('medicalCategories', medicalCategories);
  },

  async fetchSubscriptions({ commit }) {
    const subs = await notifApi.getMySubscriptions();
    commit('subscriptions', subs);
  },

  async fetchJobs({ commit }) {
    const jobs = await schedulerApi.getMyJobs();
    commit('jobs', jobs);
  },

  resetMyPassword() {
    return authApi.resetMyPassword();
  },

  // qrcode logic
  async fetchQrcode({ commit, getters }) {
    const res = await authApi.getQrcode(getters.id);
    commit('qrcode', res);
  },

  async postQrcode({ getters, commit }) {
    const res = await authApi.postQrcode(getters.id);
    commit('qrcode', res);
  },

  async deleteQrcode({ getters, commit }) {
    const res = await authApi.deleteQrcode(getters.id);
    commit('qrcode', res);
  },
};

export default createModule({ state, getters, mutations, actions });
