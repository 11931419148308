<template>
  <ActionsMenu>
    <NewStatusChanger
      instance="организацию"
      :item="org"
      store-module="STRUCTURES/ORGANIZATION_ITEM"
      :can-enable="$can('ORG_EN')"
      :can-disable="$can('ORG_DS')"
    />

    <template v-if="org.isMedical && $can('NTR_ORG')">
      <ActionsMenuItem
        :title="niiatTitle"
        :subtitle="niiatSubtitle"
        :disabled="org.niiatStatus.registered || $wait('niiatRegistration')"
        @click="niiatRegistrationHandle"
      >
        <template v-if="$wait('niiatRegistration')" #icon>
          <v-progress-circular indeterminate :size="20" :width="2" />
        </template>
      </ActionsMenuItem>
    </template>

    <template v-if="!EMPLOYEE_EXTRAS_DISABLE">
      <ActionsMenuItem
        v-if="$can('EXT_I') && !importForbidden"
        title="Импорт дополнительных полей"
        subtitle="Импортировать дополнительные поля работников"
        @click="openImportExtrasModal"
      >
        <template #icon>
          <v-icon>mdi-file-import-outline</v-icon>
        </template>
      </ActionsMenuItem>
    </template>

    <ActionsMenuItem
      v-if="$can('MT_IR')"
      title="Импорт мед. исследований"
      subtitle="Импортировать медицинские исследования работников"
      @click="openImportMedicalTestsModal"
    >
      <template #icon>
        <v-icon>mdi-file-import-outline</v-icon>
      </template>
    </ActionsMenuItem>

    <ActionsMenuItem
      v-if="$can('AF_GR')"
      title="Сброс подписи всех соглашений"
      @click="openResetAgreementsModal"
    >
      <template #icon>
        <v-icon>mdi-undo-variant</v-icon>
      </template>
    </ActionsMenuItem>

    <ActionsMenuItem
      v-if="$can('ORG_D')"
      title="Удалить организацию"
      subtitle="Действие нельзя отменить"
      @click="openDeleteModal"
    >
      <template #icon>
        <v-icon>mdi-delete-outline</v-icon>
      </template>
    </ActionsMenuItem>
  </ActionsMenu>
</template>

<script>
import env from '@/plugins/env';
import ActionsMenu from '@/components/ui/ActionsMenu';
import ActionsMenuItem from '@/components/ui/ActionsMenuItem';
import NewStatusChanger from '@/components/controls/NewStatusChanger';
import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';

export default {
  components: { ActionsMenu, ActionsMenuItem, NewStatusChanger },
  mixins: [waitable],
  props: {
    org: { type: Object, required: true },
  },

  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', [
      'extrasImportTemplateURL',
      'medicalTestsImportTemplateURL',
    ]),

    EMPLOYEE_EXTRAS_DISABLE() {
      return env.get('VUE_APP_EMPLOYEE_EXTRAS_DISABLE');
    },

    niiatTitle() {
      return this.org.niiatStatus.registered
        ? 'МО зарегистрирована в АИС ЕРП'
        : 'Внести запись о МО в АИС ЕРП';
    },
    niiatSubtitle() {
      return this.org.niiatStatus.registered ? '' : 'Действие нельзя отменить';
    },
    importForbidden() {
      return this.org?.settings?.employeeSync?.employeeModify === false;
    },
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', [
      'importExtras',
      'importMedicalTests',
      'deleteSingleItem',
      'niiatRegistration',
      'resetAgreements',
    ]),

    openImportExtrasModal() {
      const props = {
        messages: {
          title: 'Импорт дополнительных полей работников',
          actionButton: 'Импортировать',
          successfulAction:
            'Дополнительные поля работников успешно импортирован',
          errorAction:
            'Произошла ошибка импортирования дополнительных полей работников',
          failedRowsTitle: 'Ошибки в импортируемом файле:',
          instructionTitle: 'Инструкция',
          instruction: [],
        },
        entity: [],
        linkToImportTemplate: this.extrasImportTemplateURL,
        onSubmit: data => this.importExtras(data.file),
      };

      this.$openModal(() => import('@/components/crud/ImportModal.vue'), props);
    },

    openImportMedicalTestsModal() {
      const props = {
        messages: {
          title: 'Импорт медицинских исследований работников',
          actionButton: 'Импортировать',
          successfulAction:
            'Медицинские исследования работников успешно импортирован',
          errorAction:
            'Произошла ошибка импортирования медицинских исследований работников',
          failedRowsTitle: 'Ошибки в импортируемом файле:',
          instructionTitle: 'Инструкция',
          instruction: [],
        },
        entity: [],
        linkToImportTemplate: this.medicalTestsImportTemplateURL,
        onSubmit: data => this.importMedicalTests(data.file),
      };

      this.$openModal(() => import('@/components/crud/ImportModal.vue'), props);
    },

    openDeleteModal() {
      this.$openModal('prompt', {
        yes: 'Удалить',
        no: 'Отмена',
        title: 'Удалить организацию',
        description: `Вы уверены, что хотите удалить организацию?`,
        onSubmit: () =>
          this.deleteSingleItem(this.org.id).then(_ =>
            this.$router.push({ name: 'structures:organizations' }),
          ),
      });
    },

    async niiatRegistrationHandle() {
      await this.$loadingNotify(
        this.niiatRegistration(),
        'niiatRegistration',
        'Ошибка внесения записи о МО в АИС ЕРП. Повторите попытку',
      );
    },

    openResetAgreementsModal() {
      this.$openModal('prompt', {
        yes: 'Да',
        no: 'Нет',
        title: 'Сбросить подписи всех соглашений',
        description:
          'Вы уверены, что хотите сбросить подпись всех соглашений по всем работникам организации?',
        messages: {
          successfulAction: 'Подписи соглашений успешно сброшены',
          errorAction: 'Произошла ошибка сброса подписей соглашений',
        },
        onSubmit: this.resetAgreements,
      });
    },
  },
};
</script>
