import Vue from 'vue';
import axios from 'axios';

// api
import processingApi from '@/api/services/processing';
import structApi from '@/api/services/structures';
import bansApi from '@/api/services/bans';
import historyApi from '@/api/services/history';
import medrecApi from '@/api/services/medrec';
import semaphoreApi from '@/api/services/semaphore';
import surveyApi from '@/api/services/survey';

import { createModule } from '@/utils/vuex/createModule';
import env from '@/plugins/env';
import { FETCH_STATUS } from '@/utils/constants';
import { fetchFactory } from '@/utils/vuex/fetchFactory';

const SEMAPHORE_DISABLED = env.get('VUE_APP_SEMAPHORE_DISABLE');

const fetchRecord = fetchFactory({
  field: 'record',
  initialValue: null,
  customGetters: {
    validCertificates: (_, getters) => getters.record?.validCertificates,
    comments: (_, getters) => getters.record?.comments,
  },
  action: (_, { employeeId, cancelSignal }) =>
    medrecApi
      .getEmployeeRecord(employeeId, cancelSignal)
      .then(({ validCertificates, comments }) => ({
        validCertificates,
        comments,
      })),
});

const fetchBans = fetchFactory({
  field: 'bans',
  initialValue: null,
  action: (_, { employeeId, cancelSignal }) =>
    bansApi
      .getEmployeeBans(
        employeeId,
        { reasons: ['ALCOHOL', 'INJURIES', 'ILLNESS'], limit: 5, page: 1 },
        cancelSignal,
      )
      .then(({ items }) => items),
});

const fetchAverageMeasurements = fetchFactory({
  field: 'averageMeasurements',
  initialValue: null,
  action: (_, { employeeId, cancelSignal }) =>
    historyApi
      .getEmployeeHistory(employeeId, cancelSignal)
      .then(({ average }) => average),
});

const fetchSurveys = fetchFactory({
  field: 'surveys',
  initialValue: [],
  action: (_, params) =>
    surveyApi.getEmployeeSurveys(params).then(({ items }) => items),
});

const state = {
  inspectionStatus: FETCH_STATUS.IDLE,
  inspection: null,
  videoMatch: null,
  assistantDisabled: false,
  canceller: null,
};

const getters = {
  inspectionStatus: state => state.inspectionStatus,
  isFetchingInspection: state =>
    state.inspectionStatus === FETCH_STATUS.LOADING,
  isLoadedInspection: state =>
    state.inspectionStatus === FETCH_STATUS.SUCCEEDED,
  isErrorInspection: state => state.inspectionStatus === FETCH_STATUS.ERROR,

  inspection: state => state.inspection,
  id: state => state.inspection?.id,
  assistance: state => state.inspection?.assistance || {},
  video: state => state.inspection?.video,
  timestamps: state => state.inspection?.timestamps,
  steps: state => state.inspection?.steps,
  type: state => state.inspection?.type,
  employee: state => state.inspection?.employee,
  host: state => state.inspection?.host,
  organization: state => state.inspection?.organization,
  boundaries: state => state.inspection?.boundaries,
  isRetry: state => state.inspection?.isRetry,
  attempt: state => state.inspection?.attempt,

  canceller: state => state.canceller,
  videoMatch: state => state.videoMatch,
  assistantDisabled: state => state.assistantDisabled,
};

const mutations = {
  clean: state => {
    fetchRecord.clean(state);
    fetchBans.clean(state);
    fetchAverageMeasurements.clean(state);
    fetchSurveys.clean(state);

    state.inspectionStatus = null;
    state.inspection = null;

    state.canceller = null;
    state.videoMatch = null;
    state.assistantDisabled = false;
  },

  inspectionStatus: (state, value) => (state.inspectionStatus = value),
  inspection: (state, value) => (state.inspection = value),
  addComment: (state, value) => {
    state.record = {
      ...state.record,
      comments: [value, ...state.record.comments],
    };
  },
  employee: (state, value) => Vue.set(state.inspection, 'employee', value),
  videoMatch: (state, value) => (state.videoMatch = value),
  assistantDisabled: (state, value) => (state.assistantDisabled = value),

  canceller: (state, value) => (state.canceller = value),
  cancellerAbort: state => {
    if (state.canceller) {
      state.canceller.abort();
      state.canceller = null;
    }
  },
};

const actions = {
  clean({ commit }) {
    commit('cancellerAbort');
    commit('clean');
  },

  /** Using on active medcab page */
  async fetchInspection({ getters, commit }, { id, medcabType }) {
    // cancel all fetchers if medic get new inspection
    if (getters.canceller) commit('cancellerAbort');

    commit('inspectionStatus', FETCH_STATUS.LOADING);
    const fetchInspect =
      medcabType === 'active'
        ? processingApi.getInspectionActive
        : processingApi.getInspectionPassive;
    try {
      const inspection = await fetchInspect(id);

      // NOTE: функционал для тестировщиков, чтобы они в этот момент могли
      // успеть поставить паузу и получить ивент "непреднамеренный отказ"
      if (window.delay && typeof window.delay === 'number') {
        // max value - 5, protection from hijack
        if (window.delay > 5) window.delay = 5;
        // eslint-disable-next-line no-console
        console.log('Жмакай паузу, осмотр загружен (￣ー￣)ｂ');
        await new Promise(resolve => setTimeout(resolve, window.delay * 1000));
        // eslint-disable-next-line no-console
        console.log('Время вышло (=⌒‿‿⌒=)');
      }

      commit('inspection', inspection);
      commit('inspectionStatus', FETCH_STATUS.SUCCEEDED);
      return inspection;
    } catch (err) {
      commit('inspectionStatus', FETCH_STATUS.ERROR);
      throw err;
    }
  },

  async pullVideoMatch({ getters, commit, dispatch }, { id, signal }) {
    if (id !== getters.id || !getters.isLoadedInspection) return;

    let result;
    try {
      result = await semaphoreApi.matchVideoForInspection(id, signal);
      commit('videoMatch', result);
    } catch (err) {
      if (!axios.isCancel(err)) throw err;
    }

    if (result?.status === 'pending')
      setTimeout(() => dispatch('pullVideoMatch', { id, signal }), 500);
  },

  async fetchDetails({ commit, dispatch, getters }, { id }) {
    const params = prepareDetailsParams({ commit, getters });

    const surveyParams = {
      orderBy: 'finishedAt',
      orderType: 'DESC',
      refererId: getters.inspection.employee?.id,
      inspectionId: getters.id,
      page: 1,
      limit: 5,
    };

    dispatch('fetchRecord', params);
    dispatch('fetchBans', params);
    dispatch('fetchAverageMeasurements', params);
    dispatch('fetchSurveys', surveyParams);

    if (!SEMAPHORE_DISABLED)
      dispatch('pullVideoMatch', { id, signal: params.cancelSignal }).catch(
        err => {
          console.error('semaphore: ', err);
        },
      );
  },

  async refreshDetails({ commit, dispatch, getters }, field) {
    const firstLetterUpperCaseKey =
      field[0].toUpperCase() + field.slice(1, field.length);
    const params = prepareDetailsParams({ commit, getters });
    try {
      await dispatch(`fetch${firstLetterUpperCaseKey}`, params);
    } catch (err) {
      console.warn(err);
    }
  },

  async sendComment({ getters, commit }, content) {
    const comment = await medrecApi.sendComment({
      id: getters.employee.id,
      content,
      reference: { type: 'inspection', id: getters.id },
    });

    commit('addComment', comment);
  },

  async archivePhoto({ getters, commit }, id) {
    const response = await structApi.employeeArchivePhoto(id);

    if (getters.isLoadedInspection) commit('employee', response);
    return response;
  },

  disableAssistance({ commit }) {
    commit('assistantDisabled', true);
  },
};

function prepareDetailsParams({ commit, getters }) {
  const inspection = getters.inspection;
  if (!inspection) throw Error('Осмотр не загружен');

  const canceller = new AbortController();
  commit('canceller', canceller);

  const employeeId = inspection.employee?.id;
  return { employeeId, cancelSignal: canceller.signal };
}

export default createModule(
  fetchRecord,
  fetchBans,
  fetchAverageMeasurements,
  fetchSurveys,
  { state, getters, mutations, actions },
);
