import authApi from '@/api/services/auth';

import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

// babel wildcard needs explicit paths
import * as subModules from './submodules';

const state = {};

const getters = {
  id: state => state.singleItem.id,
};

const mutations = {
  changeBindings: (state, bindings) => {
    state.singleItem.bindings = bindings || {};
  },
};

const actions = {
  async changeStatus({ getters, dispatch }, isEnabled) {
    const action = isEnabled ? 'accountEnable' : 'accountDisable';
    await authApi[action](getters.id);
    dispatch('singleItemUpdate');
  },

  async changeEmail({ dispatch, getters }, email) {
    await authApi.updateEmail(getters.id, email);
    dispatch('singleItemUpdate');
  },

  async changeProfile({ getters, dispatch }, profile) {
    const { patronymic } = profile;
    if (patronymic === '') profile.patronymic = null;

    await authApi.profileUpdate({ id: getters.id, profile });
    dispatch('singleItemUpdate');
  },

  async changeBindings({ commit }, { id, bindings }) {
    const newBindings = {
      ...bindings,
      inspections: bindings.inspections.filter(item => item.parent?.id),
    };
    const response = await authApi.changeBindings(id, newBindings);
    commit('changeBindings', response);
  },

  async resetPassword({ getters }) {
    await authApi.resetPassword(getters.id);
  },
};

export default createModule(
  crudSingleFactory({
    fetchMethod: authApi.accountGet,
    deleteMethod: authApi.accountDelete,
    updateMethod: authApi.profileUpdate,
    linkedList: 'ADMIN/USERS',
  }),
  ...Object.values(subModules),
  { state, getters, mutations, actions },
);
