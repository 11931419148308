<template>
  <Card title="Опрос работников" dense>
    <AsyncWrapper :handler="fetchSurveys">
      <v-simple-table dense>
        <tbody>
          <tr v-for="type in surveyKeys" :key="type">
            <td class="grey--text px-4 py-3">
              {{ surveyTypes.schemes[type].name }}
            </td>
            <td class="px-4 py-3" style="width: 50px">
              <div class="d-flex align-center">
                <v-tooltip v-if="$can('SU_OGE')" left>
                  <template #activator="{ attrs, on }">
                    <v-btn
                      style="display: inline"
                      class="mr-3"
                      v-bind="attrs"
                      icon
                      v-on="on"
                      @click="editSurveyModal(type)"
                    >
                      <v-icon size="18">fa-edit</v-icon>
                    </v-btn>
                  </template>
                  Редактировать
                </v-tooltip>
                <v-switch
                  :input-value="surveys.surveySettings[type].isEnabled"
                  class="ma-0 pa-0"
                  inset
                  :disabled="!$can('SU_OGE')"
                  hide-details
                  :loading="$wait('handlerChangeSurveyStatus' + type)"
                  @mousedown="handlerChangeSurveyStatus(type)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </AsyncWrapper>
  </Card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';

import AsyncWrapper from '@/components/AsyncWrapper';
import Card from '@/components/ui/Card';

export default {
  components: { AsyncWrapper, Card },
  mixins: [waitable],

  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', ['surveys', 'surveyTypes']),
    surveyKeys() {
      return Object.keys(this.surveys?.surveySettings || {});
    },
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', [
      'fetchSurveys',
      'changeSurveyStatus',
      'editSurveys',
    ]),

    handlerChangeSurveyStatus(key) {
      const newStatus = !this.surveys.surveySettings[key].isEnabled;
      const name = this.surveyTypes.schemes[key].name;
      this.$openModal('prompt', {
        yes: 'Да',
        no: 'Отмена',
        title: 'Подтверждение',
        description: `Вы действительно хотите ${
          newStatus ? 'подключить' : 'отключить'
        } опрос '${name}'?`,
        messages: {
          successfulAction: `Опрос '${name}' успешно ${
            newStatus ? 'подключен' : 'отключен'
          }`,
          errorAction: `Ошибка ${
            newStatus ? 'подключения' : 'отключения'
          } опроса '${name}'`,
        },
        onSubmit: () =>
          this.$loading(
            this.changeSurveyStatus(key),
            'handlerChangeSurveyStatus' + key,
          ),
      });
    },

    editSurveyModal(type) {
      const name = this.surveyTypes.schemes[type].name;
      this.$openModal('structures/editSurvey', {
        type,
        onSubmit: async payload => {
          await this.$loadingNotify(
            this.editSurveys(payload),
            'savingSurveys',
            `Ошибка при сохранении настроек опроса "${name}"`,
            `Настройки опроса "${name}" успешно сохранены`,
          );
        },
      });
    },
  },
};
</script>
