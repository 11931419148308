<template>
  <v-layout :class="layoutClass" column align-center justify-center>
    <v-img
      v-if="item.photo && !error"
      :src="item.photo.url"
      :height="height"
      contain
      @error="error = true"
    >
      <template #placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="primary" />
        </v-row>
      </template>
      <v-layout
        v-if="hasActions"
        class="employee-photo_actions pa-2"
        justify-center
      >
        <v-btn
          v-if="canUpload"
          color="primary"
          text
          small
          @click="$emit('upload')"
        >
          Загрузить новую
        </v-btn>
        <v-btn
          v-else-if="canArchive"
          color="primary"
          text
          small
          @click="archivePhotoModal"
        >
          Архивировать
        </v-btn>
      </v-layout>
      <div v-if="!item.photo.isActive" class="employee-photo_archived">
        Фото архивировано {{ archivedAt }}
      </div>
    </v-img>
    <div v-else>
      <template v-if="error">
        <div class="text-h5 text-center">Ошибка загрузки фотографии</div>
        <div class="pt-2 text-center">
          <v-btn
            v-if="canUploadIgnoreActive"
            color="primary"
            outlined
            @click="$emit('upload')"
          >
            Загрузить
          </v-btn>
        </div>
      </template>
      <template v-else>
        <div class="text-h5 text-center">Нет фото</div>
        <div class="pt-2">
          <v-btn
            v-if="canUpload"
            color="primary"
            outlined
            @click="$emit('upload')"
          >
            Загрузить
          </v-btn>
        </div>
      </template>
    </div>
  </v-layout>
</template>

<script>
import { date } from '@/utils/convert';

export default {
  props: {
    item: { type: Object, required: true },
    height: { type: String, default: null },
    handleArchive: { type: Function, default: null },
  },
  data: () => ({
    error: false,
  }),

  computed: {
    archivedAt() {
      return date(this.item.photo.archivedAt);
    },

    layoutClass() {
      return `${
        this.$store.getters.themeIsDark ? 'darken-2' : 'lighten-2'
      } grey employee-photo`;
    },

    canUpload() {
      return (
        !this.item.isTest &&
        !this.item.photo?.isActive &&
        this.$can('EMPP_U') &&
        this.$listeners.upload
      );
    },
    canUploadIgnoreActive() {
      return !this.item.isTest && this.$can('EMPP_U') && this.$listeners.upload;
    },
    canArchive() {
      return (
        !this.item.isTest &&
        this.item.photo.isActive &&
        this.$can('EMPP_A') &&
        this.handleArchive
      );
    },
    hasActions() {
      return this.canUpload || this.canArchive;
    },
  },

  watch: {
    'item.photo.url'() {
      this.error = false;
    },

    error(val) {
      this.$emit('error', val);
    },
  },

  methods: {
    archivePhotoModal() {
      this.$openModal('employees/archivePhoto', {
        item: this.item,
        submitArchive: this.handleArchive,
      });
    },
  },
};
</script>

<style lang="scss">
.employee-photo {
  min-height: 250px;

  &_actions {
    transition: transform 0.3s ease-in-out;
    transform: translateY(-50px);
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
  }

  &:hover &_actions {
    transform: translateY(0px);
  }

  &_archived {
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    font-weight: 300;
    position: absolute;
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    bottom: 0;
    font-size: 1rem;
  }
}
</style>
