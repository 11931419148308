interface TimerParams {
  /** Время жизни таймера в секундах */
  seconds: number;
  /** Callback на каждый tick таймера */
  onTick?: (sec: number) => void;
  /** Callback при истечении времени */
  onTimeout?: () => void;
  /** Задержка между tick */
  delay?: number;
  /** Функция для проверки активности */
  isStopped?: () => boolean;
}

interface TimerInterface {
  /** Результат жизни таймера */
  promise: Promise<unknown>;
  /** Функция остановки таймера */
  stop: () => void;
}

/**
 * Функция создания таймера
 */
export function timer({
  seconds,
  onTick,
  onTimeout,
  delay = 300,
  isStopped,
}: TimerParams): TimerInterface {
  const endTime = new Date().getTime() + seconds * 1000;
  let isForceStopped = false;
  const stop = () => {
    isForceStopped = true;
  };

  const promise = new Promise((resolve, reject) => {
    const tick = () => {
      if (isForceStopped) {
        resolve(undefined);
        return;
      }

      const isStoppedResult = isStopped && isStopped();
      if (isStoppedResult) {
        if (typeof isStoppedResult !== 'boolean') {
          return reject(
            new Error(
              `Функция isStopped должна возвращать boolean, получен ${typeof isStoppedResult}`,
            ),
          );
        }
        resolve(undefined);
        return;
      }

      const currentTime = new Date().getTime();
      const diff = Math.ceil((endTime - currentTime) / 1000);
      if (diff > 0) {
        try {
          if (onTick) onTick(diff);
        } catch (err) {
          reject(err);
          return;
        }

        setTimeout(tick, delay);
      } else {
        if (onTick) onTick(0); // Set zero on timer
        if (onTimeout) onTimeout();
        resolve(undefined);
      }
    };

    setTimeout(tick, delay);
  });

  return {
    promise,
    stop,
  };
}
