<script>
import { mapActions, mapGetters } from 'vuex';
import { secondsToWordTime } from '@/utils/convert';

const MAX_DIFF_WITH_SERVER_IN_SEC = 10;

export default {
  computed: {
    ...mapGetters('TIME', ['client', 'server', 'ping', 'pingInSec']),

    slowInternet() {
      return this.pingInSec > 2;
    },
  },

  watch: {
    slowInternet(val) {
      if (val) {
        this.$notify({
          group: 'note',
          type: 'warn',
          duration: 60_000,
          title: 'Медленный интернет',
          text:
            'Обнаружены большие задержки при выполнении операций' +
            ` (~ ${this.pingInSec} сек). Для стабильной работы системы нужен` +
            ' более быстрый интернет',
        });
      }
    },
  },

  async mounted() {
    this.checkDateTime();
  },

  methods: {
    ...mapActions('TIME', ['fetchTime']),

    async checkDateTime(repeated = false) {
      await this.fetchTime();

      const diff = Math.ceil(
        Math.abs(this.server + this.ping / 2 - this.client) / 1000,
      );

      // Сравниваем с максимальным отклонением от серверного времени,
      // от этого зависит за сколько секунд до истечения будет обновлен токен
      if (diff > MAX_DIFF_WITH_SERVER_IN_SEC) {
        // Если первый раз была получена ошибка, то это может быть задержка
        // отправки запроса в браузере. Отправляем сразу повторный.
        if (!repeated) return this.checkDateTime(true);

        this.notifyAboutDifferentTime(diff);
      }
    },

    async notifyAboutDifferentTime(diff) {
      const diffAsWords = secondsToWordTime(diff);
      this.$notify({
        group: 'note',
        type: 'error',
        duration: -1,
        title: 'Обнаружена разница во времени',
        text:
          `Разница с сервером составляет: <br/>${diffAsWords}<br/><br/>` +
          'Для стабильной работы системы необходимо настроить ' +
          'системное время на автоматическое обновление и выставить ' +
          'корректную временную зону.<br/><br/>' +
          'При возникновении проблем обратитесь в техническую ' +
          'поддержку вашей организации',
      });

      console.error(
        `Time differences between client and server: ${diff} s`,
        `\nclient: ${new Date(this.client).toString()}`,
        `\nserver: ${new Date(this.server).toString()}`,
        `\nping: ${this.ping}`,
      );
    },
  },

  render() {
    return null;
  },
};
</script>
