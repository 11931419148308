import structApi from '@/api/services/structures';
import gateApi from '@/api/services/gate';
import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

// babel wildcard needs explicit paths
import * as subModules from './submodules';

const state = {
  parentOrgProfile: null,
};

const getters = {
  id: state => state.singleItem.id,
  parentOrgProfile: state => state.parentOrgProfile,
};

const mutations = {
  parentOrgProfile: (state, value) => (state.parentOrgProfile = value),
};

const actions = {
  fetchSingle: async ({ commit, dispatch }, id) => {
    try {
      commit('singleFetching');
      const response = await structApi.hostGet(id);
      await dispatch('fetchFormFactors');
      await dispatch('fetchOrgProfile', {
        orgId: response.organization.id,
        formFactor: response.formFactor,
      });
      commit('singleFetched', response);
    } catch (error) {
      commit('singleErrorFetched', error);
      throw error;
    }
  },

  async updateGroups({ getters, dispatch }, groups) {
    await structApi.setHostGroups(getters.singleItem.id, groups);
    dispatch('singleItemUpdate');
  },

  async changeStatus({ getters, dispatch }, isActive) {
    const action = isActive ? 'hostEnable' : 'hostDisable';
    await structApi[action](getters.singleItem.id);
    dispatch('singleItemUpdate');
  },

  async fetchOrgProfile({ commit }, { orgId, formFactor }) {
    const response = await gateApi.getOrgConfigs(orgId);
    const profile = response.find(item => item.formFactor === formFactor);
    commit('parentOrgProfile', profile);
  },
};

export default createModule(
  crudSingleFactory({
    fetchMethod: structApi.hostGet,
    updateMethod: structApi.hostUpdate,
    linkedList: 'STRUCTURES/HOSTS',
  }),
  ...Object.values(subModules),
  { state, getters, mutations, actions },
);
