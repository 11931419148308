<template>
  <ItemPageLayout back-route="scheduler:jobs" :title="singleItem.name">
    <Card title="Информация" dense>
      <DefinitionList :value="tableData" />
      <v-divider />

      <div v-if="singleItem || singleItem.params" class="ma-4 my-8">
        <v-text-field
          v-model="singleItem.params.subject"
          label="Тема письма"
          class="mb-4"
          outlined
          hide-details="auto"
          readonly
        />
        <v-textarea
          v-model="singleItem.params.body"
          label="Содержимое письма"
          class="mb-4"
          rows="8"
          outlined
          hide-details="auto"
          readonly
        />
      </div>

      <v-divider />
      <div class="ma-4">
        <h3 class="mb-4">Сводки</h3>
        <NoDataAlert v-if="!singleItem.params.summaries.length">
          Сводок нет
        </NoDataAlert>
        <AttachmentItem
          v-for="(summary, index) in singleItem.params.summaries"
          :key="index"
          :title="
            summaryByKey(summary.key)
              ? summaryByKey(summary.key).name
              : summary.key
          "
          :description="summary.filters"
          class="mb-4"
        />
      </div>
      <v-divider />
      <div class="ma-4">
        <h3 class="mb-4">Вложения</h3>
        <NoDataAlert v-if="!singleItem.params.attachments.length">
          Вложений нет
        </NoDataAlert>
        <AttachmentItem
          v-for="(attachment, index) in singleItem.params.attachments"
          :key="index"
          :title="
            reportByKey(attachment.key)
              ? reportByKey(attachment.key).name
              : attachment.key
          "
          :description="attachment.filters"
          class="mb-4"
        />
      </div>
      <template #action>
        <CardActionBtn
          v-if="$can('SJ_U')"
          label="Редактировать"
          :to="{
            name: 'scheduler:jobs:edit',
            params: { id: singleItem.id },
          }"
        />
      </template>
    </Card>

    <Card title="Последние 10 выполнений" dense>
      <AsyncWrapper :handler="fetchLastExecutions">
        <ExecutionsList :value="lastExecutions" />
      </AsyncWrapper>
    </Card>

    <template #aside>
      <ActionsMenu>
        <ActionsMenuItem
          v-if="$can('SJ_E')"
          title="Выполнить немедленно"
          @click="executeModal"
        >
          <template #icon><v-icon>mdi-clock-start</v-icon></template>
        </ActionsMenuItem>
        <NewStatusChanger
          instance="задачу"
          :item="singleItem"
          :status="!!singleItem.activatedAt"
          store-module="SCHEDULER/JOB_ITEM"
          :can-enable="$can('SJ_A')"
          :can-disable="$can('SJ_DA')"
        />
        <ActionsMenuItem
          v-if="$can('SJ_D')"
          title="Удалить задачу"
          subtitle="Действие нельзя отменить"
          @click="openDeleteModal"
        >
          <template #icon><RemoveCirc /></template>
        </ActionsMenuItem>
      </ActionsMenu>
    </template>
  </ItemPageLayout>
</template>

<script>
import waitable from '@/utils/mixins/waitable';
import validation from '@/utils/validation';

import ItemPageLayout from '@/components/layouts/ItemPageLayout';
import Card from '@/components/ui/Card';
import DefinitionList from '@/components/DefinitionList';
import ActionsMenu from '@/components/ui/ActionsMenu';
import ActionsMenuItem from '@/components/ui/ActionsMenuItem';
import NewStatusChanger from '@/components/controls/NewStatusChanger';
import AsyncWrapper from '@/components/AsyncWrapper';
import ExecutionsList from '../components/ExecutionsList';
import AttachmentItem from '../components/AttachmentItem';

// icons
import RemoveCirc from '@/components/icons/remove-circle-outline';

import { RU_TIMEZONES } from '@/utils/constants';
import {
  SCHEDULE_TYPES,
  SCHEDULE_WEEKLY_UNITS,
} from '@/modules/scheduler/constants';
import { mapActions, mapGetters } from 'vuex';
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';
import NoDataAlert from '@/components/ui/NoDataAlert';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';

export default {
  components: {
    NoDataAlert,
    ItemPageLayout,
    Card,
    DefinitionList,
    ActionsMenu,
    ActionsMenuItem,
    NewStatusChanger,
    RemoveCirc,
    AsyncWrapper,
    ExecutionsList,
    AttachmentItem,
    CardActionBtn,
  },
  mixins: [waitable, validation, routeGuardMixin],

  computed: {
    ...mapGetters('AVAILABLE_REPORTS', ['reportByKey']),
    ...mapGetters('AVAILABLE_SUMMARIES', ['summaryByKey']),
    ...mapGetters('SCHEDULER/JOB_ITEM', ['lastExecutions', 'receivers']),

    tableData() {
      const { params } = this.singleItem;
      const data = [
        { label: 'ID', value: this.singleItem.id },
        { label: 'Название', value: this.singleItem.name },
        {
          label: 'В архиве',
          value: params.isArchive
            ? `Да, ${params.password ? 'с паролем' : 'без пароля'}`
            : 'Нет',
        },
        {
          label: 'Расписание',
          value: this.renderSchedule(this.singleItem.schedule),
        },
      ];

      if (this.$can('AC_PRW')) {
        data.splice(2, 0, {
          label: 'Получатели',
          value: this.receivers.map(receiver => receiver.email).join(', '),
        });
      }
      return data;
    },
  },

  created() {
    this.$loadingNotify(
      this.$store.dispatch('AVAILABLE_REPORTS/fetchReports'),
      'fetchReports',
      'Не удалось загрузить отчеты',
    );

    this.$loadingNotify(
      this.$store.dispatch('AVAILABLE_SUMMARIES/fetchSummaries'),
      'fetchSummaries',
      'Не удалось загрузить сводки',
    );

    if (this.$can('AC_PRW')) {
      this.$loadingNotify(
        this.$store.dispatch('SCHEDULER/JOB_ITEM/fetchReceiverPreviews'),
        'fetchReceivers',
        'Не удалось загрузить получателей',
      );
    }
  },

  methods: {
    ...mapActions('SCHEDULER/JOB_ITEM', ['fetchLastExecutions', 'execute']),

    renderSchedule(schedule) {
      const typeName = SCHEDULE_TYPES.find(
        item => item.value === schedule.type,
      ).text;

      const timezone = RU_TIMEZONES.find(
        item => item.value === this.singleItem.timezone,
      )?.text;

      const time = `, в ${schedule.time} (${timezone})`;

      switch (schedule.type) {
        case 'DAILY':
          return typeName + time;
        case 'WEEKLY':
          const day = SCHEDULE_WEEKLY_UNITS.find(
            item => item.value === schedule.day,
          ).text;
          return typeName + ` по ${day}` + time;
        case 'MONTHLY':
          return typeName + ` по ${schedule.day} числам` + time;
      }
    },

    executeModal() {
      this.$openModal('prompt', {
        yes: 'Выполнить',
        no: 'Отмена',
        title: 'Выполнить задачу немедленно?',
        onSubmit: () =>
          this.execute().then(item =>
            this.$router.push({
              name: 'scheduler:executions:item',
              params: { id: item.id },
            }),
          ),
      });
    },

    openDeleteModal() {
      this.$openModal('prompt', {
        yes: 'Удалить',
        no: 'Отмена',
        title: 'Удалить задачу',
        description: `Вы уверены, что хотите удалить задачу?`,
        onSubmit: () =>
          this.$store
            .dispatch('SCHEDULER/JOB_LIST/deleteListItem', this.singleItem.id)
            .then(_ => this.$router.push({ name: 'scheduler:jobs' })),
      });
    },
  },
};
</script>
