var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ItemPageLayout',{attrs:{"back-route":"structures:host_groups","title":_vm.singleItem.name},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('ActionsMenu',[(_vm.$can('HSTG_D'))?_c('ActionsMenuDelete',{attrs:{"id":_vm.singleItem.id,"store-module":"STRUCTURES/HOST_GROUPS","back-route":"structures:host_groups","title":"Удалить группу ПАК","description":"Вы уверены, что хотите удалить группу?","disabled":!!_vm.singleItem.itemsCount,"subtitle":_vm.singleItem.itemsCount ? 'Нельзя удалить, в группе есть ПАК' : null}}):_vm._e()],1)]},proxy:true},(_vm.$can('HST_R'))?{key:"innerList",fn:function(){return [_c('List',{attrs:{"search-filter":"","available-actions":{
        showAction: _vm.$can('HST_RD'),
        createAction: _vm.$can('HST_C'),
      }},on:{"item:create":_vm.openCreateHost},scopedSlots:_vm._u([{key:"filters",fn:function(query){return [_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-select',{attrs:{"label":"Статус","items":_vm.statusItems,"item-value":"value","item-text":"name","hide-details":"","outlined":"","dense":"","menu-props":{ offsetY: true }},model:{value:(query.isActive),callback:function ($$v) {_vm.$set(query, "isActive", $$v)},expression:"query.isActive"}})],1)]}},{key:"table.item.nameLicense",fn:function(ref){
      var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.name))]),_c('br'),_vm._v(" "+_vm._s(item.license)+" ")]}},{key:"table.item.org_releasePoint",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.organization.shortName || item.organization.name)+" "),_c('br'),_vm._v(" "+_vm._s(item.releasePoint.address || '#' + item.releasePoint.id)+" ")]}},{key:"table.item.isActive",fn:function(ref){
      var item = ref.item;
return [_c('Indicator',{attrs:{"active":item.isActive,"tooltip":item.isActive ? 'Активен' : 'Неактивен'}})]}},{key:"table.item.isOpenpoint",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":item.isOpenpoint ? 'green lighten-2' : 'red'}},on),[_vm._v(" mdi-lock"+_vm._s(item.isOpenpoint ? '-open' : '')+"-outline ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.isOpenpoint ? 'Открытая' : 'Закрытая')+" ")])]}},{key:"table.item.isNotPrint",fn:function(ref){
      var item = ref.item;
return [_c('Indicator',{attrs:{"active":item.isNotPrint,"active-color":"red","passive-color":"grey lighten-2","tooltip":item.isNotPrint ? item.notPrintComment : 'Автоматическая'}})]}},{key:"table.item.isNotReprint",fn:function(ref){
      var item = ref.item;
return [_c('Indicator',{attrs:{"active":item.isNotReprint,"active-color":"red","passive-color":"grey lighten-2","tooltip":item.isNotReprint ? item.notPrintComment : 'Разрешено'}})]}}],null,false,1398664029)})]},proxy:true}:null],null,true)},[_c('Card',{attrs:{"title":"Данные группы","dense":""},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.$can('HSTG_U'))?_c('v-btn',{attrs:{"depressed":"","small":"","text":"","color":"primary"},on:{"click":_vm.editModal}},[_vm._v(" Редактировать ")]):_vm._e()]},proxy:true}])},[_c('DefinitionList',{attrs:{"value":_vm.computedEntity,"show-empty-values":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }