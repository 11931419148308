<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>Готовые отчеты</v-toolbar-title>
      <v-spacer />
      <v-btn text :disabled="results.length === 0" @click="clearResults">
        Очистить
      </v-btn>
    </v-toolbar>
    <v-divider />
    <div v-if="results.length === 0" class="pa-4">
      <NoDataAlert>
        Здесь пока пусто. Сформируйте свой первый отчет.
      </NoDataAlert>
    </div>
    <ResultItem
      v-for="item in results"
      :key="item.id"
      :item="item"
      :report="getReportByKey(item.reportId)"
      :expanded="isExpandedResult(item.id)"
      @toggle="() => handleToggle(item.id)"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NoDataAlert from '@/components/ui/NoDataAlert';

export default {
  components: {
    ResultItem: () => import('./ResultItem'),
    NoDataAlert,
  },

  computed: {
    ...mapGetters('AVAILABLE_REPORTS', ['reports']),
    ...mapGetters('REPORTS', ['results', 'isExpandedResult']),
  },

  methods: {
    ...mapActions('REPORTS', [
      'expandResult',
      'unexpandResult',
      'clearResults',
    ]),

    getReportByKey(key) {
      return this.reports.find(item => item.key === key);
    },
    handleToggle(id) {
      if (this.isExpandedResult(id)) {
        this.unexpandResult(id);
      } else {
        this.expandResult(id);
      }
    },
  },
};
</script>
