<template>
  <MaskField
    v-model="proxyValue"
    label="Телефон"
    placeholder="+7 (___) ___-__-__"
    :mask="maskInputPhone"
    first-format
    v-bind="$attrs"
  />
</template>

<script>
import { maskInputPhone } from '@/utils/masks';
import MaskField from '@/components/controls/MaskField';

export default {
  components: { MaskField },
  props: { value: { type: String, default: '' } },
  computed: {
    maskInputPhone: () => maskInputPhone,
    proxyValue: {
      get() {
        if (this.value == null) return '';
        return this.value;
      },
      set(val) {
        this.$emit('input', val === '' ? null : val);
      },
    },
  },
};
</script>
