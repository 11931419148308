import env from '@/plugins/env';
import { createHTTP } from '../api';

export const findface = createHTTP(env.get('VUE_APP_FINDFACE_API') + '/v3');

export default {
  /**
   * @action ESOF
   */
  enableSyncOrganization(id) {
    return findface.post(`/organizations/${id}/sync/enable`);
  },

  /**
   * @action DSOF
   */
  disableSyncOrganization(id) {
    return findface.post(`/organizations/${id}/sync/disable`);
  },

  /**
   * @action GSOF
   */
  getSyncStatus(id, signal) {
    return findface
      .get(`/organizations/${id}/sync/status`, { ...(signal && { signal }) })
      .then(res => res.data);
  },
};
