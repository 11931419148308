<template>
  <div>
    <v-row>
      <v-col :cols="12">
        <h3 v-if="manualMeasure">
          Укажите параметры ручных измерений и причину отключения ассистента
        </h3>
        <h3 v-else>Укажите причину отключения ассистента</h3>
      </v-col>
    </v-row>
    <v-row :style="[manualMeasure && { minHeight: '300px' }]">
      <v-col :cols="manualMeasure ? 6 : 12">
        <v-expansion-panels v-if="manualMeasure" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <b>Ручное измерение артериального давления и пульса</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-text-field
                v-model="params.sys"
                label="Систолическое давление, мм рт. ст."
                type="number"
                :error-messages="getValidationErrors('params.sys')"
                @blur="validateField('params.sys')"
              />
              <v-text-field
                v-model="params.dia"
                label="Диастолическое давление, мм рт. ст."
                type="number"
                :error-messages="getValidationErrors('params.dia')"
                @blur="validateField('params.dia')"
              />
              <v-text-field
                v-model="params.pulse"
                label="Пульс, уд./мин"
                type="number"
                :error-messages="getValidationErrors('params.pulse')"
                @blur="validateField('params.pulse')"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <b>Ручное измерение термометрии</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-text-field
                v-model="params.temperature"
                label="Введите температуру, °С"
                type="number"
                :error-messages="getValidationErrors('params.temperature')"
                @blur="validateField('params.temperature')"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <b>Ручное измерение алкометрии</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-text-field
                v-model="params.alcohol"
                label="Введите значение, мг/л"
                type="number"
                :error-messages="getValidationErrors('params.alcohol')"
                @blur="validateField('params.alcohol')"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- it is intensional to show resultComment error messages below
              params.userComment field, do not change it -->
        <v-textarea
          v-model="params.userComment"
          class="mt-3"
          filled
          auto-grow
          label="Комментарий"
          hide-details="auto"
          rows="3"
          :error-messages="getValidationErrors('resultComment')"
          @blur="validateField('resultComment')"
        />
      </v-col>
      <v-col v-if="manualMeasure" :cols="6">
        <v-textarea
          v-model="resultComment"
          filled
          auto-grow
          label="Результат"
          hide-details="auto"
          disabled
          height="100%"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  required,
  minValue,
  maxValue,
  maxLength,
} from 'vuelidate/lib/validators';
import validationMixin from '@/utils/validation';

export default {
  mixins: [validationMixin],

  props: {
    manualMeasure: Boolean,
  },

  data: () => ({
    resultComment: '',
    params: {
      userComment: '',
      sys: '',
      dia: '',
      pulse: '',
      temperature: '',
      alcohol: '',
    },
  }),

  validations() {
    // NOTE: границы взяты из этого файла
    // https://confluence.medpoint24.ru/pages/viewpage.action?pageId=15499849

    // Для альтернативного медкаба
    return {
      resultComment: { required, maxSymbolsLength: maxLength(1000) },
      params: {
        dia: { minValue: minValue(40), maxValue: maxValue(150) },
        temperature: { minValue: minValue(10), maxValue: maxValue(42) },
        alcohol: { minValue: minValue(0), maxValue: maxValue(2) },
        sys: { minValue: minValue(80), maxValue: maxValue(220) },
        pulse: { minValue: minValue(40), maxValue: maxValue(150) },
      },
    };
  },

  watch: {
    resultComment(val) {
      this.$emit('input', (val || '').trim() || '');
    },

    params: {
      deep: true,
      handler() {
        this.$nextTick(() => this.generate());
      },
    },
  },

  methods: {
    generate() {
      const { userComment, sys, dia, pulse, temperature, alcohol } =
        this.params;

      const texts = [];
      const hasParams = sys || dia || pulse || temperature || alcohol;

      if (userComment) texts.push(`${userComment}${hasParams ? ';' : ''}`);

      // Перечисляем все возможные ручные измерения
      if (hasParams) {
        texts.push('Измерено вручную:');
      }

      if (sys || dia || pulse) {
        texts.push(`систолическое давление: ${sys || '–'} мм рт. ст.;`);
        texts.push(`диастолическое давление: ${dia || '–'} мм рт. ст.;`);
        texts.push(`пульс: ${pulse || '–'} уд./мин;`);
      }
      if (temperature) texts.push(`термометрия: ${temperature} °С;`);
      if (alcohol) texts.push(`алкометрия: ${alcohol} мг/л;`);

      this.resultComment = texts.join('\n') || null;
    },
  },
};
</script>
