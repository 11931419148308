<template>
  <ModernModalLayout
    :title="isUpdate ? 'Редактирование организации' : 'Добавление организации'"
    close-label="Отмена"
    :watch-for="data"
    size="xlarge"
  >
    <v-form ref="form" :disabled="$wait('submit')">
      <v-row class="d-flex mx-0" style="gap: 13px">
        <v-col
          cols="5"
          class="d-flex flex-column align-self-start pa-0"
          style="gap: 0px"
        >
          <v-text-field
            v-model="data.name"
            label="Название"
            outlined
            :error-messages="getValidationErrors('data.name')"
            @blur="validateField('data.name')"
          />
          <v-text-field
            v-model="data.shortName"
            label="Краткое наименование"
            :hint="isShortNameRequired ? 'Введите краткое наименование' : ''"
            outlined
            :error-messages="getValidationErrors('data.shortName')"
            @blur="validateField('data.shortName')"
          />
          <v-text-field
            v-show="data.isMedical"
            v-model="data.medLicense.license"
            cols="12"
            label="Лицензия"
            outlined
            :error-messages="getValidationErrors('data.medLicense.license')"
            @blur="validateField('data.medLicense.license')"
          />
          <div v-show="data.isMedical">
            <DatePicker
              v-model="data.medLicense.issuedAt"
              label="Дата выдачи лицензии"
              outlined
              max-current
              :error-messages="getValidationErrors('data.medLicense.issuedAt')"
              @blur="validateField('data.medLicense.issuedAt')"
            />
          </div>
          <OrganizationGroupSelect
            v-show="isCreate"
            v-model="data.groupIds"
            access-level="full"
            :hide-details="false"
            multiple
            :error-messages="getValidationErrors('data.groupIds')"
            :is-holding="false"
            @blur="validateField('data.groupIds')"
          />
          <OrganizationGroupSelect
            v-show="isCreate"
            v-model="data.holdingId"
            access-level="full"
            :hide-details="false"
            :error-messages="getValidationErrors('data.holdingId')"
            is-holding
            @blur="validateField('data.holdingId')"
          />
          <v-switch
            v-show="isCreate"
            v-model="data.isMedical"
            class="py-0 my-0"
            label="С медицинскими работниками"
            :disabled="isUpdate"
          />
          <v-switch
            v-show="isCreate"
            v-model="data.isSelfManaged"
            class="py-0 my-0"
            label="Обслуживается самостоятельно"
            :disabled="!data.isMedical"
          />
          <template v-if="!isUpdate">
            <div
              v-if="!data.isMedical"
              class="text-sm-body-2 mb-5"
              style="margin-top: -20px"
            >
              Организация без медработников не может обслуживаться
              самостоятельно
            </div>
            <OrganizationSelect
              v-if="!data.isSelfManaged"
              v-model="medOrg"
              access-level="full"
              medical
              :hide-details="false"
              :error-messages="getValidationErrors('medOrg')"
              @blur="validateField('medOrg')"
              @change="() => (data.categoryId = null)"
            />
            <CategorySelect
              v-if="!data.isSelfManaged"
              v-model="data.categoryId"
              :hide-details="false"
              :med-org-ids="[medOrg]"
              :disabled="!medOrg"
              :error-messages="getValidationErrors('data.categoryId')"
              @blur="validateField('data.categoryId')"
            />
          </template>
        </v-col>
        <v-divider vertical />
        <v-col class="pa-0 ma-0">
          <Select
            v-model="data.legalForm"
            label="Правовая форма"
            :items="legalForm"
            outlined
            :error-messages="getValidationErrors('data.legalForm')"
            @blur="validateField('data.legalForm')"
          />
          <MaskField
            v-show="data.legalForm"
            v-model="data.inn"
            :mask="data.legalForm === 'llc' ? maskInputInn10 : maskInputInn12"
            label="ИНН"
            outlined
            first-format
            :placeholder="
              data.legalForm === 'llc' ? 'XXXXXXXXXX' : 'XXXXXXXXXXXX'
            "
            :error-messages="getValidationErrors('data.inn')"
            @blur="validateField('data.inn')"
            @focus="clearKppErr"
          />
          <MaskField
            v-show="data.legalForm === 'llc'"
            v-model="data.kpp"
            :mask="maskInputKpp"
            label="КПП"
            outlined
            first-format
            placeholder="XXXXXXXXX"
            :error-messages="getValidationErrors('data.kpp')"
            @blur="validateField('data.kpp')"
          />
          <MaskField
            v-show="
              data.legalForm === 'llc' || data.legalForm === 'self_employed_llc'
            "
            v-model="data.ogrn"
            :mask="data.legalForm === 'llc' ? maskInputOrgn : maskInputOrgnip"
            :label="data.legalForm === 'llc' ? 'ОГРН' : 'ОГРНИП'"
            :placeholder="
              data.legalForm === 'llc' ? 'XXXXXXXXXXXXX' : 'XXXXXXXXXXXXXXX'
            "
            outlined
            first-format
            :error-messages="getValidationErrors('data.ogrn')"
            @blur="validateField('data.ogrn')"
          />

          <v-text-field
            v-if="isSelfEmployed"
            v-model="data.selfEmployedFullName.lastName"
            outlined
            label="Фамилия"
            :error-messages="
              getValidationErrors('data.selfEmployedFullName.lastName')
            "
            @blur="handleFieldBlur('data.selfEmployedFullName.lastName')"
          />
          <v-text-field
            v-if="isSelfEmployed"
            v-model="data.selfEmployedFullName.firstName"
            outlined
            label="Имя"
            :error-messages="
              getValidationErrors('data.selfEmployedFullName.firstName')
            "
            @blur="handleFieldBlur('data.selfEmployedFullName.firstName')"
          />
          <v-text-field
            v-if="isSelfEmployed"
            v-model="data.selfEmployedFullName.patronymic"
            outlined
            label="Отчество"
            :error-messages="
              getValidationErrors('data.selfEmployedFullName.patronymic')
            "
            @blur="handleFieldBlur('data.selfEmployedFullName.patronymic')"
          />
          <v-divider class="mb-4" style="margin-top: -4px" />

          <div v-if="data.isMedical">
            <div v-for="(item, index) in data.contactPersons" :key="index">
              <div class="mb-2">Контактное лицо {{ index + 1 }}</div>
              <v-row class="align-center justify-space-between mb-1">
                <v-col class="d-flex flex-wrap" cols="10">
                  <v-col cols="6" class="pa-0 pr-3 pb-3">
                    <v-text-field
                      v-model="data.contactPersons[index].lastName"
                      outlined
                      hide-details="auto"
                      label="Фамилия"
                      :error-messages="
                        getValidationErrors(
                          'data.contactPersons.$each.' + index + '.lastName',
                        )
                      "
                      @blur="
                        handleFieldBlur(
                          'data.contactPersons.$each.' + index + '.lastName',
                        )
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="pa-0 pb-3">
                    <v-text-field
                      v-model="data.contactPersons[index].firstName"
                      outlined
                      label="Имя"
                      hide-details="auto"
                      :error-messages="
                        getValidationErrors(
                          'data.contactPersons.$each.' + index + '.firstName',
                        )
                      "
                      @blur="
                        handleFieldBlur(
                          'data.contactPersons.$each.' + index + '.firstName',
                        )
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="pa-0 pr-3 ma-0">
                    <v-text-field
                      v-model="data.contactPersons[index].patronymic"
                      outlined
                      label="Отчество"
                      :error-messages="
                        getValidationErrors(
                          'data.contactPersons.$each.' + index + '.patronymic',
                        )
                      "
                      @blur="
                        handleFieldBlur(
                          'data.contactPersons.$each.' + index + '.patronymic',
                        )
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-text-field
                      v-model="data.contactPersons[index].email"
                      label="Email"
                      outlined
                      hide-details="auto"
                      :error-messages="
                        getValidationErrors(
                          'data.contactPersons.$each.' + index + '.email',
                        )
                      "
                      @blur="
                        validateField(
                          'data.contactPersons.$each.' + index + '.email',
                        )
                      "
                    />
                  </v-col>
                </v-col>
                <v-col cols="2">
                  <v-btn icon outlined color="error" @click="removeRow(index)">
                    <v-icon size="18">fa-trash</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <div class="mt-4 text-center">
              <v-btn depressed color="primary" @click="addRow">
                + Добавить контактное лицо
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :disabled="$wait('submit')"
        @click="submit"
      >
        {{ isUpdate ? 'Сохранить' : 'Добавить' }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import {
  required,
  requiredIf,
  maxLength,
  email,
} from 'vuelidate/lib/validators';
import { kpp, cyrillicName } from '@/utils/validators';
import {
  maskInputInn10,
  maskInputInn12,
  maskInputKpp,
  maskInputOrgn,
  maskInputOrgnip,
} from '@/utils/masks';
import { parseDate, deepCopyWithNull } from '@/utils/helpers';
import { LEGAL_FORM } from '@/utils/constants';

import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import MaskField from '@/components/controls/MaskField.vue';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';
import OrganizationGroupSelect from '@/components/controls/structures/OrganizationGroupSelect.vue';
import CategorySelect from '@/components/controls/structures/CategorySelect';
import DatePicker from '@/components/controls/DatePicker.vue';
import Select from '@/components/controls/Select.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    ModernModalLayout,
    MaskField,
    CategorySelect,
    OrganizationSelect,
    OrganizationGroupSelect,
    DatePicker,
    Select,
  },
  mixins: [validation, waitable],

  props: {
    value: {
      type: Object,
      default: () => ({
        medLicense: {},
        selfEmployedFullName: {},
        contactPersons: [
          { firstName: '', lastName: '', patronymic: '', email: '' },
        ],
      }),
    },
    // it is done extencibely, yes. But for now it will ONLY predefine
    // org groups when org is being created from within a group
    predefinedValues: { type: Object, default: () => ({}) },
    storeForCreating: { type: String, default: 'STRUCTURES/ORGANIZATION_LIST' },
    onSubmit: { type: Function, default: () => null },
  },

  data: () => ({
    data: {},
    medOrg: null, // для фильтрации категорий
    categoriesList: [],
    legalForm: LEGAL_FORM,
  }),

  validations() {
    return {
      data: {
        name: { required },
        shortName: {
          maxSymbolsLength: maxLength(24),
          required: requiredIf(() => this.isShortNameRequired),
        },
        legalForm: { required },
        inn: {
          required,
          rule(val) {
            if (!val) return true;
            const regex = new RegExp(
              this.data.legalForm === 'llc' ? /^\d{10}$/ : /^\d{12}$/,
            );
            return regex.test(val);
          },
        },
        kpp: { kpp },
        ogrn: {
          required: requiredIf(
            () =>
              this.data.legalForm === 'llc' ||
              this.data.legalForm === 'self_employed_llc',
          ),
          rule(val) {
            if (this.data.legalForm === 'self_employed') return true;
            const regex = new RegExp(
              this.data.legalForm === 'llc' ? /^\d{13}$/ : /^\d{15}$/,
            );
            return regex.test(val);
          },
        },
        selfEmployedFullName: {
          firstName: {
            required: requiredIf(() => this.isSelfEmployed),
            cyrillicName,
            maxSymbolsLength: maxLength(50),
          },
          lastName: {
            required: requiredIf(() => this.isSelfEmployed),
            cyrillicName,
            maxSymbolsLength: maxLength(50),
          },
          patronymic: { cyrillicName, maxSymbolsLength: maxLength(50) },
        },
        contactPersons: {
          $each: {
            firstName: {
              required: requiredIf(() => this.data.isMedical),
              cyrillicName,
              maxSymbolsLength: maxLength(50),
            },
            lastName: {
              required: requiredIf(() => this.data.isMedical),
              cyrillicName,
              maxSymbolsLength: maxLength(50),
            },
            patronymic: { cyrillicName, maxSymbolsLength: maxLength(50) },
            email: { required: requiredIf(() => this.data.isMedical), email },
          },
        },

        categoryId: {
          required: requiredIf(() => this.isCreate && !this.data.isSelfManaged),
        },
        medLicense: {
          license: {
            required: requiredIf(() => this.data.isMedical),
            maxSymbolsLength: maxLength(22),
          },
          issuedAt: {
            required: requiredIf(() => this.data.isMedical),
          },
        },
      },
      medOrg: {
        required: requiredIf(() => this.isCreate && !this.data.isSelfManaged),
      },
    };
  },

  computed: {
    maskInputInn10: () => maskInputInn10,
    maskInputInn12: () => maskInputInn12,
    maskInputKpp: () => maskInputKpp,
    maskInputOrgn: () => maskInputOrgn,
    maskInputOrgnip: () => maskInputOrgnip,
    isCreate() {
      return !this.value?.id;
    },
    isUpdate() {
      return Boolean(this.value?.id);
    },
    isShortNameRequired() {
      return this.data.name?.length > 24 && this.data.isMedical;
    },
    isSelfEmployed() {
      return (
        this.data.legalForm === 'self_employed' ||
        this.data.legalForm === 'self_employed_llc'
      );
    },
    validationMessages() {
      return {
        'data.inn.rule': `Должно состоять из ${
          this.data.legalForm === 'llc' ? 10 : 12
        } цифр`,
        'data.ogrn.rule': `Должно состоять из ${
          this.data.legalForm === 'llc' ? 13 : 15
        } цифр`,
      };
    },
  },

  watch: {
    'data.isMedical': {
      handler(val) {
        if (!val) this.data.isSelfManaged = false;
      },
    },

    'data.legalForm': {
      handler(_, oldVal) {
        oldVal &&
          this.$nextTick(() => {
            this.data.inn = '';
            this.data.kpp = '';
            this.data.ogrn = '';
            this.data.selfEmployedFullName = {};
          });
      },
    },

    'data.isSelfManaged': {
      handler(val) {
        if (val) {
          this.medOrg = null;
          this.data.categoryId = null;
        }
      },
    },
  },

  async created() {
    const newData = cloneDeep(this.value);
    if (this.isCreate) {
      newData.isMedical = false;
      newData.isSelfManaged = false;
      newData.categoryId = null;

      Object.keys(this.predefinedValues).length &&
        Object.keys(this.predefinedValues).forEach(
          key => (newData[key] = this.predefinedValues[key]),
        );
    } else if (this.isUpdate && newData.medOrg === newData.id)
      newData.isSelfManaged = true;
    else if (this.isUpdate && !newData.selfEmployedFullName)
      newData.selfEmployedFullName = {};

    this.data = newData;
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_CATEGORIES', {
      changeCountOnOrgCategories: 'changeCounter',
    }),
    ...mapActions('STRUCTURES/ORGANIZATION_GROUPS', {
      changeCountOnOrgGroups: 'changeCounter',
    }),
    changeCounter() {
      if (this.isUpdate) return;
      if (this.data.categoryId)
        this.changeCountOnOrgCategories({
          itemIds: [this.data.categoryId],
          counterField: 'orgsCount',
          addCount: 1,
        });
      if (this.data.groupIds)
        this.changeCountOnOrgGroups({
          itemIds: this.data.groupIds,
          counterField: 'membersCount',
          addCount: 1,
        });
    },
    async submit() {
      const form = deepCopyWithNull(this.data);

      if (!this.validate()) {
        // here it is necessary to notify the user since the form is big and some
        // fields might be not visible (need to scroll to see them)
        this.$notify({
          group: 'note',
          type: 'error',
          title: 'Ошибка заполнения формы',
          text: 'При заполнении формы допущены ошибки, исправьте их и повторите действие снова',
        });
        return;
      }

      const actionName = this.isUpdate
        ? 'STRUCTURES/ORGANIZATION_ITEM/updateSingleItem'
        : `${this.storeForCreating}/createListItem`;

      if (this.data.isSelfManaged) delete form.categoryId;

      if (form.isMedical) {
        if (form.medLicense.issuedAt)
          form.medLicense.issuedAt = parseDate(
            this.data.medLicense.issuedAt,
          ).toFormat('yyyy-MM-dd');
      } else {
        delete form.medLicense;
        delete form.contactPersons;
      }

      await this.$loadingNotify(
        this.$store.dispatch(actionName, form),
        'submit',
        `Произошла ошибка ${
          this.isUpdate ? 'изменения' : 'создания'
        } организации`,
        `Организация успешно ${this.isUpdate ? 'изменена' : 'создана'}`,
      )
        .then(() => {
          this.changeCounter();
          this.onSubmit && this.onSubmit();
          this.$emit('close');
        })
        .catch(err => this.catchServerErrors(err, 'data'));
    },

    clearKppErr() {
      // force update
      delete this.serverValidationErrors['data.kpp'];
      this.serverValidationErrors = { ...this.serverValidationErrors };
    },

    // --- contact persons raws logic (taken from insp. filters component) ---

    addRow() {
      this.data.contactPersons = [
        ...this.data.contactPersons,
        { firstName: '', lastName: '', patronymic: '', email: '' },
      ];
    },
    removeRow(index) {
      if (this.data.contactPersons.length === 1) {
        this.$notify({
          group: 'note',
          type: 'error',
          text: 'Необходимо минимум одно контактное лицо',
        });
        return;
      }
      this.data.contactPersons = [
        ...this.data.contactPersons.slice(0, index),
        ...this.data.contactPersons.slice(index + 1),
      ];
    },
  },
};
</script>
