<template>
  <div class="mb-n1">
    <div v-if="errors.length" class="integrity-error">
      Не работает: {{ errors.join(', ') }}
    </div>
    <div v-else class="integrity-success">Не нарушена</div>
  </div>
</template>

<script>
const INTEGRITY_NAMES = {
  alcohol: 'Алкотестер',
  temperature: 'Термометр',
  tonometry: 'Тонометр',
};

export default {
  props: {
    status: {
      required: true,
      type: Object,
    },
  },
  computed: {
    errors() {
      return Object.keys(this.status)
        .filter(
          key =>
            !this.status[key].status || this.status[key].status === 'error',
        )
        .map(key => {
          return INTEGRITY_NAMES[key];
        });
    },
  },
};
</script>

<style scoped>
.integrity-error {
  font-weight: bold;
  color: red;
}
.integrity-success {
  font-weight: bold;
  color: green;
}
</style>
