import limitsApi from '@/api/services/limits';
import authApi from '@/api/services/auth';

import { createModule } from '@/utils/vuex/createModule';

const state = {
  list: null,
  groupOrgLimitPreviews: null, // group org limits
  receivers: [],
};

const getters = {
  list: state => state.list || [],
  groupOrgLimitPreviews: state => state.groupOrgLimitPreviews || {},
  receivers: state => state.receivers,
};

const mutations = {
  list(state, value) {
    state.list = value;
  },
  groupOrgLimitPreviews(state, value) {
    state.groupOrgLimitPreviews = value;
  },
  add(state, value) {
    state.list.unshift(value);
  },
  update(state, { id, payload }) {
    const index = state.list.findIndex(el => el.id === id);
    state.list.splice(index, 1, payload);
  },
  remove(state, id) {
    const index = state.list.findIndex(el => el.id === id);
    if (~index) state.list.splice(index, 1);
  },
  receivers: (state, value) => (state.receivers = value),
  changeStatus(state, { id, isEnabled }) {
    const index = state.list.findIndex(el => el.id === id);
    if (~index) {
      const newValue = { ...state.list[index], isEnabled };
      state.list.splice(index, 1, newValue);
    }
  },
};

const actions = {
  async fetchList({ commit }, { orgOrGroupId, groupp }) {
    const list = groupp
      ? await limitsApi.limitByOrgGroupGet(orgOrGroupId)
      : await limitsApi.limitByOrgGet(orgOrGroupId);
    commit('list', list);
  },
  async fetchReceiverPreviews({ getters, commit }) {
    const ids = getters.list
      .map(limit => limit.settings.notifications.map(notif => notif.receivers))
      .flat(2);
    const receivers = ids?.length
      ? await authApi.getAccountPreviewsBy(ids)
      : [];
    commit('receivers', receivers);
  },
  async fetchGroupOrgLimitPreviews({ commit }, params) {
    const limits = await limitsApi.limitsByGroupOrgsGet(params);
    commit('groupOrgLimitPreviews', limits);
    return limits;
  },
  async create({ commit }, payload) {
    const limit = await limitsApi.limitCreate(payload);
    commit('add', limit);
  },
  async update({ commit }, { id, payload }) {
    const limit = await limitsApi.limitUpdate(id, payload);
    commit('update', { id, payload: limit });
  },
  async remove({ commit }, id) {
    await limitsApi.limitDelete(id);
    commit('remove', id);
  },
  async enable({ commit }, id) {
    await limitsApi.limitEnable(id);
    commit('changeStatus', { id, isEnabled: true });
  },
  async disable({ commit }, id) {
    await limitsApi.limitDisable(id);
    commit('changeStatus', { id, isEnabled: false });
  },

  async limitCount(_, { orgOrGroupId, groupp, ...params }) {
    const data = groupp
      ? await limitsApi.limitCountByOrgGroup(orgOrGroupId, params)
      : await limitsApi.limitCountByOrg(orgOrGroupId, params);
    return data;
  },
};

export default createModule({ state, getters, actions, mutations });
