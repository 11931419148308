<template>
  <ModernModalLayout title="Изменение организации" close-label="Отмена">
    <v-form ref="form" :disabled="disabledForm">
      <v-row>
        <v-col cols="12">
          <OrganizationSelect
            v-model="data.orgId"
            medical
            hide-details="auto"
            access-level="full"
            :error-messages="getValidationErrors('data.orgId')"
            @blur="validateField('data.orgId')"
            @input="onOrgIdChange"
          />
        </v-col>
        <v-col cols="12">
          <CategorySelect
            v-model="data.categoryIds"
            multiple
            hide-details="auto"
            :med-org-ids="[data.orgId]"
            :disabled="!data.orgId"
            :error-messages="getValidationErrors('data.categoryIds')"
            @blur="validateField('data.categoryIds')"
          />
        </v-col>
        <v-col cols="12">
          <MedicGroupSelect
            v-model="data.groupIds"
            multiple
            hide-details="auto"
            :med-org-ids="[data.orgId]"
            :disabled="!data.orgId"
            :error-messages="getValidationErrors('data.groupIds')"
            @blur="validateField('data.groupIds')"
          />
        </v-col>
      </v-row>
    </v-form>

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :disabled="disabledForm"
        @click="submit"
      >
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';
import MedicGroupSelect from '@/components/controls/structures/MedicGroupSelect.vue';
import CategorySelect from '@/components/controls/structures/CategorySelect';

export default {
  components: {
    ModernModalLayout,
    OrganizationSelect,
    MedicGroupSelect,
    CategorySelect,
  },
  mixins: [validation, waitable],

  props: {
    value: { type: Object, default: () => ({}) },
  },

  data: () => ({
    data: {
      orgId: null,
      categoryIds: [],
      groupIds: [],
    },
  }),

  validations() {
    return {
      data: {
        orgId: { required },
      },
    };
  },

  computed: {
    disabledForm() {
      return this.$wait('submit');
    },
  },

  async created() {
    const { medicData } = this.value;

    this.data = {
      orgId: medicData.orgId,
      categoryIds: medicData.categories,
      groupIds: medicData.groups.map(el => el.id),
    };
  },

  methods: {
    async onOrgIdChange() {
      this.data.categoryIds = [];
      this.data.groupIds = [];
    },

    async submit() {
      if (!this.validate()) return;

      await this.$loadingNotify(
        this.$store
          .dispatch('MEDBLOCK/MEDICS/changeMedicOrganization', {
            id: this.value.id,
            params: this.data,
          })
          .then(() => {
            this.$emit('close');
          }),
        'submit',
        err => {
          const msg = err.response?.data?.inner[0]?.errors?.name;
          if (msg)
            this.$notify({
              group: 'note',
              type: 'error',
              title: 'Oшибка обновления категории',
              text: msg,
            });
        },
        'Категория успешно обновлена',
      ).catch(() => {});
    },
  },
};
</script>
