<template>
  <v-alert type="error" rounded dense outlined>
    {{ userFriendlyError }}
    <template #append>
      <v-tooltip left>
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" small icon v-on="on" @click="$emit('refresh')">
            <v-icon small>fas fa-sync-alt</v-icon>
          </v-btn>
        </template>
        <span>Перезагрузить</span>
      </v-tooltip>
    </template>
  </v-alert>
</template>

<script>
import { isXhrError, xhrErrorMessage } from '@/utils/helpers';

export default {
  name: 'ErrorBlock',
  props: {
    error: { type: Error, default: undefined },
  },
  computed: {
    userFriendlyError() {
      if (isXhrError(this.error)) {
        const message = xhrErrorMessage(this.error);
        return message || this.error;
      }

      return this.error.message || this.error;
    },
  },
};
</script>
