<template>
  <ModernModalLayout title="Графики" dense-content size="large">
    <AsyncWrapper :handler="fetchData">
      <div>
        <v-tabs v-model="chartsTab" fixed-tabs="fixed-tabs">
          <v-tab key="pressure">Артериальное давление</v-tab>
          <v-tab key="pulse">Пульс</v-tab>
        </v-tabs>
        <v-divider />
        <v-tabs-items v-model="chartsTab" class="ma-6">
          <v-tab-item key="pressure">
            <StatsChart
              v-if="(history.measurements || []).length"
              :value="history.measurements"
            />

            <v-alert v-else type="info" class="ma-4" outlined>
              Замеры артериального давления не проводились
            </v-alert>
          </v-tab-item>
          <v-tab-item key="pulse">
            <StatsChart
              v-if="(history.measurements || []).length"
              :value="history.measurements"
              type="pulse"
            />
            <v-alert v-else type="info" class="ma-4" outlined>
              Замеры пульса не проводились
            </v-alert>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </AsyncWrapper>
  </ModernModalLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AsyncWrapper from '@/components/AsyncWrapper';
import StatsChart from '@/modules/employees/components/StatsChart';
import ModernModalLayout from '@/components/layouts/ModernModalLayout';

export default {
  components: { AsyncWrapper, StatsChart, ModernModalLayout },
  props: {
    id: { required: true, type: Number },
  },
  data() {
    return {
      chartsTab: 'pressure',
    };
  },
  computed: {
    ...mapGetters('EMPLOYEE', { history: 'measurementsHistory' }),
  },
  methods: {
    ...mapActions('EMPLOYEE', ['fetchMeasurementsHistory']),

    fetchData() {
      return this.fetchMeasurementsHistory(this.id);
    },
  },
};
</script>
