import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import VERIFICATION from './store/list';
import VERIFICATION_CARD from './store/item';
import RouteGuard from '@/components/RouteGuard';

export default {
  namespaced: true,
  store: {
    VERIFICATION,
    VERIFICATION_CARD,
  },
  menu: [
    {
      title: 'Поверка ПАК',
      action: 'G_HV',
      name: 'verification',
      priority: 4,
    },
  ],
  routes: [
    {
      path: 'verification',
      name: 'verification',
      component: () => import('./view/list.vue'),
      meta: {
        action: 'G_HVI',
      },
    },
    {
      path: 'verification/create',
      name: 'verification:create',
      component: RouteGuard({
        storeModule: 'NOTIFICATIONS/VERIFICATION_CARD',
        component: () => import('./view/item.vue'),
        skipFetch: true,
      }),
      meta: {
        action: 'P_HV',
      },
    },
    {
      path: 'verification/:id',
      name: 'verification:item',
      component: RouteGuard({
        storeModule: 'NOTIFICATIONS/VERIFICATION_CARD',
        component: () => import('./view/item.vue'),
        primaryKeyName: 'id',
        primaryKeyType: 'string',
      }),
      meta: {
        action: 'G_HVI',
      },
    },
  ],
} as ModuleDefinition;
