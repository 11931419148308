import notifApi from '@/api/services/notifications';
import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

const state = {
  profiles: null,
};

const getters = {
  profiles: state => state.profiles || [],
};

const mutations = {
  setProfiles(state, data) {
    state.profiles = data;
  },
};

const actions = {
  async fetchProfiles({ commit }, params = { page: 1, limit: 1000 }) {
    const list = await notifApi.getProfilePreviews(params);
    commit('setProfiles', list.items);
  },

  async createSingleItem({ dispatch }, data) {
    const payload = data;
    payload.profileKey = data.profile;
    delete payload.profile;

    const subscription = await notifApi.subscriptionCreate(payload);
    dispatch('NOTIFICATIONS/SUBSCRIPTIONS/fetchList', null, { root: true });
    return subscription; // return is needed to redirect user on new sub:id page
  },
  async updateSingleItem({ dispatch }, data) {
    const payload = data;
    // in case user didn't reload / revisit page after creation of subscription
    // then he will have 'profile' field as an object with 'key' field and that
    // determines the line below - sometimes if there is 'key' field we use it
    // and sometimes (when user entered already existing subscribtion) we just
    // use 'profile' field as is
    payload.profileKey = data.profile.key || data.profile;
    delete payload.profile;

    await notifApi.subscriptionUpdate(payload);
    dispatch('singleItemUpdate');
    dispatch('NOTIFICATIONS/SUBSCRIPTIONS/fetchList', null, { root: true });
  },
};

export default createModule(
  crudSingleFactory({
    fetchMethod: notifApi.subscriptionGet,
    updateMethod: notifApi.subscriptionUpdate,
    deleteMethod: notifApi.subscriptionDelete,
    linkedList: 'NOTIFICATIONS/SUBSCRIPTIONS',
  }),
  { state, getters, mutations, actions },
);
