import structApi from '@/api/services/structures';
import listSimpleFactory from '@/utils/vuex/listSimpleFactory';
import { createModule } from '@/utils/vuex/createModule';
import { filters } from '@/modules/employees/entity';

const getters = {
  listQuery: (state, getters, _, rootGetters) => ({
    ...getters.listFilters,
    ...getters.listOrder,
    search: state.listQuery.search || null,
    groupIds: `${rootGetters['STRUCTURES/EMPLOYEE_GROUPS_ITEM/singleItem'].id}`,
  }),
};

const actions = {
  async setEmployeeSearch({ commit }, value) {
    commit('listQuery', { search: value });
  },
};

export default createModule(
  listSimpleFactory({
    fetchMethod: structApi.employeeList,
    createMethod: structApi.employeeCreate,
    filters,
    linkedList: 'EMPLOYEE_LIST',
  }),
  { getters, actions },
);
