<template>
  <v-timeline align-top dense>
    <v-timeline-item v-if="value.start" color="grey" large>
      <h3>Старт задачи</h3>
      <span>{{ dateTime(value.start) }}</span>
    </v-timeline-item>
    <v-timeline-item :color="status.color" large>
      <h3>{{ status.name }}</h3>
      <small>{{ status.description }}</small>
      <br />
      <span v-if="value.end">{{ dateTime(value.end) }}</span>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import { EXECUTIONS_STATUSES } from '../../constants';
import { dateTime } from '@/utils/convert';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  computed: {
    status() {
      return EXECUTIONS_STATUSES[this.value.status];
    },
  },

  methods: {
    dateTime,
  },
};
</script>
