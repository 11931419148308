<template>
  <List
    search-filter
    :available-actions="{
      createAction: $can('NF_SC'),
      showAction: $can('NF_SR'),
      editAction: false,
      deleteAction: false,
    }"
    @item:create="
      () => $router.push({ name: 'notifications:subscriptions:create' })
    "
  >
    <template #filters="query">
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.multiOrg"
          label="Мульти"
          :items="booleanItems"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :menu-props="{ offsetY: true }"
        />
      </v-col>
      <v-col cols="12" sm="4" md="6">
        <AccountSelect
          v-model="query.accountIds"
          multiple
          dense
          title="Получатели"
        />
      </v-col>
      <v-col cols="12" sm="4" md="6">
        <OrganizationSelect
          v-model="query.orgIds"
          multiple
          dense
          title="Организации владельцы"
        />
      </v-col>
    </template>

    <template #table.item.name_org="{ item }">
      <b>{{ item.name }}</b>
      <br />
      {{ (getOrgPreviewsById(item.orgId) || {}).name }}
    </template>

    <template #table.item.multiOrg="{ item }">
      <Round :type="item.multiOrg ? 'green lighten-2' : 'grey lighten-2'" />
    </template>
  </List>
</template>

<script>
import ListFactory from '@/components/crud/ListFactory';

import OrganizationSelect from '@/components/controls/structures/OrganizationSelect';
import AccountSelect from '@/components/controls/structures/AccountSelect';

import { BOOLEANS } from '@/utils/constants.js';

import Round from '@/components/Round.vue';

import { messages, entity, filters } from '../entity';
import { mapGetters } from 'vuex';

const List = ListFactory({
  storeModule: 'NOTIFICATIONS/SUBSCRIPTIONS',
  messages,
  entity,
  filters,
  viewRoute: 'notifications:subscriptions:item',
});

export default {
  components: { List, OrganizationSelect, AccountSelect, Round },
  computed: {
    ...mapGetters('NOTIFICATIONS/SUBSCRIPTIONS', [
      'previews',
      'getOrgPreviewsById',
    ]),
    booleanItems: () => BOOLEANS,
  },
};
</script>
