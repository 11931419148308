<template>
  <AsyncSelect v-bind="computedProps" v-on="$listeners">
    <template #item:description="{ item }">
      <v-chip v-if="!item.isActive" x-small color="error" class="ml-1">
        Неактивна
      </v-chip>
    </template>
  </AsyncSelect>
</template>

<script>
import asyncSelectMixin from '@/components/controls/asyncSelectMixin';

import { mapActions } from 'vuex';
export default {
  extends: asyncSelectMixin,

  props: {
    accessLevel: {
      validator: value => ['any', 'full'].indexOf(value) !== -1,
      default: 'any',
    },
    groupIds: { type: [Array], default: null },
    medical: { type: Boolean, default: null },
    errorMessages: { type: Array, default: null },
    // not required
    title: { type: String, default: null },
  },

  computed: {
    label() {
      if (this.title) return this.title;
      if (this.medical)
        return this.multiple
          ? 'Обслуживающие медицинские организации'
          : 'Обслуживающая медицинская организация';
      return this.multiple ? 'Организации' : 'Организация';
    },
    query() {
      return {
        accessLevel: this.accessLevel,
        groupIds: this.groupIds?.join() || undefined,
        ...(this.medical !== null ? { isMedical: this.medical } : undefined),
      };
    },
  },

  methods: {
    ...mapActions('STRUCTURES', {
      fetcher: 'fetchOrganizationPreviews',
      fetcherBy: 'fetchOrganizationPreviewsBy',
    }),
  },
};
</script>
