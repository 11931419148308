/**
 * Dependency Injection для localStorage
 */
export class WebStorage {
  static isAccess() {
    try {
      return window.localStorage !== undefined;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  static getItem(key: string) {
    if (WebStorage.isAccess()) return window.localStorage.getItem(key);
    else throw Error('Операция запрещена браузером');
  }

  static setItem(key: string, value: string) {
    if (WebStorage.isAccess()) {
      window.localStorage.setItem(key, value);
      return value;
    } else throw Error('Операция запрещена браузером');
  }

  static removeItem(key: string) {
    if (WebStorage.isAccess()) {
      window.localStorage.removeItem(key);
      return null;
    } else throw Error('Операция запрещена браузером');
  }
}

export default WebStorage;
