import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import REPORTS from './store/reports';
import AVAILABLE_REPORTS from './store/availableReports';
import AVAILABLE_SUMMARIES from './store/availableSummaries';

export default {
  store: {
    REPORTS,
    AVAILABLE_REPORTS,
    AVAILABLE_SUMMARIES,
  },
  menu: [
    {
      title: 'Отчеты',
      action: 'RPT_AV',
      name: 'reports',
    },
  ],
  routes: [
    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/modules/reports/views/index.vue'),
      redirect: { name: 'reports:list' },
      children: [
        {
          path: '',
          name: 'reports:list',
          component: () => import('@/modules/reports/views/list.vue'),
          meta: {
            action: 'RPT_AV',
          },
        },
        {
          path: ':key',
          name: 'reports:card',
          component: () => import('@/modules/reports/views/item.vue'),
          meta: {
            action: 'RPT_AV',
          },
        },
      ],
    },
  ],
} as ModuleDefinition;
