<template>
  <v-container md="md" style="max-width: 1000px">
    <v-row v-if="$wait('fetchReports')" justify="center"> <Spinner /></v-row>
    <v-row v-else justify="center">
      <v-col cols="10">
        <router-view :change-report="changeReport" />
      </v-col>
      <v-col cols="10">
        <ResultList />
      </v-col>
    </v-row>

    <Goto />
  </v-container>
</template>

<script>
import Goto from '@/components/Goto';
import ResultList from '@/modules/reports/components/ResultList';
import Spinner from '@/components/Spinner';
import waitable from '@/utils/mixins/waitable';
import { mapActions } from 'vuex';

export default {
  components: { Goto, ResultList, Spinner },
  mixins: [waitable],
  created() {
    this.$loading(
      this.$store.dispatch(`AVAILABLE_REPORTS/fetchReports`),
      'fetchReports',
    );
  },
  methods: {
    ...mapActions('REPORTS', ['resetFilters']),
    changeReport(key) {
      if (key) this.$router.push({ name: 'reports:card', params: { key } });
      else this.$router.push({ name: 'reports' });

      this.resetFilters();
    },
  },
};
</script>
