import axios from 'axios';
import { reportXhrError } from '@/plugins/sentry';
import { getTraceId } from '@/plugins/nanoid';
import { getServiceName, isXhrError } from '@/utils/helpers';
import env from '@/plugins/env';

axios.interceptors.request.use(async config => {
  const serviceName = getServiceName(config.url);

  // Есть случаи, когда не нужно передавать 'x-trace-id' по различным причинам
  // (например , неправильно настроенные заголовки для CORS на сервере).
  // Тогда, в запросе передаем заголовок `{headers: {'x-trace-id' : null}}`
  if (config.headers['x-trace-id'] === null) {
    delete config.headers['x-trace-id'];
  } else {
    config.headers['x-trace-id'] = getTraceId(serviceName);
  }

  return config;
});

axios.interceptors.response.use(
  response => response,
  err => {
    if (err?.name === 'AbortError') return 'Request Aborted';
    const isXhr = isXhrError(err);

    try {
      if (env.get('VUE_APP_SENTRY_REPORT_XHR') && isXhr) reportXhrError(err);
    } catch (e) {}

    return Promise.reject(err);
  },
);

export default axios;
