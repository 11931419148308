import { dateTime, name } from '@/utils/convert';

export const entity = [
  { key: 'id', label: 'ID' },
  {
    key: 'name',
    label: 'Работник',
    convert: (item, getters) => {
      // if we are viewing document modal then we probably have employee already
      // loaded to list store (which is kinda inobvious ik, but it's what it's)
      if (getters['DOCUMENTS/OUTGOING_ITEM/employee']?.id === item.employeeId)
        return name(getters['DOCUMENTS/OUTGOING_ITEM/employee']);
      // otherwise search in loaded previews list
      return getters['DOCUMENTS/OUTGOING/employees'][item.employeeId] || '—';
    },
  },
  {
    key: 'type',
    label: 'Тип',
    convert: (item, getters) => {
      return getters['REGISTRY/outgoingDocumentTypes'].find(
        type => item.type === type.key,
      )?.name;
    },
    headerProps: { width: '150px' },
  },
  { key: 'serial', label: 'Номер документа' },
  {
    key: 'issuedAt',
    label: 'Выдан',
    convert: item => dateTime(item.issuedAt),
  },
  { key: 'inspectionId', label: 'ID осмотра' },
  {
    key: 'diagnosis',
    label: 'Диагноз',
    convert: item => item.diagnosis || '—',
  },
];

export const messages = {
  resourceName: 'документов',
  searchFilterLabel: 'Поиск по документам',
};

export const filters = {
  pointIds: { type: Array, default: null },
  employeeSearch: { type: String, default: null },
  employeeIds: { type: Array, default: null },
  types: { type: Array, default: null },
};
