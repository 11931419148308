<template>
  <ModernModalLayout title="Изменение времени неявки">
    <v-form :disabled="$wait('submit')">
      <MaskField
        v-model="time"
        outlined
        hide-details="auto"
        label="Минуты"
        :mask="maskPosNumber"
        :loading="$wait('submit')"
        :error-messages="getValidationErrors('time')"
        @blur="handleFieldBlur('time')"
        @keydown.enter="handleSubmit"
      />
    </v-form>
    <template #actions:append>
      <v-btn
        class="px-16"
        depressed
        color="primary"
        :loading="$wait('submit')"
        @click="handleSubmit"
      >
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import { maskPosNumber } from '@/utils/masks';
import waitable from '@/utils/mixins/waitable';
import { mapActions, mapGetters } from 'vuex';
import validationMixin from '@/utils/validation';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';

import ModernModalLayout from '@/components/layouts/ModernModalLayout.vue';
import MaskField from '@/components/controls/MaskField';

export default {
  name: 'NoShowForm',
  components: { ModernModalLayout, MaskField },
  mixins: [validationMixin, waitable],
  validations() {
    return {
      time: { required, minValue: minValue(1), maxValue: maxValue(500) },
    };
  },
  data() {
    return {
      time: null,
    };
  },
  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', ['absenceTime']),
    maskPosNumber: () => maskPosNumber,
  },

  watch: {
    absenceTime: {
      deep: true,
      immediate: true,
      handler() {
        // HACK: maskfield is throwing an error if being bassed a number
        this.time = '' + this.absenceTime.absenceMinutes;
      },
    },
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', ['updateAbsenceTime']),
    async handleSubmit() {
      if (!this.validate()) return;

      await this.$loadingNotify(
        this.updateAbsenceTime(+this.time),
        'submit',
        'Произошла ошибка изменения времени неявки организации',
        'Время неявки организации успешно изменено',
      ).then(() => this.$emit('close'));
    },
  },
};
</script>
