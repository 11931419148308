<template>
  <ItemPageLayout back-route="admin:users" :title="name">
    <template #header>
      <Card dense hide-title>
        <v-tabs v-model="currentTab">
          <v-tab href="#general">Основное</v-tab>
          <v-tab v-if="$can('BLM_UR')" href="#logs">Лог действий</v-tab>
        </v-tabs>
      </Card>
    </template>

    <div
      v-if="currentTab === 'general'"
      style="display: flex; flex-direction: column; gap: 24px"
    >
      <Card v-if="!$wait('handlerFetchRole') && !availableEditing" dense>
        <v-alert class="ma-0" type="warning" dense outlined>
          Этот пользователь вам доступен только для чтения
        </v-alert>
      </Card>

      <Card title="Пользователь">
        <h3>{{ singleItem.email }}</h3>
        <template #action>
          <CardActionBtn
            v-if="$can('EML_S') && availableEditing"
            label="Изменить"
            @click="changeEmailModal"
          />
        </template>
      </Card>

      <Card title="Роль">
        <h3>{{ role }}</h3>
        <template #action>
          <CardActionBtn
            v-if="$can('ACC_SR') && availableEditing"
            label="Изменить"
            @click="changeRoleModal"
          />
        </template>
      </Card>

      <Card title="Профиль" dense>
        <DefinitionList :value="profileData" />
        <template #action>
          <CardActionBtn
            v-if="$can('PROF_S') && availableEditing"
            label="Редактировать"
            @click="changeProfileModal"
          />
        </template>
      </Card>

      <Qrcode
        v-if="
          $can('QR_GET') && ['service-eng', 'root'].includes(singleItem.roleKey)
        "
        :qrcode="qrcode"
        store="ADMIN/USERS_ITEM"
      />

      <Card title="Доступы" dense>
        <AsyncWrapper
          v-if="singleItem.roleKey"
          :key="singleItem.roleKey"
          :handler="handlerFetchRole"
        >
          <AccountBindingsPreview
            :bindings="singleItem.bindings"
            :all-available="isAllBindingsAvailable"
          />
        </AsyncWrapper>
        <template #action>
          <v-menu
            v-if="!isAllBindingsAvailable"
            open-on-hover
            top
            max-width="700px"
          >
            <template #activator="{ on, attrs }">
              <v-btn small text icon v-bind="attrs" v-on="on">
                <v-icon small>far fa-question-circle</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text><BindingsHelp two-columns /></v-card-text>
            </v-card>
          </v-menu>
          <v-spacer />
          <CardActionBtn
            v-if="$can('BND_C') && !isAllBindingsAvailable && availableEditing"
            label="Редактировать"
            @click="changeBindingsModal"
          />
        </template>
      </Card>

      <Card v-if="$can('NF_AS')" title="Подписки" dense>
        <AsyncWrapper :handler="fetchSubscriptions">
          <Subscriptions :value="subscriptions" />
        </AsyncWrapper>
      </Card>

      <Card v-if="$can('SJ_AR')" title="Рассылки" dense>
        <AsyncWrapper :handler="fetchJobs">
          <Jobs :value="jobs" />
        </AsyncWrapper>
      </Card>
    </div>

    <template v-if="currentTab === 'general'" #aside>
      <ActionsMenu>
        <NewStatusChanger
          v-if="availableEditing"
          instance="аккаунт"
          :item="singleItem"
          store-module="ADMIN/USERS_ITEM"
          :can-enable="$can('ACC_EN')"
          :can-disable="$can('ACC_DS')"
        />
        <ActionsMenuItem
          v-if="$can('PWD_RS') && availableEditing"
          title="Сбросить пароль"
          :subtitle="resetPasswordSubtitle"
          :disabled="$wait('resettingPassword') || !singleItem.isEnabled"
          @click="resetPassword(singleItem)"
        >
          <template #icon><HowToReg /></template>
        </ActionsMenuItem>
        <ActionsMenuItem
          v-if="$can('ACC_D') && availableEditing"
          title="Удалить пользователя"
          subtitle="Действие нельзя отменить"
          @click="deleteHandler"
        >
          <template #icon><RemoveCirc /></template>
        </ActionsMenuItem>
      </ActionsMenu>
    </template>

    <template v-else-if="currentTab === 'logs'" #innerList>
      <Card v-if="$can('BLM_UR')" title="Лог действий" class="mb-4" dense>
        <ChangesList :fetch-function="fetchEventReport" />
      </Card>
    </template>
  </ItemPageLayout>
</template>

<script>
import blameApi from '@/api/services/blame';

import { mapActions, mapGetters } from 'vuex';
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import { name } from '@/utils/convert';
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';
import queryTabSave from '@/utils/mixins/queryTabSave';
import { GENDER } from '@/utils/constants';

import AccountBindingsPreview from '@/components/bindings/AccountBindingsPreview.component.vue';
import ActionsMenu from '@/components/ui/ActionsMenu.vue';
import ActionsMenuItem from '@/components/ui/ActionsMenuItem.vue';
import AsyncWrapper from '@/components/AsyncWrapper.vue';
import BindingsHelp from '@/components/bindings/BindingsHelp.vue';
import Card from '@/components/ui/Card.vue';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';
import ChangesList from '@/components/ChangesList.vue';
import DefinitionList from '@/components/DefinitionList.vue';
import ItemPageLayout from '@/components/layouts/ItemPageLayout.vue';
import Jobs from '@/components/Jobs.vue';
import NewStatusChanger from '@/components/controls/NewStatusChanger.vue';
import Subscriptions from '@/components/Subscriptions.vue';
import Qrcode from '@/components/QRCode.vue';

// icons
import RemoveCirc from '@/components/icons/remove-circle-outline.vue';
import HowToReg from '@/components/icons/how-to-reg.vue';
import { maskTextPhone } from '@/utils/masks';

export default {
  components: {
    AccountBindingsPreview,
    ItemPageLayout,
    NewStatusChanger,
    DefinitionList,
    Card,
    ActionsMenu,
    ActionsMenuItem,
    CardActionBtn,
    BindingsHelp,
    RemoveCirc,
    HowToReg,
    AsyncWrapper,
    ChangesList,
    Subscriptions,
    Jobs,
    Qrcode,
  },
  mixins: [validation, waitable, routeGuardMixin, queryTabSave()],

  data: () => ({
    currentTab: 'general',
    organization: { name: '-' }, // default name to not check it later
  }),

  computed: {
    ...mapGetters('REGISTRY', ['accountRoles']),
    ...mapGetters('ADMIN/USERS_ITEM', [
      'isAllBindingsAvailable',
      'ruleForAvailableEditByRole',
      'subscriptions',
      'jobs',
      'qrcode',
    ]),

    name() {
      return name(this.singleItem.profile);
    },

    role() {
      return (
        this.accountRoles.find(role => role.key === this.singleItem.roleKey)
          ?.name || 'key: ' + this.singleItem.roleKey
      );
    },

    profileData() {
      if (!this.singleItem?.profile) return [];
      const { profile } = this.singleItem;
      return [
        { label: 'Фамилия', value: profile.surname },
        { label: 'Имя', value: profile.name },
        { label: 'Отчество', value: profile.patronymic },
        { label: 'Организация', value: this.organization?.name },
        { label: 'Пол', value: GENDER[profile.gender] },
        {
          label: 'Телефон',
          value: maskTextPhone(profile.phone) || 'Не указан',
        },
      ];
    },

    resetPasswordSubtitle() {
      return this.singleItem.isEnabled
        ? 'Письмо с паролем придет на почту'
        : 'Сброс пароля недоступен для неактивных пользователей';
    },

    /** Если у пользователя роль не позволяет менять данные, то блочим кнопки */
    availableEditing() {
      return this.$can(this.ruleForAvailableEditByRole);
    },
  },

  watch: {
    // FIXME: can backend send us organization object within user profile?
    // so we don't fetch organization name separately all the time
    'singleItem.profile': {
      deep: true,
      handler(val, oldVal) {
        val.orgId !== oldVal.orgId && this.fetchUserOrg();
      },
    },
  },

  async created() {
    this.fetchUserOrg();

    this.$can('RL_RP') &&
      this.$loadingNotify(
        this.$store.dispatch('REGISTRY/fetchAccountRoles'),
        'loadingRoles',
        'Ошибка загрузки ролей',
      );
  },

  methods: {
    ...mapActions('ADMIN/USERS', ['createItem']),
    ...mapActions('ADMIN/USERS_ITEM', [
      'changeBindings',
      'fetchRole',
      'fetchSubscriptions',
      'fetchJobs',
    ]),

    handlerFetchRole() {
      return this.$loading(this.fetchRole(), 'handlerFetchRole');
    },

    // don't move this function to store! we don't need cache in logs.
    fetchEventReport({ page, limit }) {
      const accountId = this.singleItem.id;
      return blameApi.getAccountReport({ page, limit, accountId });
    },

    async fetchUserOrg() {
      // fetch organization of this particular user to show its name
      const organizations = await this.$store.dispatch(
        'STRUCTURES/fetchOrganizationPreviewsBy',
        [this.singleItem.profile.orgId],
      );
      this.organization = organizations[0];
    },

    async resetPassword() {
      this.$openModal('prompt', {
        yes: 'Сбросить',
        no: 'Отмена',
        title: 'Сбросить пароль',
        description:
          'Вы уверены, что хотите сбросить пароль пользователю? ' +
          'Пользователю придет письмо с новым паролем.',
        messages: {
          successfulAction: 'Пароль успешно сброшен',
          errorAction: 'Произошла ошибка сброса пароля',
        },
        onSubmit: () => this.$store.dispatch('ADMIN/USERS_ITEM/resetPassword'),
      });
    },

    deleteHandler() {
      if (this.subscriptions?.length) {
        this.$notify({
          group: 'note',
          type: 'error',
          title: 'Невозможно удалить пользователя с подпиской',
        });
        return;
      }
      this.$openModal('prompt', {
        yes: 'Удалить',
        no: 'Отмена',
        title: 'Удалить пользователя',
        description: `Вы уверены, что хотите удалить пользователя?`,
        onSubmit: () =>
          this.$store
            .dispatch('ADMIN/USERS_ITEM/deleteSingleItem', this.singleItem.id)
            .then(_ => this.$router.push({ name: 'admin:users' })),
      });
    },

    changeRoleModal() {
      this.$openModal('selectModal', {
        value: this.singleItem.roleKey,
        items: this.accountRoles,
        itemDisabled: item => !item.canBeSet,
        itemValue: 'key',
        onSubmit: async roleKey => this.changeRole(roleKey),
        messages: {
          title: 'Редактирование роли',
        },
      });
    },

    async changeRole(roleKey) {
      await this.$loadingNotify(
        this.$store.dispatch('ADMIN/USERS_ITEM/changeRole', roleKey),
        'changingRole',
        'Ошибка изменения роли пользователя ' + this.singleItem.email,
        `Роль пользователя ${this.singleItem.email} успешно изменена`,
      );
    },

    changeEmailModal() {
      this.$openModal('admin/userEmail', {
        id: this.singleItem.id,
        email: this.singleItem.email,
      });
    },

    changeProfileModal() {
      this.$openModal('admin/userProfile', {
        id: this.singleItem.id,
        value: this.singleItem.profile,
      });
    },

    changeBindingsModal() {
      this.$openModal('userBindings', {
        instance: 'пользователя',
        id: this.singleItem.id,
        value: this.singleItem.bindings,
        onSubmit: payload => this.changeBindings(payload),
      });
    },
  },
};
</script>
