import ratingsApi from '@/api/services/ratings';
import processingApi from '@/api/services/processing';

import { createModule } from '@/utils/vuex/createModule';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { changePages } from '@/utils/vuex/changePages';
import { itemFactory } from '@/utils/vuex/itemFactory';

const state = { valuations: null };

const getters = {
  evaluations: state => state.evaluations || [],
};

const mutations = {
  evaluations: (state, value) => (state.evaluations = value),
};

const actions = {
  async patchEvaluations({ commit }, w) {
    const payload = {
      remark: w.remark,
      revision: w.revision,
      value: w.value,
    };
    const response = await ratingsApi.patchEvaluations(w.id, payload);
    commit('listUpdateItem', response);
  },

  // redefine method of crudListFactory
  async fetchList({ commit, getters, dispatch }, query) {
    await changePages(getters, commit, query);

    try {
      commit('listFetching');
      // fetch remarks only on first list load
      !getters.inspectionRemarks.length &&
        (await dispatch('fetchInspectionRemarks'));
      // reponse below is not for lists, but we adopt it
      const response = await ratingsApi.getEvaluations();
      commit('listFetched', {
        items: response,
        total: response.length,
        limit: 50,
        page: 1,
      });
    } catch (error) {
      commit('listErrorFetched', error);
      throw error;
    }
  },
};

export default createModule(
  crudListFactory(),

  /**
   * Get inspection remarks
   *
   * action: fetchInspectionRemarks
   * getter: inspectionRemarks
   */
  itemFactory({
    name: 'inspectionRemarks',
    fetchMethod: () => processingApi.getRemarks(),
  }),

  { state, getters, mutations, actions },
);
