<template>
  <div
    ref="dropzone"
    class="image-drop__container"
    :class="{ 'error--text': errorMessage }"
    @dragover.prevent
    @dragleave="dragleave"
    @dragenter="dragenter"
    @drop="drop"
  >
    <v-btn @click="$refs.filebtn.click()">
      <v-icon left dark>mdi-cloud-upload</v-icon>
      {{ label }}
    </v-btn>
    <slot name="description">
      <p class="mt-3 text-center" style="line-height: 1.6">
        Нажмите на кнопку или
        <br />
        перенесите файл в эту область.
      </p>
    </slot>
    <span v-if="errorMessage" class="mt-3 text-center">{{ errorMessage }}</span>

    <input
      ref="filebtn"
      class="filebtn"
      type="file"
      :accept="
        validatedAccept &&
        [...validatedAccept.extensions, ...validatedAccept.mimetypes].join(',')
      "
      @input="upload"
    />
  </div>
</template>

<script>
import { PDFDocument } from 'pdf-lib';
export default {
  props: {
    accept: { type: String, default: 'image/jpeg,image/png,image/bmp' },
    label: { type: String, default: 'Загрузить документ' },
    errorMessages: { type: Array, default: () => [] },
    sizeLimit: { type: Number, default: null },
  },
  data: () => ({
    hoverCounter: 0,
    hoveringContent: null,
    files: [],
  }),
  computed: {
    errorMessage() {
      return (this.errorMessages || []).join(', ');
    },
    filebtn: {
      cache: false,
      get() {
        return this.$refs.filebtn;
      },
    },
    dropzone: {
      cache: false,
      get() {
        return this.$refs.dropzone;
      },
    },
    validTypes() {
      if (this.validatedAccept) {
        return {
          extensions: this.validatedAccept.extensions
            .map(ext => ext.replace(/(\W)/g, '\\$1')) // Escape all potential regex tokens
            .map(rgxstr => new RegExp(`${rgxstr}$`, 'i')), // Transform into regex to look for extension
          mimetypes: this.validatedAccept.mimetypes
            .map(mt => mt.replace(/([-+/])/g, '\\$1')) // Escape special characters
            .map(mt => mt.replace(/\*/g, '(?:[A-Za-z0-9\\-\\+]*)*')) // Enable wildcards
            .map(rgxstr => new RegExp(`^${rgxstr}$`)), // Transform into regex
        };
      } else {
        // If we haven't been given any filters...
        return {
          extensions: [/.*/],
          mimetypes: [/.*/],
        };
      }
    },
    validatedAccept() {
      if (this.accept) {
        return {
          extensions: this.accept
            .split(',')
            .filter(type => type.match(/^\.(?!.*\/)/)), // Get only extension filters
          mimetypes: this.accept
            .split(',')
            .filter(type =>
              type.match(/^(?:[A-Za-z0-9\-+]*|\*)\/(?:[A-Za-z0-9\-+.]*|\*)$/),
            ), // Get only mimetype filters
        };
      } else {
        return null;
      }
    },
  },
  watch: {
    files(val) {
      this.$emit('change', val[val.length - 1]);
    },
    hoveringContent(val) {
      // If a file is hovering
      if (val) {
        // If we have type checking and we're using mimetypes only
        if (
          this.accept &&
          this.accept.length &&
          this.validTypes.extensions.length === 0
        ) {
          let shouldDim = false;
          // For each file hovering over the box...
          for (let i = 0; i < val.length; i++) {
            if (
              // Check the type against all our mime types
              this.validTypes.mimetypes.reduce(
                (prev, regex) => prev || !!val[i].type.match(regex),
              )
            ) {
              shouldDim = true;
              break;
            }
          }
          // If we found a match, dim the box
          if (shouldDim) {
            this.dropzone.style.backgroundColor = 'rgba(0, 0, 0, 0.25)';
          }
          // If not, we can't definitively typecheck, so...
        } else {
          // Check that we have a file in there
          let shouldDim = false;
          for (let i = 0; i < val.length; i++) {
            if (val[i].kind === 'file') {
              shouldDim = true;
              break;
            }
          }
          // ... and dim the box
          if (shouldDim) {
            this.dropzone.style.backgroundColor = 'rgba(0, 0, 0, 0.25)';
          }
        }
        // Otherwise...
      } else {
        // Un-dim the box
        this.dropzone.style.backgroundColor = '';
      }
    },
    hoverCounter(val) {
      if (val === 0) {
        this.hoveringContent = null;
      }
    },
  },
  methods: {
    upload() {
      const file = this.filebtn?.files[0] ?? [];
      this.isValid(file).then(validate => {
        if (validate) {
          this.files.push(file);
        } else {
          this.$emit('rejectedFiles', [file]);
        }
        this.filebtn.value = '';
      });
    },
    dragenter(e) {
      this.hoveringContent = e.dataTransfer.items;
      this.hoverCounter++;
    },
    /** Counts leave events (fix for event rippling issues) */
    dragleave() {
      this.hoverCounter--;
    },
    /** Validates and keeps track of dropped content */
    drop(e) {
      e.preventDefault(); // Keep from leaving the page
      this.hoverCounter = 0; // Content can't be dragged out, so go ahead and reset the counter
      if (e.dataTransfer.items) {
        const rejected = []; // Keeps track of rejected items for reporting at the end
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          if (e.dataTransfer.items[i].kind === 'file') {
            // Directories are not supported. Skip any that are found
            if (e.dataTransfer.items[i].webkitGetAsEntry) {
              const entry = e.dataTransfer.items[i].webkitGetAsEntry();
              if (entry.isDirectory) {
                rejected.push(entry.name);
                continue;
              }
            }
            const file = e.dataTransfer.items[i].getAsFile();
            if (file) {
              this.isValid(file).then(validate => {
                if (validate) {
                  this.files.splice(0, this.files.length);
                  this.files.push(file);
                } else {
                  rejected.push(file); // Keep track of rejected files
                }
                // Emit rejected files
                if (rejected.length) {
                  this.$emit('rejectedFiles', rejected);
                }
              });
            }
          }
        }
      }
    },

    async isValid(file) {
      const baseValidate = () => {
        let shouldPush =
          this.validTypes.extensions.reduce(
            (prev, regex) => prev || !!file.name.match(regex),
            false,
          ) ||
          this.validTypes.mimetypes.reduce(
            (prev, regex) => prev || !!file.type.match(regex),
            false,
          );
        if (this.sizeLimit && file.size > this.sizeLimit) {
          this.$emit('fileSizeErr', file);
          shouldPush = false;
        }
        return shouldPush;
      };

      const reader = new FileReader();

      const validatePdf = async () =>
        new Promise(resolve => {
          reader.onloadend = async e => {
            try {
              const buffer = new Uint8Array(e.target.result);
              const pdfDoc = await PDFDocument.load(buffer);
              if (!pdfDoc.catalog) {
                this.$emit('errorCheckPdfSignature');
              }
              resolve(!!pdfDoc.catalog);
            } catch {
              this.$emit('errorCheckPdfSignature');
            }
          };
          reader.readAsArrayBuffer(file);
        });

      const shouldPush = baseValidate();
      if (file.type === 'application/pdf') {
        return !!((await validatePdf()) && shouldPush);
      }
      return shouldPush;
    },

    /** Removes attachment per user's request */
    remove(file) {
      const arr = this.files;
      arr.splice(arr.indexOf(file), 1);
      this.$emit('update', null);
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  font-size: 0.75em;
  font-weight: 400;
}
.image-drop__container {
  display: flex;
  height: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px dashed #9e9e9e;
  border-radius: 4px;
  overflow: hidden;
  transition: background-color 0.2s;
}
div.input-container {
  min-width: 50%;
}
.v-input::v-deep div.v-input__control {
  div.v-input__slot {
    margin-top: 4px;
    margin-bottom: 0 !important;
  }
  div.v-messages {
    display: none;
  }
}
input.filebtn {
  display: none;
}

.image-drop__container.error--text {
  border-color: #ff5252;
}
</style>
