<template>
  <div>
    <v-row v-for="(item, index) in value[field]" :key="item.parent.id || index">
      <v-col cols="3">
        <OrgAndOrgGroupSelect
          :value="item.parent"
          :readonly="readonly"
          :clearable="!readonly"
          :error-messages="
            getValidationErrors(
              'value.' + field + '.$each.' + index + '.parent',
            )
          "
          @blur="
            validateField('value.' + field + '.$each.' + index + '.parent')
          "
          @input="
            handleChangeInspectionsBinding(index, {
              ...item,
              parent: $event,
              employeeGroups: null,
              hostGroups: null,
              points: null,
            })
          "
        />
      </v-col>
      <v-col cols="4">
        <EmployeeGroupSelect
          :id="item.parent.id"
          :id-type="item.parent.type"
          multiple
          chips
          small-chips
          deletable-chips
          :readonly="readonly"
          :clearable="!readonly"
          :disabled="!item.parent.id"
          :value="item.employeeGroups"
          @input="
            handleChangeInspectionsBinding(index, {
              ...item,
              employeeGroups: $event,
            })
          "
        />
      </v-col>
      <v-col cols="4">
        <HostGroupAndPointSelect
          :id="item.parent.id"
          :type="item.parent.type"
          :readonly="readonly"
          :clearable="!readonly"
          multiple
          chips
          small-chips
          deletable-chips
          :disabled="!item.parent.id"
          :value="{
            points: item.points,
            hostGroups: item.hostGroups,
          }"
          @input="handleChangeInspectionsBinding(index, { ...item, ...$event })"
        />
      </v-col>
      <v-col v-if="!readonly" cols="1" class="pt-6">
        <v-btn
          icon
          small
          color="error"
          @click="handleRemoveInspectionsBinding(index)"
        >
          <v-icon size="18">fa-trash</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <div v-if="!readonly" class="mt-4 text-center">
      <v-btn depressed color="primary" @click="handleAddInspectionsBinding">
        + {{ addButtonText || 'Добавить' }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators';
import validationMixin from '@/utils/validation';
import EmployeeGroupSelect from '@/components/controls/structures/EmployeeGroupSelect';
import HostGroupAndPointSelect from '@/components/controls/structures/HostGroupAndPointSelect';
import OrgAndOrgGroupSelect from '@/components/controls/structures/OrgAndOrgGroupSelect';

export default {
  components: {
    OrgAndOrgGroupSelect,
    EmployeeGroupSelect,
    HostGroupAndPointSelect,
  },

  mixins: [validationMixin],

  validations() {
    return {
      value: {
        inspections: {
          $each: {
            parent: {
              required: requiredIf(() => this.field === 'inspections'),
            },
          },
        },
        filters: {
          $each: {
            parent: { required: requiredIf(() => this.field === 'filters') },
          },
        },
      },
    };
  },

  props: {
    value: { type: Object, required: true },
    field: { type: String, default: 'inspections' },
    addButtonText: { type: String, default: null },
    readonly: { type: Boolean, default: false },
  },

  data: () => ({}),

  methods: {
    handleAddInspectionsBinding() {
      this.$emit('input', {
        ...this.value,
        [this.field]: [
          ...this.value[this.field],
          {
            parent: {},
            points: null,
            hostGroups: null,
            employeeGroupIds: null,
          },
        ],
      });
    },
    handleChangeInspectionsBinding(index, inspectionItem) {
      this.$emit('input', {
        ...this.value,
        [this.field]: [
          ...this.value[this.field].slice(0, index),
          {
            parent: inspectionItem.parent || {},
            points: inspectionItem.points?.length
              ? inspectionItem.points
              : null,
            hostGroups: inspectionItem.hostGroups?.length
              ? inspectionItem.hostGroups
              : null,
            employeeGroups: inspectionItem.employeeGroups?.length
              ? inspectionItem.employeeGroups
              : null,
          },
          ...this.value[this.field].slice(index + 1),
        ],
      });
    },
    handleRemoveInspectionsBinding(index) {
      this.$emit('input', {
        ...this.value,
        [this.field]: [
          ...this.value[this.field].slice(0, index),
          ...this.value[this.field].slice(index + 1),
        ],
      });

      this.$nextTick(() => {
        if (!(this.value[this.field] || []).length && !index)
          this.handleAddInspectionsBinding();
      });
    },
  },
};
</script>
