import Vue from 'vue';
import store from '@/store';
import env from './env';

/**
 * Check if user has specific action
 * @param {*} action Action name, usually in uppercase
 */
export function can(action) {
  env.get('VUE_APP_CAN_DEBUG') &&
    // eslint-disable-next-line no-console
    console.log(`Can: ${action}, ${store.getters['AUTH/can'](action)}`);

  // we use store, not this.$store, because context in function can be lost
  return store.getters['AUTH/can'](action);
}

const canPlugin = {
  install(_Vue) {
    Vue.prototype.$can = can;
    store.$can = can;
  },
};

Vue.use(canPlugin);

export default canPlugin;
