<template>
  <v-list-item
    color="primary"
    :input-value="item.id === currentId"
    @click="changeCurrent"
  >
    <v-list-item-content>
      <div
        v-if="item"
        class="inspection-item__container d-flex flex-wrap align-center"
        style="min-height: 70px"
      >
        <div class="inspection-item__left flex-shrink-0">
          <div class="d-flex flex-column">
            <strong class="mb-1">#{{ item.id }}</strong>
            <span title="Время терминала">{{
              dateTime(item.timestamps.processedAt, {
                timezone: item.timezoneOffsetMin / 60,
              })
            }}</span>
          </div>
        </div>
        <div class="inspection-item__right text-body-2 flex-shrink-0">
          <ResultBadge
            :result="item.resolution.success"
            :remarks="item.resolution.remarks"
            :remark-types="remarkTypes"
            :type="item.type"
          />
          <b>{{ item.type.name }}</b>
        </div>
        <div
          v-if="remarkString || (item.resolution && item.resolution.comment)"
          class="inspection-item__full text-body-2 mt-2"
        >
          <div v-if="remarkString">{{ remarkString }}</div>
          <div v-if="item.resolution && item.resolution.comment">
            <b>Комментарий:</b>
            {{ item.resolution.comment }}
          </div>
        </div>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { dateTime } from '@/utils/convert';
import ResultBadge from '@/modules/inspections/components/ResultBadge.vue';

export default {
  components: { ResultBadge },

  props: {
    item: { required: true, type: Object, default: () => ({}) },
    remarks: { required: true, type: Array, default: () => [] },
    remarkTypes: { required: true, type: Object },
    currentId: { type: Number, default: null },
  },

  computed: {
    remarkString() {
      const remarks = this.item.resolution?.remarks || [];
      if (!remarks.length) return null;
      return remarks
        .map(el => this.remarks.find(remark => remark.id === el)?.name || null)
        .filter(a => a)
        .join(', ');
    },
  },

  methods: {
    dateTime,

    changeCurrent() {
      if (this.currentId === this.item.id) return;
      this.$emit('changeCurrent', this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
// Поскольку компонент предназначен для использования в цикле, не буду инлайнить стили
.inspection-item__container {
  min-height: 70px;
}
.inspection-item__left {
  width: 40%;
}
.inspection-item__right {
  width: 60%;
}
.inspection-item__full {
  width: 100%;
}
</style>
