import RouteGuard from '@/components/RouteGuard';

export default [
  {
    path: '/inspections',
    name: 'inspections',
    component: () => import('@/views/Provider.vue'),
    redirect: { name: 'inspections:list' },
    children: [
      {
        path: '',
        name: 'inspections:list',
        component: () => import('@/modules/inspections/views/list.vue'),
        meta: {
          action: 'I_R',
        },
      },
      {
        path: 'archived/:id',
        name: 'inspections:card:archived',
        component: RouteGuard({
          storeModule: 'INSPECTION_ARCHIVED',
          component: () =>
            import('@/modules/inspections/views/archived.view.vue'),
        }),
        meta: {
          action: 'I_RD',
        },
      },
      {
        path: ':id',
        name: 'inspections:card',
        component: RouteGuard({
          storeModule: 'INSPECTION',
          component: () => import('@/modules/inspections/views/view.vue'),
        }),
        meta: {
          action: 'I_RD',
        },
      },
    ],
  },
];
