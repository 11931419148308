import historyApi from '@/api/services/history';
import medrecApi from '@/api/services/medrec';

import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

const state = {
  medRecord: {},
  measurementsHistory: [],
  outgoingDocs: [],
};

const getters = {
  medRecord: state => state.medRecord,
  employee: ({ singleItem }) => singleItem.employee || null,
  measurementsHistory: state => state.measurementsHistory,
  outgoingDocs: state => state.outgoingDocs,
};

const mutations = {
  medRecord: (state, value) => (state.medRecord = value),
  outgoingDocs: (state, value) => (state.outgoingDocs = value),
  measurementsHistory: (state, value) => {
    state.measurementsHistory = value;
  },
};

const actions = {
  async getMedRecord({ commit }, id) {
    const response = await medrecApi.getEmployeeRecord(id);
    commit('medRecord', response || []);
  },

  async fetchOutgoingDocs({ getters, commit }) {
    const response = await medrecApi.getInspectionOutgoingDocs(
      getters.singleItem.id,
    );
    commit('outgoingDocs', response);
  },

  async getEmployeeAverages({ commit }, id) {
    const response = await historyApi.getEmployeeHistory(id);
    commit('measurementsHistory', response || []);
  },
};

export default createModule(
  crudSingleFactory({
    fetchMethod: historyApi.inspectionArchived,
    linkedList: 'INSPECTION_LIST',
  }),
  { state, getters, mutations, actions },
);
