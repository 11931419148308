<template>
  <ItemPageLayout back-route="structures:organizations" :title="item.name">
    <template #header>
      <Card dense hide-title>
        <v-tabs v-model="currentTab" show-arrows>
          <v-tab href="#general">Основное</v-tab>
          <v-tab v-if="$can('EMP_R')" href="#employees">Работники</v-tab>
          <v-tab v-if="$can('EMPG_R')" href="#employee-groups">
            Группы работников
          </v-tab>
          <v-tab v-if="$can('HSTG_R')" href="#host-groups">Группы ПАК</v-tab>
          <v-tab v-if="$can('PNT_R')" href="#points">Точки выпуска</v-tab>
          <v-tab href="#settings">Настройки организации</v-tab>
          <v-tab v-if="showAdditionalTab" href="#additional">Доп. услуги</v-tab>
          <v-tab v-if="$can('LM_R')" href="#limits"> Лимиты </v-tab>
          <v-tab v-if="$can('BLM_ER')" href="#logs">Лог изменений</v-tab>
        </v-tabs>
      </Card>
    </template>

    <template v-if="currentTab === 'general'">
      <Card title="Информация" dense>
        <DefinitionList :value="organizationData" />
        <template #action>
          <CardActionBtn
            v-if="$can('ORG_U')"
            label="Изменить"
            @click="editInfoModal"
          />
        </template>
      </Card>

      <Card title="Обслуживающая организация" dense>
        <DefinitionList :value="serviceMedicalData" />
        <template #action>
          <CardActionBtn
            v-if="$can('ORG_SC')"
            label="Изменить"
            @click="editCategory"
          />
        </template>
      </Card>

      <Card title="Группы">
        <NoDataAlert v-if="!orgGroupsAsButton.length"> Не заданы </NoDataAlert>
        <div v-else>
          <LinkButtonList :list="orgGroupsAsButton" />
        </div>
        <template #action>
          <CardActionBtn
            v-if="$can('OG_SB')"
            label="Изменить"
            @click="editGroups"
          />
        </template>
      </Card>

      <Card title="Холдинг">
        <NoDataAlert v-if="!singleItem.holding"> Не задан </NoDataAlert>
        <div v-else>
          <LinkButton
            :text="singleItem.holding.name"
            :action="$can('OG_RD')"
            :to="{
              name: 'structures:organization_groups:item',
              params: { id: singleItem.holding.id },
            }"
          />
        </div>
        <template #action>
          <CardActionBtn
            v-if="$can('ORG_SH')"
            label="Изменить"
            @click="editHolding"
          />
        </template>
      </Card>

      <Card title="Гостевые привязки" dense>
        <AsyncWrapper :handler="fetchGuestsParentOrgs">
          <Guests :guests="guests" :parent-orgs="parentOrgs" />
        </AsyncWrapper>
        <template #action>
          <CardActionBtn
            v-if="$can('OGU_A')"
            label="Изменить"
            @click="openGuestsModal"
          />
        </template>
      </Card>

      <Card v-if="$can('SOS_G')" title="Время неявки на повторный осмотр" dense>
        <AsyncWrapper :handler="fetchAbsenceTime">
          <div class="ma-4 grey--text">
            {{ absenceTimeText }}
          </div>
        </AsyncWrapper>
        <template #action>
          <div>
            <CardActionBtn
              v-if="$can('SOS_U')"
              label="Изменить"
              @click="editNoShowTime"
            />
            <CardActionBtn
              v-if="$can('SOS_U')"
              label="Сбросить"
              @click="promptAbsenceTimeReset"
            />
          </div>
        </template>
      </Card>

      <Card v-if="rulesRelatedProfiles" title="Привязанные профили" dense>
        <AsyncWrapper :handler="fetchRelatedProfiles">
          <RelatedProfiles :value="relatedProfiles" />
        </AsyncWrapper>
      </Card>
      <Card v-if="$can('GT_GOPR')" title="Профили конфигураций" dense>
        <AsyncWrapper :handler="fetchConfigProfiles">
          <ConfigurationProfiles :value="configProfiles" />
        </AsyncWrapper>
      </Card>
      <Card v-if="$can('O_GBND')" title="Границы медицинских показателей" dense>
        <AsyncWrapper :handler="fetchBoundaries">
          <NoDataAlert v-if="!boundaries" class="ma-4">
            Индивидуальные границы медицинсих показателей не заданы
          </NoDataAlert>
          <DefinitionList
            v-else
            :value="boundariesData"
            :width-columns="['55%', '45%']"
          />
        </AsyncWrapper>
        <template #action>
          <div class="d-flex">
            <CardActionBtn
              v-if="canEditBoundaries"
              label="Изменить"
              @click="openBoundariesModal"
            />
            <CardActionBtn
              v-if="$can('O_DBND') && !!boundaries"
              label="Удалить"
              @click="resetBoundariesHandler"
            />
          </div>
        </template>
      </Card>
    </template>

    <template v-if="currentTab === 'general'" #aside>
      <ItemAside :org="singleItem" />
    </template>

    <!-- TABS -->
    <template v-if="currentTab !== 'general'" #innerList>
      <TabEmployee v-if="currentTab === 'employees'" :org="singleItem" />
      <TabEmployeeGroup
        v-if="currentTab === 'employee-groups'"
        :org-id="singleItem.id"
      />
      <TabPoints v-if="currentTab === 'points'" :org-id="singleItem.id" />
      <TabHostGroups
        v-if="currentTab === 'host-groups'"
        :org-id="singleItem.id"
      />
      <TabSettings v-if="currentTab === 'settings'" />
      <TabAdditional
        v-if="currentTab === 'additional'"
        :org-id="singleItem.id"
      />
      <TabLimits
        v-if="currentTab === 'limits'"
        :org-or-group-id="singleItem.id"
        :org="singleItem"
      />
      <TabLogs v-if="currentTab === 'logs'" :org-id="singleItem.id" />
    </template>
  </ItemPageLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import limitsApi from '@/api/services/limits';
import structApi from '@/api/services/structures';
import waitable from '@/utils/mixins/waitable';
import queryTabSave from '@/utils/mixins/queryTabSave';
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';
import plural from 'plural-ru';
import { name } from '@/utils/convert';
import { LEGAL_FORM } from '@/utils/constants';

import { filters as employeesFilters } from '@/modules/employees/entity';
import { filters as employeeGroupsFilters } from '@/modules/structures/employees-groups/entity';
import { filters as pointsFilters } from '@/modules/structures/points/entity';
import { filters as hostGroupsFilters } from '@/modules/structures/hosts-groups/entity';

import ItemPageLayout from '@/components/layouts/ItemPageLayout';
import DefinitionList from '@/components/DefinitionList';
import AsyncWrapper from '@/components/AsyncWrapper';
import Card from '@/components/ui/Card';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';
import LinkButton from '@/components/controls/buttons/LinkButton';
import LinkButtonList from '@/components/controls/buttons/LinkButtonList.vue';
import OrganizationGroupSelect from '@/components/controls/structures/OrganizationGroupSelect';
import RelatedProfiles from '../components/RelatedProfiles.vue';
import ConfigurationProfiles from '../components/ConfigurationProfiles.vue';
import Guests from '../components/Guests.vue';
import ContactInfo from '../components/ContactInfo.vue';
import ItemAside from '../components/ItemAside';

import TabEmployee from './item-tabs/employee';
import TabEmployeeGroup from './item-tabs/employee-group';
import TabPoints from './item-tabs/points';
import TabHostGroups from './item-tabs/host-groups';
import TabSettings from './item-tabs/settings';
import TabAdditional from './item-tabs/additional';
import TabLimits from '@/components/limits/LimitList.vue';
import TabLogs from './item-tabs/logs';
import NoDataAlert from '@/components/ui/NoDataAlert';

export default {
  components: {
    ItemPageLayout,
    DefinitionList,
    ItemAside,
    AsyncWrapper,
    Card,
    CardActionBtn,
    RelatedProfiles,
    ConfigurationProfiles,
    LinkButton,
    LinkButtonList,
    Guests,
    NoDataAlert,

    // Lists
    TabEmployee,
    TabEmployeeGroup,
    TabPoints,
    TabHostGroups,
    TabSettings,
    TabAdditional,
    TabLimits,
    TabLogs,
  },

  mixins: [waitable, routeGuardMixin, queryTabSave()],

  data: () => ({
    currentTab: 'general',
    // needed for queryTabSave mixin to work on this page
    embeddedTabFilters: {
      employees: {
        storeName: 'STRUCTURES/EMBEDDED_EMPLOYEE_LIST',
        filters: employeesFilters,
      },
      'employee-groups': {
        storeName: 'STRUCTURES/EMBEDDED_EMPLOYEE_GROUPS_LIST',
        filters: employeeGroupsFilters,
      },
      points: {
        storeName: 'STRUCTURES/EMBEDDED_POINTS_LIST',
        filters: pointsFilters,
      },
      'host-groups': {
        storeName: 'STRUCTURES/EMBEDDED_HOST_GROUPS_LIST',
        filters: hostGroupsFilters,
      },
    },
    binidngsLoaded: false,
  }),

  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', [
      'relatedProfiles',
      'configProfiles',
      'guests',
      'parentOrgs',
      'absenceTime',
      'showFindFace',
      'boundaries',
    ]),

    rulesRelatedProfiles() {
      const { $can } = this;
      return $can('BPC_P') || $can('MR_PP') || $can('ST_PP') || $can('PPR_L');
    },

    organizationData() {
      if (!this.singleItem) return [];
      const contacts = this.singleItem.isMedical
        ? this.singleItem.contactPersons.map((v, i) => ({
            label: `Контактное лицо ${i + 1}`,
            component: ContactInfo,
            value: v,
          }))
        : [];
      return [
        { label: 'ID', value: this.singleItem.id },
        { label: 'Название', value: this.singleItem.name },
        { label: 'Краткое наименование', value: this.singleItem.shortName },
        {
          label: 'Правовая форма',
          value: LEGAL_FORM.find(item => item.id === this.singleItem.legalForm)
            ?.name,
        },
        { label: 'ИНН', value: this.singleItem.inn },
        { label: 'КПП', value: this.singleItem.kpp },
        {
          label: this.singleItem.legalForm === 'llc' ? 'ОГРН' : 'ОГРНИП',
          value: this.singleItem.ogrn,
        },
        { label: 'ФИО', value: name(this.singleItem.selfEmployedFullName) },
        {
          label: 'Статус',
          value: this.singleItem.isActive ? 'Активна' : 'Неактивна',
        },
        {
          label: 'С медработниками',
          value: this.singleItem.isMedical ? 'Да' : 'Нет',
        },
        ...contacts,
        { label: 'Лицензия', value: this.singleItem.medLicense.license },
        {
          label: 'Дата выдачи лицензии',
          value: this.singleItem.medLicense.issuedAt,
        },
      ];
    },

    serviceMedicalData() {
      const medOrg = this.singleItem.medOrg;
      const category = this.singleItem.category || {};
      const orgSelfHosted = medOrg?.id === this.singleItem.id;

      return [
        {
          label: 'Обслуживающая мед. организация',
          ...((orgSelfHosted && { value: 'Самостоятельно' }) || {
            component: LinkButton,
            props: {
              text: medOrg.name || `#${medOrg.id}`,
              action: this.$can('ORG_RD'),
              to: {
                name: 'structures:organizations:item',
                params: { id: medOrg.id },
              },
            },
          }),
        },
        {
          label: 'Название категории',
          ...((!category.id && { value: 'Не указана' }) || {
            component: LinkButton,
            props: {
              text: category.name || `#${category.id}`,
              action: this.$can('ORC_RD'),
              to: {
                name: 'structures:organization_categories:item',
                params: { id: category.id },
              },
            },
          }),
        },
      ];
    },

    absenceTimeText() {
      if (!this.absenceTime) return '';

      const minutesPluralized = plural(
        this.absenceTime.absenceMinutes,
        'минута',
        'минуты',
        'минут',
      );

      if (this.absenceTime.isDefault)
        return `${this.absenceTime.absenceMinutes} ${minutesPluralized} (По умолчанию)`;
      return this.absenceTime.absenceMinutes + ' ' + minutesPluralized;
    },

    orgGroupsAsButton() {
      return this.singleItem.groups.map(el => ({
        text: el.name || `#${el.id}`,
        action: this.$can('OG_RD'),
        to: {
          name: 'structures:organization_groups:item',
          params: { id: el.id },
        },
      }));
    },

    showAdditionalTab() {
      return this.showFindFace || this.$can('RGS_G');
    },

    boundariesData() {
      return [
        {
          label: 'Систолическое артериальное давление, мм рт. ст',
          value: `${
            this.boundaries?.values.pressure?.systolic?.lower || ''
          } – ${this.boundaries?.values.pressure?.systolic?.upper || ''}`,
        },
        {
          label: 'Диастолическое артериальное давление, мм рт. ст',
          value: `${
            this.boundaries?.values.pressure?.diastolic?.lower || ''
          } – ${this.boundaries?.values.pressure?.diastolic?.upper || ''}`,
        },
        {
          label: 'Пульс, уд./мин',
          value: `${this.boundaries?.values.pulse?.lower || ''} – ${
            this.boundaries?.values.pulse?.upper || ''
          }`,
        },
        {
          label: 'Пропускать термометрию',
          value: this.boundaries?.values.checkTemperature ? 'Да' : 'Нет',
        },
        {
          label: 'Комментарий',
          value: this.boundaries?.comment,
        },
      ];
    },

    canEditBoundaries() {
      // Если границы не заданы, проверяем доступ на добавление
      // Если заданы - на обновление
      return (
        (!this.boundaries && this.$can('O_PBND')) ||
        (this.boundaries && this.$can('O_UBND'))
      );
    },
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', [
      'fetchGuests',
      'fetchParentOrgs',
      'fetchAbsenceTime',
      'resetAbsenceTime',
      'fetchRelatedProfiles',
      'fetchConfigProfiles',
      'fetchBoundaries',
      'resetBoundaries',
    ]),
    ...mapActions('STRUCTURES/ORGANIZATION_GROUPS', {
      changeCountOnOrgGroups: 'changeCounter',
    }),

    async fetchGuestsParentOrgs() {
      if (this.$can('OGU_R')) await this.fetchGuests();
      if (this.$can('WOIG_R')) await this.fetchParentOrgs();
    },

    openGuestsModal() {
      this.$openModal('structures/organizationsGuests');
    },

    changeCounter(oldIds, newIds) {
      this.changeCountOnOrgGroups({
        itemIds: oldIds,
        counterField: 'membersCount',
        addCount: -1,
      });
      this.changeCountOnOrgGroups({
        itemIds: newIds,
        counterField: 'membersCount',
        addCount: 1,
      });
    },

    editGroups() {
      // this func fires 2 same requests cuz that's the easiest and the least
      // break-prone way of doing it. Instead you could make a var that would
      // contain pre-formed message from 1st request etc.. but then you need to
      // figure out when and where to clear the error message var since user
      // can change chosen groups and fetching will be needed anew.
      const formErrMessage = async err => {
        let groups = await structApi.getOrganizationGroupPreviewsBy(
          err.response.data.inner.orgGroupIds,
        );
        groups = groups.map(group => `ID ${group.id} ${group.name}`).join(', ');

        return err.response.data.message + ' ' + groups;
      };

      this.$openModal('asyncSelectModal', {
        component: OrganizationGroupSelect,
        componentProps: {
          isHolding: false,
          type: 'public',
          accessLevel: 'full',
        },
        value: this.singleItem.groups,
        multiple: true,
        onSubmit: async groups => {
          const oldIds = this.singleItem.groups.map(group => group.id);

          const newGroups = groups.filter(e => !oldIds.find(g => g === e));
          // check for double limits / exceeded limits
          newGroups.length &&
            (await limitsApi
              .checkGroupLimits(this.singleItem.id, newGroups)
              .catch(async err => {
                const msg = await formErrMessage(err);
                throw new Error(msg);
              }));

          await this.$loadingNotify(
            this.$store.dispatch(
              'STRUCTURES/ORGANIZATION_ITEM/updateGroups',
              groups,
            ),
            'updateGroups',
            'Произошла ошибка обновления групп',
            'Группы успешно обновлены',
          );
          this.changeCounter(oldIds, groups);
        },
        messages: {
          title: 'Редактирование групп организации',
          warning: `Группы организаций отсутствуют`,
        },
      });
    },

    editHolding() {
      this.$openModal('asyncSelectModal', {
        component: OrganizationGroupSelect,
        componentProps: {
          isHolding: true,
          accessLevel: 'full',
        },
        value: this.singleItem.holding?.id,
        onSubmit: async holding => {
          const oldIds = this.singleItem.holding?.id
            ? [this.singleItem.holding?.id]
            : [];
          const newIds = holding ? [holding] : [];
          await this.$loadingNotify(
            this.$store.dispatch(
              'STRUCTURES/ORGANIZATION_ITEM/updateHolding',
              holding,
            ),
            'updateHolding',
            'Произошла ошибка обновления холдинга',
            'Холдинг успешно изменен',
          );
          this.changeCounter(oldIds, newIds);
        },
        messages: {
          title: 'Редактирование холдинга организации',
          warning: `Холдинги организаций отсутствуют`,
        },
      });
    },

    editNoShowTime() {
      this.$openModal('structures/noShowTime');
    },

    editCategory() {
      this.$openModal('structures/organizationChangeCategory', {
        value: this.singleItem,
      });
    },

    editInfoModal() {
      this.$openModal('structures/organizationForm', {
        value: this.singleItem,
      });
    },

    openBoundariesModal() {
      this.$openModal('structures/organizationBoundaries');
    },

    promptAbsenceTimeReset() {
      this.$openModal('prompt', {
        title: 'Сброс времени неявки',
        description: 'Вы точно хотите установить время неявки по умолчанию?',
        messages: {
          successfulAction: 'Время неявки успешно установлено по умолчанию',
          errorAction: 'Произошла ошибка установки времени неявки по умолчанию',
        },
        onSubmit: () => this.resetAbsenceTime(),
      });
    },

    resetBoundariesHandler() {
      this.$openModal('prompt', {
        yes: 'Удалить',
        no: 'Отмена',
        title: 'Удалить границы',
        description:
          'Вы уверены, что хотите удалить границы медицинских показателей?',
        onSubmit: this.resetBoundaries,
      });
    },
  },
};
</script>
