<template>
  <div class="additional__container d-flex flex-column">
    <FindFaceCard v-if="showFindFace" />
    <SurveyCard v-if="showSurveys" />
    <RisksCard v-if="$can('RGS_G')" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FindFaceCard from '@/modules/structures/organizations/components/FindFaceCard';
import SurveyCard from '@/modules/structures/organizations/components/SurveyCard';
import RisksCard from '@/modules/structures/organizations/components/RisksCard';

export default {
  components: { FindFaceCard, SurveyCard, RisksCard },
  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', [
      'showFindFace',
      'showSurveys',
    ]),
  },
};
</script>

<style scoped>
.additional__container {
  width: 700px;
  margin: 0 auto;
  gap: 24px;
}
</style>
