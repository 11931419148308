<template>
  <ModernModalLayout size="medium" title="Изменить номер телефона">
    <PhoneField
      v-model="newPhone"
      hide-details="auto"
      outlined
      :disabled="$wait('submit')"
      :error-messages="getValidationErrors('newPhone')"
      @blur="handleFieldBlur('newPhone')"
    />
    <template #actions:append>
      <v-btn
        class="px-16"
        depressed
        color="primary"
        :loading="$wait('submit')"
        @click="handleSubmit"
      >
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import ModernModalLayout from '@/components/layouts/ModernModalLayout.vue';
import PhoneField from '@/components/controls/PhoneField.vue';
import validationMixin from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import { phone } from '@/utils/validators';
import { unmaskPhone } from '@/utils/masks';
import { mapActions } from 'vuex';

export default {
  name: 'PhoneForm',
  components: { ModernModalLayout, PhoneField },
  mixins: [validationMixin, waitable],
  props: {
    phone: { type: String, default: null },
    employeeId: { type: Number, required: true },
  },
  validations() {
    return {
      newPhone: { phone },
    };
  },
  data() {
    return {
      newPhone: null,
    };
  },
  watch: {
    phone: {
      immediate: true,
      handler() {
        this.newPhone = this.phone;
      },
    },
  },
  methods: {
    ...mapActions('EMPLOYEE', ['updateListItemPhone']),
    async handleSubmit() {
      if (!this.validate()) return;

      await this.$loadingNotify(
        this.updateListItemPhone({
          id: this.employeeId,
          phone: unmaskPhone(this.newPhone),
        }),
        'submit',
        'Произошла ошибка изменения номера телефона',
        'Номер телефона успешно изменен',
      ).then(() => this.$emit('close'));
    },
  },
};
</script>
