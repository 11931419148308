<template>
  <List
    :available-actions="{
      createAction: false,
      showAction: false,
    }"
  >
    <!-- Фильтр-->
    <template #filters="query">
      <v-col cols="12" sm="4" md="3">
        <MaskField
          :value="query.inspectionId === null ? '' : query.inspectionId"
          :mask="maskPosNumber"
          label="ID осмотра"
          hide-details="auto"
          :clearable="query.inspectionId !== null"
          outlined
          dense
          :disabled="listIsLoading"
          @input="query.inspectionId = $event || null"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <DateRangePicker
          :value="dates"
          label="Диапазон отправки осмотров в НИИАТ"
          show-quick-options
          with-time
          outlined
          dense
          clearable
          :placeholder="datesPlaceholder"
          :disabled="listIsLoading"
          @input="setDateFilters"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.softwareVersion"
          label="Версия ПО ПАК"
          :items="softwaresList"
          item-value="value"
          item-text="version"
          hide-details
          outlined
          dense
          :disabled="listIsLoading"
          :menu-props="{ offsetY: true }"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.status"
          label="Статус"
          :items="statusList"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :disabled="listIsLoading"
          :menu-props="{ offsetY: true }"
        />
      </v-col>
    </template>

    <!-- Кастомные колонки таблицы-->
    <template #table.item.inspection="{ item }">
      <TittledText :subtitle="item.type">
        <template #title>
          <div class="d-flex align-center">#{{ item.inspectionId }}</div>
        </template>
        <div>{{ dateTime(item.timestamps.processedAt) }}</div>
        <TerminalDateTime
          :date="item.timestamps.processedAt"
          :offset="item.timezoneOffsetMin"
        />
        <div>{{ item.organization.name }}</div>
        <div>ПАК {{ hostInfo(item) }}</div>
      </TittledText>
    </template>
    <template #table.item.resolutionSuccess="{ value }">
      <Indicator
        :active="value"
        :tooltip="value ? 'Допущен/Прошел' : 'Не допущен/Не прошел'"
      />
    </template>
    <template #table.item.status="{ item }">
      <div class="d-flex align-center" style="gap: 10px">
        <Round :type="statusClass(item.status)" style="min-width: 1rem" />
        <div>{{ item.result }}</div>
      </div>
    </template>

    <!-- Кнопки действий-->
    <template #item.actions.prepend="{ item }">
      <div class="d-flex flex-column">
        <CardActionBtn
          v-if="$can('NII_PIN') && item.isReviewable"
          label="Открыть JSON"
          :disabled="$wait('loadingJson')"
          @click="openJson(item.inspectionId)"
        />
        <CardActionBtn
          v-if="$can('NII_RIN') && item.status === false"
          label="Переотправить JSON"
          :disabled="$wait('resetJson')"
          @click="resetJson(item.inspectionId)"
        />
      </div>
    </template>
    <template #item.actions.append="{ item }">
      <v-tooltip v-if="$can('I_RD')" left>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            icon
            :to="{
              name: 'inspections:card',
              params: { id: item.inspectionId },
            }"
            target="_blank"
            v-on="on"
          >
            <v-icon small>fa-eye</v-icon>
          </v-btn>
        </template>
        <span>Карточка осмотра</span>
      </v-tooltip>
    </template>
  </List>
</template>

<script>
import ListFactory from '@/components/crud/ListFactory';
import { entity, messages, filters } from '../entity';

import TittledText from '@/components/TittledText';
import TerminalDateTime from '@/components/TerminalDateTime.vue';
import Indicator from '@/components/Indicator.vue';
import Round from '@/components/Round.vue';
import MaskField from '@/components/controls/MaskField';
import DateRangePicker from '@/components/controls/DateRangePicker.vue';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';

import { dateTime } from '@/utils/convert';
import { maskPosNumber } from '@/utils/masks';
import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import { NIIAT_INSPECTIONS_STATUS } from '@/utils/constants';
import integrationsApi from '@/api/services/integrations';

const List = ListFactory({
  storeModule: 'ADMIN/INTEGRATIONS/NIIAT',
  messages,
  entity,
  filters,
});

export default {
  components: {
    List,
    TittledText,
    TerminalDateTime,
    Indicator,
    Round,
    MaskField,
    DateRangePicker,
    CardActionBtn,
  },
  mixins: [waitable],

  computed: {
    ...mapGetters('ADMIN/INTEGRATIONS/NIIAT', [
      'listIsLoading',
      'softwares',
      'dateStart',
      'dateEnd',
      'datePlaceholder',
    ]),
    maskPosNumber: () => maskPosNumber,
    statusList: () => NIIAT_INSPECTIONS_STATUS,
    hostInfo() {
      return item => {
        const { license } = item?.host;
        return (
          [
            license ? license.split('-')[1] : null,
            item?.host?.releasePoint?.address ||
              '#' + item?.host?.releasePoint?.id,
          ]
            .filter(_ => _)
            .join(', ') || '–'
        );
      };
    },
    statusClass() {
      return status => {
        if (status === null) {
          return 'yellow lighten-2';
        }
        return status ? 'green lighten-2' : 'red';
      };
    },
    dates() {
      return {
        dateStart: this.dateStart || null,
        dateEnd: this.dateEnd || null,
      };
    },
    datesPlaceholder() {
      if (!this.datePlaceholder) return null;
      return {
        dateStart: this.datePlaceholder.dateStart,
        dateEnd: this.datePlaceholder.dateEnd,
      };
    },
    softwaresList() {
      const list = (this.softwares || []).map(item => ({
        version: item.version,
        value: item.version,
      }));
      return [{ version: 'Неважно', value: null }, ...list];
    },
  },

  async created() {
    this.$can('HS_G') && (await this.fetchSoftwares());
  },

  methods: {
    ...mapActions('ADMIN/INTEGRATIONS/NIIAT', [
      'fetchSoftwares',
      'setDateFilters',
    ]),
    dateTime,

    async loadJson(id) {
      const json = await integrationsApi.getNiiatJson(id);
      const stringJson = JSON.stringify(json, null, 2);

      const w = window.open();
      w.document.open();
      w.document.write(`<html><body><pre> ${stringJson} </pre></body></html>`);
      w.document.close();
    },
    async openJson(id) {
      await this.$loadingNotify(
        this.loadJson(id),
        'loadingJson',
        'Произошла ошибка загрузки JSON осмотра',
        null,
      );
    },

    async resetJson(id) {
      await this.$loadingNotify(
        integrationsApi.resetNiiatInspection(id),
        'resetJson',
        'Произошла ошибка при отправке JSON осмотра',
        'JSON осмотра успешно отправлен',
      );
    },
  },
};
</script>
