import authApi from '@/api/services/auth';

export default {
  state: {
    role: null,
  },

  getters: {
    ruleForAvailableEditByRole: state => state.role?.type?.actionTag || null,
    isAllBindingsAvailable: state =>
      state.role !== null ? state.role.actionTags.includes('ORG_AL') : false,
  },

  mutations: {
    role: (state, value) => (state.role = value),
  },

  actions: {
    async fetchRole({ commit, getters }) {
      const role = await authApi.getRole(getters.singleItem.roleKey);
      commit('role', role);
    },

    async changeRole({ dispatch, getters }, roleKey) {
      await authApi.updateRole(getters.id, roleKey);
      dispatch('singleItemUpdate');
    },
  },
};
