import structApi from '@/api/services/structures';
import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import listSimpleFactory from '@/utils/vuex/listSimpleFactory';
import { createModule } from '@/utils/vuex/createModule';

import { filters } from '@/modules/structures/organizations/entity';

const getters = {
  listQuery: (state, getters) => ({
    ...getters.listFilters,
    ...getters.listOrder,
    search: state.listQuery.search || null,
    categoryId: `${state.singleItem.id}`,
  }),
};

export default createModule(
  listSimpleFactory({
    fetchMethod: structApi.organizationList,
    createMethod: structApi.organizationCreate,
    filters,
  }),
  crudSingleFactory({
    fetchMethod: structApi.categoryGet,
    updateMethod: structApi.categoryUpdate,
    linkedList: 'STRUCTURES/ORGANIZATION_CATEGORIES',
  }),
  { getters },
);
