export const messages = {
  resourceName: 'наборов шаблонов',
  searchFilterLabel: 'Поиск по названию',
  searchFilterPlaceholder: '',
  delete: {
    title: 'Удалить',
    successfulAction: 'Набор шаблонов успешно удален',
    errorAction: 'Произошла ошибка удаления набора шаблонов',
  },
};

export const entity = [
  { key: 'key', label: 'Ключ' },
  { key: 'name', label: 'Название' },
];
