<template>
  <v-container :fluid="fluid" :style="computedStyle">
    <div class="d-flex justify-space-between">
      <div>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn
              class="mb-2"
              icon
              exact
              :to="{ name: backRoute, params: { restoreScrollPosition: true } }"
              v-on="on"
            >
              <v-icon small>fa-long-arrow-alt-left</v-icon>
            </v-btn>
          </template>
          <span>{{ backTooltip }}</span>
        </v-tooltip>
        <span class="text-h5 font-weight-light">
          <slot name="title">{{ title }}</slot>
        </span>
      </div>
    </div>
    <Spinner v-if="loading" />
    <v-row v-else>
      <slot />
    </v-row>
  </v-container>
</template>

<script>
import Spinner from '@/components/Spinner';

export default {
  components: {
    Spinner,
  },
  props: {
    fluid: Boolean,
    loading: Boolean,
    backTooltip: {
      type: String,
      default: '',
    },
    backRoute: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },

  computed: {
    computedStyle() {
      return {
        maxWidth: !this.fluid ? '1200px' : null,
      };
    },
  },
};
</script>
