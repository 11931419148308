var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('List',{attrs:{"search-filter":"","available-actions":{
    createAction: _vm.$can('ACCB_C'),
    showAction: _vm.$can('ACC_RD'),
    importAction: _vm.$can('ACCB_I'),
  },"import-settings":{ chooseOnlyCreate: false }},on:{"item:create":_vm.managerModal},scopedSlots:_vm._u([{key:"filters",fn:function(query){return [_c('v-col',{attrs:{"cols":"12","sm":"4","md":"2"}},[_c('v-select',{attrs:{"label":"Статус пользователя","items":_vm.statusItems,"item-value":"value","item-text":"name","hide-details":"","outlined":"","dense":"","menu-props":{ offsetY: true }},model:{value:(query.isEnabled),callback:function ($$v) {_vm.$set(query, "isEnabled", $$v)},expression:"query.isEnabled"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('Select',{attrs:{"multiple":"","label":"Роли","item-value":"key","items":_vm.accountRoles,"dense":"","clearable":""},model:{value:(query.roleKeys),callback:function ($$v) {_vm.$set(query, "roleKeys", $$v)},expression:"query.roleKeys"}})],1)]}},{key:"table.item.isEnabled",fn:function(ref){
  var item = ref.item;
return [_c('Indicator',{attrs:{"active":item.isEnabled,"tooltip":item.isEnabled ? 'Активный' : 'Неактивный'}})]}},{key:"table.item.name",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"300px"}},[_vm._v(_vm._s(_vm.name(item.profile)))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }