export function createModule(...creators) {
  const initial = {
    state: {},
    getters: {},
    mutations: {},
    actions: {},
  };

  creators = creators || [];

  const module = creators.reduce((accumulator, currentCreator) => {
    return {
      state: { ...accumulator.state, ...currentCreator.state },
      getters: { ...accumulator.getters, ...currentCreator.getters },
      mutations: { ...accumulator.mutations, ...currentCreator.mutations },
      actions: { ...accumulator.actions, ...currentCreator.actions },
    };
  }, initial);
  return {
    namespaced: true,
    ...module,
  };
}
