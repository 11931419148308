<template>
  <Card title="Управление работниками" dense>
    <v-simple-table dense>
      <tbody>
        <tr>
          <td class="grey--text px-4 py-3">
            Создание и редактирование работников
          </td>
          <td class="px-4 py-3" style="width: 50px">
            <v-switch
              :input-value="
                orgSettings.employeeSync
                  ? orgSettings.employeeSync.rule.employeeModify
                  : false
              "
              class="ma-0 pa-0"
              inset
              :disabled="switchDisabled"
              hide-details
              :readonly="true"
              :loading="$wait('handleEmployeeSettingsUpdate')"
              @mousedown="handleEmployeeSettingsUpdate"
            />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </Card>
</template>

<script>
// TODO: Изменить событие в v-switch на change

import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';

import Card from '@/components/ui/Card';

export default {
  components: { Card },
  mixins: [waitable],

  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', ['orgSettings']),
    switchDisabled() {
      return this.orgSettings?.employeeSync?.rule?.employeeModify
        ? !this.$can('OS_DEML')
        : !this.$can('OS_EEML');
    },
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', [
      'toggleEmployeeRequirement',
    ]),

    handleEmployeeSettingsUpdate() {
      if (this.switchDisabled) {
        return;
      }
      const newStatus = !this.orgSettings?.employeeSync?.rule?.employeeModify;
      this.$openModal('prompt', {
        yes: 'Да',
        no: 'Отмена',
        title: 'Подтверждение',
        description: `Вы действительно хотите ${
          newStatus ? 'разрешить' : 'запретить'
        } создание и редактирование работников в организации?`,
        messages: {
          successfulAction: `Создание и редактирование работников успешно ${
            newStatus ? 'разрешено' : 'запрещено'
          }`,
          errorAction: `Ошибка ${
            newStatus ? 'разрешения' : 'запрета'
          } на создание и редактирование работников`,
        },
        onSubmit: () =>
          this.$loading(
            this.toggleEmployeeRequirement(),
            'handleEmployeeSettingsUpdate',
          ),
      });
    },
  },
};
</script>
