import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import RouteGuard from '@/components/RouteGuard';
import BANS from './list.store';
import BANS_ITEM from './item.store';

export default {
  namespaced: true,
  store: {
    BANS,
    BANS_ITEM,
  },
  routes: [
    {
      path: 'bans',
      name: 'bans',
      component: () => import('./list.view.vue'),
      meta: {
        action: 'BPC_F',
      },
    },
    {
      path: 'bans/create',
      name: 'bans:create',
      component: RouteGuard({
        storeModule: 'ADMIN/BANS_ITEM',
        component: () => import('./item.view.vue'),
        skipFetch: true,
      }),
      meta: {
        action: 'BPC_C',
      },
    },
    {
      path: 'bans/:key',
      name: 'bans:item',
      component: RouteGuard({
        storeModule: 'ADMIN/BANS_ITEM',
        component: () => import('./item.view.vue'),
        primaryKeyName: 'key',
        primaryKeyType: 'string',
      }),
      meta: {
        action: 'BPC_R',
      },
    },
  ],
  menu: [
    {
      title: 'Профили банов',
      action: 'BPC_F',
      name: 'bans',
      priority: 6,
    },
  ],
} as ModuleDefinition;
