<template>
  <div>
    <div class="pb-2">
      <strong>
        <slot name="title">{{ title }}</slot>
      </strong>
      <div v-if="subtitle" class="text-body-2">
        {{ subtitle }}
      </div>
    </div>
    <div v-if="$scopedSlots.default" class="text-body-2">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: null },
    subtitle: { type: String, default: null },
  },
};
</script>
