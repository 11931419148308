<template>
  <div class="link-button__list">
    <template v-for="(el, index) in list">
      <span :key="index">
        <LinkButton
          v-bind="el"
          :text="`${el.text}${index !== list.length - 1 ? ', ' : ''}`"
        />
      </span>
    </template>
  </div>
</template>

<script>
import LinkButton from './LinkButton.vue';
export default {
  components: { LinkButton },
  props: {
    list: { type: Array, default: () => [] },
  },
};
</script>
