import gateApi from '@/api/services/gate';

import { createModule } from '@/utils/vuex/createModule';

// TODO: move all 'fetchFormFactors' here and all formFactor stores as well

const actions = {
  fetchProfilePreviews: (_, params) => gateApi.getProfilePreviews(params),
};

export default createModule({ actions });
