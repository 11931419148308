import { VALIDATION_RULE_MESSAGES } from '@/utils/constants';
import { validationMixin as vuelidateMixin } from 'vuelidate';
import { getProperty } from './helpers';

/**
 * Interpolates message of validation error by validator params
 * Searches for places for messages to occur in the pattern $paramName
 *
 * @param {string} msg
 * @param {Object|null} params
 *
 * @returns Interpolated message
 */
export function interpolateValidationMessage(msg, params) {
  if (!params) return msg;

  Object.entries(params).forEach(
    ([name, value]) => (msg = msg.replace(`$${name}`, value)),
  );
  return msg;
}

/**
 * Returns validation message for rule
 * First looks for message with key fieldName.ruleName
 * Then looks for message with key ruleName
 * And on failure returns ruleName
 *
 * @param {*} fieldName
 * @param {*} ruleName
 * @param {*} validationMessages
 */
export function getValidationMessage(fieldName, ruleName, validationMessages) {
  return (
    validationMessages[`${fieldName}.${ruleName}`] ||
    validationMessages[ruleName] ||
    ruleName
  );
}

/**
 * Extracts field names from validate object of vuelidate
 *
 * @param {*} model
 * @param {*} path
 */
export function extractVuelidateFields(model, path = '') {
  return Object.entries(model)
    .filter(([name]) => name[0] !== '$')
    .map(([name, value]) => {
      if (typeof value === 'object')
        return [
          path + name,
          ...extractVuelidateFields(value, path + name + '.'),
        ];
    })
    .flat()
    .filter(item => item);
}

/**
 * Computes validation errors for field
 *
 * @param {*} $v
 * @param {*} fieldName
 * @param {*} validationMessages
 *
 * @return {null|Array<string>}
 */
export function computeValidationErrors($v, fieldName, validationMessages) {
  const field = getProperty(fieldName, $v);
  if (!field?.$anyError) return [];

  return Object.entries(field)
    .filter(
      ([name, value]) =>
        name[0] !== '$' && typeof value === 'boolean' && !value,
    )
    .map(([name]) =>
      interpolateValidationMessage(
        getValidationMessage(fieldName, name, validationMessages),
        field.$params[name],
      ),
    );
}

/**
 * Extracts errors from server validation object
 *
 * @param {string} fieldName
 * @param {object|null} serverResponse
 */
export function getServerValidationMessages(fieldName, serverResponse) {
  if (!serverResponse) return [];

  if (serverResponse[fieldName]) {
    return serverResponse[fieldName];
  } else return [];
}

/**
 * Flat object with validation errors to plain structure
 *
 * @param {array} errors
 * @param {string} prefix
 * @param {Object} result
 */
export function flatServerValidationObject(errors, prefix, result = {}) {
  if (!errors) return {};

  errors.forEach(item => {
    item.properties.forEach(property => {
      // префикс для массивов
      property = isNaN(property) ? property : `$each.${property}`;
      property = prefix ? `${prefix}.${property}` : property;
      if (item.nested && item.nested.length) {
        result = flatServerValidationObject(item.nested, property, result);
      } else {
        result[property] = [
          ...(result[property] || []),
          ...Object.values(item.errors || {}),
        ];
      }
    });
  });
  return result;
}

const validationMixin = {
  extends: [vuelidateMixin],
  data: () => ({ serverValidationErrors: {} }),
  computed: {
    getValidationErrors() {
      const innerRuleMessages =
        typeof this.validationMessages === 'function'
          ? this.validationMessages()
          : typeof this.validationMessages === 'object'
          ? this.validationMessages
          : {};

      return fieldName => {
        const clientValidations = computeValidationErrors(this.$v, fieldName, {
          ...VALIDATION_RULE_MESSAGES,
          ...innerRuleMessages,
        });
        const serverValidations = getServerValidationMessages(
          fieldName,
          this.serverValidationErrors,
        );

        return [...clientValidations, ...serverValidations];
      };
    },
    getClientValidationErrors() {
      const innerRuleMessages =
        typeof this.validationMessages === 'function'
          ? this.validationMessages()
          : typeof this.validationMessages === 'object'
          ? this.validationMessages
          : {};

      return fieldName =>
        computeValidationErrors(this.$v, fieldName, {
          ...VALIDATION_RULE_MESSAGES,
          ...innerRuleMessages,
        });
    },
    getAllValidationErrors() {
      return Object.fromEntries(
        extractVuelidateFields(this.$v).map(fieldName => [
          fieldName,
          this.getValidationErrors(fieldName),
        ]),
      );
    },
  },
  methods: {
    setServerValidationErrors(errors, prefix) {
      this.serverValidationErrors = flatServerValidationObject(errors, prefix);

      const watchers = {};
      Object.keys(this.serverValidationErrors).forEach(key => {
        watchers[key] = this.$watch(
          key,
          function () {
            delete this.serverValidationErrors[key];
            // force update
            this.serverValidationErrors = { ...this.serverValidationErrors };
            watchers[key]();
          },
          { deep: true },
        );
      });
    },

    catchServerErrors(err, field) {
      if (err?.response.status === 400) {
        const inner = err.response?.data?.inner || [];
        this.setServerValidationErrors(inner, field);
      }
    },

    validateField(fieldName) {
      const field = getProperty(fieldName, this.$v);
      if (!field) return;

      field.$touch();
    },

    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },

    handleFieldBlur(key) {
      if (!this.$v[key]) return;
      this.$v[key].$touch();
    },
  },
};

export default validationMixin;
export function validator(validations) {
  return {
    mixins: [validationMixin],
    validations,
  };
}
