import structApi from '@/api/services/structures';

export default {
  state: {
    guests: null,
  },

  getters: {
    guests: state => state.guests || [],
  },

  mutations: {
    guests: (state, value) => (state.guests = value),
  },

  actions: {
    async fetchGuests({ getters, commit }) {
      const result = await structApi.getOrganizationGuests(getters.id);
      commit('guests', result);
      return result;
    },

    async setOrgGuests({ getters, dispatch }, g) {
      let newGuests = [...g]; // to not mess the components' guests array
      // always check if initial array of guests contained ids that are not
      // currently present. If yes than it means we deleted some entries, means we
      // gotta update those.
      // Fill back emptied hosts so we update those
      getters.guests.forEach(
        guest =>
          !~newGuests.findIndex(el => el.hostGroupId === guest.hostGroupId) &&
          newGuests.push({
            hostGroupId: guest.hostGroupId,
            ids: [],
            hash: guest.hash,
          }),
      );

      // fix for https://jira.medpoint24.ru/browse/SW-8798
      // if user removed a row and then added it back, it loses it's hash
      // So we need to restore the hash of this row from initial array
      newGuests = newGuests.map(guest => {
        const sameGuestOldIndex = getters.guests.findIndex(
          el => el.hostGroupId === guest.hostGroupId,
        );
        // if there is an old entry of the same guest that has no hash means user
        // deleted and restored the same row
        if (~sameGuestOldIndex && !guest.hash)
          return { ...guest, hash: getters.guests[sameGuestOldIndex].hash };
        // do nothing if it's a new entry or hash is present and all other cases
        return guest;
      });

      const promises = [];
      newGuests.forEach(guest => {
        // compares initial array of guests with the new one, finding those which
        // were newly added
        const isNew = !~getters.guests.findIndex(
          el => el.hostGroupId === guest.hostGroupId,
        );
        promises.push(
          structApi.setOrgGuests(
            guest.hostGroupId,
            guest.ids,
            guest.hash,
            isNew,
          ),
        );
      });

      await Promise.all(promises);
      dispatch('fetchGuests');
    },
  },
};
