<template>
  <v-card-text class="pa-0">
    <NoDataAlert v-if="!guests.length && !parentOrgs.length" class="ma-4">
      Не заданы
    </NoDataAlert>

    <template v-else>
      <div v-if="$can('OGU_R')">
        <div class="py-3 px-3">
          Работники из этих организаций могут проходить осмотры на ваших ПАК:
        </div>
        <v-simple-table v-if="guests.length" dense>
          <thead>
            <tr>
              <th class="py-3">Группы ПАК</th>
              <th class="py-3">Организации</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="guest in guests" :key="guest.hostGroupId">
              <td class="grey--text pa-4" style="width: 40%">
                {{ hostGroupName(guest.hostGroupId) }}
              </td>

              <td class="pa-4">
                <LinkButtonList :list="orgButtons(guest.ids)" />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-alert v-else type="info" class="mx-3" outlined> Не заданы </v-alert>
      </div>

      <v-divider class="my-3" />

      <div v-if="$can('WOIG_R')" class="my-4">
        <div class="py-3 px-3">
          Ваши сотрудники могут проходить осмотры на ПАК следующих компаний:
        </div>
        <v-simple-table v-if="parentOrgs && parentOrgs.length" dense>
          <thead>
            <tr>
              <th class="py-3">Группы ПАК</th>
              <th class="py-3">Организации</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="org in parentOrgs" :key="org.hostGroupId">
              <td class="grey--text pa-4" style="width: 40%">
                {{ hostGroupName(org.hostGroupId) }}
              </td>

              <td class="pa-4">
                <LinkButtonList :list="orgButtons(org.ids)" />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-alert v-else class="mx-3" type="info" outlined> Не заданы </v-alert>
      </div>
    </template>
  </v-card-text>
</template>

<script>
import LinkButtonList from '@/components/controls/buttons/LinkButtonList';
import NoDataAlert from '@/components/ui/NoDataAlert';

export default {
  components: {
    NoDataAlert,
    LinkButtonList,
  },

  props: {
    guests: {
      type: Array,
      required: true,
    },
    parentOrgs: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    organizations: [],
    hostGroups: [],
  }),

  watch: {
    guests: {
      deep: true,
      handler() {
        this.fetchPreviews();
      },
    },
    parentOrgs: {
      handler() {
        this.fetchPreviews();
      },
    },
  },

  created() {
    this.fetchPreviews();
  },

  methods: {
    hostGroupName(id) {
      return this.hostGroups.find(group => group.id === id)?.name || String(id);
    },

    orgName(id) {
      return this.organizations.find(org => org.id === id)?.name || String(id);
    },

    async fetchPreviews() {
      const [guestOrgIds, parentOrgIds] = [
        this.guests?.map(org => org.ids).flat() || [],
        this.parentOrgs?.map(parent => parent.ids).flat() || [],
      ];

      const [guestHostIds, parentHostIds] = [
        this.guests?.map(org => org.hostGroupId) || [],
        this.parentOrgs?.map(parent => parent.hostGroupId) || [],
      ];

      const bindingIds = {
        organizations: new Set([...guestOrgIds, ...parentOrgIds]),
        hostGroups: new Set([...guestHostIds, ...parentHostIds]),
      };

      const requests = {
        organizations: 'fetchOrganizationPreviewsBy',
        hostGroups: 'fetchHostGroupPreviewsBy',
      };

      await Promise.all(
        Object.entries(requests).map(async ([key, req]) => {
          // we send requests only if ids for structure instance present in
          // bindings object
          if (!bindingIds[key].size) return;

          const res = await this.$store.dispatch(
            `STRUCTURES/${req}`,
            Array.from(bindingIds[key]),
          );
          this[key] = [...res];
        }),
      );
    },

    orgButtons(ids) {
      return ids.map(id => ({
        dense: true,
        text: this.orgName(id),
        action: this.$can('ORG_RD'),
        to: {
          name: 'structures:organizations:item',
          params: { id },
        },
      }));
    },
  },
};
</script>
