<template>
  <Card :title="title" dense>
    <template #action>
      <div class="d-flex" style="gap: 10px">
        <slot name="titleActions" />
        <slot v-if="!hasFilters" name="filterButtons" />
      </div>

      <v-card-text v-if="hasFilters" class="pb-0">
        <v-row>
          <slot name="filters" />
          <slot name="filterButtons" />
        </v-row>
      </v-card-text>
    </template>

    <v-container fluid>
      <v-row>
        <v-col v-if="$scopedSlots.actions" cols="12">
          <slot name="actions" />
        </v-col>

        <v-col cols="12" class="pa-0">
          <slot name="list" />
        </v-col>

        <v-col v-if="$scopedSlots.footer" cols="12" class="pa-0">
          <slot name="footer" />
        </v-col>
      </v-row>
    </v-container>
  </Card>
</template>

<script>
import Card from '@/components/ui/Card';

export default {
  components: { Card },
  props: {
    title: {
      type: String,
      default: '',
    },
    hasFilters: Boolean,
  },
};
</script>
