<template>
  <v-form ref="form" :disabled="isLoading">
    <v-row>
      <v-col cols="6">
        <v-text-field
          :value="value.name"
          label="Название приложения"
          outlined
          hide-details="auto"
          :error-messages="getValidationErrors('value.name')"
          @blur="validateField('value.name')"
          @input="$emit('input', { ...value, name: $event })"
        />
      </v-col>

      <v-col cols="6">
        <v-text-field
          :value="value.email"
          label="Почта для связи"
          outlined
          hide-details="auto"
          :error-messages="getValidationErrors('value.email')"
          @blur="validateField('value.email')"
          @input="$emit('input', { ...value, email: $event })"
        />
      </v-col>

      <v-col cols="12">
        <OrganizationSelect
          :value="value.orgId"
          outlined
          hint-color="orange"
          hide-details="auto"
          access-level="full"
          :error-messages="getValidationErrors('value.orgId')"
          @blur="validateField('value.orgId')"
          @input="$emit('input', { ...value, orgId: $event })"
        />
        <div class="text-sm-body-2 pt-2">
          Влияет на принадлежность приложения к организации. Права доступа нужно
          настраивать отдельно!
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { required, maxLength, email } from 'vuelidate/lib/validators';
import validation from '@/utils/validation';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';

export default {
  components: { OrganizationSelect },
  mixins: [validation],
  props: {
    value: { type: Object, default: () => ({}) },
    isLoading: { type: Boolean, default: false },
  },

  validations() {
    return {
      value: {
        name: { required, maxSymbolsLength: maxLength(64) },
        email: {
          required,
          email,
        },
        orgId: { required },
      },
    };
  },
};
</script>
