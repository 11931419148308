<template>
  <div
    class="round rounded-circle d-inline-flex"
    :class="[type]"
    :style="roundStyle"
  />
</template>

<script>
export default {
  props: {
    size: { type: String, default: '1rem' },
    type: { type: String, default: null },
  },

  computed: {
    roundStyle() {
      return {
        width: this.size,
        height: this.size,
      };
    },
  },
};
</script>
