<template>
  <v-expansion-panels
    v-model="opened"
    class="mt-4 shadow--hide"
    accordion
    multiple
  >
    <v-expansion-panel v-for="session in value" :key="session.uuid">
      <v-expansion-panel-header>
        <div style="font-size: 0.8rem">
          <b>
            {{ getTime(session.openedAt) }}
            {{
              session.closedAt
                ? isOneDay(session.openedAt, session.closedAt)
                  ? ` – ${getTime(session.closedAt)}`
                  : ` – ${dateTime(session.closedAt)}`
                : '(еще на смене)'
            }}
          </b>
          <br />
          <div class="mt-1">
            Онлайн {{ secondsToWordTime(session.onlineSec) || '–' }}
          </div>
          <div class="mt-1">
            {{ endReasons[session.endReason] || endReasons[undefined] }}
          </div>
        </div>
        <div style="font-size: 0.8rem" class="text-right">
          Всего {{ session.summary.resolved }}
          <br />
          <div class="mt-2">
            {{ session.summary.positive }}
            <Round type="success" size="0.7rem" /> /
            {{ session.summary.negative }} <Round type="error" size="0.7rem" />
          </div>
        </div>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <MedicStatsEvents :value="session.events" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { date, dateTime, getTime, secondsToWordTime } from '@/utils/convert';
import Round from '@/components/Round.vue';
import MedicStatsEvents from './MedicStatsEvents.vue';

const endReasons = {
  timeout: 'Истек таймер',
  paused: 'Пауза',
  rejected: 'Отказ',
  undefined: 'Техническая ошибка',
};

export default {
  components: {
    Round,
    MedicStatsEvents,
  },

  props: {
    value: {
      required: true,
      type: Array,
    },
  },

  data: () => ({
    opened: [],
  }),

  computed: {
    endReasons: () => endReasons,
  },

  watch: {
    value() {
      this.opened = [];
    },
  },

  methods: {
    dateTime,
    getTime,
    secondsToWordTime,

    isOneDay(start, finish) {
      return date(start) === date(finish);
    },
  },
};
</script>
