import { dateTime, name, date } from '@/utils/convert';
import { DOCUMENT_STATUSES } from '@/modules/documents/constants';

export const entity = [
  { key: 'id', label: 'ID' },
  {
    key: 'status',
    label: 'Статус',
    convert: item =>
      DOCUMENT_STATUSES.find(({ value }) => value === item.status)?.title ||
      '–',
  },
  { key: 'name', label: 'Название', hideInTable: true },
  { key: 'name_type', label: 'Название', hideInCard: true },
  {
    key: 'employeeId',
    label: 'Работник',
    convert: (item, getters) => {
      // if we are viewing document modal then we probably have employee already
      // loaded to list store (which is kinda inobvious ik, but it's what it's)
      if (getters['DOCUMENTS/INCOMING_ITEM/employee']?.id === item.employeeId)
        return name(getters['DOCUMENTS/INCOMING_ITEM/employee']);
      // otherwise search in loaded previews list
      return getters['DOCUMENTS/INCOMING/employees'][item.employeeId] || '—';
    },
  },
  {
    key: 'type',
    label: 'Тип',
    hideInTable: true,
    convert: (item, getters) =>
      getters['REGISTRY/getIncomingDocumentTypes'](item.type)?.name || '–',
    headerProps: { width: '150px' },
  },
  {
    key: 'issuedAt',
    label: 'Выдан',
    hideInTable: true,
    convert: item => date(item.issuedAt),
  },
  {
    key: 'dueDate',
    label: 'Истекает',
    hideInTable: true,
    convert: item => date(item.dueDate) || 'Бессрочно',
  },
  { key: 'resolutionUser_resolutionDate', label: 'Проверил', hideInCard: true },
  {
    key: 'diagnosis',
    label: 'Диагноз',
    convert: item => item.diagnosis,
  },
  {
    key: 'resolutionUser',
    label: 'Проверил',
    hideInTable: true,
    convert: (item, getters) =>
      item?.resolution &&
      getters['DOCUMENTS/INCOMING/accounts'][item.resolution.approvedBy],
  },
  {
    key: 'resolutionDate',
    label: 'Дата проверки',
    hideInTable: true,
    convert: item => item?.resolution && dateTime(item.resolution.date),
  },
];

export const messages = {
  resourceName: 'документов',
  searchFilterLabel: 'Поиск по документам',
};

export const filters = {
  employeeIds: { type: Array, default: null },
  employeeSearch: { type: String, default: null },
  types: { type: Array, default: null },
  status: { type: String, default: 'PENDING' },
};
