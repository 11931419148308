import notifApi from '@/api/services/notifications';
import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

const state = {
  verification: null,
};
const actions = {
  async createSingleItem({ dispatch }, data) {
    const response = await notifApi.host_verificationsCreate(data);
    dispatch('pushLinkedList', {
      action: 'listAddItem',
      payload: response,
    });
    return response;
  },
  async updateSingleItem({ dispatch }, data) {
    await notifApi.host_verificationsUpdate(data);
    dispatch('singleItemUpdate');
    dispatch('NOTIFICATIONS/verification/fetchList', null, { root: true });
  },
};

export default createModule(
  crudSingleFactory({
    fetchMethod: notifApi.host_verificationsGet,
    changeMethod: notifApi.host_verificationsChange,
    deleteMethod: notifApi.host_verificationsDelete,
    linkedList: 'NOTIFICATIONS/VERIFICATION',
  }),
  { state, actions },
);
