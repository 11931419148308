import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import RouteGuard from '@/components/RouteGuard';
import ORGANIZATION_CATEGORIES from './store/list';
import ORGANIZATION_CATEGORIES_ITEM from './store/item';

export default {
  namespaced: true,
  store: { ORGANIZATION_CATEGORIES, ORGANIZATION_CATEGORIES_ITEM },
  modals: [
    {
      name: 'orgCategoryForm',
      component: () =>
        import('@/modules/structures/organizations-categories/modals/Form.vue'),
    },
  ],
  routes: [
    {
      path: 'organization_categories',
      name: 'organization_categories',
      component: () => import('./view/list.vue'),
      meta: {
        title: 'Все категории организаций',
        action: 'ORGC_R',
      },
    },
    {
      path: 'organization_categories/:id',
      name: 'organization_categories:item',
      component: RouteGuard({
        storeModule: 'STRUCTURES/ORGANIZATION_CATEGORIES_ITEM',
        component: () => import('./view/item.vue'),
      }),
      meta: {
        title: 'Страница группы организаций',
        action: 'ORC_RD',
        // otherwise when updating embedded lists on tab items
        // the page will jump up
        disableScrollRestoring: true,
      },
    },
  ],
  menu: [
    {
      title: 'Орг Категории',
      action: 'ORGC_R',
      name: 'organization_categories',
      priority: 2,
    },
  ],
} as ModuleDefinition;
