import structApi from '@/api/services/structures';

export default {
  getters: {
    niiatEnabled: state => state.singleItem?.niiatStatus?.enabled || false,
  },
  mutations: {
    niiatRegistration: state => {
      !state.singleItem.niiatStatus && (state.singleItem.niiatStatus = {});
      state.singleItem.niiatStatus.registered = true;
      // самообслуживающаяся организация
      if (state.singleItem.id === state.singleItem.medOrg.id) {
        state.singleItem.medOrg.niiatStatus.registered = true;
      }
    },
    niiatEnabled: (state, data) => {
      !state.singleItem.niiatStatus && (state.singleItem.niiatStatus = {});
      state.singleItem.niiatStatus.enabled = data;
      // самообслуживающаяся организация
      if (state.singleItem.id === state.singleItem.medOrg.id) {
        state.singleItem.medOrg.niiatStatus.enabled = data;
      }
    },
  },
  actions: {
    async niiatRegistration({ getters, commit }) {
      const result = await structApi.orgNiiatRegistration(getters.id);
      result && commit('niiatRegistration');
    },
    async toggleNiiat({ getters, commit }, status) {
      const action = status ? 'enable' : 'disable';
      // in response there is an object {success: Boolean}
      const { success } = await structApi.setNiiatStatus(getters.id, action);
      success && commit('niiatEnabled', status);
    },
  },
};
