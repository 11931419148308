<template>
  <div class="limits__card__params">
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between">
        <div class="d-flex">
          <v-chip
            v-if="value.isEnabled"
            color="success"
            small
            v-text="'Активен'"
          />
          <v-chip v-if="!value.isEnabled" small v-text="'Отключен'" />
          <v-chip
            v-if="value.isReached"
            class="ml-1"
            color="error"
            small
            v-text="'Превышение'"
          />
        </div>

        <div v-if="value.type === LimitType.BY_TOTAL" class="limit__date">
          С какой даты:
          <span class="font-weight-bold">
            {{ date(value.settings.dateFrom) }}
          </span>
        </div>

        <div v-if="value.type === LimitType.BY_DATE" class="limit__date">
          По какую дату:
          <span class="font-weight-bold">
            {{ date(value.settings.dateEnd) }}
          </span>
        </div>

        <div v-if="value.type === LimitType.BY_PERIOD" class="limit__period">
          Период:
          <span class="font-weight-bold">
            {{ LimitPeriodName[value.settings.period] }}
          </span>
        </div>

        <div
          v-if="
            value.type === LimitType.BY_PERIOD ||
            value.type === LimitType.BY_TOTAL
          "
          class="limit__counter text-right"
        >
          Пройдено осмотров:
          <span class="font-weight-bold">
            {{ numberWithSpaces(value.counter) }} из
            {{ numberWithSpaces(value.settings.limit) }}
          </span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { LimitType, LimitPeriodName } from '@/api/services/limits.interfaces';
import { date } from '@/utils/convert';
import { numberWithSpaces } from '@/utils/helpers';

export default {
  props: {
    value: { required: true, type: Object },
  },
  computed: {
    LimitType: () => LimitType,
    LimitPeriodName: () => LimitPeriodName,
  },

  methods: {
    date,
    numberWithSpaces,
  },
};
</script>
