<template>
  <v-alert
    class="warning-block"
    :type="type"
    colored-border="colored-border"
    border="left"
    width="490px"
    elevation="2"
  >
    <h3 class="headline">{{ message.title }}</h3>
    <div v-if="message.content" class="mt-2 warning-block__content">
      {{ message.content }}
    </div>
  </v-alert>
</template>

<script>
import { xhrErrorMessage } from '@/utils/helpers';
const MESSAGES = {
  not_worked: {
    title: 'Готовы продолжить работу?',
  },
  break: {
    title: 'Время истекло',
    content: 'Время на принятие осмотра истекло. Готовы продолжить работу?',
  },
};

export default {
  props: {
    title: { type: [String, Object], default: null },
    error: { type: [String, Error, Object], default: null },
  },
  computed: {
    message() {
      if (this.error)
        return {
          title: 'Произошла ошибка',
          content: xhrErrorMessage(this.error),
        };

      return MESSAGES[this.title] || this.title;
    },
    type() {
      if (this.title === 'break') return 'warning';
      if (this.error) return 'error';

      return 'info';
    },
  },
};
</script>

<style lang="scss" scoped>
.warning-block .v-icon {
  top: 4px;
  left: 5px;
}

.warning-block__content {
  white-space: pre-line;
}
</style>
