<template>
  <div>
    <v-card v-if="$scopedSlots.filters || title" tile>
      <v-card-title v-if="title">
        <span class="mr-6">{{ title }}</span>
        <div class="d-flex" style="gap: 12px">
          <slot name="titleActions" />
        </div>
        <!-- Если нет фильтров, то кнопку обновления переносим в шапку -->
        <slot v-if="!hasFilters" name="filterButtons" />
      </v-card-title>

      <v-card-text v-if="hasFilters">
        <v-row>
          <slot name="filters" />
          <slot name="filterButtons" />
        </v-row>
      </v-card-text>
    </v-card>

    <v-container fluid>
      <v-row>
        <v-col v-if="$scopedSlots.actions" cols="12">
          <slot name="actions" />
        </v-col>

        <v-col cols="12">
          <v-card v-if="$scopedSlots.error">
            <slot name="error" />
          </v-card>

          <v-card v-if="$scopedSlots.list">
            <slot name="list" />
          </v-card>
        </v-col>

        <v-col v-if="$scopedSlots.footer" cols="12">
          <slot name="footer" />
        </v-col>
      </v-row>
    </v-container>

    <Goto />
  </div>
</template>

<script>
import Goto from '@/components/Goto';

export default {
  components: {
    Goto,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    hasFilters: Boolean,
  },
};
</script>
