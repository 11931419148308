<template>
  <v-checkbox
    :input-value="value"
    :label="label"
    class="mt-0"
    hide-details="auto"
    :disabled="disabled"
    :error-messages="errorMessages"
    @change="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
