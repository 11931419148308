<template>
  <div class="columns-layout" :style="containerStyle">
    <div
      v-for="slot in colSlotNames"
      :key="slot"
      :style="columnStyle"
      class="columns-layout__column"
      :class="classes[slot]"
    >
      <slot :name="slot" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    gridTemplateColumns: {
      type: String,
      default: null,
    },
    oneColumn: Boolean,
    classesBySlots: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    colSlotNames() {
      const result = Object.entries(this.$scopedSlots).reduce((acc, [key]) => {
        if (key.slice(0, 3) === 'col') return [...acc, key];
      }, []);
      return result;
    },
    containerStyle() {
      const template = this.gridTemplateColumns
        ? this.gridTemplateColumns
        : `repeat(${this.colSlotNames.length}, 1fr)`;

      return {
        gridTemplateColumns: this.oneColumn ? '100%' : template,
      };
    },
    columnStyle() {
      return {
        overflowX: this.oneColumn ? 'initial' : 'auto',
      };
    },
    classes() {
      const classesBySlots = this.classesBySlots || {};
      return Object.entries(this.$scopedSlots).reduce((acc, [key]) => {
        acc[key] = classesBySlots[key] || [];
        return acc;
      }, {});
    },
  },
};
</script>

<style lang="scss" scoped>
.columns-layout {
  height: 100%;
  overflow-x: auto;
  display: grid;
  grid-gap: 20px;
  grid-template-rows: auto;

  &__column {
    height: 100%;

    display: flex;
    flex-direction: column;
  }
}
</style>
