<template>
  <ModernModalLayout
    :title="
      data.id
        ? 'Редактирование категории организаций'
        : 'Добавление категории организаций'
    "
    close-label="Отмена"
    :watch-for="data"
  >
    <v-form ref="form" :disabled="isLoading || disabled || $wait('submit')">
      <v-row>
        <v-col :cols="12">
          <v-text-field
            v-model="data.name"
            label="Название"
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('data.name')"
            @blur="validateField('data.name')"
          />
        </v-col>
        <v-col :cols="12">
          <v-text-field
            v-model="data.description"
            label="Описание"
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('data.description')"
            @blur="validateField('data.description')"
          />
        </v-col>
        <v-col v-if="!data.id" :cols="12">
          <OrganizationSelect
            v-model="data.orgId"
            access-level="full"
            medical
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('data.orgId')"
            @blur="validateField('data.orgId')"
          />
        </v-col>
        <v-col :cols="12">
          <MaskField
            v-model="data.weight"
            label="Вес"
            hide-details="auto"
            outlined
            :mask="maskPosNumber"
            :error-messages="getValidationErrors('data.weight')"
            @blur="validateField('data.weight')"
          />
        </v-col>
        <v-col :cols="12">
          <MaskField
            v-model="data.sla"
            label="SLA (в минутах)"
            hide-details="auto"
            outlined
            :mask="maskPosNumber"
            :error-messages="getValidationErrors('data.sla')"
            @blur="validateField('data.sla')"
          />
        </v-col>
      </v-row>
    </v-form>

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :disabled="$wait('submit')"
        @click="submit"
      >
        {{ data.id ? 'Сохранить' : 'Добавить' }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import {
  required,
  requiredIf,
  maxLength,
  minValue,
  maxValue,
} from 'vuelidate/lib/validators';
import validation from '@/utils/validation';
import { maskPosNumber } from '@/utils/masks';
import waitable from '@/utils/mixins/waitable';

import ModernModalLayout from '@/components/layouts/ModernModalLayout.vue';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';
import MaskField from '@/components/controls/MaskField';

export default {
  components: {
    ModernModalLayout,
    OrganizationSelect,
    MaskField,
  },
  mixins: [validation, waitable],

  props: {
    value: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },

  data: () => ({
    data: { weight: 100, sla: 10 },
  }),

  validations() {
    return {
      data: {
        name: { required, maxSymbolsLength: maxLength(15) },
        description: { required, maxSymbolsLength: maxLength(100) },
        weight: { required, minValue: minValue(1), maxValue: maxValue(1000) },
        sla: { required, minValue: minValue(1), maxValue: maxValue(60) },
        orgId: { required: requiredIf(() => !this.data.id) },
      },
    };
  },

  computed: {
    maskPosNumber: () => maskPosNumber,
  },

  async created() {
    this.data = { ...this.data, ...this.value };
  },

  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const actionName = this.data.id
        ? 'STRUCTURES/ORGANIZATION_CATEGORIES_ITEM/updateSingleItem'
        : 'STRUCTURES/ORGANIZATION_CATEGORIES/createListItem';

      await this.$loadingNotify(
        this.$store.dispatch(actionName, this.data),
        'submit',
        `Произошла ошибка ${
          this.data.id ? 'изменения' : 'сохранения'
        } категории организаций`,
        `Категория организаций успешно ${
          this.data.id ? 'изменена' : 'добавлена'
        }`,
      )
        .then(() => this.$emit('close'))
        .catch(err => this.catchServerErrors(err, 'data'));
    },
  },
};
</script>
