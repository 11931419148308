<template>
  <AsyncSelect v-bind="computedProps" v-on="$listeners">
    <template #item:description="{ item }">
      <v-chip v-if="item.isHolding" x-small class="ml-1"> Холдинг </v-chip>
    </template>
  </AsyncSelect>
</template>

<script>
import asyncSelectMixin from '@/components/controls/asyncSelectMixin';
import { mapActions } from 'vuex';

export default {
  extends: asyncSelectMixin,

  props: {
    accessLevel: {
      validator: value => ['any', 'full'].indexOf(value) !== -1,
      default: 'any',
    },
    isHolding: { type: Boolean, default: null },
    type: {
      validator: value => ['all', 'public', 'system'].indexOf(value) !== -1,
      default: 'public',
    },
  },

  computed: {
    label() {
      if (this.isHolding) return this.multiple ? 'Холдинги' : 'Холдинг';

      return this.multiple ? 'Группы организаций' : 'Группа организаций';
    },
    query() {
      return {
        accessLevel: this.accessLevel,
        isHolding: this.isHolding,
        type: this.type === 'all' ? undefined : this.type,
      };
    },
  },

  methods: {
    ...mapActions('STRUCTURES', {
      fetcher: 'fetchOrganizationGroupPreviews',
      fetcherBy: 'fetchOrganizationGroupPreviewsBy',
    }),
  },
};
</script>
