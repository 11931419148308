<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template #activator="{ on, attrs }">
      <v-btn color="error" outlined small v-bind="attrs" v-on="on">
        <v-icon size="14">{{ icon }}</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-text>
        <slot name="description">{{ description }}</slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="close">{{ no }}</v-btn>
        <v-btn color="primary" text @click="submit">{{ yes }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    no: {
      type: String,
      default: 'Отмена',
    },
    yes: {
      type: String,
      default: 'Подтвердить',
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    icon: {
      type: String,
      default: 'far fa-trash-alt',
    },
  },

  data: () => ({
    menu: false,
  }),

  methods: {
    close() {
      this.menu = false;
    },

    async submit() {
      this.onSubmit && (await this.onSubmit());
      this.close();
    },
  },
};
</script>
