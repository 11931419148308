import { createLocalVue, createWrapper } from '@vue/test-utils';
import Vue from 'vue';
import Vuex from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import subscribePlugin from '@/store/plugins/subscribePlugin';

// Remove console warning Unknown custom element
// REVIEW: think about use this Vue or localVue
Vue.config.ignoredElements = [/.*/];

export const localVue = createLocalVue();

/**
 * Create full state component to check the code
 * Needed to test logic and layout of inner slots in component
 * @param {string} name Name for stub component
 */
export function createComponentStub(name) {
  return {
    name,
    render(h) {
      return h(`${name}-stub`, {}, this.$slots.$default);
    },
  };
}

/**
 * Render slot content into full wrapper
 * Needed to test with wrapper utilities (find DOM elements, fire events, create snapshot)
 * @param {VNode} slotContent
 */
export function renderSlot(slotContent) {
  const constructor = localVue.extend({
    render() {
      return slotContent;
    },
  });
  return createWrapper(new constructor().$mount());
}

/**
 * Create vuex store instance from store module
 * @param {Object} storeModule
 * @param {boolean | array} plugins If true, includes all project plugins, if array, includes passed plugins
 * @param {string} namespace Namespace for module
 */
export function initStoreInstance(storeModule, plugins = false, namespace) {
  if (typeof plugins === 'boolean') {
    if (plugins) plugins = [subscribePlugin];
    if (!plugins) plugins = [];
  }
  const localVue = createLocalVue();
  localVue.use(Vuex);

  if (namespace) {
    return new Vuex.Store(
      cloneDeep({ modules: { [namespace]: storeModule }, plugins }),
    );
  } else {
    return new Vuex.Store(cloneDeep({ ...storeModule, plugins }));
  }
}
