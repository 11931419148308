<template>
  <ModernModalLayout
    size="large"
    title="Редактирование гостевых организаций"
    close-action-name="закрыть"
    :loading="$wait('fetchingPreviews')"
  >
    <div v-if="guestsCopy.length === 0" class="mb-4">
      Ни одна гостевая организация не подключена.
    </div>
    <v-row v-for="guest in guestsCopy" :key="guest.hostGroupId">
      <v-col cols="5">
        <Select
          v-model="guest.hostGroupId"
          label="Группа ПАК"
          hide-details="auto"
          :items="hostGroupsWith(guest.hostGroupId)"
        />
      </v-col>
      <v-col cols="6">
        <OrganizationSelect
          v-model="guest.ids"
          multiple
          :org-ids="[singleItem.id]"
          exclude-self
          :self-value="singleItem.id"
        />
      </v-col>
      <v-col cols="1">
        <v-btn depressed icon color="error" @click="handleRemove(guest)">
          <v-icon>fas fa-trash-alt</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="canAddNewRecord">
      <v-col cols="5" />
      <v-col cols="5">
        <v-btn depressed color="primary" @click="handleAddRecord">
          + Добавить
        </v-btn>
      </v-col>
    </v-row>

    <template #actions:append>
      <v-btn
        class="px-10"
        depressed
        color="primary"
        :loading="$wait('onSubmit')"
        @click="handleSubmit"
      >
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import waitable from '@/utils/mixins/waitable';
import { mapActions, mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';
import Select from '@/components/controls/Select.vue';

export default {
  components: { ModernModalLayout, OrganizationSelect, Select },
  mixins: [waitable],

  data: () => ({
    hostGroups: [],
    // inner copy of guests array, is set in 'created' lifecycle
    guestsCopy: [],
  }),

  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', ['guests', 'singleItem']),

    canAddNewRecord() {
      return this.hostGroupsWith().length;
    },

    // returns all host groups or cur. org. except those already chosen
    // sorry for confusing name, didn't came up with anything better for now
    hostGroupsWith() {
      if (!this.hostGroups) return [];

      return includedHostGroup =>
        this.hostGroups?.filter(group => {
          if (group.id === includedHostGroup) return true;

          return !this.guestsCopy.find(guest => guest.hostGroupId === group.id);
        });
    },
  },

  async created() {
    const defaultParams = {
      limit: 1000,
      page: 1,
      orgIds: this.singleItem.id,
      omitGuests: true,
    };

    await this.$loadingNotify(
      this.$store
        .dispatch('STRUCTURES/fetchHostGroupPreviews', defaultParams)
        .then(res => (this.hostGroups = res.items)),
      'fetchingPreviews',
    );

    this.guestsCopy = cloneDeep(this.guests);
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', ['setOrgGuests']),

    handleSubmit() {
      this.$loadingNotify(
        this.setOrgGuests(this.guestsCopy).then(() => this.$emit('close')),
        'submitting',
        'Ошибка изменения гостевых привязок',
        'Гостевые привязки успешно изменены',
      );
    },

    handleRemove({ hostGroupId }) {
      this.guestsCopy.splice(
        this.guestsCopy.findIndex(el => el.hostGroupId === hostGroupId),
        1,
      );
    },

    handleAddRecord() {
      const hostGroup = this.hostGroupsWith()[0];
      this.guestsCopy.push({
        hostGroupId: hostGroup.id,
        ids: [],
        hash: hostGroup.hash,
      });
    },
  },
};
</script>
