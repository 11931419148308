import structApi from '@/api/services/structures';

export default {
  state: {
    mDeviceTypes: [],
  },
  getters: {
    mDeviceTypes: state => state.mDeviceTypes,
  },
  mutations: {
    mDeviceTypes: (state, value) => (state.mDeviceTypes = value),
  },
  actions: {
    async fetchMDeviceTypes({ commit }) {
      const res = await structApi.getMedicalDeviceTypes();
      commit('mDeviceTypes', res);
      return res;
    },
  },
};
