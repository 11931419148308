<template>
  <div>
    <v-alert
      v-if="!userIsMedic"
      style="width: 300px; margin: 0 auto"
      class="my-3"
      dense
      outlined
      type="error"
    >
      Вы не медработник
    </v-alert>
    <v-alert
      v-else-if="!$can('T_CP')"
      style="width: 300px; margin: 0 auto"
      class="my-3"
      dense
      outlined
      type="error"
    >
      Нет доступа к тестовой подписи. Обратитесь в тех. поддержку
    </v-alert>

    <div
      v-else-if="!isCheckedCertificate"
      class="d-flex justify-center align-center mt-4"
    >
      <v-card width="500" class="text-center px-3 pb-5">
        <v-card-title>Для начала работы выберите ЭЦП</v-card-title>
        <CheckCertificate :account-id="accountId" @submit="submitCertificate" />
      </v-card>
    </div>

    <MedcabLayoutPassive v-else>
      <MainPassive />
    </MedcabLayoutPassive>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import env from '@/plugins/env';
import waitable from '@/utils/mixins/waitable';
import sound from '../soundMixin';

import MedcabLayoutPassive from '../components/MedcabLayoutPassive.vue';
import CheckCertificate from '../components/CheckCertificate.vue';
import MainPassive from '../components/MainPassive.vue';

export default {
  components: { MedcabLayoutPassive, CheckCertificate, MainPassive },

  mixins: [waitable, sound('passive')],

  data: () => ({
    isCheckedCertificate: false,
  }),

  computed: {
    ...mapGetters('AUTH', ['accountId', 'currentAccount']),
    ...mapGetters('MEDCAB_PASSIVE', ['error']),
    ...mapGetters('MEDCAB_INSPECTION', [
      'type',
      'assistance',
      'assistantDisabled',
    ]),

    userIsMedic() {
      return this.currentAccount.type === 'medic';
    },
    actions() {
      return this.type?.details?.actions || {};
    },
    canMedicPermit() {
      if (this.assistantDisabled) return true;
      return this.assistance.result?.level !== 'obligatory';
    },
    tokenIsActive() {
      return !!this.$store.getters['TOKENS/activeToken'];
    },
  },

  watch: {
    tokenIsActive: {
      immediate: true,
      handler(val) {
        this.isCheckedCertificate = val;
      },
    },

    isCheckedCertificate: {
      immediate: true,
      handler(val) {
        if (val) this.$store.dispatch('MEDCAB_PASSIVE/restoreShift');
      },
    },
  },

  created() {
    this.$store.dispatch('REGISTRY/fetchInspectionRemarks');
    if (this.tokenIsActive) this.isCheckedCertificate = true;
    if (this.userIsMedic) this.$store.dispatch('AUTH/fetchMedicDetails');
  },

  activated() {
    this.startPing();
  },
  deactivated() {
    this.stopPing();
  },
  beforeDestroy() {
    this.stopPing();
  },

  methods: {
    ...mapActions('TIME', ['startPing', 'stopPing']),

    async submitCertificate({ token, debug = false }) {
      // only for testing
      if (env.get('VUE_APP_MEDCAB_DEBUG') && debug) {
        this.isCheckedCertificate = true;
        return;
      }

      await this.$store.dispatch('TOKENS/changeActiveToken', {
        type: token.type,
        token,
      });
      this.isCheckedCertificate = true;
    },
  },
};
</script>
