<template>
  <div v-if="isLoading">
    <Spinner />
  </div>

  <div v-else-if="!!cryptoProInfoError">
    <v-alert text type="error">{{ cryptoProInfoError }}</v-alert>
    <v-alert text type="info">
      Возможно, вам необходимо установить на компьютер
      <a href="https://www.cryptopro.ru/products/cades/plugin" target="_blank">
        Криптопровайдер и плагин КриптоПро
      </a>
    </v-alert>
  </div>

  <div v-else>
    <div v-if="showInfo">
      <div class="text--disabled">
        Версия плагина: {{ cryptoProInfo.cadesVersion || '–' }}
      </div>
      <div class="text--disabled">
        Версия криптопровайдера: {{ cryptoProInfo.cspVersion || '–' }}
      </div>
    </div>

    <v-alert v-if="cryptoProTokensError" text :type="cryptoProTokensErrorType">
      {{ cryptoProTokensError }}
    </v-alert>

    <v-list v-else class="pb-0">
      <v-radio-group
        v-model="active"
        :disabled="disabled"
        class="pa-0 ma-0"
        hide-details="auto"
      >
        <v-radio
          v-for="{ data } in cryptoProTokensList"
          :key="data.thumbprint"
          :value="data.thumbprint"
          :disabled="
            $wait('login') || !data.isValid || !addedToAccount(data.serial)
          "
        >
          <template #label>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title style="white-space: normal">
                  <div v-if="data.type === 'cryptopro'">
                    {{ name(data.ownerData.person) }}
                  </div>
                  <div v-else>
                    {{ data.serial }}
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle style="white-space: normal">
                  <div v-if="data.type === 'cryptopro'">
                    {{ data.ownerData.company }} /
                    {{ data.ownerData.position }}
                  </div>
                  Срок действия:
                  {{ date(data.validFrom) }} – {{ date(data.validTo) }}
                  <span v-if="!data.isValid">(не валиден)</span>
                  <br />
                  <span v-if="!addedToAccount(data.serial)">
                    Сертификат не подключен к аккаунту
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-radio>
      </v-radio-group>
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { date, name } from '@/utils/convert';
import waitable from '@/utils/mixins/waitable';
import Spinner from '@/components/Spinner.vue';

export default {
  components: { Spinner },
  mixins: [waitable],

  props: {
    value: {
      default: null,
      validator: value => {
        return typeof value === 'object' || typeof value === 'string';
      },
    },
    showInfo: {
      type: Boolean,
      default: true,
    },
    accountCertificates: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isInitializing: false,
    };
  },

  computed: {
    ...mapGetters('AUTH', ['isAuthorized', 'softSignIn']),
    ...mapGetters('TOKENS', [
      'cryptoProInfo',
      'cryptoProInfoError',
      'cryptoProTokens',
      'cryptoProTokensError',
      'cryptoProTokensErrorType',
      'timerCryptoProTokens',
    ]),

    active: {
      get() {
        if (
          this.value?.data?.type !== 'cryptopro' ||
          this.value?.data?.type !== 'cryptoprotest'
        )
          return null;
        return this.value?.data?.thumbprint || null;
      },
      set(thumbprint) {
        const el = this.cryptoProTokensList.find(
          el => el.data.thumbprint === thumbprint,
        );
        this.$emit('input', el);
      },
    },

    cryptoProTokensList() {
      const list = this.cryptoProTokens || [];
      // NOTE: User can continue working only with the same token that
      // was used during authorization
      if (this.matchBy) list.filter(el => el.serial === this.matchBy);
      return list;
    },
    isLoading() {
      return !!(
        (!this.cryptoProInfo && !this.cryptoProInfoError) ||
        (!this.cryptoProTokens && !this.cryptoProTokensError)
      );
    },
  },

  watch: {
    isLoading: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$emit('isLoaded', val);
        }
      },
    },

    cryptoProTokensList(val) {
      this.checkTokenInSystem(val);
    },
  },

  // все из-за "keep-alive"
  // при переключении между способами авторизации не срабатывает mounted. Только activated
  async activated() {
    if (!this.timerCryptoProTokens && !this.isInitializing) {
      await this.init();
    }
  },

  async mounted() {
    await this.init();
  },

  methods: {
    name,
    date,

    async init() {
      this.isInitializing = true;
      await this.$store.dispatch('TOKENS/initCryptoPro');
      await this.$store.dispatch('TOKENS/cycleRefreshCryptoProTokens');
      this.isInitializing = false;
    },

    checkTokenInSystem(list = this.cryptoProTokensList) {
      const tokenInList = list.find(
        el => el?.certificate?.thumbprint === this.active,
      );
      if (this.active && !tokenInList) {
        this.active = null;
        this.$notify({
          group: 'note',
          type: 'error',
          title: 'Токен не найден в системе',
          text: 'Выбранный токен был извлечен из системы',
        });
      }
    },

    addedToAccount(serial) {
      // NOTE: user is still in the system, but the token is not valid,
      // in this case it is impossible to request a list of certificates
      // connected to the account
      if (this.softSignIn) return true;

      if (this.isAuthorized) {
        return this.accountCertificates.some(
          el =>
            (el.type === 'cryptopro' || el.type === 'cryptoprotest') &&
            el.details.serial.toUpperCase() === serial.toUpperCase(),
        );
      }

      // All CryptoPro tokens enabled for login page
      return true;
    },
  },
};
</script>
