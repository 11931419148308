import axios from 'axios';
import { FETCH_STATUS } from '@/utils/constants';

export function fetchFactory({ field, initialValue, customGetters, action }) {
  const firstLetterUpperCaseKey =
    field[0].toUpperCase() + field.slice(1, field.length);

  return {
    clean: state => {
      state[field] = initialValue;
      state[`${field}Status`] = FETCH_STATUS.IDLE;
      state[`${field}Error`] = undefined;
    },

    state: {
      [field]: initialValue,
      [`${field}Status`]: FETCH_STATUS.IDLE,
      [`${field}Error`]: undefined,
    },

    getters: {
      [field]: state => state[field],
      [`${field}Status`]: state => state[`${field}Status`],
      [`${field}Error`]: state => state[`${field}Error`],
      ...(customGetters && { ...customGetters }),
    },

    mutations: {
      [field]: (state, value) => {
        state[field] = value;
        state[`${field}Status`] = FETCH_STATUS.SUCCEEDED;
      },
      [`${field}Status`]: (state, value) => (state[`${field}Status`] = value),
      [`${field}Error`]: (state, err) => {
        state[`${field}Error`] = err;
        state[`${field}Status`] = FETCH_STATUS.ERROR;
      },
    },

    actions: {
      ...(action && {
        [`fetch${firstLetterUpperCaseKey}`]: async (
          { getters, dispatch, commit },
          ...args
        ) => {
          commit(`${field}Status`, FETCH_STATUS.LOADING);
          try {
            const value = await action({ getters, dispatch, commit }, ...args);
            commit(field, value);
          } catch (err) {
            if (axios.isCancel(err)) {
              commit(`${field}Status`, FETCH_STATUS.CANCELLED);
            } else {
              console.error(err);
              commit(`${field}Error`, err);
            }
          }
        },
      }),
    },
  };
}
