<template>
  <div class="d-flex py-4 px-6" style="gap: 10px">
    <v-switch
      v-for="key in Object.keys(devicesSettings)"
      :key="key"
      v-model="devicesSettings[key]"
      style="margin: auto !important; width: fit-content"
      inset
      hide-details
      class="ma-0 pa-0"
      false-value="manual"
      true-value="auto"
      :disabled="disabled"
    />
    <span class="grow">Автоматический режим работы термометра</span>
  </div>
</template>

<script>
export default {
  name: 'DevicesSettings',
  props: {
    value: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
  },

  computed: {
    devicesSettings: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
