import surveyApi from '@/api/services/survey';
import cloneDeep from 'lodash/cloneDeep';
import { can } from '@/plugins/can';
import env from '@/plugins/env';

export default {
  state: {
    surveys: null,
    surveyTypes: null,
  },

  getters: {
    surveys: state => state.surveys || {},
    surveyTypes: state => state.surveyTypes || {},
    showSurveys: () =>
      !env.get('VUE_APP_ADDITIONAL_SURVEYS_DISABLE') && can('SU_OGS'),
  },

  mutations: {
    surveys: (state, data) => (state.surveys = data),
    surveyTypes: (state, data) => (state.surveyTypes = data),
  },

  actions: {
    async fetchSurveys({ getters, commit }) {
      let result = await surveyApi.getTypes();
      commit('surveyTypes', result);

      result = await surveyApi.getSurveys(getters.id);
      commit('surveys', result);

      return result;
    },

    async changeSurveyStatus({ getters, dispatch }, key) {
      const payload = cloneDeep(getters.surveys);
      if (!payload.isEnabled) payload.isEnabled = true;
      payload.surveySettings[key].isEnabled =
        !payload.surveySettings[key].isEnabled;
      await dispatch('editSurveys', payload);
    },

    async editSurveys({ getters, commit }, payload) {
      const result = await surveyApi.editSurveys(
        getters.singleItem.id,
        payload,
      );
      commit('surveys', result);
    },
  },
};
