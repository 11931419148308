import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import RouteGuard from '@/components/RouteGuard';
import JOB_LIST from './store/list';
import JOB_ITEM from './store/item';

export default {
  namespaced: true,
  store: {
    JOB_LIST,
    JOB_ITEM,
  },
  routes: [
    {
      path: 'jobs',
      name: 'jobs',
      component: () => import('./view/list.vue'),
      meta: {
        action: 'SJ_R',
        title: 'Список задач',
      },
    },
    {
      path: 'jobs/create',
      name: 'jobs:create',
      component: RouteGuard({
        storeModule: 'SCHEDULER/JOB_ITEM',
        component: () => import('./view/form.vue'),
        skipFetch: true,
      }),
      meta: {
        action: 'SJ_C',
      },
    },
    {
      path: 'jobs/:id',
      name: 'jobs:item',
      component: RouteGuard({
        storeModule: 'SCHEDULER/JOB_ITEM',
        component: () => import('./view/item.vue'),
      }),
      meta: {
        action: 'SJ_RD',
      },
    },
    {
      path: 'jobs/:id/edit',
      name: 'jobs:edit',
      component: RouteGuard({
        storeModule: 'SCHEDULER/JOB_ITEM',
        component: () => import('./view/form.vue'),
      }),
      meta: {
        action: 'SJ_U',
      },
    },
  ],
  menu: [
    {
      title: 'Задачи',
      action: 'SJ_R',
      name: 'jobs',
      priority: 1,
    },
  ],
  modals: [
    {
      name: 'reportForm',
      component: () => import('./modals/ReportForm.vue'),
    },
    {
      name: 'testJob',
      component: () => import('./modals/TestJob.vue'),
    },
  ],
} as ModuleDefinition;
