<script>
import asyncSelectMixin from '@/components/controls/asyncSelectMixin';
import { mapActions } from 'vuex';

export default {
  extends: asyncSelectMixin,

  props: {
    accessLevel: {
      validator: value => ['any', 'full'].indexOf(value) !== -1,
      default: 'any',
    },
    medOrgIds: {
      type: [Array],
      default: null,
    },
  },

  computed: {
    label() {
      return this.multiple ? 'Категория' : 'Категории';
    },

    query() {
      return {
        accessLevel: this.accessLevel,
        medOrgIds: this.medOrgIds?.join() || undefined,
      };
    },
  },

  methods: {
    ...mapActions('STRUCTURES', {
      fetcher: 'fetchOrganizationCategoryPreviews',
      fetcherBy: 'fetchOrganizationCategoryPreviewsBy',
    }),
  },
};
</script>
