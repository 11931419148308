<template>
  <AsyncSelect
    v-bind="computedProps"
    v-on="{ ...$listeners, input: handleInput }"
  >
    <template #item:description="{ item }">
      <v-chip v-if="item.isMedical" color="success" x-small>
        Медорганизация
      </v-chip>
      <v-chip
        v-if="!item.isMedical && item.type == 'org'"
        color="primary"
        x-small
      >
        Организация
      </v-chip>
      <v-chip
        v-if="!item.isMedical && item.type == 'org_group'"
        color="warning"
        x-small
      >
        Группа организаций
      </v-chip>
      <v-chip v-if="!item.isActive" x-small color="error" class="ml-1">
        Неактивна
      </v-chip>
    </template>
  </AsyncSelect>
</template>

<script>
import asyncSelectMixin from '@/components/controls/asyncSelectMixin';

const PREFIX_TO_TYPE = { m: 'med_org', o: 'org', g: 'org_group' };
const TYPE_TO_PREFIX = { med_org: 'm', org: 'o', org_group: 'g' };

// in one select list all items must have unique id
// but in backend response can be items with the same id (from different tables)
// for this reason I place the type belonging symbol in front of each id to create unique id

export default {
  extends: asyncSelectMixin,

  props: {
    value: {
      type: Object,
      default: () => ({
        id: null,
        type: null,
      }),
    },
  },

  computed: {
    label() {
      return this.multiple
        ? 'Организации и Группы организаций'
        : 'Организация или Группа организаций';
    },

    computedValue() {
      if (!this.value) return null;

      return TYPE_TO_PREFIX[this.value.type] + this.value.id;
    },
  },

  methods: {
    fetcher(params) {
      return this.$store
        .dispatch('STRUCTURES/fetchOrgsAndOrgGroupsPreviews', params)
        .then(res => {
          return {
            ...res,
            items: res.items.map(mapItemValue),
          };
        });
    },

    async fetcherBy(ids) {
      const { medOrgs, orgs, orgGroups } = separateIds(ids);

      return this.$store
        .dispatch('STRUCTURES/fetchOrgsAndOrgGroupsPreviewsBy', {
          orgIds: orgs,
          medOrgIds: medOrgs,
          orgGroupIds: orgGroups,
        })
        .then(res => {
          return res.map(mapItemValue);
        });
    },

    handleInput(event) {
      if (!event) return this.$emit('input', null);

      this.$emit('input', {
        type: PREFIX_TO_TYPE[event[0]],
        id: Number(event.substring(1)),
      });
    },
  },
};

function mapItemValue(item) {
  const id = item.isMedical
    ? 'm' + item.id
    : TYPE_TO_PREFIX[item.type] + item.id;

  return { ...item, id };
}

function separateIds(ids) {
  return {
    medOrgs: ids
      .filter(item => item[0] === 'm')
      .map(item => Number(item.substring(1))),
    orgs: ids
      .filter(item => item[0] === 'o')
      .map(item => Number(item.substring(1))),
    orgGroups: ids
      .filter(item => item[0] === 'g')
      .map(item => Number(item.substring(1))),
  };
}
</script>
