<template>
  <v-layout fill-height column>
    <div class="pb-2 text-caption">
      Блокировки
      <v-tooltip right max-width="400px">
        <template #activator="{ on, attrs }">
          <v-btn small text icon v-bind="attrs" v-on="on">
            <v-icon small>far fa-question-circle</v-icon>
          </v-btn>
        </template>
        <span style="width: 200px">
          В списке выводятся только блокировки и разблокировки работника по
          алкоголю, травмам и изменением цвета кожного покрова
        </span>
      </v-tooltip>
    </div>
    <v-layout fill-height>
      <template v-if="value.length > 0">
        <v-menu
          v-for="item in items"
          :key="item.type + item.id"
          class="flex-grow-1"
          top
          offset-y
          open-on-hover
          nudge-bottom="-10px"
          close-delay="100"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              class="pa-0 flex-grow-1"
              :color="item.color"
              height="100%"
              text
              v-bind="attrs"
              column
              justify-space-between="justify-space-between"
              fill-height
              v-on="on"
            >
              <div class="d-flex flex-column text-center">
                <v-icon style="font-size: 20px; color: inherit">
                  {{ item.icon }}
                </v-icon>
                <span class="pt-2">{{ item.previewDate }}</span>
              </div>
            </v-btn>
          </template>
          <v-card class="pa-3 text-body-2" width="390px">
            <span class="pr-2 text-caption">{{ '#' + item.id }}</span>
            <span class="text-subtitle-2">{{ item.title }}</span>
            <v-divider class="my-2" />
            <div>
              <strong>{{ item.date }}</strong>
            </div>
            <template v-if="item.type == 'ban'">
              <div>{{ item.dueDate }}</div>
              <div v-if="item.medicId" class="mt-2">
                Заблокировал: {{ getMedicById(item.medicId) }}
              </div>
              <div>
                На основании осмотра
                <v-btn
                  class="px-1"
                  text
                  small
                  color="primary"
                  target="_blank"
                  :to="{
                    name: 'inspections:card',
                    params: { id: item.inspectionId },
                  }"
                >
                  #{{ item.inspectionId }}
                </v-btn>
              </div>
              <div>Причина: {{ item.reason }}</div>
              <div v-if="item.comment">Комментарий: {{ item.comment }}</div>
            </template>
            <template v-else>
              <div v-if="item.unban && item.unban.issuerId" class="mt-2">
                Разблокировал: {{ getMedicById(item.unban.issuerId) }}
              </div>
              <div class="mt-2">
                {{ item.unbanReason }}
                <v-btn
                  v-if="item.documentId"
                  class="px-1"
                  color="primary"
                  small
                  depressed
                  text
                  @click="showDocumentModal(item.documentId)"
                >
                  справки
                </v-btn>
              </div>
            </template>
          </v-card>
        </v-menu>
      </template>
      <v-card-text v-else class="text-center align-self-center">
        История банов и недопусков отсутствует
      </v-card-text>
    </v-layout>
  </v-layout>
</template>

<script>
import processingApi from '@/api/services/processing';
import { parseDate } from '@/utils/helpers';
import { dateTime, duration } from '@/utils/convert';

export default {
  props: {
    value: { type: Array, required: true },
    employee: { type: Object, required: true },
  },

  data() {
    return {
      medics: [],
    };
  },

  computed: {
    items() {
      return this.value
        .map(item => {
          if (item.isActive) {
            return [this.convertToBan(item)];
          }
          return [this.convertToBan(item), this.convertToUnban(item)];
        })
        .flat(1)
        .slice(0, 5);
    },
  },

  watch: {
    items: {
      immediate: true,
      handler(val) {
        if (!val.length) return;

        const medicIds = new Set();
        val.forEach(el => {
          medicIds.add(el.medicId);
          el.unban?.issuerId && medicIds.add(el.unban?.issuerId);
        }, []);
        this.fetchMedics([...medicIds]);
      },
    },
  },

  methods: {
    async fetchMedics(ids) {
      let medics = [];
      const idsFiltered = ids.filter(_ => _);
      if (idsFiltered.length)
        medics = await processingApi.getMedicsPreviewsBy(idsFiltered);
      this.medics = medics;
    },

    getMedicById(id) {
      return this.medics.find(el => el.id === id)?.name || '–';
    },

    convertToBan(item) {
      return {
        id: item.id,
        type: 'ban',
        icon: 'fas fa-lock',
        color: 'error',
        reason: item.banScenario.reason.description,
        inspectionId: item.inspectionId,
        previewDate: parseDate(item.issuedAt).toFormat('dd.MM'),
        title: 'Работник заблокирован',
        date: dateTime(item.issuedAt),
        dueDate: item.dueDate
          ? 'На ' + duration(item.issuedAt, item.dueDate)
          : 'До ручной разблокировки',
        comment: item.medicConclusion?.comment,
        medicId: item.medicId,
        unban: item.unban,
      };
    },
    convertToUnban(item) {
      const isAuto = !item.unban;
      const date = isAuto ? item.dueDate : item.unban.date;

      return {
        id: item.id,
        type: 'unban',
        icon: 'fas fa-unlock',
        color: 'secondary',
        isAuto,
        previewDate: parseDate(date).toFormat('dd.MM'),
        title:
          'Работник разблокирован ' + (isAuto ? 'автоматически' : 'вручную'),
        date: dateTime(date),
        documentId: item.unban?.documentId,
        unbanReason: isAuto
          ? 'Разблокирован автоматически'
          : 'Разблокирован вручную' +
            (item.unban.documentId ? ' на основании' : ''),
        medicId: item.medicId,
        unban: item.unban,
      };
    },

    showDocumentModal(id) {
      this.$openModal('documents/showIncoming', {
        id,
        employee: this.employee,
      });
    },
  },
};
</script>
