<template>
  <div>
    <DefinitionList :value="extrasData" />
    <NoDataAlert v-if="isEmpty" class="ma-4">
      Медицинские исследования отсутствуют
    </NoDataAlert>
  </div>
</template>

<script>
import DefinitionList from '@/components/DefinitionList';
import { COLORS } from '@/utils/constants';
import NoDataAlert from '@/components/ui/NoDataAlert';

export default {
  components: {
    DefinitionList,
    NoDataAlert,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    types: {
      type: Array,
      required: true,
    },
  },
  computed: {
    extrasData() {
      if (this.isEmpty) return [];

      return this.types.map(type => {
        let component = null;
        // Для холестерина нужно расскрасить значениЯ
        if (type.type === 'cholesterol') {
          const val = this.value[type.type].value;
          const style = { color: this.getCholesterolColor(val) };
          component = {
            render() {
              return (
                <div>
                  <span style={style}>{val} </span>
                  {type.unit}
                </div>
              );
            },
          };
        }

        return {
          label: type.name,
          component,
          value: this.value[type.type].value + ' ' + type.unit,
        };
      });
    },
    isEmpty() {
      const values = Object.values({ ...this.value, employeeId: null });
      return values.filter(item => item != null).length === 0;
    },
  },
  methods: {
    getCholesterolColor(val) {
      if (val < 5) return `rgb(${COLORS.green})`;
      else if (val <= 6.3) return `rgb(${COLORS.yellow})`;
      else return `rgb(${COLORS.red})`;
    },
  },
};
</script>
