<template>
  <div>
    <v-simple-table v-if="value.length > 0" dense class="text-center">
      <thead>
        <tr>
          <th class="text-center py-3">ID</th>
          <th class="text-center py-3">Название</th>
          <th class="text-center py-3">Время отправления</th>
          <th class="text-center py-3">Статус</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="job in value" :key="job.id">
          <td class="py-1">
            <v-btn
              v-if="$can('SJ_RD')"
              small
              text
              color="primary"
              :to="{
                name: 'scheduler:jobs:item',
                params: { id: job.id },
              }"
            >
              {{ job.id }}
            </v-btn>
            <span v-else>{{ job.id }}</span>
          </td>
          <td>{{ job.name }}</td>
          <td>
            {{ scheduleType(job.schedule.type) + ' в ' + job.schedule.time }}
          </td>
          <td>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <!-- div wrapper because we can't listen events on disabled button -->
                <div v-bind="attrs" v-on="on">
                  <div
                    class="job"
                    :class="
                      job.activatedAt ? 'green lighten-2' : 'grey lighten-1'
                    "
                  />
                </div>
              </template>
              <span>{{ job.activatedAt ? 'Активна' : 'Неактивна' }}</span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <NoDataAlert v-else class="ma-4">{{ noJobsText }}</NoDataAlert>
  </div>
</template>

<script>
import NoDataAlert from '@/components/ui/NoDataAlert';
import { SCHEDULE_TYPES } from '@/modules/scheduler/constants';

export default {
  components: { NoDataAlert },
  props: {
    value: { type: Array, required: true },
    self: { type: Boolean, default: false },
  },
  computed: {
    noJobsText() {
      return (this.self ? 'У вас' : 'У пользователя') + ' нет никаких рассылок';
    },
  },
  methods: {
    scheduleType(type) {
      return SCHEDULE_TYPES.find(item => item.value === type).text;
    },
  },
};
</script>

<style lang="scss" scoped>
.job {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 auto;
}
</style>
