<template>
  <v-dialog :value="true" :max-width="maxWidth" @input="handleClose">
    <v-card>
      <v-card-title v-if="!!title" class="d-flex justify-space-between">
        <span>{{ title }}</span>
      </v-card-title>
      <div class="px-6">
        <slot />
      </div>
      <v-card-actions class="d-flex justify-center">
        <v-btn v-if="!hideCloseButton" text @click="handleClose">
          {{ closeActionName }}
        </v-btn>
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'medium',
      validator: value => ['small', 'medium', 'large'].includes(value),
    },
    closeActionName: {
      type: String,
      default: 'Отмена',
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    maxWidth() {
      const sizeToWith = {
        small: '300px',
        medium: '500px',
        large: '700px',
      };
      return sizeToWith[this.size];
    },
  },

  methods: {
    handleClose() {
      this.$parent.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.modal-layout__content {
  max-height: 65vh;
  overflow-x: auto;
}
</style>
