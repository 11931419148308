import { COLORS } from '@/utils/constants';

export const SHIFT_TYPES = {
  active: 'Стандартный',
  passive: 'Альтернативный',
};

/** Цвета подсветки предупреждений */
export const BADGE_COLORS = {
  success: COLORS.green,
  medical: COLORS.orange,
  alcohol: COLORS.red,
  break: COLORS.purple,
  mute: COLORS.gray,
  malfunction: COLORS.blue,
};

/** Словарь перевода меток */
export const LABEL_TITLES = {
  beyond_boundaries: 'Выход за границы допуска',
  excess: 'Превышено пороговое значение',
};

/** Словарь вариантов ошибок в шагах осмотра */
export const STEP_ERROR_TYPES = {
  cancel: 'Работник не завершил осмотр',
  timeout: 'Время осмотра истекло',
  malfunction: 'Сбой оборудования',
};

/** Словарь типов шагов, аналогичный тому что на бэке */
export const STEP_TYPES = {
  TEMPERATURE: 'temperature',
  TONOMETRY: 'tonometry',
  ALCOHOL_SHORT: 'alcohol.short',
  ALCOHOL_LONG: 'alcohol.long',
  SATURATION: 'saturation',
  REACTIONS: 'reactions',
  COMPLAINTS: 'complaints',
  SLEEP: 'sleep',
  SIGNATURE: 'signature',
};

/** Ключи ремарок, использующиеся в нескольких местах */
export const REMARK_KEYS = {
  ALCOHOL: 'alcohol',
};

/** Словарь типов реакций */
export const REACTION_TYPES = {
  numbers: 'Шульте',
  dots: 'Тахистоскопия',
};

/** Варианты написания шага прохождения осмотра */
export const STEPS_CANCELED_TITLE = {
  [STEP_TYPES.TEMPERATURE]: {
    nominative: 'термометрия',
    genitive: 'термометрии',
  },
  [STEP_TYPES.TONOMETRY]: {
    nominative: 'тонометрия',
    genitive: 'тонометрии',
  },
  [STEP_TYPES.ALCOHOL_SHORT]: {
    nominative: 'качественный продув',
    genitive: 'качественного продува',
  },
  [STEP_TYPES.ALCOHOL_LONG]: {
    nominative: 'количественный продув',
    genitive: 'количественного продува',
  },
  [STEP_TYPES.SATURATION]: {
    nominative: 'сатурация',
    genitive: 'сатурации',
  },
  [STEP_TYPES.REACTIONS]: {
    nominative: 'реакция',
    genitive: 'реакции',
  },
  [STEP_TYPES.COMPLAINTS]: {
    nominative: 'жалоба',
    genitive: 'жалоб',
  },
  [STEP_TYPES.SLEEP]: {
    nominative: 'качество сна',
    genitive: 'качества сна',
  },
  [STEP_TYPES.SIGNATURE]: {
    nominative: 'подпись',
    genitive: 'подписи',
  },
};
