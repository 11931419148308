import RouteGuard from '@/components/RouteGuard';
import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import MEDICS from './store';

export default {
  namespaced: true,
  store: {
    MEDICS,
  },
  routes: [
    {
      path: 'medics',
      name: 'medics:list',
      component: () => import('./views/list.vue'),
      meta: {
        title: 'Все медработники',
        action: 'MED_R',
      },
    },
    {
      path: 'medics/:id',
      name: 'medics:item',
      component: RouteGuard({
        storeModule: 'MEDBLOCK/MEDICS',
        component: () => import('./views/item.vue'),
      }),
      meta: {
        title: 'Медицинский работник',
        action: 'MED_RD',
      },
    },
  ],

  menu: [
    {
      title: 'Медработники',
      action: 'MED_R',
      name: 'medics:list',
      priority: 1,
    },
  ],

  modals: [
    {
      name: 'medics/form',
      component: () => import('@/modules/medblock/medics/modals/MedicForm.vue'),
    },
    {
      name: 'medics/certificates',
      component: () =>
        import('@/modules/medblock/medics/modals/MedicCertificates.vue'),
    },
    {
      name: 'medics/changeOrganization',
      component: () =>
        import('@/modules/medblock/medics/modals/ChangeOrganization.vue'),
    },
  ],
} as ModuleDefinition;
