<template>
  <v-simple-table dense class="actual-answers">
    <tbody>
      <tr
        v-for="{ key, status, surveyDate, value, answerId } in answers"
        :key="answerId"
        :class="{ 'grey--text': status !== 'actual' }"
      >
        <td style="width: 80%" class="actual-answers__text">
          <div class="d-flex flex-column align-start">
            <span>{{ getQuestion(key) }}</span>
            <span class="text-caption">
              {{ `${getDate(surveyDate)} ${statusText(status)}` }}
            </span>
          </div>
        </td>
        <td style="width: 20%" class="actual-answers__text">
          {{ value || '-' }}
        </td>
        <td v-if="$can('ANS_INV')" class="my-0 ml-2">
          <v-tooltip left>
            <template #activator="{ on, attrs }">
              <v-btn
                v-if="status === 'actual' && value"
                v-bind="attrs"
                icon
                v-on="on"
                @click="invalidate(answerId, key)"
              >
                <v-icon size="15">fa-times-circle</v-icon>
              </v-btn>
            </template>
            <span>Сбросить ответ</span>
          </v-tooltip>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import {
  QUESTIONS,
  ANSWERS_STATUS,
  CRUTCH_HIERARCHY_QUESTIONS_MAP,
} from '@/utils/constants';
import { dateTime } from '@/utils/convert';
import { mapActions } from 'vuex';

export default {
  name: 'ActualAnswers',
  props: {
    answers: { type: Array, default: () => [] },
    organisationId: { type: Number, required: true },
  },
  methods: {
    ...mapActions('EMPLOYEE', ['invalidateAnswer']),

    getQuestion(key) {
      return QUESTIONS[key] || key;
    },
    statusText(status) {
      return ANSWERS_STATUS[status] ? `(${ANSWERS_STATUS[status]})` : '';
    },
    getDate(date) {
      return dateTime(date, { format: 'dd.MM.yy' });
    },
    invalidate(answerId, key) {
      // FIXME:(surveys) на бэке обещали сделать иерархическую зависимость вопросов
      // Если у вопроса есть дочерние, и у них актуальный статус, то необходимо сбросить и их
      const invalidAnswersIds = this.answers
        .filter(
          answer =>
            ((CRUTCH_HIERARCHY_QUESTIONS_MAP[key] || []).includes(answer.key) ||
              answer.answerId === answerId) &&
            answer.status === 'actual',
        )
        .map(answer => answer.answerId);
      this.invalidateAnswer({
        invalidAnswersIds,
        organisationId: this.organisationId,
      });
    },
  },
};
</script>

<style lang="scss">
.grey--text .actual-answers__text {
  color: #9e9e9e;
}
</style>
