<template>
  <v-select
    :value="value"
    label="Форм-фактор"
    :items="ffs"
    item-value="value"
    item-text="name"
    multiple
    hide-details
    outlined
    dense
    :menu-props="{ offsetY: true }"
    @input="val => $emit('input', val)"
  >
    <template #prepend-item>
      <v-list-item ripple @mousedown.prevent @click="toggleAllFF">
        <v-list-item-action>
          <v-icon :color="value && value.length > 0 ? 'indigo darken-4' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Все виды </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2" />
    </template>
  </v-select>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    value: { type: [Array], default: () => [] },
  },

  computed: {
    ...mapGetters('STRUCTURES/HOSTS_ITEM', ['formFactors']),
    ffs() {
      return Object.entries(this.formFactors || []).map(([k, v]) => ({
        value: k,
        name: v,
      }));
    },

    allFFChosen() {
      return this.value?.length === this.ffs.length;
    },
    someFFChosen() {
      return this.value?.length > 0 && !this.allFFChosen;
    },
    icon() {
      if (this.allFFChosen) return 'mdi-close-box';
      if (this.someFFChosen) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },

  async created() {
    this.$store.dispatch('STRUCTURES/HOSTS_ITEM/fetchFormFactors');
  },

  methods: {
    toggleAllFF() {
      this.$nextTick(() => {
        if (this.allFFChosen) {
          this.$emit('input', []);
        } else {
          this.$emit(
            'input',
            this.ffs.map(v => v.value),
          );
        }
      });
    },
  },
};
</script>
