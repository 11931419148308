<template>
  <v-list-item
    :disabled="disabled"
    :three-line="subtitle ? subtitle.length > 34 : false"
    :to="to"
    @click="$emit('click')"
  >
    <v-list-item-icon>
      <slot name="icon" />
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title>
        <slot name="title">{{ title }}</slot>
      </v-list-item-title>
      <v-list-item-subtitle v-if="$scopedSlots.subtitle || subtitle">
        <slot name="subtitle">{{ subtitle }}</slot>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    subtitle: { type: String, default: '' },
    disabled: Boolean,
    to: { type: [Object, String], default: undefined },
  },
};
</script>
