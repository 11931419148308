<template>
  <v-list-item
    class="flex-grow-0 pa-0"
    style="flex-basis: auto; max-width: 100%"
  >
    <v-list-item-content class="pa-0">
      <div class="d-flex flex-column">
        <div class="secondary--text font-weight-medium pl-3">
          <router-link
            v-if="!isRestricted"
            class="pr-1 text-decoration-none"
            target="_blank"
            :to="{ name: 'employees:card', params: { id: value.id } }"
          >
            <v-icon class="preview-profile__icon" small>
              fas fa-external-link-alt
            </v-icon>
          </router-link>
          <span style="vertical-align: middle">{{ fullname }}</span>
        </div>
        <div>
          <v-btn
            class="mt-2"
            text
            small
            color="secondary"
            target="_blank"
            :disabled="!$can('ORG_RD') || isOrgRestricted"
            :to="{
              name: 'structures:organizations:item',
              params: { id: value.organization.id },
            }"
          >
            {{ value.organization.name }}
          </v-btn>
        </div>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { name as fullname } from '@/utils/convert';
import structApi from '@/api/services/structures';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // NOTE: is current user restricted from viewing this inspection employee
      // card true by default for security reasons, so the user can't slip in when
      // the request hasn't been resolved yet
      isRestricted: true,
      isOrgRestricted: true,
    };
  },
  computed: {
    fullname() {
      return fullname(this.value);
    },
  },
  async created() {
    const { isRestricted } = await structApi.getPermissionToReadEmployee(
      this.value.id,
    );
    this.isRestricted = isRestricted;

    const orgPreview = await structApi.getOrganizationPreviewsBy([
      this.value.organization.id,
    ]);
    this.isOrgRestricted = orgPreview[0]?.isRestricted;
  },
};
</script>
<style scoped lang="scss">
.preview-profile__icon:hover {
  color: #1976d2;
}
</style>
