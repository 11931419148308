<script>
import Spinner from './Spinner.vue';
import ErrorBlock from './ErrorBlock';

export default {
  props: {
    handler: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    isLoading: true,
    result: null,
    error: null,
  }),
  async created() {
    await this.handleFetchData();
  },
  methods: {
    async handleFetchData() {
      this.result = null;
      this.error = null;
      this.isLoading = true;
      try {
        this.result = await this.handler();
      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
    renderLoading() {
      if (this.$scopedSlots.loading) return this.$scopedSlots.loading();

      return <Spinner />;
    },
    renderError(error) {
      if (this.$scopedSlots.error) return this.$scopedSlots.error(error);

      return (
        <ErrorBlock
          class="mb-0"
          error={error}
          onRefresh={this.handleFetchData}
        />
      );
    },
    renderContent(result) {
      if (this.$scopedSlots.default) return this.$scopedSlots.default(result);

      return (
        <div>
          <strong>No default slot</strong>
          <pre>{result}</pre>
        </div>
      );
    },
  },
  render() {
    if (this.isLoading) {
      return this.renderLoading();
    } else if (this.error) {
      return this.renderError(this.error);
    }
    return this.renderContent(this.result);
  },
};
</script>
