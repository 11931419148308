<template>
  <v-col
    v-ripple="!readonly"
    class="d-flex pt-3 py-2 resolve-reason-item"
    cols="6"
    @click="readonly ? null : $emit('input', item.id)"
  >
    <v-simple-checkbox
      class="align-self-start"
      :value="value"
      color="primary"
      :disabled="readonly"
      @input="$emit('input', item.id)"
    />
    <span class="px-1" :class="{ 'text--disabled': readonly }">
      {{ item.name }}
    </span>
  </v-col>
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
    value: { type: Boolean, required: true },
    readonly: { type: Boolean, default: null },
  },
};
</script>

<style>
.resolve-reason-item {
  cursor: pointer;
}
</style>
