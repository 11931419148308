<template>
  <v-tooltip bottom :disabled="isActive">
    <template #activator="{ on }">
      <v-chip
        small
        class="mr-2 mb-3 text-wrap"
        style="height: auto"
        :color="!isActive ? 'red' : null"
        :text-color="!isActive ? 'white' : null"
        v-on="on"
      >
        {{
          typeof value === 'object'
            ? value.name || value.address || '#' + value.id
            : '#' + value
        }}
      </v-chip>
    </template>
    <span>Неактивна</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    value: {
      type: [Object, Number],
      required: true,
    },
  },
  computed: {
    isActive() {
      // if isActive equal undefined, entity can not be set not active
      return this.value?.isActive === undefined
        ? true
        : this.value?.isActive === true;
    },
  },
};
</script>
