<template>
  <v-form>
    <v-text-field
      v-model="username"
      label="Логин"
      outlined
      depressed
      dense
      tabindex="1"
      autofocus
      persistent-placeholder
      :disabled="softAuth || $wait('login')"
      :error-messages="getValidationErrors('username')"
      :error="!!authError"
      @input="authError = null"
    />
    <v-text-field
      v-model.trim="password"
      label="Пароль"
      name="password"
      type="password"
      outlined
      depressed
      dense
      tabindex="2"
      persistent-placeholder
      :disabled="$wait('login')"
      :error-messages="authError || getValidationErrors('password')"
      @input="authError = null"
      @keyup.enter="onSubmit"
    />
    <v-card-actions class="pt-0 d-flex justify-center">
      <v-btn
        v-if="softAuth"
        class="px-10"
        :disabled="$wait('login')"
        height="40"
        color="primary"
        outlined
        @click="signOut"
      >
        Выйти
      </v-btn>
      <v-btn
        class="px-5"
        :loading="$wait('login')"
        height="40"
        color="primary"
        @click="onSubmit"
      >
        {{ softAuth ? 'Продолжить' : 'Войти' }}
      </v-btn>
    </v-card-actions>

    <div v-if="!softAuth" class="text-center mt-2">
      <v-btn
        v-for="[testUsername, testPassword] in testCredentials"
        :key="testUsername"
        :disabled="$wait('login')"
        depressed
        x-small
        @click="signInBy(testUsername, testPassword)"
      >
        Log in by {{ testUsername }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, minLength, email } from 'vuelidate/lib/validators';
import validationMixin from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import authFormMixin from './authFormMixin';

export default {
  mixins: [validationMixin, waitable, authFormMixin],

  validations() {
    return {
      username: {
        required,
        email,
        minSymbolsLength: minLength(3),
      },
      password: {
        required,
        minSymbolsLength: minLength(3),
      },
    };
  },

  data: () => ({
    username: '',
    password: '',
    authError: null,
  }),

  computed: mapGetters('AUTH', ['testCredentials']),

  watch: {
    // For comfortable development
    testCredentials: {
      immediate: true,
      handler(value) {
        if (!this.matchBy) return;
        const credentials = value.find(([key]) => key === this.matchBy) || [];
        this.password = credentials[1];
      },
    },
  },

  created() {
    if (this.matchBy) this.username = this.matchBy;
  },

  methods: {
    signInBy(username = 'superuser', password = 'admin') {
      this.username = username;
      this.password = password;
      this.onSubmit();
    },

    async onSubmit() {
      this.authError = null;
      this.$v.$touch();

      if (this.$v.$invalid) return null;

      try {
        await this.$loading(
          this.signIn({
            username: this.username,
            password: this.password,
          }),
          'login',
        );
        this.username = '';
        this.password = '';
        this.$v.$reset();
      } catch (error) {
        this.$v.$reset();

        console.error(error);
        const status = error?.response?.status || null;
        const messagesStatus = {
          401: 'Неверный пароль или логин',
          403: 'Доступ заблокирован',
          404: 'Пользователя с такой почтой не существует',
        };

        if (error.message === 'Network Error')
          this.authError =
            'Ошибка сети. Проверьте интернет соединение или обратитесь в ТП';
        else if (error.message === 'no_certificate')
          this.authError = 'Сертификат не установлен. Установите сертификат.';
        else if (error.message === 'esia_error')
          this.authError = 'Ошибка авторизации через Госуслуги';
        else if (error.type === 'esia_error') this.authError = error.message;
        else if (/.*disabled.*/.test(error.response?.data?.message))
          this.authError = 'Аккаунт деактивирован';
        else if (messagesStatus[status])
          this.authError = messagesStatus[status];
        else this.authError = 'Произошла ошибка авторизации';
      }
    },
  },
};
</script>
