import { createDetachedSignature, createHash } from '@libs/crypto-pro';
import signApi from '@/api/services/sign';
import { Token } from './Token';
import { debug } from '@/utils/helpers';

const signerLog = text => debug('SIGNER', '#4CAF50')(`[CryptoPro] ${text}`);

export class CryptoProToken extends Token {
  constructor(type, certificate) {
    super({ type, certificate });
  }

  async computedFields() {
    const item = {
      type: this.type,
      thumbprint: this.certificate.thumbprint,
      validFrom: this.certificate.validFrom,
      validTo: this.certificate.validTo,
      isValid: await this.certificate.isValid(),
      serial: await this.certificate.getCadesProp('SerialNumber'),
    };

    const ownerInfo = await this.certificate.getOwnerInfo();
    const [name, patronymic] = ownerInfo
      .find(el => el.title.includes('Имя Отчество'))
      ?.description?.split(' ') || ['Нетимени', null];
    item.ownerData = {
      company: ownerInfo.find(el => el.title.includes('Компания'))?.description,
      position: ownerInfo.find(el => el.title.includes('Должность'))
        ?.description,
      person: {
        surname:
          ownerInfo.find(el => el.title.includes('Фамилия'))?.description ||
          'Нетфамилии',
        name,
        patronymic: patronymic || null,
      },
    };

    const issuerInfo = await this.certificate.getIssuerInfo();

    item.issuer =
      issuerInfo.find(el => el.title.includes('Удостоверяющий центр'))
        ?.description || '';

    // якуты делают пиратские ключи, поэтому у них ФИО все большими буквами
    // строки ниже приводят всё к унифицированному формату
    const a = item.ownerData.person; // let the pointers do their job ^^
    const capitalize = word => word.charAt(0) + word.slice(1).toLowerCase();
    a.surname && (a.surname = capitalize(a.surname));
    a.patronymic && (a.patronymic = capitalize(a.patronymic));
    a.name && (a.name = capitalize(a.name));

    const freezeItem = Object.freeze(item);
    this.data = freezeItem;
    return freezeItem;
  }

  async inSystem(cryptoProTokens) {
    if (!Array.isArray(cryptoProTokens))
      throw Error('CryptoProTokens is not array');

    const findActive = cryptoProTokens.some(
      el => el.data.serial === this.data.serial,
    );
    return Boolean(findActive);
  }

  async tickSignDocument(doc) {
    const file = await signApi.loadFileForSign(doc.url);
    const hash = await createHash(file);
    const signature = await createDetachedSignature(this.data.thumbprint, hash);

    const payload = {
      signature,
    };
    await signApi.sendTaskComplete(doc.id, payload);
  }

  /**
   * @public
   */
  getSerial() {
    return this.data.serial;
  }

  /**
   * @public
   */
  async checkIsValid({ cryptoProTokens }) {
    const inSystem = await this.inSystem(cryptoProTokens);
    if (!inSystem)
      return this.generateError('Выбранный сертификат не подключен в систему');

    const isValid = await this.certificate.isValid();
    if (!isValid)
      return this.generateError(
        'Сертификат не валиден, или истек срок действия',
      );

    return { type: 'success', value: true };
  }

  /**
   * @public
   */
  async initialize() {
    const params = {
      type: this.type,
      matchBy: this.data.serial,
      issuedAt: this.data.validFrom,
      expiresAt: this.data.validTo,
      owner: this.data.ownerData.person,
      serial: this.data.serial,
      externalId: this.data.externalId,
      issuer: this.data.issuer,
    };

    const cert = await signApi.initialize(this.accountCertificate.id, params);

    this.setAccountCertData(cert);
    return cert;
  }

  async signDocuments({ list, counter }) {
    for (const el of list) {
      try {
        if (counter) counter.tick();
        await this.tickSignDocument(el);
      } catch (err) {
        console.error(`Document signing error ${el.id}:`, err);
      }
    }
  }

  /**
   * @public
   */
  async signTasks({ force, counter, setAllCountDocs }) {
    const unsignedDocs = await signApi.takeTasks({
      type: this.type,
      certificateId: this.accountCertificate.id,
      ...(force && { force: true }),
    });

    if (unsignedDocs?.taken?.length) {
      signerLog(
        `Run signer for ${unsignedDocs.taken.length} unsignedDocs`,
        unsignedDocs,
      );

      // set count documents on queue on store
      if (setAllCountDocs)
        setAllCountDocs(
          counter.value() + unsignedDocs?.taken?.length + unsignedDocs.left,
        );

      try {
        await this.signDocuments({ list: unsignedDocs.taken, counter });
        signerLog(
          `${unsignedDocs.taken.length} / ${
            unsignedDocs.taken.length + unsignedDocs.left
          } documents successfully signed`,
        );
        if (unsignedDocs.left)
          return this.signTasks({ counter, setAllCountDocs });
      } catch (err) {
        signerLog(
          'An error occurred during signing unsignedDocs,',
          err?.message || err,
        );
        const myError = new Error('Не удалось подписать документы');
        myError.stack = err.stack;
        throw myError;
      }
    } else {
      signerLog('No have docs for signing');
    }
  }
}
