import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import RATINGS from './store/list';

export default {
  namespaced: true,
  store: { RATINGS },

  routes: [
    {
      path: 'evaluations',
      name: 'evaluations',
      component: () => import('./view/list.vue'),
      meta: {
        title: 'Рейтинг работника',
        action: 'G_EVA',
      },
    },
  ],

  modals: [
    {
      name: 'evaluation',
      component: () =>
        import('@/modules/medblock/rating/modals/Evaluation.vue'),
    },
  ],
  menu: [
    {
      title: 'Рейтинг работника',
      action: 'G_EVA',
      name: 'evaluations',
      priority: 3,
    },
  ],
} as ModuleDefinition;
