<template>
  <v-simple-table dense>
    <colgroup>
      <col width="200" />
      <col width="160" />
      <col width="130" />
    </colgroup>
    <thead>
      <tr>
        <th class="pa-4">UUID</th>
        <th class="pa-4">Ключ</th>
        <th class="pa-4">Срок действия</th>
        <th class="pa-4">Статус</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in value" :key="item.id">
        <td class="pa-4 grey--text" style="font-family: monospace">
          {{ item.uuid }}
        </td>
        <td class="pa-4" style="font-family: monospace">
          {{ item.key }}
        </td>
        <td class="pa-4">
          {{ item.expiresAt ? 'До ' + dateTime(item.expiresAt) : 'Бессрочно' }}
        </td>
        <td class="pa-4">
          <div class="mb-2">
            <Round
              size="10px"
              :type="
                item.isRevoked
                  ? 'red'
                  : item.isExpired
                  ? 'orange lighten-2'
                  : 'green lighten-2'
              "
            />
            {{
              `${
                item.isRevoked
                  ? 'Отозван'
                  : item.isExpired
                  ? 'Истек'
                  : 'Активен'
              }`
            }}
            {{
              (!(compareDates(curDT(), item.ignoreExpiredErrorUntil) > 0) &&
                ', уведомления отключены до ' +
                  dateTime(item.ignoreExpiredErrorUntil, {
                    timezone: 0,
                    showTimeZone: true,
                  })) ||
              ''
            }}
          </div>
          <v-btn
            v-if="$can('APP_RAK') && (item.isExpired || item.isRevoked)"
            x-small
            depressed
            color="warning"
            outlined
            class="mb-2"
            @click="$emit('muteByExpired', item)"
          >
            <v-icon size="14">mdi-bell-off</v-icon>
            <div class="ml-1">
              {{
                compareDates(curDT(), item.ignoreExpiredErrorUntil) > 0
                  ? 'Выкл.'
                  : 'Вкл.'
              }}
              уведомления
            </div>
          </v-btn>
          <v-btn
            v-if="$can('APP_RK') && !item.isRevoked"
            x-small
            depressed
            color="error"
            outlined
            @click="$emit('deactivate', item)"
          >
            <v-icon size="14">mdi-power</v-icon>
            Отозвать
          </v-btn>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { dateTime } from '@/utils/convert';
import { compareDates, currentDateTime } from '@/utils/helpers';
import Round from '@/components/Round.vue';

export default {
  components: { Round },

  props: {
    value: {
      type: Array,
      required: true,
    },
  },

  methods: {
    dateTime,
    compareDates,
    // ебучий prettier
    curDT: currentDateTime,
  },
};
</script>
