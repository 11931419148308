<template>
  <AsyncSelect v-bind="computedProps" v-on="$listeners">
    <template #item:description="{ item }">
      <v-chip v-if="item.isGuest" color="primary" x-small> Гость </v-chip>
      <v-chip x-small :class="item.isGuest ? 'ml-1' : ''">
        {{ item.organization.name }}
      </v-chip>
    </template>
  </AsyncSelect>
</template>

<script>
// NOTE: backend MUST add org information (at least 'name' field) to
// host groups objects that im gettin from server
import asyncSelectMixin from '@/components/controls/asyncSelectMixin';

import { mapActions } from 'vuex';
export default {
  extends: asyncSelectMixin,
  props: {
    accessLevel: {
      validator: value => ['any', 'full'].indexOf(value) !== -1,
      default: 'any',
    },
    orgIds: { type: [Array], default: null },
    type: {
      validator: value => ['all', 'public', 'system'].indexOf(value) !== -1,
      default: 'public',
    },
    omitGuests: { type: Boolean, default: false },
  },

  computed: {
    label() {
      return this.multiple ? 'Группы ПАК' : 'Группа ПАК';
    },
    query() {
      return {
        accessLevel: this.accessLevel,
        orgGroupIds: this.orgGroupIds?.join() || undefined,
        orgIds: this.orgIds?.join() || undefined,
        type: this.type === 'all' ? undefined : this.type,
        omitGuests: this.omitGuests,
      };
    },
  },

  methods: {
    ...mapActions('STRUCTURES', {
      fetcher: 'fetchHostGroupPreviews',
      fetcherBy: 'fetchHostGroupPreviewsBy',
    }),
  },
};
</script>
