<template>
  <List
    search-filter
    :available-actions="{
      createAction: $can('NF_PC'),
      showAction: $can('NF_PR'),
      editAction: false,
      deleteAction: false,
    }"
    @item:create="() => $router.push({ name: 'notifications:profiles:create' })"
  />
</template>

<script>
import ListFactory from '@/components/crud/ListFactory';
import { messages, entity } from '../entity';

const List = ListFactory({
  storeModule: 'NOTIFICATIONS/PROFILES',
  messages,
  entity,
  viewRoute: 'notifications:profiles:item',
});

export default {
  components: { List },
};
</script>
