import omit from 'lodash/omit';
import { DateTime } from '@/plugins/luxon';
import {
  Field,
  ReportScheme,
  FieldValues,
  ReportSchemes,
  FieldPresets,
  Reports,
} from './models';

/**
 * Добавить в объекты полей соответствующие пресеты
 */
export function mergeReportFields(
  fields: Field[],
  fieldPresets: FieldPresets,
): Field[] {
  return fields
    .map(field => {
      if (!field.preset) return field;

      const preset = fieldPresets[field.preset];

      if (!preset) {
        console.warn(
          '(REPORTS WARN)',
          `Field preset with name "${field.preset}" is not exist.`,
          `Found in field with name "${field.name}".`,
        );
        return field;
      }

      return {
        ...preset,
        ...field,
        // Merge props
        props: (context: any) => {
          return {
            ...(typeof preset.props === 'function'
              ? preset.props(context)
              : preset.props),
            ...(typeof field.props === 'function'
              ? field.props(context)
              : field.props),
          };
        },
      };
    })
    .map(compiledField => {
      // Check required fields
      if (!compiledField.name)
        console.warn('(REPORTS WARN)', `Required "field.name" is not exist.`);
      if (!compiledField.component)
        console.warn(
          '(REPORTS WARN)',
          `Required "field.component" is not exist.`,
        );

      return compiledField;
    });
}

export function compileReportSchemes(
  reportSchemes: ReportSchemes,
  fieldPresets: FieldPresets,
): Reports {
  return Object.fromEntries(
    Object.entries(reportSchemes).map(([name, reportScheme]) => {
      const report = {
        ...reportScheme,
        fields: mergeReportFields(reportScheme.fields, fieldPresets),
        prepareValues: (filters: FieldValues) => prepareValues(filters, report),
        formComponent: () => import('./components/SchemeForm.vue'),
      };
      return [name, report];
    }),
  );
}

export function getAvailableFieldNames({ fields, fixedValues }: ReportScheme) {
  const names = fields.reduce((acc: any[], field) => {
    return [...acc, ...(Array.isArray(field.name) ? field.name : [field.name])];
  }, []);

  return [...names, ...Object.keys(fixedValues || {})];
}

export function getFieldDefinitionByName(
  fields: Field[],
  name: string,
): Partial<Field> {
  return (
    fields.find(field =>
      Array.isArray(field.name)
        ? field.name.includes(name)
        : field.name === name,
    ) || {}
  );
}

export function reduceFieldName<T>(
  field: Field,
  cb: (acc: T | undefined, name: string) => T,
) {
  if (Array.isArray(field.name))
    return field.name.reduce<T | undefined>(
      (acc, name) => cb(acc, name),
      undefined,
    );
  else if (field.name) return cb(undefined, field.name);
}

// FIXME: определить может ли быть объект в дате
// eslint-disable-next-line @typescript-eslint/ban-types
export function prepareDate(date: string | Object, timezone?: number) {
  if (typeof date === 'object') return date;
  if (typeof timezone === 'undefined') return date;

  return DateTime.fromISO(date, { setZone: true })
    .setZone((timezone * 60) as any, { keepLocalTime: true })
    .toISO();
}

export function prepareValues(
  values: FieldValues,
  { fields, fixedValues, batchByField, batchedFieldName }: ReportScheme,
) {
  values = {
    ...values,
    ...fixedValues,
  };

  const fieldNames = getAvailableFieldNames({ fields, fixedValues });

  if (!fieldNames.length) return {};

  const preparedValues = Object.fromEntries(
    Object.entries(values)
      .map(([name, value]) => {
        if (!fieldNames.includes(name)) return [];

        const fieldDefinition = getFieldDefinitionByName(fields, name);

        return fieldDefinition.prepareValue
          ? fieldDefinition.prepareValue(name, value, values) || []
          : [name, value];
      })
      .filter(item => item.length),
  );

  // Приводим запрос к множественному формату с перебором по полю `batchByField`
  if (batchByField && Array.isArray(preparedValues[batchByField])) {
    const batchedValues = preparedValues[batchByField].map((item: any) => ({
      ...omit(preparedValues, batchByField),
      [batchedFieldName || batchByField]: item,
    }));

    return batchedValues.length === 1 ? batchedValues[0] : batchedValues;
  }

  return preparedValues;
}
