import Vue from 'vue';
import signApi from '@/api/services/sign';
import { debug } from '@/utils/helpers';
import plural from 'plural-ru';

const signerLog = debug('SIGNER', '#4CAF50');

const FIRST_SIGNING_DELAY = 5_000;
const REPEATED_SIGNING_DELAY = 35_000;
const RESTART_SIGNING_AFTER = 5 * 60_000;
const MAX_SIGNING_RETRIES = 10;

const state = {
  nextSignTimerId: null,
  signerError: null,
  countTasks: null,
  signerStatus: null,
  countSignedDocs: null,
  allCountDocs: null,
  signerErrorCount: 0,
};

const getters = {
  isSigning: state => state.signerStatus === 'signing',
  isStoppedAfterMaxSigningRetries: state =>
    state.signerErrorCount >= MAX_SIGNING_RETRIES,

  nextSignTimerId: state => state.nextSignTimerId,
  signerError: state => state.signerError,
  countTasks: state => state.countTasks,
  signerStatus: state => state.signerStatus,
  countSignedDocs: state => state.countSignedDocs,
  allCountDocs: state => state.allCountDocs,
  signerErrorCount: state => state.signerErrorCount,
};

const mutations = {
  nextSignTimerId: (state, value) => (state.nextSignTimerId = value),
  setSignerError: (state, error) => (state.signerError = error),
  setCountTasks: (state, count) => (state.countTasks = count),
  setSignerStatus: (state, status) => (state.signerStatus = status),
  countSignedDocs: (state, count) => (state.countSignedDocs = count),
  allCountDocs: (state, count) => (state.allCountDocs = count),
  setSignerErrorCount: (state, count) => (state.signerErrorCount = count),
};

const actions = {
  async initSigner({ state, commit, dispatch }) {
    if (state.nextSignTimerId) clearTimeout(state.nextSignTimerId);
    try {
      commit('setSignerErrorCount', 0);

      signerLog('Signature initialization');

      const id = setTimeout(
        () => dispatch('executeTasks'),
        FIRST_SIGNING_DELAY,
      );
      commit('nextSignTimerId', id);
    } catch (err) {
      signerLog('An error occurred during initialization sign,', err);
    }
  },

  async executeTasks(
    { getters, commit, dispatch, rootGetters },
    { force } = {},
  ) {
    // Запретить запуск параллельного цикла подписи
    if (getters.isSigning) return;

    const token = rootGetters['TOKENS/activeToken'];
    if (!token) {
      signerLog('Signing stopped. Not selected certificate for sign.');
      return;
    }

    try {
      commit('setSignerError', null);
      commit('setSignerStatus', 'signing');

      const counter = (() => {
        let i = 0;
        return {
          tick: () => commit('countSignedDocs', i++),
          value: () => i,
        };
      })();

      const setAllCountDocs = count => commit('allCountDocs', count);
      await token.signTasks({ force, counter, setAllCountDocs });

      commit('setSignerStatus', null);
      commit('setSignerErrorCount', 0);
      commit('setCountTasks', 0);
    } catch (err) {
      signerLog('Error signing: ', err?.message || err);
      commit('setSignerStatus', 'error');
      commit('setSignerErrorCount', getters.signerErrorCount + 1);
      commit('setSignerError', 'Не удалось подписать документы');
    } finally {
      commit('countSignedDocs', null);
      commit('allCountDocs', null);
    }

    // Если больше MAX_SIGNING_RETRIES попыток подписи документов,
    // то приостанавливаем цикл и делаем попытки раз в 5 минут
    if (getters.signerErrorCount >= MAX_SIGNING_RETRIES) {
      signerLog('Signing stopped. Signature attempts exceeded.');
      commit(
        'nextSignTimerId',
        setTimeout(() => dispatch('executeTasks'), RESTART_SIGNING_AFTER),
      );
      return;
    }
    commit(
      'nextSignTimerId',
      setTimeout(() => dispatch('executeTasks'), REPEATED_SIGNING_DELAY),
    );
  },

  async checkNotSignedDocuments({ commit, rootGetters }) {
    const account = rootGetters['AUTH/currentAccount'] || null;
    if (account?.type !== 'medic') return;

    const { count: countTasks } = await signApi.getTasks();
    const messages = [];

    const token = rootGetters['TOKENS/isActiveToken'];
    if (countTasks && !token) {
      const pluralTasks = plural(
        countTasks,
        'документ',
        'документа',
        'документов',
      );
      messages.push(`У вас в очереди ${countTasks} ${pluralTasks} на подпись.`);
      commit('setCountTasks', countTasks);
    }
    if (!token)
      messages.push(
        'Не выбран сертификат для подписи документов,' +
          ' проведенные осмотры не будут подписаны.',
      );

    if (messages.length) {
      Vue.prototype.$notify({
        group: 'note',
        type: 'error',
        title: messages.join('\n'),
        delay: 10000,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
