<template>
  <Card v-if="$can('BLM_ER')" title="Лог изменений" class="mb-4" dense>
    <ChangesList :fetch-function="fetchEventReport" />
  </Card>
</template>

<script>
import blameApi from '@/api/services/blame';

import Card from '@/components/ui/Card';
import ChangesList from '@/components/ChangesList';

export default {
  components: {
    Card,
    ChangesList,
  },
  props: {
    orgId: { type: Number, required: true },
  },
  methods: {
    fetchEventReport({ page, limit }) {
      const id = this.orgId;
      return blameApi.getEntityReport({
        page,
        limit,
        entity: 'ORGANIZATION',
        id,
      });
    },
  },
};
</script>
