<template>
  <div class="employee-documents__container">
    <v-tabs v-if="showTabs" v-model="tab" height="30">
      <v-tab>Входящие</v-tab>
      <v-tab>Исходящие</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="incoming">
        <v-list
          v-if="incoming && incoming.length"
          class="overflow-x-auto"
          :height="height"
        >
          <EmployeeDocumentItem
            v-for="document in incoming"
            :key="document.id"
            :value="document"
            :employee="employee"
          />
        </v-list>
        <div v-else class="text-center py-3">
          Нет действующих подтвержденных справок
        </div>
      </v-tab-item>

      <v-tab-item value="outgoing">
        <v-list
          v-if="outgoing && outgoing.length"
          class="overflow-x-auto"
          :height="height"
        >
          <EmployeeDocumentItem
            v-for="document in outgoing"
            :key="document.id"
            :value="document"
            outgoing
            :employee="employee"
          />
        </v-list>
        <NoDataAlert v-else class="ma-4">Нет исходящих документов</NoDataAlert>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import EmployeeDocumentItem from './EmployeeDocumentItem.vue';
import NoDataAlert from '@/components/ui/NoDataAlert';

export default {
  components: {
    EmployeeDocumentItem,
    NoDataAlert,
  },

  props: {
    incoming: { type: Array, default: null },
    outgoing: { type: Array, default: null },
    height: { type: String, default: '100%' },
    showTabs: Boolean,
    employee: { type: Object, default: () => ({}) },
  },

  data: () => ({
    tab: 'incoming',
  }),

  created() {
    if (!this.incoming) this.tab = 'outgoing';
  },
};
</script>
