<template>
  <v-layout column fill-height>
    <h4 v-if="showTitle">Комментарии:</h4>
    <Spinner v-if="$wait('accountPreviewsLoading')" title="Загрузка профилей" />
    <v-list
      v-else
      class="pa-0 flex-grow-1"
      :height="height"
      style="overflow-y: auto"
    >
      <v-layout
        v-if="!value || value.length === 0"
        class="justify-center"
        fill-height
      >
        <NoDataAlert class="align-self-center flex-fill ma-4">
          Комментарии отсутствуют
        </NoDataAlert>
      </v-layout>

      <v-list-item
        v-for="(commentInner, index) in value"
        :key="index"
        class="pa-0"
        dense
      >
        <CommentItem
          :value="commentInner"
          :account-previews="accounts"
          :current-account-id="currentAccountId"
        />
      </v-list-item>
    </v-list>

    <v-layout v-if="canAddComment" class="mt-3 ma-2">
      <v-text-field
        v-model="text"
        autocomplete="off"
        outlined
        dense
        auto-grow="auto-grow"
        rows="1"
        label="Добавить комментарий"
        hide-details="auto"
        :error-messages="errorMessages || getValidationErrors('text')"
        :disabled="$wait('send')"
        height="100%"
        @keyup.enter.prevent="handleSendComment"
      />
      <v-btn
        v-if="onSendComment"
        class="ml-3"
        :disabled="$wait('send') || !text.length"
        outlined
        color="primary"
        height="40px"
        @click="handleSendComment"
      >
        <v-icon>mdi-send</v-icon>
      </v-btn>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex';
import { maxLength, required } from 'vuelidate/lib/validators';
import { shortName } from '@/utils/convert';
import validationMixin from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import Spinner from '@/components/Spinner';
import CommentItem from './CommentItem';
import NoDataAlert from '@/components/ui/NoDataAlert';

export default {
  components: { CommentItem, Spinner, NoDataAlert },
  mixins: [validationMixin, waitable],
  props: {
    value: { type: Array, default: null },
    showTitle: { type: Boolean, default: true },
    errorMessages: { type: Array, default: null },
    comment: { type: String, default: null },
    canAddComment: { type: Boolean, default: false },
    currentAccountId: { type: Number, default: null },
    onSendComment: { type: Function, default: null },
    height: { type: String, default: '300px' },
  },

  validations() {
    return {
      text: {
        required,
        maxSymbolsLength: maxLength(1000),
      },
    };
  },

  data: () => ({
    text: '',
    accounts: {},
  }),

  watch: {
    text(value) {
      this.$emit('update:comment', value);
    },
  },

  mounted() {
    this.clearCachedAccs();
  },

  // NOTE: for now this 'shitcode' is due to our (frontenders) completely
  // broken mood cuz of our backenders' desisions.. So.. Instead of fetching
  // those account previews in respective stores and caching 'em there we
  // fetch those in this component (which is logically wrong) and store that
  // info here locally, which means even more frequent backend structures
  // service abuse
  async created() {
    const accountIds = [
      ...new Set(
        this.value
          .filter(comment => comment.author)
          .map(comment => comment.author),
      ),
    ];

    if (accountIds.length)
      await this.$loadingNotify(
        this.fetchAccountPreviewsBy(accountIds).then(res => {
          res.forEach(account => {
            if (account?.id)
              this.accounts[account.id] = shortName(account.profile);
          });
        }),
        'accountPreviewsLoading',
        'Произошла ошибка получения информации о работниках',
        '',
      );
  },

  methods: {
    ...mapActions('AUTH_PREVIEWS', [
      'clearCachedAccs',
      'fetchAccountPreviewsBy',
    ]),

    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },

    async handleSendComment() {
      if (!this.validate()) return;
      if (!this.onSendComment) return;

      await this.$loadingNotify(
        this.onSendComment(this.text),
        'send',
        'Произошла ошибка добавления комментарий',
        'Комментарий добавлен',
      );

      this.text = '';
      this.$v.$reset();
    },
  },
};
</script>
