<template>
  <v-chip :color="color" :outlined="outlined" :text-color="textColor" small>
    {{ text }}
  </v-chip>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: null,
    },
    outlined: Boolean,
    textColor: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
  },
};
</script>
