import Vue from 'vue';
import { isXhrError, xhrErrorMessage } from '../helpers';

export default Vue.extend({
  data: () => ({
    waiters: {},
  }),

  computed: {
    $wait() {
      return name => !!this.waiters[name];
    },
  },

  methods: {
    async $loading(promise, name = 'loading') {
      this.$set(this.waiters, name, true);
      try {
        const result = await promise;
        return result;
      } finally {
        this.$set(this.waiters, name, false);
      }
    },

    // depending on @errorHandling type the logic differs
    async $loadingNotify(promise, name, errorHandling, successTitle) {
      try {
        const result = await this.$loading(promise, name);

        if (successTitle)
          this.$notify({
            group: 'note',
            type: 'info',
            title: successTitle,
          });
        return result;
      } catch (err) {
        if (!isXhrError(err)) console.error(err);
        if (errorHandling && typeof errorHandling === 'string')
          this.$notify({
            group: 'note',
            type: 'error',
            title: errorHandling,
            text: xhrErrorMessage(err),
          });
        else if (typeof errorHandling === 'function') errorHandling(err);
        // we still need to throw an error to be able to catch it outside
        // of this function and prevent modal closing and etc.
        throw err;
      }
    },
  },
});
