<template>
  <ItemPageLayout back-route="scheduler:executions" :title="name">
    <Card :title="isFinished ? 'Результат выполнения' : 'Статус'">
      <ExecutionTimeline :value="singleItem" />

      <json-viewer
        v-if="singleItem.result"
        class="mt-4"
        :value="result"
        theme="jv-theme"
        expanded
        :expand-depth="Infinity"
        sort
        :show-array-index="false"
        copyable
      />
      <template #action>
        <v-btn
          v-if="$can('SE_T') && isFinished"
          depressed
          small
          text
          color="primary"
          @click="retryExecutionModal"
        >
          Повторить
        </v-btn>
      </template>
    </Card>

    <Card title="Параметры выполнения задания">
      <json-viewer
        :value="singleItem.params"
        theme="jv-theme"
        expanded
        :expand-depth="Infinity"
        sort
        :show-array-index="false"
        copyable
      />
    </Card>

    <template #aside>
      <Card title="Задача">
        <h3>{{ singleItem.job ? singleItem.job.name : '&lt;DELETED&gt;' }}</h3>
        <template #action>
          <v-btn
            v-if="$can('SJ_RD') && singleItem.job"
            depressed
            small
            text
            color="primary"
            :to="{
              name: 'scheduler:jobs:item',
              params: { id: singleItem.job.id },
            }"
          >
            Открыть
          </v-btn>
        </template>
      </Card>
    </template>
  </ItemPageLayout>
</template>

<script>
import waitable from '@/utils/mixins/waitable';
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';

import ItemPageLayout from '@/components/layouts/ItemPageLayout';
import Card from '@/components/ui/Card';
import ExecutionTimeline from '../components/ExecutionTimeline.vue';
import JsonViewer from '@/plugins/json-viewer';

export default {
  components: {
    ItemPageLayout,
    Card,
    ExecutionTimeline,
    JsonViewer,
  },
  mixins: [waitable, routeGuardMixin],

  computed: {
    name() {
      return '#' + this.singleItem.id;
    },
    isFinished() {
      return (
        this.singleItem.status !== 'WAITING' &&
        this.singleItem.status !== 'IN_PROGRESS'
      );
    },
    result() {
      try {
        return JSON.parse(this.singleItem.result);
      } catch (err) {
        return {};
      }
    },
  },
  methods: {
    retryExecutionModal() {
      this.$openModal('prompt', {
        yes: 'Повторить',
        no: 'Отмена',
        title: 'Повторить выполнение?',
        onSubmit: () =>
          this.$store.dispatch('SCHEDULER/EXECUTION_ITEM/retry').then(res => {
            this.$router.push({
              name: 'scheduler:executions:item',
              params: { id: res.id },
            });
          }),
      });
    },
  },
};
</script>
