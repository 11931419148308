<template>
  <v-bottom-sheet
    :value="value"
    persistent
    no-click-animation
    hide-overlay
    :retain-focus="false"
    @input="handleClose"
  >
    <v-sheet style="background: rgba(0, 0, 0, 0.8)" class="rounded-t pa-3" dark>
      <v-layout style="gap: 16px">
        <div>
          <v-icon>fas fa-info-circle</v-icon>
        </div>
        <div style="width: 100%">
          <div>Вы используете устаревший браузер</div>
          <div class="text-body-2" style="opacity: 0.7">
            Стабильная работа АРМ не гарантируется в вашем браузере. <br />
            Чтобы использовать все возможности сайта, установите обновления для
            своего браузера или
            <a
              href="https://google.ru/chrome/"
              style="color: inherit"
              v-text="'скачайте современный браузер'"
            />.
          </div>
        </div>
        <div>
          <v-btn icon @click="handleClose">
            <v-icon>fa-times</v-icon>
          </v-btn>
        </div>
      </v-layout>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { isUnsupportedBrowser } from '@/plugins/browser';
import { WebStorage } from '@/plugins/webStorage';
import { IGNORE_BROWSER_VERSION } from '@/utils/constants';
import { mapGetters } from 'vuex';
import Sentry from '@/plugins/sentry';
import env from '@/plugins/env';

export default {
  data: () => ({
    value: false,
  }),

  computed: {
    ...mapGetters('AUTH', ['isAuthorized']),
  },

  watch: {
    isAuthorized(val) {
      if (val && isUnsupportedBrowser) {
        try {
          Sentry.captureMessage('У пользователя старый браузер');
        } catch (e) {}
      }
    },
  },

  mounted() {
    const ignoreRaw = WebStorage.getItem(IGNORE_BROWSER_VERSION);

    if (ignoreRaw) {
      let ignoreData = null;
      try {
        ignoreData = JSON.parse(ignoreRaw);
      } catch (e) {
        console.error(e);
      }

      // check support browser if released new version app
      if (ignoreData?.versionApp !== env.get('VUE_APP_VERSION')) {
        WebStorage.removeItem(IGNORE_BROWSER_VERSION);
        this.value = isUnsupportedBrowser;
      }
    } else {
      // check only if there is no ignoreData
      this.value = isUnsupportedBrowser;
    }
  },

  methods: {
    handleClose() {
      this.value = false;

      WebStorage.setItem(
        IGNORE_BROWSER_VERSION,
        JSON.stringify({
          at: new Date().toISOString(),
          versionApp: env.get('VUE_APP_VERSION'),
        }),
      );
    },
  },
};
</script>
