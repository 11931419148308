var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-5"},[_c('v-card',{staticClass:"ma-0",attrs:{"tile":""}},[_c('v-layout',{staticClass:"pa-5 inspection-header",attrs:{"wrap":""}},[_c('TittledText',{staticClass:"inspection-header__first pr-5",attrs:{"subtitle":_vm.type && _vm.type.name},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"4px"}},[_c('div',[_vm._v("#"+_vm._s(_vm.id))]),(_vm.isRetry)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":"error","label":"","small":""}},'v-chip',attrs,false),on),[_vm._v(" Повторный ")])]}}],null,false,627470821)},[_c('span',[_vm._v(" Работник уже проходил осмотр этого же типа"),_c('br'),_vm._v(" в течении последних трех часов ")])]):_vm._e(),(_vm.showAttemptLabel)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":"warning","text-color":"black","label":"","small":""}},'v-chip',attrs,false),on),[_vm._v(" Назначен "+_vm._s(_vm.attemptText)+" ")])]}}],null,false,2825892846)},[_c('span',[_vm._v(" Осмотр был назначен медработникам "+_vm._s(_vm.attemptText)+", включая этот ")])]):_vm._e()],1)]},proxy:true}])},[_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(_vm._s(_vm.finishedDate))])]}}])},[_c('span',[_vm._v("Время завершения осмотра на ПАК")])])],1),_c('div',[_vm._v("Длительность "+_vm._s(_vm.duration))]),_c('div',[_vm._v("Точка: "+_vm._s(_vm.pointText))])]),_c('TittledText',{staticClass:"inspection-header__second",attrs:{"subtitle":("Возраст: " + _vm.employeeAge + " (" + _vm.dateOfBirth + ")")},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('EmployeeName',{attrs:{"value":_vm.employee}})]},proxy:true}])},[_c('div',[_vm._v("Пол: "+_vm._s(_vm.gender))]),_c('div',[_vm._v("Табельный №: "+_vm._s(_vm.employee.personnelNumber))]),_c('div',[_vm._v("Организация: "+_vm._s(_vm.organization.name))])]),(_vm.$can('GRT'))?_c('v-spacer'):_vm._e(),(_vm.$can('GRT'))?_c('div',[_c('v-card',{staticClass:"pa-3 mt-2 text-center align-center",style:(_vm.getRatingColor()),attrs:{"min-width":"150px","height":"80%"}},[_c('div',{staticClass:"text-caption"},[_vm._v("Рейтинг")]),_c('div',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.rating || '-')+" ")])])],1):_vm._e(),_c('v-spacer'),_c('div',{staticClass:"d-flex align-start"},[_c('Scenario',{attrs:{"with-icons":"","steps":_vm.steps,"labels":_vm.labels}})],1)],1)],1),_c('AssistantRecommendation',{staticClass:"mx-5 px-3 py-1",attrs:{"assistance":_vm.assistance,"steps":_vm.steps,"type":_vm.type,"hide-button":!_vm.isInspecting,"assistant-disabled":_vm.assistantDisabled}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }