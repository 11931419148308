<template>
  <List
    :available-actions="{
      exportAction: $can('EXP_IL'),
      goByIdAction: $can('I_RD'),
      showAction: false,
    }"
    :export-limit="exportLimit"
    @item:goById="goByIdModal"
    @filters:resetHook="resetHook"
  >
    <template #filters="query">
      <v-col cols="12" sm="4" md="3">
        <SearchEmployee
          ref="empSearch"
          v-model="query.employeeIds"
          store="INSPECTION_LIST"
          :org-ids="query.orgIds"
          :group-ids="query.employeeGroupIds"
          :org-groups-ids="query.orgGroupIds"
          :disabled="loading"
          :loading="loading"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <DateRangePicker
          label="Диапазон осмотров"
          show-quick-options
          with-time
          outlined
          dense
          clearable
          :placeholder="datesPlaceholder"
          :value="dates"
          :timezone="query.timezone"
          :disabled="loading"
          @input="setDateFilters"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.timezone"
          label="Часовой пояс диапазона"
          :items="ruTimezones"
          :disabled="!(dateStart && dateEnd) || loading"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <OrganizationGroupSelect
          v-model="query.orgGroupIds"
          multiple
          dense
          :disabled="loading"
          @input="query.orgIds = query.pointIds = query.employeeGroupIds = null"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <OrganizationSelect
          v-model="query.orgIds"
          multiple
          dense
          :group-ids="query.orgGroupIds"
          :hint="organizationSelectHint"
          :disabled="loading"
          @input="query.pointIds = query.employeeGroupIds = null"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <EmployeeGroupSelect
          v-model="query.employeeGroupIds"
          :org-ids="query.orgIds"
          multiple
          dense
          :disabled="loading || !query.orgIds || !query.orgIds.length"
          :hint="employeeGroupSelectHint"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <PointSelect
          v-model="query.pointIds"
          multiple
          dense
          include-guests
          :org-group-ids="!query.orgIds ? query.orgGroupIds : null"
          :org-ids="query.orgIds"
          :hint="pointSelectHint"
          :disabled="loading"
        />
      </v-col>
      <v-col cols="12" md="3" sm="4">
        <MaskField
          v-model="licenseSearch"
          :mask="maskInputHostLicense"
          placeholder="A270920181900-XXXXX"
          label="Лицензия ПАК"
          dense
          :clearable="licenseSearch != 'A270920181900-_____'"
          outlined
          :disabled="loading"
          hide-details="auto"
          first-format
          @click:clear="licenseSearch = 'A270920181900-_____'"
        />
      </v-col>

      <v-col cols="12" sm="4" md="3">
        <Select
          v-model="query.types"
          label="Тип осмотров"
          dense
          multiple
          :items="inspectionTypes"
          item-text="name"
          item-value="key"
          :disabled="loading"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <SelectRemarks
          v-model="query.remarks"
          label="Причина недопуска"
          dense
          multiple
          :items="inspectionRemarks"
          :disabled="loading"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.success"
          label="Пройденные"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :items="resultStatuses"
          :menu-props="{ offsetY: true }"
          :disabled="loading"
        />
      </v-col>
      <v-col v-if="!excludeImportedInspections" cols="12" sm="4" md="3">
        <v-select
          v-model="query.isImported"
          label="Импортированные"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :items="importedStatuses"
          :menu-props="{ offsetY: true }"
          :disabled="loading"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.isTest"
          label="Тестовые"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :items="isTestList"
          :menu-props="{ offsetY: true }"
          :disabled="loading"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.isTechError"
          label="С тех. ошибками"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :items="isTechErrorList"
          :menu-props="{ offsetY: true }"
          :disabled="loading"
        />
      </v-col>
      <v-col v-if="$can('I_RIA')" cols="12" sm="4" md="3">
        <v-select
          v-model="query.isInspected"
          label="Осмотр проверен"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :items="isInspectedList"
          :menu-props="{ offsetY: true }"
          :disabled="loading"
        />
      </v-col>
    </template>

    <template #table.item="{ item }">
      <InspectionItem
        :value="item"
        :remarks="inspectionRemarks"
        :remark-types="inspectionRemarkTypes"
        :timezone="listQuery.timezone"
      />
    </template>
  </List>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import { maskInputHostLicense } from '@/utils/masks';
import MaskField from '@/components/controls/MaskField';
import env from '@/plugins/env';

import ListFactory from '@/components/crud/ListFactory';
import InspectionItem from '@/modules/inspections/components/InspectionItem';
// Filters components
import DateRangePicker from '@/components/controls/DateRangePicker.vue';
import SearchEmployee from '@/modules/inspections/components/controls/SearchEmployee.vue';
import SelectRemarks from '@/modules/inspections/components/controls/SelectRemarks.vue';
import Select from '@/components/controls/Select';
import EmployeeGroupSelect from '@/components/controls/structures/EmployeeGroupSelect';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect';
import OrganizationGroupSelect from '@/components/controls/structures/OrganizationGroupSelect';
import PointSelect from '@/components/controls/structures/PointSelect';
import { RU_TIMEZONES } from '@/utils/constants';

import { entity, messages, filters } from '../entity';

const defaultFilterValues = [
  { name: 'Неважно', value: null },
  { name: 'Да', value: true },
  { name: 'Нет', value: false },
];

const List = ListFactory({
  storeModule: 'INSPECTION_LIST',
  messages,
  entity,
  filters,
});

export default {
  components: {
    List,
    SearchEmployee,
    DateRangePicker,
    Select,
    MaskField,
    InspectionItem,
    SelectRemarks,
    EmployeeGroupSelect,
    OrganizationSelect,
    OrganizationGroupSelect,
    PointSelect,
  },

  mixins: [waitable],

  data() {
    return {
      licenseSearch: 'A270920181900-_____',
    };
  },

  computed: {
    ...mapGetters('REGISTRY', [
      'inspectionTypes',
      'inspectionRemarks',
      'inspectionRemarkTypes',
    ]),
    ...mapGetters('INSPECTION_LIST', [
      'listQuery',
      'dateStart',
      'dateEnd',
      'exportLimit',
      'datePlaceholder',
      'listIsLoading',
    ]),

    resultStatuses: () => defaultFilterValues,
    importedStatuses: () => defaultFilterValues,
    isTechErrorList: () => defaultFilterValues,
    isTestList: () => defaultFilterValues,
    isInspectedList: () => defaultFilterValues,
    maskInputHostLicense: () => maskInputHostLicense,

    loading() {
      return (
        this.listIsLoading ||
        this.$wait('fetchingInspectionTypes') ||
        this.$wait('fetchingInspectionRemarks')
      );
    },

    dates() {
      return {
        dateStart: this.dateStart || null,
        dateEnd: this.dateEnd || null,
      };
    },

    datesPlaceholder() {
      if (!this.datePlaceholder) return null;
      return {
        dateStart: this.datePlaceholder.dateStart,
        dateEnd: this.datePlaceholder.dateEnd,
      };
    },

    ruTimezones() {
      return RU_TIMEZONES;
    },

    organizationSelectHint() {
      return this.listQuery?.orgGroupIds?.length
        ? 'Отфильтровано по группам'
        : null;
    },
    pointSelectHint() {
      if (this.listQuery?.orgIds?.length)
        return 'Отфильтровано по организациям';
      else if (this.listQuery?.orgGroupIds?.length)
        return 'Отфильтровано по группам';

      return null;
    },

    employeeGroupSelectHint() {
      if (this.listQuery?.orgIds?.length)
        return 'Отфильтровано по организациям';
      return 'Выберите организацию';
    },

    excludeImportedInspections() {
      return env.get('VUE_APP_EXCLUDE_IMPORTED_INSPECTIONS');
    },
  },

  watch: {
    licenseSearch(val, oldVal) {
      // set filter only if field is filled
      if (val && val.replace(/[_]/g, '').length === 19)
        val !== oldVal && this.setLicenseFilter(val);
      // if field has only mask value - commit 'null' to the filter
      if (val === 'A270920181900-_____') this.setLicenseFilter(null);
    },
    'listQuery.licenses': {
      handler(val) {
        // on filters reset we need to reset the components own license as well
        !val && (this.licenseSearch = 'A270920181900-_____');
      },
    },
  },

  async created() {
    this.$loadingNotify(
      this.$store.dispatch('REGISTRY/fetchInspectionTypes'),
      'fetchingInspectionTypes',
      'Произошла ошибка загрузки типов осмотров',
    );
    this.$loadingNotify(
      this.$store.dispatch('REGISTRY/fetchInspectionRemarks'),
      'fetchingInspectionRemarks',
      'Произошла ошибка загрузки меток',
    );

    this.$store.dispatch('INSPECTION_LIST/fetchExportLimit');

    // since licenses filter is a crutch on this page - manually set it in
    // component locally and in store
    if (this.$route.query.licenses) {
      this.licenseSearch = this.$route.query.licenses;
      this.setLicenseFilter(this.$route.query.licenses);
    }
  },

  methods: {
    ...mapActions('INSPECTION_LIST', [
      'setDateFilters',
      'resetListFilters',
      'setLicenseFilter',
    ]),

    goById({ id, isImported }) {
      const payload = isImported
        ? { name: 'inspections:card:archived', params: { id } }
        : { name: 'inspections:card', params: { id } };

      return this.$router.push(payload);
    },

    resetHook() {
      this.$refs.empSearch.clear();
    },

    goByIdModal() {
      this.$openModal('inspections/goById');
    },
  },
};
</script>
