<template>
  <!--  Без доступа-->
  <div v-if="noAccessText" class="px-4 pb-4 pt-2">
    <NoDataAlert>{{ noAccessText }}</NoDataAlert>
  </div>
  <!--  Проведение контроля-->
  <div
    v-else-if="!value.isInspected"
    class="px-4 pb-4 pt-2 d-flex"
    style="gap: 8px"
  >
    <v-btn
      class="flex-shrink-1"
      style="flex-basis: 100%"
      color="success"
      outlined
      :loading="$wait('setApproval')"
      @click="apply(true)"
    >
      Допустить
    </v-btn>
    <v-btn
      class="flex-shrink-1"
      style="flex-basis: 100%"
      color="error"
      outlined
      :loading="$wait('setApproval')"
      @click="apply(false)"
    >
      Отстранить
    </v-btn>
  </div>
  <!--  Чтение результатов контроля-->
  <DefinitionList v-else dense :value="tableData" />
</template>

<script>
import waitable from '@/utils/mixins/waitable';
import { mapActions } from 'vuex';
import NoDataAlert from '@/components/ui/NoDataAlert';
import DefinitionList from '@/components/DefinitionList';
import LinkButton from '@/components/controls/buttons/LinkButton';
import ColoredText from '@/modules/documents/incoming/components/ColoredText';
import { dateTime } from '@/utils/convert';

export default {
  name: 'Approval',
  components: { NoDataAlert, DefinitionList },
  mixins: [waitable],

  props: {
    value: { type: Object, default: () => ({}) },
    inspectionId: { type: Number, required: true },
  },

  computed: {
    noAccessText() {
      // Проведение контроля запрещено и контроль не проведен
      if (!this.$can('I_DIA') && !this.value.isInspected) {
        return 'Не проверено';
      }
      // Чтение запрещено и контроль проведен
      if (!this.$can('I_RIA') && this.value.isInspected) {
        return 'Проверено';
      }
      return null;
    },

    tableData() {
      const approver = this.value.approver || {};
      const result = [
        {
          label: 'Контролёр',
          component: LinkButton,
          props: {
            dense: true,
            text: approver.name || `#${approver.id}` || '-',
            action: this.$can('ACC_R'),
            blank: true,
            to: {
              name: 'admin:users:item',
              params: { id: approver.id },
            },
          },
        },
        {
          label: 'Результат проверки',
          component: ColoredText,
          props: {
            text: this.value.isApproved ? 'Допущен' : 'Отстранен',
            color: this.value.isApproved ? 'green darken-1' : 'red darken-2',
          },
        },
        {
          label: 'Дата проверки',
          value: dateTime(this.value.approvedAt) || '-',
        },
      ];
      if (this.value.comment) {
        result.push({
          label: 'Комментарий',
          value: this.value.comment,
        });
      }
      return result;
    },
  },

  methods: {
    ...mapActions('INSPECTION', ['setApproval', 'singleItemUpdate']),

    async setApprovalHandle(params) {
      await this.$loadingNotify(
        this.setApproval({ inspectionId: this.inspectionId, params }).catch(
          data => {
            // Если кто-то успел проинспектировать раньше, обновляем данные
            if (data?.response.data.code === 400) {
              this.singleItemUpdate(this.inspectionId);
            }
            throw new Error(data?.response.data.message || data);
          },
        ),
        'setApproval',
        'Ошибка отправки данных',
        `Осмотр #${this.inspectionId} успешно проинспектирован`,
      );
    },

    apply(approve) {
      this.$openModal('inspections/approvalComment', {
        onSubmit: comment => {
          this.setApprovalHandle({ approve, comment });
        },
      });
    },
  },
};
</script>
