<template>
  <PageLayout
    back-tooltip="Вернуться к списку осмотров"
    back-route="inspections:list"
    fluid
  >
    <template #title>
      <span>Карточка осмотра № {{ (item || {}).id }}</span>
      <v-chip
        v-if="item.isImported"
        class="ml-3"
        color="teal darken-4"
        outlined
      >
        Архив
      </v-chip>
    </template>
    <template v-if="loading">
      <v-col class="text-center" cols="12" sm="12">
        <Spinner />
      </v-col>
    </template>
    <template v-else>
      <v-col cols="12" sm="3">
        <v-card>
          <HistoryPhoto
            v-if="employee.photo"
            :photo="employee.photo.url"
            :date="singleItem.timestamps.finishedAt"
            :timezone-offset="singleItem.timezoneOffsetMin"
          />
          <v-card-title><span class="mr-3">Личные данные</span></v-card-title>
          <DefinitionList dense :value="employeeData" />
          <v-divider v-if="$can('I_RMI')" />
          <AverageMedData
            v-if="$can('I_RMI')"
            :boundaries="singleItem.boundaries"
            :average="measurementsHistory.average"
          />
        </v-card>
      </v-col>
      <v-col cols="12" sm="5">
        <v-card class="d-flex">
          <Plyr v-if="item.video" :url="item.video" autoplay download />
          <v-card-text
            v-else
            class="d-flex align-center justify-center"
            style="height: 200px"
          >
            <h3>Видео не найдено</h3>
          </v-card-text>
        </v-card>
        <v-layout class="mt-3" style="height: 100px">
          <BigBtn
            v-if="$can('EMP_RD')"
            icon="fa-eye"
            text="Карточка работника"
            :to="{ name: 'employees:card', params: { id: employee.id } }"
            :disabled="!$can('EMP_RD')"
          />
          <BigBtn
            v-if="$can('SKR_RE')"
            class="ml-5"
            icon="fa-print"
            text="Печать стикера"
            @click="openModalPrintSticker"
          />
          <BigBtn
            v-if="$can('I_R')"
            class="ml-5"
            icon="fa-history"
            text="История осмотров"
            @click="showInspectionsHistoryModal"
          />
        </v-layout>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card v-if="$can('I_RMI')" class="mb-4">
          <v-card-title>
            <span class="mr-3">Медицинские показатели</span>
          </v-card-title>
          <v-card-text>
            <Scenario
              dense
              :steps="item.steps"
              :remarks="item.resolution.remarks"
            />
          </v-card-text>
        </v-card>
        <v-card class="mb-4">
          <v-card-title>
            <span class="mr-3">Подробности осмотра</span>
          </v-card-title>
          <InspectionDetails
            :inspection="singleItem"
            :remarks="inspectionRemarks"
            :remark-types="inspectionRemarkTypes"
          />
        </v-card>
        <v-card v-if="$can('ODC_R')" class="mb-4" style="overflow: hidden">
          <AsyncWrapper :handler="fetchOutgoingDocs">
            <AverageMedData
              :outgoing="outgoingDocs"
              :average="measurementsHistory.average"
              doc-title="Сформированные документы"
            />
          </AsyncWrapper>
        </v-card>
      </v-col>
    </template>
  </PageLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import waitable from '@/utils/mixins/waitable';
import { convert } from '@/utils/entities';
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';

import { entity as entityEmployee } from '@/modules/employees/entity';

import InspectionDetails from '@/modules/inspections/components/InspectionDetails';
import Scenario from '@/modules/medcab/components/Scenario';
import AverageMedData from '@/components/AverageMedData.vue';
import PageLayout from '@/components/layouts/PageLayout';
import DefinitionList from '@/components/DefinitionList';
import Spinner from '@/components/Spinner';
import BigBtn from '@/components/controls/buttons/BigBtn';
import Plyr from '@/components/Plyr';
import HistoryPhoto from '@/components/HistoryPhoto';
import AsyncWrapper from '@/components/AsyncWrapper';
import LinkButton from '@/components/controls/buttons/LinkButton';
import LinkButtonList from '@/components/controls/buttons/LinkButtonList';

export default {
  components: {
    PageLayout,
    DefinitionList,
    Spinner,
    AverageMedData,
    BigBtn,
    HistoryPhoto,
    Plyr,
    InspectionDetails,
    Scenario,
    AsyncWrapper,
  },

  mixins: [waitable, routeGuardMixin],

  computed: {
    ...mapGetters('REGISTRY', ['inspectionRemarks', 'inspectionRemarkTypes']),

    ...mapGetters('INSPECTION_ARCHIVED', [
      'employee',
      'medRecord',
      'measurementsHistory',
      'outgoingDocs',
    ]),

    employeeData() {
      if (!this.employee) return [];

      const emp = convert(entityEmployee, this.employee);
      const empOriginal = this.employee;

      const groupButtons = {
        label: 'Группы',
        component: LinkButtonList,
        props: {
          list: empOriginal.groups.map(el => ({
            text: el.name || `#${el.id}`,
            action: this.$can('EMG_RD'),
            to: {
              name: 'structures:employee_groups:item',
              params: { id: el.id },
            },
          })),
        },
      };

      return [
        { label: 'ФИО', value: emp.name },
        {
          label: 'ID',
          component: LinkButton,
          props: {
            text: String(emp.id) || `#${emp.id}`,
            action: this.$can('EMP_RD'),
            to: { name: 'employees:card', params: { id: emp.id } },
          },
        },
        { label: 'Дата рождения', value: emp.dateOfBirth },
        { label: 'Возраст', value: emp.age },
        { label: 'Пол', value: emp.gender },
        { label: 'Табельный №', value: emp.personnelNumber },
        { label: 'Телефон', value: emp.phone },
        {
          label: 'Организация',
          component: LinkButton,
          props: {
            text:
              empOriginal.organization.name ||
              `#${empOriginal.organization.id}`,
            action: this.$can('ORG_RD'),
            to: {
              name: 'structures:organizations:item',
              params: { id: empOriginal.organization.id },
            },
          },
        },
        ...((empOriginal.groups.length && [groupButtons]) || []),
      ].filter(_ => _);
    },

    loading() {
      return (
        this.$wait('fetchingInspectionRemarks') ||
        this.$wait('fetchingRecord') ||
        this.$wait('fetchingHistory')
      );
    },
  },

  watch: {
    employee: {
      immediate: true,
      handler(val) {
        this.$nextTick(() => val && this.fetchEmployeeExtendedInformation());
      },
    },
  },

  async created() {
    await this.$loadingNotify(
      this.$store.dispatch('REGISTRY/fetchInspectionRemarks'),
      'fetchingInspectionRemarks',
      'Произошла ошибка загрузки меток',
    );
  },

  methods: {
    ...mapActions('INSPECTION_ARCHIVED', ['fetchOutgoingDocs']),

    openModalPrintSticker() {
      const props = {
        inspectionId: this.item.id,
        organizationId: this.item?.organization.id,
        hostId: this.item?.host.id,
      };
      this.$openModal('inspections/printSticker', props);
    },

    showInspectionsHistoryModal() {
      this.$openModal('inspections/history', {
        employee: this.singleItem.employee,
      });
    },

    fetchEmployeeExtendedInformation() {
      if (!this.employee) return;

      this.$loadingNotify(
        this.$store.dispatch(
          'INSPECTION_ARCHIVED/getMedRecord',
          this.employee.id,
        ),
        'fetchingRecord',
        'Произошла ошибка загрузки медицинских записей',
      );
      this.$loadingNotify(
        this.$store.dispatch(
          'INSPECTION_ARCHIVED/getEmployeeAverages',
          this.employee.id,
        ),
        'fetchingHistory',
        'Произошла ошибка загрузки средних показателей',
      );
    },
  },
};
</script>
