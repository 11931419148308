import { TOKEN_TYPES } from '@/utils/constants';
import { Token } from './Token';
import { debug } from '@/utils/helpers';
import signApi from '@/api/services/sign';

const signerLog = text => debug('SIGNER', '#4CAF50')(`[SignMe] ${text}`);

export class SignMeToken extends Token {
  constructor(certificate) {
    super({ type: TOKEN_TYPES['SIGNME'], certificate });
    this.computedFields();
  }

  async computedFields() {
    const cert = this.certificate;
    const item = {
      type: this.type,
      matchBy: cert.matchBy,
      validFrom: cert.issuedAt,
      validTo: cert.expiresAt,
      serial: cert.details.serial,
      externalId: cert.details.externalId || null,
      ownerData: {
        company: null,
        position: null,
        person: {
          surname: cert.details.owner.surname || null,
          name: cert.details.owner.name || null,
          patronymic: cert.details.owner.patronymic || null,
        },
      },
      isValid: true, // FIXME: Check valid certificate on server
      issuer: cert.details.issuer,
    };

    const freezeItem = Object.freeze(item);
    this.data = freezeItem;
    return freezeItem;
  }

  async initialize() {
    const params = {
      type: this.type,
      matchBy: this.data.matchBy,
      issuedAt: this.data.validFrom,
      expiresAt: this.data.validTo,
      owner: this.data.ownerData.person,
      serial: this.data.serial,
      externalId: this.data.externalId,
      issuer: this.data.issuer,
    };

    const cert = await signApi.initialize(this.accountCertificate.id, params);

    this.setAccountCertData(cert);
    return cert;
  }

  /**
   * @public
   */
  async checkIsValid() {
    return { type: 'success', value: true };
  }

  /**
   * @public
   */
  getSerial() {
    return this.data.serial;
  }

  /**
   * @public
   */
  async signTasks({ force = false }) {
    await signApi.triggerTasks({
      certificateId: this.accountCertificate.id,
      ...(force && { force: true }),
    });

    signerLog('Documents have been sent for signing');
  }
}
