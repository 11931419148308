<script>
import { VAutocomplete } from 'vuetify/lib';

export default {
  functional: true,
  render(createElement, context) {
    let defaultProps = {
      itemValue: 'id',
      itemText: 'name',
      outlined: true,
    };

    if (context.props.multiple || context.props.multiple === '') {
      defaultProps = {
        ...defaultProps,
        multiple: true,
        chips: true,
        deletableChips: true,
        smallChips: true,
      };
    }

    if (context.props.dense || context.props.dense === '') {
      defaultProps = {
        ...defaultProps,
        dense: true,
        hideDetails: 'auto',
      };
    }

    const defaultAttrs = {
      spellcheck: false,
    };

    return createElement(VAutocomplete, {
      ...context.data,
      attrs: defaultAttrs,
      class: [
        'autocomplete--customized',
        defaultProps.deletableChips && 'autocomplete--deletable-chips',
      ]
        .filter(a => a)
        .join(' '),
      props: { ...defaultProps, ...context.props },
    });
  },
};
</script>

<style lang="scss" scoped>
.autocomplete--customized.v-select::v-deep {
  & .v-chip {
    margin: 4px 4px 0 4px;

    & .v-chip__content {
      display: block;
      height: 100%;
      line-height: 2;
      max-width: 212px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  & .v-select__selections input {
    padding: 4px 0;
  }
}

.autocomplete--customized.autocomplete--deletable-chips.v-select::v-deep {
  & .v-chip.v-size--small .v-icon {
    font-size: 18px;
    height: 16px;
    width: 16px;
    position: absolute;
    right: 9px;
    z-index: 1;
    margin-top: 4px;
  }

  & .v-chip .v-chip__content {
    padding-right: 12px;
  }
}
</style>
