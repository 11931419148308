export const DOCUMENT_STATUSES = [
  {
    title: 'Ожидает подтверждения',
    value: 'PENDING',
    icon: 'mdi-account-clock',
    color: 'orange darken-1',
  },
  {
    title: 'Подтверждён',
    value: 'VALID',
    icon: 'mdi-text-box-check',
    color: 'green darken-1',
  },
  {
    title: 'Отклонён',
    value: 'DECLINED',
    icon: 'mdi-text-box-remove',
    color: 'red darken-2',
  },
  {
    title: 'Истёк',
    value: 'OUTDATED',
    icon: 'mdi-clipboard-text-clock',
    color: 'blue-grey darken-2',
  },
  {
    title: 'Архивирован',
    value: 'ARCHIVED',
    icon: 'mdi-archive',
    color: 'grey darken-1',
  },
  {
    title: 'Использован',
    value: 'USED',
    icon: 'mdi-book-check',
    color: 'grey darken-1',
  },
];
