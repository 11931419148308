import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import MEDCAB from './store/medcab';
import MEDCAB_INSPECTION from './store/inspection';
import MEDCAB_PASSIVE from './store/passive';

export default {
  store: {
    MEDCAB,
    MEDCAB_INSPECTION,
    MEDCAB_PASSIVE,
  },

  routes: [
    {
      path: '/medcab',
      name: 'medcab',
      component: () => import('@/modules/medcab/views/index.vue'),
      meta: {
        showFooter: false,
        action: 'IPS_T',
      },
    },
    {
      path: '/medcab_passive',
      name: 'medcab:passive',
      component: () => import('@/modules/medcab/views/passive.vue'),
      meta: {
        showFooter: false,
        action: 'OINS_R',
      },
    },
  ],

  menu: [
    {
      title: 'Медкаб',
      action: 'IPS_T',
      name: 'medcab',
    },
    {
      title: 'Медкаб',
      action: 'OINS_R',
      name: 'medcab:passive',
    },
  ],

  modals: [
    {
      name: 'medcab/resolve',
      component: () => import('@/modules/medcab/modals/Resolve.vue'),
    },
    {
      name: 'medcab/prompt',
      component: () => import('@/modules/medcab/modals/Prompt.vue'),
    },
    {
      name: 'medcab/closeShift',
      component: () => import('@/modules/medcab/modals/StopShift.vue'),
    },
  ],
} as ModuleDefinition;
