import { RouteConfig } from 'vue-router';
import store from '@/store';
import router from '@/router';
import env from '@/plugins/env';
import { debug } from '@/utils/helpers';
import { NAME_ROOT_ROUTE } from '@/utils/constants';
import { submenuForRoute } from '@/utils/routing.ts';
import { MenuItem } from '@/models/app/MenuItem';
import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import { ModuleSystem } from '@/models/app/ModuleSystem';
import { registerModal } from './modal';

const modulesLog = debug('MODULES', '#8e44ad');

const excludedModules =
  String(env.get('VUE_APP_EXCLUDED_MODULES'))?.split(',') || [];

const modulesPaths = require.context(
  '@/modules/',
  true,
  /^\.\/[A-Za-z-]*\/([A-Za-z-]*\/){0,2}index\.([jt])s$/,
);

const modules: ModuleSystem[] = modulesPaths
  .keys()
  .map(item => {
    const modulePath = item.slice(2).split('/');
    const nestingIndex =
      modulePath.findIndex(item => item.startsWith('index.')) - 1;
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const module = require('@/modules/' + item.slice(2));

    return {
      path: '@/modules/' + item,
      name: modulePath.slice(0, -1).join('/'),
      nestingIndex,
      namespace: modulePath.slice(0, nestingIndex).join(':') || null,
      module: normalizeModule(module.default),
    };
  })
  .filter((module, index, modules) => {
    if (excludedModules.includes(module.name)) {
      modulesLog('Module with name ' + module.name + ' is excluded!');
      return false;
    }

    if (module.nestingIndex) {
      const rootModule = modules.find(
        item => item.name === module.namespace && !item.nestingIndex,
      );
      return !rootModule || rootModule.module.hasChildren;
    }

    return true;
  })
  .sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

logModuleSystem(modules);

// Подключаем модули к частям приложения
modules.forEach(item => {
  connectStore(item);
  connectModals(item);
  connectRouter(item);
  connectPages(item);
});

/**
 * Регистрируем модули в Vuex
 */
function connectStore({ namespace, module }: ModuleSystem) {
  if (module.store && module.namespaced && namespace) {
    const moduleName = namespace?.toUpperCase().replace(':', '/');
    if (!store.hasModule(moduleName))
      store.registerModule(moduleName, {
        namespaced: true,
        modules: module.store,
      });
    else
      Object.entries(module.store).forEach(([name, storeModule]) =>
        store.registerModule([moduleName, name], storeModule),
      );
  } else {
    Object.entries(module.store).forEach(([name, storeModule]) =>
      store.registerModule(name, storeModule),
    );
  }
}

/**
 * Добавить страницы из модулей в Router
 */
function connectRouter({ namespace, module }: ModuleSystem) {
  if (module.routes.length && module.namespaced && namespace) {
    if (!hasRoute(namespace))
      router.addRoute(
        NAME_ROOT_ROUTE,
        generateIndexRouteForNamespace(namespace),
      );

    module.routes.forEach(item =>
      router.addRoute(namespace, addPrefixRoute(item, namespace)),
    );
  } else {
    module.routes.forEach(item => {
      router.addRoute(NAME_ROOT_ROUTE, {
        ...item,
        // Добавляем редирект только при наличии дочерних страниц
        ...(module.hasChildren && {
          redirect: to => {
            const [firstPage] = submenuForRoute(to.matched[1]);

            if (firstPage?.name) return { name: firstPage?.name };

            // Если есть дочерние роуты, но список submenu пустой, то будем
            // считать что пользователю недоступна страница на которую
            // он делает переход
            return { name: 'forbidden', query: { to: to.fullPath } };
          },
        }),
      });
    });
  }
}

/**
 * Подключить модальные окна из модуля в проект
 */
function connectModals({ namespace, module }: ModuleSystem) {
  if (module.modals.length && module.namespaced && namespace) {
    module.modals.forEach(item =>
      registerModal({
        ...item,
        name: `${namespace.replace(':', '/')}/${item.name}`,
      }),
    );
  } else {
    module.modals.forEach(item => registerModal(item));
  }
}

/**
 * Подключить страницы к меню приложения
 */
function connectPages({ namespace, module }: ModuleSystem) {
  if (module.menu.length && module.namespaced && namespace) {
    const items = addPrefixMenu(module.menu, namespace);
    store.commit('addMenuItems', { items, parent: namespace });
  } else {
    store.commit('addMenuItems', { items: module.menu });
  }
}

/**
 * Привести к нормальному виду объект модуля
 */
function normalizeModule(rawObject: any): Required<ModuleDefinition> {
  if (!rawObject) rawObject = {};

  return {
    namespaced: Boolean(rawObject.namespaced),
    hasChildren: Boolean(rawObject.hasChildren),

    store: rawObject.store || {},
    routes: rawObject.routes || [],
    modals: rawObject.modals || [],
    menu: rawObject.menu || [],
  };
}

// Utilities for correct register routes
function hasRoute(name: string) {
  return router.getRoutes().find(item => item.name === name);
}

function generateIndexRouteForNamespace(namespace: string): RouteConfig {
  return {
    path: '/' + namespace,
    name: namespace,
    component: () => import('@/views/Provider.vue'),
  };
}

function addPrefixRoute(route: RouteConfig, prefix: string): RouteConfig {
  return {
    ...route,
    name: prefix + ':' + route.name,
    ...(route.children && {
      children: route.children.map(item => addPrefixRoute(item, prefix)),
    }),
  };
}

function addPrefixMenu(items: MenuItem[], prefix: string): MenuItem[] {
  return items.map(item => {
    return {
      ...item,
      name: prefix + ':' + item.name,
      ...(item.children && {
        children: addPrefixMenu(item.children, prefix),
      }),
    };
  });
}

// Logging
function logModuleSystem(modules: Array<any>) {
  const dirs = modules.reduce((acc, item) => {
    if (item.namespace) {
      if (!acc[item.namespace]) acc[item.namespace] = [];
      if (item.nestingIndex) {
        acc[item.namespace].push(
          item.name.replace(new RegExp(`^${item.namespace}/`), ''),
        );
      }
    } else {
      acc[item.name] = [];
    }
    return acc;
  }, {});
  modulesLog('Active modules in build: ', dirs);
}
