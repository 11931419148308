<template>
  <List
    :available-actions="{
      showAction: $can('SE_D'),
      createAction: false,
    }"
  >
    <template #filters="query">
      <v-col cols="12" sm="4" md="2">
        <v-select
          v-model="query.status"
          label="Статус"
          :items="statusItems"
          hide-details
          outlined
          clearable
          dense
          :menu-props="{ offsetY: true }"
        />
      </v-col>
    </template>

    <template #table.item.isManual="{ item }">
      {{ item.isManual ? 'Вручную' : 'Автоматически' }}
    </template>

    <template #table.item.job="{ item }">
      <b>{{ item.job ? item.job.name : '– DELETED –' }}</b>
    </template>

    <template #table.item.status="{ item }">
      <ExecutionStatus :value="item.status" />
    </template>
  </List>
</template>

<script>
import waitable from '@/utils/mixins/waitable';
import { EXECUTIONS_STATUSES } from '../../constants';

import ListFactory from '@/components/crud/ListFactory';
import { messages, entity, filters } from '../entity';
import ExecutionStatus from '../components/ExecutionStatus.vue';

const List = ListFactory({
  storeModule: 'SCHEDULER/EXECUTION_LIST',
  messages,
  entity,
  filters,
  viewRoute: 'scheduler:executions:item',
});

export default {
  components: { List, ExecutionStatus },

  extends: waitable,
  computed: {
    statusItems() {
      return Object.entries(EXECUTIONS_STATUSES).map(([key, value]) => ({
        value: key,
        text: value.name,
      }));
    },
  },
};
</script>
