<template>
  <v-text-field
    :key="key"
    v-model.number="proxyValue"
    label="Включить тех, у кого меньше N часов отдыха"
    type="number"
    min="1"
    max="24"
    step="1"
    outlined
    hide-details="auto"
    :error-messages="errorMessages"
    @blur="onBlur"
  />
</template>

<script>
export default {
  props: {
    value: { type: Number, default: 0 },
    errorMessages: { type: Array, default: () => [] },
  },

  data: () => ({
    key: Math.random(),
  }),

  computed: {
    proxyValue: {
      get() {
        return this.value || 0;
      },
      set(val) {
        this.$emit('input', val || 0);
      },
    },
  },

  methods: {
    onBlur() {
      if (!String(this.proxyValue).length) this.proxyValue = 0;
      // Хак. Если удалить последний символ, то чтобы отобразить добавленный
      // ноль, нужно выполнить рендеринг. Для этого обновляем key.
      if (this.proxyValue === 0) this.key = Math.random();
    },
  },
};
</script>
