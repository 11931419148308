<template>
  <List
    :available-actions="{
      showAction: $can('EMP_RD'),
      createAction: $can('EMP_C'),
      importAction: $can('EMP_I'),
      exportAction: $can('EMP_E'),
    }"
    :export-limit="exportLimit"
    @item:create="formModal"
    @filters:resetHook="resetHook"
  >
    <template #header.actions>
      <ListActionBtn
        v-if="$can('EMP_CT')"
        label="Добавить тестового"
        icon="mdi-account-multiple-plus-outline"
        color="success"
        @click="createTestEmployee"
      />
    </template>
    <template #item.actions.prepend="{ item }">
      <v-tooltip v-if="$can('EMG_CP')" left>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            icon
            :disabled="item.isRestricted"
            v-on="on"
            @click="openPhoneForm(item)"
          >
            <v-icon small>fa-phone-square</v-icon>
          </v-btn>
        </template>
        <span>{{
          item.isRestricted ? 'Недоступно' : 'Изменить номер телефона'
        }}</span>
      </v-tooltip>
    </template>

    <template #import.prepend="{ data, getValidationErrors }">
      <v-col class="pb-0" cols="12">
        <OrganizationSelectImport
          v-model="data.orgId"
          :error-messages="getValidationErrors('data.orgId')"
          dense
        />
      </v-col>
    </template>

    <template #filters="query">
      <v-col cols="12" sm="4" md="3">
        <SearchEmployee
          ref="empSearch"
          :value="query.search"
          store="EMPLOYEE_LIST"
          :org-ids="query.orgIds"
          :group-ids="query.groupIds"
          :disabled="listIsLoading"
          :loading="listIsLoading"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.isEnabled"
          label="Статус работников"
          :items="statusItems"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :menu-props="{ offsetY: true }"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <OrganizationSelect v-model="query.orgIds" multiple dense />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.gender"
          label="Пол"
          :items="genderItems"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :menu-props="{ offsetY: true }"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <EmployeeGroupSelect
          v-model="query.groupIds"
          :org-ids="query.orgIds"
          multiple
          dense
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.isTest"
          label="Тестовые работники"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :items="testEmployeesStatuses"
          :menu-props="{ offsetY: true }"
        />
      </v-col>
    </template>

    <template #table.item.nameOrg="{ item }">
      <b><EmployeeName :value="item" /></b>
      <v-tooltip v-if="item.organization.shortName" right>
        <template #activator="{ on }">
          <span class="text-decoration-underline" v-on="on">
            {{ item.organization.shortName }}
          </span>
        </template>
        {{ item.organization.name }}
      </v-tooltip>
      <template v-else>
        {{ item.organization.name }}
      </template>
    </template>

    <template #table.item.isEnabled="{ item }">
      <Indicator
        :active="item.isEnabled"
        :tooltip="item.isEnabled ? 'Активен' : 'Неактивен'"
      />
    </template>

    <template #table.item.gender="{ item }">
      {{ genderTranslate[item.gender][0] }}
    </template>
  </List>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { STATUS, GENDER } from '@/utils/constants.js';
import waitable from '@/utils/mixins/waitable';
import { entity, messages, filters } from '../entity';

import EmployeeName from '@/modules/employees/components/EmployeeName';
import ListFactory from '@/components/crud/ListFactory';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect';
import EmployeeGroupSelect from '@/components/controls/structures/EmployeeGroupSelect';
import OrganizationSelectImport from '@/components/modals/OrganizationSelectImport.vue';
import ListActionBtn from '@/components/controls/buttons/ListActionBtn';
import Indicator from '@/components/Indicator.vue';
import SearchEmployee from '@/modules/inspections/components/controls/SearchEmployee.vue';

const List = ListFactory({
  storeModule: 'EMPLOYEE_LIST',
  messages,
  entity,
  filters,
  viewRoute: 'employees:card',
});

const genderItems = [
  { name: 'Неважно', value: null },
  { name: GENDER.MALE, value: 'MALE' },
  { name: GENDER.FEMALE, value: 'FEMALE' },
];

const testEmployeesStatuses = [
  { name: 'Неважно', value: null },
  { name: 'Да', value: true },
  { name: 'Нет', value: false },
];

export default {
  components: {
    List,
    EmployeeName,
    OrganizationSelectImport,
    OrganizationSelect,
    EmployeeGroupSelect,
    ListActionBtn,
    Indicator,
    SearchEmployee,
  },

  extends: waitable,

  computed: {
    ...mapGetters('EMPLOYEE_LIST', [
      'exportLimit',
      'listQuery',
      'listIsLoading',
    ]),

    genderItems: () => genderItems,
    statusItems: () => STATUS,
    genderTranslate: () => GENDER,
    testEmployeesStatuses: () => testEmployeesStatuses,
  },

  watch: {
    'listQuery.orgIds': {
      handler(_, oldVal) {
        // reset employee groups on org id filter change
        // check below is to not reset employee groups on page loading
        oldVal && this.resetEmpGroups();
      },
    },
  },

  async created() {
    this.$store.dispatch('EMPLOYEE_LIST/fetchExportLimit');
  },

  methods: {
    ...mapActions('EMPLOYEE_LIST', ['resetEmpGroups']),

    formModal() {
      this.$openModal('employees/form', {
        // object will b like {id: undefined, name: undefined ...}
        item: Object.fromEntries(entity.map(field => [field.key])),
        entity,
        messages: messages.create,
        onSubmit: data =>
          this.$store.dispatch('EMPLOYEE_LIST/createListItem', data),
        fetchOrganizationSettings: id =>
          this.$store.dispatch('EMPLOYEE_LIST/fetchOrganizationSettings', id),
      });
    },

    createTestEmployee() {
      this.$openModal('employees/form', {
        item: {},
        isTestEmployee: true,
        onSubmit: data =>
          this.$store.dispatch('EMPLOYEE_LIST/createTestEmployee', data),
        fetchOrganizationSettings: id =>
          this.$store.dispatch('EMPLOYEE_LIST/fetchOrganizationSettings', id),
      });
    },

    openPhoneForm({ id, phone }) {
      this.$openModal('employees/updatePhone', {
        employeeId: id,
        phone,
      });
    },

    resetHook() {
      this.$refs.empSearch.clear();
    },
  },
};
</script>
