import structApi from '@/api/services/structures';

export default {
  state: {
    ambient: {},
  },
  getters: {
    ambient: state => state.ambient,
  },
  mutations: {
    ambient: (state, data) => (state.ambient = data),
  },
  actions: {
    async fetchAmbientSettings({ commit, getters }) {
      const response = await structApi.getAmbientSettings(
        getters.singleItem.id,
      );
      commit('ambient', response);
      return response;
    },

    async updateAmbientSettings({ getters, commit }, payload) {
      // clear emptied values from payload
      Object.keys(payload).forEach(key => {
        if (payload[key] === '') delete payload[key];
        else payload[key] = +payload[key]; // convert to number
      });
      const result = await structApi.updateAmbientSettings(
        getters.singleItem.id,
        payload,
      );
      commit('ambient', result);
    },
  },
};
