<template>
  <AsyncSelect
    v-bind="computedProps"
    :item-text="getItemText"
    v-on="$listeners"
  >
    <template #item:description="{ item }">
      <v-chip v-if="!hideOrgName" x-small>{{ item.organization.name }}</v-chip>
    </template>
  </AsyncSelect>
</template>

<script>
import asyncSelectMixin from '@/components/controls/asyncSelectMixin';

import { mapActions } from 'vuex';
export default {
  extends: asyncSelectMixin,
  props: {
    accessLevel: {
      validator: value => ['any', 'full'].indexOf(value) !== -1,
      default: 'any',
    },
    orgIds: {
      type: [Array],
      default: null,
    },
    groupIds: {
      type: [Array],
      default: null,
    },
    releasePointIds: {
      type: [Array],
      default: null,
    },
    hideOrgName: { type: Boolean },
    isActive: { type: Boolean, default: null },
    isNotReprint: { type: Boolean, default: null },
  },

  computed: {
    // 'ПАК' is always , no matter multiple or not
    label() {
      return 'ПАК';
    },
    query() {
      const params = {
        accessLevel: this.accessLevel,
        isActive: this.isActive,
        orgIds: this.orgIds?.join() || undefined,
        groupIds: this.groupIds?.join() || undefined,
        releasePointIds: this.releasePointIds?.join() || undefined,
      };

      if (this.isNotReprint !== undefined)
        params.isNotReprint = this.isNotReprint;

      return params;
    },
  },

  methods: {
    ...mapActions('STRUCTURES', {
      fetcher: 'fetchHostPreviews',
      fetcherBy: 'fetchHostPreviewsBy',
    }),

    getItemText(item) {
      const licenseNumber = item.license?.split('-')[1] || null;
      // NOTE: we have 3 cases: points with adress / with coordinates / mobile
      // points. For those with adress we show adress always, for 2 other cases
      // we show their point id.
      const address = item.point?.address || '#' + item.point.id;
      return (
        [licenseNumber && `ПАК ${licenseNumber}`, address]
          .filter(_ => _)
          .join(', ') || `ПАК #${item.id}, Точка #${item?.point?.id}`
      );
    },
  },
};
</script>
