<template>
  <div>
    <DefinitionList dense :value="dataTable" />

    <div v-if="!events.length" class="pa-4">
      <NoDataAlert> Действия отсутствуют </NoDataAlert>
    </div>

    <v-expansion-panels v-else class="mt-4 shadow--hide" accordion multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="font-weight-bold">События</span>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-simple-table>
            <template #default>
              <thead>
                <th class="pa-1 text-caption text-left">Время</th>
                <th class="pa-1 text-caption text-left">Действие</th>
                <th class="pa-1 text-caption text-left">Медработник</th>
              </thead>

              <tbody>
                <tr
                  v-for="event in events"
                  :key="event.id"
                  :class="classByEvent(event)"
                >
                  <td class="pa-1">{{ getTime(event.timestamp) }}</td>
                  <td class="pa-1">{{ event.name || '–' }}</td>
                  <td class="pa-1">
                    <LinkButton
                      :text="event.medicId ? `#${event.medicId}` : '–'"
                      :action="event.medicId && $can('MED_RD')"
                      :to="{
                        name: 'medblock:medics:item',
                        params: { id: event.medicId },
                      }"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { DISTRIBUTION_TYPES, COLOR_BY_EVENT_TYPE } from '@/utils/constants';
import { mapGetters } from 'vuex';
import { dateTime, getTime } from '@/utils/convert';
import DefinitionList from '@/components/DefinitionList.vue';
import LinkButton from '@/components/controls/buttons/LinkButton';
import NoDataAlert from '@/components/ui/NoDataAlert';

export default {
  components: {
    DefinitionList,
    LinkButton,
    NoDataAlert,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    hideInspectionNumber: Boolean,
  },

  data: () => ({
    opened: [],
    inspectionIsAvailableInHistory: false,
  }),

  computed: {
    ...mapGetters(['themeIsDark']),

    dataTable() {
      const linkToInspection = {
        label: 'Номер осмотра',
        component: LinkButton,
        props: {
          text: `#${this.value.inspectionId}`,
          action: this.$can('I_RD'),
          to: {
            name: 'inspections:card',
            params: { id: this.value.inspectionId },
          },
        },
      };

      return [
        {
          label: 'Статус',
          value: this.value.statusName,
        },
        ...((this.hideInspectionNumber && []) || [linkToInspection]),
        {
          label: 'Дата добавления',
          value: dateTime(this.value.addedAt, {
            showTimeZone: true,
            showSeconds: true,
          }),
        },
        {
          label: 'ПАК',
          component: LinkButton,
          props: {
            text: `#${this.value.hostId}`,
            action: this.$can('HST_RD'),
            to: {
              name: 'structures:hosts:item',
              params: { id: this.value.hostId },
            },
          },
        },
        {
          label: 'Тип медкабинета',
          value: DISTRIBUTION_TYPES[this.value.distribution],
        },
      ].filter(_ => _);
    },

    events() {
      return this.value?.events || [];
    },
  },

  methods: {
    getTime,
    dateTime,

    classByEvent(event) {
      const light = this.themeIsDark ? 'darken' : 'lighten';
      const { color, lightness } = COLOR_BY_EVENT_TYPE[event.type] || {};
      return color ? [color, `${light}-${lightness}`] : [];
    },
  },
};
</script>
