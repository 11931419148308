<template>
  <div>
    <template v-if="isMedcabDebug">
      <v-btn class="ma-3" @click="onSubmit({ debug: true })">
        Войти без ЭЦП
      </v-btn>
      <v-divider class="mb-5" />
    </template>

    <Spinner v-if="accountCertificates === null" />

    <v-alert v-else-if="!(accountCertificates || []).length">
      К вашему аккаунту не подключены сертификаты
    </v-alert>

    <div v-else class="text-left">
      <CryptoProTokens
        v-model="selectedCert"
        :account-certificates="accountCertificates"
      />
      <template v-if="!SIGNME_DISABLE">
        <SignMeTokens
          v-model="selectedCert"
          :account-certificates="accountCertificates"
        />
      </template>
    </div>

    <v-btn
      class="px-5"
      depressed
      color="primary"
      :disabled="!selectedCert"
      @click="onSubmit({ token: selectedCert })"
    >
      Продолжить
    </v-btn>
  </div>
</template>

<script>
import env from '@/plugins/env';
import waitable from '@/utils/mixins/waitable';
import Spinner from '@/components/Spinner.vue';
import CryptoProTokens from '@/components/CryptoProTokens.vue';
import SignMeTokens from '@/components/SignMeTokens.vue';

export default {
  components: {
    Spinner,
    CryptoProTokens,
    SignMeTokens,
  },
  mixins: [waitable],

  data: () => ({
    selectedCert: null,
  }),

  computed: {
    SIGNME_DISABLE() {
      return env.get('VUE_APP_SIGNME_DISABLE');
    },

    isMedcabDebug() {
      return env.get('VUE_APP_MEDCAB_DEBUG');
    },

    accountCertificates() {
      return this.$store.getters['TOKENS/accountCertificates'];
    },
  },

  async created() {
    await this.$store.dispatch('TOKENS/fetchAccountCertificates');
  },

  methods: {
    async onSubmit(payload) {
      this.$emit('submit', payload);
    },
  },
};
</script>
