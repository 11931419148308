<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-btn :disabled="disabled" icon @click="$emit('click')">
          <IconWrapper :icon="icon" :color="color" :size="size" />
        </v-btn>
      </div>
    </template>
    <span>{{ title }}</span>
  </v-tooltip>
</template>

<script>
import IconWrapper from '@/components/IconWrapper';
export default {
  name: 'IconButton',
  components: { IconWrapper },
  props: {
    icon: { required: true, type: String },
    title: { required: true, type: String },
    color: { type: String, default: null },
    size: { type: Number, default: 16 },
    disabled: { type: Boolean, default: false },
  },
};
</script>
