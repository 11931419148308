import env from '@/plugins/env';
import { createHTTP } from '../api';
import {
  LimitDto,
  CreateLimitCommand,
  UpdateLimitCommand,
  CountInspectionsQuery,
  LimitPreviewPayload,
} from './limits.interfaces';

export const limits = createHTTP(env.get('VUE_APP_LIMITS_API'));

export default {
  /** @action LM_R */
  async limitGet(id: number) {
    const res = await limits.get(`/v3/limits/${id}`);
    return res.data as LimitDto;
  },
  /** @action LM_C */
  async limitCreate(payload: CreateLimitCommand) {
    const res = await limits.post('/v3/limits', payload);
    return res.data as LimitDto;
  },
  /** @action LM_U */
  async limitUpdate(id: number, payload: UpdateLimitCommand) {
    const res = await limits.patch(`/v3/limits/${id}`, payload);
    return res.data as LimitDto;
  },
  /** @action LM_D */
  async limitDelete(id: number) {
    await limits.delete(`/v3/limits/${id}`);
    return id;
  },

  /** @action LM_ORR */
  async limitByOrgGet(orgId: number) {
    const res = await limits.get(`/v3/limits/org/${orgId}`);
    return res.data as LimitDto[];
  },

  async limitsByGroupOrgsGet(params: LimitPreviewPayload) {
    const res = await limits.get('/v3/limits/preview', { params });
    return res.data as [];
  },

  /** @action LM_ORR */
  async limitByOrgGroupGet(orgGroupId: number) {
    const res = await limits.get(`/v3/limits/group/${orgGroupId}`);
    return res.data as LimitDto[];
  },

  /** @action LM_EN */
  async limitEnable(id: number) {
    const res = await limits.post(`/v3/limits/${id}/enable`);
    return res.data as LimitDto;
  },
  /** @action LM_DS */
  async limitDisable(id: number) {
    const res = await limits.post(`/v3/limits/${id}/disable`);
    return res.data as LimitDto;
  },

  /** @action LM_ICNT */
  async limitCountByOrg(orgId: number, params: CountInspectionsQuery) {
    const res = await limits.get(
      `/v3/organizations/${orgId}/inspections/count`,
      { params },
    );
    return res.data as [];
  },

  async checkGroupLimits(orgId: number, groupIds: number[]) {
    const res = await limits.get(
      `/v3/organizations/${orgId}/check-groups-limits`,
      { params: { orgGroupIds: groupIds.join(',') } },
    );
    return res.data as [];
  },

  async limitCountByOrgGroup(
    orgGroupId: number,
    params: CountInspectionsQuery,
  ) {
    const res = await limits.get(
      `/v3/org_groups/${orgGroupId}/inspections/count`,
      { params },
    );
    return res.data as [];
  },
};
