<template>
  <ModernModalLayout
    size="full"
    height="100vh"
    :title="`История осмотров - ${shortName(employee)}`"
    hide-bottom
    dense-content
  >
    <div
      v-if="inspectionsIsNone"
      class="d-flex justify-center align-center"
      style="height: 100%"
    >
      <div class="text-h6 text-center">
        {{ name(employee) }} не проходил осмотры
      </div>
    </div>
    <ColumnLayout
      v-else-if="inspections.length"
      grid-template-columns="450px 1fr"
      style="height: 100%; gap: 0"
    >
      <template #col-list>
        <v-list
          dense
          class="pa-0"
          color="grey"
          :class="themeIsDark ? 'darken-2' : 'lighten-4'"
          height="100%"
        >
          <div v-for="(item, index) in inspections" :key="item.id">
            <InspectionsListItem
              class="grey"
              :class="themeIsDark ? 'darken-2' : 'lighten-4'"
              :item="item"
              :remarks="inspectionRemarks"
              :remark-types="inspectionRemarkTypes"
              :current-id="currentId"
              @changeCurrent="current = $event"
            />
            <v-divider v-if="index !== inspections.length - 1" />
          </div>
          <v-list-item v-if="!allItemsLoaded" class="d-flex justify-center">
            <Spinner v-if="$wait('fetchInspections')" :size="30" />
            <ErrorBlock
              v-if="inspectionsError"
              style="width: 100%"
              :error="inspectionsError"
              @refresh="fetchData"
            />
            <div
              v-else
              v-show="!$wait('fetchInspections')"
              v-intersect="fetchNext"
              class="mt-16"
              style="height: 1px"
            />
          </v-list-item>
        </v-list>
      </template>

      <template #col-item>
        <div v-if="currentId" style="height: 100%">
          <template v-if="$wait('fetchInspectionItem')">
            <Spinner :size="30" />
          </template>
          <template v-else-if="inspectionItemError" class="d-flex align-start">
            <ErrorBlock
              style="width: 100%"
              :error="inspectionItemError"
              @refresh="loadInspectionItem(current)"
            />
          </template>
          <template v-else-if="inspectionItem">
            <InspectionInfo
              style="height: 100%"
              :remarks="inspectionRemarks"
              :remark-types="inspectionRemarkTypes"
              :value="inspectionItem"
            />
          </template>
        </div>
      </template>
    </ColumnLayout>
    <div v-else-if="inspectionsError" class="d-flex align-start">
      <ErrorBlock
        class="ma-5"
        style="width: 100%"
        :error="inspectionsError"
        @refresh="fetchData"
      />
    </div>
    <Spinner v-else />
  </ModernModalLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import Spinner from '@/components/Spinner.vue';
import ModernModalLayout from '@/components/layouts/ModernModalLayout.vue';
import ColumnLayout from '@/components/layouts/ColumnLayout.vue';
import InspectionsListItem from '@/modules/inspections/components/InspectionsListItem.vue';
import InspectionInfo from '@/modules/inspections/components/InspectionInfo.vue';
import ErrorBlock from '@/components/ErrorBlock';
import { shortName, name } from '@/utils/convert';

export default {
  components: {
    Spinner,
    ModernModalLayout,
    ColumnLayout,
    InspectionsListItem,
    InspectionInfo,
    ErrorBlock,
  },

  mixins: [waitable],

  props: {
    employee: { type: Object, required: true },
  },

  data: () => ({
    nextPage: 1,
    current: null,
    inspectionsError: null,
    inspectionItemError: null,
  }),

  computed: {
    ...mapGetters('REGISTRY', ['inspectionRemarks', 'inspectionRemarkTypes']),
    ...mapGetters('INSPECTION_HISTORY', [
      'inspections',
      'inspectionsTotal',
      'inspectionItem',
    ]),
    ...mapGetters(['themeIsDark']),

    currentId() {
      return this.current?.id || null;
    },

    allItemsLoaded() {
      return this.inspections.length === (this.inspectionsTotal || 0);
    },

    inspectionsIsNone() {
      return this.inspectionsTotal === 0;
    },
  },

  watch: {
    current(val) {
      if (val) this.loadInspectionItem(val);
    },
  },

  async mounted() {
    await this.fetchData();
  },

  async created() {
    await this.clearInspections();
    await this.fetchInspectionRemarks();
  },

  methods: {
    ...mapActions('INSPECTION_HISTORY', [
      'clearInspections',
      'fetchInspections',
      'fetchInspectionItem',
    ]),
    ...mapActions('REGISTRY', ['fetchInspectionRemarks']),

    shortName,
    name,

    async fetchData() {
      this.inspectionsError = null;
      const params = {
        orderBy: 'date',
        orderType: 'DESC',
        employeeIds: this.employee.id,
        page: this.nextPage,
        limit: 10,
      };
      try {
        await this.$loading(this.fetchInspections(params), 'fetchInspections');

        // first element always active
        if (this.nextPage === 1) {
          this.$nextTick(() => {
            if (this.inspections.length) {
              this.current = this.inspections[0];
            }
          });
        }

        this.nextPage++;
      } catch (err) {
        this.inspectionsError = err;
      }
    },

    async fetchNext(evt) {
      if (!evt[0].isIntersecting) return;

      await this.fetchData();
    },

    async loadInspectionItem(item) {
      if (this.$wait('fetchInspectionItem')) return;

      try {
        this.inspectionItemError = null;
        await this.$loading(
          this.fetchInspectionItem({
            id: item.id,
            isImported: item.isImported,
          }),
          'fetchInspectionItem',
          `Не удалось загрузить данные по осмотру #${item.id}`,
        );

        if (this.current.id !== this.inspectionItem.id) {
          this.current = this.inspectionItem || null;
        }
      } catch (err) {
        this.inspectionItemError = err;
      }
    },
  },
};
</script>
