<template>
  <div class="d-flex justify-end">
    <slot v-bind="{ item }">
      <slot name="prepend" v-bind="$props" />

      <v-tooltip v-if="showAction" left>
        <template #activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
              :disabled="item.isRestricted"
              icon
              :to="{
                name: viewRoute,
                params: item.id ? { id: item.id } : { key: item.key },
              }"
            >
              <v-icon small>fa-eye</v-icon>
            </v-btn>
          </div>
        </template>
        <span>{{ item.isRestricted ? 'Недоступно' : 'Карточка' }}</span>
      </v-tooltip>

      <slot name="append" v-bind="$props" />
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    showAction: { type: Boolean, default: true },
    handleShowAction: { type: Function, default: () => {} },
    item: { type: Object, required: true },
    viewRoute: { type: String, required: true },
  },
};
</script>
