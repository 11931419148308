<template>
  <v-expansion-panels v-model="panel" accordion focusable>
    <InspTypePanel
      v-for="(type, i) in inspectionTypes"
      :key="i"
      v-model="scenariosInner[type.key]"
      :insp-type="type"
      :disable-actions="disableActions"
      :is-create="isCreate"
      :hide-sign="hideSign"
      @toggleType="toggleType($event, i)"
    />
  </v-expansion-panels>
</template>

<script>
import InspTypePanel from '@/components/InspTypePanel.vue';
import cloneDeep from 'lodash/cloneDeep';

const ALCOHOL_SHORT_TYPE = 'alcohol.short';
const ALCOHOL_LONG_TYPE = 'alcohol.long';
const ALCOHOL_COMMON_TYPE = 'alcohol';

export default {
  name: 'InspectionTypes',
  components: { InspTypePanel },
  props: {
    scenarios: { type: Object, required: true },
    inspectionTypes: { type: Array, required: true },
    stepTypes: { type: Array, required: true },
    isCreate: { type: Boolean, required: true },
    disableActions: { type: Boolean, required: true },
    hideSign: { type: Boolean, default: false },
    fullStepsList: { type: Boolean, default: true }, // Нужно ли дополнять список шагов до полного
  },
  data: () => {
    return {
      panel: null,
      scenariosInner: {},
    };
  },

  beforeMount() {
    this.prepare();
  },

  methods: {
    prepare() {
      if (this.isCreate) this.scenariosInner = this.setEmptyState();
      else this.scenariosInner = this.setFromTemplate(this.scenarios);
    },

    // FIXME: с 1 сентября 2023 вступит в силу закон, по которому качественный
    // продув не будет катироваться и тогда можно поднять вопрос об избавлении
    // от этого функционала

    // its need that the alcohol steps follow each other and drops together
    connectAlcoholSteps(steps) {
      const alcoShortStep = steps[ALCOHOL_SHORT_TYPE];
      const alcoLongStep = steps[ALCOHOL_LONG_TYPE];
      const targetObj = { ...steps };
      if (!alcoShortStep || !alcoLongStep) {
        return steps;
      }

      delete targetObj[ALCOHOL_SHORT_TYPE];
      delete targetObj[ALCOHOL_LONG_TYPE];

      // connect steps by the common step
      const minOrderIndex =
        alcoShortStep.orderIndex < alcoLongStep.orderIndex
          ? alcoShortStep.orderIndex
          : alcoLongStep.orderIndex;

      targetObj[ALCOHOL_COMMON_TYPE] = {
        items: [alcoShortStep, alcoLongStep],
        orderIndex: minOrderIndex,
        type: ALCOHOL_COMMON_TYPE,
      };
      // make array by new object, sort by orderIndex and write new indexes
      const target = Object.values(targetObj)
        .sort((step1, step2) => step1.orderIndex - step2.orderIndex)
        .map((step, i) => [step.type, { ...step, orderIndex: i }]);

      return Object.fromEntries(target);
    },

    // its revers for the connectAlcoholSteps
    splitAlcoholSteps(scenario) {
      const alcoStepIndex = scenario.stepOrder.findIndex(
        type => type === ALCOHOL_COMMON_TYPE,
      );
      if (alcoStepIndex < 0) {
        return scenario;
      }
      // we need to replace common step with short and long steps
      scenario.stepOrder.splice(
        alcoStepIndex,
        1,
        ALCOHOL_SHORT_TYPE,
        ALCOHOL_LONG_TYPE,
      );
      scenario.steps[ALCOHOL_COMMON_TYPE].items.forEach(
        item => (scenario.steps[item.type] = item),
      );
      delete scenario.steps[ALCOHOL_COMMON_TYPE];
      return scenario;
    },

    // backend consideres every scenario / step object that is present in
    // payload to be 'enabled' so before sending it for update/create we need
    // to cleanup the reactivity mess
    cleanPayload() {
      const payload = cloneDeep(this.scenariosInner);
      Object.values(payload).forEach(scenario => {
        scenario = this.splitAlcoholSteps(scenario);
        // we need an independent index since length of steps passed to back
        // is not equal to the one we have on front (the ones status=false)
        let index = 0;
        // update step indecies based on stepOrder field
        scenario.stepOrder.forEach(step => {
          if (scenario.steps[step].inActive) {
            delete scenario.steps[step];
            return;
          }
          scenario.steps[step].orderIndex = index++;
        });

        // Удаление локальных полей
        delete scenario.stepOrder;
        Object.values(scenario.steps).forEach(step => {
          delete step.inActive;
        });
      });
      return payload;
    },

    setEmptyState() {
      const r = {};
      // switches are bound to status field, which doesn't come from
      // backend since we can't bind v-switch to 'if object exists'
      this.inspectionTypes.forEach(v => {
        const steps = this.connectAlcoholSteps(
          Object.fromEntries(
            this.stepTypes.map((type, i) => [
              type,
              {
                type,
                orderIndex: i,
                status: false,
                cancellable: false,
                inActive: false,
              },
            ]),
          ),
        );

        const stepOrder = [];
        const validSteps = Object.keys(steps).filter(e => !steps[e].inActive);
        validSteps.forEach(step => (stepOrder[steps[step].orderIndex] = step));

        r[v.key] = {
          isEnabled: false,
          type: v.key,
          // for 'draggable' component, later when submitting this array
          // will be used to assign 'orderIndex' field in each step in obj below
          stepOrder,
          // default steps object
          steps,
        };
      });
      return r;
    },

    setFromTemplate(template) {
      const r = cloneDeep(template);

      Object.values(r).forEach(v => {
        // steps that come from backend have orderIndex field
        let initialStepsAmount = Object.keys(v.steps).length;
        // traverse default step value
        // settings those not present and giving them correct index
        // and setting status of already present to 'true'
        if (this.fullStepsList) {
          this.stepTypes.forEach(type => {
            if (!v.steps[type]) {
              v.steps[type] = {
                type,
                orderIndex: initialStepsAmount++,
                status: false,
                cancellable: false,
              };
            } else
              v.steps[type].status =
                !v.steps[type].inActive && v.steps[type].status;
          });
        } else {
          // Когда нужно исключить отсутствующие шаги
          // значение чекбокса вычисляется на основе поля inActive
          Object.keys(v.steps).forEach(type => {
            v.steps[type].status =
              !v.steps[type].inActive && v.steps[type].status;

            if (!v.steps[type].status) {
              v.steps[type].cancellable = false;
            }
          });
        }

        Object.keys(v.steps).forEach(step => {
          if (v.steps[step].inActive) {
            delete v.steps[step];
          }
        });

        v.steps = this.connectAlcoholSteps(v.steps);

        v.stepOrder = Object.values(v.steps)
          .sort((step1, step2) => step1.orderIndex - step2.orderIndex)
          .map(step => step.type);
      });
      return r;
    },

    toggleType(type, panelIndex) {
      const action = this.scenariosInner[type.key].isEnabled
        ? 'Отключ'
        : 'Подключ';

      this.$openModal('prompt', {
        yes: 'Подтвердить',
        no: 'Отменить',
        title: `${action}ение типа осмотра`,
        description: `Вы действительно хотите ${action.toLowerCase()}ить тип осмотра ${type.name.toLowerCase()}?`,
        onSubmit: () => {
          this.scenariosInner[type.key].isEnabled =
            !this.scenariosInner[type.key].isEnabled;
          this.scenariosInner[type.key].isEnabled && (this.panel = panelIndex);
        },
      });
    },
  },
};
</script>
