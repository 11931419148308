<script>
import waitable from '@/utils/mixins/waitable';

import Scroller from './Scroller.vue';
import ErrorBlock from '@/components/ErrorBlock';
import NoDataAlert from '@/components/ui/NoDataAlert';

export default {
  mixins: [waitable],
  props: {
    fetcher: { type: Function, required: true },
    limit: { type: Number, default: 20 },
    dividers: Boolean,
    noDataMessage: {
      type: String,
      default: 'Результатов нет',
    },
    fullWidth: Boolean,
  },

  data: () => ({
    items: [],
    page: 0,
    total: Infinity,
    error: null,
  }),

  created() {
    this.handleFetchData();
  },

  methods: {
    async handleFetchData() {
      const current = this.page * this.limit;
      if (this.$wait('fetchingData') || current > this.total) return;

      try {
        this.error = null;
        const { total, items } = await this.$loading(
          this.fetcher({ page: this.page + 1, limit: this.limit }),
          'fetchingData',
        );
        this.page += 1;
        this.total = total;
        this.items = [...this.items, ...items];
        this.$emit('fetched', items);
      } catch (error) {
        this.error = error;
      }
    },

    renderEmptyBadge() {
      if (this.total === 0 && !this.$wait('fetchingData'))
        return <NoDataAlert class="ma-4">{this.noDataMessage}</NoDataAlert>;
    },
    renderError(error) {
      if (this.$scopedSlots.error) return this.$scopedSlots.error(error);

      return <ErrorBlock error={error} onRefresh={this.handleFetchData} />;
    },
  },

  render() {
    return this.$createElement(Scroller, {
      props: {
        dividers: this.dividers,
        items: this.items,
        fullWidth: this.fullWidth,
        loading: this.$wait('fetchingData'),
      },
      on: {
        'scroll:end': () => !this.error && this.handleFetchData(),
      },
      scopedSlots: {
        item: this.$scopedSlots.item,
        append: this.error ? () => this.renderError(this.error) : null,
        prepend: this.renderEmptyBadge,
      },
    });
  },
};
</script>
