<template>
  <ModernModalLayout
    :title="data.id ? 'Редактирование ПАК' : 'Добавление ПАК'"
    close-label="Отмена"
    :watch-for="data"
  >
    <v-form ref="form" :disabled="isLoading || disabled || $wait('submit')">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="data.name"
            label="Название"
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('data.name')"
            @blur="validateField('data.name')"
          />
        </v-col>
        <v-col cols="12">
          <MaskField
            key="data.license"
            v-model="data.license"
            :mask="maskInputHostLicense"
            placeholder="A270920181900-XXXXX"
            label="Лицензия"
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('data.license')"
            @blur="handleFieldBlur('data.license')"
            @input="needApprove = true"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="data.formFactor"
            label="Форм-фактор"
            :items="_factors"
            item-value="value"
            item-text="name"
            outlined
            :disabled="data.id && !$can('HST_UFF')"
            :loading="$wait('fetchingFormFactors')"
            hide-details="auto"
            :error-messages="getValidationErrors('data.formFactor')"
            @blur="handleFieldBlur('data.formFactor')"
            @change="needApprove = true"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="data.medicalDeviceTypeId"
            label="Вариант исполнения"
            :items="types"
            item-value="id"
            item-text="shortName"
            outlined
            :disabled="
              !$can('MDT_R') ||
              !data.formFactor ||
              (data.id && !$can('HST_UFF'))
            "
            :loading="$wait('fetchingMDeviceTypes')"
            hide-details="auto"
            :error-messages="getValidationErrors('data.medicalDeviceTypeId')"
            @blur="handleFieldBlur('data.medicalDeviceTypeId')"
            @change="needApprove = true"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="data.verificationCertificate.number"
            label="Номер свидетельства поверки"
            outlined
            hide-details="auto"
            :messages="verificationCertificateMessage"
            :error-messages="
              getValidationErrors('data.verificationCertificate.number')
            "
            @blur="validateField('data.verificationCertificate.date')"
            @input="needApprove = true"
          />
        </v-col>
        <v-col cols="12">
          <DatePicker
            v-model="data.verificationCertificate.date"
            label="Дата поверки"
            outlined
            max-current
            min="2017-01-01"
            hide-details="auto"
            :disabled="!data.verificationCertificate.number"
            :error-messages="
              getValidationErrors('data.verificationCertificate.date')
            "
            @blur="validateField('data.verificationCertificate.date')"
            @input="needApprove = true"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="data.description"
            label="Описание"
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('data.description')"
            @blur="validateField('data.description')"
          />
        </v-col>

        <template v-if="!data.id">
          <v-col cols="12">
            <v-switch
              v-model="data.isOpenpoint"
              :true-value="true"
              :false-value="false"
              label="Открытая точка"
              class="mb-3"
              hide-details="auto"
              :error-messages="getValidationErrors('data.isOpenpoint')"
            />
          </v-col>
          <v-col cols="12">
            <OrganizationSelect
              v-model="orgId"
              access-level="full"
              hide-details="auto"
              :error-messages="getValidationErrors('orgId')"
              @input="data.releasePointId = null"
            />
          </v-col>
          <v-col cols="12">
            <PointSelect
              v-model="data.releasePointId"
              :hint="orgId ? '' : 'Сначала выберите организацию'"
              hide-details="auto"
              access-level="full"
              :disabled="!orgId"
              :org-ids="[orgId]"
              :error-messages="getValidationErrors('data.releasePointId')"
              @blur="validateField('data.releasePointId')"
            />
          </v-col>
          <v-col cols="12">
            <HostGroupSelect
              v-model="data.groupIds"
              :org-ids="[orgId]"
              multiple
              type="public"
              access-level="full"
              :omit-guests="true"
              :hint="orgId ? '' : 'Сначала выберите организацию'"
              persistent-hint
              :disabled="!orgId"
              :error-messages="getValidationErrors('data.groupIds')"
              @blur="validateField('data.groupIds')"
            />
          </v-col>
        </template>

        <v-col cols="12">
          <v-checkbox
            v-model="data.isNotPrint"
            label="Запретить автоматическую печать стикера"
            hide-details="auto"
            :error-messages="getValidationErrors('data.isNotPrint')"
          />
          <v-checkbox
            v-model="data.isNotReprint"
            label="Запретить печатать стикер повторно"
            hide-details="auto"
            :error-messages="getValidationErrors('data.isNotReprint')"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="data.notPrintComment"
            label="Причина запрета печати"
            outlined
            hide-details="auto"
            :disabled="!notPrintCommentEnabled"
            :error-messages="getValidationErrors('data.notPrintComment')"
            @blur="validateField('data.notPrintComment')"
          />
        </v-col>
      </v-row>
    </v-form>

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :disabled="$wait('submit')"
        @click="openModal"
      >
        {{ data.id ? 'Сохранить' : 'Добавить' }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import {
  required,
  maxLength,
  requiredIf,
  minLength,
} from 'vuelidate/lib/validators';
import { hostLicense } from '@/utils/validators';
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import { maskInputHostLicense } from '@/utils/masks';
import { mapActions, mapGetters } from 'vuex';
import { deepCopyWithNull, currentDate } from '@/utils/helpers';
import { DateTime } from '@/plugins/luxon';

import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import MaskField from '@/components/controls/MaskField';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect';
import PointSelect from '@/components/controls/structures/PointSelect';
import HostGroupSelect from '@/components/controls/structures/HostGroupSelect';
import DatePicker from '@/components/controls/DatePicker.vue';

export default {
  components: {
    ModernModalLayout,
    MaskField,
    OrganizationSelect,
    PointSelect,
    HostGroupSelect,
    DatePicker,
  },
  mixins: [validation, waitable],

  props: {
    value: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    onSubmit: { type: Function, default: null },
    // it is done extencibely, yes. But for now it will ONLY predefine
    // release point ID when host is being created from within a point
    predefinedValues: {
      type: Object,
      default: () => ({}),
    },
    storeForCreating: { type: String, default: 'STRUCTURES/HOSTS' },
  },

  data: () => ({
    orgId: null,
    data: {
      groupIds: [],
      verificationCertificate: {},
      releasePointId: null,
      medicalDeviceTypeId: null,
      isOpenpoint: false,
      isNotPrint: false,
      isNotReprint: false,
      notPrintComment: null,
    },
    needApprove: false,
  }),

  computed: {
    ...mapGetters('STRUCTURES/HOSTS_ITEM', ['formFactors', 'mDeviceTypes']),
    maskInputHostLicense: () => maskInputHostLicense,
    notPrintCommentEnabled() {
      return this.data.isNotPrint || this.data.isNotReprint;
    },

    _factors() {
      const ff = this.formFactors;
      return Object.keys(ff).map(k => ({ name: ff[k], value: k }));
    },

    types() {
      return this.mDeviceTypes.filter(
        d => d.formFactor === this.data.formFactor,
      );
    },
    verificationCertificateMessage() {
      return this.value?.niiatStatus?.registered &&
        this.value.verificationCertificate.number !==
          this.data.verificationCertificate.number
        ? 'Будет отправлен в АИС ЕРП'
        : null;
    },
  },

  validations() {
    const v = {
      data: {
        name: { required, maxSymbolsLength: maxLength(500) },
        license: { required, hostLicense },
        formFactor: {
          required: requiredIf(() => !this.data.id || this.$can('HST_UFF')),
        },
        medicalDeviceTypeId: {
          required: requiredIf(() => !this.data.id || this.$can('HST_UFF')),
        },
        verificationCertificate: {
          date: {
            required: requiredIf(
              () => !!this.data.verificationCertificate.number,
            ),
            date: val => {
              if (this.data.verificationCertificate.number) {
                // нельзя указать дату из будущего
                const current = currentDate(true);
                const input = DateTime.fromISO(val);
                return current >= input;
              }
              return true;
            },
          },
          number: {
            maxSymbolsLength: maxLength(50),
            required: requiredIf(() => this.value?.niiatStatus?.registered),
          },
        },
        releasePointId: { required: requiredIf(() => !this.data.id) },
        isOpenpoint: { required: requiredIf(() => !this.data.id) },
        notPrintComment: {
          required: requiredIf(() => this.notPrintCommentEnabled),
        },
      },
      orgId: { required: requiredIf(() => !this.data.id) },
    };

    if (this.notPrintCommentEnabled) {
      v.data.notPrintComment.minSymbolsLength = minLength(5);
      v.data.notPrintComment.maxSymbolsLength = maxLength(1000);
    }

    return v;
  },

  watch: {
    'data.description': {
      handler(val) {
        // we need to send null to backend, not an empty string, or it will
        // return code 400
        !val?.length && (this.data.description = null);
      },
    },
    'data.formFactor': {
      handler(_, oldVal) {
        if (oldVal) this.data.medicalDeviceTypeId = null;
      },
    },
    'data.verificationCertificate.number': {
      handler(val) {
        !val?.length &&
          !this.value?.niiatStatus?.registered &&
          (this.data.verificationCertificate = {});
      },
    },
    notPrintCommentEnabled(val) {
      if (!val) this.data.notPrintComment = null;
    },
  },

  created() {
    this.data = deepCopyWithNull({ ...this.data, ...this.value });
    // verificationCertificate shouldn't be null
    !this.data.verificationCertificate &&
      (this.data.verificationCertificate = {});

    this.$loadingNotify(
      this.fetchMDeviceTypes(),
      'fetchingMDeviceTypes',
      'Произошла ошибка загрузки форм факторов',
    );
    if (this.data.medicalDeviceType?.id)
      this.data.medicalDeviceTypeId = this.data.medicalDeviceType?.id;

    if (!this.data.id) {
      Object.keys(this.predefinedValues).length &&
        Object.keys(this.predefinedValues).forEach(key => {
          // HACK: jus cuz i don't any better way of passing orgid to this
          // component and assigning it NOT into data object
          // It is needed to preselect the release point ID value
          if (key === 'orgId') this.orgId = this.predefinedValues.orgId;
          else this.data[key] = this.predefinedValues[key];
        });

      // form factors object might be missing only on creation
      if (Object.keys(this.formFactors).length === 0)
        this.$loadingNotify(
          this.fetchFormFactors(),
          'fetchingFormFactors',
          'Произошла ошибка загрузки форм факторов',
        );
    }
  },

  methods: {
    ...mapActions('STRUCTURES/HOSTS_ITEM', [
      'fetchFormFactors',
      'fetchMDeviceTypes',
    ]),
    ...mapActions('STRUCTURES/HOST_GROUPS', {
      changeCountOnHostGroups: 'changeCounter',
    }),
    ...mapActions('STRUCTURES/POINTS', {
      changeCountOnPoints: 'changeCounter',
    }),

    openModal() {
      if (!this.validate()) return;
      if (this.needApprove || !this.data.id) {
        this.$openModal('structures/approveChanges', { onSubmit: this.submit });
        return;
      }
      this.submit();
    },

    changeCounters() {
      this.changeCountOnHostGroups({
        itemIds: this.value.groupIds,
        counterField: 'itemsCount',
        addCount: -1,
      });
      this.changeCountOnHostGroups({
        itemIds: this.data.groupIds,
        counterField: 'itemsCount',
        addCount: 1,
      });
      if (this.value.releasePointId)
        this.changeCountOnPoints({
          itemIds: [this.value.releasePointId],
          counterField: 'hostsCount',
          addCount: -1,
        });
      if (this.data.releasePointId)
        this.changeCountOnPoints({
          itemIds: [this.data.releasePointId],
          counterField: 'hostsCount',
          addCount: 1,
        });
    },

    async submit() {
      if (!this.notPrintCommentEnabled) this.data.notPrintComment = null;

      const payload = deepCopyWithNull(this.data);

      let actionName;
      if (payload.id) {
        actionName = this.$can('HST_UFF')
          ? 'STRUCTURES/HOSTS_ITEM/updateSingleItemFF'
          : 'STRUCTURES/HOSTS_ITEM/updateSingleItem';
      } else actionName = `${this.storeForCreating}/createListItem`;

      await this.$loadingNotify(
        this.$store.dispatch(actionName, payload),
        'submit',
        `Произошла ошибка ${payload.id ? 'изменения' : 'создания'} ПАК`,
        `ПАК успешно ${payload.id ? 'изменен' : 'создан'}`,
      )
        .then(() => {
          this.changeCounters();
          this.onSubmit && this.onSubmit();
          this.$emit('close');
        })
        .catch(err => {
          this.catchServerErrors(err, 'data');
          // При ошибки в поле поверки необходимо вернуть номер поверки в старое значение
          const properties = err.response?.data?.inner?.properties || [];
          if (
            this.value?.niiatStatus?.registered &&
            properties.includes('verificationCertificate')
          ) {
            this.data.verificationCertificate.number =
              this.value.verificationCertificate.number;
          }
        });
    },
  },
};
</script>
