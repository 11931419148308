<template>
  <ModernModalLayout :title="message('title')">
    <v-form>
      <v-switch v-model="isImported" label="Архивный" class="mt-0" />

      <MaskField
        v-model="id"
        :label="message('label')"
        hide-details="auto"
        autofocus
        outlined
        :error-messages="error"
        :mask="maskPosNumber"
        @keydown.enter="handleEnter"
      />
    </v-form>

    <template #actions:append>
      <v-btn
        class="px-5"
        color="primary"
        depressed
        :disabled="!id || $wait('submit')"
        :loading="$wait('submit')"
        @click="handleSubmit"
      >
        {{ message('actionButton') }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import _isNumber from 'lodash/isNumber';
import _isNaN from 'lodash/isNaN';

import historyApi from '@/api/services/history';
import { maskPosNumber } from '@/utils/masks';
import waitable from '@/utils/mixins/waitable';
import messagable from '@/utils/mixins/messagable';

import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import MaskField from '@/components/controls/MaskField';

const MESSAGES = {
  title: 'Перейти по ID',
  label: 'Введите ID',
  actionButton: 'Перейти',
  notFound: 'Не найдено',
  forbidden: 'Не доступно',
  serverValidation: 'Ошибка. Получен не валидный запрос',
  error: 'Ошибка. Попробуйте позже',
};

export default {
  components: {
    ModernModalLayout,
    MaskField,
  },

  mixins: [waitable, messagable(MESSAGES)],

  data: () => ({
    id: null,
    isImported: false,
    error: null,
  }),

  computed: {
    maskPosNumber: () => maskPosNumber,
  },

  methods: {
    handleEnter(event) {
      event.preventDefault();

      this.error = null;

      if (!this.id) return null;

      const idAsNumber = Number(this.id);
      if (!_isNumber(idAsNumber) || _isNaN(idAsNumber)) {
        this.error = 'Ошибка. Ожидалось число';
        return;
      }

      this.handleSubmit();
    },

    async handleSubmit() {
      this.error = null;

      try {
        const action = this.isImported
          ? historyApi.inspectionArchived(this.id)
          : historyApi.inspectionGet(this.id);

        await this.$loading(action, 'submit').then(() => {
          this.$emit('close');

          this.$nextTick(() =>
            this.$router.push({
              name: this.isImported
                ? 'inspections:card:archived'
                : 'inspections:card',
              params: {
                id: this.id,
              },
            }),
          );
        });
      } catch (err) {
        const status = err?.response?.status;
        const errList = {
          404: this.message('notFound'),
          403: this.message('forbidden'),
          400: this.message('serverValidation'),
        };

        this.error = errList[status] || this.message('error');
      }
    },
  },
};
</script>
