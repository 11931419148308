import structApi from '@/api/services/structures';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { createModule } from '@/utils/vuex/createModule';
import { filters } from '../entity';

export default createModule(
  crudListFactory({
    fetchMethod: structApi.pointList,
    createMethod: structApi.pointCreate,
    deleteMethod: structApi.pointDelete,
    filters,
  }),
);
