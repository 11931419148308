<template>
  <AsyncSelect
    v-bind="computedProps"
    :item-text="getItemText"
    v-on="$listeners"
  >
    <template #item:description="{ item }">
      <v-chip v-if="item.isGuest" color="primary" x-small> Гость </v-chip>
      <v-chip x-small :class="item.isGuest ? 'ml-1' : ''">
        {{ item.organization.name }}
      </v-chip>
      <v-chip v-if="!item.isActive" x-small color="error" class="ml-1">
        Неактивна
      </v-chip>
    </template>
  </AsyncSelect>
</template>

<script>
import asyncSelectMixin from '@/components/controls/asyncSelectMixin';

import { mapActions } from 'vuex';
export default {
  extends: asyncSelectMixin,
  props: {
    accessLevel: {
      validator: value => ['any', 'full'].indexOf(value) !== -1,
      default: 'any',
    },
    orgGroupIds: { type: [Array], default: null },
    orgIds: { type: [Array], default: null },
    includeGuests: Boolean,
  },

  computed: {
    label() {
      return this.multiple ? 'Точки выпуска' : 'Точка выпуска';
    },
    query() {
      return {
        accessLevel: this.accessLevel,
        orgGroupIds: this.orgGroupIds?.join() || undefined,
        orgIds: this.orgIds?.join() || undefined,
        includeGuests: this.includeGuests,
      };
    },
  },

  methods: {
    ...mapActions('STRUCTURES', {
      fetcher: 'fetchPointPreviews',
      fetcherBy: 'fetchPointPreviewsBy',
    }),

    getItemText(item) {
      return item.address || '#' + item.id;
    },
  },
};
</script>
