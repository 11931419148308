<template>
  <v-menu
    ref="menu"
    transition="scale-transition"
    top
    offset-y
    origin="bottom left"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-bind="attrs"
        label="Период"
        :value="relativeRangeText"
        readonly
        required
        persistent-hint
        hide-details="auto"
        outlined
        :append-icon="$vuetify.icons.values.calendar"
        :error-messages="errorMessages"
        :disabled="disabled"
        v-on="on"
        @click:clear="clear"
      />
    </template>

    <v-card class="pa-4">
      <div class="text-caption mb-2">
        Отсчитывается от старта выполнения задачи и по часовому поясу задачи
      </div>

      <v-alert v-if="errorMsg" type="error" outlined dense>
        {{ errorMsg }}
      </v-alert>

      <div class="date-range-picker__start">
        <div class="mb-2 text-subtitle-2">Начало выгрузки</div>
        <v-checkbox v-model="isAbsoluteDate.start" label="Задать точную дату" />

        <div class="date-range-picker__start__input">
          <DatePickerWithTime
            v-if="isAbsoluteDate.start"
            v-model="innerValue.dateStart"
            dense
            outlined
          />
          <RelativeControl
            v-else
            ref="relativeStartInput"
            v-model="innerValue.dateStart"
          />
        </div>
      </div>

      <v-divider class="my-2" />

      <div class="date-range-picker__end">
        <div class="mb-2 text-subtitle-2">Окончание выгрузки</div>
        <v-checkbox v-model="isAbsoluteDate.end" label="Задать точную дату" />

        <div class="date-range-picker__end__input">
          <DatePickerWithTime
            v-if="isAbsoluteDate.end"
            v-model="innerValue.dateEnd"
            dense
            outlined
            is-end
          />
          <RelativeControl
            v-else
            ref="relativeEndInput"
            v-model="innerValue.dateEnd"
          />
        </div>
      </div>

      <div class="text-center">
        <v-btn text color="primary" @click="save">Применить</v-btn>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { DateTime } from '@/plugins/luxon';
import { dateTime, setZoneWithoutChangeTime } from '@/utils/convert';
import { formatRelativeDate } from '@/modules/scheduler/convert';
import DatePickerWithTime from '@/components/controls/DatePickerWithTime';
import RelativeControl from './RelativeControl';

export default {
  components: { RelativeControl, DatePickerWithTime },
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: Boolean,
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },
  },

  data: () => ({
    innerValue: {
      dateStart: null,
      dateEnd: null,
    },

    isAbsoluteDate: {
      start: false,
      end: false,
    },

    // Сообщение при ошибке порядка дат начала/окончания выгрузки
    errorMsg: null,
  }),

  computed: {
    relativeRangeText() {
      const { dateStart, dateEnd } = this.value;

      if (!dateStart || !dateEnd) return '';

      const startText =
        typeof dateStart === 'string'
          ? dateTime(dateStart, {
              setZone: true,
              showSeconds: true,
              showMilliseconds: true,
            })
          : formatRelativeDate(dateStart);

      const endText =
        typeof dateEnd === 'string'
          ? dateTime(dateEnd, {
              setZone: true,
              showSeconds: true,
              showMilliseconds: true,
            })
          : formatRelativeDate(dateEnd);

      return [startText, endText].join(' – ');
    },
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.innerValue.dateStart = val.dateStart;
        this.innerValue.dateEnd = val.dateEnd;

        if (typeof val.dateStart === 'string') this.isAbsoluteDate.start = true;
        if (typeof val.dateEnd === 'string') this.isAbsoluteDate.end = true;
      },
    },

    isAbsoluteDate: {
      deep: true,
      handler(val, old) {
        if (val.start !== old.start) this.innerValue.dateStart = null;
        if (val.end !== old.end) this.innerValue.dateEnd = null;
      },
    },
  },

  methods: {
    save() {
      const [start, end] = [
        this.$refs.relativeStartInput,
        this.$refs.relativeEndInput,
      ];

      if ((start && !start.validate()) || (end && !end.validate())) return;
      const isChecked = this.checkDateOrder(this.innerValue);
      if (!isChecked) {
        return;
      }

      this.$emit('input', this.innerValue);
      this.$refs.menu.save(this.innerValue);
    },

    clear() {
      this.innerValue = {};
      this.$emit('input', {});
      this.$refs.menu.save(null);
    },

    checkDateOrder({ dateStart, dateEnd }) {
      this.errorMsg = null;

      // Если искомая дата задана относительно, а вторая абсолютно,
      // отсчет нужно вести от абсолютной даты для избежания проблем с соответствием в таймзонах
      const formattingDateStart = this.getDateTimeFormat(dateStart, dateEnd);
      const formattingDateEnd = this.getDateTimeFormat(dateEnd, dateStart);
      if (!formattingDateStart || !formattingDateEnd) {
        this.errorMsg = 'Проверьте корректность заполнения полей';
        return false;
      }
      const diff = formattingDateEnd
        .diff(formattingDateStart, 'hours')
        .toObject();
      if (diff.hours <= 0) {
        this.errorMsg = 'Нельзя указать дату начала позже даты окончания';
        return false;
      }
      return true;
    },

    /**
     * Возвращает конкретную дату в формате DateTime
     * из выбранной пользователем даты (точной или относительной)
     */
    getDateTimeFormat(date, fromDate = null) {
      if (!date) return null;
      if (typeof date === 'string') {
        return setZoneWithoutChangeTime(date, 0);
      }

      // если fromDate передана как абсолютная дата
      // ведем отсчет от нее
      // иначе берем текущее время
      let formatDate =
        fromDate && typeof fromDate === 'string'
          ? setZoneWithoutChangeTime(fromDate, 0)
          : DateTime.now();
      if (date.start !== 'NOW') {
        // Если начало отсчета не "Прямо сейчас", то оно записывается в формате START_OF_DAY, END_OF_MONTH и тд
        // Нам необходимо понять, какую функцию (startOf/endOf) и с каким параметром (day/week/month) применять
        const startArr = date.start.split('_');
        const funcName = startArr[0] === 'START' ? 'startOf' : 'endOf';
        const paramVal = startArr[2].toLowerCase();
        formatDate = formatDate[funcName](paramVal);
      }
      // Применим смещение
      formatDate = formatDate.plus({ [date.offset.units]: date.offset.value });

      return formatDate;
    },
  },
};
</script>
