<template>
  <div class="medcab__passive__inspection" style="width: 100%">
    <Spinner v-if="!isLoadedInspection" />
    <template v-else>
      <InspectionHeader v-if="isLoadedInspection" />
      <InspectionBody v-if="isLoadedInspection" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InspectionHeader from './InspectionHeader.vue';
import InspectionBody from './InspectionBody.vue';
import Spinner from '@/components/Spinner.vue';

export default {
  components: {
    InspectionHeader,
    InspectionBody,
    Spinner,
  },

  computed: {
    ...mapGetters('MEDCAB_INSPECTION', ['isLoadedInspection']),
  },
};
</script>

<style lang="scss" scoped>
.loader__container {
  display: flex;
  width: 100%;
  height: calc(100vh - 350px);
  align-items: center;
  justify-content: center;
}
</style>
