import bansApi from '@/api/services/bans';
import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

const state = {
  reasons: [],
  unbanConditions: [],
};

const getters = {
  reasons: state => state.reasons,
  unbanConditions: state => state.unbanConditions,
};

const mutations = {
  reasons: (state, val) => (state.reasons = val),
  unbanConditions: (state, val) => (state.unbanConditions = val),
};

const actions = {
  async fetchReasons({ commit }) {
    const data = await bansApi.getReasons();
    commit('reasons', data);
  },

  async fetchUnbanConditions({ commit }) {
    const data = await bansApi.getUnbanConditions({
      limit: 10000,
      page: 1,
    });
    commit('unbanConditions', data);
  },

  async createSingleItem({ dispatch }, data) {
    const response = await bansApi.profileCreate(data);
    dispatch('pushLinkedList', {
      action: 'listAddItem',
      payload: response,
    });
    return response;
  },
};

export default createModule(
  crudSingleFactory({
    fetchMethod: bansApi.profileGet,
    changeMethod: bansApi.profileChange,
    deleteMethod: bansApi.profileDelete,
    linkedList: 'ADMIN/BANS',
  }),
  { state, getters, mutations, actions },
);
