var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EmployeeList',{attrs:{"search-filter":"","available-actions":{
    showAction: _vm.$can('EMP_RD'),
    createAction: _vm.$can('EMP_C') && !_vm.createForbidden,
    importAction: _vm.$can('EMP_I') && !_vm.importForbidden,
  }},on:{"item:create":_vm.openCreateEmployee},scopedSlots:_vm._u([{key:"header.actions",fn:function(){return [(_vm.$can('EMP_CT') && !_vm.createForbidden)?_c('ListActionBtn',{attrs:{"label":"Добавить тестового","icon":"mdi-account-multiple-plus-outline","color":"success"},on:{"click":_vm.createTestEmployee}}):_vm._e(),(_vm.showUpdateRisks)?_c('ListActionBtn',{attrs:{"label":"Пересчитать риски","icon":"mdi-calculator"},on:{"click":_vm.updateRisks}}):_vm._e()]},proxy:true},{key:"filters",fn:function(query){return [_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-select',{attrs:{"label":"Статус работников","items":_vm.statusItems,"item-value":"value","item-text":"name","hide-details":"","outlined":"","dense":"","menu-props":{ offsetY: true }},model:{value:(query.isEnabled),callback:function ($$v) {_vm.$set(query, "isEnabled", $$v)},expression:"query.isEnabled"}})],1)]}},{key:"import.prepend",fn:function(ref){
  var data = ref.data;
  var getValidationErrors = ref.getValidationErrors;
return [_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('OrganizationSelectImport',{attrs:{"predefined-value":_vm.org.id,"error-messages":getValidationErrors('data.orgId'),"dense":""},model:{value:(data.orgId),callback:function ($$v) {_vm.$set(data, "orgId", $$v)},expression:"data.orgId"}})],1)]}},{key:"table.item.name_gender_birthDay",fn:function(ref){
  var item = ref.item;
return [_c('b',[_c('EmployeeName',{attrs:{"value":item}})],1),_vm._v(" "+_vm._s(_vm.GENDER_NOMINATIVE[item.gender])+", "+_vm._s(_vm.date(item.dateOfBirth))+" ")]}},{key:"table.item.isEnabled",fn:function(ref){
  var item = ref.item;
return [_c('Indicator',{attrs:{"active":item.isEnabled,"tooltip":item.isEnabled ? 'Активен' : 'Неактивен'}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }