<template>
  <List
    :available-actions="{ showAction: $can('ODC_RD') }"
    @filters:resetHook="resetHook"
  >
    <template #filters="query">
      <v-col cols="12" sm="4" md="3">
        <SearchEmployee
          ref="empSearch"
          v-model="query.employeeIds"
          store="DOCUMENTS/OUTGOING"
        />
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <Select
          v-model="query.types"
          label="Тип документов"
          :items="outgoingDocumentTypes"
          dense
          multiple
          item-value="key"
        />
      </v-col>
    </template>
  </List>
</template>

<script>
import { entity, filters } from '../entity';
import ListFactory from '@/components/crud/ListFactory';

import { mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';

import Select from '@/components/controls/Select';
import SearchEmployee from '@/modules/inspections/components/controls/SearchEmployee.vue';

export const messages = {
  resourceName: 'исходящих документов',
  searchFilterLabel: 'Поиск по исходящим документам',
};

const List = ListFactory({
  storeModule: 'DOCUMENTS/OUTGOING',
  messages,
  entity,
  filters,
  viewRoute: 'documents:outgoing:item',
});

export default {
  components: { List, Select, SearchEmployee },
  extends: waitable,
  data() {
    return {};
  },
  computed: {
    ...mapGetters('REGISTRY', ['outgoingDocumentTypes']),
  },

  created() {
    this.$loadingNotify(
      this.$store.dispatch('REGISTRY/fetchOutgoingDocumentTypes'),
      'fetchTypes',
      'Произошла ошибка загрузки типов документов',
    );
  },

  methods: {
    resetHook() {
      this.$refs.empSearch.clear();
    },
  },
};
</script>
