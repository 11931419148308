<template>
  <v-tooltip right>
    <template #activator="{ on }">
      <div v-on="on">
        <Round :type="active ? activeColor : passiveColor" />
      </div>
    </template>
    <span>{{ computedTooltip }}</span>
  </v-tooltip>
</template>

<script>
import Round from '@/components/Round.vue';

export default {
  components: { Round },
  props: {
    // active is not necessarily a 'positive' thing, it also can indicate
    // negative stuff
    active: { type: Boolean, required: true },
    // essentially a color class
    activeColor: { type: String, default: 'green lighten-2' },
    // essentially a color class
    passiveColor: { type: String, default: 'red' },
    tooltip: { type: String, default: null },
    hideTooltip: { type: Boolean, default: false },
  },

  computed: {
    computedTooltip() {
      if (this.hideTooltip) return null;
      if (this.tooltip) return this.tooltip;
      // most commonly tooltip value passed
      return this.active ? 'Активна' : 'Неактивна'; // 'default value'
    },
  },
};
</script>
