<template>
  <ModernModalLayout
    size="medium"
    title="Редактирование Email"
    close-label="Отмена"
    :watch-for="data"
  >
    <v-text-field
      ref="email"
      v-model="data.value"
      label="Email"
      hide-details="auto"
      outlined
      :disabled="!$can('EML_S')"
      :error-messages="getValidationErrors('data.value')"
      @blur="validateField('data.value')"
    />
    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :disabled="$wait('changingEmail')"
        @click="onSubmit"
      >
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import { required, email } from 'vuelidate/lib/validators';

export default {
  components: { ModernModalLayout },

  mixins: [validation, waitable],

  props: {
    id: { type: Number, required: true },
    email: { type: String, required: true },
  },

  data: () => ({
    data: {
      value: '',
    },
  }),

  created() {
    this.data.value = this.email;
  },

  validations() {
    return {
      data: {
        value: { required, email },
      },
    };
  },

  methods: {
    async onSubmit() {
      if (!this.$refs.email.validate()) return;

      await this.$loadingNotify(
        this.$store
          .dispatch('ADMIN/USERS_ITEM/changeEmail', this.data.value)
          .then(() => this.$emit('close')),
        'changingEmail',
        'Ошибка изменения email пользователя ' + this.data.value,
        `Email пользователя ${this.data.value} успешно изменен`,
      );
    },
  },
};
</script>
