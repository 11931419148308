<template>
  <ItemPageLayout
    back-route="structures:employee_groups"
    :title="singleItem.name"
  >
    <Card title="Данные группы" dense>
      <DefinitionList :value="computedEntity" show-empty-values />
      <template #action>
        <v-btn
          v-if="$can('EMPG_U')"
          depressed
          small
          text
          color="primary"
          @click="editModal"
        >
          Редактировать
        </v-btn>
      </template>
    </Card>

    <template #aside>
      <ActionsMenu>
        <ActionsMenuDelete
          v-if="$can('EMPG_D')"
          :id="singleItem.id"
          store-module="STRUCTURES/EMPLOYEE_GROUPS"
          back-route="structures:employee_groups"
          title="Удалить группу работников"
          description="Вы уверены, что хотите удалить группу?"
          :disabled="!!singleItem.itemsCount"
          :subtitle="
            singleItem.itemsCount
              ? 'Нельзя удалить, в группе есть работники'
              : null
          "
        />
      </ActionsMenu>
    </template>

    <template v-if="$can('EMP_R')" #innerList>
      <List
        :available-actions="{
          showAction: $can('EMP_RD'),
          createAction: $can('EMP_C'),
        }"
        @item:create="openCreate"
      >
        <template #filters="query">
          <v-col cols="12" sm="4" md="3">
            <SearchEmployee
              ref="empSearch"
              :value="query.search"
              store="STRUCTURES/EMPLOYEE_GROUPS_ITEM"
              :org-ids="[singleItem.organization.id]"
              :group-ids="[singleItem.id]"
              :disabled="listIsLoading"
              :loading="listIsLoading"
            />
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-select
              v-model="query.isEnabled"
              label="Статус работников"
              :items="statusItems"
              item-value="value"
              item-text="name"
              hide-details
              outlined
              dense
              :menu-props="{ offsetY: true }"
            />
          </v-col>
        </template>

        <template #table.item.name_gender_birthDay="{ item }">
          <b><EmployeeName :value="item" /></b>
          {{ GENDER_NOMINATIVE[item.gender] }}, {{ date(item.dateOfBirth) }}
        </template>

        <template #table.item.isEnabled="{ item }">
          <Indicator
            :active="item.isEnabled"
            :tooltip="item.isEnabled ? 'Активен' : 'Неактивен'"
          />
        </template>
      </List>
    </template>
  </ItemPageLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';
import { date } from '@/utils/convert';
import { STATUS, GENDER_NOMINATIVE } from '@/utils/constants.js';

// components go below
import Indicator from '@/components/Indicator.vue';
import EmployeeName from '@/modules/employees/components/EmployeeName';
import ItemPageLayout from '@/components/layouts/ItemPageLayout';
import Card from '@/components/ui/Card';
import ActionsMenu from '@/components/ui/ActionsMenu';
import DefinitionList from '@/components/DefinitionList';
import ActionsMenuDelete from '@/components/ActionsMenuDelete';
import LinkButton from '@/components/controls/buttons/LinkButton';
import SearchEmployee from '@/modules/inspections/components/controls/SearchEmployee.vue';

import { entity } from '../entity';

// List section code
import ListFactory from '@/components/crud/ListFactory';
import {
  messages,
  entityEmbedded as embedEntity,
  filters,
} from '@/modules/employees/entity';

const List = ListFactory({
  storeModule: 'STRUCTURES/EMPLOYEE_GROUPS_EMPLOYEE_LIST',
  messages,
  entity: embedEntity.filter(val => val.key !== 'groups'),
  filters,
  viewRoute: 'employees:card',
  embedded: true,
});

export default {
  components: {
    ItemPageLayout,
    DefinitionList,
    Card,
    ActionsMenu,
    ActionsMenuDelete,

    List,
    Indicator,
    EmployeeName,
    SearchEmployee,
  },
  mixins: [routeGuardMixin],

  computed: {
    ...mapGetters('STRUCTURES/EMPLOYEE_GROUPS_EMPLOYEE_LIST', [
      'listItems',
      'listIsLoading',
    ]),

    GENDER_NOMINATIVE: () => GENDER_NOMINATIVE,

    computedEntity() {
      if (!this.singleItem) return [];

      const table = entity
        .filter(el => !el.hideInCard)
        .map(({ key, label, convert }) => ({
          label,
          value: convert ? convert(this.singleItem) : this.singleItem[key],
        }));

      const org = this.singleItem?.organization;
      org &&
        table.push({
          label: 'Организация',
          component: LinkButton,
          props: {
            text: org.name || `#${org.id}`,
            action: this.$can('ORG_RD'),
            to: {
              name: 'structures:organizations:item',
              params: { id: org.id },
            },
          },
        });

      return table;
    },

    statusItems: () => STATUS,
  },

  beforeMount() {
    // we need to reset filters on each 'item page' mount, cut those are being
    // saved in store
    this.resetListFilters();
  },

  methods: {
    ...mapActions('STRUCTURES/EMPLOYEE_GROUPS_EMPLOYEE_LIST', [
      'resetListFilters',
      'createListItem',
    ]),
    ...mapActions('STRUCTURES/EMPLOYEE_GROUPS_ITEM', ['fetchSingle']),

    date,
    editModal() {
      this.$openModal('structures/employeeGroupForm', {
        id: this.singleItem.id,
        value: this.singleItem,
      });
    },

    openCreate() {
      this.$openModal('employees/form', {
        // object will b like {id: undefined, name: undefined ...}
        item: Object.fromEntries(embedEntity.map(field => [field.key])),
        entity: embedEntity,
        messages: messages.create,
        fetchOrganizationSettings: id =>
          this.$store.dispatch('EMPLOYEE_LIST/fetchOrganizationSettings', id),
        predefinedValues: {
          orgId: this.singleItem.organization.id,
          groupIds: [this.singleItem.id],
        },
        onSubmit: data =>
          this.createListItem(data).then(() =>
            this.fetchSingle(this.singleItem.id),
          ),
      });
    },
  },
};
</script>
