<template>
  <div>
    <v-alert v-if="isHasResolution" text type="error" dense outlined>
      Этот документ уже был подтвержден другим медицинским работником.
    </v-alert>
    <AsyncWrapper :handler="fetch">
      <div>
        <ColumnLayout>
          <template #col-form>
            <EmployeePreviewProfile :value="employee" class="mb-3" />

            <v-divider class="mb-5" />
            <div
              v-if="document.comments && document.comments.length"
              class="mb-5"
            >
              <div class="text-body-2 py-2">Комментарии:</div>
              <Spinner v-if="$wait('accountPreviewsLoading')" />
              <div v-else>
                <CommentItem
                  v-for="(comment, index) in document.comments"
                  :key="index"
                  :account-previews="accounts"
                  :value="comment"
                />
              </div>
            </div>

            <v-form :disabled="isHasResolution">
              <v-autocomplete
                v-model="data.type"
                label="Тип"
                class="mt-2"
                outlined
                dense
                item-text="name"
                item-value="key"
                :items="incomingDocumentTypes"
                :error-messages="getValidationErrors('data.type')"
                @blur="validateField('type')"
              />
              <v-text-field
                v-model="data.name"
                label="Название документа"
                dense
                outlined
                :error-messages="getValidationErrors('data.name')"
                @blur="validateField('data.name')"
              />
              <v-text-field
                v-model="data.issuedBy"
                label="Кем выдан"
                dense
                outlined
                :error-messages="getValidationErrors('data.issuedBy')"
                @blur="validateField('data.issuedBy')"
              />
              <DatePicker
                v-model="data.issuedAt"
                label="Выдан"
                dense
                outlined
                :max="maxIssuedAt"
                :error-messages="getValidationErrors('data.issuedAt')"
                @blur="validateField('data.issuedAt')"
              />
              <Select
                v-model="data.dueDate"
                class="mb-3"
                :items="periods"
                dense
                item-text="text"
                item-value="value"
                hide-details="auto"
                label="Срок действия:"
                :error-messages="getValidationErrors('data.dueDate')"
                @blur="handleFieldBlur('data.dueDate')"
              />
              <v-text-field
                v-if="needsDiagnosis"
                v-model="data.diagnosis"
                class="mt-2"
                label="Диагноз"
                dense
                outlined
                :error-messages="getValidationErrors('data.diagnosis')"
                @blur="validateField('data.diagnosis')"
              />
              <v-checkbox
                v-if="data.type === 'HEALTH_CERTIFICATE'"
                v-model="data.includedInRiskGroup"
                label="Учитывать в группах риска"
                class="ma-0 ml-3"
              />
              <v-textarea
                v-model.trim="data.comment"
                rows="3"
                class="mt-2"
                label="Ответ клиенту"
                dense
                outlined
                :error-messages="getValidationErrors('data.comment')"
                @blur="validateField('data.comment')"
              />
            </v-form>
          </template>
          <template #col-images>
            <div style="min-height: 70vh; position: relative">
              <div style="width: 100%; position: absolute">
                <ImagePreview
                  v-for="(scan, index) in document.scans"
                  :key="scan"
                  :url="scan"
                >
                  <template v-if="!isHasResolution">
                    <v-btn
                      color="primary"
                      text
                      small
                      @click="handleEditScan(index)"
                    >
                      Редактировать
                    </v-btn>
                    <v-divider vertical class="mx-2" />
                  </template>

                  <v-btn
                    color="primary"
                    text
                    small
                    :href="scan"
                    target="_blank"
                  >
                    Скачать
                  </v-btn>
                </ImagePreview>
              </div>
            </div>
          </template>
        </ColumnLayout>

        <div class="approve-incoming-form__buttons mt-5">
          <v-btn
            v-if="$can('DOC_DN')"
            large
            color="error"
            :disabled="isHasResolution"
            :loading="$wait('handleDocument')"
            @click="handleDecline"
          >
            Отклонить
          </v-btn>
          <v-btn
            v-if="$can('DOC_AP')"
            large
            color="success"
            :disabled="isHasResolution"
            :loading="$wait('handleDocument')"
            @click="handleApprove"
          >
            Подтвердить
          </v-btn>
        </div>
      </div>
    </AsyncWrapper>
  </div>
</template>

<script>
import validationMixin from '@/utils/validation';
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import { shortName } from '@/utils/convert';
import plural from 'plural-ru';
import { diffYears, currentDateTime, parseDate } from '@/utils/helpers';

import ColumnLayout from '@/components/layouts/ColumnLayout.vue';
import AsyncWrapper from '@/components/AsyncWrapper.vue';

import ImagePreview from '@/components/ImagePreview.vue';
import DatePicker from '@/components/controls/DatePicker.vue';
import EmployeePreviewProfile from '@/modules/employees/components/PreviewProfile';
import CommentItem from '@/modules/employees/components/CommentItem.vue';
import Spinner from '@/components/Spinner';
import Select from '@/components/controls/Select.vue';

import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';

export default {
  components: {
    AsyncWrapper,
    ColumnLayout,
    ImagePreview,
    DatePicker,
    Spinner,
    CommentItem,
    EmployeePreviewProfile,
    Select,
  },
  mixins: [validationMixin, waitable],

  props: {
    document: { type: Object, required: true },
    employee: { type: Object, required: true },
  },

  data: () => ({
    data: {},
    action: 'approve',
    accounts: {},
  }),

  computed: {
    ...mapGetters('REGISTRY', ['incomingDocumentTypes']),

    needsDiagnosis() {
      return this.incomingDocumentTypes.find(
        item => item.key === this.data.type,
      )?.needsDiagnosis;
    },

    isHasResolution() {
      return !!this.document.resolution;
    },

    maxIssuedAt() {
      return currentDateTime().toFormat('yyyy-MM-dd');
    },

    periods() {
      const res = [{ text: 'Бессрочно', value: 0 }];
      for (let i = 1; i <= 5; i++)
        res[i] = { text: `${i} ${plural(i, 'год', 'года', 'лет')}`, value: i };
      return res;
    },
  },

  watch: {
    'data.type': {
      handler() {
        this.data.includedInRiskGroup = null;
      },
    },
  },

  mounted() {
    this.clearCachedAccs();
  },

  validations() {
    return {
      data: {
        type: { required },
        comment: {
          required: requiredIf(() => this.action === 'decline'),
          maxSymbolsLength: maxLength(1000),
        },
        name: {
          required: requiredIf(() => this.action === 'approve'),
          maxSymbolsLength: maxLength(100),
        },
        issuedAt: { required: requiredIf(() => this.action === 'approve') },
        issuedBy: {
          required: requiredIf(() => this.action === 'approve'),
          maxSymbolsLength: maxLength(150),
        },
        dueDate: { required: requiredIf(() => this.action === 'approve') },
        diagnosis: {
          required: requiredIf(
            () => this.action === 'approve' && this.needsDiagnosis,
          ),
          maxSymbolsLength: maxLength(500),
        },
      },
    };
  },

  methods: {
    ...mapActions('REGISTRY', ['fetchIncomingDocumentTypes']),
    ...mapActions('AUTH_PREVIEWS', [
      'clearCachedAccs',
      'fetchAccountPreviewsBy',
    ]),
    ...mapActions('DOCUMENTS/INCOMING_ITEM', ['approve', 'decline', 'update']),

    async fetch() {
      await this.fetchIncomingDocumentTypes();
      this.data = {
        ...this.document,
        dueDate: !this.document.dueDate
          ? 0
          : diffYears(this.document.issuedAt, this.document.dueDate),
      };

      // NOTE: for now this 'shitcode' is due to our (frontenders) completely
      // broken mood cuz of our backenders' desisions.. So.. Instead of fetching
      // those account previews in respective stores and caching 'em there we
      // fetch those in this component (which is logically wrong) and store that
      // info here locally, which means even more frequent backend structures
      // service abuse
      const accountIds = [
        ...new Set(
          (this.document.comments || [])
            .filter(comment => comment.author)
            .map(comment => comment.author),
        ),
      ];

      accountIds.length &&
        (await this.$loadingNotify(
          this.fetchAccountPreviewsBy(accountIds).then(res => {
            res.forEach(
              account =>
                (this.accounts[account.id] = shortName(account.profile)),
            );
          }),
          'accountPreviewsLoading',
          'Произошла ошибка получения информации о работниках',
          '',
        ));
    },

    handleEditScan(index) {
      this.$openModal('imageEditor', {
        value: this.document.scans[index],
        change: async newImage => {
          const scansBinary = await Promise.all(
            this.document.scans.map(getBase64Image),
          );
          const scans = [
            ...scansBinary.slice(0, index),
            newImage,
            ...scansBinary.slice(index + 1),
          ];
          const params = {
            id: this.data.id,
            data: { scans },
          };

          await this.$loadingNotify(
            this.update(params),
            'updateDocument',
            'Ошибка при изменении документа',
            'Документ успешно изменен',
          );
        },
      });
    },

    async handleApprove() {
      this.action = 'approve';
      if (!this.validate()) return;

      const dueDate =
        this.data.dueDate > 0
          ? parseDate(this.data.issuedAt)
              .plus({ years: this.data.dueDate })
              .toFormat('yyyy-MM-dd')
          : null;

      const diagnosis = this.needsDiagnosis ? this.data.diagnosis : undefined;

      await this.$loadingNotify(
        this.approve({
          id: this.document.id,
          data: {
            type: this.data.type,
            name: this.data.name,
            issuedAt: this.data.issuedAt,
            issuedBy: this.data.issuedBy,
            includedInRiskGroup: this.data.includedInRiskGroup,
            dueDate,
            diagnosis,
            comment: this.data.comment,
          },
        }),
        'handleDocument',
        'Ошибка подтвержения документа',
        'Документ успешно подтвержден',
      );
    },

    handleDecline() {
      this.action = 'decline';
      if (!this.validate()) return;

      this.$loadingNotify(
        this.decline({
          id: this.document.id,
          data: { comment: this.data.comment },
        }),
        'handleDocument',
        'Ошибка отклонения документа',
        'Документ успешно отклонен',
      );
    },
  },
};

// FIXME: temporary solution for demo, until sergey corrects api
function getBase64Image(url) {
  return fetch(url).then(response => response.blob());
}
</script>
<style scoped lang="scss">
.approve-incoming-form__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}
</style>
