import Vue from 'vue';

// api
import processingApi from '@/api/services/processing';
import authApi from '@/api/services/auth';
import signApi from '@/api/services/sign';
import structApi from '@/api/services/structures';

import crudListFactory from '@/utils/vuex/crudListFactory';
import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';
import { unmaskPhone } from '@/utils/masks';
import { xhrErrorMessage } from '@/utils/helpers';
import { diagnosticTheMedic, convertMedics } from './convert';
import { filters } from './entity';
import { changePages } from '@/utils/vuex/changePages';

const state = {
  medicalRoles: null,
  medicalOrgs: null,
  orgCategories: null,
  role: null,
};

const getters = {
  medicalRoles: state => state.medicalRoles || [],
  medicalOrgs: state => state.medicalOrgs || [],
  orgCategories: state => state.orgCategories || [],

  findOrgById: state => id =>
    (state.medicalOrgs || []).find(el => el.id === id),

  medicalRoleByKey: (_, getters) => key =>
    getters.medicalRoles.find(role => role.key === key),
};

const updateItem = (state, value) => {
  value.diagnostic = diagnosticTheMedic(value);
  state.singleItem = value;

  const index = state.listItems.findIndex(item => item.id === value.id);
  if (index === -1) return;

  const medic = { ...state.listItems[index], ...value };
  state.listItems.splice(index, 1, medic);
};

const mutations = {
  medicalRoles: (state, value) => (state.medicalRoles = value),
  medicalOrgs: (state, value) => (state.medicalOrgs = value),
  addMedicalOrg: (state, value) =>
    state.medicalOrgs
      ? state.medicalOrgs.push(value)
      : (state.medicalOrgs = [value]),
  orgCategories: (state, value) => (state.orgCategories = value),

  updateItem,

  updateRole: (state, roleKey) => {
    Vue.set(state.singleItem.medicData, 'roleKey', roleKey);
    updateItem(state, state.singleItem);
  },

  changeGroups: (state, groups) => {
    Vue.set(state.singleItem.medicData, 'groups', groups);
    updateItem(state, state.singleItem);
  },

  changeCategories: (state, categories) => {
    Vue.set(state.singleItem.medicData, 'categories', categories);
    updateItem(state, state.singleItem);
  },

  addMedicCertificate: (state, cert) => {
    state.singleItem.certificates.push(cert);
    updateItem(state, state.singleItem);
  },
  removeMedicCertificate: (state, certId) => {
    const cert = state.singleItem.certificates.findIndex(
      ({ id }) => id === certId,
    );
    Vue.delete(state.singleItem.certificates, cert);
    updateItem(state, state.singleItem);
  },

  role: (state, value) => (state.role = value),
};

const actions = {
  async fetchMedicalRolesPreviews({ state, commit }) {
    if (state.medicalRoles) return;
    const response = await authApi.fetchRolesPreviews({ kind: 'medical' });
    commit('medicalRoles', response);
  },

  async fetchRole({ commit, getters }) {
    const role = await authApi.getRole(getters.singleItem.accountData.roleKey);
    commit('role', role);
  },

  async fetchMedicalOrgsPreviews({ commit }, ids = []) {
    const response = ids.length
      ? await structApi.getOrganizationPreviewsBy(ids)
      : null;
    commit('medicalOrgs', response);
  },

  async fetchMedOrg({ state, commit }, id) {
    const medOrg = state.medicalOrgs?.find(medOrg => medOrg.id === id) || null;
    // Если организации нет в ранее запрошенном списке
    // запросим ее и добавим в список
    if (!medOrg) {
      const response = await structApi.getOrganizationPreviewsBy([id]);
      response && response.length && commit('addMedicalOrg', response[0]);
    }
  },

  async fetchOrgCategoryPreviews({ state, commit }) {
    if (state.orgCategories) return;
    const response = await structApi.getCategoryPreviews({
      limit: 1000,
      page: 1,
    });
    commit('orgCategories', response.items);
  },

  async fetchList({ commit, getters, dispatch }, query) {
    await changePages(getters, commit, query);

    try {
      commit('listFetching');
      const medics = await processingApi.medicList(getters.listQuery);
      medics.items = await convertMedics(medics.items);

      // Дополнительно запросим данные только по привязанным к текущему списку работников мед.организациям
      const orgIds = [
        ...new Set(medics.items?.map(item => item.medicData.orgId) || []),
      ];
      dispatch('fetchMedicalOrgsPreviews', orgIds);

      commit('listFetched', medics);
    } catch (error) {
      console.error(error);
      commit('listErrorFetched', error);
      throw error;
    }
  },

  async fetchSingle({ commit }, medicId) {
    try {
      commit('singleFetching');
      const data = await processingApi.medicGet(medicId);
      const [medicItem] = await convertMedics([data]);
      commit('singleFetched', medicItem);
      return medicItem;
    } catch (err) {
      commit('singleErrorFetched', err);
      throw err;
    }
  },

  async createItem({ commit, dispatch }, data) {
    const medic = await processingApi.medicCreate(preparedData(data));
    try {
      await authApi.createMedic(medic.id);
    } catch (err) {
      console.error(err);
      const errorForm = err?.response?.data?.inner
        ?.reduce((acc = [], el) => [...acc, ...Object.values(el.errors)], [])
        .join('; ');

      Vue.prototype.$notify({
        group: 'note',
        type: 'error',
        title: 'Не удалось создать аккаунт для медработника',
        text: errorForm || xhrErrorMessage(err),
        delay: 10000,
      });
    }

    const [medicModified] = await convertMedics([medic]);

    // Дополнительно запросим данные по мед.организации
    dispatch('fetchMedOrg', medicModified.medicData.orgId);

    commit('listAddItem', medicModified);

    return medicModified;
  },

  async updateItem({ getters, dispatch, commit }, data) {
    const oldEmail = getters.singleItem?.medicData?.email;

    if (oldEmail !== data.email) {
      await dispatch('changeMedicEmail', {
        medicId: data.id,
        email: data.email,
      });
    }

    const medic = await processingApi.medicUpdate(preparedData(data));
    const [medicModified] = await convertMedics([medic]);

    if (getters.singleItem?.accountData?.bindings)
      medicModified.accountData.bindings =
        getters.singleItem?.accountData?.bindings;

    commit('updateItem', medicModified);

    return medicModified;
  },

  async refreshMedicOnList({ commit }, medicId) {
    const data = await processingApi.medicGet(medicId);
    const [medicItem] = await convertMedics([data]);

    commit('updateItem', medicItem);
  },

  async updateGroups({ commit }, { id, groupIds }) {
    const { groups } = await processingApi.medicUpdateGroups(id, groupIds);
    commit('changeGroups', groups);
  },

  async updateCategories({ commit }, { id, categoryIds }) {
    const { categories } = await processingApi.medicUpdateCategories(
      id,
      categoryIds,
    );
    commit('changeCategories', categories);
  },

  async updateRole({ commit }, { id, roleKey }) {
    const data = await processingApi.medicUpdateRole(id, roleKey);
    commit('updateRole', data.roleKey);
  },

  async changeStatus({ getters, commit }, { id, isEnabled }) {
    const action = isEnabled ? 'medicEnable' : 'medicDisable';

    await processingApi[action](id);
    getters.singleItem.medicData.isEnabled = isEnabled;

    commit('updateItem', getters.singleItem);
  },

  async changeMedicEmail({ commit, getters }, { medicId, email }) {
    await authApi.changeMedicEmail(medicId, email);
    const medic = getters.listItems.find(el => el.id === medicId);
    if (!medic) return;

    if (medic?.accountData)
      medic.accountData = {
        ...medic.accountData,
        email,
      };

    commit('updateItem', { ...medic });
  },

  async recreateAccount({ dispatch, commit }, { medicId }) {
    await authApi.createMedic(medicId);
    await processingApi.medicGet(medicId);

    const medic = await dispatch('fetchSingle', medicId);
    commit('updateItem', medic);
  },

  async addCertificate({ commit }, data) {
    const params = { ...data };
    delete params.medicId;

    const cert = await signApi.addCertificate(params);
    await commit('addMedicCertificate', cert);
  },

  async removeCertificate({ commit }, { certificateId } = {}) {
    await signApi.removeCertificate(certificateId);
    await commit('removeMedicCertificate', certificateId);
  },

  resetMedicPassword(_, medicId) {
    return authApi.resetMedicPassword(medicId);
  },

  async changeMedicOrganization({ dispatch }, { id, params }) {
    await processingApi.changeMedicOrganization(id, params);
    // Запросим данные новой организации
    dispatch('fetchMedOrg', params.orgId);
    await dispatch('refreshMedicOnList', id);
  },
};

function preparedData(data) {
  const params = { ...data };
  return {
    ...params,
    phone: unmaskPhone(params.phone),
  };
}

export default createModule(
  crudListFactory({
    fetchMethod: authApi.accountList,
    createMethod: authApi.accountCreate,
    updateMethod: authApi.accountUpdate,
    deleteMethod: processingApi.medicDelete,
    filters,
  }),
  crudSingleFactory({
    linkedList: 'MEDBLOCK/MEDICS',
    updateMethod: authApi.profileUpdate,
  }),
  { state, getters, mutations, actions },
);
