<template>
  <ItemPageLayout :back-route="backRoute" :title="name">
    <Card :title="isCreate ? 'Новая рассылка' : 'Рассылка'">
      <v-form :disabled="$wait('submitting')">
        <div class="form__container">
          <v-text-field
            v-model="form.name"
            label="Название"
            class="mb-4"
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('form.name')"
            @blur="validateField('form.name')"
          />
          <AccountSelect
            v-model="form.params.receivers"
            multiple
            class="mb-4"
            title="Получатели"
            hide-details="auto"
            :error-messages="getValidationErrors('form.params.receivers')"
            @blur="validateField('form.params.receivers')"
          />
          <v-text-field
            v-model="form.params.subject"
            label="Тема письма"
            class="mb-4"
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('form.params.subject')"
            @blur="validateField('form.params.subject')"
          />
          <v-textarea
            v-model="form.params.body"
            label="Содержимое письма"
            class="mb-4"
            rows="8"
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('form.params.body')"
            @blur="validateField('form.params.body')"
          />
        </div>

        <div class="summaries__container">
          <h3 class="mb-4">Сводка</h3>
          <NoDataAlert v-if="!form.params.summaries.length" class="mb-4">
            Сводок нет
          </NoDataAlert>

          <AttachmentItem
            v-for="(summary, index) in form.params.summaries"
            :key="'s' + index"
            :title="(summaryByKey(summary.key) || {}).name || summary.key"
            :description="summary.filters"
            class="mb-4"
            @edit="() => addAttachment(index, 'summaries')"
            @remove="() => removeAttachment(index, 'summaries')"
          />
          <div class="text-right">
            <v-btn
              class="mb-4"
              depressed
              color="primary"
              small
              @click="() => addAttachment(undefined, 'summaries')"
            >
              Добавить сводку
            </v-btn>
          </div>
        </div>

        <div class="attachments__container">
          <h3 class="mb-4">Вложения</h3>
          <NoDataAlert v-if="!form.params.attachments.length" class="mb-4">
            Вложений нет
          </NoDataAlert>
          <AttachmentItem
            v-for="(attachment, index) in form.params.attachments"
            :key="'a' + index"
            :title="(reportByKey(attachment.key) || {}).name || attachment.key"
            :description="attachment.filters"
            class="mb-4"
            @edit="addAttachment(index, 'attachments')"
            @remove="removeAttachment(index, 'attachments')"
          />
          <div class="text-right">
            <v-btn
              class="mb-4"
              depressed
              color="primary"
              small
              @click="addAttachment(undefined, 'attachments')"
            >
              Добавить вложение
            </v-btn>
          </div>
        </div>

        <div class="archive__container">
          <v-layout class="mb-4">
            <v-checkbox
              v-model="form.params.isArchive"
              label="В архиве"
              class="ma-0 pt-0"
              hide-details="auto"
              :messages="exportTitle"
              @change="form.params.password = null"
            />
            <v-spacer />
            <v-text-field
              v-model="form.params.password"
              label="Пароль для архива"
              class="ml-4"
              outlined
              dense
              hide-details="auto"
              :disabled="!form.params.isArchive"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              :error-messages="getValidationErrors('form.params.password')"
              @input="validateField('form.params.password')"
              @click:append="showPassword = !showPassword"
            />
          </v-layout>
        </div>

        <div class="schedule__container">
          <h3 class="mb-4">Расписание</h3>
          <ScheduleControl
            ref="schedule"
            v-model="form.schedule"
            class="mb-4"
          />
          <v-select
            v-model="form.timezone"
            label="Часовой пояс"
            outlined
            hide-details="auto"
            :items="ruTimezones"
            :error-messages="getValidationErrors('form.timezone')"
            @blur="validateField('form.timezone')"
          />
        </div>
      </v-form>
    </Card>

    <div class="actions__container text-right">
      <v-btn
        class="mr-3"
        depressed
        outlined
        color="primary"
        @click="submitTest"
      >
        Протестировать без сохранения
      </v-btn>

      <v-btn
        depressed
        color="primary"
        :loading="$wait('submitting')"
        @click="submit"
      >
        Сохранить
      </v-btn>
    </div>

    <template #aside>
      <Card title="Переменные" dense>
        <div class="mx-4 my-2">
          Для применения переменной напишите её в поле "Тема" или "Содержимое
          письма" в двойных фигурных скобках. Например:<br />
          <!-- eslint-disable-next-line vue/no-v-html -->
          <pre v-html="'Текущее время: {{ NOW }}'" />
        </div>
        <v-divider />

        <v-expansion-panels accordion>
          <v-expansion-panel
            v-for="(item, label) in possibleVariables"
            :key="label"
          >
            <v-expansion-panel-header v-text="label" />
            <v-expansion-panel-content>
              <LocalsBlock :key="label" :value="item" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </Card>
    </template>
  </ItemPageLayout>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

import { DateTime } from '@/plugins/luxon';
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';
import { RU_TIMEZONES } from '@/utils/constants';
import { getUserTimeZone } from '@/utils/convert';

import Card from '@/components/ui/Card.vue';
import LocalsBlock from '@/components/ui/LocalsBlock.vue';
import ItemPageLayout from '@/components/layouts/ItemPageLayout.vue';
import ScheduleControl from '../components/ScheduleControl.vue';
import AttachmentItem from '../components/AttachmentItem.vue';
import AccountSelect from '@/components/controls/structures/AccountSelect';
import NoDataAlert from '@/components/ui/NoDataAlert';

/**
 * Расчёт добавления полей не хранящихся на бэке
 */
function calculateValues(value) {
  const newValue = cloneDeep(value);

  if (newValue.key === 'billing-summary')
    newValue.filters.setAllOrgs = newValue.filters.orgIds === undefined;

  return newValue;
}

export default {
  components: {
    NoDataAlert,
    ItemPageLayout,
    Card,
    LocalsBlock,
    ScheduleControl,
    AccountSelect,
    AttachmentItem,
  },
  mixins: [waitable, validation, routeGuardMixin],

  validations() {
    return {
      form: {
        name: { required },
        timezone: { required },
        params: {
          receivers: { required },
          subject: {
            required,
            minSymbolsLength: minLength(2),
            maxSymbolsLength: maxLength(100),
          },
          body: { required },
          password: {
            rule(val) {
              // test whether all symbols in password are from ascii
              // eslint-disable-next-line no-control-regex
              return /^[\x00-\x7F]*$/.test(val);
            },
          },
        },
      },
    };
  },

  data: () => ({ form: {}, showPassword: false }),

  computed: {
    ...mapGetters('AVAILABLE_REPORTS', ['reportByKey']),
    ...mapGetters('AVAILABLE_SUMMARIES', ['summaryByKey']),
    ...mapGetters('REGISTRY', ['schedulerLocals']),
    ...mapGetters('SCHEDULER/JOB_ITEM', ['summaryLocals']),

    ruTimezones: () => RU_TIMEZONES,

    isCreate() {
      return this.$route.name.includes('create');
    },

    validationMessages() {
      // allowed symbols for password field are only ascii (not extended), so
      // we filter out all non-ascii symbols from current string and show them
      // as invalid to user
      return {
        'form.params.password.rule':
          'Недопустимые символы: ' +
          // eslint-disable-next-line no-control-regex
          this.form.params.password?.replace(/[\x00-\x7F]/g, ''),
      };
    },

    exportTitle() {
      const { attachments, summaries } = this.form?.params || {};
      return [...attachments, ...summaries].length > 1
        ? 'Вложения будут отправлены в виде zip-архива'
        : 'Вложение будет отправлено в виде zip-архива';
    },

    possibleVariables() {
      const list = {
        Общие: this.schedulerLocals,
      };

      Object.entries(this.summaryLocals || {}).forEach(([key, val]) => {
        const name = this.summaryByKey(key)?.name || key;
        list[name] = val;
      });

      return list;
    },

    name() {
      return this.isCreate ? 'Добавить' : 'Редактировать';
    },

    backRoute() {
      return this.isCreate
        ? 'scheduler:jobs'
        : [
            'scheduler:jobs',
            { name: 'scheduler:jobs:item', title: this.singleItem.name },
          ];
    },
  },

  watch: {
    'form.params.summaries': {
      immediate: true,
      handler(summaries) {
        if (!summaries) {
          this.resetSummaryLocals();
          return;
        }

        const summaryKeys = [...new Set(summaries.map(item => item.key))];
        if (!summaryKeys.length) this.resetSummaryLocals();
        else this.fetchSummaryLocals(summaryKeys);
      },
    },
  },

  created() {
    this.$loadingNotify(
      this.fetchSchedulerLocals(),
      'fetchSchedulerLocals',
      'Не удалось загрузить переменные',
    );
    this.$loadingNotify(
      this.fetchReports(),
      'fetchReports',
      'Не удалось загрузить отчеты',
    );
    this.$loadingNotify(
      this.fetchSummaries(),
      'fetchSummaries',
      'Не удалось загрузить сводки',
    );

    if (this.isCreate) this.$store.commit('SCHEDULER/JOB_ITEM/singleItem', {});

    const formData = this.isCreate
      ? {
          type: 'SEND_REPORT',
          timezone: getUserTimeZone(),
          params: {
            isArchive: false,
            receivers: [],
            attachments: [],
            summaries: [],
            password: null, // needed for dynamic validation message
          },
          schedule: {
            type: 'DAILY',
          },
        }
      : cloneDeep(this.singleItem);
    Vue.set(this, 'form', formData);
  },

  methods: {
    ...mapActions('REGISTRY', ['fetchSchedulerLocals']),
    ...mapActions('SCHEDULER/JOB_ITEM', [
      'updateSingleItem',
      'fetchSummaryLocals',
      'resetSummaryLocals',
    ]),
    ...mapActions('SCHEDULER/JOB_LIST', ['createListItem']),
    ...mapActions('AVAILABLE_REPORTS', ['fetchReports']),
    ...mapActions('AVAILABLE_SUMMARIES', ['fetchSummaries']),

    addAttachment(index, key) {
      const value = index === undefined ? null : this.form.params[key][index];
      const isSummary = key === 'summaries';

      this.$openModal(
        'scheduler/reportForm',
        {
          title: isSummary ? 'сводку' : 'вложение',
          value: index === undefined ? value : calculateValues(value),
          ...(isSummary ? { bySummary: true } : {}),
          noTimezone: true,
        },
        {
          input: document => {
            if (index !== undefined)
              Vue.set(this.form.params[key], index, document);
            else this.form.params[key].push(document);
          },
        },
      );
    },

    removeAttachment(index, key) {
      this.form.params[key].splice(index, 1);
    },

    formIsValid() {
      if (!this.validate() || !this.$refs.schedule.validate()) {
        this.$notify({
          group: 'note',
          type: 'error',
          title: 'Ошибки при заполнении формы',
          text: 'Исправьте ошибки и повторите снова',
        });
        return false;
      }
      return true;
    },

    setTZInDateTime(value, tz) {
      if (typeof value === 'string')
        return DateTime.fromISO(value, { setZone: true })
          .setZone(tz * 60, { keepLocalTime: true })
          .toISO();

      if (typeof value === 'object') return { ...value, timezone: tz };

      return value;
    },

    setTZInAttachments(list, tz, type) {
      return list.map(el => {
        const { key, filters } = el;
        let { dateStart, dateEnd } = filters;

        const schemaGetter =
          type === 'report' ? this.reportByKey : this.summaryByKey;
        const { fixedValues } = schemaGetter(key) || {};

        // Для отчетов с захардкоженой таймзоной не меняем даты
        const tzForAttachment = fixedValues?.timezone || tz;
        delete filters.timezone;

        dateStart = this.setTZInDateTime(dateStart, tzForAttachment);
        dateEnd = this.setTZInDateTime(dateEnd, tzForAttachment);

        el.filters = { ...filters, dateStart, dateEnd };
        return el;
      });
    },

    prepareForm(form) {
      const {
        timezone,
        params: { summaries, attachments },
      } = form;

      const changedSummaries = summaries.length
        ? this.setTZInAttachments(summaries, timezone, 'summary')
        : [];

      const changedAttachments = attachments.length
        ? this.setTZInAttachments(attachments, timezone, 'report')
        : [];

      form.params = {
        ...form.params,
        summaries: changedSummaries,
        attachments: changedAttachments,
      };

      return form;
    },

    submit() {
      if (!this.formIsValid()) return;

      Vue.set(this, 'form', this.prepareForm(this.form));

      const promise = this.isCreate
        ? this.createListItem(this.form)
        : this.updateSingleItem(this.form);

      this.$loadingNotify(
        promise.then(item => {
          this.$router.push({
            name: 'scheduler:jobs:item',
            params: { id: item.id },
          });
        }),
        'submitting',
        'Произошла ошибка создания задачи',
        'Задача успешно создана',
      );
    },

    submitTest() {
      if (!this.formIsValid()) return;

      Vue.set(this, 'form', this.prepareForm(this.form));

      this.$openModal('scheduler/testJob', { value: this.form });
    },
  },
};
</script>
