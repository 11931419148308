import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  computed: {
    ...mapGetters('AUTH', [
      'currentAccount',
      'isAuthorized',
      'permissionsForSigning',
    ]),
    ...mapGetters('TOKENS', ['activeTokenImprint']),
  },
  methods: {
    async checkSignatureToken() {
      if (
        !this.isAuthorized ||
        this.currentAccount?.type !== 'medic' ||
        !this.permissionsForSigning
      )
        return;

      await this.$store.dispatch('TOKENS/fetchAccountCertificates');

      let credential = null;
      if (this.activeTokenImprint) {
        credential = {
          type: this.activeTokenImprint?.type,
          serial: this.activeTokenImprint?.serial,
        };
      } else if (
        this.currentAccount &&
        this.currentAccount?.loginType !== 'email_pwd'
      ) {
        credential = {
          type: this.currentAccount?.loginType,
          serial: this.currentAccount?.matchBy,
        };
      }

      if (!credential) return;

      try {
        await this.$store.dispatch(
          'TOKENS/changeActiveTokenBySerial',
          credential,
        );
      } catch (e) {
        this.$store.dispatch('TOKENS/clearActiveToken');
        this.$notify({
          group: 'note',
          type: 'error',
          title: 'Проблема с сертификатом',
          text: e.message,
        });
      }

      await this.$store.dispatch('SIGNER/checkNotSignedDocuments');
    },
  },
});
