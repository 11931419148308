export const messages = {
  resourceName: 'профилей уведомлений',
  searchFilterLabel: 'Поиск по названию',
  searchFilterPlaceholder: '',
  create: {
    title: 'Добавление профиля',
    actionButton: 'Добавить',
    successfulAction: 'Профиль успешно создан',
    errorAction: 'Произошла ошибка создания профиля',
  },
};

export const entity = [
  { key: 'key', label: 'Ключ' },
  { key: 'name', label: 'Название' },
];
