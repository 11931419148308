<template>
  <v-simple-table dense>
    <colgroup>
      <col width="30%" />
      <col width="65%" />
      <col width="5%" />
    </colgroup>
    <thead>
      <tr>
        <th class="pa-4">Тип</th>
        <th class="pa-4">Название</th>
        <th class="pa-4" />
      </tr>
    </thead>
    <tbody>
      <tr v-for="config in value" :key="config.key">
        <td class="pa-4 grey--text">{{ formFactors[config.formFactor] }}</td>
        <td class="pa-4">
          <LinkButton
            :text="config.name"
            :action="$can('GT_GPR')"
            :to="{
              name: 'admin:profiles:item',
              params: { key: config.key },
            }"
          />
          <span
            v-if="config.isDefault"
            class="text--disabled"
            style="white-space: nowrap"
          >
            (Профиль по умолчанию)
          </span>
        </td>
        <td class="pa-4">
          <div class="d-flex justify-end">
            <v-tooltip v-if="$can('GT_POPR')" left>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  v-on="on"
                  @click="changeProfile(config)"
                >
                  <v-icon small>fa-edit</v-icon>
                </v-btn>
              </template>
              <span>Изменить</span>
            </v-tooltip>
            <v-tooltip v-if="$can('GT_CPR')" left>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  :to="{
                    name: 'admin:profiles:create',
                  }"
                  v-on="on"
                >
                  <v-icon small>fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Создать</span>
            </v-tooltip>
          </div>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import waitable from '@/utils/mixins/waitable';
import { mapActions, mapGetters } from 'vuex';

// components go below
import LinkButton from '@/components/controls/buttons/LinkButton.vue';
import ConfigurationSelect from '@/components/controls/ConfigurationSelect.vue';

export default {
  components: { LinkButton },
  mixins: [waitable],
  props: {
    value: { type: Array, required: true },
  },

  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', [
      'singleItem',
      'formFactors',
    ]),
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', ['editConfigProfile']),
    openApproveModal(key) {
      this.$openModal('prompt', {
        title: 'Изменение профиля',
        description:
          'На ВСЕХ ПАК данной организации произойдут изменение настроек. Вы уверены?',
        no: 'Нет',
        yes: 'Да',
        messages: {
          successfulAction: 'Профиль успешно обновлен',
          errorAction: 'Произошла ошибка обновления профиля',
        },
        onSubmit: () => this.editConfigProfile(key),
      });
    },
    changeProfile(config) {
      this.$openModal('asyncSelectModal', {
        component: ConfigurationSelect,
        value: config.key,
        componentProps: {
          itemValue: 'key',
          binding: 'org',
          formFactor: config.formFactor,
        },

        messages: {
          title:
            'Изменение профиля ' +
            this.formFactors[config.formFactor].toLowerCase(),
          warning: `Список профилей конфигураций отсутствует`,
        },
        onSubmit: this.openApproveModal,
      });
    },
  },
};
</script>
