<template>
  <Card title="Обязательность СНИЛС работников" dense>
    <v-simple-table dense>
      <tbody>
        <tr>
          <td class="grey--text px-4 py-3">Обязательность СНИЛС</td>
          <td class="px-4 py-3" style="width: 50px">
            <v-switch
              :input-value="
                orgSettings.employeeMustHave
                  ? orgSettings.employeeMustHave.rule.snils
                  : false
              "
              class="ma-0 pa-0"
              inset
              :disabled="switchDisabled"
              hide-details
              :readonly="true"
              :loading="$wait('handleSnilsSettingsUpdate')"
              @mousedown="handleSnilsSettingsUpdate"
            />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <template #action>
      <v-tooltip bottom max-width="600px">
        <template #activator="{ on, attrs }">
          <v-btn small text icon v-bind="attrs" v-on="on">
            <v-icon small>far fa-question-circle</v-icon>
          </v-btn>
        </template>
        <span>
          - Нельзя включить обязательность СНИЛС, если хотя бы у одного
          активного работника организации отсутствуют данные в поле "СНИЛС". При
          включенном статусе обязательности СНИЛС поле СНИЛС в карточке
          работника станет обязательным. Сохранить работника без СНИЛС
          нельзя.<br />
          - Нельзя отключить обязательность СНИЛС, если у организации включена
          отправка осмотров в НИИАТ
        </span>
      </v-tooltip>
    </template>
  </Card>
</template>

<script>
// TODO: Изменить событие в v-switch на change

import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';

import Card from '@/components/ui/Card';

export default {
  components: { Card },
  mixins: [waitable],

  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', ['orgSettings']),
    switchDisabled() {
      return this.orgSettings?.employeeMustHave?.rule?.snils
        ? !this.$can('OS_DSNL')
        : !this.$can('OS_ESNL');
    },
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', ['toggleSnilsRequirement']),

    handleSnilsSettingsUpdate() {
      if (this.switchDisabled) return;
      const newStatus = !this.orgSettings?.employeeMustHave?.rule?.snils;
      this.$openModal('prompt', {
        yes: 'Да',
        no: 'Отмена',
        title: 'Подтверждение',
        description: `Вы действительно хотите ${
          newStatus ? 'включить' : 'отключить'
        } обязательность СНИЛС?`,
        messages: {
          successfulAction: `Обязательность СНИЛС успешно ${
            newStatus ? 'включена' : 'отключена'
          }`,
          errorAction: `Ошибка ${
            newStatus ? 'включения' : 'отключения'
          } обязательности снилса`,
        },
        onSubmit: () =>
          this.$loading(
            this.toggleSnilsRequirement(),
            'handleSnilsSettingsUpdate',
          ),
      });
    },
  },
};
</script>
