<template>
  <v-list-item-content
    v-if="isSystem"
    class="py-4 px-2"
    :style="eventTypeStyle"
  >
    <v-list-item-title class="d-flex justify-space-between">
      <div class="text-body-1 font-weight-bold">
        <div
          class="d-inline"
          style="white-space: normal"
          v-text="value.content"
        />
        <template v-if="referenceData">
          (<LinkButton class="d-inline" v-bind="referenceData" dense blank />)
        </template>
      </div>
      <span
        class="pl-1 text-caption"
        :title="`${date.full}`"
        v-text="date.withTime"
      />
    </v-list-item-title>
  </v-list-item-content>

  <v-list-item-content v-else class="pt-2 pb-4 px-2">
    <v-list-item-title class="d-flex justify-space-between">
      <span class="font-weight-medium subtitle-1">{{ name }}</span>
      <span
        class="pl-1 text-caption"
        :title="`${date.full}`"
        v-text="date.withTime"
      />
    </v-list-item-title>
    <div
      class="pb-1 text-body-2 d-inline"
      style="white-space: normal; width: 100%"
    >
      {{ value.content }}
      <template v-if="referenceData">
        (<LinkButton class="d-inline" v-bind="referenceData" dense blank />)
      </template>
    </div>
    <div><v-divider /></div>
  </v-list-item-content>
</template>

<script>
import { dateTime } from '@/utils/convert';
import LinkButton from '@/components/controls/buttons/LinkButton.vue';
import { COLORS } from '@/utils/constants';

const commentColors = {
  alcohol: COLORS.red,
  fraud: COLORS.violet,
  injury: COLORS.yellow,
  bandage: COLORS.yellow,
};

export default {
  components: { LinkButton },
  props: {
    value: { type: Object, required: true },
    currentAccountId: { type: Number, default: null },
    accountPreviews: { type: Object, required: true },
  },
  computed: {
    commentColors: () => commentColors,
    isSystem() {
      if (!this.value?.type) return false;
      return this.value.type.toLowerCase() === 'system';
    },
    eventTypeStyle() {
      const { eventType } = this.value;
      if (!eventType) return;

      const color = commentColors[eventType.toLowerCase()];
      return `color: rgb(${color}); background: rgba(${color}, 0.1); border-bottom: 1px solid`;
    },

    name() {
      // У системных комментариев нет автора
      if (!this.value?.author) return null;

      if (this.currentAccountId === this.value.author) return 'Вы';
      return (
        this.accountPreviews[this.value.author] || '– пользователь не найден –'
      );
    },

    date() {
      return {
        withTime: dateTime(this.value.timestamp),
        full: dateTime(this.value.timestamp, {
          showSeconds: true,
          showTimeZone: true,
        }),
      };
    },

    referenceData() {
      const { reference } = this.value;

      if (!reference) return null;

      if (reference.type === 'inspection') {
        return {
          text: `Осмотр #${reference.id}`,
          action: this.$can('I_RD'),
          to: {
            name: 'inspections:card',
            params: { id: reference.id },
          },
        };
      }
      return null;
    },
  },
};
</script>
