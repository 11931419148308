import signApi from '@/api/services/sign';
import authApi from '@/api/services/auth';

import {
  MedicDiagnosticKeyStatus,
  IAccountData,
  IMedicItem,
  ICertificate,
  IMedicListItem,
  IMedicData,
  IMedicDiagnosticStatus,
  IMedicDiagnosticRule,
  IMedicDiagnostic,
} from '@/models/medics/MedicItem';

const statusList: Record<MedicDiagnosticKeyStatus, IMedicDiagnosticStatus> = {
  error: {
    order: 1,
    key: 'error',
    icon: 'mdi-alert',
    color: 'red darkest-1',
  },
  warning: {
    order: 2,
    key: 'warning',
    icon: 'mdi-alert',
    color: 'orange',
  },
  success: {
    order: 3,
    key: 'success',
    icon: 'mdi-check-bold',
    color: 'green darkest-2',
  },
};

const statusPriority: MedicDiagnosticKeyStatus[] = [
  statusList.success.key,
  statusList.warning.key,
  statusList.error.key,
];

export const medicVerifyRules: IMedicDiagnosticRule[] = [
  {
    isValid: (item: IMedicItem) => item.medicData?.isEnabled,
    status: statusList.error,
    message: {
      resolve: 'Медработник активирован',
      reject: 'Медработник деактивирован',
    },
  },
  {
    isValid: (item: IMedicItem) => !!item.accountId,
    status: statusList.error,
    message: {
      reject: 'При создании аккаунта произошла ошибка',
    },
  },
  {
    isValid: (item: IMedicItem) => !!item.certificates?.length,
    status: statusList.error,
    message: {
      resolve: 'ЭЦП подключены',
      reject: 'ЭЦП отсутствуют',
    },
  },
  {
    isValid: (item: IMedicItem) => !!item.medicData?.categories?.length,
    status: statusList.error,
    message: {
      resolve: 'Категории заданы',
      reject: 'Категории отсутствуют',
    },
  },
  {
    isValid: (item: IMedicItem) =>
      item.medicData?.email === item.accountData?.email,
    status: statusList.warning,
    message: {
      resolve: 'Можно авторизоваться по email',
      reject: 'По этому email нельзя авторизоваться',
    },
  },
];

export const diagnosticTheMedic = (medic: IMedicItem) => {
  const item: IMedicDiagnostic = {
    highStatus: statusList.success,
    list: [],
  };

  for (const rule of medicVerifyRules) {
    const isValid = rule.isValid(medic);

    if (isValid) {
      rule.message.resolve &&
        item.list.push({
          status: statusList.success,
          message: rule.message.resolve,
        });
    } else {
      rule.message.reject &&
        item.list.push({
          status: rule.status,
          message: rule.message.reject,
        });
    }
  }

  item.list.sort((a, b) => a.status.order - b.status.order);

  // set high level status
  for (const el of item.list) {
    const indexEl = statusPriority.indexOf(el.status.key);
    const indexItem = statusPriority.indexOf(item.highStatus.key);

    if (indexEl > indexItem) {
      item.highStatus = el.status;
      if (indexEl === statusPriority.length - 1) break;
    }
  }

  return item;
};

export const convertMedics = async (items: IMedicData[], diagnostic = true) => {
  if (!items.length) return [];

  const medicIds = items.map(el => el.id);
  const accounts = await authApi.getMedicAccounts(medicIds);

  const accountIds = accounts.map((el: IAccountData) => el.id);
  const certs = accountIds.length
    ? await signApi.getCertificatesByAccountId(accountIds)
    : [];

  // add fields from account service
  const medicItems = items.map((el: IMedicData) => {
    const foundAccount = accounts.find(
      (account: IAccountData) => account.medicId === el.id,
    );

    const newEl: IMedicListItem = {
      id: el.id,
      medicId: el.id,
      medicData: el,
      accountData: foundAccount || null,
      accountId: foundAccount?.id || null,
    };

    if (foundAccount) {
      const foundCert: ICertificate[] = certs.filter(
        (cert: ICertificate) => cert.ownerId === foundAccount.id,
      );
      newEl.certificates = foundCert;
    }

    if (diagnostic) newEl.diagnostic = Object.freeze(diagnosticTheMedic(newEl));

    return newEl;
  });

  return medicItems;
};
