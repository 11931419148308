import { GENDER } from '@/utils/constants.js';
import { date as dateFormatter, name } from '@/utils/convert';
import { maskTextPhone } from '@/utils/masks';
import { IMedicItem } from '@/models/medics/MedicItem';

export const messages = {
  resourceName: 'медработников',
  searchFilterLabel: 'Поиск по медработникам',
  searchFilterPlaceholder: '',
  delete: {
    title: 'Удалить',
    description: 'Вы точно хотите удалить медработника?',
    successfulAction: 'Медработник успешно удален',
    errorAction: 'Произошла ошибка удаления медработника',
  },
};

export const entity = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'diagnostic',
    label: 'Статус',
    headerProps: {
      width: '50px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
  },
  {
    key: 'name',
    label: 'ФИО',
    hideInTable: true,
    convert: (item: IMedicItem) => name(item.medicData),
  },
  {
    key: 'gender',
    label: 'Пол',
    hideInTable: true,
    convert: (item: IMedicItem) => GENDER[item.medicData.gender],
  },
  {
    key: 'dateOfBirth',
    label: 'Дата рождения',
    hideInTable: true,
    convert: (item: IMedicItem) => dateFormatter(item.medicData.dateOfBirth),
  },
  { key: 'name_gender_dateOfBirth', label: 'Медработник', hideInCard: true },
  { key: 'contacts', label: 'Контакты', hideInCard: true },
  {
    key: 'email',
    label: 'Email',
    hideInTable: true,
    convert: (item: IMedicItem) => item.medicData.email,
  },
  {
    key: 'phone',
    headerProps: { width: '160px' },
    label: 'Телефон',
    hideInTable: true,
    convert: (item: IMedicItem) => maskTextPhone(item.medicData.phone),
  },
  {
    key: 'roleKey',
    label: 'Роль',
    hideInTable: true,
    convert: (item: IMedicItem, getters: any) => {
      return (
        getters['MEDBLOCK/MEDICS/medicalRoleByKey'](item.medicData?.roleKey)
          ?.name || '–'
      );
    },
  },
  {
    key: 'orgId',
    label: 'Организация',
    convert: (item: IMedicItem, getters: any) =>
      getters['MEDBLOCK/MEDICS/medicalOrgs'].find(
        (org: any) => org.id === item.medicData.orgId,
      )?.name || '–',
  },
  {
    key: 'groups',
    label: 'Группы',
    convert: (item: IMedicItem) =>
      item.medicData.groups.map((group: any) => group.name).join(', '),
  },
  {
    key: 'categories',
    label: 'Категории',
    convert: (item: IMedicItem, getters: any) => {
      return item.medicData.categories
        .map(
          categoryId =>
            // REVIEW: i don't know where else to store org categories in order
            // to display them in medics list
            getters['MEDBLOCK/MEDICS/orgCategories'].find(
              (category: any) => categoryId === category.id,
            )?.name,
        )
        .filter(a => a)
        .join(', ');
    },
  },
];

export const filters = {
  isEnabled: {
    type: Boolean,
    default: true,
  },
  categoryIds: {
    type: Array,
    default: null,
  },
  roleKeys: {
    type: Array,
    default: null,
  },
  groupIds: {
    type: Array,
    default: null,
  },
  orgIds: {
    type: Array,
    default: null,
  },
};
