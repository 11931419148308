import structApi from '@/api/services/structures';

export default {
  state: {
    orgSettings: {},
  },
  getters: {
    orgSettings: state => state.orgSettings,
  },
  mutations: {
    orgSettings: (state, value) => (state.orgSettings = value),
  },
  actions: {
    /**
     * Получить настройки организации для синхронизации работника
     * @action ORG_RD
     */
    async fetchOrgSettings({ commit }, id) {
      const settings = await structApi.getOrganizationSettings(id);
      commit('orgSettings', settings || {});
    },
  },
};
