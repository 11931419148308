<template>
  <v-form ref="form" :disabled="isLoading || disabled">
    <v-row>
      <v-col cols="6">
        <v-text-field
          :value="value.surname"
          label="Фамилия"
          outlined
          hide-details="auto"
          :error-messages="getErrors('surname')"
          @blur="validateFields('surname')"
          @input="$emit('input', { ...value, surname: $event })"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          :value="value.name"
          label="Имя"
          outlined
          hide-details="auto"
          :error-messages="getErrors('name')"
          @blur="validateFields('name')"
          @input="$emit('input', { ...value, name: $event })"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          :value="value.patronymic"
          label="Отчество"
          outlined
          hide-details="auto"
          :error-messages="getErrors('patronymic')"
          @blur="validateFields('patronymic')"
          @input="$emit('input', { ...value, patronymic: $event })"
        />
      </v-col>
      <v-col cols="6">
        <v-radio-group
          class="mt-0"
          :value="value.gender"
          row
          hide-details="auto"
          :error-messages="getErrors('gender')"
          @blur="validateFields('gender')"
          @change="$emit('input', { ...value, gender: $event })"
        >
          <v-radio value="MALE" label="Мужчина" />
          <v-radio value="FEMALE" label="Женщина" />
        </v-radio-group>
      </v-col>
      <v-col cols="6">
        <OrganizationSelect
          :value="value.orgId"
          outlined
          hide-details="auto"
          hint-color="orange"
          access-level="full"
          :error-messages="getErrors('orgId')"
          @blur="validateFields('orgId')"
          @input="$emit('input', { ...value, orgId: $event })"
        />
        <div class="text-sm-body-2 pt-2">
          Влияет на принадлежность пользователя к организации. Права доступа
          нужно настраивать отдельно!
        </div>
      </v-col>
      <v-col cols="6">
        <PhoneField
          :value="value.phone"
          hide-details="auto"
          outlined
          :error-messages="getErrors('phone')"
          @blur="validateFields('phone')"
          @input="$emit('input', { ...value, phone: $event })"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import validation from '@/utils/validation';
import { cyrillicName, phone } from '@/utils/validators';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';
import PhoneField from '@/components/controls/PhoneField.vue';

export default {
  components: { OrganizationSelect, PhoneField },
  mixins: [validation],

  props: {
    value: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },

  validations() {
    return {
      value: {
        name: { required, cyrillicName, maxSymbolsLength: maxLength(64) },
        surname: { required, cyrillicName, maxSymbolsLength: maxLength(64) },
        patronymic: {
          cyrillicName,
          maxSymbolsLength: maxLength(64),
        },
        orgId: { required },
        gender: { required },
        phone: { phone },
      },
    };
  },
  computed: {
    getErrors() {
      return fieldName => [
        ...this.getValidationErrors(`value.${fieldName}`), // local errors
        ...this.getValidationErrors(`value.profile.${fieldName}`), // server errors
      ];
    },
  },
  methods: {
    validateFields(fieldName) {
      this.validateField(`value.${fieldName}`); // local errors
      this.validateField(`value.profile.${fieldName}`); // server errors
    },
  },
};
</script>
