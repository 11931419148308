import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import structApi from '@/api/services/structures';
import { createModule } from '@/utils/vuex/createModule';

export default createModule(
  crudSingleFactory({
    fetchMethod: structApi.employeeGroupGet,
    updateMethod: structApi.employeeGroupUpdate,
    linkedList: 'STRUCTURES/EMPLOYEE_GROUPS',
  }),
);
