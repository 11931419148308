import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import STICKERS from './list.store';
import STICKERS_ITEM from './item.store';
import RouteGuard from '@/components/RouteGuard';

export default {
  namespaced: true,
  store: {
    STICKERS,
    STICKERS_ITEM,
  },
  routes: [
    {
      path: 'stickers',
      name: 'stickers',
      component: () => import('./list.view.vue'),
      meta: {
        action: 'ST_PF',
      },
    },
    {
      path: 'stickers/create',
      name: 'stickers:create',
      component: RouteGuard({
        storeModule: 'ADMIN/STICKERS_ITEM',
        component: () => import('./item.view.vue'),
        skipFetch: true,
      }),
      meta: {
        action: 'ST_PC',
      },
    },
    {
      path: 'stickers/:key',
      name: 'stickers:item',
      component: RouteGuard({
        storeModule: 'ADMIN/STICKERS_ITEM',
        component: () => import('./item.view.vue'),
        primaryKeyName: 'key',
        primaryKeyType: 'string',
      }),
      meta: {
        action: 'ST_PR',
      },
    },
  ],
  menu: [
    {
      title: 'Профили стикеров',
      action: 'ST_PF',
      name: 'stickers',
      priority: 7,
    },
  ],
} as ModuleDefinition;
