<template>
  <AsyncSelect v-bind="computedProps" v-on="$listeners" />
</template>

<script>
import asyncSelectMixin from '@/components/controls/asyncSelectMixin';

export default {
  extends: asyncSelectMixin,

  props: {
    errorMessages: { type: Array, default: null },
    title: { type: String, default: null },
    value: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    label() {
      if (this.title) return this.title;
      return null;
    },
    query() {
      return {
        roleTypeKey: 'waybillOperator',
      };
    },
  },

  methods: {
    fetcher(params) {
      return this.$store
        .dispatch('AUTH/fetchAppAccountsPreviews', params)
        .then(res => {
          return {
            ...res,
            items: res.items.map(e => ({ id: e.id, name: e.profile.name })),
          };
        });
    },
  },
};
</script>
