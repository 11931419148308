import medrecApi from '@/api/services/medrec';

export default {
  state: {
    riskFactors: {},
  },
  getters: {
    riskFactors: state => state.riskFactors,
  },
  mutations: {
    riskFactors: (state, value) => (state.riskFactors = value),
  },
  actions: {
    async fetchHealthStateInfo({ getters, commit }) {
      const riskFactors = await medrecApi.fetchRiskFactors(
        getters.singleItem.id,
        getters.singleItem.organization.id,
      );
      commit('riskFactors', riskFactors);
    },
  },
};
