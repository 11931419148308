import structApi from '@/api/services/structures';
import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

// babel wildcard needs explicit paths
import * as subModules from './submodules';

const actions = {
  async changeStatus({ dispatch, getters }, isActive) {
    const action = isActive ? 'pointEnable' : 'pointDisable';
    await structApi[action](getters.singleItem.id);
    dispatch('singleItemUpdate');
  },
};

export default createModule(
  crudSingleFactory({
    fetchMethod: structApi.pointGet,
    updateMethod: structApi.pointUpdate,
    linkedList: 'STRUCTURES/POINTS',
  }),
  ...Object.values(subModules),
  { actions },
);
