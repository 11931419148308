// Основа взята из src/modules/medcab/scenarios.js - SCENARIOS_BASE

export const REMARKS_TRANSLATION_TABLE = {
  temperature: 'Температура',
  pressure: 'Артериальное давление',
  pulse: 'Пульс',
  reactions: 'Реакции',
  complaints: 'Жалобы',
  skin: 'Цвет кожных покровов изменен',
  alcohol: 'Обнаружен алкоголь',
  injury: 'Травмы',

  // Переводы для definition table кардио заболеваний
  // REVIEW: пока не уверен что эти переводы должны быть в этом объекте
  averageDiastolic: 'Среднее диастолическое',
  averagePulse: 'Среднее значение ЧСС',
  averageSystolic: 'Среднее систолическое',
  isAddictedSmoking: 'Курит',
  age: 'Возраст',
  hasCertificates: 'Наличие справки',

  gender: 'Пол',
  cholesterol: 'Холестерин',
  failedAlcoAmountYear: 'Кол-во осмотров с выявленным алкоголем за год',
  failedAlcoAmountMonth: 'Кол-во осмотров с выявленным алкоголем за месяц',
  diagnosis: 'Диагноз',
  smokingAddiction: 'Расшифровка индекса курильщика',
  systematicStress: 'Расшифровка рассчета адаптационного потенциала',
};

export const RISKS_TRANSLATION_TABLE = {
  cardioDisease: 'Риск сердечно-сосудистых заболеваний',
  cardioEvents: 'Риск сердечно-сосудистых событий',
  cardioArrhythmia: 'Риск развития нарушения сердечного ритма',
  certificate: 'Справка 027У',
  oldAge: 'Риск развития возрастных заболеваний',
  alcohol: 'Злоупотребление алкоголем',
  smoking: 'Риск развития ХОБЛ (курильщики)',
  stress: 'Систематический стресс',
  oldDudeRisk: 'Риск возрастных недугов ?)',
};

export const RISK_LEVELS = {
  veryLow: 'Незначительный',
  low: 'Низкий',
  middle: 'Умеренный',
  high: 'Высокий',
  veryHigh: 'Очень высокий',
};

const REC_MSG = {
  WRR: 'соблюдение режима труда и отдыха',
  BH: 'отказ от вредных привычек',
  BD: 'сбалансированный рацион питания',
  SC: 'исключение стрессовых состояний',
  PA: 'оптимизация уровня физической активности',
  IS: 'ограничение суточного потребления поваренной соли',
  TD: 'исключение тонизирующих напитков',
  CS: 'отказ от курения',
  AM: 'обращение в лечебное учреждение с целью обследования и при необходимости назначения лечения',
  AMP: 'обращение в лечебно-профилактическое учреждение за специализированной помощью',
  ME: 'регулярная диспансеризация',
};

export const RECOMMENDATIONS_TABLE = {
  cardioDisease: {
    veryLow: [REC_MSG.WRR, REC_MSG.BH, REC_MSG.BD, REC_MSG.SC, REC_MSG.PA],
    low: [REC_MSG.WRR, REC_MSG.BH, REC_MSG.BD, REC_MSG.SC, REC_MSG.PA],
    middle: [REC_MSG.WRR, REC_MSG.BH, REC_MSG.BD, REC_MSG.IS, REC_MSG.SC],
    high: [REC_MSG.WRR, REC_MSG.BH, REC_MSG.BD, REC_MSG.IS, REC_MSG.SC],
    veryHigh: [REC_MSG.WRR, REC_MSG.BH, REC_MSG.BD, REC_MSG.IS, REC_MSG.SC],
  },
  cardioEvents: {
    low: [REC_MSG.WRR, REC_MSG.BD, REC_MSG.SC],
    middle: [REC_MSG.WRR, REC_MSG.BH, REC_MSG.BD, REC_MSG.IS, REC_MSG.SC],
    high: [REC_MSG.WRR, REC_MSG.BH, REC_MSG.BD, REC_MSG.IS, REC_MSG.SC],
    veryHigh: [REC_MSG.WRR, REC_MSG.BH, REC_MSG.BD, REC_MSG.IS, REC_MSG.SC],
  },
  cardioArrhythmia: {
    bradycardia: [
      REC_MSG.WRR,
      REC_MSG.BH,
      REC_MSG.BD,
      REC_MSG.TD,
      REC_MSG.SC,
      REC_MSG.PA,
    ],
    tachycardia: [
      REC_MSG.WRR,
      REC_MSG.BH,
      REC_MSG.BD,
      REC_MSG.TD,
      REC_MSG.SC,
      REC_MSG.PA,
    ],
  },
  smoking: {
    middle: [REC_MSG.CS, REC_MSG.BD, REC_MSG.SC, REC_MSG.WRR, REC_MSG.PA],
    high: [
      REC_MSG.CS,
      REC_MSG.BD,
      REC_MSG.SC,
      REC_MSG.WRR,
      REC_MSG.PA,
      REC_MSG.AM,
    ],
    veryHigh: [REC_MSG.CS, REC_MSG.BD, REC_MSG.SC, REC_MSG.WRR, REC_MSG.PA],
  },
  alcohol: {
    middle: [REC_MSG.BH, REC_MSG.BD, REC_MSG.SC, REC_MSG.WRR, REC_MSG.PA],
  },
  oldAge: {
    middle: [
      REC_MSG.WRR,
      REC_MSG.BH,
      REC_MSG.BD,
      REC_MSG.SC,
      REC_MSG.PA,
      REC_MSG.ME,
    ],
  },
  stress: {
    middle: [REC_MSG.BH, REC_MSG.BD, REC_MSG.SC, REC_MSG.WRR, REC_MSG.PA],
    high: [
      REC_MSG.BH,
      REC_MSG.BD,
      REC_MSG.SC,
      REC_MSG.WRR,
      REC_MSG.PA,
      REC_MSG.AMP,
    ],
    veryHigh: [
      REC_MSG.BH,
      REC_MSG.BD,
      REC_MSG.SC,
      REC_MSG.WRR,
      REC_MSG.PA,
      REC_MSG.AMP,
    ],
  },
};

export const ADDITION_TRANSLATION_TABLE = {
  cardioDisease: {
    middle: `При стабильной артериальной гипертензии для профилактики осложнений рекомендовано обратиться
     за консультацией врача в лечебное учреждение`,
    high: `При впервые выявленных  отклонениях в состоянии здоровья, рекомендовано обратиться за консультацией врача.
     Для лиц у которых уже есть установленный диагноз, необходимо строго соблюдать рекомендации лечащего врача.
     В случае неэффективности терапии, для предупреждения осложнений таких как инфаркт и инсульт,
     рекомендовано повторно обратиться в лечебное учреждение с целью корректировки лечения.`,
    veryHigh: `При впервые выявленных  отклонениях в состоянии здоровья, рекомендовано обратиться за консультацией врача.
     Для лиц у которых уже есть установленный диагноз, необходимо строго соблюдать рекомендации лечащего врача.
     В случае неэффективности терапии, для предупреждения осложнений таких как инфаркт и инсульт,
     рекомендовано повторно обратиться в лечебное учреждение с целью корректировки лечения.`,
  },
  cardioEvents: {
    middle: `При стабильной артериальной гипертензии для профилактики осложнений рекомендовано обратиться
     за консультацией врача в лечебное учреждение.`,
    high: `При впервые выявленных  отклонениях в состоянии здоровья, рекомендовано обратиться за консультацией врача.
     Для лиц у которых уже есть установленный диагноз, необходимо строго соблюдать рекомендации лечащего врача.
     В случае неэффективности терапии, для предупреждения осложнений таких как инфаркт и инсульт,
     рекомендовано повторно обратиться в лечебное учреждение с целью корректировки лечения.`,
    veryHigh: `При впервые выявленных  отклонениях в состоянии здоровья, рекомендовано обратиться за консультацией врача.
     Для лиц у которых уже есть установленный диагноз, необходимо строго соблюдать рекомендации лечащего врача.
     В случае неэффективности терапии, для предупреждения осложнений таких как инфаркт и инсульт,
     рекомендовано повторно обратиться в лечебное учреждение с целью корректировки лечения.`,
  },
  cardioArrhythmia: {
    bradycardia: `При стойкой брадикардии обратиться в лечебное учреждение с целью консультации врача,
     дополнительного обследования и при необходимости назначения терапии.`,
    tachycardia: `При стойкой тахикардии обратиться в лечебное учреждение с целью консультации врача,
     дополнительного обследования и при необходимости назначения терапии.`,
  },
  smoking: {
    veryHigh: `Необходимо строго соблюдать рекомендации лечащего врача.
     В случае неэффективности терапии, для предупреждения осложнений таких как инфаркт и инсульт,
     рекомендовано повторно обратиться в лечебное учреждение с целью корректировки лечения.`,
  },
  alcohol: {
    middle: `При несамостоятельности отказа от спиртных напитков рекомендовано обратиться в лечебно-профилактическое учреждение
     за специализированной помощью. Так же рекомендовано обращение в лечебное учреждение с целью обследования,
     выявления соматических заболеваний и при необходимости назначения терапии.`,
  },
  oldAge: {
    middle: `При наличии хронических заболеваний строго соблюдать рекомендации лечащего врача.`,
  },
};
