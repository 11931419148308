<template>
  <div>
    <CryptoProTokens v-model="selectedCert" :disabled="$wait('login')" />

    <v-alert v-if="authError" text type="error" style="white-space: pre-line">
      {{ authError }}
    </v-alert>
    <div class="pt-0 d-flex justify-center">
      <v-btn
        v-if="softAuth"
        class="px-10 mr-2"
        :disabled="$wait('login')"
        height="40"
        color="primary"
        outlined
        @click="signOut"
      >
        Выйти
      </v-btn>
      <v-btn
        class="px-5"
        :disabled="!selectedCert || $wait('login')"
        :loading="$wait('login')"
        height="40"
        color="primary"
        @click="onSubmit"
      >
        {{ softAuth ? 'Продолжить' : 'Войти' }}
      </v-btn>
    </div>
    <div v-if="!(softAuth || $wait('login'))" class="text-center mt-2">
      <v-btn depressed x-small @click="toLogin"> Назад </v-btn>
    </div>
  </div>
</template>

<script>
import Sentry from '@/plugins/sentry';
import waitable from '@/utils/mixins/waitable';
import applyToken from '@/utils/mixins/applyToken';
import { isXhrError } from '@/utils/helpers';
import CryptoProTokens from '@/components/CryptoProTokens';
import authFormMixin from './authFormMixin';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: { CryptoProTokens },
  mixins: [authFormMixin, waitable, applyToken],

  data: () => ({
    selectedCert: null,
    authError: null,
  }),

  computed: {
    ...mapGetters('TOKENS', ['timerCryptoProTokens']),
  },

  methods: {
    ...mapActions('TOKENS', ['setTimerCryptoProTokens']),
    async onSubmit() {
      this.authError = null;

      if (!this.selectedCert) return null;

      try {
        await this.$loading(
          this.signIn({
            thumbprint: this.selectedCert.data.thumbprint,
            type: this.selectedCert.type,
          }),
          'login',
        );

        await this.checkSignatureToken();
      } catch (err) {
        console.error(err);
        const messagesStatus = {
          401: 'Ошибка проверки сертификата',
          403: 'Доступ заблокирован',
          404: 'Сертификат не прикреплен к пользователю',
        };

        const status = err?.response?.status || null;
        if (err?.message === 'Network Error')
          this.authError =
            'Ошибка сети. Проверьте интернет соединение или обратитесь в техническую поддержку';
        else if (err?.message === 'account_mismatch')
          this.authError =
            'Сертификат ЭЦП используется для другой учетной записи. Убедитесь, что вы используете свой сертификат';
        else if (
          isXhrError(err) &&
          /.*disabled.*/.test(err?.response?.data?.message)
        )
          this.authError = 'Аккаунт деактивирован';
        else if (messagesStatus[status])
          this.authError = messagesStatus[status];
        else {
          this.authError =
            'При авторизации произошла ошибка\n' + err?.message || err;
          // Если уж дошло до этого места, то точно нужно отправить
          // сообщение в Sentry полной информацией об ошибке
          Sentry.captureException(err);
        }
      }
    },

    toLogin() {
      if (this.timerCryptoProTokens) {
        clearTimeout(this.timerCryptoProTokens);
        this.setTimerCryptoProTokens(null);
      }
      this.$router.push({
        name: 'login',
        query: { redirect: this.$route.query.redirect },
      });
    },
  },
};
</script>
