<template>
  <v-select
    :value="value"
    label="Тип отчета"
    :items="types"
    outlined
    hide-details="auto"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    value: { type: String, default: null },
    cancelledName: { type: String, default: 'С незавершенными' },
  },
  computed: {
    types() {
      return [
        { value: 'default', text: 'По умолчанию' },
        { value: 'detailed', text: 'Детализированный' },
        { value: 'cancelled', text: this.cancelledName },
      ];
    },
  },
};
</script>
