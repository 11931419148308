<template>
  <v-autocomplete
    :value="value"
    label="Тип осмотра"
    :items="filteredTypes"
    item-value="key"
    item-text="name"
    multiple="multiple"
    outlined
    hide-details="auto"
    :loading="$wait('fetchingInspectionTypes')"
    :error-messages="errorMessages"
    @input="$emit('input', $event)"
  >
    <template #prepend-item>
      <v-list-item ripple @mousedown.prevent @click="toggle">
        <v-list-item-action>
          <v-icon :color="value && value.length > 0 ? 'primary' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Выбрать все </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2" />
    </template>
  </v-autocomplete>
</template>

<script>
import waitable from '@/utils/mixins/waitable';
import { mapGetters } from 'vuex';

export default {
  mixins: [waitable],

  props: {
    value: { type: [String, Array], default: null },
    errorMessages: { type: Array, default: () => [] },
    multiple: Boolean,
    // Array of type keys to exclude from showing types array
    // needed only for 'inspections-duratoin-stats' report where we need to
    // exclude some of types. REVIEW: shall we somehow move it to backend? Cuz
    // to me it seems kindof a hardcode
    exclude: { type: Array, default: () => [] },
  },
  computed: {
    ...mapGetters('REGISTRY', ['inspectionTypes']),
    filteredTypes() {
      return !this.exclude.length
        ? this.inspectionTypes
        : this.inspectionTypes.filter(type => !this.exclude.includes(type.key));
    },
    allSelected() {
      return this.value?.length === this.filteredTypes?.length;
    },
    someSelected() {
      return this.value?.length > 0 && !this.allSelected;
    },
    icon() {
      if (this.allSelected) return 'mdi-close-box';
      if (this.someSelected) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },
  mounted() {
    this.$loadingNotify(
      this.$store.dispatch('REGISTRY/fetchInspectionTypes'),
      'fetchingInspectionTypes',
      'Произошла ошибка загрузки типов осмотров',
    );
  },

  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.allSelected) {
          this.$emit('input', []);
        } else {
          this.$emit(
            'input',
            this.filteredTypes.map(v => v.key),
          );
        }
      });
    },
  },
};
</script>
