<script>
import { date } from '@/utils/convert';
import { VBtn } from 'vuetify/lib';

export default {
  props: {
    cert: { type: Object, required: true },
    employee: { type: Object, required: true },
  },

  methods: {
    openDocumentModal() {
      this.$openModal('documents/showIncoming', {
        id: this.cert.incomingDocumentId,
        employee: this.employee,
      });
    },
  },

  render(createElement) {
    return createElement(
      VBtn,
      {
        attrs: this.$attrs,
        class: ['px-0'],
        ref: 'field',
        props: {
          text: true,
          small: true,
          dense: true,
          color: 'primary',
        },
        on: {
          // ...this.$listeners,
          click: this.openDocumentModal,
        },
      },
      [date(this.cert.dueDate), this.cert.diagnosis].filter(_ => _).join(', '),
    );
  },
};
</script>
