import { name } from '@/utils/convert';

export const entity = [
  { label: 'ID', key: 'id' },
  {
    label: 'Статус',
    key: 'isEnabled',
    headerProps: {
      width: '60px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
    convert: item => (item.isEnabled ? 'Активный' : 'Неактивный'),
  },
  {
    label: 'ФИО',
    key: 'name',
    type: 'custom',
    headerProps: { width: '200px' },
    convert: item => name(item.profile),
  },
  { key: 'email', label: 'Email' },
  {
    label: 'Роль',
    key: 'roleKey',
    convert: (item, getters) =>
      getters['ADMIN/USERS/role'](item.roleKey, 'name'),
  },
];

const fieldTitle = (str, required = false) =>
  `<span ${required ? 'style="color:red"' : ''}><b>${str}${
    required ? ' *' : ''
  }</b></span>`;

const monospaceText = str =>
  `<span style="font-family: monospace">${str}</span>`;

export const messages = {
  resourceName: 'пользователей',
  searchFilterLabel: 'Поиск по пользователям',
  searchFilterPlaceholder: '',
  import: {
    title: 'Импорт пользователей',
    instruction: [
      `${fieldTitle(
        '1. Email',
        true,
      )} – логин почты должен содержать цифры и/или латинские буквы, ` +
        'после логина нужно вписать символ @, потом почту (mail, gmail, yandex, rambler, корпоративный адрес и т.д.), ' +
        'затем точку и доменную зону (.ru, .com и т.д.). ' +
        `\nПример: ${monospaceText('example@medpoint24.ru')}.`,
      `${fieldTitle(
        '2. Фамилия',
        true,
      )} – текстовое поле (только кириллица), ` +
        'максимальное количество символов - 64, двойные фамилии указываются через 1 пробел или дефис без пробелов. ' +
        'Начальная буква первого слова вводится в верхнем регистре, вторая (после пробела или дефиса) - не имеет значения.',
      `${fieldTitle(
        '3. Имя',
        true,
      )} – текстовое поле (только кириллица), максимальное количество символов - 64, ` +
        'двойные имена указываются через 1 пробел или дефис без пробелов. Начальная буква первого слова вводится в верхнем регистре, вторая (после пробела или дефиса) - не имеет значения.',
      `${fieldTitle(
        '4. Отчество',
      )} – текстовое поле (только кириллица), максимальное количество символов - 64, ` +
        'двойные отчества указываются через 1 пробел или дефис без пробелов. Начальная буква первого слова вводится в верхнем регистре, вторая (после пробела или дефиса) - не имеет значения.',
      `${fieldTitle(
        '5. Пол',
        true,
      )} – указывается цифрой: 1 - мужской, 2 - женский.`,
      `${fieldTitle(
        '6. Ключ роли',
        true,
      )} – вносится ключ только существующей в системе роли. Цифры и/или буквы на латинице, строго в нижнем регистре, ` +
        `также может включать символы \n${monospaceText('_')} и ${monospaceText(
          '-',
        )} (минус). Минимальное количество символов - 3, максимальное количество символов - 15.`,
      `${fieldTitle(
        '7. Организация',
        true,
      )} – вносится ID только существующей в системе организации. Только цифры.`,
      `${fieldTitle(
        '8. Доступ к организациям',
      )} – вносятся ID только существующих в системе организаций, через запятую без пробелов. Только цифры.`,
      '</br>',
      `Поля, отмеченные ${fieldTitle(
        '',
        true,
      )}, обязательны к заполнению. Остальные поля можно оставить пустыми.`,
    ],
  },
};

export const filters = {
  roleKeys: {
    type: Array,
    default: null,
  },
  isEnabled: {
    type: Boolean,
    default: true,
  },
};
