<template>
  <div class="d-flex">
    <DateRangePicker
      v-if="byRange"
      :value="value"
      :with-time="withTime"
      label="Диапазон дат"
      hide-details="auto"
      outlined
      :max-range="31"
      :error-messages="errorMessages"
      @input="ev => inputEmit({ ...value, ...ev })"
    />
    <DatePicker
      v-else
      :value="month"
      label="Месяц"
      hide-details="auto"
      first-day-of-week="1"
      outlined
      type="month"
      max-current
      :error-messages="errorMessages"
      @input="changeMonth"
    />
    <div v-if="!onlyMonth" class="ml-3 mt-1">
      <v-checkbox
        v-model="byRange"
        class="mt-2"
        label="Период"
        hide-details="auto"
        @change="handleModeChange"
      />
    </div>
  </div>
</template>

<script>
import { DateTime } from '@/plugins/luxon';

import DateRangePicker from '@/components/controls/DateRangePicker.vue';
import DatePicker from '@/components/controls/DatePicker.vue';

export default {
  components: { DateRangePicker, DatePicker },
  props: {
    value: { required: true, type: Object },
    errorMessages: { type: Array, default: () => [] },
    withTime: Boolean,
    onlyMonth: Boolean,
  },
  data: () => ({
    byRange: false,
  }),

  computed: {
    month() {
      if (!this.value.dateStart) return null;
      return DateTime.fromISO(this.value.dateStart).toFormat('yyyy-MM');
    },
  },
  created() {
    const previousMonth = DateTime.local()
      .minus({ month: 1 })
      .startOf('month')
      .startOf('day');

    this.inputEmit(this.getFullMouth(previousMonth));
  },
  methods: {
    getFullMouth(date) {
      const endOfMonth = date.endOf('month');
      const current = DateTime.local();
      return {
        dateStart: date.toISO(),
        dateEnd:
          endOfMonth.ts < current.ts ? endOfMonth.toISO() : current.toISO(),
      };
    },

    changeMonth(value) {
      const [year, month] = value.split('-');
      const date = DateTime.local(Number(year), Number(month), 1);

      this.inputEmit(this.getFullMouth(date));
    },

    handleModeChange(value) {
      if (value) return;

      const [year, month] = this.value.dateStart.split('-');
      const date = DateTime.local(Number(year), Number(month), 1);

      this.inputEmit(this.getFullMouth(date));
    },

    inputEmit(val) {
      this.$emit('input', val);
    },
  },
};
</script>
