<template>
  <Card title="Соглашение" dense>
    <AsyncWrapper :handler="fetchAgreements">
      <v-simple-table dense>
        <tbody>
          <tr v-for="agreement in agreementList" :key="agreement.type">
            <td class="grey--text px-4 py-3" style="width: 100%">
              {{ agreement.label }}
            </td>
            <td class="pa-0">
              <div class="d-flex justify-end">
                <CardActionBtn
                  :label="agreement.s3key ? 'Открыть' : 'Файл отсутствует'"
                  :disabled="!agreement.s3key"
                  :href="agreement.s3key"
                  target="_blank"
                />
              </div>
            </td>
            <td class="pa-0">
              <CardActionBtn
                v-if="showAddBtn(!agreement.s3key)"
                label="Добавить"
                @click="openFileUploader(agreement)"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </AsyncWrapper>
  </Card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';
import AsyncWrapper from '@/components/AsyncWrapper';
import Card from '@/components/ui/Card';
const AGREEMENTS_TYPES = [
  { label: 'Соглашение ПЭП', type: 'simple-signature' },
  { label: 'Соглашение ОПД', type: 'personal-data' },
];
export default {
  name: 'Agreements',
  components: { Card, AsyncWrapper, CardActionBtn },
  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', ['agreements']),

    agreementList() {
      return AGREEMENTS_TYPES.map(item => ({
        ...item,
        ...this.agreements.find(agr => agr.type === item.type),
      }));
    },

    showAddBtn() {
      return isNew => (isNew ? this.$can('AF_U') : this.$can('AF_P'));
    },
  },
  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', ['fetchAgreements']),
    openFileUploader(agreement) {
      this.$openModal('structures/uploadAgreementFile', {
        isNew: !agreement.s3key,
        type: agreement.type,
      });
    },
  },
};
</script>
