import EventSourcePolyfill from 'eventsource/lib/eventsource';
import qs from 'query-string';
import store from '@/store';
import axios from '@/plugins/axios';
import { reportXhrError } from '@/plugins/sentry';
import { getTraceId } from '@/plugins/nanoid';
import { getServiceName, isXhrError } from '@/utils/helpers';
import env from '@/plugins/env';

function isValidApiResponse({ status, headers, data }) {
  if ([204].includes(status)) return true;

  // temporary fix for ability to correctly process DELETE responses
  // Sergey promised to fix it on backend and send 204 in this case
  if (data === '') return true;

  const contentTypeHeader = headers['content-type'] || '';
  return status > 500 || contentTypeHeader.includes('application/json');
}

export function createHTTP(url) {
  const instance = axios.create({
    baseURL: url,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  instance.interceptors.request.use(async config => {
    const accessToken = await store.dispatch('AUTH/getAccessToken');
    const serviceName = getServiceName(config.baseURL);

    config.headers = {
      ...config.headers,
      Authorization: 'Bearer ' + accessToken,
      'x-trace-id': getTraceId(serviceName),
    };

    return config;
  });

  instance.interceptors.response.use(
    response => {
      // in all statuses, except for a server (nginx) error,
      // the content type must be JSON
      if (!isValidApiResponse(response))
        throw new Error('Content-type must be application/json');

      return response;
    },
    async err => {
      if (err?.name === 'AbortError') return;

      const isXhr = isXhrError(err);
      const originalRequest = err?.config;
      if (
        isXhr &&
        [401, 444].includes(err?.response?.status) &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const accessToken = await store.dispatch('AUTH/refreshToken');
        const serviceName = getServiceName(originalRequest.baseURL);

        originalRequest.headers = {
          ...originalRequest.headers,
          Authorization: 'Bearer ' + accessToken,
          'x-trace-id': getTraceId(serviceName),
        };
        return instance(originalRequest);
      }

      try {
        if (env.VUE_APP_SENTRY_REPORT_XHR && isXhr) reportXhrError(err);
      } catch (e) {}

      return Promise.reject(err);
    },
  );

  return instance;
}

export async function createSSE(url, query) {
  const accessToken = await store.dispatch('AUTH/getAccessToken');
  const serviceName = getServiceName(url);

  const headers = {
    Authorization: 'Bearer ' + accessToken,
    'x-trace-id': getTraceId(serviceName),
  };

  const params = query ? '?' + qs.stringify(query) : '';

  // Hi my little friend, if you have a question "Why I can't see data in
  // SSE connection in developer tools", this issue is for you
  // https://bugs.chromium.org/p/chromium/issues/detail?id=1025893#:~:text=https%3A//github.com/Yaffle/EventSource/issues/79
  //
  // We are using a polyfill to send headers in an sse connection
  // since the EventSource spec does not support headers
  return new EventSourcePolyfill(url + params, { headers });
}
