<template>
  <ItemPageLayout :back-route="backRoute" :title="name">
    <v-card class="pa-6">
      <ApproveIncomingForm
        v-if="canApprove"
        :document="singleItem"
        :employee="employee"
        :back-route="backRoute"
      />

      <AsyncWrapper v-else-if="$can('DOC_RD')" :handler="fetch">
        <div>
          <IncomingItem :document="singleItem" :employee="employee" />
          <div
            v-if="availableAdditionalAction || canArchive"
            class="incoming-item__buttons mt-5"
          >
            <v-btn
              v-if="availableAdditionalAction"
              large
              color="primary"
              @click="performAdditionalAction"
            >
              <span>
                <span v-if="availableAdditionalAction === 'boundaries'">
                  Установить границы работнику
                </span>
                <span v-if="availableAdditionalAction === 'unban'">
                  Разблокировать работника
                </span>
                <br />
                на основании данного документа
              </span>
            </v-btn>

            <v-btn
              v-if="canArchive"
              large
              color="error"
              :loading="$wait('archiveDocument')"
              @click="handleArchiveAction"
            >
              <v-icon class="mr-3">fa-archive</v-icon>
              Архивировать
            </v-btn>
          </div>
        </div>
      </AsyncWrapper>
    </v-card>
  </ItemPageLayout>
</template>

<script>
import ItemPageLayout from '@/components/layouts/ItemPageLayout.vue';
import IncomingItem from '@/modules/documents/incoming/components/IncomingItem';
import ApproveIncomingForm from '@/modules/documents/incoming/components/ApproveIncomingForm';
import AsyncWrapper from '@/components/AsyncWrapper.vue';

import waitable from '@/utils/mixins/waitable';
import { mapActions, mapGetters } from 'vuex';
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';

export default {
  components: {
    ItemPageLayout,
    IncomingItem,
    AsyncWrapper,
    ApproveIncomingForm,
  },
  mixins: [waitable, routeGuardMixin],

  data: () => ({
    backRoute: 'documents:incoming',
  }),

  computed: {
    ...mapGetters('DOCUMENTS/INCOMING_ITEM', [
      'employee',
      'employeeActiveBans',
    ]),
    ...mapGetters('MEDCAB', ['isInspecting']),
    ...mapGetters('MEDCAB_PASSIVE', { isPassiveInspecting: 'isInspecting' }),

    isHasResolution() {
      return !!this.document.resolution;
    },

    id() {
      return this.singleItem.id;
    },

    name() {
      return this.canApprove ? 'Подтверждение документа' : 'Просмотр документа';
    },

    canApprove() {
      return this.$can('DOC_U') && this.singleItem.status === 'PENDING';
    },

    availableAdditionalAction() {
      const { status, applicableFor } = this.singleItem;

      if (status !== 'VALID') return false;

      if (applicableFor === 'boundaries' && this.$can('SETBND'))
        return 'boundaries';
      else if (
        applicableFor === 'unban' &&
        this.$can('UNBN_F') &&
        this.employeeActiveBans[0]
      )
        return 'unban';

      return false;
    },

    canArchive() {
      return (
        this.$can('DOC_AR') &&
        this.singleItem.status === 'VALID' &&
        this.singleItem.status !== 'ARCHIVED' &&
        !this.isInspecting &&
        !this.isPassiveInspecting
      );
    },
  },

  methods: {
    ...mapActions('DOCUMENTS/INCOMING_ITEM', [
      'fetchEmployeeBans',
      'deleteSingleItem',
    ]),
    ...mapActions('EMPLOYEE', ['setBoundaries']),
    ...mapActions('DOCUMENTS/INCOMING', ['fetchList']),

    async fetch() {
      // fir for phantom error when in medcab medic tries to open document
      // which was used to unban a user and for some reason there is no employee
      // id
      await this.$loadingNotify(
        await this.fetchEmployeeBans({
          employeeId: this.employee.id,
          documentType: this.singleItem.type,
        }),
        'fetchingEmployeeBans',
        'У вас нет доступа.',
      );
    },

    handleDecline() {
      this.action = 'decline';
      if (!this.validate()) return;

      this.$loadingNotify(
        this.decline({
          id: this.singleItem.id,
          data: { comment: this.data.comment },
        }).then(_ => {
          this.employee.id && this.fetchMedrec(this.employee.id);
          this.backRoute
            ? this.$router.push({ name: this.backRoute })
            : this.$emit('close');
        }),
        'handleDocument',
        'Ошибка отклонения документа',
        'Документ успешно отклонен',
      );
    },

    performAdditionalAction() {
      if (this.availableAdditionalAction === 'boundaries')
        this.$openModal('documents/boundaries', {
          employee: this.employee,
          document: this.singleItem,
          onSubmit: (id, data) => {
            this.setBoundaries({ id, data });
          },
        });
      else if (this.availableAdditionalAction === 'unban')
        this.$openModal('employees/unban', {
          from: 'document',
          value: this.employeeActiveBans[0],
          document: this.singleItem,
          employee: this.employee,
        });
    },

    handleArchiveAction() {
      this.$openModal('prompt', {
        title: 'Архивировать',
        description: 'Вы точно хотите архивировать документ?',
        messages: {
          successfulAction: 'Документ успешно архивирован',
          errorAction: 'Произошла ошибка архивации документа',
        },
        onSubmit: async () => {
          await this.$loading(
            this.deleteSingleItem(this.singleItem.id),
            'archiveDocument',
          );
          await this.fetchList();
          await this.$router.push({ name: this.backRoute });
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.incoming-item__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}
</style>
