<template>
  <ModernModalLayout
    size="medium"
    :title="
      value ? 'Редактировать запись' : 'Добавить медицинское исследование'
    "
    close-label="Отмена"
    :watch-for="innerValue"
  >
    <v-form :disabled="$wait('submit')" class="pt-1">
      <v-autocomplete
        v-model="innerValue.type"
        label="Тип"
        :items="types"
        :disabled="value !== null"
        item-text="name"
        item-value="type"
        outlined="outlined"
        :error-messages="getValidationErrors('innerValue.type')"
        @blur="handleFieldBlur('innerValue.type')"
      />
      <v-text-field
        v-model="innerValue.value"
        class="mb-3"
        type="number"
        label="Значение"
        outlined
        :suffix="currentType.unit"
        :hint="currentType.description"
        persistent-hint
        :error-messages="getValidationErrors('innerValue.value')"
        @blur="handleFieldBlur('innerValue.value')"
      />
      <DatePicker
        v-model="innerValue.timestamp"
        hide-details="auto"
        outlined
        first-format
        label="Дата исследования"
        max-current
        :error-messages="getValidationErrors('innerValue.timestamp')"
        @blur="handleFieldBlur('innerValue.timestamp')"
      />
    </v-form>

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :loading="$wait('submit')"
        @click="submit"
      >
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import DatePicker from '@/components/controls/DatePicker.vue';
import { currentDateTime } from '@/utils/helpers';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';

export default {
  components: { ModernModalLayout, DatePicker },

  mixins: [validation, waitable],

  props: {
    value: { type: Object, default: null },
    types: { type: Array, required: true },
    handleSubmit: { type: Function, required: true },
  },

  data() {
    return {
      innerValue: {
        type: this.value?.type || this.types[0].type,
        value: this.value?.value || null,
        timestamp:
          this.value?.timestamp || currentDateTime().toFormat('yyyy-MM-dd'),
      },
    };
  },

  validations() {
    return {
      innerValue: {
        type: {
          required,
        },
        value: {
          required,
          minValue: minValue(this.currentType.min),
          maxValue: maxValue(this.currentType.max),
        },
        timestamp: {
          required,
        },
      },
    };
  },

  computed: {
    currentType() {
      return this.types.find(item => item.type === this.innerValue.type);
    },
  },

  methods: {
    async submit() {
      if (!this.validate()) return;
      this.setServerValidationErrors([]);

      await this.$loadingNotify(
        this.handleSubmit(this.innerValue),
        'submit',
        'Произошла ошибка добавления медицинского значения',
        `Медицинское значение добавлено`,
      )
        .then(() => this.$emit('close'))
        .catch(err => this.catchServerErrors(err, 'innerValue'));
    },
  },
};
</script>
