import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import RouteGuard from '@/components/RouteGuard';
import PROFILES from './store/list.js';
import PROFILE from './store/item.js';

export default {
  namespaced: true,
  store: { PROFILES, PROFILE },
  routes: [
    {
      path: 'profiles',
      name: 'profiles',
      component: () => import('./views/list.vue'),
      meta: {
        title: 'Профили конфигураций',
        action: 'GT_FPR',
      },
    },
    {
      path: 'profiles/create',
      name: 'profiles:create',
      component: RouteGuard({
        storeModule: 'ADMIN/PROFILE',
        component: () => import('./views/item.vue'),
        skipFetch: true,
      }),
      meta: {
        action: 'GT_CPR',
      },
    },
    {
      path: 'profiles/:key',
      name: 'profiles:item',
      component: RouteGuard({
        storeModule: 'ADMIN/PROFILE',
        component: () => import('./views/item.vue'),
        primaryKeyName: 'key',
        primaryKeyType: 'string',
      }),
      meta: {
        action: 'GT_GPR',
      },
    },
  ],
  menu: [
    {
      title: 'Профили конфигураций',
      action: 'GT_FPR',
      name: 'profiles',
      priority: 6,
    },
  ],
} as ModuleDefinition;
