<template>
  <v-sheet style="background: rgba(0, 0, 0, 0.06)" class="text-h6 py-1 px-6">
    <slot>{{ title }}</slot>
  </v-sheet>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
