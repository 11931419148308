<template>
  <List
    search-filter
    :available-actions="{
      showAction: $can('ORG_RD'),
      createAction: $can('ORG_C'),
    }"
    @item:create="formModal"
  >
    <template #filters="query">
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.isActive"
          label="Статус организации"
          :items="statusItems"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :menu-props="{ offsetY: true }"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <CategorySelect v-model="query.categoryId" dense />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <OrganizationGroupSelect
          v-model="query.groupIds"
          multiple
          dense
          :is-holding="false"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <OrganizationGroupSelect
          v-model="query.holdingIds"
          dense
          is-holding
          multiple
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.isMedical"
          label="С медработниками"
          :items="booleanItems"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :menu-props="{ offsetY: true }"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.legalForm"
          label="Правовая форма"
          :items="legalForm"
          item-value="id"
          item-text="name"
          hide-details
          outlined
          dense
          :menu-props="{ offsetY: true }"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.niiatStatusEnabled"
          label="Отправка осмотров в НИИАТ"
          :items="booleanItems"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :menu-props="{ offsetY: true }"
        />
      </v-col>
    </template>

    <template #table.item.nameShortName="{ item }">
      <b>{{ item.name }}</b>
      <div v-if="item.shortName">{{ `Сокр.: ${item.shortName}` }}</div>
      <div v-if="item.holding">{{ `Холдинг: ${item.holding.name}` }}</div>
    </template>

    <template #table.item.inn_kpp="{ item }">
      ИНН:&nbsp;{{ item.inn }}
      <div v-if="item.kpp">КПП:&nbsp;{{ item.kpp }}</div>
      <div v-if="item.ogrn">
        {{ ogrnTitle[item.legalForm] }}: {{ item.ogrn }}
      </div>
      <div v-if="item.legalForm">{{ legalFormName(item.legalForm) }}</div>
    </template>

    <template #table.item.isActive="{ item }">
      <Indicator :active="item.isActive" />
    </template>

    <template #table.item.isMedical="{ item }">
      <Round :type="item.isMedical ? 'green lighten-2' : 'grey lighten-1'" />
    </template>
  </List>
</template>

<script>
import ListFactory from '@/components/crud/ListFactory';
import {
  STATUS,
  BOOLEANS,
  CONFIG_TYPES,
  LEGAL_FORM,
  OGRN_TITLE,
  LEGAL_FORM_FILTER,
} from '@/utils/constants.js';
import Round from '@/components/Round.vue';
import Indicator from '@/components/Indicator.vue';

import CategorySelect from '@/components/controls/structures/CategorySelect';
import OrganizationGroupSelect from '@/components/controls/structures/OrganizationGroupSelect';
import { messages, entity, filters } from '../entity';

const List = ListFactory({
  storeModule: 'STRUCTURES/ORGANIZATION_LIST',
  messages,
  entity,
  filters,
  viewRoute: 'structures:organizations:item',
});

export default {
  components: {
    List,
    CategorySelect,
    OrganizationGroupSelect,
    Round,
    Indicator,
  },

  data: () => ({
    configType: CONFIG_TYPES,
  }),

  computed: {
    statusItems: () => STATUS,
    booleanItems: () => BOOLEANS,
    ogrnTitle: () => OGRN_TITLE,
    legalForm: () => LEGAL_FORM_FILTER,
  },

  methods: {
    formModal() {
      this.$openModal('structures/organizationForm');
    },
    legalFormName(legalFormId) {
      return LEGAL_FORM.find(e => e.id === legalFormId)?.name;
    },
  },
};
</script>
