export const messages = {
  resourceName: 'задач',
};

export const entity = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'activatedAt',
    label: 'Статус',
    headerProps: {
      width: '60px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
    convert: item => (item.activatedAt ? 'Активна' : 'Неактивна'),
  },
  {
    key: 'name',
    label: 'Название',
  },
  {
    key: 'params',
    label: 'Кол-во получателей',
    headerProps: {
      width: '100px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
    convert: item => item.params.receivers.length,
  },
];

export const filters = {
  active: {
    type: Boolean,
    default: true,
  },
};
