import notifApi from '@/api/services/notifications';

export default {
  state: {
    subscriptions: null,
  },

  getters: {
    subscriptions: state => state.subscriptions || [],
  },

  mutations: {
    subscriptions: (state, value) => (state.subscriptions = value),
  },

  actions: {
    async fetchSubscriptions({ commit, getters }) {
      const subs = await notifApi.getAccountSubscriptions(getters.id);
      commit('subscriptions', subs);
    },
  },
};
