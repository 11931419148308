import env from '@/plugins/env';
import { createHTTP } from '../api';

export const blame = createHTTP(env.get('VUE_APP_BLAME_API'));

export default {
  getEntityReport(params) {
    return blame.get(`/report/entity/`, { params }).then(res => res.data);
  },

  getAccountReport(params) {
    return blame.get(`/report/account/`, { params }).then(res => res.data);
  },
};
