<template>
  <ActionModalLayout title="Загрузить новую фотографию">
    <UploaderBox
      v-if="!photo"
      :error-messages="error"
      @change="inputUploaderHandler"
    />

    <div v-if="fileAsUrl">
      <v-img
        max-height="400"
        style="border: 1px solid #ccc; border-radius: 4px"
        :src="fileAsUrl"
        contain
        eager
      />
      <p v-if="error" class="error--text">{{ error.join('; ') }}</p>
      <div class="text-center mt-1 mb-1">
        <v-btn
          class="mr-1"
          color="primary"
          small
          text
          outlined
          @click="openEditor"
        >
          Редактировать
        </v-btn>
        <v-btn color="primary" small text outlined @click="clear">
          Удалить
        </v-btn>
      </div>
    </div>

    <template #actions>
      <v-btn v-if="photo" color="primary" text @click="upload">
        Загрузить
      </v-btn>
    </template>
  </ActionModalLayout>
</template>

<script>
import Vue from 'vue';
import waitable from '@/utils/mixins/waitable';
import UploaderBox from '@/components/controls/UploaderBox.vue';
import ActionModalLayout from '@/components/layouts/ActionModalLayout';

const mesgErrorFF = {
  70004000: 'Низкое качество фото',
  70004001: 'На фото нет лиц',
  70004002: 'На фото найдено несколько лиц',
};

export default Vue.extend({
  components: { ActionModalLayout, UploaderBox },
  extends: waitable,
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      originalFile: null,
      photo: null,
      error: null,
    };
  },

  computed: {
    fileAsUrl() {
      return this.photo ? URL.createObjectURL(this.photo) : null;
    },
  },

  methods: {
    clear() {
      this.photo = null;
      this.error = null;
    },

    async upload() {
      this.$loadingNotify(
        this.$store
          .dispatch('EMPLOYEE/uploadPhoto', {
            id: this.item.id,
            photo: this.photo,
          })
          .then(() => this.$emit('close'))
          .catch(err => {
            const code = err.response?.data?.code;
            this.error = mesgErrorFF[code]
              ? [mesgErrorFF[code]]
              : ['Произошла ошибка загрузки нового фото'];
          }),
        'Произошла ошибка загрузки нового фото',
        'Новое фото работника успешно загружено',
      );
    },

    async inputUploaderHandler(file) {
      this.originalFile = file;
      this.photo = file;
      this.error = null;

      if (file) {
        await this.$nextTick();
        this.openEditor();
      }
    },

    openEditor() {
      this.$openModal('imageEditor', {
        value: this.originalFile,
        change: editedFile => (this.photo = editedFile),
      });
    },
  },
});
</script>
