import { getUserTimeZone } from '@/utils/convert';

export const entity = [
  {
    key: 'inspection',
    label: 'Осмотр',
    headerProps: {
      width: '22%',
    },
  },
  {
    key: 'employee',
    label: 'Работник',
    headerProps: {
      width: '22%',
    },
  },
  {
    key: 'result',
    label: 'Результат',
  },
];

export const messages = {
  resourceName: 'осмотров',
  searchFilterLabel: 'Поиск по осмотрам',
  searchFilterPlaceholder: '',
  goById: {
    title: 'Перейти по ID',
    label: 'Введите ID осмотра',
    actionButton: 'Перейти',
    notFound: 'Осмотр не найден',
    forbidden: 'Осмотр не доступен',
  },
};

export const filters = {
  employeeSearch: { type: String, default: null },
  dateStart: { type: String, default: null },
  dateEnd: { type: String, default: null },
  success: { type: String, default: null },
  types: { type: Array, default: null },
  orgIds: { type: Array, default: null },
  orgGroupIds: { type: Array, default: null },
  employeeGroupIds: { type: Array, default: null },
  licenses: { type: Array, default: null },
  pointIds: { type: Array, default: null },
  employeeIds: { type: Array, default: null },
  remarks: { type: Array, default: null },
  isTest: { type: Boolean, default: false },
  isImported: { type: Boolean, default: null },
  timezone: { type: Number, default: getUserTimeZone() },
  isTechError: { type: Boolean, default: false },
  isInspected: { type: Boolean, default: null },
};
