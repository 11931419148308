import { ModuleDefinition } from '@/models/app/ModuleDefinition';

export default {
  hasChildren: true,
  menu: [
    {
      title: 'Медблок',
      name: 'medblock',
      priority: 5,
    },
  ],
  routes: [
    {
      path: '/medblock',
      name: 'medblock',
      component: () => import('@/views/Provider.vue'),
    },
  ],
} as ModuleDefinition;
