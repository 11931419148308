<template>
  <ItemPageLayout back-route="medblock:medic_groups" :title="singleItem.name">
    <Card title="Данные группы" dense>
      <DefinitionList :value="dataTable" />
      <template #action>
        <CardActionBtn
          v-if="$can('MEDG_U')"
          label="Редактировать"
          @click="editModal"
        />
      </template>
    </Card>

    <template #aside>
      <ActionsMenu>
        <ActionsMenuDelete
          v-if="$can('MEDG_D')"
          :id="singleItem.id"
          store-module="MEDBLOCK/MEDIC_GROUPS"
          back-route="medblock:medic_groups"
          title="Удалить группу медработников"
          description="Вы уверены, что хотите удалить группу?"
          :disabled="!!singleItem.itemsCount"
        />
      </ActionsMenu>
    </template>
  </ItemPageLayout>
</template>

<script>
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';
import ItemPageLayout from '@/components/layouts/ItemPageLayout';
import Card from '@/components/ui/Card';
import ActionsMenu from '@/components/ui/ActionsMenu';
import DefinitionList from '@/components/DefinitionList';
import ActionsMenuDelete from '@/components/ActionsMenuDelete';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';
import { mapGetters } from 'vuex';

export default {
  components: {
    ItemPageLayout,
    DefinitionList,
    Card,
    ActionsMenu,
    ActionsMenuDelete,
    CardActionBtn,
  },
  mixins: [routeGuardMixin],

  computed: {
    ...mapGetters('MEDBLOCK/MEDIC_GROUPS_ITEM', ['medOrg']),

    dataTable() {
      const data = this.singleItem;
      const medOrgName = this.medOrg?.name ?? '–';
      return [
        { value: data.id, label: 'ID' },
        { value: data.name, label: 'Имя' },
        { value: data.description, label: 'Описание' },
        { value: `${data.itemsCount}`, label: 'Количество медработников' },
        { value: medOrgName, label: 'Организация' },
      ];
    },
  },

  methods: {
    editModal() {
      this.$openModal('medblock/medicGroupForm', {
        id: this.singleItem.id,
        value: this.singleItem,
      });
    },
  },
};
</script>
