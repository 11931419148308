export const messages = {
  resourceName: 'групп уведомлений по поверке ПАК',
  searchFilterLabel: 'Поиск по названию',
  searchFilterPlaceholder: '',
  create: {
    title: 'Добавление профиля',
    actionButton: 'Добавить',
    successfulAction: 'Профиль успешно создан',
    errorAction: 'Произошла ошибка создания профиля',
  },
};

export const entity = [
  { key: 'id', label: 'id' },
  { key: 'name', label: 'Название' },
  {
    key: 'filters',
    label: 'Организации/Группы организаций',
    convert: item => item.filters.length || 'По всем',
  },
  {
    key: 'recipients',
    label: 'Получатели',
    convert: item => item.recipients.length,
  },
];
