import axios from '@/plugins/axios';
import env from '@/plugins/env';
import Sentry from '@/plugins/sentry';
import processingPoolFactory from '@/utils/processingPoolFactory';
import resourceServiceFactory from '@/utils/resourceServiceFactory';
import { createHTTP } from '../api';

const processingUrl = env.get('VUE_APP_PROCESSING_API') + '/v3';
export const processing = createHTTP(processingUrl);
export const terminalBff = createHTTP(env.get('VUE_APP_TERMINAL_BFF_API'));
export const esMedicUrl = processingUrl + '/events';

const actions = {
  // Profiles
  getOrganizationProfilePreview(id: number) {
    return processing.get(`/organizations/${id}/profile`).then(res => res.data);
  },

  getProfilesPreview() {
    return processing.get('/profiles').then(res => res.data);
  },

  changeOrganizationProfile(id: number, profileKey: string) {
    return processing
      .put(`/organizations/${id}/profile/${profileKey}`)
      .then(res => res.data);
  },

  // Active Pool
  ...processingPoolFactory({ url: processingUrl, poolType: 'active' }),
  pauseShiftActive(params: { action: string }) {
    return processing
      .post(`/active_pool/my/shift/pause`, params)
      .then(res => res.data);
  },
  resumeShiftActive() {
    return processing
      .post(`/active_pool/my/shift/resume`)
      .then(res => res.data);
  },

  // Passive Pool
  ...processingPoolFactory({ url: processingUrl, poolType: 'passive' }),
  getInspectionsPassive(params: { pointId?: number; search?: string } = {}) {
    return processing
      .get(`/passive_pool/inspections`, { params })
      .then(res => res.data);
  },
  releaseInspectionPassive(id: number) {
    return processing
      .post(`/passive_pool/inspections/${id}/release`)
      .then(res => res.data);
  },

  getTypes() {
    return processing.get('/inspections/types').then(res => res.data);
  },

  getRemarks() {
    return processing.get('/inspections/remarks').then(res => res.data);
  },

  async generateFakeInspection(id: number) {
    return terminalBff.post(`/inspections/fakes/${id}`).then(res => res.data);
  },

  async getFakes() {
    return terminalBff.get('/inspections/fakes').then(res => res.data);
  },

  // Medic
  ...resourceServiceFactory(processing, 'medic', ['status']),
  getMedicsPreviewsBy(medicIds: number[]) {
    const params = {
      medicIds: medicIds.join(','),
    };
    return processing
      .get('/medics/previews_by', { params })
      .then(res => res.data);
  },
  getMedicsPreviews(params: any) {
    return processing.get('/medics/previews', { params }).then(res => res.data);
  },

  getMedicGroupPreviews(params: any) {
    return processing
      .get('/medic_groups/previews', { params })
      .then(res => res.data);
  },

  getMedicGroupPreviewsBy(medGroupIds: any) {
    const params = {
      ids: medGroupIds.join(','),
    };
    return processing
      .get('/medic_groups/previews_by', { params })
      .then(res => res.data);
  },

  myMedic() {
    return processing.get('/medics/my/medic').then(res => res.data);
  },
  changeMedicOrganization(
    medicId: number,
    params: {
      orgId: number;
      categoryIds: [number];
      groupIds: [number];
    },
  ) {
    return processing
      .put(`/medics/${medicId}/organization`, params)
      .then(res => res.data);
  },

  // Medics groups
  ...resourceServiceFactory(processing, ['medic_groups']),

  medicUpdateRole(id: number, roleId: number) {
    return processing
      .put(`/medics/${id}/roles/${roleId}`)
      .then(res => res.data);
  },

  medicUpdateCategories(id: number, categoryIds: number[]) {
    return processing
      .put(`/medics/${id}/categories`, { categoryIds })
      .then(res => res.data);
  },

  medicUpdateGroups(id: number, groupIds: number[]) {
    return processing
      .put(`/medics/${id}/groups`, { groupIds })
      .then(res => res.data);
  },

  // Statistics
  getStatsMedic(medicId: number, params: any) {
    return processing
      .get(`/stats/medics/${medicId}`, { params })
      .then(res => res.data);
  },
  getMyStats(params: any) {
    return processing.get(`/my/stats/`, { params }).then(res => res.data);
  },

  getStatsMedicShifts(shiftId: number) {
    return processing.get(`/stats/shifts/${shiftId}`).then(res => res.data);
  },
  getMyStatsShifts(shiftId: number) {
    return processing.get(`/stats/my/shifts/${shiftId}`).then(res => res.data);
  },

  /**
   * Замер задержек между клиентом и сервером.
   * Общедоступная функция (не требует разрешений на сервере)
   */
  async time(
    isRepeated = false,
  ): Promise<{ server: number; client: number; ping: number }> {
    // NOTE:
    // В этой реализации замеряется время на функции обращения к серверу.
    // Его считаем за время запроса на сервер и обратно (пинг туда-обратно).
    // Спустя некоторое время, обнаружили, что браузер может остановить
    // запрос к серверу, если перейти на другую вкладку. А также пинг будет
    // неверным, если запрос попал в очередь долгих запросов и ждал своего часа.
    //
    // Если снова будут проблемы - убейте его и переходите на WebSocket.
    // Он постоянно держит подключение и не будет вставать в очередь.
    // "Чем этого лечить проще нового сделать" (с) Старая Библейская Мудрость.
    const start = performance.now();
    const data = await axios.get(`${processingUrl}/time`).then(res => res.data);
    const end = performance.now();

    const dateServer = new Date(data.serverTime);
    const dateClient = new Date();

    const result = {
      server: dateServer.getTime(),
      client: dateClient.getTime(),
      ping: Math.ceil(end - start),
    };

    // Если на первый запрос вернулась ошибка, то сразу сделать второй.
    // Это пригодится, когда запрос стоял в очереди.
    if (!isRepeated && result.ping > 2000) return actions.time(true);

    Sentry.setContext('Time', {
      'time server': dateServer.toString(),
      'time client': dateClient.toString(),
      'diff between times': Math.abs(
        Math.ceil((result.server - result.client) / 1000),
      ),
      'ping (ms)': result.ping,
    });

    return result;
  },

  /** @action INS_LD */
  async employeeCurrentDetails(id: number) {
    return processing.get(`employees/${id}/current`).then(res => res.data);
  },

  /** @action INS_DET */
  async inspectionDetails(id: number) {
    return processing.get(`inspections/${id}/details`).then(res => res.data);
  },

  // --- Settings ---

  /** @action PS_G */
  async getSettings(orgId: number) {
    return processing
      .get(`organizations/${orgId}/settings`)
      .then(res => res.data);
  },

  /** @action PS_P */
  async setSettings(orgId: number, payload: Record<string, unknown>) {
    return processing
      .patch(`organizations/${orgId}/settings`, payload)
      .then(res => res.data);
  },

  // --- Settings end ---
};

export default actions;
