<template>
  <v-bottom-sheet
    :value="show"
    persistent
    no-click-animation
    hide-overlay
    :retain-focus="false"
    @input="handleClose"
  >
    <v-sheet style="background: rgba(0, 0, 0, 0.8)" class="rounded-t pa-3" dark>
      <v-layout style="gap: 16px">
        <div>
          <v-icon>fas fa-info-circle</v-icon>
        </div>
        <div style="width: 100%">
          <div>В браузере обнаружены ограничения на работу приложения</div>
          <div class="text-body-2" style="opacity: 0.7">
            Обнаружена ошибка при использовании компонента localStorage.
            <br />Нет возможности сохранить активную сессию.
            <br /><br />Возможные причины: <br />– Приложение открыто в
            приватной вкладки (режим инкогнито) с включенной блокировкой
            сторонних файлов Cookie. <br />– Выставлены правила с запретом на
            использование локального хранилища для этого или всех сайтов.
            <br />– Уточните у инфраструктуры компании политику использования
            Cookie / LocalStorage в вашей компании <br /><br />Если проблема не
            решается, то попробуйте воспользоваться другим браузером.
          </div>
        </div>
        <div>
          <v-btn icon @click="handleClose">
            <v-icon>fa-times</v-icon>
          </v-btn>
        </div>
      </v-layout>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { WebStorage } from '@/plugins/webStorage';

export default {
  data: () => ({
    show: false,
  }),

  mounted() {
    try {
      const deniedLocalStorage = !WebStorage.isAccess();
      if (deniedLocalStorage) this.show = true;
    } catch (err) {
      // Отобразить предупреждение об отключенном localStorage
      this.show = true;
      console.error(err);
    }
  },

  methods: {
    handleClose() {
      this.show = false;
    },
  },
};
</script>
