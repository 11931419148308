<template>
  <div>
    <v-simple-table v-if="value.length > 0" dense class="text-center">
      <thead>
        <tr>
          <th class="text-center py-3">ID</th>
          <th class="text-center py-3">Название</th>
          <th class="text-center py-3">Профиль</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="subscription in value" :key="subscription.id">
          <td class="py-1">
            <v-btn
              v-if="$can('NF_SR')"
              small
              text
              dense
              color="primary"
              :to="{
                name: 'notifications:subscriptions:item',
                params: { id: subscription.id },
              }"
            >
              {{ subscription.id }}
            </v-btn>
            <span v-else>{{ subscription.id }}</span>
          </td>
          <td>{{ subscription.name }}</td>
          <td>
            <v-btn
              v-if="$can('NF_PR')"
              small
              text
              color="primary"
              :to="{
                name: 'notifications:profiles:item',
                params: { key: subscription.profile.key },
              }"
            >
              {{ subscription.profile.name }}
            </v-btn>
            <span v-else>{{ subscription.profile.name }}</span>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <NoDataAlert v-else class="ma-4">{{ noSubsText }}</NoDataAlert>
  </div>
</template>

<script>
import NoDataAlert from '@/components/ui/NoDataAlert';
export default {
  components: { NoDataAlert },
  props: {
    value: { type: Array, required: true },
    self: { type: Boolean, default: false },
  },
  computed: {
    noSubsText() {
      return (this.self ? 'У вас' : 'У пользователя') + ' нет никаких подписок';
    },
  },
};
</script>
