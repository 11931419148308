<template>
  <v-hover :disabled="disabled">
    <template #default="{ hover }">
      <v-card
        :class="`elevation-${hover ? 10 : 2}`"
        class="ma-0 transition-swing"
        @click="clickOnCard"
      >
        <v-layout
          v-if="disabled"
          class="card__container d-flex flex-column align-center justify-center"
          style="
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
          "
        >
          <h3>{{ loading ? message.loading : message[value.status] }}</h3>
        </v-layout>

        <v-layout
          class="pa-5 card__container d-flex flex-column"
          :class="{ 'card__container--blur': disabled }"
          style="position: relation; z-index: 1"
        >
          <div class="card d-flex flex-row align-start">
            <div class="card__details">
              <div class="card__id">{{ value.id }}</div>
              <div class="card__type">
                {{ value.type.details.language.nominative.short }}
              </div>
              <div class="card__additional">
                <div class="card__additional__group">
                  <span class="card__additional__date">
                    {{ finishedDate.date }}
                  </span>
                  <time class="font-weight-medium">
                    {{ finishedDate.time }}
                  </time>
                </div>
                <div class="card__additional__group">
                  Длительность: {{ duration }}
                </div>
              </div>
            </div>

            <div class="card__employee">
              <div class="card__employee__name mb-2">
                <EmployeeName :value="employee" show-age />
              </div>
              <div class="card__employee__additional">
                <div>Табельный №: {{ employee.personnelNumber }}</div>
                <div>Организация: {{ organization.name }}</div>
                <div>
                  Точка:
                  <span class="font-weight-medium">
                    {{ pointText }}
                  </span>
                </div>
              </div>
            </div>

            <div class="card__scenarios d-flex justify-end align-start">
              <Scenario
                with-icons
                :steps="steps"
                :remarks="remarks"
                :labels="labels"
              />
            </div>
          </div>
          <AssistantRecommendation
            :assistance="assistance"
            :steps="steps"
            :type="type"
            only-text
            class="mt-4 px-3 py-1 rounded"
          />
        </v-layout>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
import { mapGetters } from 'vuex';
import { dateLang, getTime, name, duration } from '@/utils/convert';

import EmployeeName from '@/modules/employees/components/EmployeeName.vue';
import Scenario from './Scenario.vue';
import AssistantRecommendation from './AssistantRecommendation.vue';

export default {
  components: { Scenario, EmployeeName, AssistantRecommendation },

  props: {
    value: { required: true, type: Object },
    loading: Boolean,
  },

  computed: {
    ...mapGetters('MEDCAB', ['isInspecting']),
    ...mapGetters(['themeIsDark']),

    steps() {
      return this.value?.steps;
    },
    employee() {
      return this.value?.employee;
    },
    host() {
      return this.value?.host;
    },
    organization() {
      return this.value?.organization;
    },

    remarks() {
      return this.value.assistance.remarks
        .map(({ id, value }) => value && id)
        .filter(_ => _);
    },
    labels() {
      return this.value.assistance.labels;
    },
    finishedDate() {
      const value = this.value.timestamps.finishedAt;
      return {
        date: dateLang(value),
        time: getTime(value),
      };
    },
    duration() {
      return duration(
        this.value.timestamps.startedAt,
        this.value.timestamps.finishedAt,
      );
    },
    pointText() {
      const p = this.host.releasePoint;
      // no address? coordinates/mobile point doesn't matter - always return id
      return p.address || '#' + p.id;
    },

    assistance() {
      return this.value?.assistance || {};
    },
    type() {
      return this.value?.type;
    },

    disabled() {
      return this.value.status !== 'ready' || this.loading;
    },

    message() {
      return {
        loading: 'Запрошен в работу',
        taken: 'В работе у медработника',
        resolved: 'Принято решение',
      };
    },
  },

  methods: {
    name,

    clickOnCard() {
      if (this.value.status === 'ready') return this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  gap: 20px;

  &__container {
    &--blur {
      opacity: 0.3;
      filter: blur(3px);
    }
  }

  &__details {
    width: 160px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }

  &__id {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &__type {
    margin-top: 7px;
    font-size: 14px;
    line-height: 16px;
  }

  &__additional {
    margin-top: 13px;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    & > *:not(:first-child) {
      margin-top: 4px;
    }
  }

  &__employee {
    width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;

    &__name {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
    }
    &__additional {
      font-size: 14px;
      line-height: 16px;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-end;
      & > *:not(:first-child) {
        margin-top: 4px;
      }
    }
  }

  &__scenarios {
    width: 100%;
  }
}
</style>
