<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="1">
          <v-tooltip right :disabled="!expired">
            <template #activator="{ on, attrs }">
              <div v-on="on">
                <v-btn
                  class="px-3"
                  style="min-width: auto"
                  color="primary"
                  :outlined="!item.isLoading"
                  :loading="item.isLoading"
                  :disabled="item.isLoading || !!item.error || expired"
                  :download="filename"
                  :href="url"
                  v-bind="attrs"
                  @click="$emit('toggle')"
                >
                  <v-icon>
                    {{
                      isChart
                        ? 'mdi-eye'
                        : expired
                        ? 'mdi-timer-outline'
                        : 'mdi-download'
                    }}
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <span>Время жизни ссылки истекло</span>
          </v-tooltip>
        </v-col>
        <v-col cols="11">
          <div
            class="text-subtitle-1"
            :class="[themeIsDark ? 'white--text' : 'black--text']"
            @click="$emit('toggle')"
          >
            {{ report.name }} – {{ status }}
          </div>
          <div @click="$emit('toggle')">{{ date }}</div>
          <div v-if="isRoot" class="ml-4 my-2">
            <span class="text-subtitle-2">Параметры отчета:</span>
            <json-viewer
              :value="item.filters"
              theme="jv-theme"
              expanded
              :expand-depth="Infinity"
              sort
              :show-array-index="false"
              copyable
            />
          </div>
          <div v-if="isChart && expanded">
            <component
              :is="chartComponent"
              :value="item.data.result.rows"
              :timezone="filters.timezone"
            />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
  </v-card>
</template>

<script>
import charts from '@/modules/reports/charts';
import JsonViewer from '@/plugins/json-viewer';
import { dateTime } from '@/utils/convert';
import { mapGetters } from 'vuex';

export default {
  components: { JsonViewer },
  props: {
    item: { type: Object, default: () => ({}) },
    report: { type: Object, default: () => ({}) },
    expanded: Boolean,
  },
  data: () => ({ expired: false, timeout: null }),

  computed: {
    ...mapGetters(['themeIsDark']),
    ...mapGetters('AUTH', ['isRoot']),
    ...mapGetters('REPORTS', ['filters']),

    url() {
      return this.item.data?.url || '';
    },
    filename() {
      return this.item.data?.filename || '';
    },
    isChart() {
      return this.item.data?.result?.rows;
    },
    status() {
      if (this.item.error) {
        return 'Ошибка';
      } else if (this.item.isLoading) {
        return 'Формируется';
      } else if (this.item.data.result?.rows) {
        return 'График сформирован';
      } else if (this.expired) {
        return 'Время действия ссылки истекло';
      }
      return 'Готов к скачиванию';
    },
    date() {
      if (this.item.timestamp)
        return dateTime(new Date(this.item.timestamp).toISOString());
      return '';
    },
    chartComponent() {
      return charts[this.report.chart];
    },
  },

  watch: {
    'item.isLoading': {
      handler(val) {
        if (!val)
          // after 30 mins disable link
          this.timeout = setTimeout(
            () => (this.expired = true),
            30 * 60 * 1000,
          );
      },
    },
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },
};
</script>
