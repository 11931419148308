import Vue from 'vue';
import AsyncSelect from '@/components/controls/AsyncSelect';

export default Vue.extend({
  components: { AsyncSelect },
  props: {
    value: { type: [String, Number, Array], default: null },
    errorMessages: { type: Array, default: null },
    hint: { type: [Array, String], default: null },
    multiple: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    dense: Boolean,
    clearable: { type: Boolean, default: null },
    hideDetails: { type: [Boolean, String], default: 'auto' },

    // explanation on props below read in 'asyncselect' component
    excludeSelf: { type: Boolean, default: false },
    selfValue: { type: [Number, String], default: null },
  },

  computed: {
    label() {
      return '';
    },
    query() {
      return {};
    },

    computedValue() {
      return this.value;
    },

    computedProps() {
      return {
        fetcher: this.fetcher,
        fetcherBy: this.fetcherBy,
        query: this.query,
        label: this.label,

        // props to props
        value: this.computedValue,
        multiple: this.multiple,
        disabled: this.disabled,
        readonly: this.readonly,
        errorMessages: this.errorMessages,
        dense: this.dense,
        hideDetails: this.hideDetails,
        hint: this.hint,
        excludeSelf: this.excludeSelf,
        selfValue: this.selfValue,
        itemValue: this.itemValue,

        // ХЗ зачем, но Олегу было нужно дефолтно задать "true"
        clearable: this.clearable !== null ? this.clearable : true,
      };
    },
  },

  methods: {
    fetcher() {},
    fetcherBy() {},

    handleInput(event) {
      this.$emit('input', event);
    },
  },

  render() {
    return this.$createElement(AsyncSelect, {
      props: this.computedProps,
      attrs: this.$attrs,
      on: {
        ...this.$listeners,
        input: this.handleInput,
      },
    });
  },
});
