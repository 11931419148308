<template>
  <v-list-item
    v-if="show"
    style="min-width: 230px"
    @click.stop="promptStatusChange"
  >
    <v-list-item-icon>
      <v-icon v-if="currentStatus">mdi-thumb-down-outline</v-icon>
      <v-icon v-else>mdi-thumb-up-outline</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{ action }}
      </v-list-item-title>
      <v-list-item-subtitle>Действие можно отменить</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { xhrErrorMessage } from '@/utils/helpers';

export default {
  props: {
    item: { type: Object, default: () => ({}) },
    storeModule: { type: String, default: '' },
    instance: { type: String, default: '' },
    canEnable: { type: Boolean, default: true },
    status: { type: Boolean, default: null },
    canDisable: { type: Boolean, default: true },
    handler: {
      type: Function,
      default(newStatus) {
        return this.$store.dispatch(
          this.storeModule + '/changeStatus',
          newStatus,
        );
      },
    },
  },

  computed: {
    currentStatus() {
      return this.status || this.item?.isEnabled || this.item?.isActive;
    },
    show() {
      return this.currentStatus ? this.canEnable : this.canDisable;
    },
    action() {
      return `${this.currentStatus ? 'Деактивировать' : 'Активировать'} ${
        this.instance
      }`;
    },
  },

  methods: {
    promptStatusChange() {
      this.$openModal('prompt', {
        title: this.action + '?',
        messages: {
          successfulAction: 'Статус успешно изменен',
          errorAction: err => {
            // Такая обработка необходима для вывода сообщений, приходящих с бэка
            // критично при активации медиков в медблоке
            let errorMsg = null;
            const inner = err?.response?.data?.inner;
            if (Array.isArray(inner)) {
              errorMsg = inner
                ?.reduce(
                  (acc = [], el) => [...acc, ...Object.values(el.errors)],
                  [],
                )
                .join('; ');
            } else if (inner?.message) {
              // а такая обработка при активации медика с почтой, как у пользователя...
              // на бэке снова "невозможно" привести формат к единому
              errorMsg = inner.message;
            }

            this.$notify({
              group: 'note',
              type: 'error',
              title: 'Произошла ошибка изменения статуса',
              text: errorMsg || xhrErrorMessage(err),
            });
          },
        },
        onSubmit: () => this.handler(!this.currentStatus),
      });
    },
  },
};
</script>
