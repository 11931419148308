<template>
  <div class="medcab" :style="[boxHeight]">
    <v-layout fill-height>
      <slot />
    </v-layout>

    <MedcabActionsPassive />
  </div>
</template>

<script>
import MedcabActionsPassive from './MedcabActionsPassive.vue';

export default {
  components: {
    MedcabActionsPassive,
  },

  data: () => ({
    headerHeight: 0,
  }),

  computed: {
    boxHeight() {
      return { minHeight: `calc(100vh - ${this.headerHeight})` };
    },
  },

  mounted() {
    const header = document.getElementsByTagName('header')?.[0];
    if (header) this.headerHeight = header.style.height;
  },
};
</script>

<style lang="scss" scoped>
.medcab {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 104px;

  &__actions {
    height: 92px;
    position: sticky;
    bottom: 0;
  }
}
</style>
