import env from '@/plugins/env';
import resourceServiceFactory from '@/utils/resourceServiceFactory';
import { createHTTP } from '../api';

export const stickers = createHTTP(env.get('VUE_APP_STICKERS_API') + '/v3');

export default {
  ...resourceServiceFactory(stickers, 'profile', ['put']),

  getStrategies() {
    return stickers.get('/strategies').then(res => res.data);
  },

  bindOrgsToProfile(id, data) {
    return stickers
      .put(`/profiles/${id}/organizations`, data)
      .then(res => res.data);
  },

  reprint(id, params = {}) {
    return stickers
      .post(`/inspections/${id}/reprint`, params)
      .then(res => res.data);
  },

  getOrganizationProfilePreview(id) {
    return stickers
      .get(`/organizations/${id}/profile/preview`)
      .then(res => res.data);
  },

  getProfilesPreview() {
    return stickers.get(`/profiles/previews`).then(res => res.data);
  },

  changeOrganizationProfile(id, profileKey) {
    return stickers
      .put(`/organizations/${id}/profile/${profileKey}`)
      .then(res => res.data);
  },
};
