<template>
  <ItemPageLayout back-route="employees:list" :title="item.name">
    <template #header>
      <Card dense hide-title>
        <v-tabs v-model="currentTab">
          <v-tab href="#general">Основное</v-tab>
          <v-tab
            v-if="$can('INS_LD') || $can('CMNT_R') || $can('I_RMI')"
            href="#meddata"
          >
            Медицинские данные
          </v-tab>
          <v-tab v-if="showRiskTab" href="#risks"> Профиль здоровья </v-tab>
          <v-tab v-if="$can('EBN_R')" href="#bans">Блокировки</v-tab>
          <v-tab v-if="$can('BLM_ER')" href="#logs">Лог изменений</v-tab>
        </v-tabs>
      </Card>
    </template>

    <div
      v-if="currentTab === 'general'"
      style="display: flex; flex-direction: column; gap: 24px"
    >
      <Card title="Фото" dense>
        <Photo :item="singleItem" height="400px" @error="photoIsLoadingError" />
        <template #action>
          <v-menu v-if="rulesPhoto.upload && rulesPhoto.archive">
            <template #activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon size="24">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="uploadPhotoModal">
                <v-list-item-title>Загрузить новое фото</v-list-item-title>
              </v-list-item>

              <v-list-item @click="archivePhotoModal">
                <v-list-item-title>Архивировать</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <CardActionBtn
            v-else-if="rulesPhoto.upload"
            label="Загрузить новое фото"
            @click="uploadPhotoModal"
          />
          <CardActionBtn
            v-else-if="rulesPhoto.archive"
            label="Архивировать"
            @click="archivePhotoModal"
          />
        </template>
      </Card>

      <Card title="Личные данные" dense>
        <div
          v-if="orgNoModify"
          class="d-flex justify-center text--disabled my-2"
        >
          <span>
            В данной организации действует запрет на создание и редактирование
            работников.
          </span>
        </div>
        <DefinitionList :value="employeeData" />

        <template v-if="!orgNoModify" #action>
          <div class="d-flex flex-column">
            <template v-if="$can('EMP_U')">
              <CardActionBtn
                v-if="!employeeSyncActions.includes('update')"
                label="Редактировать"
                @click="editModal"
              />
              <div v-else class="text-caption text--disabled">
                Управляется организацией
              </div>
            </template>
          </div>
        </template>
      </Card>

      <Card title="Организация">
        <LinkButton
          :text="organization.name"
          :action="$can('ORG_RD')"
          :to="{
            name: 'structures:organizations:item',
            params: { id: organization.id },
          }"
        />
        <template v-if="!orgNoModify" #action>
          <CardActionBtn
            v-if="$can('EMP_MV')"
            label="Изменить"
            @click="changeOrgModal"
          />
        </template>
      </Card>

      <Card title="Группы">
        <template v-if="employeeGroupsAsButton.length">
          <LinkButtonList :list="employeeGroupsAsButton" />
        </template>
        <NoDataAlert v-else>Группы не заданы</NoDataAlert>

        <template v-if="!orgNoModify" #action>
          <CardActionBtn
            v-if="$can('EMG_BN')"
            label="Изменить"
            @click="openEditEmployeeGroups"
          />
        </template>
      </Card>

      <template v-if="!EMPLOYEE_EXTRAS_DISABLE">
        <Card v-if="$can('EMPED_E')" title="Дополнительные поля" dense>
          <AsyncWrapper :handler="fetchExtras">
            <ExtraFields :value="extras" :types="employeeExtrasTypes" />
          </AsyncWrapper>
          <template v-if="!orgNoModify" #action>
            <CardActionBtn
              v-if="$can('EMPED_P') && !extrasError"
              label="Редактировать"
              @click="editExtrasModal"
            />
          </template>
        </Card>
      </template>

      <BanFlags :employee-id="singleItem.id" />
    </div>

    <template v-if="currentTab === 'general'" #aside>
      <ItemAside :employee="singleItem" :org-no-modify="orgNoModify" />
    </template>

    <!-- TABS -->
    <template v-else #innerList>
      <TabMedData v-if="currentTab === 'meddata'" :employee="singleItem" />
      <TabRisks v-else-if="currentTab === 'risks'" :employee="singleItem" />
      <TabBans v-else-if="currentTab === 'bans'" :employee="singleItem" />
      <TabLogs v-else-if="currentTab === 'logs'" :employee-id="singleItem.id" />
    </template>
  </ItemPageLayout>
</template>

<script>
import IMask from 'imask';

import { mapGetters, mapActions } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import queryTabSave from '@/utils/mixins/queryTabSave';
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';
import { maskTextPhone, maskTextSnils } from '@/utils/masks';
import env from '@/plugins/env';

import ItemAside from '../components/ItemAside';
import TabBans from './item-tabs/bans';
import TabMedData from './item-tabs/meddata';
import TabRisks from './item-tabs/risks';
import TabLogs from './item-tabs/logs';

import DefinitionList from '@/components/DefinitionList';
import ItemPageLayout from '@/components/layouts/ItemPageLayout';
import Card from '@/components/ui/Card';
import AsyncWrapper from '@/components/AsyncWrapper';

import ExtraFields from '../components/ExtraFields';
import EmployeeName from '../components/EmployeeName';
import BanFlags from '../components/BanFlags';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';
import EmployeeGroupSelect from '@/components/controls/structures/EmployeeGroupSelect.vue';
import Photo from '../components/Photo';
import LinkButton from '@/components/controls/buttons/LinkButton.vue';
import LinkButtonList from '@/components/controls/buttons/LinkButtonList.vue';
import NoDataAlert from '@/components/ui/NoDataAlert';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';

export default {
  components: {
    ItemAside,

    TabBans,
    TabMedData,
    TabRisks,
    TabLogs,

    DefinitionList,
    ItemPageLayout,
    Card,
    AsyncWrapper,

    ExtraFields,
    BanFlags,
    Photo,
    LinkButton,
    LinkButtonList,
    NoDataAlert,
    CardActionBtn,
  },

  mixins: [waitable, routeGuardMixin, queryTabSave()],

  props: {
    openEmployeeModal: {
      type: Function,
      default: () => {},
    },
  },

  data: () => ({
    currentTab: 'general',
    photoError: false,
    extrasError: null,
  }),

  computed: {
    ...mapGetters('EMPLOYEE', ['extras', 'orgSettings']),
    ...mapGetters('REGISTRY', ['employeeExtrasTypes']),

    EMPLOYEE_EXTRAS_DISABLE() {
      return env.get('VUE_APP_EMPLOYEE_EXTRAS_DISABLE');
    },

    employeeData() {
      if (!this.item) return [];

      const pn = this.maskedPn(this.item.personnelNumber, this.orgSettings?.pn);
      return [
        { label: 'ID', value: this.item.id },
        { label: 'Статус', value: this.item.isEnabled },
        {
          label: 'ФИО',
          value: this.singleItem,
          component: EmployeeName,
        },
        { label: 'Дата рождения', value: this.item.dateOfBirth },
        { label: 'Возраст', value: this.item.age },
        { label: 'Пол', value: this.item.gender },
        { label: 'Табельный №', value: pn },
        { label: 'Телефон', value: maskTextPhone(this.item.phone) },
        {
          label: 'СНИЛС',
          value: maskTextSnils(this.item.snils),
          labelTooltip: 'Без указания СНИЛС ЭПЛ не будет сформирован',
        },
        {
          label: 'Отправка осмотров в НИИАТ',
          value: this.singleItem.sendToNiiat ? 'Да' : 'Нет',
        },
      ];
    },

    groups() {
      // Обрати внимание, что singleItem содержит "чистые" - не обработанные
      // по правилам entity данные.
      return this.singleItem.groups || [];
    },

    organization() {
      return this.singleItem.organization || {};
    },

    employeeSync() {
      return this.orgSettings?.employeeSync;
    },

    employeeSyncActions() {
      return this.employeeSync?.rule?.actions || [];
    },

    rulesPhoto() {
      const isTest = this.singleItem?.isTest;
      const isActivePhoto = this.singleItem?.photo?.isActive;

      return {
        upload:
          !isTest &&
          // Если при загрузке фото произошла ошибка,
          // то разрешить загружать фото
          (this.photoError ? true : !isActivePhoto) &&
          this.$can('EMPP_U'),
        archive: !isTest && isActivePhoto && this.$can('EMPP_A'),
      };
    },

    employeeGroupsAsButton() {
      return this.groups.map(el => ({
        text: el.name || `#${el.id}`,
        action: this.$can('EMG_RD'),
        to: {
          name: 'structures:employee_groups:item',
          params: { id: el.id },
        },
      }));
    },
    showRiskTab() {
      return (
        this.$can('RG_SE') ||
        this.$can('EMT_R') ||
        this.$can('SUR_GET') ||
        this.$can('MPR_RD') ||
        this.$can('RG_RFC')
      );
    },

    // global 'disabled' for current employee, if his org has 'employeeModify'
    // setting set to false
    orgNoModify() {
      if (!this.orgSettings) return false;
      return this.orgSettings?.employeeSync?.rule?.employeeModify === false;
    },
  },

  async created() {
    if (this.$can('ORG_RD')) this.checkSyncOrg(this.singleItem.organization.id);
  },

  methods: {
    ...mapActions('EMPLOYEE', ['fetchOrgSettings', 'getEmployeeMedicalTests']),
    ...mapActions('REGISTRY', ['fetchEmployeeExtrasTypes']),
    ...mapActions('STRUCTURES/EMPLOYEE_GROUPS', {
      changeCountOnEmpGroups: 'changeCounter',
    }),

    checkSyncOrg(orgId) {
      if (this.$can('ORG_RD'))
        this.$loadingNotify(
          this.fetchOrgSettings(orgId),
          'fetchingOrg',
          'Не удалось загрузить информацию об организации пользователя',
        );
    },

    fetchExtras() {
      this.extrasError = null;
      if (!this.EMPLOYEE_EXTRAS_DISABLE && this.$can('EMPED_T'))
        this.$loadingNotify(
          this.fetchEmployeeExtrasTypes(),
          'fetchEmployeeExtrasTypes',
          'Не удалось загрузить типы дополнительных полей работника',
        );
      return this.$store
        .dispatch('EMPLOYEE/fetchExtras', this.singleItem.id)
        .catch(err => {
          this.extrasError = err;
          throw err;
        });
    },

    handleArchive(id) {
      return this.$store.dispatch('EMPLOYEE/archivePhoto', id);
    },

    /**
     * MODALS
     */

    editModal() {
      const item = this.$store.state.EMPLOYEE.singleItem;
      const onSubmit = data =>
        this.$store.dispatch('EMPLOYEE/updateSingleItem', data);
      const fetchOrganizationSettings = id =>
        this.$store.dispatch('EMPLOYEE_LIST/fetchOrganizationSettings', id);

      this.$openModal('employees/form', {
        item,
        disabledFields: this.employeeSync?.fields || [],
        onSubmit,
        fetchOrganizationSettings,
      });
    },

    editExtrasModal() {
      this.$openModal('employees/editExtras', {
        id: this.singleItem.id,
        orgId: this.singleItem.organization.id,
        value: this.extras,
        types: this.employeeExtrasTypes,
      });
    },

    uploadPhotoModal() {
      this.$openModal('employees/uploadPhoto', { item: this.item });
    },

    async changeOrgModal() {
      this.$openModal('asyncSelectModal', {
        component: OrganizationSelect,
        value: this.singleItem.organization.id,
        componentProps: {
          accessLevel: 'full',
          required: true,
        },
        onSubmit: async orgId => {
          const oldCategoryIds = this.singleItem.groups.map(item => item.id);
          await this.$loadingNotify(
            this.$store
              .dispatch('EMPLOYEE/changeOrg', {
                id: this.singleItem.id,
                orgId,
              })
              .then(() => this.checkSyncOrg(orgId)),
            'updateGroups',
            'Произошла ошибка изменения организации работника',
            'Организация работника успешно изменена',
          );
          this.changeCountOnEmpGroups({
            itemIds: oldCategoryIds,
            counterField: 'itemsCount',
            addCount: -1,
          });
        },
        messages: {
          title: 'Изменение организации работника',
          warning: `Список организаций отсутствует`,
        },
      });
    },

    openEditEmployeeGroups() {
      this.$openModal('asyncSelectModal', {
        component: EmployeeGroupSelect,
        value: this.singleItem.groups,
        componentProps: {
          orgIds: [this.singleItem.organization?.id],
          accessLevel: 'full',
        },
        multiple: true,
        onSubmit: async groupIds => {
          const oldCategoryIds = this.singleItem.groups.map(item => item.id);
          await this.$loadingNotify(
            this.$store.dispatch('EMPLOYEE/changeGroups', {
              groupIds,
            }),
            'changeGroups',
            'Произошла ошибка обновления групп',
            'Группы успешно обновлены',
          );
          this.changeCountOnEmpGroups({
            itemIds: oldCategoryIds,
            counterField: 'itemsCount',
            addCount: -1,
          });
          this.changeCountOnEmpGroups({
            itemIds: groupIds,
            counterField: 'itemsCount',
            addCount: 1,
          });
        },
        messages: {
          title: 'Редактирование групп работника',
          warning: `Группы работников для организации ${this.singleItem.organization.name} отсутствуют`,
        },
      });
    },

    photoIsLoadingError(error) {
      this.photoError = error;
    },

    archivePhotoModal() {
      this.$openModal('employees/archivePhoto', {
        item: this.item,
        submitArchive: this.handleArchive,
      });
    },

    maskedPn(value, rules) {
      if (rules?.pattern) {
        const regexp = new RegExp(rules.pattern);
        // Если значение не подходит под результат, то не применяем маску
        if (!regexp.test(value)) return value;
      } else {
        return value;
      }

      if (rules?.mask) {
        const maskData = IMask.createMask({ mask: rules.mask });
        return maskData.resolve(value);
      }

      return value;
    },
  },
};
</script>
