import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import INSPECTION from './store/item';
import INSPECTION_ARCHIVED from './store/archived.item';
import INSPECTION_LIST from './store/list';
import INSPECTION_HISTORY from './store/history';
import routes from './routes';

export default {
  routes,
  store: {
    INSPECTION,
    INSPECTION_ARCHIVED,
    INSPECTION_LIST,
    INSPECTION_HISTORY,
  },
  menu: [
    {
      title: 'Осмотры',
      action: 'I_R',
      name: 'inspections',
      priority: 1,
    },
  ],
  modals: [
    {
      name: 'inspections/history',
      component: () =>
        import('@/modules/inspections/modals/InspectionsHistory.vue'),
    },
    {
      name: 'inspections/printSticker',
      component: () => import('@/modules/inspections/modals/PrintSticker.vue'),
    },
    {
      name: 'inspections/goById',
      component: () => import('@/modules/inspections/modals/GoByIdModal.vue'),
    },
    {
      name: 'inspections/approvalComment',
      component: () =>
        import('@/modules/inspections/modals/ApprovalComment.vue'),
    },
  ],
} as ModuleDefinition;
