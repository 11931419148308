export interface IBadge {
  text: string;
  description?: (insp: any) => string | string[];
  color: string;
  textColor?: string;
  outlined?: boolean;
  isShow: (inspection: any) => boolean;
}
export interface IBadgeResult {
  text: string;
  description?: string | string[];
  color: string;
  textColor?: string;
  outlined?: boolean;
}

const FAILURES: { [key: string]: string } = {
  no_video: 'Видео не загружается',
  device_malfunction: 'Сбой в работе медицинского оборудования',
  no_photo: 'Отсутствует фото работника',
  incorrect_video: 'Некорректная видеофиксация',
  history_lag:
    'Осмотр попал в историю с большой задержкой из-за технического сбоя',
  moveFiles: 'Нет файла результата',
  outdated: 'Осмотр поступил в систему с задержкой',
  not_all_steps: 'Неполный состав осмотра',
};

const BADGES: IBadge[] = [
  {
    text: 'Гостевой осмотр',
    color: 'orange',
    outlined: true,
    isShow: (insp: any) =>
      insp.host?.organization?.id !== insp.employee?.organization?.id,
  },
  {
    text: 'Без ассистента',
    color: 'red',
    outlined: true,
    isShow: (insp: any) => !insp.resolution.withAssistance,
  },
  {
    text: 'Тестовый',
    color: 'green',
    outlined: true,
    isShow: (insp: any) => insp.employee.isTest,
  },
  {
    text: 'Технический сбой',
    color: 'red',
    outlined: true,
    description: (insp: { failures: { [key: string]: string[] } }) =>
      Object.values(insp.failures)
        .flat()
        .reduce((agg: string[], reason) => {
          if (FAILURES[reason]) agg.push(FAILURES[reason]);
          return agg;
        }, []),
    isShow: (insp: any) => insp?.failures,
  },
];

export const getInspectionBadges = (inspection: any) => {
  return BADGES.filter(el => el.isShow(inspection)).map(el => {
    const result: IBadgeResult = {
      text: el.text,
      color: el.color,
      outlined: el.outlined,
    };

    if (el.textColor) result.textColor = el.textColor;
    if (el.description) result.description = el.description(inspection);

    return result;
  });
};
