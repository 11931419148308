<template>
  <ItemPageLayout back-route="medblock:medics:list" :title="name">
    <template #header>
      <Card dense hide-title>
        <v-tabs v-model="currentTab">
          <v-tab href="#general">Основное</v-tab>
          <v-tab href="#statistic">Статистика</v-tab>
        </v-tabs>
      </Card>
    </template>

    <template v-if="currentTab === 'general'">
      <Card title="Роль">
        <h3>{{ role }}</h3>
        <template #action>
          <CardActionBtn
            v-if="$can('MED_SR')"
            label="Изменить"
            @click="changeRoleModal"
          />
        </template>
      </Card>

      <Card title="Профиль" dense>
        <DefinitionList :value="profileData" />
        <template #action>
          <CardActionBtn
            v-if="$can('MED_U')"
            label="Редактировать"
            @click="changeProfileModal"
          />
        </template>
      </Card>

      <Card title="Организация">
        <Spinner v-if="$wait('fetchMedOrg')" :size="16" />
        <v-alert
          v-else-if="!organization"
          class="ma-0"
          dense
          outlined
          type="error"
        >
          {{ `#${item.medicData.orgId} не найдена в списке мед.организаций` }}
        </v-alert>
        <ButtonAsLink
          v-else
          :disabled="!$can('ORG_RD')"
          :text="organization.name"
          :to="{
            name: 'structures:organizations:item',
            params: { id: organization.id },
          }"
        />
        <template #action>
          <CardActionBtn
            v-if="$can('MED_OG')"
            label="Изменить"
            @click="openEditOrganization"
          />
        </template>
      </Card>

      <Card title="Категории">
        <Spinner v-if="$wait('fetchOrgCategoriesByOrgId')" :size="16" />
        <NoDataAlert v-else-if="!categories.length"> Не указаны </NoDataAlert>
        <div v-else>
          <v-chip
            v-for="category in categories"
            :key="category.id"
            class="mr-1 mb-1"
            :color="category.notFound && 'red'"
          >
            {{ category.name || `– категория #${category.id} не найдена –` }}
          </v-chip>
        </div>
        <template #action>
          <CardActionBtn
            v-if="$can('MED_BC')"
            label="Изменить"
            @click="openEditMedicCategories"
          />
        </template>
      </Card>

      <Card title="Группы">
        <NoDataAlert v-if="!groups.length">Не указаны</NoDataAlert>
        <div v-else>
          <v-chip v-for="group in groups" :key="group.id" class="mr-1 mb-1">
            {{ group.name }}
          </v-chip>
        </div>
        <template #action>
          <CardActionBtn
            v-if="$can('MEG_BN')"
            label="Изменить"
            @click="openEditGroups"
          />
        </template>
      </Card>

      <Card v-if="item.accountId" title="Сертификаты (ЭЦП)">
        <v-alert v-if="!certs.length" class="ma-0" type="error" dense outlined>
          Нет подключенных сертификатов
        </v-alert>
        <div v-else>
          <v-tooltip v-for="cert in certs" :key="cert.id" top>
            <template #activator="{ on, attrs }">
              <v-chip class="mr-1 mb-1" v-bind="attrs" v-on="on">
                <span class="mr-2">
                  <v-icon
                    :color="
                      cert.payload.isInitialized && cert.payload.isActive
                        ? 'green darkest-2'
                        : cert.payload.isInitialized || cert.payload.isActive
                        ? 'orange'
                        : 'red darkest-1'
                    "
                  >
                    {{
                      cert.payload.isInitialized && cert.payload.isActive
                        ? 'mdi-check-bold'
                        : 'mdi-alert'
                    }}
                  </v-icon>
                </span>
                {{ cert.label }}
              </v-chip>
            </template>
            <span>
              {{ cert.payload.details.serial }} ({{
                cert.payload.isInitialized
                  ? 'инициализирован'
                  : 'не инициализирован'
              }}, {{ cert.payload.isActive ? 'активен' : 'отключен' }})
            </span>
          </v-tooltip>
          <v-alert
            v-if="certs.some(el => !el.payload.isInitialized)"
            type="info"
            class="my-3"
            outlined
          >
            Чтобы инициализировать ЭЦП необходимо войти в систему по связке
            email и пароль (или уже инициализированного ЭЦП). И выбрать
            инициализируемый сертификат в качестве способа подписи документов.
          </v-alert>
        </div>
        <template #action>
          <CardActionBtn
            v-if="$can('MED_SR')"
            label="Изменить"
            @click="changeCertificatesModal"
          />
        </template>
      </Card>

      <Card title="Доп. информация" dense>
        <DefinitionList :value="accountDetails" />
      </Card>
    </template>

    <template v-if="currentTab === 'statistic'" #innerList>
      <Card v-if="$can('SHFT_ST') && item.medicId" title="История смен" dense>
        <MedicStats :key="keyShift" :medic-id="item.medicId" />
        <template #action>
          <CardActionBtn label="Обновить" @click="keyShift = Math.random()" />
        </template>
      </Card>
    </template>

    <template v-if="currentTab === 'general'" #aside>
      <ActionsMenu>
        <NewStatusChanger
          :item="item.medicData || {}"
          :can-enable="$can('MED_EN')"
          :can-disable="$can('MED_DS')"
          :handler="changeStatusHandle"
        />
        <ActionsMenuItem
          v-if="
            $can('MPWD_R') &&
            ((item.accountData || {}).authWays || []).includes('email_pwd')
          "
          title="Сбросить пароль"
          :subtitle="resetPasswordSubtitle"
          :disabled="$wait('resettingPassword') || !item.medicData.isEnabled"
          @click="resetPassword(item)"
        >
          <template #icon><v-icon>mdi-account-check-outline</v-icon></template>
        </ActionsMenuItem>
        <ActionsMenuItem
          v-if="$can('MED_C') && !item.accountData"
          title="Пересоздать аккаунт"
          subtitle="Необходимо для входа по email"
          :disabled="$wait('recreateAccount')"
          @click="recreateAccountHandle(item)"
        >
          <template #icon>
            <v-icon>mdi-account-reactivate-outline</v-icon>
          </template>
        </ActionsMenuItem>
      </ActionsMenu>
      <Card title="Диагностика" dense class="mt-4">
        <MedicDiagnosticTable
          :value="item.diagnostic"
          :width-columns="['80%', '20%']"
        />
      </Card>
    </template>
  </ItemPageLayout>
</template>

<script>
import structApi from '@/api/services/structures';
import { mapActions, mapGetters } from 'vuex';
import { maskTextPhone, maskTextSnils } from '@/utils/masks';
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import queryTabSave from '@/utils/mixins/queryTabSave';
import { date, dateTime, name } from '@/utils/convert';
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';

import { GENDER, TOKEN_TYPES, TOKEN_TYPES_LANG } from '@/utils/constants';

import Spinner from '@/components/Spinner.vue';
import DefinitionList from '@/components/DefinitionList.vue';
import ItemPageLayout from '@/components/layouts/ItemPageLayout.vue';
import Card from '@/components/ui/Card.vue';
import ActionsMenu from '@/components/ui/ActionsMenu.vue';
import ActionsMenuItem from '@/components/ui/ActionsMenuItem.vue';
import NewStatusChanger from '@/components/controls/NewStatusChanger.vue';
import ButtonAsLink from '@/components/controls/buttons/ButtonAsLink.vue';
import MedicStats from '../components/stats/MedicStats.vue';
import MedicDiagnosticTable from '../components/MedicDiagnosticTable.vue';
import MedicGroupSelect from '@/components/controls/structures/MedicGroupSelect.vue';
import CategorySelect from '@/components/controls/structures/CategorySelect.vue';
import NoDataAlert from '@/components/ui/NoDataAlert';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';

export default {
  components: {
    NoDataAlert,
    CardActionBtn,
    Spinner,
    DefinitionList,
    ItemPageLayout,
    Card,
    ActionsMenu,
    ActionsMenuItem,
    NewStatusChanger,
    ButtonAsLink,
    MedicStats,
    MedicDiagnosticTable,
  },
  mixins: [validation, waitable, routeGuardMixin, queryTabSave()],

  data: () => ({
    currentTab: 'general',
    orgCategories: [],
    keyShift: Math.random(),
  }),

  computed: {
    ...mapGetters('MEDBLOCK/MEDICS', ['medicalRoles', 'medicalOrgs']),

    name() {
      return name(this.item?.medicData || {});
    },

    role() {
      if (!this.item?.medicData) return '–';

      const { roleKey } = this.item.medicData;
      return (
        this.medicalRoles.find(role => role.key === roleKey)?.name ||
        'id: ' + roleKey
      );
    },

    certs() {
      const list = this.item.certificates;
      const reverseTokenTypes = Object.entries(TOKEN_TYPES).reduce(
        (acc, [key, val]) => ({ ...acc, [val]: key }),
        {},
      );

      return list.map(el => {
        const type = reverseTokenTypes[el.type];
        return {
          id: el.id,
          label: TOKEN_TYPES_LANG[type] || el.type,
          payload: el,
        };
      });
    },

    profileData() {
      const data = this.item?.medicData;
      if (!data) return [];

      return [
        { label: 'Фамилия', value: data.surname },
        { label: 'Имя', value: data.name },
        { label: 'Отчество', value: data.patronymic },
        { label: 'Дата рождения', value: date(data.dateOfBirth) },
        { label: 'Пол', value: GENDER[data.gender] },
        { label: 'СНИЛС', value: maskTextSnils(data.snils) },
        { label: 'Email', value: data.email },
        { label: 'Номер телефона', value: maskTextPhone(data.phone) },
      ];
    },

    resetPasswordSubtitle() {
      return this.item.medicData.isEnabled
        ? 'Письмо с паролем придет на почту'
        : 'Сброс пароля недоступен для неактивных медработников';
    },

    accountDetails() {
      return [
        {
          label: 'Создан',
          value: dateTime(this.item?.medicData?.createdAt),
        },
        {
          label: 'Последний вход',
          value:
            dateTime(this.item?.accountData?.lastLogin) ||
            '(пользователь не входил в систему)',
        },
        {
          label: 'ID аккаунта',
          value: this.item.accountId || '–',
        },
        {
          label: 'ID медработника',
          value: this.item.medicId || '–',
        },
      ];
    },

    organization() {
      return this.medicalOrgs.find(el => el.id === this.item?.medicData?.orgId);
    },

    categories() {
      const orgCategories = this.orgCategories || [];
      const userCategories = this.item?.medicData?.categories || [];

      if (!userCategories.length) return [];

      return userCategories.map(
        id => orgCategories.find(el => el.id === id) || { notFound: true, id },
      );
    },

    groups() {
      return this.item?.medicData?.groups || [];
    },
  },

  watch: {
    organization: {
      immediate: true,
      handler(val) {
        if (val) this.fetchOrgCategoriesByOrgId(val.id);
      },
    },
  },

  created() {
    this.$loadingNotify(
      this.fetchMedicalRolesPreviews(),
      'fetchMedicalRolesPreviews',
      'Произошла ошибка загрузки ролей медработников',
    );
    this.$loadingNotify(
      this.fetchMedOrg(this.item.medicData.orgId),
      'fetchMedOrg',
      'Произошла ошибка загрузки медицинских организаций',
    );
  },

  methods: {
    ...mapActions('MEDBLOCK/MEDICS', [
      'fetchMedicalRolesPreviews',
      'fetchMedOrg',
      'resetMedicPassword',
      'changeStatus',
      'recreateAccount',
      'updateRole',
      'changeBindings',
      'fetchRole',
    ]),

    async resetPassword() {
      this.$openModal('prompt', {
        yes: 'Сбросить',
        no: 'Отмена',
        title: 'Сбросить пароль',
        description:
          'Вы уверены, что хотите сбросить пароль медработнику? ' +
          'Пользователю придет письмо с новым паролем.',
        messages: {
          successfulAction: 'Пароль успешно сброшен',
          errorAction: 'Произошла ошибка сброса пароля',
        },
        onSubmit: () => this.resetMedicPassword(this.item.medicId),
      });
    },

    async changeStatusHandle(value) {
      await this.$loadingNotify(
        this.changeStatus({
          id: this.item.medicId,
          isEnabled: value,
        }),
        'changingStatus',
        null,
        `Медработник #${this.item.medicId} успешно ${
          value ? 'активирован' : 'деактивирован'
        }`,
      );
    },

    async recreateAccountHandle() {
      this.$loadingNotify(
        this.recreateAccount({ medicId: this.item.medicId }),
        'recreateAccount',
        'Не удалось создать аккаунт для медработника, ' +
          'обратитесь в техническую поддержку',
        'Аккаунт для медработника успешно создан',
      );
    },

    changeRoleModal() {
      const action = roleKey =>
        this.$loadingNotify(
          this.updateRole({ id: this.item.medicId, roleKey }),
          'changingRole',
          'Ошибка изменения роли',
          `Роль успешно обновлена`,
        );

      this.$openModal('selectModal', {
        value: this.item.medicData.roleKey,
        items: this.medicalRoles,
        itemValue: 'key',
        itemDisabled: item => !item.canBeSet,
        onSubmit: async roleKey => action(roleKey),
        messages: {
          title: 'Редактирование роли',
        },
      });
    },

    changeProfileModal() {
      this.$openModal('medblock/medics/form', {
        value: this.item,
      });
    },

    changeCertificatesModal() {
      this.$openModal('medblock/medics/certificates', {
        accountId: this.item.accountId,
        medicId: this.item.medicId,
        items: this.item.certificates,
      });
    },

    openEditGroups() {
      this.$openModal('asyncSelectModal', {
        component: MedicGroupSelect,
        value: this.item.medicData.groups,
        multiple: true,
        componentProps: {
          accessLevel: 'full',
          medOrgIds: [this.item.medicData.orgId],
        },
        onSubmit: groupIds =>
          this.$loadingNotify(
            this.$store
              .dispatch('MEDBLOCK/MEDICS/updateGroups', {
                id: this.item.medicData.id,
                groupIds,
              })
              .catch(err => console.error(err)),
            'updateGroups',
            'Произошла ошибка обновления групп',
            'Группы успешно обновлены',
          ),
        messages: {
          title: 'Редактирование групп медработников',
          warning: `Группы медработников отсутствуют`,
        },
      });
    },

    async fetchOrgCategoriesByOrgId(orgId) {
      // this request is being used only in this component, no need to move it
      // to store
      this.$loading(
        structApi
          .getCategoryPreviews({ limit: 1000, page: 1, orgIds: orgId })
          .then(data => {
            this.orgCategories = data.items;
          }),
        'fetchOrgCategoriesByOrgId',
      );
    },

    async openEditMedicCategories() {
      this.$openModal('asyncSelectModal', {
        component: CategorySelect,
        value: this.item.medicData.categories,
        multiple: true,
        componentProps: {
          accessLevel: 'full',
          medOrgIds: [this.item.medicData.orgId],
        },
        onSubmit: async categoryIds => {
          await this.$loadingNotify(
            this.$store.dispatch('MEDBLOCK/MEDICS/updateCategories', {
              id: this.item.medicData.id,
              categoryIds,
            }),
            'updateCategories',
            'Произошла ошибка обновления категорий',
            'Категории успешно обновлены',
          );
          await this.fetchOrgCategoriesByOrgId(this.item.medicData.orgId);
        },
        messages: {
          title: 'Редактирование списка категорий',
          warning: 'Категории отсутствуют',
        },
      });
    },

    openEditOrganization() {
      this.$openModal('medblock/medics/changeOrganization', {
        value: this.item,
      });
    },
  },
};
</script>
