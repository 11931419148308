<template>
  <v-container style="height: 73vh" class="d-flex align-center justify-center">
    <v-layout align-center>
      <v-flex text-center>
        <h1 class="display-2 primary--text">
          Ошибка <span v-if="code">{{ code }}</span>
        </h1>
        <p class="ma-3">{{ message || userFriendlyMessage }}</p>
        <v-btn :to="{ name: 'main' }" color="primary" class="mt-10">
          Вернуться в АРМ
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { APP_FATAL_ERROR_MESSAGE, HTTP_CODES } from '@/utils/constants';
import { isXhrError } from '@/utils/helpers';
export default {
  props: {
    message: {
      type: String,
      default: null,
    },
    error: {
      type: [Object, Error],
      default: null,
    },
  },

  computed: {
    code() {
      return isXhrError(this.error) ? this.error?.response?.status : null;
    },

    userFriendlyMessage() {
      // Если ошибка получена в результате XHR запроса, то выведем его
      if (this.code) return HTTP_CODES[this.code];

      return this.error?.message || APP_FATAL_ERROR_MESSAGE;
    },
  },

  mounted() {
    // NOTE: Вывод ошибки, чтобы понять её причину в консоли.
    // На перспективу: можно добавить вывод в Sentry.
    this.error && console.error(this.error); // sometimes error is null
  },
};
</script>
