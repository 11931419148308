<template>
  <ModernModalLayout
    size="large"
    title="Редактирование Push-метода"
    close-label="Отмена"
    :watch-for="settings"
  >
    <PushMethodFields
      ref="settings"
      v-model="settings"
      :is-loading="$wait('changingPushSettings')"
      :disabled="!$can('WBS_S')"
    />

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :disabled="$wait('changingPushSettings')"
        @click="submit"
      >
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import ModernModalLayout from '@/components/layouts/ModernModalLayout.vue';
import PushMethodFields from '@/modules/admin/applications/components/PushMethodFields.vue';

export default {
  components: { ModernModalLayout, PushMethodFields },

  mixins: [validation, waitable],

  props: {
    id: { type: Number, required: true },
    value: { type: Object, required: true },
  },

  data: () => ({
    settings: {},
  }),

  created() {
    this.settings = this.value;
  },

  methods: {
    async submit() {
      if (!this.$refs.settings.validate()) return;

      await this.$loadingNotify(
        this.$store
          .dispatch('ADMIN/APPLICATIONS_ITEM/changePushSettings', this.settings)
          .then(() => {
            this.$emit('close');
          }),
        'changingPushSettings',
        'Произошла ошибка изменения настроек push-метода',
        'Настройки push-метода успешно изменены',
      );
    },
  },
};
</script>
