import authApi from '@/api/services/auth';
import env from '@/plugins/env';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { createModule } from '@/utils/vuex/createModule';

const state = {};

const getters = {
  organizations: state => state.organizations,
  listItems: state => state.listItems || [],
  role: (state, _, __, rootGetters) => (key, field) => {
    const role = rootGetters['REGISTRY/accountRoles'].find(
      role => role.key === key,
    );
    if (!role) return 'key:' + key; // is it the correct thing to return? unknown for now
    if (!field) return role;
    return role[field];
  },

  importTemplateURL: _ =>
    env.get('VUE_APP_AUTH_API') + '/accounts/import/template',
};

const mutations = {};

const actions = {
  async createItem({ commit }, payload) {
    const { patronymic } = payload.profile;
    if (patronymic === '') payload.profile.patronymic = null;

    const account = await authApi.accountCreate(payload);
    commit('listAddItem', account);
  },

  async importList({ dispatch }, data) {
    const response = await authApi.accountImport(data);
    await dispatch('fetchList');
    return response;
  },
};

export default createModule(
  crudListFactory({
    fetchMethod: authApi.accountList,
    createMethod: authApi.accountCreate,
    filters: {
      roleKeys: {
        type: Array,
        default: null,
      },
      isEnabled: {
        type: Boolean,
        default: true,
      },
    },
  }),
  { state, getters, mutations, actions },
);
