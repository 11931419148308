<template>
  <ModernModalLayout title="Загрузить файл соглашения" size="large">
    <UploaderBox
      v-if="!pdf"
      accept="application/pdf"
      :size-limit="31457280"
      @change="pdf = $event"
      @fileSizeErr="fileSizeErr"
      @errorCheckPdfSignature="errorCheckPdfSignature"
      @rejectedFiles="rejectedFiles"
    >
      <template #description>
        <p class="mt-3 text-center" style="line-height: 1.6">
          Нажмите на кнопку или
          <br />
          перенесите PDF файл в эту область.
          <br />
          Максимальный размер файла 30 MB.
        </p>
      </template>
    </UploaderBox>

    <div v-if="fileAsUrl">
      <iframe
        :src="fileAsUrl"
        type="application/pdf"
        style="object-fit: contain"
        width="100%"
        height="500px"
      />
    </div>

    <template #actions>
      <v-spacer />
      <v-btn
        :loading="$wait('upload')"
        color="primary"
        text
        :disabled="!pdf"
        @click="upload"
      >
        Загрузить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import waitable from '@/utils/mixins/waitable';
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import UploaderBox from '@/components/controls/UploaderBox.vue';
import { mapActions } from 'vuex';

export default {
  name: 'UploadAgreementFileModal',
  components: { ModernModalLayout, UploaderBox },
  mixins: [waitable],
  props: {
    isNew: { type: Boolean, required: true },
    type: { type: String, required: true },
  },

  data() {
    return {
      pdf: null,
    };
  },

  computed: {
    fileAsUrl() {
      return this.pdf ? URL.createObjectURL(this.pdf) : null;
    },
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', [
      'createAgreement',
      'changeAgreement',
      'fetchAgreements',
    ]),
    async upload() {
      const params = {
        type: this.type,
        file: this.pdf,
      };
      this.$loadingNotify(
        this.isNew
          ? this.createAgreement(params)
          : this.changeAgreement(params),
        'upload',
        'Произошла ошибка загрузки файла соглашения',
        'Файл соглашения успешно загружен',
      ).then(() => {
        this.fetchAgreements();
        this.$emit('close', { action: 'upload' });
      });
    },
    fileSizeErr(file) {
      this.$notify({
        group: 'note',
        type: 'error',
        title: 'Превышен допустимый размер файла',
        text: `Слишком большой файл! (${Math.floor(
          file.size / 1048576,
        )}Мб) Загрузите файл не больше 30MB`,
      });
    },
    errorCheckPdfSignature() {
      this.$notify({
        group: 'note',
        type: 'error',
        title: 'Ошибка целостности файла',
        text: `Файл поврежден`,
      });
    },
    rejectedFiles(files) {
      if (files.some(file => file.type !== 'application/pdf')) {
        this.$notify({
          group: 'note',
          type: 'error',
          title: 'Ошибка загрузки файла',
          text: 'Данный формат не поддерживается. Загрузите файл в формате PDF',
        });
      }
    },
  },
};
</script>
