<template>
  <HostGroupsList
    search-filter
    :available-actions="{
      showAction: $can('HSG_RD'),
      createAction: $can('HSTG_C'),
    }"
    @item:create="openCreateHostGroup"
  >
    <template #table.item.name_org="{ item }">
      <b>{{ item.name }}</b>
      <br />
      {{ item.organization.name }}
    </template>
  </HostGroupsList>
</template>

<script>
import {
  messages as hostGroupsMessages,
  entity as hostGroupsEntity,
  filters as hostGroupsFilters,
} from '@/modules/structures/hosts-groups/entity';
import ListFactory from '@/components/crud/ListFactory';

const HostGroupsList = ListFactory({
  storeModule: 'STRUCTURES/EMBEDDED_HOST_GROUPS_LIST',
  messages: hostGroupsMessages,
  entity: hostGroupsEntity.filter(val => val.key !== 'organization'),
  filters: hostGroupsFilters,
  viewRoute: 'structures:host_groups:item',
  embedded: true,
});

export default {
  components: { HostGroupsList },
  props: {
    orgId: { type: Number, required: true },
  },
  methods: {
    openCreateHostGroup() {
      this.$openModal('structures/hostGroupForm', {
        predefinedValues: { orgId: this.orgId },
        storeForCreate: 'STRUCTURES/EMBEDDED_HOST_GROUPS_LIST',
      });
    },
  },
};
</script>
