<template>
  <v-simple-table style="width: 100%">
    <template #default>
      <thead>
        <tr>
          <th v-if="allowEditing" width="46" class="pa-0" />
          <th class="px-2">№</th>
          <th width="150">Тип сценария</th>
          <th>
            Тип осмотра
            <TooltipTable
              v-if="inspectionTypes.length"
              :list="inspectionTypesKV"
            >
              <template #row="{ data }">
                <td>
                  <v-chip :color="data.color" text-color="black">
                    {{ data.label }}
                  </v-chip>
                </td>
                <td>{{ data.description }}</td>
              </template>
            </TooltipTable>
          </th>
          <th width="150">Принял решение</th>
          <th width="110" class="text-center">Не завер-шенный</th>
          <th width="124" class="text-center">Успешный</th>
          <th class="text-center">
            Попытка
            <TooltipTable :list="visitsList">
              <template #row="{ data }">
                <td>{{ data.label }}</td>
                <td>{{ data.description }}</td>
              </template>
            </TooltipTable>
          </th>
          <th>
            Флаги
            <TooltipTable v-if="scenarioFlags.length" :list="scenarioFlagsKV">
              <template #row="{ data }">
                <td>
                  <v-chip
                    :color="data.backgroundColor"
                    :text-color="data.textColor"
                  >
                    {{ data.label }}
                  </v-chip>
                </td>
                <td>{{ data.description }}</td>
              </template>
            </TooltipTable>
          </th>
          <th v-for="item in header" :key="item.key">{{ item.name }}</th>
          <th v-if="allowEditing" width="0" />
        </tr>
      </thead>

      <!-- Body -->
      <draggable
        v-model="items"
        tag="tbody"
        handle=".sorttable--handle"
        ghost-class="sorttable--ghost"
        :animation="300"
        :disabled="!allowEditing"
      >
        <tr
          v-for="(item, index) in items"
          :key="item.id"
          @dblclick="startEditing(item.id)"
        >
          <td v-if="allowEditing" class="text-center pa-0">
            <div class="sorttable--handle">
              <v-icon size="14">fa-arrows-alt</v-icon>
            </div>
          </td>
          <td class="px-2">{{ index + 1 }}</td>
          <td>
            <Select
              v-if="editing.indexOf(item.id) > -1"
              v-model="item.scenarioType"
              :items="scenarioTypes"
              dense
              :error-messages="
                getValidationErrors('value.$each.' + index + '.scenarioType')
              "
              @blur="validateField('value.$each.' + index + '.scenarioType')"
            />
            <div v-else>{{ scenarioTypesKV[item.scenarioType] }}</div>
          </td>
          <td>
            <Select
              v-if="editing.indexOf(item.id) > -1"
              v-model="item.inspectionTypes"
              :items="inspectionTypesList"
              item-text="name"
              item-value="key"
              dense
              multiple
              :error-messages="
                getValidationErrors('value.$each.' + index + '.inspectionTypes')
              "
              @blur="validateField('value.$each.' + index + '.inspectionTypes')"
            />
            <TooltipChip
              v-for="type in item.inspectionTypes"
              v-else-if="inspectionTypes.length"
              :key="type"
              :label="inspectionTypesKV[type].label"
              :description="inspectionTypesKV[type].description"
              :color="inspectionTypesKV[type].color"
              text-color="black"
            />
          </td>
          <td>
            <Select
              v-if="editing.indexOf(item.id) > -1"
              v-model="item.resolvedBy"
              :items="resolvedByKeyVal"
              item-text="name"
              item-value="key"
              dense
              :error-messages="
                getValidationErrors('value.$each.' + index + '.resolvedBy')
              "
            />
            <div v-else>{{ resolvedByTranslations[item.resolvedBy] }}</div>
          </td>
          <td class="text-center">
            <v-select
              v-if="editing.indexOf(item.id) > -1"
              v-model="item.isCanceled"
              :items="optionalValues"
              item-text="label"
              item-value="value"
              outlined
              dense
              :error-messages="
                getValidationErrors('value.$each.' + index + '.isCanceled')
              "
              hide-details
            />
            <div v-else>{{ optionalValuesKV[item.isCanceled] }}</div>
          </td>
          <td class="text-center">
            <v-select
              v-if="editing.indexOf(item.id) > -1"
              v-model="item.result"
              :items="optionalValues"
              item-text="label"
              item-value="value"
              outlined
              dense
              :error-messages="
                getValidationErrors('value.$each.' + index + '.result')
              "
              hide-details
            />
            <div v-else>{{ optionalValuesKV[item.result] }}</div>
          </td>
          <td class="text-center">
            <Select
              v-if="editing.indexOf(item.id) > -1"
              v-model="item.visits"
              :items="visitsList"
              dense
              multiple
              item-text="label"
              item-value="value"
              :error-messages="
                getValidationErrors('value.$each.' + index + '.visits')
              "
              @blur="validateField('value.$each.' + index + '.visits')"
            />
            <v-chip
              v-for="visit in item.visits"
              v-else
              :key="visit"
              small
              class="ma-1"
            >
              {{ visitsKV[visit] }}
            </v-chip>
          </td>
          <td>
            <Select
              v-if="editing.indexOf(item.id) > -1"
              v-model="item.flags"
              :items="scenarioFlags"
              multiple
              dense
              :error-messages="
                getValidationErrors('value.$each.' + index + '.flags')
              "
              @blur="validateField('value.$each.' + index + '.flags')"
            />
            <template
              v-for="(flag, flagIndex) in item.flags"
              v-else-if="scenarioFlags.length"
            >
              <TooltipChip
                :key="flag"
                :label="scenarioFlagsKV[flag].label"
                :description="scenarioFlagsKV[flag].description"
                :color="scenarioFlagsKV[flag].backgroundColor"
                :text-color="scenarioFlagsKV[flag].textColor"
              />
              <span v-if="flagIndex !== item.flags.length - 1" :key="flagIndex">
                +
              </span>
            </template>
          </td>

          <td v-for="headerItem in header" :key="headerItem.key">
            <slot
              :name="headerItem.key"
              v-bind="{
                row: item,
                index,
                editing: editing.indexOf(item.id) > -1,
                getValidationErrors,
                validateField,
              }"
            />
          </td>

          <td v-if="allowEditing" class="pa-0">
            <div class="d-flex justify-end">
              <v-btn
                v-if="!(editing.indexOf(item.id) > -1)"
                color="primary"
                outlined
                small
                class="mr-1"
                @click="startEditing(item.id)"
              >
                <v-icon size="14">far fa-edit</v-icon>
              </v-btn>

              <v-btn outlined small class="mr-1" @click="cloneRow(item.id)">
                <v-icon size="14">far fa-copy</v-icon>
              </v-btn>

              <PromptPopup
                description="Вы действительно хотите удалить строку?"
                :on-submit="() => removeRow(item.id)"
              />
            </div>
          </td>
        </tr>
      </draggable>

      <tfoot>
        <tr>
          <td :colspan="countColumns" class="text-right pt-6">
            <v-btn
              color="primary"
              outlined
              :disabled="!allowEditing"
              @click="addRow"
            >
              <v-icon size="14" class="fa-fw mr-2">fa-plus</v-icon>
              Добавить строку
            </v-btn>
          </td>
        </tr>
      </tfoot>
    </template>
  </v-simple-table>
</template>

<script>
import Draggable from 'vuedraggable';
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import _get from 'lodash/get';
import validationMixin from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import Select from '@/components/controls/Select';
import PromptPopup from '@/components/PromptPopup';
import TooltipTable from '@/components/RuleTable/TooltipTable';
import TooltipChip from '@/components/RuleTable/TooltipChip';

const listToKeyValue = (list, idKey, valueKey) => {
  return list.reduce((agg, el) => {
    const data = _get(el, valueKey);
    agg[el[idKey]] = data;
    return agg;
  }, {});
};

export default {
  components: {
    Draggable,
    Select,
    PromptPopup,
    TooltipTable,
    TooltipChip,
  },

  mixins: [validationMixin, waitable],

  validations() {
    return {
      value: {
        $each: {
          inspectionTypes: { required },
          scenarioType: { required },
          visits: { required },
          ...this.validationRules,
        },
      },
    };
  },

  props: {
    value: { type: Array, default: () => [] },
    header: { type: Array, default: () => [] },
    isDraggable: Boolean,
    editingRule: { type: Boolean, default: false },
    validationRules: { type: Object, default: () => ({}) },
  },

  data: () => ({
    editing: [],
    countColumns: 0,
  }),

  computed: {
    ...mapGetters('REGISTRY', [
      'inspectionTypes',
      'scenarioTypes',
      'scenarioFlags',
    ]),

    optionalValues() {
      return [
        { value: null, label: 'Неважно' },
        { value: true, label: 'Да' },
        { value: false, label: 'Нет' },
      ];
    },
    optionalValuesKV() {
      return listToKeyValue(this.optionalValues, 'value', 'label');
    },

    visitsList() {
      return [
        {
          value: 1,
          label: 'Первый',
          description: 'за последний час не было осмотров с такими признаками',
        },
        {
          value: 2,
          label: 'Повторный',
          description: 'за последний час был 1 осмотр с такими признаками',
        },
        {
          value: 3,
          label: 'Последующие',
          description:
            'за последний час было более 1 осмотра с такими признаками',
        },
      ];
    },
    visitsKV() {
      return listToKeyValue(this.visitsList, 'value', 'label');
    },

    inspectionTypesList() {
      return this.inspectionTypes.map(el => ({
        key: el.key,
        name: el.details.language.nominative.short,
      }));
    },
    resolvedByKeyVal() {
      return [
        { key: 'bot', name: 'Бот' },
        { key: 'medic', name: 'Медик' },
        { key: null, name: 'Неважно' },
      ];
    },
    resolvedByTranslations() {
      return this.resolvedByKeyVal.reduce((acc, val) => {
        acc[val.key] = val.name;
        return acc;
      }, {});
    },
    inspectionTypesKV() {
      return this.inspectionTypes.reduce((agg, el) => {
        agg[el.key] = {
          label: el.details.language.nominative.short,
          description: el.description,
          color: `rgb(${el.details.color})`,
        };
        return agg;
      }, {});
    },
    scenarioTypesKV() {
      return listToKeyValue(this.scenarioTypes, 'id', 'name');
    },
    scenarioFlagsKV() {
      return this.scenarioFlags.reduce((agg, el) => {
        agg[el.id] = {
          label: el.name,
          description: el.description,
          // FIXME: добейся от бэка получения цветов в RGB
          textColor: el.appearance.textColor,
          backgroundColor: `rgb(${el.appearance.backgroundColor})`,
        };
        return agg;
      }, {});
    },

    items: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },

    allowEditing() {
      return this.editingRule;
    },
  },

  created() {
    this.$loadingNotify(
      this.fetchScenarioFlags(),
      'fetchScenarioFlags',
      'Не удалось загрузить флаги сценариев',
    );
    this.$loadingNotify(
      this.fetchScenarioTypes(),
      'fetchScenarioTypes',
      'Не удалось загрузить типы сценариев',
    );
    this.$loadingNotify(
      this.fetchInspectionTypes(),
      'fetchInspectionTypes',
      'Не удалось загрузить типы осмотров',
    );
  },

  mounted() {
    this.countColumns = this.$el.querySelectorAll('th').length;
  },

  methods: {
    ...mapActions('REGISTRY', [
      'fetchScenarioFlags',
      'fetchScenarioTypes',
      'fetchInspectionTypes',
    ]),

    addRow() {
      this.$emit('addRow');
    },

    cloneRow(id) {
      this.$emit('cloneRow', id);
    },

    removeRow(id) {
      this.$emit('removeRow', id);
    },

    /**
     * For start editing added row
     * @public
     */
    startEditing(id) {
      if (!this.allowEditing) return;
      if (this.editing.indexOf(id) > -1) return;

      this.editing.push(id);
    },

    /**
     * For stop editing row
     * @public
     */
    stopEditing(id) {
      const index = this.editing.indexOf(id);
      if (index > -1) this.editing.splice(index, 1);
    },

    /**
     * Stop editing rows
     * @public
     */
    clearEditingList() {
      this.editing = [];
    },
  },
};
</script>
