import { createHTTP } from '../api';
import resourceServiceFactory from '@/utils/resourceServiceFactory';
import { pollerServiceFactory } from '@/utils/pollerServiceFactory';
import env from '@/plugins/env';

export const structures = createHTTP(env.get('VUE_APP_STRUCTURES_API') + '/v3');

const poller = pollerServiceFactory(taskId =>
  structures.get('/export/' + taskId),
);

export default {
  // Organization
  ...resourceServiceFactory(structures, 'organization', ['status']),

  organizationUpdateHolding(id, holdingId) {
    return structures
      .put(`/organizations/${id}/holding`, { holdingId })
      .then(res => res.data);
  },

  getOrganizationPreviews(params) {
    return structures
      .get('/organizations/previews', { params })
      .then(res => res.data);
  },

  getOrganizationSettings(id) {
    return structures
      .get(`/organizations/${id}/settings`)
      .then(res => res.data);
  },

  setOrganizationSettings(id, payload) {
    return structures
      .put(`/organizations/${id}/settings`, payload)
      .then(res => res.data);
  },

  // action - 'enable' / 'disable'
  setOrgSnils(id, action) {
    return structures
      .put(`/organizations/${id}/settings/snils/${action}`)
      .then(res => res.data);
  },

  setOrgEmployee(id, action) {
    return structures
      .put(`/organizations/${id}/settings/employee_modify/${action}`)
      .then(res => res.data);
  },

  orgNiiatRegistration(id) {
    return structures
      .post(`/organizations/${id}/niiat/register`)
      .then(res => res.data);
  },

  // status - 'enable' / 'disable'
  setNiiatStatus(id, status) {
    return structures
      .post(`/organizations/${id}/niiat/${status}`)
      .then(res => res.data);
  },

  // status - 'enable' / 'disable'
  setEsiaStatus(id, status) {
    return structures
      .put(`/organizations/${id}/settings/esia/${status}`)
      .then(res => res.data);
  },

  /**
   * Мед. организации, которыми пользователь может управлять
   * @action ORG_RPM
   */
  getOrganizationPreviewsMedical(params) {
    return structures
      .get('/organizations/previews/medical', { params })
      .then(res => res.data);
  },
  getOrganizationPreviewsBy(ids) {
    return structures
      .get('/organizations/previews_by', { params: { ids: ids.join() } })
      .then(res => res.data);
  },

  organizationUpdateCategory(id, categoryId) {
    return structures
      .put(`/organizations/${id}/category`, { categoryId })
      .then(res => res.data);
  },

  organizationUpdateGroups(id, groupIds) {
    return structures
      .put(`/organizations/${id}/groups`, { groupIds })
      .then(res => res.data);
  },

  // Organization categories
  ...resourceServiceFactory(structures, 'category'),
  getCategoryPreviews(params) {
    return structures
      .get('/categories/previews', { params })
      .then(res => res.data);
  },
  getCategoryPreviewsBy(ids) {
    return structures
      .get('/categories/previews_by', { params: { ids: ids.join() } })
      .then(res => res.data);
  },

  // Organization groups
  ...resourceServiceFactory(structures, ['organization', 'group']),
  getOrganizationGroupPreviews(params) {
    return structures
      .get('/organizations/groups/previews', { params })
      .then(res => res.data);
  },
  getOrganizationGroupPreviewsBy(ids) {
    return structures
      .get('/organizations/groups/previews_by', { params: { ids: ids.join() } })
      .then(res => res.data);
  },

  // Host
  ...resourceServiceFactory(structures, 'host', ['status']),
  // FF for FormFactor, since we have different actions for 2 cases:
  // - when user has rights to also update formfactor is's this endpoint
  // - when user doesn't it's default one
  hostUpdateFF(data) {
    return structures
      .patch(`hosts/${data.id || data.key}/formfactor`, data)
      .then(res => res.data);
  },
  getHostPreviews(params) {
    return structures.get('/hosts/previews', { params }).then(res => res.data);
  },
  getHostPreviewsBy(ids) {
    return structures
      .get('/hosts/previews_by', { params: { ids: ids.join() } })
      .then(res => res.data);
  },
  setHostGroups(id, groupIds) {
    return structures
      .put(`/hosts/${id}/groups`, { groupIds })
      .then(res => res.data);
  },

  hostUpdateCategory(id, categoryId) {
    return structures
      .put(`/hosts/${id}/category`, { categoryId })
      .then(res => res.data);
  },
  getMedicalDeviceTypes() {
    return structures.get('/hosts/medical-device-types').then(res => res.data);
  },
  hostNiiatRegistration(id) {
    return structures.post(`/hosts/${id}/niiat/register`).then(res => res.data);
  },

  // Host groups
  ...resourceServiceFactory(structures, ['host', 'group']),
  hostGroupList(params) {
    return structures.get('/hosts/groups', { params }).then(res => res.data);
  },
  getHostGroupPreviews(params) {
    return structures
      .get('/hosts/groups/previews', { params })
      .then(res => res.data);
  },
  getHostGroupPreviewsBy(ids) {
    return structures
      .get('/hosts/groups/previews_by', { params: { ids: ids.join() } })
      .then(res => res.data);
  },

  // Points
  ...resourceServiceFactory(structures, 'point', ['status']),
  getPointPreviews(params) {
    return structures.get('/points/previews', { params }).then(res => res.data);
  },
  getPointPreviewsBy(ids) {
    return structures
      .get('/points/previews_by', { params: { ids: ids.join() } })
      .then(res => res.data);
  },

  getTimezone(params) {
    return structures.post('/points/timezone', params).then(res => res.data);
  },

  // Employee
  ...resourceServiceFactory(structures, 'employee', ['status']),
  getEmployeePreviews(params) {
    return structures
      .get('/employees/previews', { params })
      .then(res => res.data);
  },
  getEmployeePreviewsBy(ids) {
    return structures
      .get('/employees/previews_by', { params: { ids: ids.join() } })
      .then(res => res.data);
  },
  getEmployeePreview(id) {
    return structures.get(`/employees/${id}/preview`).then(res => res.data);
  },
  /**
   * @action EMP_CT
   */
  createTestEmployee(data) {
    return structures.post('/test_employees', data).then(res => res.data);
  },
  employeeChangeGroups(id, groupIds) {
    return structures
      .put(`/employees/${id}/groups`, { groupIds })
      .then(res => res.data);
  },
  // used for inspection page to get the info if current user can view this
  // employee card
  getPermissionToReadEmployee(id) {
    return structures.get(`/employees/${id}/is_allowed`).then(res => res.data);
  },

  /**
   * @action EMG_CP
   */
  updateEmployeePhone(id, phone) {
    return structures
      .put(`/employees/${id}/phone`, { phone })
      .then(res => res.data);
  },

  // Employee groups
  ...resourceServiceFactory(structures, ['employee', 'group']),
  getEmployeeGroupPreviews(params) {
    return structures
      .get('/employees/groups/previews', { params })
      .then(res => res.data);
  },
  getEmployeeGroupPreviewsBy(ids) {
    return structures
      .get('/employees/groups/previews_by', { params: { ids: ids.join() } })
      .then(res => res.data);
  },

  uploadPhoto(id, photo) {
    const formData = new FormData();
    formData.append('file', photo);

    return structures
      .post(`/employees/${id}/photo`, formData)
      .then(res => res.data);
  },

  employeeArchivePhoto(id) {
    return structures
      .post(`/employees/${id}/photo/archive`)
      .then(res => res.data);
  },

  async employeeImport({ file, orgId, onlyCreate }) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('onlyCreate', onlyCreate);

    const { taskId } = await structures
      .post(`/organizations/${orgId}/employees/async-import`, formData)
      .then(res => res.data);

    const poller = pollerServiceFactory(taskId =>
      structures.get(`organizations/${orgId}/employees/async-import/${taskId}`),
    );

    return poller(taskId);
  },

  async employeeExport(query) {
    const { taskId } = await structures
      .post('/export/employees', { ...query, format: 'xlsx' })
      .then(res => res.data);

    return poller(taskId);
  },

  employeeChangeOrg(id, { orgId }) {
    return structures
      .post(`/employees/${id}/organizations/${orgId}`)
      .then(res => res.data);
  },

  /** @action EMP_FR */
  getEmployeeFlagsIgnoreWorkRestBans(employeeId) {
    return structures
      .get(`/employees/${employeeId}/flags/ignore_work_rest_bans`)
      .then(res => res.data);
  },
  /** @action EMP_FRS */
  setEmployeeFlagsIgnoreWorkRestBans(employeeId, value) {
    return structures
      .put(`/employees/${employeeId}/flags/ignore_work_rest_bans`, { value })
      .then(res => res.data);
  },

  // Export
  getExportLimits() {
    return structures.get('/export/limits').then(res => res.data);
  },

  getOrganizationGuests(orgId) {
    return structures
      .get(`/organizations/${orgId}/guests`)
      .then(res => res.data);
  },
  getParentOrgs(orgId) {
    return structures
      .get(`/organizations/${orgId}/is_guest_in`)
      .then(res => res.data);
  },

  // uses post instead of put in case of new row of guest orgs since we don't
  // have it's hash yet
  setOrgGuests(groupId, ids, hash, postp) {
    return structures[postp ? 'post' : 'put'](
      `/hosts/groups/${groupId}/guests`,
      { ids, hash },
    ).then(res => res.data);
  },

  // Orgs and Org groups
  getOrgsAndOrgGroupsPreviews(params) {
    return structures
      .get(`/composite/orgs_org_groups`, { params })
      .then(res => res.data);
  },
  getOrgsAndOrgGroupsPreviewsBy({ orgIds, medOrgIds, orgGroupIds }) {
    return structures
      .get('/composite/orgs_org_groups_by', {
        params: {
          orgIds: orgIds.length ? orgIds.join() : undefined,
          medOrgIds: medOrgIds.length ? medOrgIds.join() : undefined,
          orgGroupIds: orgGroupIds.length ? orgGroupIds.join() : undefined,
        },
      })
      .then(res => res.data);
  },

  // HostGroups and Points
  getHostGroupsAndPointsPreviews(type, id, params) {
    return structures
      .get(`/composite/${type}/${id}/host_groups_and_points`, { params })
      .then(res => res.data);
  },
  getHostGroupsAndPointsPreviewsBy({ pointIds, hostGroupIds }) {
    return structures
      .get('/composite/host_groups_and_points_by', {
        params: {
          pointIds: pointIds.length ? pointIds.join() : undefined,
          hostGroupIds: hostGroupIds.length ? hostGroupIds.join() : undefined,
        },
      })
      .then(res => res.data);
  },

  // Composite EmployeeGroups
  getCompositeEmployeeGroupPreviews(type, id, params) {
    return structures
      .get(`/composite/${type}/${id}/employee_groups`, { params })
      .then(res => res.data);
  },
  getCompositeEmployeeGroupPreviewsBy(ids) {
    return structures
      .get('/composite/employee_groups_by', {
        params: { employeeGroupIds: ids.join() },
      })
      .then(res => res.data);
  },

  // end composite employee groups

  getAmbientSettings(id) {
    return structures.get(`/points/${id}/ambient`).then(res => res.data);
  },
  updateAmbientSettings(id, payload) {
    return structures
      .patch(`/points/${id}/ambient`, payload)
      .then(res => res.data);
  },

  // help files api
  getHelpFiles() {
    // HF_R
    return structures.get('/help_files').then(res => res.data);
  },
  createHelpFile(key, pdf, name) {
    // HF_ADD
    const formData = new FormData();
    formData.append('file', pdf);
    formData.append('name', name);

    return structures
      .post(`/help_files/${key}`, formData)
      .then(res => res.data);
  },
  putHelpFile(key, pdf, name) {
    // HF_U
    const formData = new FormData();
    formData.append('file', pdf);
    formData.append('name', name);

    return structures.put(`/help_files/${key}`, formData).then(res => res.data);
  },
  removeHelpFile(key) {
    // HF_D
    return structures.delete(`/help_files/${key}`).then(res => res.data);
  },
  getSoftwares() {
    // HS_G
    return structures.get('/softwares').then(res => res.data);
  },
};
