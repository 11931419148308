// api
import bansApi from '@/api/services/bans';
import medrecApi from '@/api/services/medrec';
import stickersApi from '@/api/services/stickers';
import processingApi from '@/api/services/processing';

import { can } from '@/plugins/can';

export default {
  state: {
    relatedProfiles: null,
  },

  getters: {
    relatedProfiles: state => state.relatedProfiles,
  },

  mutations: {
    relatedProfiles: (state, value) => (state.relatedProfiles = value),
    relatedProfile: (state, { type, value }) =>
      (state.relatedProfiles[type] = value),
  },

  actions: {
    async fetchRelatedProfiles({ getters, commit }) {
      const id = getters.id;
      const actions = [];
      const result = {};

      if (can('BPO_RK'))
        actions.push(
          bansApi
            .getOrganizationProfilePreview(id)
            .then(data => (result.bans = data)),
        );
      if (can('ODPO_RP'))
        actions.push(
          medrecApi
            .getOrganizationProfilePreview(id)
            .then(data => (result.documentsOutgoing = data)),
        );
      if (can('SOP_RP'))
        actions.push(
          stickersApi
            .getOrganizationProfilePreview(id)
            .then(data => (result.stickers = data)),
        );
      if (can('PPR_R'))
        actions.push(
          processingApi
            .getOrganizationProfilePreview(id)
            .then(data => (result.processing = data)),
        );

      await Promise.all(actions);

      commit('relatedProfiles', result);
    },

    async changeRelatedProfile({ getters, commit }, { type, key }) {
      const changers = {
        bans: bansApi.changeOrganizationProfile,
        documentsOutgoing: medrecApi.changeOrganizationProfile,
        stickers: stickersApi.changeOrganizationProfile,
        processing: processingApi.changeOrganizationProfile,
      };

      const id = getters.id;
      const value = await changers[type](id, key);

      commit('relatedProfile', { type, value });
    },
  },
};
