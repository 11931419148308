<template>
  <v-menu
    ref="menu"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :close-on-content-click="false"
    :open-on-click="!readonly && !disabled"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-if="type === 'month'"
        v-bind="attrs"
        required
        autocomplete="off"
        :append-icon="$vuetify.icons.values.calendar"
        :label="label"
        :value="formattedDate"
        :disabled="disabled"
        :readonly="true"
        :dense="dense"
        :outlined="outlined"
        :clearable="clearable"
        :error-messages="errorMessages"
        :hide-details="hideDetails"
        v-on="on"
        @input="dateInput"
      />
      <MaskField
        v-else
        v-bind="attrs"
        required
        placeholder="ДД.ММ.ГГГГ"
        autocomplete="off"
        :append-icon="$vuetify.icons.values.calendar"
        :mask="maskDatePicker"
        :label="label"
        :value="formattedDate"
        :disabled="disabled"
        :readonly="readonly"
        :dense="dense"
        :outlined="outlined"
        :clearable="clearable"
        :error-messages="errorMessages"
        :hide-details="hideDetails"
        :first-format="firstFormat"
        v-on="on"
        @input="dateInput"
      />
    </template>
    <v-date-picker
      no-title
      scrollable
      first-day-of-week="1"
      color="primary"
      :value="innerValue"
      :type="type"
      :min="min"
      :max="max"
      @input="updateDate"
    />
  </v-menu>
</template>

<script>
import { DateTime } from '@/plugins/luxon';
import { parseDate, currentDate } from '@/utils/helpers';
import { maskDatePicker } from '@/utils/masks';

import MaskField from '@/components/controls/MaskField';

export default {
  components: { MaskField },
  props: {
    type: { type: String, default: 'date' },
    value: { type: String, default: '' },
    errorMessages: { type: [String, Array], default: () => [] },
    label: { required: true, type: String },
    dense: Boolean,
    outlined: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    maxCurrent: Boolean, // whether max date should be current date
    min: { type: String, default: undefined },
    hideDetails: { type: [String, Boolean], default: false },
    clearable: Boolean,
    firstFormat: Boolean,
  },

  computed: {
    maskDatePicker: () => maskDatePicker,

    formattedDate() {
      const date = parseDate(this.value);
      return date
        ? date.toFormat(this.type === 'month' ? 'LLLL y' : 'dd.MM.y')
        : undefined;
    },

    innerValue() {
      const date = DateTime.fromISO(this.value);
      return date.isValid ? this.value : null;
    },

    max() {
      return this.maxCurrent ? currentDate() : undefined;
    },
  },

  methods: {
    dateInput(value) {
      const unmaskedVal = value.replace(/(_|\W)/g, ''); // leave out only chars
      if (!value || !unmaskedVal.length) {
        // users want to see error message when they leave this field empty
        // for some reason... (SW-5576)
        this.$emit('input', '');
        return '';
      }
      const date = parseDate(value);

      if (date.isValid && value.length === 10) {
        this.$emit('input', date.toFormat('yyyy-MM-dd'));
      }
    },

    updateDate(value) {
      this.$refs.menu.save(value);
      this.$emit('input', value);
    },
  },
};
</script>
