import { ModuleDefinition } from '@/models/app/ModuleDefinition';

export default {
  hasChildren: true,
  menu: [
    {
      title: 'Админка',
      name: 'admin',
      priority: 6,
    },
  ],
  routes: [
    {
      path: '/admin',
      name: 'admin',
      component: () => import('@/views/Provider.vue'),
    },
  ],
} as ModuleDefinition;
