var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$wait('fetchRoleTypes'))?_c('Spinner'):_c('List',{attrs:{"available-actions":{
    showAction: false,
    createAction: _vm.$can('RL_C'),
  },"can-show-scoped-actions":_vm.$can('RL_U') || _vm.$can('RL_AG') || _vm.$can('RL_D')},on:{"item:create":_vm.openForm},scopedSlots:_vm._u([{key:"item.actions.append",fn:function(ref){
  var item = ref.item;
return [(_vm.$can('RL_U'))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openForm({ item: item })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактировать")])]):_vm._e(),(_vm.$can('RL_AG'))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openEditGroups(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактировать группы")])]):_vm._e(),(_vm.$can('RL_D'))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.openDeleteModal(item)}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-trash-alt")])],1)]}}],null,true)},[_c('span',[_vm._v("Удалить")])]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }