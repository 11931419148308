import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import RouteGuard from '@/components/RouteGuard';
import ORGANIZATION_GROUPS from './store/list';
import ORGANIZATION_GROUPS_ITEM from './store/item';
import EMBEDDED_ORG_LIST from './store/embeddedOrgList';

export default {
  namespaced: true,
  store: {
    ORGANIZATION_GROUPS,
    ORGANIZATION_GROUPS_ITEM,
    ORGANIZATION_GROUPS_ORGANIZATION_LIST: EMBEDDED_ORG_LIST,
  },
  modals: [
    {
      name: 'orgGroupForm',
      component: () =>
        import('@/modules/structures/organizations-groups/modals/Form.vue'),
    },
  ],
  routes: [
    {
      path: 'organization_groups',
      name: 'organization_groups',
      component: () => import('./view/list.vue'),
      meta: {
        title: 'Все группы организаций',
        action: 'ORGG_R',
      },
    },
    {
      path: 'organization_groups/:id',
      name: 'organization_groups:item',
      component: RouteGuard({
        storeModule: 'STRUCTURES/ORGANIZATION_GROUPS_ITEM',
        component: () => import('./view/item.vue'),
      }),
      meta: {
        title: 'Страница группы организаций',
        action: 'OG_RD',
        // otherwise when updating embedded lists on tab items
        // the page will jump up
        disableScrollRestoring: true,
      },
    },
  ],
  menu: [
    {
      title: 'Орг Группы',
      action: 'ORGG_R',
      name: 'organization_groups',
      priority: 3,
    },
  ],
} as ModuleDefinition;
