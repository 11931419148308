import notifApi from '@/api/services/notifications';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { createModule } from '@/utils/vuex/createModule';

export default createModule(
  crudListFactory({
    fetchMethod: notifApi.profileList,
    createMethod: notifApi.profileCreate,
    defaultSort: {
      minLength: 2,
    },
  }),
);
