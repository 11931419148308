import structApi from '@/api/services/structures';
import listSimpleFactory from '@/utils/vuex/listSimpleFactory';
import { createModule } from '@/utils/vuex/createModule';

const getters = {
  listQuery: (state, getters, _, rootGetters) => ({
    ...state.listQuery,
    search: state.listQuery.search || null,
    orgIds: `${rootGetters['STRUCTURES/ORGANIZATION_ITEM/singleItem'].id}`,
  }),
};

export default createModule(
  listSimpleFactory({
    fetchMethod: structApi.pointList,
    createMethod: structApi.pointCreate,
    filters: {
      isActive: { type: Boolean, default: null },
      orgIds: { type: Array, default: undefined },
    },
    linkedList: 'STRUCTURES/POINTS',
  }),
  { getters },
);
