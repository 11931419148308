var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"fill-height":"","column":""}},[_c('div',{staticClass:"pb-2 text-caption"},[_vm._v(" Блокировки "),_c('v-tooltip',{attrs:{"right":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("far fa-question-circle")])],1)]}}])},[_c('span',{staticStyle:{"width":"200px"}},[_vm._v(" В списке выводятся только блокировки и разблокировки работника по алкоголю, травмам и изменением цвета кожного покрова ")])])],1),_c('v-layout',{attrs:{"fill-height":""}},[(_vm.value.length > 0)?_vm._l((_vm.items),function(item){return _c('v-menu',{key:item.type + item.id,staticClass:"flex-grow-1",attrs:{"top":"","offset-y":"","open-on-hover":"","nudge-bottom":"-10px","close-delay":"100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0 flex-grow-1",attrs:{"color":item.color,"height":"100%","text":"","column":"","justify-space-between":"justify-space-between","fill-height":""}},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex flex-column text-center"},[_c('v-icon',{staticStyle:{"font-size":"20px","color":"inherit"}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',{staticClass:"pt-2"},[_vm._v(_vm._s(item.previewDate))])],1)])]}}],null,true)},[_c('v-card',{staticClass:"pa-3 text-body-2",attrs:{"width":"390px"}},[_c('span',{staticClass:"pr-2 text-caption"},[_vm._v(_vm._s('#' + item.id))]),_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(item.title))]),_c('v-divider',{staticClass:"my-2"}),_c('div',[_c('strong',[_vm._v(_vm._s(item.date))])]),(item.type == 'ban')?[_c('div',[_vm._v(_vm._s(item.dueDate))]),(item.medicId)?_c('div',{staticClass:"mt-2"},[_vm._v(" Заблокировал: "+_vm._s(_vm.getMedicById(item.medicId))+" ")]):_vm._e(),_c('div',[_vm._v(" На основании осмотра "),_c('v-btn',{staticClass:"px-1",attrs:{"text":"","small":"","color":"primary","target":"_blank","to":{
                  name: 'inspections:card',
                  params: { id: item.inspectionId },
                }}},[_vm._v(" #"+_vm._s(item.inspectionId)+" ")])],1),_c('div',[_vm._v("Причина: "+_vm._s(item.reason))]),(item.comment)?_c('div',[_vm._v("Комментарий: "+_vm._s(item.comment))]):_vm._e()]:[(item.unban && item.unban.issuerId)?_c('div',{staticClass:"mt-2"},[_vm._v(" Разблокировал: "+_vm._s(_vm.getMedicById(item.unban.issuerId))+" ")]):_vm._e(),_c('div',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(item.unbanReason)+" "),(item.documentId)?_c('v-btn',{staticClass:"px-1",attrs:{"color":"primary","small":"","depressed":"","text":""},on:{"click":function($event){return _vm.showDocumentModal(item.documentId)}}},[_vm._v(" справки ")]):_vm._e()],1)]],2)],1)}):_c('v-card-text',{staticClass:"text-center align-self-center"},[_vm._v(" История банов и недопусков отсутствует ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }