<template>
  <AsyncSelect
    v-bind="computedProps"
    v-on="{ ...$listeners, input: handleInput }"
  >
    <template #item:description="{ item }">
      <v-chip v-if="item.isMedical" color="success" x-small>
        Медорганизация
      </v-chip>
      <v-chip
        v-if="!item.isMedical && item.type === 'org'"
        color="primary"
        x-small
      >
        Организация
      </v-chip>
      <v-chip
        v-if="!item.isMedical && item.type === 'org_group'"
        color="warning"
        x-small
      >
        Группа организаций
      </v-chip>
      <v-chip v-if="!item.isActive" x-small color="error" class="ml-1">
        Неактивна
      </v-chip>
    </template>
  </AsyncSelect>
</template>

<script>
import asyncSelectMixin from '@/components/controls/asyncSelectMixin';

export default {
  extends: asyncSelectMixin,
  props: {
    title: { type: String, default: 'Организации или группы организаций' },
    value: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    label() {
      return this.title;
    },
    computedValue() {
      if (!this.value) return null;
      return this.value
        .map(e => ({ ...e.parent }))
        .map(mapItemValue)
        .filter(e => e.id)
        .map(e => e.id);
    },
  },

  methods: {
    fetcher(params) {
      return this.$store
        .dispatch('STRUCTURES/fetchOrgsAndOrgGroupsPreviews', params)
        .then(res => {
          return {
            ...res,
            items: res.items.map(mapItemValue),
          };
        });
    },
    async fetcherBy(ids) {
      const { medOrgs, orgs, orgGroups } = separateIds(ids);

      return this.$store
        .dispatch('STRUCTURES/fetchOrgsAndOrgGroupsPreviewsBy', {
          orgIds: orgs,
          medOrgIds: medOrgs,
          orgGroupIds: orgGroups,
        })
        .then(res => {
          return res.map(mapItemValue);
        });
    },
    handleInput(event) {
      const mappedInput = event.map(e => {
        return {
          parent: {
            type: FORMAT_TYPE[e[0]],
            id: Number(e.substring(1)),
          },
        };
      });
      this.$emit('input', [...mappedInput]);
    },
  },
};

const TYPE_TO_PREFIX = { med_org: 'm', org: 'o', org_group: 'g' };

const FORMAT_TYPE = {
  m: 'med_org',
  o: 'org',
  g: 'org_group',
};

function mapItemValue(item) {
  const id = item.isMedical
    ? 'm' + item.id
    : TYPE_TO_PREFIX[item.type] + item.id;
  return { ...item, id };
}

function separateIds(ids) {
  return {
    medOrgs: ids
      .filter(item => item[0] === 'm')
      .map(item => Number(item.substring(1))),
    orgs: ids
      .filter(item => item[0] === 'o')
      .map(item => Number(item.substring(1))),
    orgGroups: ids
      .filter(item => item[0] === 'g')
      .map(item => Number(item.substring(1))),
  };
}
</script>
