import processingApi from '@/api/services/processing';
import structApi from '@/api/services/structures';

import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

const state = {
  medOrg: null,
};
const getters = {
  medOrg: state => state.medOrg,
};
const mutations = {
  medOrg: (state, value) => (state.medOrg = value),
};
const actions = {
  fetchSingle: async ({ commit, dispatch }, id) => {
    try {
      // Очистим старые данные по мед организации,
      // чтобы они не отображались в текущей до получения новых данных
      commit('medOrg', null);

      commit('singleFetching');
      const response = await processingApi.medicGroupsGet(id);
      commit('singleFetched', response);
      dispatch('fetchMedOrg', response.organization.id);
      return response;
    } catch (err) {
      commit('singleErrorFetched', err);
      throw err;
    }
  },

  singleItemUpdate: async ({ getters, commit, dispatch }) => {
    const response = await dispatch('fetchSingle', getters.singleItem.id);
    commit('singleItemUpdate', response);
    dispatch('pushLinkedList', {
      action: 'listUpdateItem',
      payload: response,
    });
  },

  fetchMedOrg: async ({ commit }, id) => {
    const response = await structApi.getOrganizationPreviewsBy([id]);
    commit('medOrg', response[0]);
  },
};
export default createModule(
  crudSingleFactory({
    fetchMethod: processingApi.medicGroupsGet,
    updateMethod: processingApi.medicGroupsUpdate,
    linkedList: 'MEDBLOCK/MEDIC_GROUPS',
  }),
  { state, getters, actions, mutations },
);
