var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{attrs:{"dense":""}},[_c('colgroup',[_c('col',{attrs:{"width":"30%"}}),_c('col',{attrs:{"width":"65%"}}),_c('col',{attrs:{"width":"5%"}})]),_c('thead',[_c('tr',[_c('th',{staticClass:"pa-4"},[_vm._v("Тип")]),_c('th',{staticClass:"pa-4"},[_vm._v("Название")]),_c('th',{staticClass:"pa-4"})])]),_c('tbody',_vm._l((_vm.value),function(config){return _c('tr',{key:config.key},[_c('td',{staticClass:"pa-4 grey--text"},[_vm._v(_vm._s(_vm.formFactors[config.formFactor]))]),_c('td',{staticClass:"pa-4"},[_c('LinkButton',{attrs:{"text":config.name,"action":_vm.$can('GT_GPR'),"to":{
            name: 'admin:profiles:item',
            params: { key: config.key },
          }}}),(config.isDefault)?_c('span',{staticClass:"text--disabled",staticStyle:{"white-space":"nowrap"}},[_vm._v(" (Профиль по умолчанию) ")]):_vm._e()],1),_c('td',{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex justify-end"},[(_vm.$can('GT_POPR'))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.changeProfile(config)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Изменить")])]):_vm._e(),(_vm.$can('GT_CPR'))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{
                  name: 'admin:profiles:create',
                }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Создать")])]):_vm._e()],1)])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }