export const messages = {
  resourceName: 'подписок',
  searchFilterLabel: 'Поиск по названию',
  searchFilterPlaceholder: '',
};

export const entity = [
  { key: 'id', label: 'ID' },
  { key: 'name_org', label: 'Название / Орг. владелец', hideInCard: true },
  { key: 'name', label: 'Название', hideInTable: true },
  {
    key: 'orgId',
    label: 'Организация владелец',
    hideInTable: true,
    convert: (item, getters) =>
      getters['NOTIFICATIONS/SUBSCRIPTIONS/getOrgPreviewsById'](item.orgId)
        ?.name || '-',
  },
  {
    key: 'profile',
    label: 'Профиль',
    convert: item => item.profile?.name || '–',
  },
  // NOTE: better do not delete comment below cuz some day we might need to
  // render previews of groups in list
  // {
  //   key: 'employeeGroups',
  //   label: 'Группы работников',
  //   convert: (item, getters) =>
  //     item.filters
  //       .map(el => el.employeeGroups)
  //       .flat()
  //       .map(
  //         id =>
  //           getters['NOTIFICATIONS/SUBSCRIPTIONS/previews'].employee_groups[id]
  //             .name || '-',
  //       )
  //       .join(', '),
  // },
  // {
  //   key: 'hostGroups',
  //   label: 'Группы ПАКов',
  //   convert: (item, getters) =>
  //     item.filters
  //       .map(el => el.hostGroups)
  //       .flat()
  //       .map(
  //         id =>
  //           getters['NOTIFICATIONS/SUBSCRIPTIONS/previews'].host_groups[id]
  //             .name || '-',
  //       )
  //       .join(', '),
  // },
  {
    key: 'filters',
    label: 'Фильтры',
    headerProps: { width: '280px' },
    convert: item => {
      const pluralOrgs =
        item.filters.length > 1 ? 'организациям' : 'организации';
      return `по ${item.filters.length} ${pluralOrgs}`;
    },
  },
  {
    key: 'multiOrg',
    label: 'Мульти',
    headerProps: {
      width: '60px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
    convert: item => (item.multiOrg ? 'Да' : 'Нет'),
  },
  {
    key: 'recipientIds',
    label: 'Получатели',
    headerProps: {
      width: '106px',
      align: 'center',
    },
    convert: item => item.recipients.length,
  },
];

export const filters = {
  multiOrg: { type: Boolean, default: null },
  orgIds: { type: Array, default: null },
  accountIds: { type: Array, default: null },
};
