<template>
  <List
    search-filter
    :available-actions="{
      createAction: $can('ACCB_C'),
      showAction: $can('ACC_RD'),
      importAction: $can('ACCB_I'),
    }"
    :import-settings="{ chooseOnlyCreate: false }"
    @item:create="managerModal"
  >
    <template #filters="query">
      <v-col cols="12" sm="4" md="2">
        <v-select
          v-model="query.isEnabled"
          label="Статус пользователя"
          :items="statusItems"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :menu-props="{ offsetY: true }"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <Select
          v-model="query.roleKeys"
          multiple
          label="Роли"
          item-value="key"
          :items="accountRoles"
          dense
          clearable
        />
      </v-col>
    </template>

    <template #table.item.isEnabled="{ item }">
      <Indicator
        :active="item.isEnabled"
        :tooltip="item.isEnabled ? 'Активный' : 'Неактивный'"
      />
    </template>

    <template #table.item.name="{ item }">
      <div style="max-width: 300px">{{ name(item.profile) }}</div>
    </template>
  </List>
</template>

<script>
// TODO: isEnabled -> isActive (ask backend to change)
import ListFactory from '@/components/crud/ListFactory';
import Select from '@/components/controls/Select';

import { mapActions, mapGetters } from 'vuex';
import { STATUS } from '@/utils/constants.js';
import { entity, messages, filters } from '../entity';

import waitable from '@/utils/mixins/waitable';
import Indicator from '@/components/Indicator.vue';
import { name } from '@/utils/convert';

const List = ListFactory({
  storeModule: 'ADMIN/USERS',
  messages,
  entity,
  filters,
  viewRoute: 'admin:users:item',
});

export default {
  components: { List, Select, Indicator },
  mixins: [waitable],

  data: () => ({
    statusItems: STATUS,
  }),

  computed: {
    ...mapGetters('REGISTRY', ['accountRoles']),
  },

  async created() {
    this.$can('RL_RP') && (await this.fetchAccountRoles());
  },

  methods: {
    ...mapActions('REGISTRY', ['fetchAccountRoles']),

    name,

    managerModal(item, action = 'create') {
      this.$openModal('admin/userCreate', { id: item.id, action });
    },
  },
};
</script>
