<template>
  <v-card class="pa-3" min-width="130" height="100%">
    <div
      v-if="!value.length"
      class="spinner__wrapper d-flex flex-row justify-center align-center"
    >
      <Spinner size="20" :title="null" />
    </div>

    <template v-else>
      <v-menu offset-y top :close-on-content-click="false">
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="value.length"
            class="icon-absolute"
            icon
            x-small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
        <v-card class="statistic__block d-flex">
          <v-list dense class="pa-1">
            <v-list-item-group
              v-for="(group, index) in value"
              :key="index"
              color="primary"
              :value="activeKey"
              @change="changeActive"
            >
              <v-subheader>{{ group.title }}</v-subheader>
              <v-list-item
                v-for="(item, i) in group.list"
                :key="item.key + i"
                :value="item.key"
              >
                <v-list-item-content>
                  <div class="d-flex">
                    <span class="group__item__label">
                      {{ item.label }}
                    </span>
                    <span class="font-weight-bold">
                      {{ item.value }}
                    </span>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-divider vertical />

          <v-list v-if="active && detail.length" dense class="pa-1 ml-1">
            <v-subheader>{{ active.label }}</v-subheader>
            <v-list-item v-for="item in detail" :key="item.label">
              <v-list-item-content>
                <div class="d-flex">
                  <span class="detail__item__label">
                    {{ item.label }}
                  </span>
                  <span class="font-weight-bold">
                    {{ item.value }}
                  </span>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <div v-if="active">
        <div class="text-caption">
          {{ active.label }}
        </div>
        <div class="text-h4">
          {{ active.value }}
        </div>
      </div>
    </template>
  </v-card>
</template>

<script>
import Spinner from '@/components/Spinner.vue';

export default {
  components: {
    Spinner,
  },

  props: {
    value: {
      required: true,
      type: Array,
      default: () => [],
    },
    active: {
      type: Object,
      default: null,
    },
  },

  computed: {
    activeKey() {
      return this.active?.key || null;
    },

    detail() {
      return (this.active?.detail && this.active?.detail()) || null;
    },
  },

  methods: {
    changeActive(key) {
      if (!key) return;
      if (this.activeKey !== key) this.$emit('changeActive', key);
    },
  },
};
</script>

<style scoped>
.icon-absolute {
  position: absolute;
  top: 0;
  right: 0;
}

.spinner__wrapper {
  height: 100%;
  width: 100%;
}

.group__item__label {
  width: 140px;
  margin-right: 20px;
}

.detail__item__label {
  width: 200px;
  margin-right: 20px;
}
</style>
