export const messages = {
  resourceName: 'групп работников',
  searchFilterLabel: 'Поиск по группам работников',
  searchFilterPlaceholder: '',
};

export const entity = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: 'Название', hideInTable: true },
  { key: 'nameOrg', label: 'Название / Организация', hideInCard: true },
  { key: 'description', label: 'Описание' },
  {
    key: 'itemsCount',
    label: 'Кол-во работников',
    headerProps: {
      width: '110px',
      align: 'center',
    },
  },
];

export const entityEmbedded = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: 'Название' },
  { key: 'description', label: 'Описание' },
  {
    key: 'itemsCount',
    label: 'Кол-во работников',
    headerProps: { width: '110px', align: 'center' },
  },
];

export const filters = {
  orgIds: { type: Array, default: undefined },

  // fixed filter
  type: { type: String, default: 'public' },
};
