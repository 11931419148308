<template>
  <ModernModalLayout title="Комментарий" :watch-for="{ text }">
    <v-textarea
      v-model="text"
      autocomplete="off"
      outlined
      dense
      label="Добавить комментарий"
      hide-details="auto"
      :rows="4"
      :error-messages="getValidationErrors('text')"
      :disabled="$wait('submit')"
      height="100%"
      @keyup.enter.prevent="handleSubmit"
    />
    <template #actions:append>
      <v-btn
        class="px-10"
        depressed
        :loading="$wait('submit')"
        color="primary"
        @click="handleSubmit"
      >
        Отправить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import validationMixin from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';

export default {
  name: 'ApprovalComment',
  components: { ModernModalLayout },
  mixins: [validationMixin, waitable],

  props: {
    onSubmit: { type: Function, default: null },
  },

  validations() {
    return {
      text: {
        required,
        maxSymbolsLength: maxLength(1000),
        minSymbolsLength: minLength(5),
      },
    };
  },

  data() {
    return {
      text: null,
    };
  },

  // crutch, otherwise "watch for" working since second symbol
  mounted() {
    this.text = '';
  },

  methods: {
    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },

    async handleSubmit() {
      if (!this.validate()) return;
      if (!this.onSubmit) return;

      await this.$loading(this.onSubmit(this.text), 'submit').then(() =>
        this.$emit('close'),
      );

      this.text = '';
      this.$v.$reset();
    },
  },
};
</script>
