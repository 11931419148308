<template>
  <ModernModalLayout
    size="xlarge"
    title="Добавление документа"
    :watch-for="data"
  >
    <ColumnLayout grid-template-columns="1fr 400px">
      <template #col-form>
        <v-form :disabled="$wait('handleSubmit')">
          <v-alert v-if="canApproveDocument" dense outlined type="info">
            У вас есть право не только создать документ, но и сразу подтвердить
            его.
          </v-alert>
          <v-text-field
            v-model="data.name"
            label="Название документа"
            dense
            outlined
            :error-messages="getValidationErrors('data.name')"
            @blur="validateField('data.name')"
          />
          <v-autocomplete
            v-model="data.type"
            label="Тип"
            outlined
            dense
            item-text="name"
            item-value="key"
            :items="incomingDocumentTypes"
            :loading="$wait('fetchTypes')"
            :error-messages="getValidationErrors('data.type')"
            @blur="validateField('data.type')"
          />

          <v-text-field
            v-model="data.issuedBy"
            label="Кем выдан"
            dense
            outlined
            :error-messages="getValidationErrors('data.issuedBy')"
            @blur="validateField('data.issuedBy')"
          />

          <div class="pb-4">
            <DatePicker
              v-model="data.issuedAt"
              label="Дата выдачи"
              dense
              outlined
              :max="maxIssuedAt"
              :error-messages="getValidationErrors('data.issuedAt')"
              @blur="validateField('data.issuedAt')"
            />
          </div>
          <v-textarea
            v-model.trim="data.comment"
            label="Комментарий"
            rows="3"
            dense
            outlined
            :error-messages="getValidationErrors('data.comment')"
            @blur="validateField('data.comment')"
          />

          <Select
            v-model="data.dueDate"
            class="mb-3"
            :items="periods"
            dense
            item-text="text"
            item-value="value"
            hide-details="auto"
            label="Срок действия:"
            :error-messages="getValidationErrors('data.dueDate')"
            @blur="handleFieldBlur('data.dueDate')"
          />

          <v-text-field
            v-if="needsDiagnosis && createAndApprove"
            v-model="data.diagnosis"
            class="mt-2"
            label="Диагноз"
            dense
            outlined
            :error-messages="getValidationErrors('data.diagnosis')"
            @blur="validateField('data.diagnosis')"
          />

          <v-checkbox
            v-if="data.type === 'HEALTH_CERTIFICATE' && canApproveDocument"
            v-model="data.includedInRiskGroup"
            :disabled="!createAndApprove"
            hint="Активируется только при создании и подтверждении документа"
            persistent-hint
            label="Учитывать в группах риска"
            class="ma-0 ml-2 mb-4"
          />

          <v-checkbox
            v-if="canApproveDocument"
            v-model="createAndApprove"
            label="Добавить и подтвердить"
            value="true"
            class="ma-2 mb-4"
            hide-details
            @change="data.includedInRiskGroup = false"
          />
        </v-form>
      </template>
      <template #col-uploadScans>
        <MultipleImageUploader
          v-model="data.scans"
          :error-messages="getValidationErrors('data.scans')"
        />
      </template>
    </ColumnLayout>

    <template #actions:append>
      <v-btn
        class="px-16"
        depressed
        :color="createAndApprove ? 'success' : 'primary'"
        :loading="$wait('handleSubmit')"
        @click="
          createAndApprove ? handleApproveDocument() : handleAddDocument()
        "
      >
        {{ createAndApprove ? 'Добавить и подтвердить' : 'Добавить' }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import validationMixin from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import plural from 'plural-ru';

import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import ColumnLayout from '@/components/layouts/ColumnLayout.vue';
import DatePicker from '@/components/controls/DatePicker.vue';
import MultipleImageUploader from '@/components/MultipleImageUploader.vue';
import Select from '@/components/controls/Select.vue';

import { mapActions, mapGetters } from 'vuex';
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import { currentDateTime, parseDate } from '@/utils/helpers';

export default {
  components: {
    ModernModalLayout,
    ColumnLayout,
    DatePicker,
    MultipleImageUploader,
    Select,
  },
  mixins: [validationMixin, waitable],
  props: {
    employee: {
      type: Object,
      required: true,
    },
  },

  validations() {
    return {
      data: {
        name: { required, maxSymbolsLength: maxLength(100) },
        type: { required },
        scans: { required },
        issuedAt: { required },
        issuedBy: { required, maxSymbolsLength: maxLength(150) },
        comment: { maxSymbolsLength: maxLength(1000) },
        dueDate: { required },
        diagnosis: {
          required: requiredIf(
            () => this.action === 'approve' && this.needsDiagnosis,
          ),
          maxSymbolsLength: maxLength(500),
        },
      },
    };
  },

  data() {
    return {
      action: 'add',
      createAndApprove: false,
      data: {
        name: null,
        type: null,
        scans: [],
        issuedAt: null,
        issuedBy: null,
        comment: null,
        dueDate: 1,
        includedInRiskGroup: null,
      },
    };
  },

  computed: {
    ...mapGetters('REGISTRY', ['incomingDocumentTypes']),

    maxIssuedAt() {
      return currentDateTime().toFormat('yyyy-MM-dd');
    },

    needsDiagnosis() {
      const needsDiagnosis = this.incomingDocumentTypes.find(
        item => item.key === this.data.type,
      )?.needsDiagnosis;
      let isDocumentOutdated = false;
      // if doc is not perpetual
      if (this.data.dueDate > 0 && this.data.issuedAt) {
        isDocumentOutdated =
          parseDate(this.data.issuedAt).plus({ years: this.data.dueDate }) <
          parseDate(this.maxIssuedAt);
      }
      return needsDiagnosis && !isDocumentOutdated;
    },

    canApproveDocument() {
      return this.$can('DOC_AP');
    },

    periods() {
      const res = [{ text: 'Бессрочно', value: 0 }];
      for (let i = 1; i <= 5; i++)
        res[i] = { text: `${i} ${plural(i, 'год', 'года', 'лет')}`, value: i };
      return res;
    },
  },

  watch: {
    'data.type': {
      handler() {
        this.data.includedInRiskGroup = null;
      },
    },
  },

  created() {
    this.$loadingNotify(
      this.$store.dispatch('REGISTRY/fetchIncomingDocumentTypes'),
      'fetchTypes',
      'Произошла ошибка загрузки типов',
    );
  },

  methods: {
    ...mapActions('DOCUMENTS/INCOMING_ITEM', ['approve', 'fetchEmployeeBans']),
    ...mapActions('DOCUMENTS/INCOMING', { add: 'createListItem' }),

    async addDocument() {
      const params = {
        ...this.data,
      };

      if (!(params.comment || '').trim()) {
        delete params.comment;
      }

      // 0 means perpetual
      if (params.dueDate === 0) {
        delete params.dueDate;
      } else {
        params.dueDate = parseDate(this.data.issuedAt)
          .plus({ years: params.dueDate })
          .toFormat('yyyy-MM-dd');
      }

      return this.add({ id: this.employee.id, data: params });
    },

    async handleAddDocument() {
      this.action = 'add';
      if (!this.validate()) return;

      await this.$loadingNotify(
        this.addDocument(),
        'handleSubmit',
        'Произошла ошибка добавления документа',
        'Документ успешно добавлен',
      );

      await this.$store.dispatch('EMPLOYEE/fetchMedrec', this.employee.id);
      this.$emit('close');
    },

    async handleApproveDocument() {
      this.action = 'approve';
      if (!this.validate()) return;

      const doc = await this.$loadingNotify(
        this.addDocument(),
        'handleSubmit',
        'Произошла ошибка добавления документа',
      );

      const dueDate =
        this.data.dueDate > 0
          ? parseDate(this.data.issuedAt)
              .plus({ years: this.data.dueDate })
              .toFormat('yyyy-MM-dd')
          : null;

      const diagnosis = this.needsDiagnosis ? this.data.diagnosis : null;

      if (doc.status === 'OUTDATED')
        // edgecase fix, if document was added with date older than 1 year,
        // which causes backend to return error.
        await this.$loadingNotify(
          this.performNextAction(doc).then(() => this.$emit('close')),
          'handleSubmit',
          // no error possible here
          'Документ был добавлен, но ошибка',
          'Документ успешно добавлен, но не подтвержден, поскольку истек',
        );
      else
        await this.$loadingNotify(
          this.approve({
            id: doc.id,
            data: {
              type: this.data.type,
              name: this.data.name,
              issuedAt: this.data.issuedAt,
              issuedBy: this.data.issuedBy,
              includedInRiskGroup: this.data.includedInRiskGroup,
              diagnosis,
              dueDate,
            },
          })
            .then(_ => this.performNextAction(doc))
            .then(() => this.$emit('close')),
          'handleSubmit',
          'Документ был добавлен, но произошла ошибка подтверждения документа',
          'Документ успешно добавлен и подтвержден',
        );

      await this.$store.dispatch('EMPLOYEE/fetchMedrec', this.employee.id);
    },

    async performNextAction(doc) {
      // Perform action after approve document:
      // Show unnban or boundaries prompt
      if (doc.applicableFor === 'boundaries')
        await this.promptSetBoundaries(doc);
      else if (doc.applicableFor === 'unban') {
        const employeeBans = await this.fetchEmployeeBans({
          employeeId: this.employee.id,
          documentType: doc.type,
        });
        await this.promptUnban(employeeBans[0], doc);
      }
    },

    promptSetBoundaries(doc) {
      // Нельзя установить индивидуальные границы по истекшему документу
      if (!this.$can('SETBND') || doc.status === 'OUTDATED') return;

      this.$openModal.call(null, 'prompt', {
        yes: 'Да',
        no: 'Нет',
        title: 'Дополнительно',
        description: 'Установить индивидуальные границы?',
        onSubmit: () =>
          this.$openModal('documents/boundaries', {
            employee: this.employee,
            document: doc,
            onSubmit: (id, data) => {
              this.$store.dispatch('EMPLOYEE/setBoundaries', { id, data });
            },
          }),
      });
    },
    // need to pass doc here rather to take from component cuz 'this.data'
    // doesn't have doc.id field
    promptUnban(ban, doc) {
      if (!this.$can('UNBN_F') || ban == null) return;

      this.$openModal.call(null, 'prompt', {
        yes: 'Да',
        no: 'Нет',
        title: 'Дополнительно',
        description: 'Разблокировать работника?',
        onSubmit: () =>
          this.$openModal('employees/unban', {
            from: 'document',
            value: ban,
            document: doc, // ?
            employee: this.employee,
          }),
      });
    },
  },
};
</script>
