import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import EPL from './store/list';

export default {
  namespaced: true,
  store: { EPL },
  menu: [
    {
      title: 'ЭПЛ',
      name: 'epl',
      action: 'W_GL',
      priority: 2,
    },
  ],
  routes: [
    {
      path: 'epl',
      name: 'epl',
      component: () => import('./views/list.vue'),
    },
  ],
} as ModuleDefinition;
