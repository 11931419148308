<template>
  <AsyncSelect
    v-bind="computedProps"
    :item-text="getItemText"
    v-on="{ ...$listeners, input: handleInput }"
  >
    <template #item:description="{ item }">
      <v-chip
        x-small
        class="mr-1"
        :color="item.type === 'point' ? 'primary' : 'warning'"
      >
        {{ item.type === 'point' ? 'Точка выпуска' : 'Группы ПАК' }}
      </v-chip>
      <v-chip v-if="item.isGuest" color="primary" x-small> Гость </v-chip>
      <v-chip v-if="item.owner" x-small :class="item.isGuest ? 'ml-1' : ''">
        {{ item.owner.name }}
      </v-chip>
      <v-chip v-if="!item.isActive" x-small color="error" class="ml-1">
        Неактивна
      </v-chip>
    </template>
  </AsyncSelect>
</template>

<script>
import asyncSelectMixin from '@/components/controls/asyncSelectMixin';

// in one select list all items must have unique id
// but in backend response can be items with the same id (from different tables)
// for this reason I place the type belonging symbol in front of each id to create unique id

export default {
  extends: asyncSelectMixin,

  props: {
    type: {
      type: String, // org, med_org, org_group
      default: 'orgs',
    },
    id: {
      type: Number,
      default: null,
    },
    value: {
      type: Object,
      default: () => ({
        points: null,
        hostGroups: null,
      }),
    },
  },

  computed: {
    label() {
      return this.multiple
        ? 'Группы ПАК и Точки выпуска'
        : 'Группа ПАК или Точка выпуска';
    },
    query() {
      return {
        type: this.type,
        id: this.id || undefined,
      };
    },

    computedValue() {
      return [
        ...(this.value.points?.map(id => 'p' + id) || []),
        ...(this.value.hostGroups?.map(id => 'h' + id) || []),
      ];
    },
  },

  methods: {
    fetcher(params) {
      return this.$store
        .dispatch('STRUCTURES/fetchHostGroupsAndPointsPreviews', params)
        .then(res => {
          return {
            ...res,
            items: res.items.map(mapItemValue),
          };
        });
    },

    async fetcherBy(ids) {
      const { points, hostGroups } = separateIds(ids);

      return this.$store
        .dispatch('STRUCTURES/fetchHostGroupsAndPointsPreviewsBy', {
          pointIds: points,
          hostGroupIds: hostGroups,
        })
        .then(res => {
          return res.map(mapItemValue);
        });
    },

    getItemText(item) {
      if (item.type === 'point')
        return item.name || item.address || 'Мобильная';
      return item.name;
    },

    handleInput(event) {
      this.$emit('input', separateIds(event));
    },
  },
};

function mapItemValue(item) {
  const id = item.type === 'point' ? 'p' + item.id : 'h' + item.id;
  return { ...item, id };
}

function separateIds(ids) {
  return {
    points: ids
      .filter(item => item[0] === 'p')
      .map(item => Number(item.substring(1))),
    hostGroups: ids
      .filter(item => item[0] === 'h')
      .map(item => Number(item.substring(1))),
  };
}
</script>
