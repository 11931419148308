// NOTE: maybe in the future.. but for now i think current 'fetchfactory'
// factory is a bloat
// import { fetchFactory } from '@/utils/vuex/fetchFactory';
// export default fetchFactory({
//   field: 'parentOrgs',
//   initialValue: [],
//   action: () => structApi.getParentOrgs(getters.id)
// });

import structApi from '@/api/services/structures';

export default {
  state: {
    parentOrgs: null,
  },

  getters: {
    parentOrgs: state => state.parentOrgs || [],
  },

  mutations: {
    parentOrgs: (state, value) => (state.parentOrgs = value),
  },

  actions: {
    async fetchParentOrgs({ getters, commit }) {
      const result = await structApi.getParentOrgs(getters.id);
      commit('parentOrgs', result);
      return result;
    },
  },
};
