<template>
  <v-container class="item-page-layout px-13">
    <v-row>
      <v-col class="pb-0">
        <v-breadcrumbs :items="breadcrumbs" class="px-0 py-2">
          <template #divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <Spinner v-if="loading" />
    <v-row v-else>
      <v-col cols="12" class="item-page-layout__header d-flex flex-column mb-2">
        <slot name="header" />
      </v-col>
      <v-col
        cols="12"
        :sm="$scopedSlots.aside && 8"
        class="item-page-layout__default d-flex flex-column"
        style="gap: 24px"
      >
        <slot />
      </v-col>
      <v-col v-if="$scopedSlots.aside" cols="12" sm="4">
        <!--Обертка нужна, чтобы aside не выходил за пределы столбца-->
        <div
          ref="aside"
          class="item-page-layout__aside"
          :style="computedAsideStyles"
        >
          <slot name="aside" />
        </div>
      </v-col>
      <v-col cols="12" class="item-page-layout__inner-list">
        <slot name="innerList" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Spinner from '@/components/Spinner';

export default {
  components: { Spinner },
  props: {
    loading: Boolean,
    backRoute: { type: [String, Array], default: null },
    title: { type: String, default: null },
  },

  computed: {
    breadcrumbs() {
      if (!this.backRoute) return;

      const backRoutes = Array.isArray(this.backRoute)
        ? this.backRoute
        : [this.backRoute];

      const routes = backRoutes.map(item => {
        const routeName = typeof item === 'string' ? item : item.name;
        const route = this.$router
          .getRoutes()
          .find(route => route.name === routeName);

        return {
          text: route.meta?.title || item?.title || route.name,
          exactPath: true,
          to: {
            name: route.name,
            params: { restoreScrollPosition: true },
          },
        };
      });

      const current = this.$route;

      return [
        ...routes,
        {
          text: this.title || current.meta?.name || current.name,
          disabled: true,
        },
      ];
    },

    appBarHeight() {
      const appNavbar = document.getElementById('app-navbar');
      return appNavbar?.clientHeight || 0;
    },

    computedAsideStyles() {
      // 12px - отступ от верха экрана при скроле
      return {
        top: this.appBarHeight + 12 + 'px',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.item-page-layout {
  max-width: 1200px;

  &__inner-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__aside {
    align-self: flex-start;
    position: sticky;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &:empty {
      display: none !important;
    }
  }

  &__header:empty {
    display: none !important;
  }

  &__default:empty {
    display: none !important;
  }
}
</style>
