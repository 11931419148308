import { ModuleDefinition } from '@/models/app/ModuleDefinition';

export default {
  namespaced: true,
  modals: [
    {
      name: 'employee',
      component: () => import('./EmployeePreviewer.modal.vue'),
    },
  ],
} as ModuleDefinition;
