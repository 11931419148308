/**
 * Ключевые понятия:
 * - Шаг (Step): шаг прохождения осмотра на терминале. Содержит тип шага,
 *   время начала, завершения, результат прохождения и детали об
 *   измерительном приборе. Получены с сервера.
 *
 * - Замечание (Remarks): замечания к шагам осмотра. Получены с сервера.
 *   Например, информация о том, что осмотр был прерван (не завершен).
 *   Или обязательное ли было поле для прохождения осмотра.
 *
 * - Метка (Label): отметки с информацией об отклонениях прохождения шага.
 *   Получены с сервера.
 *   Например, превышение допустимого значения вверх или вниз.
 *
 * - Сценарий (Scenario(s)): синтетический объект собирательный из данных
 *   о шаге, замечаниях, метках
 */

import {
  BADGE_COLORS,
  STEP_TYPES,
  REMARK_KEYS,
  REACTION_TYPES,
  STEPS_CANCELED_TITLE,
} from './constants';

/** Базовая заготовка для генерирования сценария на основе типа шага */
const SCENARIOS_BASE = [
  /*
   * TODO: Оставь те же комменты когда будешь переводить на TS.
   * Дефолтная заготовка для новых сценариев.
   *
   * {
   *   // Тип шага, нужен на этапах обработок сценария для применения бизнес
   *   // логики на фронте (например, логика отображения алкоголя).
   *   // Так же на один тип может быть два шага, например - тонометрия.
   *   stepType: STEP_TYPES.TEMPERATURE,
   *   // Когда за один шаг собираются данные по давлению и пульсу и их
   *   // надо отобразить разными карточками.
   *
   *   // Ключ замечания. Как правило совпадают с ключами labels.
   *   // Используется для сопоставления замечаний и отметок (labels).
   *   remark: 'temperature',
   *
   *   // Подпись сценария.
   *   // Так сложилось, что она совпадает c ключом замечаний (remarks).
   *   title: 'Температура',
   *
   *   // Возвращает название полученное в результате обработки данных.
   *   // Например в реакциях требуется вывести название по данным из результата
   *   // шага. При отображении названия шага приоритет у subtitle.
   *   // Если потребуется сделать перенос юзай '\n'.
   *   subtitle: (step) => {},
   *
   *   // Путь к SVG файлу для отображения иконки, так же может принимать текст
   *   svg: () => import('@/components/icons/thermometer.vue'),
   *
   *   // Описание, выводится в tooltip при наведении курсора на карточку.
   *   description: (step) => {},
   *
   *   // Цвет, в который будет окрашиваться карточка для привлечения внимания
   *   // к проблеме
   *   warnColor: BADGE_COLORS.medical,
   *
   *   // Результат прохождения шага. Является генерируемым полем в зависимости
   *   // от логики отображения и принимает данные по шагу.
   *   result: (step) => {},
   * }
   */
  {
    stepType: STEP_TYPES.TEMPERATURE,
    remark: 'temperature',
    title: 'Температура',
    svg: () => import('@/components/icons/thermometer.vue'),
    warnColor: BADGE_COLORS.medical,
    result: ({ result }) => result.value,
  },
  {
    stepType: STEP_TYPES.TONOMETRY,
    remark: 'pressure',
    title: 'Артериальное давление',
    svg: () => import('@/components/icons/pressure.vue'),
    warnColor: BADGE_COLORS.medical,
    result: ({ result }) =>
      `${result.value.pressure.systolic} / ${result.value.pressure.diastolic}`,
  },
  {
    stepType: STEP_TYPES.TONOMETRY,
    remark: 'pulse',
    title: 'Пульс',
    svg: () => import('@/components/icons/heart-pulse.vue'),
    warnColor: BADGE_COLORS.medical,
    result: ({ result }) => result.value.pulse,
  },
  {
    stepType: STEP_TYPES.ALCOHOL_SHORT,
    remark: REMARK_KEYS.ALCOHOL,
    title: 'Алкоголь',
    svg: () => import('@/components/icons/alcohol.vue'),
    warnColor: BADGE_COLORS.alcohol,
    result: ({ result }) => (result.value ? 'Обнаружен' : 'Не обнаружен'),
  },
  {
    stepType: STEP_TYPES.ALCOHOL_LONG,
    remark: REMARK_KEYS.ALCOHOL,
    title: 'Алкоголь',
    svg: () => import('@/components/icons/alcohol.vue'),
    warnColor: BADGE_COLORS.alcohol,
    result: ({ result }) => result.value,
  },
  {
    stepType: STEP_TYPES.SATURATION,
    remark: 'saturation',
    title: 'Сатурация',
    svg: () => import('@/components/icons/lungs.vue'),
    warnColor: BADGE_COLORS.medical,
    result: ({ result }) => result.value,
  },
  {
    stepType: STEP_TYPES.REACTIONS,
    remark: 'reactions',
    title: 'Реакции',
    svg: () => import('@/components/icons/brain.vue'),
    subtitle: ({ result }) => {
      const type = result?.value?.type;
      return REACTION_TYPES[type] || 'Реакции';
    },
    warnColor: BADGE_COLORS.medical,
    result: ({ result }) => {
      const type = result.value.type === 'dots' ? 'точек' : 'секунд';
      return `${result.value.value} ${type}`;
    },
  },
  {
    stepType: STEP_TYPES.COMPLAINTS,
    remark: 'complaints',
    title: 'Жалобы',
    svg: () => import('@/components/icons/sneeze.vue'),
    warnColor: BADGE_COLORS.medical,
    result: ({ result }) =>
      result.value.length === 0 ? 'Жалоб нет' : result.value[0],
  },
  {
    stepType: STEP_TYPES.SLEEP,
    remark: 'sleep',
    title: 'Сон',
    svg: () => import('@/components/icons/bed.vue'),
    warnColor: BADGE_COLORS.medical,
    result: ({ result }) => (result.value ? 'Выспался' : 'Не выспался'),
  },
  {
    stepType: STEP_TYPES.SIGNATURE,
    remark: 'signature',
    title: 'Подписано работником',
    svg: () => import('@/components/icons/signature.vue'),
    warnColor: BADGE_COLORS.medical,
    result: () => 'Подписан',
  },

  // Если добавлен новый тип осмотра на backend и еще не обработан фронтом.
  // Ну или допущена опечатка в названии типа (и такое тоже было...).
  {
    stepType: undefined,
    icon: 'None',
    title: 'None',
    result: ({ result }) => result.value,
  },
];

// FUNCTIONS

/**
 * Дополняет сценарий ошибками
 * @param {object} error Ошибка прохождения шага
 */
const addErrorToScenarios = error => {
  const result = {
    errorData: error,
    warnColor: null,
    result: null,
  };

  switch (error.type) {
    case 'malfunction':
      return {
        ...result,
        warnColor: BADGE_COLORS.malfunction,
        result: 'Сбой',
      };
    case 'cancel':
      return {
        ...result,
        warnColor: BADGE_COLORS.break,
        result: 'Отменен',
      };
    case 'timeout':
      return {
        ...result,
        warnColor: BADGE_COLORS.break,
        result: 'Вышло время',
      };
    default: {
      return {
        ...result,
        warnColor: BADGE_COLORS.break,
        result: '–',
      };
    }
  }
};

/**
 * Формирует объект со склеенными данными из сущностей связанных с этим типом
 * шага прохождения осмотра. Это необходимо, так как вместе с шагом возвращаются
 * замечания и отклонения в отдельных полях связанных с шагом осмотра только
 * ключом. Все данные по шагу требуется обработать и выдать результат в виде
 * единого объекта.
 *
 * @param {*} params
 * @param {*} params.step Шаг с сервера
 * @param {*} params.scenarioBase Заготовка сценария для этого типа шага
 * @param {boolean} params.haveRemarks Есть причины для недопуска по осмотру
 * @param {string[]} params.labels Отметки по этому типу шага
 * @param {number} params.index Индекс по шагу прохождения
 */
function generateScenario({
  step = {},
  scenarioBase = {},
  haveRemarks = false,
  labels = [],
  index = null,
}) {
  // "skip" возвращается с сервера и говорит о том, что водитель пропустил шаг
  // осмотра и перешел к следующему. Выводить его не надо.
  if (step.result?.skip) return null;

  // Subtitle может генерироваться по функции из сценария, для этого передаем
  // данные шага в функцию, либо выводим статическое значение.
  // В случае с null, subtitle не будет отображаться в карточке.
  const subtitle =
    typeof scenarioBase.subtitle === 'function'
      ? scenarioBase.subtitle(step)
      : scenarioBase.subtitle || null;

  const resultData = {
    ...scenarioBase,
    subtitle,

    // Расширяем сценарий до полноценного объекта
    step,
    labels,
    index,
  };

  if (step.result?.error) {
    // Добавляем к сценарию ошибки
    const errorData = addErrorToScenarios(step.result?.error);
    return {
      ...resultData,
      warn: true,
      ...errorData,
    };
  } else if (step.result?.value === undefined) {
    // Обработка сломанных и пропущенных этапов
    return {
      ...resultData,
      warn: true,
      warnColor: BADGE_COLORS.break, // Переопределяем цвет на поломку
      result: '–',
    };
  } else {
    const result = scenarioBase.result ? scenarioBase.result(step) : null;
    // Если нет результата обработки, вернуть null, но такого не должно быть,
    // это равносильно скрытию шага из списка карточек.
    if (result === null) return null;

    return {
      ...resultData,
      warn: Boolean(labels.length) || haveRemarks,
      result,
    };
  }
}

/**
 * Преобразование набора данных в единый сценарий
 * @param {*} param0
 * @param {object[]} param0.steps Шаги прохождения на терминале
 * @param {object} param0.labels Отметки к шагам
 * @param {string[]} param0.remarks Замечания к шагам
 */
function transformStepsToScenarios({ steps = [], labels = {}, remarks = [] }) {
  // Находим элемент для заполнения когда ни один тип не подошел
  const baseUndefined = SCENARIOS_BASE.find(el => el.stepType === undefined);

  const scenarios = steps
    .reduce((agg, step, index) => {
      // Собираем сценарии из базового набора по типу шага
      const bases =
        SCENARIOS_BASE.filter(el => el.stepType === step.type) || baseUndefined;

      // Генерируем из заготовок полноценные сценарии
      const scenarios = bases.map(scenarioBase =>
        generateScenario({
          scenarioBase,
          step,
          labels: labels[scenarioBase.remark],
          haveRemarks: remarks.includes(scenarioBase.remark),
          index,
        }),
      );
      agg.push(...scenarios);
      return agg;
    }, [])
    // Удаляем пустые (пропущенные) элементы массива содержащие null
    .filter(_ => _);

  return scenarios;
}

/**
 * Группировка сценариев по типу замечаний
 * @param {object[]} scenarios Список сценариев
 */
function groupByRemark(scenarios = []) {
  const groupedByRemarks = scenarios.reduce((agg, el) => {
    agg[el.remark] = agg[el.remark] || [];
    agg[el.remark].push(el);
    return agg;
  }, {});

  return groupedByRemarks;
}

/**
 * Применение логики к сценариям сгруппированным по типам замечаний
 * @param {object} scenariosGroupedByRemarks Сгруппированные сценарии
 */
function applyLogic(scenariosGroupedByRemarks = {}) {
  /**
   * Логика вывода карточки по алкоголю
   * @param {object[]} scenarios Набор сценариев по алкоголю
   */
  const logicAlcohol = scenarios => {
    let short = null;
    let long = null;

    // NOTE: Может быть не более 2 записей на алкоголь.
    scenarios.forEach(scenario => {
      if (scenario.stepType === STEP_TYPES.ALCOHOL_SHORT) short = scenario;
      else long = scenario;
    });

    // ВАЖНО: Короткий продув всегда проводится до длинного.

    // - Кейс: Если на коротком обнаружен алкоголь, а на длинном сбой, то
    // выводим сообщение о сбое с изменением цвета на предупреждающий
    // об алкоголе и пояснением
    if (short?.step?.result?.value && long?.step?.result?.error) {
      const shortTitle =
        STEPS_CANCELED_TITLE[STEP_TYPES.ALCOHOL_SHORT].nominative;
      const longTitle =
        STEPS_CANCELED_TITLE[STEP_TYPES.ALCOHOL_LONG].nominative;
      return [
        {
          ...long,
          warnColor: BADGE_COLORS.alcohol,
          description: `– ${
            shortTitle[0].toUpperCase() + shortTitle.slice(1)
          }: обнаружен алкоголь\n– ${
            longTitle[0].toUpperCase() + longTitle.slice(1)
          }: ${long.result.toLowerCase()}`,
        },
      ];
    }

    // - Кейс: Если есть длинный продув, он не прерван и у него есть результат,
    // - то всегда выводим его.
    if (long && long.step.result && long.step.start) return [long];

    if (!short) return [long]; // если короткого нет, тоже выводим длинный

    return [short];
  };

  // Логика должна выполняться в определенном порядке, поэтому задаём массив
  const logics = [
    {
      remark: REMARK_KEYS.ALCOHOL,
      action: logicAlcohol,
    },
  ];

  for (const { remark, action } of logics) {
    if (scenariosGroupedByRemarks[remark])
      scenariosGroupedByRemarks[remark] =
        action && action(scenariosGroupedByRemarks[remark]);
  }

  return scenariosGroupedByRemarks;
}

/**
 * Генерирование сценариев
 *
 * @param {*} param0
 * @param {object[]} param0.steps Шаги прохождения на терминале
 * @param {object} param0.labels Отметки к шагам
 * @param {string[]} param0.remarks Замечания к шагам
 */
export function generateScenarios({ steps = [], labels = [], remarks = [] }) {
  const scenarios = transformStepsToScenarios({ steps, labels, remarks });
  const groups = groupByRemark(scenarios);
  const groupsWithAppliedLogic = applyLogic(groups);

  // Разгруппировываем сценарии из объекта в массив
  const scenariosInList = [];
  for (const key in groupsWithAppliedLogic) {
    scenariosInList.push(...groupsWithAppliedLogic[key]);
  }

  // Упорядочиваем в том же порядке как было при прохождении на терминале
  scenariosInList.sort((a, b) => a.index - b.index);

  return scenariosInList;
}
