import historyApi from '@/api/services/history';

export default {
  state: {
    measurementsHistory: [],
  },
  getters: {
    measurementsHistory: state => state.measurementsHistory,
  },
  mutations: {
    measurementsHistory: (state, value) => {
      state.measurementsHistory = value;
    },
  },
  actions: {
    async fetchMeasurementsHistory({ commit }, id) {
      const response = await historyApi.getEmployeeHistory(id);
      commit('measurementsHistory', response || {});
    },
  },
};
