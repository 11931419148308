<template>
  <PageLayout
    class="px-13"
    back-tooltip="Вернуться к списку подписок"
    back-route="notifications:verification"
    :title="title"
    :loading="isLoading"
  >
    <v-card class="mt-4 pa-4 d-flex mx-3">
      <v-card-text>
        <v-row>
          <v-text-field
            v-model="data.name"
            outlined
            dense
            label="Название"
            :readonly="!hasAction"
            :error-messages="getValidationErrors('data.name')"
            @blur="validateField('data.name')"
          />
          <div class="d-flex ml-2" style="margin-top: 2px">
            <ListActionBtn
              label="Сохранить"
              icon="fa-save"
              :icon-size="14"
              color="success"
              class="mr-1"
              :loading="$wait('submit')"
              :disabled="!hasAction || disableActions"
              @click="submit"
            />
            <ListActionBtn
              label="Удалить"
              icon="fa-trash-alt"
              :icon-size="14"
              color="error"
              :disabled="!$can('D_HV') || disableActions || isCreate"
              @click="handleRemove"
            />
          </div>
        </v-row>
        <v-row>
          <v-col cols="6">
            <OrganizationGroupSelect
              v-model="data.filters"
              :disabled="!hasAction || data.allOrgs"
              multiple
              :error-messages="getValidationErrors('data.filters')"
              @blur="validateField('data.filters')"
            />
          </v-col>
          <v-col cols="6">
            <Recipients
              v-model="data.recipients"
              multiple
              :disabled="!hasAction"
              :error-messages="getValidationErrors('data.recipients')"
              @blur="validateField('data.recipients')"
            />
          </v-col>
          <v-col cols="6">
            <Checkbox
              v-model="data.allOrgs"
              :disabled="!hasAction"
              label="По всем организациям"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </PageLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required, requiredIf } from 'vuelidate/lib/validators';
import validationMixin from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';
import queryTabSave from '@/utils/mixins/queryTabSave';

import PageLayout from '@/components/layouts/PageLayout';
import ListActionBtn from '@/components/controls/buttons/ListActionBtn';
import asyncSelectMixin from '@/components/controls/asyncSelectMixin';
import Checkbox from '@/modules/reports/components/fields/Checkbox';
import OrganizationGroupSelect from '../components/OrganizationGroupSelect';
import Recipients from '../components/Recipients';

export default {
  components: {
    Recipients,
    OrganizationGroupSelect,
    Checkbox,
    PageLayout,
    ListActionBtn,
  },
  extends: asyncSelectMixin,
  mixins: [validationMixin, waitable, routeGuardMixin, queryTabSave()],

  data: () => ({
    data: {
      name: '',
      allOrgs: false,
      filters: [],
      recipients: [],
    },
  }),

  validations() {
    return {
      data: {
        name: { required },
        recipients: { required },
        filters: {
          required: requiredIf(() => !this.data.allOrgs),
        },
      },
    };
  },

  computed: {
    ...mapGetters('NOTIFICATIONS/VERIFICATION_CARD', ['verification']),
    ...mapGetters('AUTH_PREVIEWS', ['cachedAccs']),
    ...mapGetters('AUTH', ['accountId']),

    title() {
      if (this.isCreate) return 'Создание подписки';
      return `Подписка ${
        this.singleItem ? `"${this.singleItem.name}"` : '...'
      }`;
    },

    disableActions() {
      return this.$wait('createSingleItem') || this.$wait('changeSingleItem');
    },

    isCreate() {
      return this.$route.name.includes('create');
    },

    isLoading() {
      if (this.isCreate) return false;
      return (
        this.$wait('fetchingAccountPreview') ||
        this.singleItem.recipients.length > Object.keys(this.cachedAccs).length
      );
    },

    hasAction() {
      if (this.isCreate) return this.$can('P_HV');
      else return this.$can('PA_HV');
    },
  },
  watch: {
    'data.allOrgs'(val) {
      if (val) {
        this.data.filters = [];
      }
    },
  },

  async created() {
    this.clearCachedAccs();

    // HACK: fetch current user account preview for 'add me as recipient' button
    // in 'components/Recipients' component to work properly, since we need
    // account preview to be loaded in when channel type value will be altered.
    // Otherwise channels select will but out visually and won't update.
    // It IS a 'hack' since we already have current user object in auth store,
    // which is 'currentAccount' and we could merge it to 'cachedAccs' in
    // auth-previews, but i'd rather not do that ugly stuff.
    await this.$loadingNotify(
      this.fetchAccountPreviewsBy([this.accountId]),
      'fetchingSelfPreview',
      'Произошла ошибка загрузки превью вашего аккаунта',
    );

    if (!this.isCreate) {
      this.data = { ...this.singleItem };
      if (!this.data.filters.length) {
        this.$set(this.data, 'allOrgs', true);
      }

      // fetch recipients info to be able to display type selects properly
      // in Recipients.vue
      await this.$loadingNotify(
        this.fetchAccountPreviewsBy(
          this.singleItem.recipients.map(recipient => recipient.accountId),
        ),
        'fetchingAccountPreview',
        'Произошла ошибка загрузки превью аккаунтов',
      );

      // delete empty row of groups if there r alredy some (just for convenience)
      if (!this.data.recipients?.length)
        // this.data.recipients = [{ accountId: null, channels: null }];
        this.data.recipients.splice(0, 1);
    }
  },

  methods: {
    ...mapActions('NOTIFICATIONS/VERIFICATION_CARD', ['deleteSingleItem']),
    ...mapActions('AUTH_PREVIEWS', [
      'clearCachedAccs',
      'fetchAccountPreviewsBy',
    ]),
    ...mapActions('AUTH_PREVIEWS', {
      fetcherAccountPreviews: 'fetchAccountPreviews',
    }),

    async submit() {
      if (!this.validate()) return;
      const data = { ...this.data };

      const actionName = this.isCreate
        ? 'NOTIFICATIONS/VERIFICATION_CARD/createSingleItem'
        : 'NOTIFICATIONS/VERIFICATION_CARD/updateSingleItem';
      await this.$loadingNotify(
        this.$store.dispatch(actionName, data).then(res => {
          this.isCreate &&
            this.$router.push({
              name: 'notifications:verification:item',
              params: {
                id: res.id,
              },
            });
        }),
        'submit',
        `Произошла ошибка ${this.isCreate ? 'создания' : 'изменения'} подписки`,
        `Подписка успешно ${this.isCreate ? 'добавлена' : 'изменена'}`,
      ).catch(err => this.catchServerErrors(err, 'data'));
    },

    handleRemove() {
      this.$openModal('prompt', {
        yes: 'Подтвердить',
        no: 'Отменить',
        title: 'Удалить подписку?',
        description: 'Вы точно хотите удалить подписку?',
        messages: {
          successfulAction: 'Подписка успешно удалена',
          errorAction: 'Ошибка при удалении подписки',
        },
        onSubmit: () =>
          this.deleteSingleItem(this.singleItem.id).then(_ =>
            this.$router.push({ name: 'notifications:verification' }),
          ),
      });
    },
  },
};
</script>
