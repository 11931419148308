<template>
  <EmployeeGroupList
    search-filter
    :available-actions="{
      showAction: $can('EMG_RD'),
      createAction: $can('EMPG_C'),
    }"
    @item:create="openCreateEmployeeGroup"
  >
    <template #table.item.name="{ item }">
      <b>{{ item.name }}</b>
    </template>
  </EmployeeGroupList>
</template>

<script>
import {
  messages as employeeGroupsMessages,
  entityEmbedded as employeeGroupsEntity,
  filters as employeeGroupsFilters,
} from '@/modules/structures/employees-groups/entity';
import ListFactory from '@/components/crud/ListFactory';

const EmployeeGroupList = ListFactory({
  storeModule: 'STRUCTURES/EMBEDDED_EMPLOYEE_GROUPS_LIST',
  messages: employeeGroupsMessages,
  entity: employeeGroupsEntity.filter(val => val.key !== 'organization'),
  filters: employeeGroupsFilters,
  viewRoute: 'structures:employee_groups:item',
  embedded: true,
});

export default {
  components: { EmployeeGroupList },
  props: {
    orgId: { type: Number, required: true },
  },
  methods: {
    openCreateEmployeeGroup() {
      this.$openModal('structures/employeeGroupForm', {
        predefinedValues: { orgId: this.orgId },
        storeForCreate: 'STRUCTURES/EMBEDDED_EMPLOYEE_GROUPS_LIST',
      });
    },
  },
};
</script>
