<template>
  <List
    :available-actions="{
      showAction: $can('SJ_RD'),
      createAction: $can('SJ_C'),
    }"
    @item:create="
      $router.push({
        name: 'scheduler:jobs:create',
      })
    "
  >
    <template #filters="query">
      <v-col cols="12" sm="4" md="2">
        <v-select
          v-model="query.active"
          label="Статус задачи"
          :items="statusItems"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :menu-props="{ offsetY: true }"
        />
      </v-col>
    </template>

    <template #table.item.activatedAt="{ item }">
      <Indicator :active="!!item.activatedAt" />
    </template>
  </List>
</template>

<script>
import waitable from '@/utils/mixins/waitable';
import Indicator from '@/components/Indicator';
import { STATUS } from '@/utils/constants.js';

import ListFactory from '@/components/crud/ListFactory';
import { messages, entity, filters } from '../entity';

const List = ListFactory({
  storeModule: 'SCHEDULER/JOB_LIST',
  messages,
  entity,
  filters,
  viewRoute: 'scheduler:jobs:item',
});

export default {
  components: { List, Indicator },

  extends: waitable,
  computed: {
    statusItems: () => STATUS,
  },
};
</script>
