var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{staticClass:"definition-list",attrs:{"dense":"","dark":_vm.dark}},[_c('tbody',_vm._l((_vm.value),function(ref){
var label = ref.label;
var val = ref.value;
var component = ref.component;
var props = ref.props;
var labelTooltip = ref.labelTooltip;
return _c('tr',{key:label},[(val || _vm.showEmptyValues || component)?[_c('td',{class:{ 'grey--text': true, 'pa-4': !_vm.dense },style:([{ width: _vm.widthColumns[0] }])},[_vm._v(" "+_vm._s(label)+" "),(labelTooltip)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("far fa-question-circle")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(labelTooltip))])]):_vm._e()],1),_c('td',{class:{ 'pa-4': !_vm.dense, 'py-1': _vm.dense },style:([{ width: _vm.widthColumns[1] }])},[(component)?_c(component,_vm._b({tag:"component"},'component',Object.assign({}, props, {value: val}),false)):[_vm._v(" "+_vm._s(val)+" ")]],2)]:_vm._e()],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }