<template>
  <v-tooltip right content-class="tooltip--show-white-space">
    <template #activator="{ on }">
      <div v-on="on">
        <Round :type="status.color" />
      </div>
    </template>
    <span>{{ status.description }}</span>
  </v-tooltip>
</template>

<script>
import { EXECUTIONS_STATUSES } from '../../constants';
import Round from '@/components/Round';

export default {
  components: { Round },
  props: {
    value: {
      type: String,
      required: true,
    },
  },

  computed: {
    status() {
      return EXECUTIONS_STATUSES[this.value];
    },
  },
};
</script>
