export const messages = { resourceName: 'групп медработников' };

export const entity = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: 'Название' },
  { key: 'description', label: 'Описание' },
  { key: 'itemsCount', label: 'Количество медработников' },
  {
    label: 'Организация',
    key: 'organization',
    headerProps: { width: '200px' },
    convert: (item, getters) =>
      getters['MEDBLOCK/MEDIC_GROUPS/medicalOrgs'].find(
        el => el.id === item.organization.id,
      )?.name || '–',
  },
];

export const filters = {
  medOrgIds: {
    type: Array,
    default: null,
  },
};
