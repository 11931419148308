/*
 * Route Guard Protection
 * 1. Отображает спиннер. Требуется для моментального отображения информации
 *    о переходе на страницу.
 * 2. Дергает ручку с получением данных по карточке.
 * 3. Если произошла ошибка загрузки данных, отображает пользовательскую ошибку.
 *
 * – Применяется в роутерах карточек:
 * import RouterGuard from '@/components/RouterGuard';
 * [{
 * ...
 *   component: RouteGuard({
 *     storeModule: 'INSPECTION',
 *     component: () => import('@/modules/inspections/views/view.vue'),
 *   }),
 * ...
 * }],
 *
 * - В компоненте страницы требуется использовать mixin:
 * {...
 *   mixins: [routerGuardMixin],
 * ...}
 */

import Vue from 'vue';
import { mapGetters } from 'vuex';
import _isNumber from 'lodash/isNumber';
import _isNaN from 'lodash/isNaN';
import { convert } from '@/utils/entities';
import Spinner from '@/components/Spinner.vue';
import ErrorPage from '@/views/ErrorPage.vue';

/**
 * Route Guard Protection
 * @param {Object} param0
 * @param {string} param0.storeModule Название стора карточки
 * @param {boolean=} param0.needForceUpdate
 * @param {any} param0.component Объект компонента
 * @param {string=} param0.primaryKeyName Название ключа, из которого взять значение для запроса на сервер
 * @param {'number' | 'string'=} param0.primaryKeyType Тип ключа. Если задать числовой, то приведет к числу и в случае ошибки отобразит её
 * @param {boolean=} param0.skipFetch Пропустить запрос данных
 * @param {object=} param0.entity Объект приведения для конвертирования (из модулей)
 * @param {boolean=} param0.useCache Использовать кэш из предыдущего запроса
 */
export default ({
  storeModule, // required
  component, // required
  needForceUpdate = false,
  primaryKeyName = 'id',
  primaryKeyType = 'number',
  skipFetch = false,
  entity,
  useCache = true,
}) => {
  return Vue.extend({
    // NOTE: Вхождение "Item" в названии обязательно для обновления страницы
    // все из-за "keep-alive"
    name: 'RouteGuardItem',

    data: () => ({
      isLoading: false,
      error: null,
    }),

    computed: {
      ...mapGetters(storeModule, ['singleItem', 'singleIsLoading']),
    },

    watch: {
      $route: {
        handler(val, old) {
          const values = {
            val:
              primaryKeyType === 'number'
                ? Number(val.params?.id || val.params?.key)
                : val.params?.id || val.params?.key,
            old:
              primaryKeyType === 'number'
                ? Number(old.params?.id || old.params?.key)
                : old.params?.id || old.params?.key,
          };

          if (values.val && values.val !== values.old) this.init();
        },
      },
    },

    created() {
      this.init();
    },

    methods: {
      async init() {
        try {
          this.isLoading = true;
          let primaryKeyValue = this.$route.params[primaryKeyName];

          if (skipFetch) {
            this.isLoading = false;
            return;
          }
          if (primaryKeyType === 'number') {
            primaryKeyValue = Number(primaryKeyValue);
            if (!_isNumber(primaryKeyValue) || _isNaN(primaryKeyValue)) {
              throw Error('В ключе обнаружена ошибка – ожидалось число');
            }
          }
          if (
            this.$store.getters[storeModule + '/singleItem'][primaryKeyName] !==
              primaryKeyValue ||
            needForceUpdate
          )
            await this.$store.dispatch(
              storeModule + '/fetchSingle',
              primaryKeyValue,
            );
          else if (!useCache)
            await this.$store.dispatch(
              storeModule + '/singleItemUpdate',
              primaryKeyValue,
            );
        } catch (err) {
          this.error = err;
        } finally {
          this.isLoading = false;
        }
      },
    },

    render(h) {
      if (this.error) return h(ErrorPage, { props: { error: this.error } });

      // По дефолту singleItem пустой объект.
      // Требуется подождать пока он заполнится данными для отрисовки.
      // Если не требуется загрузить данные, то пропускаем отображение
      const itemFilled = Object.keys(this.singleItem).length;
      if (
        !skipFetch &&
        (this.isLoading || (!itemFilled && this.singleIsLoading))
      )
        return h(Spinner);

      const item = entity ? convert(entity, this.singleItem) : this.singleItem;
      return h(component, { props: { item, singleItem: this.singleItem } });
    },
  });
};
