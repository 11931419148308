import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import MEDIC_GROUPS from './store/list';
import MEDIC_GROUPS_ITEM from './store/item';
import RouteGuard from '@/components/RouteGuard';

export default {
  namespaced: true,
  store: {
    MEDIC_GROUPS,
    MEDIC_GROUPS_ITEM,
  },

  modals: [
    {
      name: 'medicGroupForm',
      component: () =>
        import('@/modules/medblock/medics-groups/modals/Form.vue'),
    },
  ],

  routes: [
    {
      path: 'medic_groups',
      name: 'medic_groups',
      component: () => import('./view/list.vue'),
      meta: {
        title: 'Группы медработников',
        action: 'MEDG_R',
      },
    },
    {
      path: 'medic_groups/:id',
      name: 'medic_groups:item',
      component: RouteGuard({
        storeModule: 'MEDBLOCK/MEDIC_GROUPS_ITEM',
        component: () => import('./view/item.vue'),
        useCache: false,
      }),
      meta: {
        title: 'Страница группы медработников',
        action: 'MEDG_R',
      },
    },
  ],
  menu: [
    {
      title: 'Группы медработников',
      action: 'MEDG_R',
      name: 'medic_groups',
      priority: 2,
    },
  ],
} as ModuleDefinition;
