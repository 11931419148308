<template>
  <v-dialog
    ref="dialog"
    :value="isActive"
    :max-width="maxWidth"
    :persistent="persistent || dirty"
    :fullscreen="size === 'full'"
    content-class="modal-dialog__content"
    @input="handleClose"
    @click:outside="handleClose($event, true)"
    @keydown="handleEscape($event)"
  >
    <div
      v-if="loading"
      class="spinner__wrapper d-flex flex-row justify-center align-center"
      style="background-color: #fff"
    >
      <Spinner size="20" :title="null" />
    </div>
    <v-card v-else class="modern-modal" :style="{ height }">
      <v-toolbar color="primary" class="px-2 modern-modal__header" dark flat>
        <v-toolbar-title class="text-h6 modern-modal__title">
          {{ title }}
        </v-toolbar-title>

        <v-spacer />

        <v-btn
          v-if="!hideCloseIcon"
          icon
          :disabled="closeDisabled"
          @click="handleClose"
        >
          <v-icon>{{ $vuetify.icons.values.close }}</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="modern-modal__container" :class="{ 'pa-6': !denseContent }">
        <slot />
      </div>

      <template v-if="!hideBottom">
        <v-divider />
        <v-card-actions class="px-6">
          <slot name="actions">
            <v-spacer />
            <!-- WARN! you should use append slot for your actions -->
            <!-- <slot name="actions:prepend" /> -->
            <v-btn elevation="0" :disabled="closeDisabled" @click="handleClose">
              {{ closeLabel }}
            </v-btn>
            <slot name="actions:append" />
          </slot>
        </v-card-actions>
      </template>
    </v-card>

    <v-snackbar v-model="confirmClose">
      Данные изменены, вы уверены, что хотите закрыть модальное окно?
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="closeModal">Да</v-btn>
        <v-btn text v-bind="attrs" @click="confirmClose = false">Нет</v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import isEqual from 'lodash/isEqual';
import Spinner from '@/components/Spinner';
const SIZES = {
  small: '300px',
  medium: '500px',
  large: '700px',
  xlarge: '1100px',
  viewport: '90vw',
  full: '100vw',
};

export default {
  components: { Spinner },
  props: {
    // object to watch for changes
    watchFor: { type: Object, default: () => ({}) },
    title: { type: String, default: null },
    size: {
      type: String,
      default: 'medium',
      validator: value => Object.keys(SIZES).includes(value),
    },
    height: { type: String, default: null },
    hideCloseIcon: Boolean,
    closeDisabled: Boolean,
    closeLabel: { type: String, default: 'Закрыть' },
    persistent: Boolean,
    denseContent: Boolean,
    loading: Boolean,
    hideBottom: Boolean,
  },

  data: () => ({
    isActive: false,
    dirty: false,
    currentData: null, // needed for 'crutch?' below in 'watch' block
    confirmClose: false,
  }),

  computed: {
    maxWidth() {
      return SIZES[this.size];
    },
  },

  watch: {
    // TODO: try immediate property
    watchFor: {
      deep: true,
      handler(val) {
        // REVIEW: val & oldVal are EQUAL, why?? Therefore i need to keep
        // old data in components own 'data'
        if (
          this.currentData &&
          !isEqual(val, this.currentData) &&
          !this.loading
        )
          this.dirty = true;
        // if data is being assigned to component first time - do not
        // set 'dirty' flag
        else this.currentData = { ...val };
        return false;
      },
    },
  },

  mounted() {
    this.isActive = true;
  },

  beforeDestroy() {
    this.isActive = false;
    if (this.$refs.dialog?.overlay) {
      this.$refs.dialog.overlay.$el.parentNode.removeChild(
        this.$refs.dialog.overlay.$el,
      );
      this.$refs.dialog.overlay.$destroy();
      this.$refs.dialog.overlay = null;
    }
  },

  methods: {
    handleClose() {
      if (this.dirty) this.confirmClose = true;
      else this.$parent.$emit('close');
    },
    handleEscape(e) {
      if (this.dirty && e.keyCode === 27) this.confirmClose = true;
    },
    closeModal() {
      this.$parent.$emit('close');
    },
    undirty() {
      this.dirty = false;
      this.currentData = { ...this.watchFor };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-dialog__content {
  display: flex;
}

.modern-modal {
  // Set display grid mode for whole modal container for turn on scrolling for content div
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr auto auto;
  border-radius: inherit;

  &__title {
    width: calc(100% - 36px);
  }

  &__container {
    height: 100%;
    overflow-x: auto;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 100%;
  }

  &__header {
    overflow: hidden;
  }

  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
