<template>
  <ItemPageLayout
    back-route="structures:organization_categories"
    :title="singleItem.name"
  >
    <Card title="Данные категории" dense>
      <DefinitionList :value="computedEntity" show-empty-values />
      <template #action>
        <v-btn
          v-if="$can('ORGC_U')"
          depressed
          small
          text
          color="primary"
          @click="editModal"
        >
          Редактировать
        </v-btn>
      </template>
    </Card>

    <template #aside>
      <ActionsMenu>
        <ActionsMenuDelete
          v-if="$can('ORGC_D')"
          :id="singleItem.id"
          store-module="STRUCTURES/ORGANIZATION_CATEGORIES"
          back-route="structures:organization_categories"
          title="Удалить категорию"
          description="Вы уверены, что хотите удалить категорию?"
          :subtitle="
            singleItem.orgsCount ? 'В категории есть организации' : null
          "
          :disabled="!!singleItem.orgsCount"
        />
      </ActionsMenu>
    </template>

    <template v-if="$can('ORG_R')" #innerList>
      <List
        search-filter
        :available-actions="{
          showAction: $can('ORG_RD'),
        }"
        @item:create="formModal"
      >
        <template #filters="query">
          <v-col cols="12" sm="4" md="3">
            <v-select
              v-model="query.isActive"
              label="Статус организации"
              :items="statusItems"
              item-value="value"
              item-text="name"
              hide-details
              outlined
              dense
              :menu-props="{ offsetY: true }"
            />
          </v-col>
        </template>

        <template #table.item.nameShortName="{ item }">
          <b>{{ item.name }}</b>
          <div v-if="item.shortName">{{ `Сокр.: ${item.shortName}` }}</div>
          <div v-if="item.holding">{{ `Холдинг: ${item.holding.name}` }}</div>
        </template>

        <template #table.item.inn_kpp="{ item }">
          ИНН:&nbsp;{{ item.inn }}
          <div v-if="item.kpp">КПП:&nbsp;{{ item.kpp }}</div>
        </template>

        <template #table.item.isActive="{ item }">
          <Indicator :active="item.isActive" />
        </template>

        <template #table.item.isMedical="{ item }">
          <Round
            :type="item.isMedical ? 'green lighten-2' : 'grey lighten-1'"
          />
        </template>
      </List>
    </template>
  </ItemPageLayout>
</template>

<script>
import { mapActions } from 'vuex';
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';
import { entity } from '../entity';
import LinkButton from '@/components/controls/buttons/LinkButton';

import ItemPageLayout from '@/components/layouts/ItemPageLayout';
import Card from '@/components/ui/Card';
import ActionsMenu from '@/components/ui/ActionsMenu';
import DefinitionList from '@/components/DefinitionList';
import ActionsMenuDelete from '@/components/ActionsMenuDelete';

// embedded list stuff
import { STATUS } from '@/utils/constants.js';
import ListFactory from '@/components/crud/ListFactory';
import Round from '@/components/Round.vue';
import Indicator from '@/components/Indicator.vue';
import {
  messages,
  entity as embeddedEntity,
  filters,
} from '@/modules/structures/organizations/entity';

const List = ListFactory({
  storeModule: 'STRUCTURES/ORGANIZATION_CATEGORIES_ITEM',
  messages,
  entity: embeddedEntity.filter(val => val.key !== 'category'),
  filters,
  viewRoute: 'structures:organizations:item',
  embedded: true,
});

export default {
  components: {
    ItemPageLayout,
    DefinitionList,
    Card,
    ActionsMenu,
    ActionsMenuDelete,
    List,
    Round,
    Indicator,
  },
  mixins: [routeGuardMixin],

  computed: {
    computedEntity() {
      if (!this.singleItem) return [];

      const table = entity
        .filter(el => !el.hideInCard)
        .map(({ key, label, convert }) => ({
          label,
          value: convert ? convert(this.singleItem) : this.singleItem[key],
        }));

      const belongsTo = this.singleItem?.belongsTo;
      belongsTo &&
        table.push({
          label: 'Организация',
          component: LinkButton,
          props: {
            text: belongsTo.name || `#${belongsTo.id}`,
            action: this.$can('ORG_RD'),
            to: {
              name: 'structures:organizations:item',
              params: { id: belongsTo.id },
            },
          },
        });

      return table;
    },
    statusItems: () => STATUS,
  },

  beforeMount() {
    // we need to reset filters on each 'item page' mount, cut those are being
    // saved in store
    this.resetListFilters();
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_CATEGORIES_ITEM', [
      'resetListFilters',
    ]),

    editModal() {
      this.$openModal('structures/orgCategoryForm', {
        value: this.singleItem,
      });
    },
    formModal() {
      this.$openModal('structures/organizationForm');
    },
  },
};
</script>
