import Vue from 'vue';
import bansApi from '@/api/services/bans';
import medrecApi from '@/api/services/medrec';

export default {
  state: {
    bans: [],
  },
  getters: {
    bans: state => state.bans,
  },
  mutations: {
    bans: (state, value) => (state.bans = value),
    unban: (state, value) => {
      const index = state.bans.findIndex(item => item.id === value.id);
      Vue.set(state.bans, index, value);
    },
  },
  actions: {
    async fetchEmployeeBans({ commit }, id) {
      const response = await bansApi.getEmployeeBans(id, {
        orderIsActive: true,
        limit: 1000,
        page: 1,
      });

      commit('bans', response?.items || []);
      return response?.items || [];
    },
    async unban({ state, commit }, { banId, ...payload }) {
      const response = await bansApi.unbanEmployee(banId, payload);
      commit('unban', response);

      // we need to update comments in comments block
      if (payload.comment && state.medRecord.comments) {
        const comments = await medrecApi.getEmployeeComments(
          response.employeeId,
        );
        commit('changeComments', comments);
      }

      return response;
    },
  },
};
