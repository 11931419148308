import medrecApi from '@/api/services/medrec';
import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

const state = {
  recipes: [],
};

const getters = {
  recipes: state => state.recipes,
};

const mutations = {
  setRecipes: (state, val) => (state.recipes = val),
};

const actions = {
  async fetchRecipes({ commit }) {
    const data = await medrecApi.getRecipesList();
    commit('setRecipes', data);
  },

  async createSingleItem({ dispatch }, payload) {
    const response = await medrecApi.profileCreate(payload);
    dispatch('pushLinkedList', {
      action: 'listAddItem',
      payload: response,
    });
    return response;
  },
};

export default createModule(
  crudSingleFactory({
    fetchMethod: medrecApi.profileGet,
    changeMethod: medrecApi.profileChange,
    deleteMethod: medrecApi.profileDelete,
    linkedList: 'ADMIN/DOCS_OUTGOING',
  }),
  { state, getters, mutations, actions },
);
