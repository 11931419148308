import { createHTTP } from '@/api/api';

export interface IProcessingPoolFactory {
  url: string;
  poolType: 'active' | 'passive';
}

/**
 * Processing Pool Factory
 */
export default function processingPoolFactory({
  url,
  poolType,
}: IProcessingPoolFactory) {
  const service = createHTTP(url);
  const typeAction = poolType[0].toUpperCase() + poolType.slice(1);
  const typeUrl = `${poolType}_pool`;

  return {
    // Shifts
    [`getCurrentShift${typeAction}`]() {
      return service.get(`/${typeUrl}/my/shift`).then(res => res.data);
    },
    [`openShift${typeAction}`](data: any) {
      return service
        .post(`/${typeUrl}/my/shift/open`, data)
        .then(res => res.data);
    },
    [`closeShift${typeAction}`]() {
      return service.post(`/${typeUrl}/my/shift/close`).then(res => res.data);
    },

    // Inspections
    [`getInspection${typeAction}`](id: number) {
      return service.get(`/${typeUrl}/inspections/${id}`).then(res => res.data);
    },
    [`takeInspection${typeAction}`](id: number) {
      return service
        .post(`/${typeUrl}/inspections/${id}/take`)
        .then(res => res.data);
    },
    [`resolveInspection${typeAction}`](id: number, data: any) {
      return service
        .post(`/${typeUrl}/inspections/${id}/resolve`, data)
        .then(res => res.data);
    },
  };
}
