<template>
  <Card title="Настройка количества попыток и времени неявки" dense>
    <AsyncWrapper :handler="fetch">
      <v-expansion-panels v-model="panel" accordion focusable>
        <v-expansion-panel v-for="(remarks, type) in waitretry" :key="type">
          <v-expansion-panel-header>
            <div class="d-flex justify-space-between align-center">
              <span>{{ profileTemplates.inspectionType[type] }}</span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table class="text-center" dense>
              <thead class="font-weight-bold">
                <tr>
                  <th class="py-3">Параметры</th>
                  <th class="text-center py-3">Кол-во попыток</th>
                  <th class="text-center py-3">Время неявки (в мин.)</th>
                  <th class="text-center py-3">
                    Продолжительность запрета авторизации (в мин.)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(fields, remark) in remarks" :key="remark">
                  <td class="grey--text px-4 py-3">
                    {{ remarksTranslations[remark] }}
                  </td>
                  <td
                    v-for="(val, field) in fields"
                    :key="field"
                    class="px-4 py-3"
                  >
                    <span v-if="!editing">
                      {{ waitretry[type][remark][field] }}
                    </span>
                    <MaskField
                      v-else
                      v-model="data[type][remark][field]"
                      style="max-width: 150px; margin: auto !important"
                      :mask="maskPosNumber"
                      class="ma-0 pa-0"
                      :disabled="
                        !$can('PS_P') || $wait('updatingWaitretrySettings')
                      "
                      outlined
                      dense
                      :error-messages="
                        getValidationErrors(
                          'data.$each.' +
                            type +
                            '.$each.' +
                            remark +
                            '.' +
                            field,
                        )
                      "
                      @blur="
                        validateField(
                          'data.$each.' +
                            type +
                            '.$each.' +
                            remark +
                            '.' +
                            field,
                        )
                      "
                    />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </AsyncWrapper>

    <template #action>
      <div>
        <CardActionBtn
          v-if="$can('PS_P') && !editing"
          label="Изменить"
          :loading="$wait('updatingWaitretrySettings')"
          :disabled="loading"
          @click="toggleEditing"
        />
        <template v-else-if="$can('PS_P') && editing">
          <v-btn
            v-if="true"
            color="success"
            outlined
            small
            class="mr-2"
            @click="handleWaitretrySettingsUpdate"
          >
            <v-icon size="16">far fa-save</v-icon>
          </v-btn>
          <v-btn outlined small @click="toggleEditing">
            <v-icon size="16">fa-times</v-icon>
          </v-btn>
        </template>
      </div>
    </template>
  </Card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import { maskPosNumber } from '@/utils/masks';
import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { REMARKS_TRANSLATION_TABLE } from '@/modules/employees/constants';
import { maxValue } from 'vuelidate/lib/validators';
import validationMixin from '@/utils/validation';

import AsyncWrapper from '@/components/AsyncWrapper';
import Card from '@/components/ui/Card';
import MaskField from '@/components/controls/MaskField';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';

export default {
  components: { AsyncWrapper, Card, MaskField, CardActionBtn },
  mixins: [validationMixin, waitable],

  data: () => ({
    editing: false,
    loading: false,
    data: {},

    panel: null,
  }),

  validations() {
    return {
      data: {
        $each: {
          $each: {
            limit: { maxValue: maxValue(10) },
            absenceMinutes: { maxValue: maxValue(60) },
            noAllowAuthMinutes: { maxValue: maxValue(60) },
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', ['waitretry']),
    ...mapGetters('REGISTRY', ['profileTemplates']),
    maskPosNumber: () => maskPosNumber,
    remarksTranslations: () => REMARKS_TRANSLATION_TABLE,
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', [
      'fetchWaitretrySettings',
      'changeWaitretrySettings',
    ]),
    ...mapActions('REGISTRY', ['fetchProfileTemplates']),
    async fetch() {
      this.loading = true;
      await Promise.all([
        this.fetchWaitretrySettings(),
        this.fetchProfileTemplates(),
      ]);
      this.loading = false;
    },

    toggleEditing() {
      if (!this.editing) {
        Vue.set(this, 'data', cloneDeep(this.waitretry));
        this.editing = true;
      } else {
        this.data = {};
        this.editing = false;
      }
    },

    handleWaitretrySettingsUpdate() {
      if (!this.validate()) return;

      // convert all values to numbers since MaskField can work only with
      // strings
      const p = cloneDeep(this.data);
      Object.keys(p).forEach(key => {
        Object.keys(p[key]).forEach(k => {
          Object.keys(p[key][k]).forEach(y => {
            p[key][k][y] = +p[key][k][y];
          });
        });
      });
      this.$openModal('prompt', {
        yes: 'Да',
        no: 'Отмена',
        title: 'Подтверждение',
        description:
          'Вы действительно хотите изменить настройки количества попыток и времени неявки?',
        messages: {
          successfulAction:
            'Настройки количества попыток и времени неявки успешно изменены',
          errorAction:
            'Возникла ошибка изменения настроек количества попыток и времени неявки',
        },
        onSubmit: () =>
          this.$loading(
            this.changeWaitretrySettings({ ...p }).then(() => {
              this.toggleEditing();
            }),
            'updatingWaitretrySettings',
          ),
      });
    },
  },
};
</script>
