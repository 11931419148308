<template>
  <ColumnLayout>
    <template #col-view>
      <div
        class="d-flex justify-space-between flex-wrap py-3"
        style="gap: 12px"
      >
        <EmployeePreviewProfile :value="employee" />
        <CardActionBtn
          v-if="!isItemPage"
          label="Открыть карточку"
          :to="{
            name: 'documents:incoming:item',
            params: { id: document.id },
          }"
          target="_blank"
        />
      </div>

      <v-divider />
      <DefinitionList :value="itemData" dense :data="document" />

      <div v-if="document.comments && document.comments.length">
        <v-divider class="mb-4" />
        <b class="px-2">Комментарии:</b>

        <Spinner v-if="$wait('accountPreviewsLoading')" />
        <div v-else>
          <CommentItem
            v-for="(comment, index) in document.comments"
            :key="index"
            :value="comment"
            :account-previews="accounts"
          />
        </div>
      </div>
    </template>

    <template #col-images>
      <div style="min-height: 70vh; position: relative">
        <div style="width: 100%; position: absolute">
          <ImagePreview v-for="scan in document.scans" :key="scan" :url="scan">
            <v-btn color="primary" text small :href="scan" target="_blank">
              Скачать
            </v-btn>
          </ImagePreview>
        </div>
      </div>
    </template>
  </ColumnLayout>
</template>

<script>
import ColumnLayout from '@/components/layouts/ColumnLayout.vue';
import EmployeePreviewProfile from '@/modules/employees/components/PreviewProfile';
import ImagePreview from '@/components/ImagePreview.vue';
import CommentItem from '@/modules/employees/components/CommentItem.vue';
import DefinitionList from '@/components/DefinitionList';
import Spinner from '@/components/Spinner';
import ColoredText from './ColoredText';
import LinkButton from '@/components/controls/buttons/LinkButton';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';

import { date, shortName } from '@/utils/convert';

import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import { entity } from '../entity';
import { DOCUMENT_STATUSES } from '@/modules/documents/constants';

export default {
  components: {
    ColumnLayout,
    EmployeePreviewProfile,
    ImagePreview,
    Spinner,
    CommentItem,
    DefinitionList,
    CardActionBtn,
  },
  mixins: [waitable],
  props: {
    document: { type: Object, required: true },
    employee: { type: Object, required: true },
  },

  data: () => ({ accounts: {} }),

  computed: {
    ...mapGetters('DOCUMENTS/INCOMING_ITEM', ['approvedByUser']),

    itemData() {
      const data = entity
        .filter(item => item.label && !item.hideInCard)
        .map(item => {
          const res = {
            label: item.label,
            value:
              (item.convert
                ? item.convert(this.document, this.$store.getters)
                : this.document[item.key]) || '–',
          };

          if (item.key === 'status') {
            res.component = ColoredText;
            res.props = {
              text: res.value,
              color:
                DOCUMENT_STATUSES.find(
                  status => status.value === this.document[item.key],
                )?.color || undefined,
            };
          }

          if (item.key === 'resolutionUser') {
            const val = this.approvedByUser
              ? shortName(this.approvedByUser)
              : null;
            res.component = LinkButton;
            res.props = {
              text: val || '–',
              action: this.$can('ACC_R') && !!val,
              dense: true,
              blank: true,
              to: {
                name: 'admin:users:item',
                params: { id: this.document.resolution?.approvedBy },
              },
            };
          }
          return res;
        });

      this.document.type === 'HEALTH_CERTIFICATE' &&
        this.document.status !== 'PENDING' &&
        data.push({
          label: 'Учитывается в группах риска',
          value: this.document.includedInRiskGroup ? 'Да' : 'Нет',
        });

      return data;
    },

    isItemPage() {
      return this.$route.name === 'documents:incoming:item';
    },
  },

  async created() {
    this.clearCachedAccs();

    // NOTE: for now this 'shitcode' is due to our (frontenders) completely
    // broken mood cuz of our backenders' desisions.. So.. Instead of fetching
    // those account previews in respective stores and caching 'em there we
    // fetch those in this component (which is logically wrong) and store that
    // info here locally, which means even more frequent backend structures
    // service abuse
    const accountIds = [
      ...new Set(
        this.document.comments
          .filter(comment => comment.author)
          .map(comment => comment.author),
      ),
    ];

    accountIds.length &&
      this.$can('AC_PRW') &&
      (await this.$loadingNotify(
        this.fetchAccountPreviewsBy(accountIds).then(res => {
          res.forEach(
            account => (this.accounts[account.id] = shortName(account.profile)),
          );
        }),
        'accountPreviewsLoading',
        'Произошла ошибка получения информации о работниках',
        '',
      ));
    await this.fetchIncomingDocumentTypes();
  },

  methods: {
    ...mapActions('AUTH_PREVIEWS', [
      'clearCachedAccs',
      'fetchAccountPreviewsBy',
    ]),
    ...mapActions('REGISTRY', ['fetchIncomingDocumentTypes']),

    date,
    shortName,
  },
};
</script>
