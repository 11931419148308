<template>
  <ModernModalLayout title="Просмотр исходящего документа" size="xlarge">
    <AsyncWrapper :handler="fetch">
      <Item :document="singleItem" :employee="employee" />
    </AsyncWrapper>
  </ModernModalLayout>
</template>

<script>
import Item from './Item.component';
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import AsyncWrapper from '@/components/AsyncWrapper.vue';

import { mapActions, mapGetters } from 'vuex';

export default {
  components: { ModernModalLayout, Item, AsyncWrapper },

  props: { id: { type: Number, required: true } },

  computed: {
    ...mapGetters('DOCUMENTS/OUTGOING_ITEM', ['singleItem', 'employee']),
  },

  methods: {
    ...mapActions('DOCUMENTS/OUTGOING_ITEM', ['fetchSingle']),

    fetch() {
      return this.fetchSingle(this.id);
    },
  },
};
</script>
