var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('List',{attrs:{"available-actions":{
    showAction: _vm.$can('SE_D'),
    createAction: false,
  }},scopedSlots:_vm._u([{key:"filters",fn:function(query){return [_c('v-col',{attrs:{"cols":"12","sm":"4","md":"2"}},[_c('v-select',{attrs:{"label":"Статус","items":_vm.statusItems,"hide-details":"","outlined":"","clearable":"","dense":"","menu-props":{ offsetY: true }},model:{value:(query.status),callback:function ($$v) {_vm.$set(query, "status", $$v)},expression:"query.status"}})],1)]}},{key:"table.item.isManual",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.isManual ? 'Вручную' : 'Автоматически')+" ")]}},{key:"table.item.job",fn:function(ref){
  var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.job ? item.job.name : '– DELETED –'))])]}},{key:"table.item.status",fn:function(ref){
  var item = ref.item;
return [_c('ExecutionStatus',{attrs:{"value":item.status}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }