<template>
  <div>
    <NoDataAlert v-if="!healthInfo || !healthInfo.length">
      Нет данных для групп рисков
    </NoDataAlert>
    <v-row v-else class="pt-0 justify-space-around">
      <v-col :cols="showingInfo.risksEnabled ? 5 : 12">
        <div class="ml-3">
          <template v-if="healthInfo.length > 1">
            <v-switch
              v-model="showCurrentMonth"
              label="Текущий календарный месяц"
              hide-details="auto"
              hint="По умолчанию состояние здоровья показывается за текущий календарный месяц"
              persistent-hint
            />

            <v-divider class="my-3" />
          </template>

          <div class="d-flex flex-column" style="gap: 16px">
            <DefinitionList :value="employeeData" dense />

            <div>
              <div class="font-weight-bold text-subtitle-1 mb-2">
                Количество осмотров
              </div>
              <div class="d-flex" style="width: 100%">
                <div class="text-center" style="width: 100%">
                  <div
                    :style="'color: rgb(' + colors.green + ')'"
                    class="subtitle-1"
                  >
                    {{ showingInfo.cardHeader.resolutionSuccess }}
                  </div>
                  <div>{{ successes }}</div>
                </div>
                <div
                  class="text-center"
                  style="width: 100%; border-left: 1px solid #dfe0df"
                >
                  <div
                    :style="'color: rgb(' + colors.red + ')'"
                    class="subtitle-1"
                  >
                    {{ showingInfo.cardHeader.resolutionFailed }}
                  </div>
                  <div>{{ failures }}</div>
                </div>
              </div>
            </div>

            <div v-if="statsListData.length">
              <div class="font-weight-bold text-subtitle-1 mb-2">
                Замечания по осмотрам
              </div>
              <DefinitionList
                dense
                :value="statsListData"
                :width-columns="['50%', '50%']"
              />
            </div>
          </div>
        </div>
      </v-col>

      <v-col v-if="showingInfo.risksEnabled" :cols="7" class="d-flex pr-0">
        <v-divider vertical class="mx-1" />
        <v-col class="pt-0">
          <h2 class="text-center mb-4">Группы риска</h2>

          <NoDataAlert v-if="!hasPositiveRisks">
            Не входит в группы риска
          </NoDataAlert>

          <!-- I could make separate component of 'content' block for each of
               expansion panels below, and kinda reduce lines amount here, but is
               it worth populating components just for that? -->
          <v-expansion-panels v-else v-model="panel" multiple>
            <v-expansion-panel v-for="risk in risksArray" :key="risk.label">
              <v-expansion-panel-header>
                <div class="text-subtitle-1">
                  {{ risksTransTable[risk.label] }}

                  <v-btn
                    v-if="risk.label === 'certificate' && $can('DOC_R')"
                    color="primary"
                    small
                    text
                    dense
                    @click="() => openDocumentModal(risk)"
                  >
                    Открыть
                  </v-btn>
                </div>
                <div
                  v-if="risk.riskClarification"
                  class="clarification ml-4 py-1 px-2 mr-2"
                  :style="risk.riskColor"
                >
                  {{ risk.riskClarification }}
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div>
                  <p v-if="risk.subtitle" class="health-state__rec-item">
                    {{ risk.subtitle }}
                  </p>
                  <div
                    v-if="risk.recommendationList"
                    class="health-state__rec-item"
                  >
                    <span>Рекомендовано:</span>
                    <ul>
                      <li
                        v-for="(item, index) in risk.recommendationList"
                        :key="index"
                      >
                        <span>{{
                          `${item}${
                            index === risk.recommendationList.length - 1
                              ? '.'
                              : ';'
                          }`
                        }}</span>
                      </li>
                    </ul>
                  </div>
                  <div v-if="risk.addition" class="health-state__rec-item">
                    {{ risk.addition }}
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { diffYears } from '@/utils/helpers';
import plural from 'plural-ru';
import { date } from '@/utils/convert';
// green yellow orange red, seek for examples
import { COLORS } from '@/utils/constants';
import {
  REMARKS_TRANSLATION_TABLE,
  RISKS_TRANSLATION_TABLE,
  RISK_LEVELS,
  RECOMMENDATIONS_TABLE,
  ADDITION_TRANSLATION_TABLE,
} from '../constants';

// components below
import DefinitionList from '@/components/DefinitionList.vue';
import EmployeeName from '../components/EmployeeName';
import OpenDocButton from '../components/OpenDocButton';
import NoDataAlert from '@/components/ui/NoDataAlert';

const RISK_STATUS_FIELDS_HANDLERS = {
  cardioDisease: risk => RISK_LEVELS[risk.riskLevel],
  cardioEvents: risk => {
    const percent = risk.summaryCardicRiskInTenYears;
    const riskLevelLabel = RISK_LEVELS[risk.riskLevel];
    return percent !== '-' ? `${percent}, ${riskLevelLabel}` : riskLevelLabel;
  },
  cardioArrhythmia: risk => risk.verdict,
  alcohol: risk => risk.failedInspectionsByAlcohol,
  certificate: risk =>
    risk.dueDate === '-' ? 'Бессрочная' : 'До ' + risk.dueDate,
  smoking: risk => risk.smokingAddictionDegree,
  stress: risk => risk.systematicStressIndex,
};

export default {
  components: { DefinitionList, NoDataAlert },
  props: {
    employee: { type: Object, required: true },
    healthInfo: { type: Array, required: true },
  },
  data: () => ({
    // show risks report for current month, otherwise for previous
    showCurrentMonth: true,
    panel: [],
  }),

  computed: {
    // show for current month or previous
    showingInfo() {
      // here we can omit checking if there is a report for previous month since
      // the user won't be able to toggle 'showCurrentMonth' anyway if there's
      // just one
      return this.showCurrentMonth
        ? this.healthInfo[this.healthInfo.length - 1]
        : this.healthInfo[this.healthInfo.length - 2];
    },

    age() {
      const age = diffYears(this.employee.dateOfBirth, new Date());
      return age + ' ' + plural(age, 'год', 'года', 'лет');
    },

    successes() {
      const amount = this.showingInfo.cardHeader.resolutionSuccess;
      return plural(amount, 'допуск', 'допуска', 'допусков');
    },

    failures() {
      const amount = this.showingInfo.cardHeader.resolutionFailed;
      return plural(amount, 'недопуск', 'недопуска', 'недопусков');
    },

    statsListData() {
      const s = this.showingInfo.cardHeader.resolutionFailedWithMedicalRemarks;
      return Object.keys(s).map(remark => ({
        label: REMARKS_TRANSLATION_TABLE[remark],
        // turn into string cuz DefinitionList doesn't show FALSY values
        value: '' + s[remark],
      }));
    },

    hasPositiveRisks() {
      return Object.keys(this.showingInfo.risks).length;
    },

    // --- static stuff just to not put it in HTML ---

    employeeData() {
      const s = this.showingInfo.cardHeader;
      let gender = '-'; // default value
      let smoking = null;

      if (this.employee.gender)
        gender = this.employee.gender === 'FEMALE' ? 'Женщина' : 'Мужчина';
      if (s.smoking.hasLastSurvey)
        smoking = s.smoking.isSmoking ? 'Курит' : 'Не курит';

      return [
        { label: 'ФИО', value: this.employee, component: EmployeeName },
        { label: 'Пол', value: gender },
        {
          label: 'Дата рождения',
          value: date(this.employee.dateOfBirth) || '-',
        },
        { label: 'Возраст', value: this.age },
        { label: 'Вес', value: s.height && `рост ${s.height} см` },
        { label: 'Рост', value: s.weight && `вес ${s.weight} кг` },
        { label: 'Курение', value: smoking },
        {
          label: 'Д-учет',
          component:
            s.certificate.incomingDocumentId && this.$can('ODC_R')
              ? OpenDocButton
              : null,
          props: { cert: s.certificate, employee: this.employee },
        },
      ];
    },

    colors: () => COLORS,
    risksTransTable: () => RISKS_TRANSLATION_TABLE,
    risksArray() {
      return Object.keys(this.showingInfo.risks).map(key => {
        const risk = this.showingInfo.risks[key];
        const recTranslationKey = risk.riskLevel || 'middle';
        const riskColor = this.getRiskColor(key, risk);
        const riskClarification = this.getRiskClarification(key, risk);
        return {
          label: key,
          // Предполагаемый или установленный диагноз
          subtitle:
            risk.smokingRisk || risk.systematicStress || risk.diagnosis || null,
          // Список рекомендаций
          recommendationList:
            RECOMMENDATIONS_TABLE[key]?.[recTranslationKey] || null,
          // Дополнение к рекомендациям
          addition:
            ADDITION_TRANSLATION_TABLE[key]?.[recTranslationKey] || null,
          // цвет плашечки в заголовке риска
          riskColor,
          // текст плашечки в заголовке риска
          riskClarification,
          // Дата действия справки (Необходима только для открытия документа справки)
          incomingDocumentId: risk.incomingDocumentId || null,
        };
      });
    },
  },

  methods: {
    // return color for clarification badge (near heading)
    getRiskColor(key, risk) {
      const colors = {
        veryLow: COLORS.gray,
        low: COLORS.green,
        middle: COLORS.yellow,
        high: COLORS.orange,
        veryHigh: COLORS.red,
      };

      let color = '';
      const NO_RISK_LEVELS = ['alcohol', 'cardioArrhythmia'];

      if (NO_RISK_LEVELS.includes(key)) color = `rgb(${colors.veryHigh})`;
      else if (risk.riskLevel) color = `rgb(${colors[risk.riskLevel]})`;
      else color = `rgb(${colors.middle})`;

      return `color: ${color}; border-color: ${color}`;
    },

    // clarification (what to display in risk badge near hearing)
    getRiskClarification(key, risk) {
      // if clarification field will remain empty for some risk - the badge
      // just won't be rendered
      return RISK_STATUS_FIELDS_HANDLERS[key]
        ? RISK_STATUS_FIELDS_HANDLERS[key](risk)
        : null;
    },

    openDocumentModal(cert) {
      this.$openModal('documents/showIncoming', {
        id: cert.incomingDocumentId,
        employee: this.employee,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.clarification {
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  max-width: fit-content;
}
.health-state__rec-item {
  margin-bottom: 0;
}
.health-state__rec-item:not(:last-child) {
  margin-bottom: 16px;
}
</style>
