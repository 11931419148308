var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{staticClass:"actual-answers",attrs:{"dense":""}},[_c('tbody',_vm._l((_vm.answers),function(ref){
var key = ref.key;
var status = ref.status;
var surveyDate = ref.surveyDate;
var value = ref.value;
var answerId = ref.answerId;
return _c('tr',{key:answerId,class:{ 'grey--text': status !== 'actual' }},[_c('td',{staticClass:"actual-answers__text",staticStyle:{"width":"80%"}},[_c('div',{staticClass:"d-flex flex-column align-start"},[_c('span',[_vm._v(_vm._s(_vm.getQuestion(key)))]),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(((_vm.getDate(surveyDate)) + " " + (_vm.statusText(status))))+" ")])])]),_c('td',{staticClass:"actual-answers__text",staticStyle:{"width":"20%"}},[_vm._v(" "+_vm._s(value || '-')+" ")]),(_vm.$can('ANS_INV'))?_c('td',{staticClass:"my-0 ml-2"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(status === 'actual' && value)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.invalidate(answerId, key)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("fa-times-circle")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Сбросить ответ")])])],1):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }