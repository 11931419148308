<template>
  <div>
    <v-btn
      v-if="$can('ESOF') && !value.isEnabled"
      depressed
      small
      text
      color="primary"
      :loading="changingStatus"
      @click="changeFindFaceStatus('enable')"
    >
      Включить
    </v-btn>
    <v-btn
      v-else-if="$can('DSOF') && value.isEnabled"
      depressed
      small
      text
      color="primary"
      :loading="changingStatus"
      @click="changeFindFaceStatus('disable')"
    >
      Отключить
    </v-btn>
    <v-btn
      v-if="value.isEnabled && !changingStatus"
      depressed
      small
      text
      color="primary"
      :loading="$wait('fetchStatus')"
      @click="fetchStatus"
    >
      Обновить статус
    </v-btn>
  </div>
</template>

<script>
import waitable from '@/utils/mixins/waitable';
import { mapActions } from 'vuex';
import axios from 'axios';

export default {
  mixins: [waitable],
  props: {
    value: { required: true, type: Object },
  },

  data: () => ({
    changingStatus: false,
    canceller: null,
  }),

  beforeDestroy() {
    this.canceller && this.canceller.abort();
    this.canceller = null; // set to null for spammer func to know that we'r done
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', [
      'fetchFindFaceStatus',
      'enableFindFaceSync',
      'disableFindFaceSync',
    ]),

    fetchStatus() {
      if (this.$wait('fetchStatus')) return;
      this.canceller = new AbortController();
      const signal = this.canceller.signal;
      this.$loading(
        this.fetchFindFaceStatus(signal).catch(err => {
          if (!axios.isCancel(err)) throw err;
        }),
        'fetchStatus',
      );
    },

    // while status of find face is 'in progress' keep fetching its' status
    // and set timeout between requests
    async fetchStatusSpammer(oldStatus) {
      do {
        await this.fetchStatus();
        await new Promise(resolve => setTimeout(resolve, 1000));
      } while (
        (this.value.status === 'in_progress' ||
          this.value.isEnabled === oldStatus) &&
        this.canceller
      );
      this.changingStatus = false;
    },

    async changeFindFaceStatus(status) {
      if (this.changingStatus) return;

      const oldStatus = this.value.isEnabled;
      const enabling = status === 'enable';

      const action = enabling
        ? this.enableFindFaceSync
        : this.disableFindFaceSync;
      this.$openModal('prompt', {
        yes: 'Да',
        no: 'Отмена',
        title: 'Подтверждение',
        description: `Вы действительно хотите ${
          enabling ? 'подключить' : 'отключить'
        } FindFace?`,
        messages: {
          successfulAction: `FindFace успешно ${
            enabling ? 'подключен' : 'отключен'
          }`,
          errorAction: `Ошибка ${
            enabling ? 'подключения' : 'отключения'
          } FindFace`,
        },
        onSubmit: () => {
          // set component state to loading which will be changed back to normal
          // in the 'fetchStatusSpammer' function, not here to not hang the
          // modal
          this.changingStatus = true;
          action(enabling)
            .then(() => {
              this.fetchStatusSpammer(oldStatus);
            })
            .catch(err => {
              this.changingStatus = false;
              this.$notify({
                group: 'note',
                type: 'error',
                title: err?.response?.data?.message,
              });
            });
          // do not return async function to (again) not hang modal untill
          // request be complete (which might take a while).
          return false;
        },
      });
    },
  },
};
</script>
