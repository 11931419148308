var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('List',{attrs:{"search-filter":"","available-actions":{
    showAction: _vm.$can('MED_RD'),
    createAction: _vm.$can('MED_C'),
  }},on:{"item:create":_vm.createModal},scopedSlots:_vm._u([{key:"filters",fn:function(query){return [_c('v-col',{attrs:{"cols":"12","md":"3","sm":"4"}},[_c('v-select',{attrs:{"label":"Статус медработника","item-value":"value","item-text":"name","hide-details":"","outlined":"","dense":"","items":_vm.statusItems,"menu-props":{ offsetY: true }},model:{value:(query.isEnabled),callback:function ($$v) {_vm.$set(query, "isEnabled", $$v)},expression:"query.isEnabled"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"4"}},[_c('CategorySelect',{attrs:{"multiple":"","dense":""},model:{value:(query.categoryIds),callback:function ($$v) {_vm.$set(query, "categoryIds", $$v)},expression:"query.categoryIds"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"4"}},[_c('Select',{attrs:{"label":"Роли","item-value":"key","dense":"","clearable":"","multiple":"","items":_vm.medicalRoles,"loading":_vm.$wait('fetchMedicalRolesPreviews')},model:{value:(query.roleKeys),callback:function ($$v) {_vm.$set(query, "roleKeys", $$v)},expression:"query.roleKeys"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"4"}},[_c('MedicGroupSelect',{attrs:{"multiple":"","dense":""},model:{value:(query.groupIds),callback:function ($$v) {_vm.$set(query, "groupIds", $$v)},expression:"query.groupIds"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"4"}},[_c('OrganizationSelect',{attrs:{"medical":"","multiple":"","dense":""},model:{value:(query.orgIds),callback:function ($$v) {_vm.$set(query, "orgIds", $$v)},expression:"query.orgIds"}})],1)]}},{key:"table.item.name_gender_dateOfBirth",fn:function(ref){
  var item = ref.item;
return [_c('b',[_vm._v(_vm._s(_vm.name(item.medicData)))]),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s((_vm.medicalRoleByKey(item.medicData.roleKey) || {}).name || '–')+" "),_c('br'),_vm._v(" "+_vm._s(_vm.GENDER_NOMINATIVE[item.medicData.gender])+", "+_vm._s(_vm.dateFormatter(item.medicData.dateOfBirth))+" ")]}},{key:"table.item.contacts",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.medicData.email)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.maskTextPhone(item.medicData.phone))+" ")]}},{key:"table.item.diagnostic",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":"","content-class":"tooltip--show-white-space","color":"rgba(0,0,0,0)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-icon',{attrs:{"color":item.diagnostic.highStatus.color}},[_vm._v(" "+_vm._s(item.diagnostic.highStatus.icon)+" ")])],1)]}}],null,true)},[_c('MedicDiagnosticTable',{staticStyle:{"max-width":"400px"},attrs:{"dense":"","dark":"","value":item.diagnostic,"width-columns":['80%', '20%']}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }