<template>
  <div class="mb-5">
    <v-card class="ma-0" tile>
      <v-layout class="pa-5 inspection-header" wrap>
        <TittledText
          class="inspection-header__first pr-5"
          :subtitle="type && type.name"
        >
          <template #title>
            <div class="d-flex align-center" style="gap: 4px">
              <div>#{{ id }}</div>

              <v-tooltip v-if="isRetry" bottom>
                <template #activator="{ on, attrs }">
                  <v-chip v-bind="attrs" color="error" label small v-on="on">
                    Повторный
                  </v-chip>
                </template>
                <span>
                  Работник уже проходил осмотр этого же типа<br />
                  в течении последних трех часов
                </span>
              </v-tooltip>

              <v-tooltip v-if="showAttemptLabel" bottom>
                <template #activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    color="warning"
                    text-color="black"
                    label
                    small
                    v-on="on"
                  >
                    Назначен {{ attemptText }}
                  </v-chip>
                </template>
                <span>
                  Осмотр был назначен медработникам {{ attemptText }}, включая
                  этот
                </span>
              </v-tooltip>
            </div>
          </template>
          <div>
            <v-tooltip top>
              <template #activator="{ on }">
                <div v-on="on">{{ finishedDate }}</div>
              </template>
              <span>Время завершения осмотра на ПАК</span>
            </v-tooltip>
          </div>
          <div>Длительность {{ duration }}</div>
          <div>Точка: {{ pointText }}</div>
        </TittledText>
        <TittledText
          class="inspection-header__second"
          :subtitle="`Возраст: ${employeeAge} (${dateOfBirth})`"
        >
          <template #title> <EmployeeName :value="employee" /></template>
          <div>Пол: {{ gender }}</div>
          <div>Табельный №: {{ employee.personnelNumber }}</div>
          <div>Организация: {{ organization.name }}</div>
        </TittledText>
        <v-spacer v-if="$can('GRT')" />
        <div v-if="$can('GRT')">
          <v-card
            class="pa-3 mt-2 text-center align-center"
            min-width="150px"
            height="80%"
            :style="getRatingColor()"
          >
            <div class="text-caption">Рейтинг</div>
            <div class="text-h4">
              {{ rating || '-' }}
            </div>
          </v-card>
        </div>
        <v-spacer />
        <div class="d-flex align-start">
          <Scenario with-icons :steps="steps" :labels="labels" />
        </div>
      </v-layout>
    </v-card>

    <AssistantRecommendation
      :assistance="assistance"
      :steps="steps"
      :type="type"
      :hide-button="!isInspecting"
      :assistant-disabled="assistantDisabled"
      class="mx-5 px-3 py-1"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import plural from 'plural-ru';
import { diffYears } from '@/utils/helpers';
import {
  date,
  dateTime,
  gender,
  durationInMinutes,
  name,
} from '@/utils/convert';
import waitable from '@/utils/mixins/waitable';
import { COLORS } from '@/utils/constants';

import EmployeeName from '@/modules/employees/components/EmployeeName.vue';
import TittledText from '@/components/TittledText.vue';
import Scenario from './Scenario.vue';
import AssistantRecommendation from './AssistantRecommendation.vue';
import ratingsApi from '@/api/services/ratings';

export default {
  components: {
    EmployeeName,
    TittledText,
    Scenario,
    AssistantRecommendation,
  },

  mixins: [waitable],

  data: () => ({
    rating: null,
  }),

  computed: {
    ...mapGetters('MEDCAB', {
      activePoolIsInspecting: 'isInspecting',
    }),
    ...mapGetters('MEDCAB_PASSIVE', {
      passivePoolIsInspecting: 'isInspecting',
    }),
    ...mapGetters('MEDCAB_INSPECTION', [
      'id',
      'assistance',
      'timestamps',
      'type',
      'steps',
      'employee',
      'host',
      'organization',
      'isRetry',
      'attempt',
      'assistantDisabled',
    ]),
    labels() {
      return this.assistance.labels;
    },
    gender() {
      return gender(this.employee.gender);
    },
    finishedDate() {
      return dateTime(this.timestamps.finishedAt);
    },
    duration() {
      return durationInMinutes(
        this.timestamps.startedAt,
        this.timestamps.finishedAt,
      );
    },
    pointText() {
      const p = this.host.releasePoint;
      // no address? coordinates/mobile point doesn't matter - always return id
      return p.address || '#' + p.id;
    },
    dateOfBirth() {
      return date(this.employee.dateOfBirth);
    },
    employeeAge() {
      return diffYears(
        this.employee.dateOfBirth,
        new Date(this.timestamps.startedAt),
      );
    },
    isInspecting() {
      return this.activePoolIsInspecting || this.passivePoolIsInspecting;
    },
    showAttemptLabel() {
      return this.attempt > 1;
    },
    attemptText() {
      if (!this.attempt) return '–';
      return this.attempt + ' ' + plural(this.attempt, 'раз', 'раза', 'раз');
    },
  },

  async created() {
    this.$can('GRT') &&
      (await this.$loadingNotify(
        ratingsApi
          .getRating(this.employee.id)
          .then(res => (this.rating = res.value)),
        'fetchingRating',
        'Произошла ошибка загрузки рейтинга работника',
      ));
  },

  methods: {
    ...mapActions('EMPLOYEE', ['getRating']),
    name,
    getRatingColor() {
      var color;
      if (!this.rating) return '';
      if (this.rating < 4.5) color = COLORS.red;
      else if (this.rating <= 8.5) color = COLORS.orange;
      else color = COLORS.green;
      return {
        color: `rgb(${color})`,
        background: `rgba(${color}, 0.1)`,
      };
    },
  },
};
</script>

<style lang="scss">
.inspection-header {
  &__first {
    min-width: 200px;
    max-width: 400px;
  }
  &__second {
    width: 300px;
    min-width: 300px;
  }

  &__scenario {
    width: 110px;
  }
}
</style>
