import { dateTime, name } from '@/utils/convert';

export const entity = [
  {
    key: 'inspection',
    label: 'Осмотр',
    headerProps: {
      width: '22%',
    },
  },
  {
    key: 'softwareVersion',
    label: 'Версия ПО ПАК',
  },
  {
    key: 'sentAt',
    label: 'Дата, время отправки осмотра в НИИАТ',
    headerProps: {
      width: '150px',
    },
    convert: ({ sentAt }) => dateTime(sentAt) || '–',
  },
  {
    key: 'resolutionSuccess',
    label: 'Результат осмотра',
    headerProps: {
      width: '100px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
  },
  {
    key: 'name',
    label: 'ФИО работника',
    convert: ({ employee }) => name(employee) || '–',
  },
  {
    key: 'status',
    label: 'Статус',
  },
];

export const messages = {
  resourceName: 'осмотров в НИИАТ',
  searchFilterLabel: 'Поиск по осмотрам',
  searchFilterPlaceholder: '',
};

export const filters = {
  inspectionId: { type: String, default: null },
  dateStart: { type: String, default: null },
  dateEnd: { type: String, default: null },
  softwareVersion: { type: String, default: null },
  status: { type: String, default: null },
};
