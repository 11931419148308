import scenariosApi from '@/api/services/scenarios';

export default {
  state: {
    absenceTime: null,
  },

  getters: {
    absenceTime: state => state.absenceTime,
  },

  mutations: {
    absenceTime: (state, value) => (state.absenceTime = value),
  },

  actions: {
    async fetchAbsenceTime({ getters, commit }) {
      const response = await scenariosApi.getAbsenceTime(getters.id);
      commit('absenceTime', response);
      return response;
    },

    async updateAbsenceTime({ getters, commit }, absenceMinutes) {
      const response = await scenariosApi.updateAbsenceTime(
        getters.id,
        absenceMinutes,
      );
      commit('absenceTime', response);
      return response;
    },

    async resetAbsenceTime({ getters, commit, dispatch }) {
      await scenariosApi.resetAbsenceTime(getters.id);
      // api method above doesn't return anything, so we need to fetch anew
      const response = await dispatch('fetchAbsenceTime');
      commit('absenceTime', response);
      return response;
    },
  },
};
