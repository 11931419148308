var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ItemPageLayout',{attrs:{"back-route":"structures:employee_groups","title":_vm.singleItem.name},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('ActionsMenu',[(_vm.$can('EMPG_D'))?_c('ActionsMenuDelete',{attrs:{"id":_vm.singleItem.id,"store-module":"STRUCTURES/EMPLOYEE_GROUPS","back-route":"structures:employee_groups","title":"Удалить группу работников","description":"Вы уверены, что хотите удалить группу?","disabled":!!_vm.singleItem.itemsCount,"subtitle":_vm.singleItem.itemsCount
            ? 'Нельзя удалить, в группе есть работники'
            : null}}):_vm._e()],1)]},proxy:true},(_vm.$can('EMP_R'))?{key:"innerList",fn:function(){return [_c('List',{attrs:{"available-actions":{
        showAction: _vm.$can('EMP_RD'),
        createAction: _vm.$can('EMP_C'),
      }},on:{"item:create":_vm.openCreate},scopedSlots:_vm._u([{key:"filters",fn:function(query){return [_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('SearchEmployee',{ref:"empSearch",attrs:{"value":query.search,"store":"STRUCTURES/EMPLOYEE_GROUPS_ITEM","org-ids":[_vm.singleItem.organization.id],"group-ids":[_vm.singleItem.id],"disabled":_vm.listIsLoading,"loading":_vm.listIsLoading}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-select',{attrs:{"label":"Статус работников","items":_vm.statusItems,"item-value":"value","item-text":"name","hide-details":"","outlined":"","dense":"","menu-props":{ offsetY: true }},model:{value:(query.isEnabled),callback:function ($$v) {_vm.$set(query, "isEnabled", $$v)},expression:"query.isEnabled"}})],1)]}},{key:"table.item.name_gender_birthDay",fn:function(ref){
      var item = ref.item;
return [_c('b',[_c('EmployeeName',{attrs:{"value":item}})],1),_vm._v(" "+_vm._s(_vm.GENDER_NOMINATIVE[item.gender])+", "+_vm._s(_vm.date(item.dateOfBirth))+" ")]}},{key:"table.item.isEnabled",fn:function(ref){
      var item = ref.item;
return [_c('Indicator',{attrs:{"active":item.isEnabled,"tooltip":item.isEnabled ? 'Активен' : 'Неактивен'}})]}}],null,false,223222708)})]},proxy:true}:null],null,true)},[_c('Card',{attrs:{"title":"Данные группы","dense":""},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.$can('EMPG_U'))?_c('v-btn',{attrs:{"depressed":"","small":"","text":"","color":"primary"},on:{"click":_vm.editModal}},[_vm._v(" Редактировать ")]):_vm._e()]},proxy:true}])},[_c('DefinitionList',{attrs:{"value":_vm.computedEntity,"show-empty-values":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }