<template>
  <v-img
    :src="url"
    class="grey lighten-2 image-preview"
    contain
    @error="error = true"
  >
    <v-layout v-if="error" justify-center fill-height align-center>
      <span class="text-h5">Ошибка загрузки фотографии</span>
    </v-layout>
    <v-layout
      v-if="$scopedSlots.default && !error"
      class="image-preview__actions pa-2"
      justify-center
    >
      <slot />
    </v-layout>
  </v-img>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data: () => ({ error: false }),
};
</script>

<style lang="scss" scoped>
.image-preview {
  border-radius: 4px;
  overflow: hidden;
  min-height: 250px;

  &__actions {
    transition: transform 0.3s ease-in-out;
    transform: translateY(-50px);
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
  }

  &:hover &__actions {
    transform: translateY(0px);
  }
}
</style>
