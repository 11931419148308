<template>
  <OrganizationSelect
    v-model="value"
    :error-messages="errors"
    :loading="$wait('fetchSettings')"
    :disabled="$wait('fetchSettings') || !!predefinedValue"
    access-level="any"
  />
</template>

<script>
import structApi from '@/api/services/structures';
import waitable from '@/utils/mixins/waitable';
import { isXhrError, xhrErrorMessage } from '@/utils/helpers';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect';

export default {
  components: { OrganizationSelect },
  mixins: [waitable],

  props: {
    errorMessages: { type: Array, default: () => [] },
    predefinedValue: { type: Number, default: null },
  },

  data: () => ({
    value: null,
    error: null,
  }),

  created() {
    if (this.predefinedValue) this.value = this.predefinedValue;
  },

  computed: {
    errors() {
      return [this.error, ...this.errorMessages].filter(_ => _);
    },
  },

  watch: {
    value(val) {
      setTimeout(() => {
        if (val) {
          this.fetchSettings(val);
        } else {
          this.$emit('input', null);
        }
      }, 200);
    },
  },

  methods: {
    async fetchSettings(orgId) {
      this.error = null;
      let actions = [];

      await this.$loadingNotify(
        structApi.organizationGet(orgId).then(data => {
          actions = data?.settings?.employeeSync?.actions || [];
        }),
        'fetchSettings',
        'При загрузке организации произошла ошибка',
      ).catch(err => {
        this.error = isXhrError(err)
          ? xhrErrorMessage(err)
          : 'Ошибка загрузки данных по организации';

        this.value = null;
        this.$emit('input', null);
        console.error(err);
      });

      // forbidden create new employees
      if (actions.includes('create')) {
        // clear input value
        this.value = null;
        this.error =
          'В эту организацию нельзя импортировать работников, выберите другую';
      } else {
        this.$emit('input', orgId);
      }

      return orgId;
    },
  },
};
</script>
