import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import RouteGuard from '@/components/RouteGuard';
import INCOMING from './store/list';
import INCOMING_ITEM from './store/item';

export default {
  namespaced: true,
  store: { INCOMING, INCOMING_ITEM },
  routes: [
    {
      path: 'incoming',
      name: 'incoming',
      component: () => import('@/modules/documents/incoming/views/list.vue'),
      meta: {
        title: 'Список входящих документов',
        action: 'DOC_R',
      },
    },
    {
      path: 'incoming/:id',
      name: 'incoming:item',
      component: RouteGuard({
        storeModule: 'DOCUMENTS/INCOMING_ITEM',
        component: () => import('./views/item.vue'),
        useCache: false,
      }),
      meta: {
        title: 'Карточка входящего документа',
        action: 'DOC_RD',
      },
    },
  ],
  menu: [{ title: 'Входящие', action: 'DOC_R', name: 'incoming' }],
  modals: [
    {
      name: 'add',
      component: () =>
        import('@/modules/documents/incoming/modals/AddDocument.vue'),
    },
    {
      name: 'showIncoming',
      component: () =>
        import('@/modules/documents/incoming/modals/ShowIncoming.vue'),
    },
  ],
} as ModuleDefinition;
