<template>
  <Card title="Группы риска" dense>
    <AsyncWrapper :handler="fetchRiskGroups">
      <v-simple-table dense>
        <tbody>
          <tr v-for="{ key, name } in risksDictionary" :key="key">
            <td class="grey--text px-4 py-3">
              {{ name }}
            </td>
            <td class="px-4 py-3" style="width: 50px">
              <v-switch
                :input-value="riskGroups[key]"
                class="ma-0 pa-0"
                inset
                :disabled="!$can('RGS_P')"
                hide-details
                @change="updateRiskGroups(key)"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </AsyncWrapper>

    <template #action>
      <CardActionBtn
        v-if="$can('RGS_P')"
        :label="allEnabled ? 'Отключить все' : 'Включить все'"
        :loading="$wait('togglingAll')"
        @click="toggleAllPrompt()"
      />
    </template>
  </Card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import AsyncWrapper from '@/components/AsyncWrapper';
import Card from '@/components/ui/Card';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';

export default {
  components: { AsyncWrapper, Card, CardActionBtn },
  mixins: [waitable],

  data: () => ({
    risksDictionary: [
      { key: 'cardioEvents', name: 'Риск сердечно-сосудистых событий' },
      { key: 'cardioDisease', name: 'Риск сердечно-сосудистых заболеваний' },
      { key: 'alcohol', name: 'Злоупотребление алкоголем' },
      { key: 'certificate', name: 'Справка 027/у' },
      { key: 'cardioArrhythmia', name: 'Риск нарушения сердечного ритма' },
      { key: 'oldAge', name: 'Работники старшего возраста' },
      { key: 'stress', name: 'Работники в стрессовых состояниях' },
      { key: 'smoking', name: 'Риск развития ХОБЛ' },
    ],
  }),

  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', ['riskGroups']),
    allEnabled() {
      // check if every risk group is enabled or not
      return Object.values(this.riskGroups).every(risk => risk);
    },
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', [
      'fetchRiskGroups',
      'updateRiskGroups',
    ]),

    toggleAllPrompt() {
      this.$openModal('prompt', {
        yes: 'Да',
        no: 'Отмена',
        title: 'Подтверждение',
        description: `Вы действительно хотите ${
          this.allEnabled ? 'отключить' : 'включить'
        } весь отчет Группы Риска?`,
        messages: {
          successfulAction: `Отчет Группы Риска полностью ${
            this.allEnabled ? 'отключен' : 'включен'
          }`,
          errorAction: 'Ошибка обновления настроек групп риска',
        },
        onSubmit: () =>
          this.$loading(this.updateRiskGroups(!this.allEnabled), 'togglingAll'),
      });
    },
  },
};
</script>
