import structApi from '@/api/services/structures';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { createModule } from '@/utils/vuex/createModule';
import { filters } from '../entity';

const actions = {
  changeCounter(
    { commit, rootGetters },
    { itemIds, counterField, addCount = 0 },
  ) {
    if (!itemIds || !itemIds.length || !counterField) return;
    commit('changeCounter', { itemIds, counterField, addCount });
    const groupSingleItem =
      rootGetters['STRUCTURES/EMPLOYEE_GROUPS_ITEM/singleItem'];
    if (groupSingleItem && itemIds.includes(groupSingleItem.id))
      groupSingleItem.itemsCount += addCount;
  },
};

export default createModule(
  crudListFactory({
    fetchMethod: structApi.employeeGroupList,
    createMethod: structApi.employeeGroupCreate,
    deleteMethod: structApi.employeeGroupDelete,
    filters,
  }),
  { actions },
);
