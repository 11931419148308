import surveyApi from '@/api/services/survey';

export default {
  state: {
    surveySettings: false,
    actualAnswers: null,
    surveys: [],
    surveysPage: 1,
    surveysTotal: null,
  },
  getters: {
    surveySettings: state => state.surveySettings,
    actualAnswers: state =>
      (state.actualAnswers || []).filter(
        answer => answer.key !== 'illegal_drugs',
      ),
    // https://jira.medpoint24.ru/browse/SW-8959 <- read last comment here
    surveysNoDrugs: state =>
      state.surveys
        .map(survey => ({
          ...survey,
          answers: survey.answers.filter(
            answer => answer.key !== 'illegal_drugs',
          ),
        }))
        .filter(surv => surv.answers.length > 0),
    // unfiltered surveys array, to compare lenghts with total survey number
    surveys: state => state.surveys,
    surveysPage: state => state.surveysPage,
    surveysTotal: state => state.surveysTotal,
  },
  mutations: {
    surveySettings: (state, value) => (state.surveySettings = value),
    actualAnswers: (state, value) => (state.actualAnswers = value),
    addSurveys: (state, value = {}) => {
      state.surveys = [...state.surveys, ...(value?.items || [])];
      state.surveysPage = value.page + 1;
      state.surveysTotal = value?.total;
    },
    clearSurveys: state => {
      state.surveys = [];
      state.surveysTotal = null;
      state.surveysPage = 1;
    },
    updateSurveyAnswers: (state, value) => {
      const surveyIds = value.map(answer => answer.surveyId);

      // мапинг массива вопросов по id опроса
      const updatedSurveys = {};
      state.surveys
        .filter(survey => surveyIds.includes(survey.surveyId))
        .forEach(survey => {
          updatedSurveys[survey.surveyId] = survey.answers;
        });
      Object.values(updatedSurveys).length &&
        value.forEach(answer => {
          const index = updatedSurveys[answer.surveyId].findIndex(
            item => item.answerId === answer.answerId,
          );
          updatedSurveys[answer.surveyId].splice(index, 1, answer);
        });
    },
  },
  actions: {
    async fetchEmployeeActualAnswers({ getters, commit, dispatch }) {
      // Очистка истории опросов
      commit('clearSurveys');
      const orgId = getters.singleItem.organization.id;
      // Проверка доступности опросов для организации
      const { surveySettings, isEnabled } = await surveyApi.getSurveys(orgId);
      commit('surveySettings', surveySettings);

      let data = null;
      data = isEnabled && (await dispatch('fetchActualAnswers'));
      return data;
    },

    async fetchActualAnswers({ getters, commit }) {
      const id = getters.singleItem.id;
      const data = await surveyApi.getActualAnswers(id);
      commit('actualAnswers', data);
      return data;
    },

    async fetchEmployeeSurveys({ getters, commit, dispatch }, params) {
      const data = await surveyApi.getEmployeeSurveys({
        ...params,
        page: getters.surveysPage,
      });
      commit('addSurveys', data);
      if (getters.surveysNoDrugs.length < 1)
        await dispatch('fetchEmployeeSurveys', {
          ...params,
          page: getters.surveysPage + 1,
        });
      return data;
    },

    async invalidateAnswer(
      { dispatch, commit },
      { invalidAnswersIds, organisationId },
    ) {
      const data = await surveyApi.invalidateAnswer(
        invalidAnswersIds,
        organisationId,
      );
      dispatch('fetchActualAnswers');
      commit('updateSurveyAnswers', data);
    },
  },
};
