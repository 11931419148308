<template>
  <v-select
    :value="value"
    label="Часовой пояс дат в отчете"
    :items="timeDisplayModes"
    outlined
    hide-details="auto"
    @input="$emit('input', $event)"
  />
</template>

<script>
import { TIME_DISPLAY_MODES } from '../../constants';

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    timeDisplayModes() {
      return TIME_DISPLAY_MODES;
    },
  },
};
</script>
