<template>
  <AsyncSelect v-bind="computedProps" :clearable="false" v-on="$listeners" />
</template>

<script>
import asyncSelectMixin from '@/components/controls/asyncSelectMixin';

import { mapActions } from 'vuex';
export default {
  extends: asyncSelectMixin,

  props: {
    formFactor: { type: String, default: null },
    binding: { type: String, default: null },
    // Флаг выбора кастомных профилей. false - дефолт профили, true - кастомные
    // профили, undefined/null - все профили
    isCustom: { type: Boolean, default: null },
    // not required
    title: { type: String, default: null },
  },

  computed: {
    label() {
      if (this.title) return this.title;
      return this.multiple ? 'Профили конфигураций' : 'Профиль конфигурации';
    },
    itemValue() {
      return 'key';
    },
    query() {
      const query = {
        formFactor: [this.formFactor],
        binding: [this.binding],
      };
      if (this.isCustom === false || this.isCustom === true)
        query.isCustom = this.isCustom;
      return query;
    },
  },

  methods: {
    ...mapActions('GATE', {
      fetcher: 'fetchProfilePreviews',
    }),
  },
};
</script>
