<template>
  <div
    :class="themeIsDark ? 'plyr--dark' : 'plyr--light'"
    style="position: relative; width: 100%; padding-bottom: 50px"
  >
    <vue-plyr ref="player" :options="options">
      <video controls crossorigin playsinline>
        <source :src="url" type="video/mp4" />
      </video>
    </vue-plyr>
  </div>
</template>

<script>
import plyrSvg from '@/assets/plyr/plyr.svg';
import plyrBlankVideo from '@/assets/plyr/blank.mp4';
import { mapGetters } from 'vuex';

export default {
  props: {
    url: { type: String, required: true },
    hideVolume: Boolean,
    autoplay: Boolean,
    download: Boolean,
  },
  computed: {
    ...mapGetters(['themeIsDark']),

    options() {
      const controls = [
        'play-large',
        'play',
        'progress',
        'current-time',
        ...((!this.hideVolume && ['mute', 'volume']) || []),
        ...((this.$can('I_RMI') && this.download && ['download']) || []),
        'settings',
        'fullscreen',
      ];

      return {
        autoplay: this.autoplay,
        hideControls: false,
        speed: { selected: 1, options: [10, 5, 2, 1.5, 1, 0.75, 0.5] },
        controls,
        iconUrl: plyrSvg,
        blankVideo: plyrBlankVideo,
        i18n: {
          speed: 'Скорость',
          normal: 'Обычная',
          quality: 'Качество',
        },
      };
    },
  },

  methods: {
    goToTime(time) {
      this.$refs.player.$el.plyr.currentTime = time;
    },
  },
};
</script>

<style lang="scss">
.plyr--video {
  overflow: inherit;
}
.plyr__controls {
  bottom: -50px !important;
  .plyr__controls__item {
    color: inherit;
  }
}
:fullscreen .plyr__controls {
  bottom: 0 !important;
}
.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: none;
}
.plyr--light .plyr__video-wrapper {
  background-color: #e0e0e0;
}
.plyr--dark .plyr__video-wrapper {
  background-color: #616161;
}
</style>
