<template>
  <Fragment>
    <Spinner
      v-if="status === FETCH_STATUS.LOADING"
      :title="loadingTitle"
      :size="20"
    />
    <ErrorBlock
      v-if="status === FETCH_STATUS.ERROR"
      class="mb-0"
      :error="error"
      @refresh="$emit('refresh')"
    />
    <template v-if="status === FETCH_STATUS.SUCCEEDED">
      <slot />
    </template>
  </Fragment>
</template>

<script>
import { FETCH_STATUS } from '@/utils/constants';
import Spinner from '@/components/Spinner';
import ErrorBlock from '@/components/ErrorBlock';

export default {
  components: { Spinner, ErrorBlock },
  props: {
    status: { required: true, type: String, default: FETCH_STATUS.IDLE },
    error: { type: Error, default: undefined },
    loadingTitle: { type: String, default: null },
  },

  computed: {
    FETCH_STATUS: () => FETCH_STATUS,
  },
};
</script>
