import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import RouteGuard from '@/components/RouteGuard';
import EMPLOYEE_GROUPS from './store/list';
import EMPLOYEE_GROUPS_ITEM from './store/item';
import EMBEDDED_EMPLOYEE_LIST from './store/embeddedEmployeeList';

export default {
  namespaced: true,
  store: {
    EMPLOYEE_GROUPS,
    EMPLOYEE_GROUPS_ITEM,
    EMPLOYEE_GROUPS_EMPLOYEE_LIST: EMBEDDED_EMPLOYEE_LIST,
  },
  modals: [
    {
      name: 'employeeGroupForm',
      component: () =>
        import('@/modules/structures/employees-groups/modals/Form.vue'),
    },
  ],
  routes: [
    {
      path: 'employee_groups',
      name: 'employee_groups',
      component: () => import('./view/list.vue'),
      meta: {
        title: 'Все группы работников',
        action: 'EMPG_R',
      },
    },
    {
      path: 'employee_groups/:id',
      name: 'employee_groups:item',
      component: RouteGuard({
        storeModule: 'STRUCTURES/EMPLOYEE_GROUPS_ITEM',
        component: () => import('./view/item.vue'),
      }),
      meta: {
        title: 'Страница группы работников',
        action: 'EMG_RD',
        // otherwise when updating embedded lists on tab items
        // the page will jump up
        disableScrollRestoring: true,
      },
    },
  ],
  menu: [
    {
      title: 'Группы работников',
      action: 'EMPG_R',
      name: 'employee_groups',
      priority: 4,
    },
  ],
} as ModuleDefinition;
