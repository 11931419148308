<template>
  <ItemPageLayout back-route="admin:profiles" :title="title">
    <template v-if="!isCreate" #header>
      <Card dense hide-title>
        <v-tabs v-model="currentTab">
          <v-tab href="#general">Основное</v-tab>
          <v-tab href="#logs">Лог изменений</v-tab>
        </v-tabs>
      </Card>
    </template>

    <div v-if="currentTab === 'general'" :class="isCreate ? 'pt-3' : ''">
      <AsyncWrapper :handler="prepareComponent">
        <v-row class="d-flex mx-0" style="gap: 13px">
          <v-col class="d-flex flex-column pa-0" style="gap: 13px">
            <v-card>
              <v-card-text>
                <v-text-field
                  v-model="conf.key"
                  label="Ключ"
                  outlined
                  :readonly="!$can('GT_UPR')"
                  :disabled="!isCreate"
                  :error-messages="getValidationErrors('conf.key')"
                  @blur="validateField('conf.key')"
                />
                <Select
                  v-model="conf.formFactor"
                  :items="formFactors"
                  :disabled="!isCreate"
                  label="Тип профиля"
                  item-value="value"
                  item-text="name"
                  :readonly="!$can('GT_UPR')"
                  :error-messages="getValidationErrors('conf.formFactor')"
                  @blur="validateField('conf.formFactor')"
                />
                <v-text-field
                  v-model="conf.name"
                  label="Название профиля"
                  outlined
                  :readonly="!$can('GT_UPR')"
                  :error-messages="getValidationErrors('conf.name')"
                  @blur="validateField('conf.name')"
                />
                <v-textarea
                  v-model="conf.description"
                  label="Описание профиля"
                  outlined
                  auto-grow
                  filled
                  :readonly="!$can('GT_UPR')"
                  :error-messages="getValidationErrors('conf.description')"
                  @blur="validateField('conf.description')"
                />
              </v-card-text>
            </v-card>
            <div class="d-flex justify-center">
              <ListActionBtn
                v-if="!conf.isDefault || $can('P_UP_R')"
                label="Сохранить"
                icon="fa-save"
                class="mr-3"
                :icon-size="14"
                color="success"
                :disabled="
                  isCreate
                    ? !$can('GT_CPR')
                    : !$can('GT_UPR') || $wait('createSingleItem')
                "
                @click="isCreate ? handleCreate() : handleSave()"
              />
              <ListActionBtn
                v-if="!conf.isDefault"
                label="Удалить"
                icon="fa-trash-alt"
                :icon-size="14"
                color="error"
                :disabled="
                  !$can('GT_DPR') || $wait('createSingleItem') || isCreate
                "
                @click="handleRemove"
              />
            </div>
          </v-col>
          <v-col class="pa-0">
            <v-card>
              <DevicesSettings
                v-if="
                  conf.devicesSettings && conf.devicesSettings.thermometerMode
                "
                v-model="conf.devicesSettings"
                :disabled="
                  isCreate
                    ? !$can('GT_CPR')
                    : !$can('GT_UPR') || $wait('createSingleItem')
                "
              />
              <InspectionTypes
                v-if="!!conf.scenarios && inspectionTypes.length"
                ref="inspectionTypes"
                :scenarios="conf.scenarios"
                :disable-actions="
                  isCreate
                    ? !$can('GT_CPR')
                    : !$can('GT_UPR') || $wait('createSingleItem')
                "
                :is-create="isCreate"
                :inspection-types="inspectionTypes"
                :hide-sign="true"
                :step-types="stepTypes"
              />
            </v-card>
          </v-col>
        </v-row>
      </AsyncWrapper>
    </div>
    <template v-else-if="currentTab === 'logs'">
      <Card class="mb-4" dense>
        <ChangesList :fetch-function="fetchEventReport" />
      </Card>
    </template>
  </ItemPageLayout>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { uniqueStringKey } from '@/utils/validators';
import cloneDeep from 'lodash/cloneDeep';
import validationMixin from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';
import blameApi from '@/api/services/blame';

import Select from '@/components/controls/Select';
import ListActionBtn from '@/components/controls/buttons/ListActionBtn';
import AsyncWrapper from '@/components/AsyncWrapper.vue';
import ApproveEditing from '../components/ApproveEditing.vue';
import InspectionTypes from '@/components/InspectionTypes';
import DevicesSettings from '@/components/DevicesSettings';
import ItemPageLayout from '@/components/layouts/ItemPageLayout';
import Card from '@/components/ui/Card';
import ChangesList from '@/components/ChangesList';

export default {
  // for exclude from keep-alive
  name: 'Item',
  components: {
    Select,
    ListActionBtn,
    AsyncWrapper,
    InspectionTypes,
    DevicesSettings,
    ItemPageLayout,
    Card,
    ChangesList,
  },
  mixins: [validationMixin, waitable, routeGuardMixin],

  validations() {
    return {
      conf: {
        key: {
          required,
          uniqueStringKey,
          minSymbolsLength: minLength(3),
          maxSymbolsLength: maxLength(15),
        },
        name: { required, maxSymbolsLength: maxLength(500) },
        description: { maxSymbolsLength: maxLength(500) },
        formFactor: { required },
      },
    };
  },

  data: () => ({
    conf: {},
    currentTab: 'general',
  }),

  computed: {
    ...mapGetters('REGISTRY', ['profileTemplates']),

    formFactors() {
      const ff = this.profileTemplates.formFactor;
      return Object.keys(ff).map(k => ({ name: ff[k], value: k }));
    },

    inspectionTypes() {
      const it = this.profileTemplates.inspectionType;
      return Object.keys(it).map(k => ({ name: it[k], key: k }));
    },

    stepTypes() {
      return Object.keys(this.profileTemplates.stepType);
    },

    isCreate() {
      return this.$route.name.includes('create');
    },

    title() {
      if (this.isCreate) return 'Создание профиля';
      return `Профиль ${this.singleItem ? `"${this.singleItem.key}"` : '...'}`;
    },
  },

  methods: {
    ...mapActions('ADMIN/PROFILE', [
      'createSingleItem',
      'changeSingleItem',
      'changeDefaultSingleItem',
      'deleteSingleItem',
    ]),

    async prepareComponent() {
      await this.$loadingNotify(
        this.$store.dispatch('REGISTRY/fetchProfileTemplates'),
        'fetchingProfileTemplates',
        'Произошла ошибка загрузки шаблонов профилей',
      );

      this.setInitialState();
    },

    setInitialState() {
      let r = {};

      if (this.isCreate) r = this.setEmptyState(r);
      else r = cloneDeep(this.singleItem);

      Vue.set(this, 'conf', r);
    },

    validate() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$notify({
          group: 'note',
          type: 'error',
          title: 'Неверно заполнены поля',
          text: 'Исправьте ошибки и повторите снова',
        });
        return false;
      }
      return true;
    },

    cleanPayload() {
      const payload = cloneDeep(this.conf);
      payload.scenarios = this.$refs.inspectionTypes.cleanPayload();
      // обнуление пустых полей
      Object.keys(payload).forEach(
        key => !payload[key] && (payload[key] = null),
      );
      return payload;
    },

    checkEmptiness() {
      const cleanedPayload = this.cleanPayload();
      const t = Object.values(cleanedPayload.scenarios); // just a shorting

      const someScenarioEnabled = t.some(scenario => scenario.isEnabled);
      const noEmptyScenarios = t
        .filter(scenario => scenario.isEnabled)
        .every(scenario =>
          Object.values(scenario.steps).some(step => step.status),
        );
      return someScenarioEnabled && noEmptyScenarios;
    },

    notifyEmptinessError(msg) {
      this.$notify({
        group: 'note',
        type: 'error',
        title: msg,
        text: 'Хотя бы 1 тип осмотра должен быть включен, а во включенном типе должен быть включен хотя бы 1 этап. Проверьте настройки профиля.',
      });
    },

    handleCreate() {
      const createErrMsg = 'Ошибка при создании профиля';
      if (!this.validate()) return;

      if (!this.checkEmptiness()) {
        this.notifyEmptinessError(createErrMsg);
        return;
      }

      const cleanedPayload = this.cleanPayload();

      this.$loadingNotify(
        this.createSingleItem(cleanedPayload).then(item => {
          this.$router.push({
            name: 'admin:profiles:item',
            params: {
              key: item.key,
            },
          });
        }),
        'createSingleItem',
        createErrMsg,
        'Профиль успешно создан',
      );
    },

    handleSave() {
      const saveErrMsg = 'Ошибка при изменении профиля';
      if (!this.validate()) return;

      if (!this.checkEmptiness()) {
        this.notifyEmptinessError(saveErrMsg);
        return;
      }

      const cleanedPayload = this.cleanPayload();

      this.$openModal(
        'prompt',
        {
          title: 'Изменение профиля',
          no: 'Нет',
          yes: 'Да',
          messages: {
            successfulAction: 'Профиль успешно сохранен',
            errorAction: saveErrMsg,
          },
          onSubmit: () =>
            this.conf.isDefault
              ? this.changeDefaultSingleItem(cleanedPayload)
              : this.changeSingleItem(cleanedPayload),
        },
        {},
        {
          description: () => this.$createElement(ApproveEditing),
        },
      );
    },

    handleRemove() {
      this.$openModal(() => import('@/components/crud/DeleteModal.vue'), {
        item: this.singleItem,
        onDelete: async key => {
          await this.deleteSingleItem(key);
          this.$router.push({ name: 'admin:profiles' });
        },
        messages: {
          description: 'Вы точно хотите удалить профиль?',
          successfulAction: 'Профиль удален',
          errorAction: 'Произошла ошибка удаления профиля',
        },
      });
    },

    fetchEventReport({ page, limit }) {
      const id = this.singleItem.key;
      return blameApi.getEntityReport({
        page,
        limit,
        entity: 'GATE_PROFILE',
        id,
      });
    },

    setEmptyState() {
      return {
        key: '',
        name: '',
        formFactor: '',
        scenarios: {},
        description: '',
        devicesSettings: {
          thermometerMode: 'manual',
        },
      };
    },
  },
};
</script>
