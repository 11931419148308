<template>
  <v-row :style="recommendationStyle" class="mt-0">
    <template v-if="onlyText">
      <v-col :cols="12" class="text-button pa-0">
        <span>РЕКОМЕНДАЦИЯ: {{ recommendation }}</span>
      </v-col>
    </template>

    <template v-else>
      <v-col :cols="3" class="text-button pa-0">
        <span>РЕКОМЕНДАЦИЯ: {{ recommendation }}</span>
      </v-col>
      <v-col :cols="6" class="pa-0">
        <div
          v-if="canceledInfo"
          class="text-button justify-center align-center text-center"
        >
          {{ canceledInfo }}
        </div>
      </v-col>

      <v-col :cols="3" class="d-flex py-0 justify-end align-center">
        <div v-if="!hideButton">
          <v-btn
            v-if="!assistantDisabled"
            data-sentry-mark="medcab:disableAssistance"
            text
            x-small
            @click="handleDisableAssistance"
          >
            Отключить ассистент
          </v-btn>
          <span v-if="assistantDisabled" class="text-button">
            Ассистент отключен
          </span>
        </div>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import {
  BADGE_COLORS,
  STEPS_CANCELED_TITLE,
  STEP_ERROR_TYPES,
} from '@/modules/medcab/constants';

export default {
  props: {
    assistance: {
      type: Object,
      default: () => ({}),
    },
    steps: {
      type: Array,
      default: () => [],
    },
    type: {
      type: Object,
      default: () => ({}),
    },
    onlyText: Boolean,
    hideButton: Boolean,
    assistantDisabled: Boolean,
  },

  computed: {
    recommendationStyle() {
      const assistanceResult = this.assistance.result?.value !== false;
      const interruptedInspection = this.steps.some(
        ({ result }) => result === null,
      );

      const color = this.assistantDisabled
        ? BADGE_COLORS.mute
        : assistanceResult
        ? BADGE_COLORS.success
        : interruptedInspection
        ? BADGE_COLORS.break
        : BADGE_COLORS.medical;

      return {
        color: `rgb(${color})`,
        background: `rgba(${color}, 0.1)`,
      };
    },

    recommendation() {
      const assistanceResult = this.assistance.result?.value !== false;
      const { actions = {} } = this.type.details;

      if (assistanceResult) return actions.pass || actions.validate;
      return actions.fail || actions.validate;
    },

    canceledInfo() {
      for (const item of this.steps) {
        const error = item.result?.error;
        if (error) {
          return `${STEP_ERROR_TYPES[error?.type]} на этапе ${
            STEPS_CANCELED_TITLE[item.type]?.genitive || '–'
          }`;
        }
      }
      return null;
    },
  },

  methods: {
    handleDisableAssistance() {
      this.$openModal(
        'prompt',
        {
          title: 'Отключение ассистента',
          messages: {
            successfulAction: 'Ассистент отключен',
            errorAction: 'Произошла ошибка отключения ассистента',
          },
          onSubmit: () => {
            this.$store.dispatch('MEDCAB_INSPECTION/disableAssistance');
          },
        },
        null,
        {
          description: () => (
            <div>
              Вы уверены, что хотите отключить ассистент?
              <br />
              "Полная свобода - полная ответственность!"
            </div>
          ),
        },
      );
    },
  },
};
</script>
