export const messages = {
  resourceName: 'ПАК',
  searchFilterLabel: 'Поиск по ПАК',
  searchFilterPlaceholder: '',
  create: {
    title: 'Добавление ПАК',
    actionButton: 'Добавить',
    successfulAction: 'ПАК успешно добавлен',
    errorAction: 'Произошла ошибка сохранения ПАК',
  },
};

export const entity = [
  { key: 'id', label: 'ID' },
  {
    key: 'isActive',
    label: 'Статус',
    headerProps: {
      width: '48px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
    convert: item => (item.isActive ? 'Активен' : 'Неактивен'),
  },
  { key: 'nameLicense', label: 'Название / Лицензия' },
  { key: 'org_releasePoint', label: 'Организация / Точка выпуска' },
  {
    key: 'groups',
    label: 'Группы',
    convert: item => item.groups.map(group => group.name).join(', '),
  },
  {
    key: 'isOpenpoint',
    label: 'Тип',
    convert: item => (item.isOpenpoint ? 'Открытая' : 'Закрытая'),
  },
  {
    key: 'isNotPrint',
    label: 'Печать стикера',
    convert: item => item.isNotPrint,
    headerProps: {
      width: '70px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
  },
  {
    key: 'isNotReprint',
    label: 'Повторная печать',
    convert: item => item.isNotReprint,
    headerProps: {
      width: '70px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
  },
];

export const entityEmbedded = [
  { key: 'id', label: 'ID' },
  {
    key: 'isActive',
    label: 'Статус',
    headerProps: {
      width: '48px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
  },
  { key: 'nameLicense', label: 'Название / Лицензия' },
  { key: 'org', label: 'Организация' },
  { key: 'org_releasePoint', label: 'Организация / Точка выпуска' },
  { key: 'isOpenpoint', label: 'Тип' },
  {
    key: 'isNotPrint',
    label: 'Печать стикера',
    convert: item => item.isNotPrint,
    headerProps: {
      width: '70px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
  },
  {
    key: 'isNotReprint',
    label: 'Повторная печать',
    convert: item => item.isNotReprint,
    headerProps: {
      width: '70px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
  },
];

export const filters = {
  isActive: { type: Boolean, default: true },
  isOpenpoint: { type: Boolean, default: null },
  orgIds: { type: Array, default: undefined },
  groupIds: { type: Array, default: null },
  releasePointIds: { type: Array, default: null },
  formFactor: { type: Array, default: null },
  niiatStatusRegistered: { type: Boolean, default: null },

  // fixed filter
  includeSystemGroups: { type: Boolean, default: false },
};
