<template>
  <v-alert class="ma-0" dense outlined type="info">
    <slot />
  </v-alert>
</template>

<script>
export default {
  name: 'NoDataAlert',
};
</script>

<style lang="scss" scoped></style>
