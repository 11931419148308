import medrecApi from '@/api/services/medrec';
import structApi from '@/api/services/structures';

import crudSingleFactory, {
  SINGLE_STATUSES,
} from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';
import { changePages } from '@/utils/vuex/changePages';

const state = { employee: {} };

const getters = { employee: state => state.employee };

const mutations = {
  singleFetched: (state, { document, employee }) => {
    state.singleItem = document;
    state.employee = employee;
    state.singleStatus = SINGLE_STATUSES[2];
    state.singleError = null;
  },
};

const actions = {
  fetchSingle: async ({ commit }, id) => {
    try {
      commit('singleFetching');
      const document = await medrecApi.documentOutgoingGet(id);
      const employee = await structApi.getEmployeePreview(document.employeeId);

      commit('singleFetched', { document, employee });
      commit('DOCUMENTS/OUTGOING/listUpdateItem', document, { root: true });
    } catch (error) {
      commit('singleErrorFetched', error);
      throw error;
    }
  },

  async fetchList({ commit, getters }, query) {
    await changePages(getters, commit, query);

    try {
      if (getters.listSearch?.length > 100)
        throw Error('Не более 100 символов в поле поиска');

      commit('listFetching');

      const params = { ...getters.listQuery };
      // Удаляем текстовую запись из запроса.
      // Передаются только IDs найденных работников
      delete params.employeeSearch;

      const response = await medrecApi.documentOutgoingList(params);

      // fetch employee previews to show employee names in list
      const missingIds = response.items
        .filter(item => !getters.employees[item.employeeId])
        .map(item => item.employeeId);
      const employees = missingIds.length
        ? await structApi.getEmployeePreviewsBy(missingIds)
        : [];
      commit('employees', employees);

      commit('listFetched', response);
    } catch (error) {
      commit('listErrorFetched', error);
      throw error;
    }
  },
};

export default createModule(
  crudSingleFactory({
    fetchMethod: medrecApi.documentOutgoingGet,
    linkedList: 'DOCUMENTS/OUTGOING',
  }),
  { state, getters, mutations, actions },
);
