import reportsApi from '@/api/services/reports';
import Vue from 'vue';
import { createModule } from '@/utils/vuex/createModule';
import { DateTime } from '@/plugins/luxon';

const state = {
  filters: {},
  results: [],
  expandedResults: [],
};

const getters = {
  filters: state => state.filters,
  results: state => state.results,
  isExpandedResult: state => id => state.expandedResults.includes(id),
};

const mutations = {
  filters: (state, value) => {
    state.filters = {
      ...state.filters,
      ...value,
    };
  },

  addResult: (state, data) => {
    state.results = [data, ...state.results];
  },
  clearResults: state => {
    state.results = state.results.filter(result => result.isLoading);
    state.expandedResults = [];
  },
  changeResult: (state, data) => {
    const index = state.results.findIndex(item => item.id === data.id);
    state.results = [
      ...state.results.slice(0, index),
      { ...state.results[index], ...data },
      ...state.results.slice(index + 1),
    ];
  },
  expandedResults: (state, value) => {
    state.expandedResults = value;
  },
  resetFilters: state => {
    const resetDate = state.filters.dateStart;

    Vue.set(state, 'filters', {});

    if (resetDate) {
      const previousMonth = DateTime.local()
        .minus({ month: 1 })
        .startOf('month')
        .startOf('day');

      state.filters.dateStart = previousMonth.toISO();
      state.filters.dateEnd = previousMonth.endOf('month').toISO();
    }
  },
};

const actions = {
  changeFilters({ commit }, value) {
    commit('filters', value);
  },

  resetFilters({ commit }) {
    commit('resetFilters');
  },

  async export({ commit }, { key, filters, format, type }) {
    const itemId = new Date().valueOf();
    commit('addResult', {
      id: itemId,
      reportId: key,
      isLoading: true,
      error: null,
      timestamp: new Date().valueOf(),
      format,
      data: null,
      filters,
    });

    try {
      const data = await reportsApi.execute(key, filters, format, type);
      commit('changeResult', {
        id: itemId,
        isLoading: false,
        data,
      });
      data?.result?.rows && commit('expandedResults', [itemId]);

      return data;
    } catch (error) {
      commit('changeResult', { id: itemId, isLoading: false, error });
      throw error;
    }
  },

  expandResult({ commit, state }, id) {
    commit('expandedResults', [...state.expandedResults, id]);
  },
  unexpandResult({ commit, state }, id) {
    const index = state.expandedResults.findIndex(item => item === id);
    commit('expandedResults', [
      ...state.expandedResults.slice(0, index),
      ...state.expandedResults.slice(index + 1),
    ]);
  },
  clearResults({ commit }) {
    commit('clearResults');
  },
};

export default createModule({
  state,
  getters,
  mutations,
  actions,
});
