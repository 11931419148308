import medrecApi from '@/api/services/medrec';

export default {
  state: {
    medicalPrevention: {},
  },
  getters: {
    medicalPrevention: state => state.medicalPrevention,
  },
  mutations: {
    medicalPrevention: (state, value) => (state.medicalPrevention = value),
  },
  actions: {
    async fetchMedicalPrevention({ commit, getters }) {
      const id = getters.singleItem.id;
      const medicalPrevention = await medrecApi.getEmployeeMedicalPrevention(
        id,
      );
      commit('medicalPrevention', medicalPrevention || {});
    },

    async changeMedicalPrevention({ commit }, { id, data }) {
      const medicalPrevention = await medrecApi.changeEmployeeMedicalPrevention(
        id,
        data,
      );
      commit('medicalPrevention', medicalPrevention || {});
    },
  },
};
