<template>
  <div>
    <v-alert v-if="allAvailable" class="ma-4" type="success" dense outlined>
      Роли {{ roleName }} доступны все организации
    </v-alert>
    <v-alert
      v-else-if="!allAvailable && bindingsNotSetted"
      type="error"
      dense
      outlined
      class="ma-4"
    >
      Доступы не настроены
    </v-alert>
    <template v-else>
      <Spinner v-if="!bindingsLoaded" />

      <template v-else>
        <v-simple-table dense>
          <tbody>
            <tr v-for="(label, key) in previewBindingTypes" :key="key">
              <td class="grey--text pa-4" style="width: 40%">
                {{ label }}
              </td>
              <!-- We don't want to have big padding bottom here cuz
              we have margin-bottom on chips that are in this table -->
              <td class="pa-4 pb-1">
                <BindingChip
                  v-for="itemId in bindings.objects[key]"
                  :key="itemId"
                  :value="
                    previews[key === 'med_orgs' ? 'orgs' : key][itemId] ||
                    itemId
                  "
                />
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <BlockTitle
          v-if="bindings.inspections.length"
          title="Доступ к осмотрам"
          class="pl-4"
        />
        <v-simple-table dense>
          <tbody>
            <tr v-for="(item, key) in bindings.inspections" :key="key">
              <td class="grey--text pa-4" style="width: 40%">
                {{ getInspectionParentName(item.parent) }}
              </td>
              <!-- We don't want to have big padding bottom here cuz
              we have margin-bottom on chips that are in this table -->
              <td class="pa-4 pb-1">
                <template v-if="item.employeeGroups">
                  <BindingChip
                    v-for="itemId in item.employeeGroups"
                    :key="itemId"
                    :value="previews.employee_groups[itemId]"
                  />
                </template>
                <template v-if="item.hostGroups">
                  <BindingChip
                    v-for="itemId in item.hostGroups"
                    :key="itemId"
                    :value="previews.host_groups[itemId]"
                  />
                </template>
                <template v-if="item.points">
                  <BindingChip
                    v-for="itemId in item.points"
                    :key="itemId"
                    :value="previews.points[itemId]"
                  />
                </template>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </template>
    </template>
  </div>
</template>

<script>
import BlockTitle from '@/components/ui/BlockTitle.vue';
import Spinner from '@/components/Spinner.vue';
import BindingChip from './BindingChip.vue';

// import transform from 'lodash/transform';
// import isEqual from 'lodash/isEqual';
// import isObject from 'lodash/isObject';

const BINDING_TYPES = {
  med_orgs: 'По медицинским организациям',
  org_groups: 'По группам организаций',
  orgs: 'По организациям',
  points: 'По точкам выпуска',
  employee_groups: 'По группам работников',
  host_groups: 'По группам ПАК',
};

const REQUESTS = {
  orgs: 'fetchOrganizationPreviewsBy',
  org_groups: 'fetchOrganizationGroupPreviewsBy',
  points: 'fetchPointPreviewsBy',
  employee_groups: 'fetchEmployeeGroupPreviewsBy',
  host_groups: 'fetchHostGroupPreviewsBy',
};

export default {
  components: { BindingChip, BlockTitle, Spinner },

  props: {
    // Bindings object, NOT the entire instance
    bindings: { type: Object, required: true },
    roleName: { type: String, default: '' },
    allAvailable: Boolean,
  },

  data: () => ({
    previews: {
      orgs: {},
      org_groups: {},
      points: {},
      employee_groups: {},
      host_groups: {},
    },
    bindingsLoaded: false,
  }),

  computed: {
    bindingsNotSetted() {
      const objects = Object.entries(this.bindings.objects);
      const { inspections } = this.bindings;

      if (!objects.length && !inspections.length) return true;

      return objects.some(([_, value]) => !value.length);
    },

    previewBindingTypes() {
      return Object.fromEntries(
        Object.entries(BINDING_TYPES).filter(
          ([key]) => this.bindings.objects[key],
        ),
      );
    },
  },

  watch: {
    bindings: {
      deep: true,
      handler() {
        this.fetchPreviews(REQUESTS);
      },
    },
  },

  async created() {
    // NOTE: dunno.. it looks like a crutch and it is one
    // we r fetching ALL instances that present in bindings by requesting
    // previewBy endpoints passing there all ids corresponding to that instance
    await this.fetchPreviews(REQUESTS); // fetch ALL in the beginning
  },

  methods: {
    getInspectionParentName(parent) {
      if (['org', 'med_org'].includes(parent.type))
        return this.previews.orgs[parent.id]?.name || parent;

      return this.previews.org_groups[parent.id]?.name || parent;
    },
    async fetchPreviews(reqs) {
      const { objects, inspections } = this.bindings;
      this.bindingsLoaded = false;

      const bindingIds = {
        orgs: new Set([
          ...(objects.orgs || []),
          ...(objects.med_orgs || []),
          ...inspections
            .filter(item => ['org', 'med_org'].includes(item.parent.type))
            .map(item => item.parent.id),
        ]),
        org_groups: new Set([
          ...(objects.org_groups || []),
          ...inspections
            .filter(item => item.parent.type === 'org_group')
            .map(item => item.parent.id),
        ]),
        points: new Set(
          [
            ...(objects.points || []),
            ...inspections.map(item => item.points).flat(1),
          ].filter(item => item),
        ),
        host_groups: new Set(
          [
            ...(objects.host_groups || []),
            ...inspections.map(item => item.hostGroups).flat(1),
          ].filter(item => item),
        ),
        employee_groups: new Set(
          [
            ...(objects.employee_groups || []),
            ...inspections.map(item => item.employeeGroups).flat(1),
          ].filter(item => item),
        ),
      };

      await Promise.all(
        Object.entries(reqs).map(async ([key, request]) => {
          // we send requests only if ids for structure instance present in
          // bindings object
          if (!bindingIds[key].size) return;

          const res = await this.$store.dispatch(
            `STRUCTURES/${request}`,
            Array.from(bindingIds[key]),
          );
          this.previews[key] = Object.fromEntries(
            res.map(item => [item.id, item]),
          );
        }),
      );

      this.bindingsLoaded = true;
    },

    /**
     * Deep diff between two object, using lodash
     * @param  {Object} object Object compared
     * @param  {Object} base   Object to compare with
     * @return {Object}        Return a new object who represent the diff
     */
    // difference(object, base) {
    //   function changes(object, base) {
    //     return transform(object, function (result, value, key) {
    //       if (!isEqual(value, base[key])) {
    //         result[key] =
    //           isObject(value) && isObject(base[key])
    //             ? changes(value, base[key])
    //             : value;
    //       }
    //     });
    //   }
    //   return changes(object, base);
    // },
  },
};
</script>
