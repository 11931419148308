<template>
  <v-btn
    class="big-button caption flex-grow-1 flex-shrink-1"
    height="100%"
    :max-height="maxHeight"
    color="normal"
    :to="to"
    :disabled="disabled"
    v-on="$listeners"
  >
    <div class="text-center">
      <v-icon v-if="icon" :style="{ fontSize: '20px' }">
        {{ icon }}
      </v-icon>
      <div class="mt-3">
        {{ text }}
      </div>
    </div>
  </v-btn>
</template>

<script>
export default {
  props: {
    icon: { type: String, default: null },
    text: { type: String, default: null },
    maxHeight: { type: String, default: null },
    to: { type: [String, Object], default: null },
    disabled: { type: Boolean, default: null },
  },
};
</script>
<style lang="scss" scoped>
.big-button::v-deep .v-btn__content {
  max-width: 100%;
  white-space: normal;
}
</style>
