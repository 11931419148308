<template>
  <ModernModalLayout
    size="large"
    title="Создать приложение"
    dense-content
    :watch-for="account"
  >
    <BlockTitle title="Аккаунт" />
    <v-form class="pa-6">
      <v-row>
        <v-col cols="6">
          <v-select
            v-model="account.roleKey"
            label="Роль"
            item-value="key"
            item-text="name"
            :item-disabled="item => !item.canBeSet"
            outlined
            hide-details="auto"
            :menu-props="{ offsetY: true }"
            :items="accountRolesApplication"
            :disabled="$wait('submit') || $wait('loadingRoles')"
            :error-messages="getValidationErrors('account.roleKey')"
            @blur="validateField('account.roleKey')"
          />
        </v-col>
      </v-row>
    </v-form>

    <BlockTitle title="Профиль" />
    <ProfileFields
      ref="profile"
      v-model="account.profile"
      class="pa-6"
      :is-loading="$wait('submit')"
    />

    <PushMethodFields
      v-if="isWaybillOperator"
      ref="pushMethod"
      v-model="account.waybillOperatorSettings"
      :is-waybill-operator="isWaybillOperator"
      class="pa-6"
      :is-loading="$wait('submit')"
    />

    <BlockTitle title="Доступы" />
    <v-form class="pa-6">
      <Spinner v-if="$wait('loadingAccount')" />
      <AccountBindings
        v-else
        :value="account.bindings"
        @input="handleChangeBindings"
      />
    </v-form>

    <template #actions:append>
      <v-btn
        depressed
        color="primary"
        :loading="$wait('submit')"
        @click="submit"
      >
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';

import Spinner from '@/components/Spinner.vue';
import BlockTitle from '@/components/ui/BlockTitle.vue';
import ModernModalLayout from '@/components/layouts/ModernModalLayout.vue';
import AccountBindings from '@/components/bindings/AccountBindings.component.vue';
import ProfileFields from '@/modules/admin/applications/components/ProfileFields.vue';
import PushMethodFields from '@/modules/admin/applications/components/PushMethodFields.vue';

export default {
  components: {
    Spinner,
    BlockTitle,
    ModernModalLayout,
    AccountBindings,
    ProfileFields,
    PushMethodFields,
  },
  mixins: [validation, waitable],

  data: () => ({
    account: {
      roleKey: null,
      profile: {
        type: 'app',
      },
      waybillOperatorSettings: {
        pushMethod: false,
        t2Callback: '',
        t6Callback: '',
      },
      bindings: {
        // dynamic changes
        objects: {},
        inspections: [],
      },
    },
  }),

  validations: {
    account: {
      roleKey: { required },
    },
  },

  computed: {
    ...mapGetters('REGISTRY', ['accountRolesApplication']),
    isWaybillOperator() {
      return (
        this.accountRolesApplication?.find(
          role => role.key === this.account.roleKey,
        )?.typeKey === 'waybillOperator'
      );
    },
  },

  async created() {
    this.$can('RL_RP') &&
      this.$loadingNotify(
        this.$store.dispatch('REGISTRY/fetchAccountRolesApplication'),
        'loadingRoles',
        'Ошибка загрузки ролей',
      );
  },

  methods: {
    ...mapActions('ADMIN/APPLICATIONS', ['createItem']),
    ...mapActions('ADMIN/APPLICATIONS_ITEM', ['changeBindings']),

    async submit() {
      // push method form ref can be undefined if chosen role is not of type epl
      // so we need to check whether there is available validation
      const validationResults = Object.keys(this.$refs).map(ref =>
        this.$refs[ref] ? !this.$refs[ref].validate() : false,
      );

      const localIsNotValid = !this.validate();
      if (validationResults.some(result => result) || localIsNotValid) return;

      const preparedResponse = { ...this.account };
      // Если есть добавленные доступы к осмотрам с незаполненными данными, удаляем их
      if (preparedResponse.bindings.inspections?.length) {
        preparedResponse.bindings.inspections =
          preparedResponse.bindings.inspections.filter(
            inspection => inspection.parent?.id,
          );
      }

      await this.$loadingNotify(
        this.createItem(preparedResponse),
        'submit',
        'Произошла ошибка создания приложения',
        'Приложение успешно создано',
      )
        .then(() => this.$emit('close'))
        .catch(err => this.catchServerErrors(err, 'account'));
    },

    handleChangeBindings(value) {
      this.account.bindings = value;
    },
  },
};
</script>
