<template>
  <AsyncWrapper :handler="fetchShifts">
    <NoDataAlert v-if="!shiftGroups.length" class="ma-4">
      Медработник еще не выходил на смену
    </NoDataAlert>

    <ColumnLayout
      v-else
      grid-template-columns="30% 1fr"
      class="medic-stats__container"
      :classes-by-slots="{
        'col-shifts': ['overscroll-behavior'],
        'col-details': ['overscroll-behavior'],
      }"
    >
      <template #col-shifts>
        <MedicStatsList
          v-model="selectedShiftId"
          :items="shiftGroups"
          :fetch="fetchShifts"
          :is-loading="loadingShifts"
        />
      </template>

      <template #col-details>
        <MedicStatsShift :my="my" :value="selectedShiftId" />
      </template>
    </ColumnLayout>
  </AsyncWrapper>
</template>

<script>
import processingApi from '@/api/services/processing';
import { date } from '@/utils/convert';

import AsyncWrapper from '@/components/AsyncWrapper.vue';
import ColumnLayout from '@/components/layouts/ColumnLayout.vue';
import MedicStatsList from './MedicStatsList.vue';
import MedicStatsShift from './MedicStatsShift.vue';
import NoDataAlert from '@/components/ui/NoDataAlert';

export default {
  components: {
    AsyncWrapper,
    ColumnLayout,
    MedicStatsList,
    MedicStatsShift,
    NoDataAlert,
  },
  props: {
    medicId: {
      type: Number,
      default: null,
    },
    // Получить смены текущего пользователя-медика
    my: Boolean,
  },

  data: () => ({
    selectedShiftId: null,
    shiftGroups: [],
    loadingShifts: false,
    page: 1,
    total: null,
  }),

  computed: {
    allLoaded() {
      // first load
      if (this.total === null) return false;

      return this.shiftGroups.length === this.total;
    },
  },

  methods: {
    isOneDay(start, finish) {
      return date(start) === date(finish);
    },

    handleFetch(params) {
      if (this.my) return processingApi.getMyStats(params);
      else return processingApi.getStatsMedic(this.medicId, params);
    },

    async fetchShifts() {
      if (this.loadingShifts || this.allLoaded) return;

      this.loadingShifts = true;
      const data = await this.handleFetch({
        page: this.page++,
        limit: 10,
      });

      // selected first element
      if (!this.shiftGroups.length && data.items.length)
        this.selectedShiftId = data.items[0].id;

      this.shiftGroups.splice(this.shiftGroups.length, 0, ...data.items);
      this.total = data.total;

      this.loadingShifts = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.medic-stats__container {
  height: calc(100vh - 300px);
  min-height: 400px;
}
</style>
