import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import POINTS from './store/list';
import POINTS_ITEM from './store/item';
import POINTS_ITEM_HOSTS_LIST from './store/embeddedHostsList';
import RouteGuard from '@/components/RouteGuard';

export default {
  namespaced: true,
  store: {
    POINTS,
    POINTS_ITEM,
    POINTS_ITEM_HOSTS_LIST,
  },
  modals: [
    {
      name: 'pointsForm',
      component: () => import('./modals/Form.vue'),
    },
  ],
  routes: [
    {
      path: 'points',
      name: 'points',
      component: () => import('./view/list.vue'),
      meta: {
        title: 'Все точки выпуска',
        action: 'PNT_R',
      },
    },
    {
      path: 'points/:id',
      name: 'points:item',
      component: RouteGuard({
        storeModule: 'STRUCTURES/POINTS_ITEM',
        component: () => import('./view/item.vue'),
        needForceUpdate: true,
      }),
      meta: {
        title: 'Страница точки выпуска',
        action: 'PNT_RD',
        // otherwise when updating embedded lists on tab items
        // the page will jump up
        disableScrollRestoring: true,
      },
    },
  ],
  menu: [
    {
      title: 'Точки выпуска',
      action: 'PNT_R',
      name: 'points',
      priority: 7,
    },
  ],
} as ModuleDefinition;
