import { ModuleDefinition } from '@/models/app/ModuleDefinition';

export default {
  hasChildren: true,
  menu: [
    {
      title: 'Уведомления',
      name: 'notifications',
    },
  ],
  routes: [
    {
      path: '/notifications',
      name: 'notifications',
      component: () => import('@/views/Provider.vue'),
    },
  ],
} as ModuleDefinition;
