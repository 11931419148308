<template>
  <v-card class="pa-3" min-width="150" height="100%">
    <div class="text-caption">{{ label }}</div>
    <div class="text-h4">{{ score }}</div>
  </v-card>
</template>

<script>
export default {
  props: {
    score: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: null,
    },
  },
};
</script>

<style></style>
