import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import RouteGuard from '@/components/RouteGuard';
import ORGANIZATION_LIST from './store/list';
import ORGANIZATION_ITEM from './store/item';
import EMBEDDED_EMPLOYEE_LIST from './store/embeddedEmployeeList';
import EMBEDDED_EMPLOYEE_GROUPS_LIST from './store/embeddedEmployeeGroupsList';
import EMBEDDED_POINTS_LIST from './store/embeddedPointsList';
import EMBEDDED_HOST_GROUPS_LIST from './store/embeddedHostGroupsList';
import LIMITS from './store/limits';

export default {
  namespaced: true,
  store: {
    ORGANIZATION_LIST,
    ORGANIZATION_ITEM,
    EMBEDDED_EMPLOYEE_LIST,
    EMBEDDED_EMPLOYEE_GROUPS_LIST,
    EMBEDDED_POINTS_LIST,
    EMBEDDED_HOST_GROUPS_LIST,
    LIMITS,
  },
  routes: [
    {
      path: 'organizations',
      name: 'organizations',
      component: () => import('./view/list.vue'),
      meta: {
        title: 'Все организации',
        action: 'ORG_R',
      },
    },
    {
      path: 'organizations/:id',
      name: 'organizations:item',
      component: RouteGuard({
        storeModule: 'STRUCTURES/ORGANIZATION_ITEM',
        component: () => import('./view/item.vue'),
      }),
      meta: {
        action: 'ORG_RD',
      },
    },
  ],
  menu: [
    {
      title: 'Организации',
      action: 'ORG_R',
      name: 'organizations',
      priority: 1,
    },
  ],
  modals: [
    {
      name: 'organizationsGuests',
      component: () =>
        import(
          '@/modules/structures/organizations/modals/OrganizationGuests.vue'
        ),
    },
    {
      name: 'organizationForm',
      component: () =>
        import('@/modules/structures/organizations/modals/Form.vue'),
    },
    {
      name: 'organizationChangeCategory',
      component: () =>
        import('@/modules/structures/organizations/modals/ChangeCategory.vue'),
    },
    {
      name: 'organizationBoundaries',
      component: () =>
        import(
          '@/modules/structures/organizations/modals/OrganizationBoundaries.vue'
        ),
    },
    {
      name: 'noShowTime',
      component: () =>
        import('@/modules/structures/organizations/modals/NoShowTime.vue'),
    },
    {
      name: 'editSurvey',
      component: () =>
        import('@/modules/structures/organizations/modals/EditSurvey.vue'),
    },
    {
      name: 'uploadAgreementFile',
      component: () =>
        import(
          '@/modules/structures/organizations/modals/UploadAgreementFileModal.vue'
        ),
    },
  ],
} as ModuleDefinition;
