<template>
  <div
    :class="{ 'd-flex': true, 'flex-column': !twoColumns }"
    style="gap: 15px"
  >
    <div class="text-center">
      <img src="@/assets/images/bindings-help.png" width="373" height="492" />
    </div>
    <div>
      <p>
        Доступы являются иерархическими. Это означает, что имея доступ к какому
        либо объекту, пользователь имеет доступ ко всем его "дочерним" объектам.
      </p>
      Например:
      <ul>
        <li>доступ к точке выпуска даёт доступ ко всем ПАК на ней</li>
        <li>
          доступ к организации даёт доступ ко всем её работникам, группам
          работников, группам ПАК, точками и самим ПАК.
        </li>
        <li>
          доступ к медицинской организации даёт доступ ко всем организациям,
          которые она обслуживает и их "дочерним" объектам
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    twoColumns: Boolean,
  },
};
</script>
