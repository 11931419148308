<template>
  <List
    :available-actions="{
      showAction: true,
      createAction: $can('MEDG_C'),
    }"
    @item:create="formModal"
  >
    <template #filters="query">
      <v-col cols="12" sm="4" md="3">
        <OrganizationSelect v-model="query.medOrgIds" medical dense multiple />
      </v-col>
    </template>
  </List>
</template>

<script>
import waitable from '@/utils/mixins/waitable';
import ListFactory from '@/components/crud/ListFactory';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';
import { messages, entity, filters } from '../entity';

const List = ListFactory({
  storeModule: 'MEDBLOCK/MEDIC_GROUPS',
  messages,
  entity,
  filters,
  viewRoute: 'medblock:medic_groups:item',
});

export default {
  components: { List, OrganizationSelect },
  mixins: [waitable],

  methods: {
    formModal() {
      this.$openModal('medblock/medicGroupForm');
    },
  },
};
</script>
