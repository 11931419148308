<template>
  <ModernModalLayout size="medium" title="Изменить дату проведения осмотра">
    <v-form :disabled="$wait('submit')">
      <v-alert v-if="isError" type="error" outlined dense>
        Укажите периодичность осмотра в карточке работника
      </v-alert>
      <DatePicker
        v-model="newDate"
        label="Дата проведения осмотра"
        hide-details="auto"
        outlined
        :error-messages="getValidationErrors('newDate')"
        @blur="handleFieldBlur('newDate')"
      />
    </v-form>
    <template #actions:append>
      <v-btn
        class="px-16"
        depressed
        color="primary"
        :loading="$wait('submit')"
        :disabled="$wait('fetchExtras') || isError"
        @click="handleSubmit"
      >
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import { DateTime } from '@/plugins/luxon';
import ModernModalLayout from '@/components/layouts/ModernModalLayout.vue';
import DatePicker from '@/components/controls/DatePicker.vue';
import waitable from '@/utils/mixins/waitable';
import validation from '@/utils/validation';
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'MedicalPrevention',
  components: {
    ModernModalLayout,
    DatePicker,
  },
  mixins: [waitable, validation],
  props: {
    value: { type: String, default: null },
    employeeId: { type: Number, required: true },
  },
  data() {
    return {
      newDate: null,
      isError: false,
    };
  },
  validations() {
    return {
      newDate: { required },
    };
  },
  computed: {
    ...mapGetters('EMPLOYEE', ['extras']),
    inspectionTakePeriodMonth() {
      return this.extras?.inspectionTakePeriodMonth || null;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.newDate = this.value;
      },
    },
    employeeId: {
      immediate: true,
      async handler() {
        this.isError = false;
        await this.$loadingNotify(
          this.fetchExtras(this.employeeId),
          'fetchExtras',
          'Произошла ошибка загрузки переодичности осмотров',
        );
        if (!this.inspectionTakePeriodMonth) {
          this.isError = true;
        }
      },
    },
  },
  methods: {
    ...mapActions('EMPLOYEE', ['changeMedicalPrevention', 'fetchExtras']),
    async handleSubmit() {
      if (!this.validate()) return;

      const inspectionCheckupExpiredAt = DateTime.fromISO(this.newDate)
        .plus({
          month: this.inspectionTakePeriodMonth,
        })
        .toISODate();

      await this.$loadingNotify(
        this.changeMedicalPrevention({
          id: this.employeeId,
          data: {
            inspectionCheckupTakenAt: this.newDate,
            inspectionCheckupExpiredAt,
          },
        }),
        'submit',
        'Произошла ошибка изменения даты проведения осмотра',
        'Дата проведения осмотра успешно изменена',
      ).then(() => this.$emit('close'));
    },
  },
};
</script>
