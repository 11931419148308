import env from '@/plugins/env';
import { createHTTP } from '../api';

export const ratings = createHTTP(env.get('VUE_APP_RATINGS_API') + '/v3');

export default {
  // evaluations
  getEvaluations() {
    return ratings.get(`/evaluations`).then(res => res.data);
  },
  patchEvaluations(id, payload) {
    return ratings.patch(`/evaluations/${id}`, payload).then(res => res.data);
  },

  // raitings
  getRating(id) {
    return ratings.get(`/ratings/${id}`).then(res => res.data);
  },
  putRating(id) {
    return ratings.put(`/ratings/${id}`).then(res => res.data);
  },
  deleteRating(id) {
    return ratings.delete(`/ratings/${id}`).then(res => res.data);
  },
};
