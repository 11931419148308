<template>
  <List
    :available-actions="{
      showAction: $can('ORC_RD'),
      createAction: $can('ORGC_C'),
    }"
    @item:create="formModal"
  >
    <template #filters="query">
      <v-col cols="12" sm="4" md="9">
        <OrganizationSelect v-model="query.medOrgIds" medical dense multiple />
      </v-col>
    </template>

    <template #table.item.name_belongsTo="{ item }">
      <b>{{ item.name }}</b>
      <br />
      {{ item.belongsTo.name }}
    </template>
  </List>
</template>

<script>
import ListFactory from '@/components/crud/ListFactory';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';
import { messages, entity, filters } from '../entity';

const List = ListFactory({
  storeModule: 'STRUCTURES/ORGANIZATION_CATEGORIES',
  messages,
  entity,
  filters,
  viewRoute: 'structures:organization_categories:item',
});

export default {
  components: { List, OrganizationSelect },
  methods: {
    formModal() {
      this.$openModal('structures/orgCategoryForm');
    },
  },
};
</script>
