<template>
  <div
    class="d-flex justify-center flex-column align-center"
    :class="{ 'my-5': !disableDefaultMargin }"
  >
    <v-progress-circular
      indeterminate
      :size="size"
      :width="lineWidth"
      :color="color"
    />
    <div v-if="title" class="mt-3" :class="`${color}--text`">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    lineWidth: {
      type: [String, Number],
      default: 2,
    },
    size: {
      type: [String, Number],
      default: 60,
    },
    title: {
      type: String,
      default: 'Загрузка',
    },
    disableDefaultMargin: Boolean,
  },
};
</script>
