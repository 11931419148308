<template>
  <AsyncSelect
    v-bind="computedProps"
    :item-text="fullname"
    v-on="{ ...$listeners, input: handleInput }"
  />
</template>

<script>
import { name as fullname } from '@/utils/convert';

import asyncSelectMixin from '@/components/controls/asyncSelectMixin';

import { mapActions } from 'vuex';
export default {
  extends: asyncSelectMixin,

  props: {
    title: { type: String, default: null },
    errorMessages: { type: Array, default: null },
  },

  computed: {
    label() {
      if (this.title) return this.title;
      return this.multiple ? 'Пользователи' : 'Пользователь';
    },
    query() {
      return { isEnabled: true };
    },
    computedValue() {
      if (!this.value) return null;
      return this.value.map(e => e.accountId).filter(e => e);
    },
  },

  methods: {
    ...mapActions('AUTH_PREVIEWS', {
      fetcher: 'fetchAccountPreviews',
      fetcherBy: 'fetchAccountPreviewsBy',
    }),

    fullname(item) {
      return fullname(item.profile);
    },
    handleInput(event) {
      this.$emit('input', [
        ...event.map(e => ({
          channels: ['email'],
          accountId: e,
        })),
      ]);
    },
  },
};
</script>
