export default function subscribePlugin(store) {
  const callbacks = new Map();

  store.$unsubscribeAll = function () {
    callbacks.clear();
  };

  store.$subscribeOn = function (mutationName, callback) {
    if (callbacks.has(mutationName)) callbacks.get(mutationName).push(callback);
    else callbacks.set(mutationName, [callback]);
  };

  store.subscribe((mutation, state) => {
    if (callbacks.has(mutation.type)) {
      callbacks
        .get(mutation.type)
        .forEach(callback => callback(state, mutation));
    }
  });
}
