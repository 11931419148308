<template>
  <ModernModalLayout title="Протестировать задачу" size="large">
    <AccountSelect
      v-model="testReceivers"
      multiple
      class="mb-4"
      title="Тестовые получатели"
      hide-details="auto"
      :error-messages="getValidationErrors('testReceivers')"
      @blur="validateField('testReceivers')"
    />

    <v-divider v-if="$wait('submitting') || result" class="mb-3" />

    <Spinner v-if="$wait('submitting')" />
    <template #actions:append>
      <v-btn color="primary" depressed @click="onSubmit"> Выполнить </v-btn>
    </template>
    <template v-if="result">
      <ExecutionTimeline :value="result" />
      <code v-if="result.result" style="display: block" class="mt-4">
        {{ result.result }}
      </code>
    </template>
  </ModernModalLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';

import Spinner from '@/components/Spinner.vue';
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import AccountSelect from '@/components/controls/structures/AccountSelect';
import ExecutionTimeline from '@/modules/scheduler/executions/components/ExecutionTimeline';

export default {
  components: {
    ModernModalLayout,
    Spinner,
    ExecutionTimeline,
    AccountSelect,
  },
  mixins: [validation, waitable],

  props: {
    value: { required: true, type: Object },
  },

  validations() {
    return {
      testReceivers: { required },
    };
  },

  data: () => ({
    testReceivers: [],
    result: null,
  }),

  computed: {
    ...mapGetters('AUTH', ['currentAccount']),
  },

  mounted() {
    if (this.currentAccount?.id) this.testReceivers = [this.currentAccount?.id];
  },

  methods: {
    ...mapActions('SCHEDULER/JOB_ITEM', ['testJob']),

    onSubmit() {
      if (!this.validate()) return;
      this.result = null;

      const data = {
        ...this.value,
        params: {
          ...this.value.params,
          receivers: this.testReceivers,
        },
      };

      this.$loadingNotify(
        this.testJob(data).then(result => (this.result = result)),
        'submitting',
        'Произошла ошибка выполнения задачи',
        'Задача успешно выполнена',
      );
    },
  },
};
</script>
