import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import RouteGuard from '@/components/RouteGuard';
import HOSTS from './store/list';
import HOSTS_ITEM from './store/item';

export default {
  namespaced: true,
  store: { HOSTS, HOSTS_ITEM },
  modals: [
    {
      name: 'hostForm',
      component: () => import('@/modules/structures/hosts/modals/Form.vue'),
    },
    {
      name: 'approveChanges',
      component: () => import('@/modules/structures/hosts/modals/Approve.vue'),
    },
  ],
  routes: [
    {
      path: 'hosts',
      name: 'hosts',
      component: () => import('./view/list.vue'),
      meta: {
        title: 'Все ПАК',
        action: 'HST_R',
      },
    },
    {
      path: 'hosts/:id',
      name: 'hosts:item',
      component: RouteGuard({
        storeModule: 'STRUCTURES/HOSTS_ITEM',
        component: () => import('./view/item.vue'),
      }),
      meta: {
        title: 'Страница ПАК',
        action: 'HST_RD',
      },
    },
  ],
  menu: [{ title: 'ПАК', action: 'HST_R', name: 'hosts', priority: 5 }],
} as ModuleDefinition;
