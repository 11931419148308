<script>
import { Line } from 'vue-chartjs';
import { dateTime } from '@/utils/convert';
import { getProperty } from '@/utils/helpers';

export default {
  extends: Line,
  props: {
    labels: {
      type: [Object, Array],
      default: () => ({
        pressures: [
          {
            upperBound: 'Верхняя граница систолического давления',
            value: 'Систолическое давление', // Верхнее
            lowerBound: 'Нижняя граница систолического давления',
          },
          {
            upperBound: 'Верхняя граница диастолического давления',
            value: 'Диастолическое давление', // Нижнее
            lowerBound: 'Нижняя граница диастолического давления',
          },
        ],
        pulse: {
          upperBound: 'Верхняя граница пульса',
          value: 'Пульс',
          lowerBound: 'Нижняя граница пульса',
        },
      }),
    },
    value: {
      type: [Object, Array],
      default: null,
    },
    type: {
      type: String,
      default: 'pressure',
    },
  },
  data: () => ({
    datasets: [],
    min: Infinity,
    max: 0,
    xLabels: new Set(),
    colors: ['#A393F5', '#28BC00'],
  }),
  mounted() {
    this.convertValueToDataset(this.value);
    this.renderChart({ datasets: this.datasets }, this.generateOptions());
  },

  methods: {
    convertValueToDataset(item) {
      if (this.type === 'pressure') {
        ['systolic', 'diastolic'].map((val, index) => {
          this.datasets.push({
            data: this.processData(item, `pressure.${val}.upper`),
            label: this.labels.pressures[index].upperBound,
            hiddenOnLegend: true,
            borderColor: this.colors[index],
            borderDash: [10, 2],
            borderWidth: 1,
            pointRadius: 1,
            fill: false,
            steppedLine: true,
          });
          this.datasets.push({
            data: this.processData(item, `pressure.${val}.actual`),
            label: this.labels.pressures[index].value,
            borderColor: this.colors[index],
            fill: false,
            lineTension: 0,
            borderWidth: 3,
            pointRadius: 2,
          });
          this.datasets.push({
            data: this.processData(item, `pressure.${val}.lower`),
            label: this.labels.pressures[index].lowerBound,
            hiddenOnLegend: true,
            borderColor: this.colors[index],
            borderDash: [10, 2],
            borderWidth: 1,
            pointRadius: 1,
            fill: false,
            steppedLine: true,
          });
        });
      } else {
        this.datasets.push({
          data: this.processData(item, `pulse.upper`),
          label: this.labels.pulse.upperBound,
          hiddenOnLegend: true,
          borderColor: this.colors[0],
          borderDash: [10, 2],
          borderWidth: 1,
          pointRadius: 1,
          fill: false,
          steppedLine: true,
        });
        this.datasets.push({
          data: this.processData(item, `pulse.actual`),
          label: this.labels.pulse.value,
          borderColor: this.colors[0],
          fill: false,
          lineTension: 0,
          borderWidth: 3,
          pointRadius: 2,
        });
        this.datasets.push({
          data: this.processData(item, `pulse.lower`),
          label: this.labels.pulse.lowerBound,
          hiddenOnLegend: true,
          borderColor: this.colors[0],
          borderDash: [10, 2],
          borderWidth: 1,
          pointRadius: 1,
          fill: false,
          steppedLine: true,
        });
      }
    },
    processData(data, key) {
      if (!data) return [];
      return data.map(value => {
        if (getProperty(key, value) && this.min > getProperty(key, value))
          this.min = Math.round(getProperty(key, value) / 10) * 10;
        if (getProperty(key, value) && this.max < getProperty(key, value))
          this.max = Math.round(getProperty(key, value) / 10) * 10;
        return { x: value.date, y: getProperty(key, value) };
      });
    },
    generateOptions() {
      const min = this.min - 20;
      const max = this.max + 20;
      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index',
          callbacks: {
            title: tooltipItem => {
              return dateTime(tooltipItem[0].xLabel);
            },
          },
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'series',
              time: {
                displayFormats: {
                  day: 'D.MM.YYYY',
                },
                minUnit: 'day',
              },
            },
          ],
          yAxes: [{ ticks: { min, max } }],
        },
        legend: {
          position: 'bottom',
          labels: {
            filter: ({ datasetIndex }, { datasets }) =>
              datasets[datasetIndex].hiddenOnLegend !== true,
          },
        },
      };
    },
  },
};
</script>
