<template>
  <Card v-if="$can('EBN_R')" title="Блокировки" dense>
    <Spinner v-if="isLoading" />
    <ErrorBlock
      v-else-if="fetchError"
      class="mx-4 mt-4"
      :error="fetchError"
      @refresh="fetchBans(employee.id)"
    />
    <v-data-table
      v-else
      loading-text=""
      item-key="id"
      no-data-text="Блокировки отсутствуют"
      dense
      :headers="headers"
      :options="options"
      :items="bans"
      :item-class="getClassesForItem"
    >
      <template #item.inspectionId="{ item }">
        <v-btn
          class="pa-0"
          text
          small
          color="primary"
          target="_blank"
          :to="{ name: 'inspections:card', params: { id: item.inspectionId } }"
        >
          #{{ item.inspectionId }}
        </v-btn>
      </template>
      <template #item.banScenario.scenarioType="{ item }">
        <span>{{ scenarioType(item.banScenario.scenarioType) }}</span>
      </template>
      <template #item.issuedAt="{ item }">
        <span>{{ dateTime(item.issuedAt) }}</span>
        <span v-if="getMedic(item.medicId)">
          Заблокировал: {{ getMedic(item.medicId).name }}
        </span>
      </template>
      <template #item.dueDate="{ item }">
        <template v-if="item.unban">
          <span>{{ dateTime(item.unban.date) }}</span>
          <span v-if="getMedic(item.unban.issuerId)">
            Разблокировал: {{ getMedic(item.unban.issuerId).name }}
          </span>
        </template>
        <span v-else>{{ item.dueDate ? dateTime(item.dueDate) : '–' }}</span>
      </template>
      <template #item.reason="{ item }">
        <span>{{ item.banScenario.reason.description }}</span>
      </template>
      <template #item.state="{ item }">
        <span>{{ status(item) }}</span>
      </template>
      <template v-if="$can('UNBN_F') || $can('BAN_D')" #item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-tooltip left>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                icon
                :disabled="!isUnbanned(item)"
                v-on="on"
                @click="unbanModal(item)"
              >
                <v-icon small>fa-user-check</v-icon>
              </v-btn>
            </template>
            <span>Разблокировать</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </Card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import processingApi from '@/api/services/processing';
import { parseDate } from '@/utils/helpers';
import { dateTime, duration, shortName } from '@/utils/convert';
import waitable from '@/utils/mixins/waitable';

import Spinner from '@/components/Spinner';
import Card from '@/components/ui/Card';
import ErrorBlock from '@/components/ErrorBlock';

const SCENARIO_TYPES = {
  INSPECTION: 'Осмотр',
  ABSENCE: 'Неявка',
};
export default {
  components: {
    ErrorBlock,
    Spinner,
    Card,
  },
  mixins: [waitable],

  props: {
    employee: { type: Object, required: true },
  },

  data() {
    return {
      headers: [
        {
          text: 'Осмотр',
          value: 'inspectionId',
          sortable: false,
          width: 100,
        },
        {
          text: 'Тип сценария',
          value: 'banScenario.scenarioType',
          sortable: false,
          width: 90,
        },
        {
          text: 'Дата блокировки',
          width: 250,
          value: 'issuedAt',
          sortable: false,
        },
        {
          text: 'Дата разблокировки',
          width: 250,
          value: 'dueDate',
          sortable: false,
        },
        { text: 'Причина', value: 'reason', sortable: false },
        { text: 'Состояние', value: 'state', sortable: false },
        (this.$can('UNBN_F') || this.$can('BAN_D')) && {
          text: 'Действия',
          value: 'actions',
          sortable: false,
        },
      ],
      options: {
        itemsPerPage: 10,
      },

      medics: null,

      fetchError: null,
    };
  },

  computed: {
    ...mapGetters('EMPLOYEE', ['bans']),

    medicIds() {
      const ids = new Set();
      for (const item of this.bans) {
        if (item.medicId) ids.add(item.medicId);
        if (item.unban?.issuerId) ids.add(item.unban.issuerId);
      }
      return [...ids];
    },

    getMedic() {
      return id => this.medics?.find(med => med.id === id);
    },

    isLoading() {
      return this.$wait('fetchBans') || this.$wait('fetchMedicsById');
    },
  },

  async created() {
    await this.fetchBans(this.employee.id);
    if (this.medicIds.length) await this.fetchMedicsById(this.medicIds);
  },

  methods: {
    ...mapActions('EMPLOYEE', ['fetchEmployeeBans']),
    shortName,
    dateTime,
    scenarioType(type) {
      return SCENARIO_TYPES[type] || '-';
    },
    status(item) {
      if (!item.isActive) return 'Разблокирован';
      else if (!item.dueDate) return 'Бессрочно';

      if (new Date().getTime() / 1000 > parseDate(item.dueDate).toSeconds())
        return 'Истёк';

      return 'Истекает через ' + duration(new Date(), item.dueDate);
    },

    unbanModal(item) {
      this.$openModal('employees/unban', {
        value: item,
        employee: this.employee,
      });
    },

    getClassesForItem(item) {
      return item.isActive ? '' : 'text--disabled bans__item--not-active';
    },

    isUnbanned(item) {
      return item.isActive && item.banScenario.unbanCondition !== 'no_unban';
    },

    async fetchBans(id) {
      try {
        this.fetchError = null;
        await this.$loading(this.fetchEmployeeBans(id), 'fetchBans');
      } catch (error) {
        this.fetchError = error;
      }
    },

    async fetchMedicsById(ids) {
      return this.$loading(
        processingApi.getMedicsPreviewsBy(ids).then(list => {
          this.medics = Object.freeze(list);
        }),
        'fetchMedicsById',
      );
    },
  },
};
</script>

<style scoped>
.bans__item--not-active:hover {
  background: transparent !important;
}
</style>
