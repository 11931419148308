import processingApi from '@/api/services/processing';
import { debug } from '@/utils/helpers';
import { createModule } from '@/utils/vuex/createModule';
const timeLog = debug('TIME', '#00bcd4');

const FIRST_PING_DELAY = 5_000;
const PING_TIMEOUT_DELAY = 20_000;
const PING_RETRY_COUNT = 10;

const state = {
  isFetching: false,

  client: null,
  server: null,
  ping: 0,
  error: null,

  retryCount: 0,
  pingTimeoutId: null,
};

const getters = {
  isFetching: state => state.isFetching,

  client: state => state.client || null,
  server: state => state.server || null,
  ping: state => state.ping || null,
  pingInSec: state => (state.ping ? Math.ceil(state.ping / 1000) : null),
  error: state => state.error,

  retryCount: state => state.retryCount,
  pingTimeoutId: state => state.pingTimeoutId,
  pingMaxRetryAttemptsExceeded: state => state.retryCount >= PING_RETRY_COUNT,
};

const mutations = {
  isFetching: (state, value) => (state.isFetching = value),

  setTime: (state, { client, server, ping }) => {
    state.client = client;
    state.server = server;
    state.ping = ping;
  },

  error: (state, err) => (state.error = err),
  retryCount: (state, count) => (state.retryCount = count),
  pingTimeoutId: (state, id) => (state.pingTimeoutId = id),
};

const actions = {
  async fetchTime({ getters, commit }) {
    // Если запрос выполняется в этот момент, то прервать повторный запуск цикла
    if (getters.isFetching) return;

    commit('isFetching', true);
    try {
      const { client, server, ping } = await processingApi.time();
      commit('setTime', {
        client,
        server,
        ping,
      });
    } catch (err) {
      commit('error', err);
      throw err;
    } finally {
      commit('isFetching', false);
    }
  },

  async pingTick({ getters, dispatch, commit }) {
    const { retryCount } = getters;
    try {
      await dispatch('fetchTime');
      if (retryCount) commit('retryCount', 0);
    } catch (err) {
      timeLog(`Tick Error. Attempt #${retryCount + 1}: `, err?.message || err);
      commit('retryCount', retryCount + 1);
    }

    // При превышении лимита на количество ошибок - остановить цикл
    if (getters.pingMaxRetryAttemptsExceeded) {
      dispatch(
        'stopPing',
        `Stopping after ${PING_RETRY_COUNT} failed attempts`,
      );
      return;
    }

    commit(
      'pingTimeoutId',
      setTimeout(() => dispatch('pingTick'), PING_TIMEOUT_DELAY),
    );
  },

  startPing({ getters, dispatch, commit }) {
    if (getters.pingTimeoutId) {
      dispatch('stopPing');
      dispatch('startPing');
    } else {
      commit(
        'pingTimeoutId',
        setTimeout(() => dispatch('pingTick'), FIRST_PING_DELAY),
      );
      timeLog('Cycle started');
    }
  },

  stopPing({ getters, commit }, message) {
    // Если цикл уже остановлен, то прервать выполнение метода
    if (!getters.pingTimeoutId) return;

    clearInterval(getters.pingTimeoutId);
    commit('pingTimeoutId', null);

    timeLog(message || 'Cycle stopped');
  },
};

export default createModule({
  state,
  getters,
  mutations,
  actions,
});
