import { render, staticRenderFns } from "./EmployeeSettingsCard.vue?vue&type=template&id=5e57e28d&"
import script from "./EmployeeSettingsCard.vue?vue&type=script&lang=js&"
export * from "./EmployeeSettingsCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VSimpleTable,VSwitch})
