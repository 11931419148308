<template>
  <ModernModalLayout
    :title="value ? `Редактировать ${title}` : `Добавить ${title}`"
    size="large"
    :watch-for="form"
  >
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          v-model="form.key"
          label="Отчет"
          outlined
          hide-details="auto"
          :items="items"
          item-text="name"
          item-value="key"
          @input="selectReport"
        />
      </v-col>

      <v-col v-if="item && !bySummary" cols="6">
        <v-text-field
          v-model="form.filename"
          label="Название файла"
          outlined
          hide-details="auto"
          :error-messages="getValidationErrors('form.filename')"
          @blur="validateField('form.filename')"
        />
      </v-col>

      <v-col v-if="item && !bySummary" cols="6">
        <v-autocomplete
          v-model="form.format"
          label="Формат"
          outlined
          hide-details="auto"
          :items="item.formats"
          :error-messages="getValidationErrors('form.format')"
          @blur="validateField('form.format')"
        />
      </v-col>
    </v-row>
    <template v-if="item">
      <v-row>
        <v-col cols="12"><v-divider /></v-col>
      </v-row>

      <component
        :is="reportFormComponent"
        ref="reportForm"
        v-model="form.filters"
        no-batch
        no-timezone
        :fields="item.fields"
      >
        <template #dateRange="{ getValidationError }">
          <RelativeRangePicker
            :value="form.filters"
            :error-messages="getValidationError()"
            @input="changeFilter"
          />
        </template>
        <template #date="{ getValidationError }">
          <RelativeRangePicker
            :value="form.filters"
            :error-messages="getValidationError()"
            @input="changeFilter"
          />
        </template>
      </component>
    </template>

    <template #actions:append>
      <v-btn color="primary" depressed :disabled="!item" @click="onSubmit">
        {{ value ? 'Сохранить' : 'Добавить' }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { requiredIf } from 'vuelidate/lib/validators';
import validation from '@/utils/validation';
import { fileName } from '@/utils/validators';
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import RelativeRangePicker from '../components/RelativeRangePicker';

export default {
  components: {
    ModernModalLayout,
    RelativeRangePicker,
  },
  mixins: [validation],

  props: {
    title: { type: String, default: '' },
    value: { type: Object, default: null },
    bySummary: Boolean,
    noTimezone: Boolean,
  },
  data: () => ({
    form: {
      key: null,
      format: null,
      filters: {},
      filename: '',
    },
  }),

  validations() {
    return {
      form: {
        format: { required: requiredIf(() => !this.bySummary) },
        filename: { fileName },
      },
    };
  },

  computed: {
    ...mapGetters('AVAILABLE_REPORTS', ['reports', 'reportByKey']),
    ...mapGetters('AVAILABLE_SUMMARIES', ['summaries', 'summaryByKey']),

    items() {
      return this.bySummary ? this.summaries : this.reports;
    },

    item() {
      return this.bySummary
        ? this.summaryByKey(this.form.key)
        : this.reportByKey(this.form.key);
    },

    reportFormComponent() {
      return this.item?.formComponent;
    },
  },

  created() {
    if (this.value) {
      const newForm = {
        ...this.form,
        key: this.value.key,
        format: this.value.format,
        filename: this.value.filename,
        filters: cloneDeep(this.value.filters),
      };

      const { filters } = newForm;

      // Поле "hoursRestMinutes" в отчете "Режим рабочего времени и времени
      // отдыха" является вспомогательным. Для гибкости на бэк принимается поле
      // "minRestMinutes". В итоге приходится костылять для обратного приведения
      // поля к рабочему виду.
      if (filters.minRestMinutes !== undefined)
        filters.hoursRestMinutes = Math.floor(filters.minRestMinutes / 60);

      Vue.set(this, 'form', newForm);
    }
  },
  methods: {
    selectReport() {
      this.form.format = null;
      this.form.filters = {};
    },

    changeFilter(newValues) {
      Vue.set(this.form, 'filters', { ...this.form.filters, ...newValues });
    },

    onSubmit() {
      const { reportForm } = this.$refs;
      if (!this.validate() || !reportForm.validate()) return;

      const resultFilters = this.item.prepareValues({
        ...this.form.filters,
        noTimezone: this.noTimezone,
      });

      const type = this.item.reportType
        ? this.item.reportType(resultFilters)
        : 'file';

      this.$emit('input', {
        key: this.form.key,
        format: this.form.format,
        filename: this.form.filename,
        type,
        filters: resultFilters,
      });
      this.$emit('close');
    },
  },
};
</script>
