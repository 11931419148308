import medrecApi from '@/api/services/medrec';

export default {
  state: {
    medicalTests: {},
  },
  getters: {
    medicalTests: state => state.medicalTests,
  },
  mutations: {
    medicalTests: (state, value) => (state.medicalTests = value),
  },
  actions: {
    async fetchMedicalTests({ commit, getters }) {
      const id = getters.singleItem.id;
      const medicalTests = await medrecApi.getEmployeeMedicalTests(id);
      commit('medicalTests', medicalTests || {});
    },

    addMedicalTest({ getters }, value) {
      const id = getters.singleItem.id;
      const orgId = getters.singleItem.organization.id;
      return medrecApi.addEmployeeMedicalTest(id, orgId, value);
    },

    changeMedicalTest(_, { id, data }) {
      return medrecApi.changeEmployeeMedicalTest(id, data);
    },

    deleteMedicalTest(_, { id, reason }) {
      return medrecApi.deleteEmployeeMedicalTest(id, reason);
    },
  },
};
