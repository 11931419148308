import env from '@/plugins/env';
import { pollerServiceFactory } from '@/utils/pollerServiceFactory';
import { createHTTP } from '../api';

export const reports = createHTTP(env.get('VUE_APP_REPORTS_API'));

export default {
  list(type) {
    return reports.get('/reports', { params: { type } }).then(res => res.data);
  },

  getReportLocals(keys) {
    return reports
      .get('/reports/locals', { params: { keys } })
      .then(res => res.data);
  },

  execute(reportKey, filters, format = 'xlsx', type) {
    if (format === 'json') {
      return this.executorJSON(reportKey, filters);
    }

    const multiple = Array.isArray(filters);

    if (multiple) {
      return this.executorZip(reportKey, filters, format);
    } else {
      return this.executorPlain(reportKey, filters, format, type);
    }
  },

  async executorJSON(reportKey, filters) {
    const params = {
      filters: encodeURIComponent(JSON.stringify(filters)),
    };
    return reports
      .get(`/reports/${reportKey}`, { params })
      .then(res => res.data);
  },

  async executorPlain(key, filters, format, type = 'file') {
    const data = {
      key,
      format,
      type,
      filters,
    };

    const { taskId } = await reports
      .post('/tasks/report', data)
      .then(res => res.data);

    return this.poller(taskId);
  },

  async executorZip(key, filters, format) {
    const data = filters.map(item => ({
      key,
      format,
      type: 'file',
      filters: item,
    }));

    const { taskId } = await reports
      .post('/tasks/batch', { reports: data })
      .then(res => res.data);

    return this.poller(taskId);
  },

  poller: pollerServiceFactory(taskId => reports.get(`/tasks/${taskId}`)),
};
