import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import PROFILES from './store/list';
import PROFILES_CARD from './store/item';
import RouteGuard from '@/components/RouteGuard';

export default {
  namespaced: true,
  store: {
    PROFILES,
    PROFILES_CARD,
  },
  menu: [
    {
      title: 'Профили',
      action: 'NF_PF',
      name: 'profiles',
      priority: 2,
    },
  ],
  routes: [
    {
      path: 'profiles',
      name: 'profiles',
      component: () => import('./view/list.vue'),
      meta: {
        action: 'NF_PF',
      },
    },
    {
      path: 'profiles/create',
      name: 'profiles:create',
      component: RouteGuard({
        storeModule: 'NOTIFICATIONS/PROFILES_CARD',
        component: () => import('./view/item.vue'),
        skipFetch: true,
      }),
      meta: {
        action: 'NF_PC',
      },
    },
    {
      path: 'profiles/:key',
      name: 'profiles:item',
      component: RouteGuard({
        storeModule: 'NOTIFICATIONS/PROFILES_CARD',
        component: () => import('./view/item.vue'),
        primaryKeyName: 'key',
        primaryKeyType: 'string',
      }),
      meta: {
        action: 'NF_PR',
      },
    },
  ],
} as ModuleDefinition;
