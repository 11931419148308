<template>
  <div class="d-flex">
    <v-col cols="5" class="pa-0 ma-0">
      <AuthTypes
        v-if="$can('GT_OSA')"
        class="mr-4 mb-4 pb-3"
        style="height: fit-content"
      />
      <AsyncWrapper v-if="$can('ORGCP_R')" :handler="fetchOrgSettings">
        <div>
          <EmployeeSettingsCard
            class="mr-4 mb-4 pb-3"
            style="height: fit-content"
          />
          <SnilsSetting class="mr-4 mb-4 pb-3" style="height: fit-content" />
          <EsiaAuth v-if="singleItem.isMedical" class="mr-4 mb-4 pb-3" />
        </div>
      </AsyncWrapper>
      <InspectionSignSettings
        v-if="$can('GT_OSS')"
        class="mr-4"
        style="height: fit-content"
      />
    </v-col>
    <v-col cols="7" class="pa-0 ma-0">
      <WaitretrySettings v-if="$can('PS_G')" class="mb-4" />
      <TimeSettings v-if="$can('GT_OST')" class="mb-4" />
      <NiiatIntegration />
      <Agreements v-if="singleItem.isMedical && $can('AF_G')" class="mt-4" />
    </v-col>
  </div>
</template>

<script>
import AuthTypes from '@/modules/structures/organizations/components/AuthSettingsCard';
import TimeSettings from '@/modules/structures/organizations/components/TimeSettingsCard';
import WaitretrySettings from '@/modules/structures/organizations/components/WaitretrySettingsCard';
import SnilsSetting from '@/modules/structures/organizations/components/SnilsSettingCard';
import EmployeeSettingsCard from '@/modules/structures/organizations/components/EmployeeSettingsCard';
import InspectionSignSettings from '@/modules/structures/organizations/components/InspectionSignSettings';
import NiiatIntegration from '@/modules/structures/organizations/components/NiiatIntegration';
import EsiaAuth from '@/modules/structures/organizations/components/EsiaAuth';
import Agreements from '@/modules/structures/organizations/components/Agreements';
import AsyncWrapper from '@/components/AsyncWrapper';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    AuthTypes,
    TimeSettings,
    WaitretrySettings,
    SnilsSetting,
    InspectionSignSettings,
    NiiatIntegration,
    EmployeeSettingsCard,
    AsyncWrapper,
    EsiaAuth,
    Agreements,
  },

  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', [
      'singleItem',
      'esiaEnabled',
    ]),
  },
  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', ['fetchOrgSettings']),
  },
};
</script>
