var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ItemPageLayout',{attrs:{"back-route":"scheduler:jobs","title":_vm.singleItem.name},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('ActionsMenu',[(_vm.$can('SJ_E'))?_c('ActionsMenuItem',{attrs:{"title":"Выполнить немедленно"},on:{"click":_vm.executeModal},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',[_vm._v("mdi-clock-start")])]},proxy:true}],null,false,4202713529)}):_vm._e(),_c('NewStatusChanger',{attrs:{"instance":"задачу","item":_vm.singleItem,"status":!!_vm.singleItem.activatedAt,"store-module":"SCHEDULER/JOB_ITEM","can-enable":_vm.$can('SJ_A'),"can-disable":_vm.$can('SJ_DA')}}),(_vm.$can('SJ_D'))?_c('ActionsMenuItem',{attrs:{"title":"Удалить задачу","subtitle":"Действие нельзя отменить"},on:{"click":_vm.openDeleteModal},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('RemoveCirc')]},proxy:true}],null,false,4051839582)}):_vm._e()],1)]},proxy:true}])},[_c('Card',{attrs:{"title":"Информация","dense":""},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.$can('SJ_U'))?_c('CardActionBtn',{attrs:{"label":"Редактировать","to":{
          name: 'scheduler:jobs:edit',
          params: { id: _vm.singleItem.id },
        }}}):_vm._e()]},proxy:true}])},[_c('DefinitionList',{attrs:{"value":_vm.tableData}}),_c('v-divider'),(_vm.singleItem || _vm.singleItem.params)?_c('div',{staticClass:"ma-4 my-8"},[_c('v-text-field',{staticClass:"mb-4",attrs:{"label":"Тема письма","outlined":"","hide-details":"auto","readonly":""},model:{value:(_vm.singleItem.params.subject),callback:function ($$v) {_vm.$set(_vm.singleItem.params, "subject", $$v)},expression:"singleItem.params.subject"}}),_c('v-textarea',{staticClass:"mb-4",attrs:{"label":"Содержимое письма","rows":"8","outlined":"","hide-details":"auto","readonly":""},model:{value:(_vm.singleItem.params.body),callback:function ($$v) {_vm.$set(_vm.singleItem.params, "body", $$v)},expression:"singleItem.params.body"}})],1):_vm._e(),_c('v-divider'),_c('div',{staticClass:"ma-4"},[_c('h3',{staticClass:"mb-4"},[_vm._v("Сводки")]),(!_vm.singleItem.params.summaries.length)?_c('NoDataAlert',[_vm._v(" Сводок нет ")]):_vm._e(),_vm._l((_vm.singleItem.params.summaries),function(summary,index){return _c('AttachmentItem',{key:index,staticClass:"mb-4",attrs:{"title":_vm.summaryByKey(summary.key)
            ? _vm.summaryByKey(summary.key).name
            : summary.key,"description":summary.filters}})})],2),_c('v-divider'),_c('div',{staticClass:"ma-4"},[_c('h3',{staticClass:"mb-4"},[_vm._v("Вложения")]),(!_vm.singleItem.params.attachments.length)?_c('NoDataAlert',[_vm._v(" Вложений нет ")]):_vm._e(),_vm._l((_vm.singleItem.params.attachments),function(attachment,index){return _c('AttachmentItem',{key:index,staticClass:"mb-4",attrs:{"title":_vm.reportByKey(attachment.key)
            ? _vm.reportByKey(attachment.key).name
            : attachment.key,"description":attachment.filters}})})],2)],1),_c('Card',{attrs:{"title":"Последние 10 выполнений","dense":""}},[_c('AsyncWrapper',{attrs:{"handler":_vm.fetchLastExecutions}},[_c('ExecutionsList',{attrs:{"value":_vm.lastExecutions}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }