<template>
  <div
    class="scenario-container"
    :class="{ 'd-flex flex-wrap justify-end': withIcons }"
  >
    <template v-if="withIcons">
      <v-tooltip v-for="(item, index) in scenarios" :key="index" bottom>
        <template #activator="{ on, attrs }">
          <v-layout
            class="scenario-container__icon rounded"
            :class="{
              'scenario-container__icon--large': item.result.length > 19,
            }"
            v-bind="attrs"
            column
            :style="itemStyle(item)"
            v-on="on"
          >
            <ScenarioLabel v-if="item.labels.length" :color="item.warnColor" />
            <component :is="item.svg" v-if="item.svg" class="scenario__icon" />
            <v-icon v-else class="scenario__icon">{{ item.icon }}</v-icon>
            <div class="scenario__text font-weight-bold">{{ item.result }}</div>
          </v-layout>
        </template>

        <div style="max-width: 400px">
          <b>{{ item.title }}</b>

          <v-divider v-if="item.description || item.labels.length" dark />

          <div v-if="item.subtitle">{{ item.subtitle }}</div>
          <div v-if="item.description" style="white-space: pre-line">
            {{ item.description }}
          </div>

          <template v-if="item.labels.length">
            <div class="scenario__labels__container">
              <div
                v-for="label in item.labels"
                :key="label"
                class="scenario__labels__item"
              >
                – {{ LABEL_TITLES[label] || label }}
              </div>
            </div>
          </template>
        </div>
      </v-tooltip>
    </template>

    <div
      v-else
      class="scenario__dense"
      :style="{ gridTemplateColumns: `repeat(${columnsCount}, 1fr)` }"
    >
      <div
        v-for="(item, index) in scenarios"
        :key="index"
        class="scenario__dense__item d-flex px-1"
        :style="itemStyle(item)"
      >
        <div class="scenario__dense__name caption">
          {{ item.subtitle || item.title }}
        </div>
        <div class="scenario__dense__result font-weight-bold">
          {{ item.result }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LABEL_TITLES } from '@/modules/medcab/constants';
import { generateScenarios } from '@/modules/medcab/scenarios';
import ScenarioLabel from './ScenarioLabel.vue';

const VIEWPORT_MIN_WIDTH = 1300;
export default {
  components: { ScenarioLabel },
  inheritAttrs: false,

  props: {
    steps: {
      required: true,
      type: Array,
    },

    // retry logic labels but only history inspections
    remarks: {
      type: Array,
      default: () => [],
    },

    // logic labels information on medcab inspections
    labels: {
      type: Object,
      default: () => ({}),
    },
    columns: { type: Number, default: null },
    withIcons: { type: Boolean, default: false },
  },

  computed: {
    LABEL_TITLES: () => LABEL_TITLES,

    scenarios() {
      return generateScenarios({
        steps: this.steps,
        labels: this.labels,
        remarks: this.remarks,
      });
    },

    itemStyle() {
      return item => {
        return {
          color: item.warn ? `rgb(${item.warnColor})` : null,
          background: item.warn ? `rgba(${item.warnColor}, 0.1)` : null,
        };
      };
    },
    columnsCount() {
      if (this.columns) {
        return this.columns;
      }
      // sets the default value depending on the width of the viewport
      return window.innerWidth < VIEWPORT_MIN_WIDTH ? 1 : 2;
    },
  },
};
</script>

<style lang="scss" scoped>
.scenario-container {
  gap: 4px;
}

.scenario-container__icon {
  width: 95px;
  padding: 4px;
  text-align: center;
  justify-content: space-between;
  flex-grow: 0;
}
.scenario-container__icon--large .scenario__text {
  font-size: 0.75rem;
}

.scenario__icon {
  height: 50px;
  font-size: 35px;
  color: inherit;
}
.scenario__text {
  line-height: 1.2;
  margin-top: 6px;
  text-align: center;
}

.scenario__dense {
  display: grid;
  gap: 8px;

  &__item {
    flex-direction: column;
  }
}
</style>
