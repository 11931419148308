<template>
  <v-data-table
    loading-text=""
    item-key="id"
    no-data-text="Выполненных задач нет"
    dense
    :headers="headers"
    :options="options"
    :items="value"
    hide-default-footer
  >
    <template #item.end="{ item }">
      {{ dateTime(item.end) }}
    </template>
    <template #item.status="{ item }">
      <ExecutionStatus :value="item.status" />
    </template>
    <template #item.isManual="{ item }">
      {{ item.isManual ? 'Вручную' : 'Автоматически' }}
    </template>

    <template #item.actions="{ item }">
      <div class="d-flex justify-end">
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              icon
              :to="{
                name: 'scheduler:executions:item',
                params: { id: item.id },
              }"
              v-on="on"
            >
              <v-icon small>fa-eye</v-icon>
            </v-btn>
          </template>
          <span>Открыть</span>
        </v-tooltip>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { dateTime } from '@/utils/convert';
import ExecutionStatus from '@/modules/scheduler/executions/components/ExecutionStatus.vue';

export default {
  components: { ExecutionStatus },

  props: {
    value: { type: Array, required: true },
  },

  data: () => ({
    headers: [
      { text: 'ID', value: 'id', sortable: false, width: 100 },
      { text: 'Дата', width: 200, value: 'end', sortable: false },
      {
        text: 'Статус',
        width: 50,
        value: 'status',
        sortable: false,
        align: 'center',
      },
      {
        text: 'Способ запуска',
        width: 200,
        value: 'isManual',
        sortable: false,
        align: 'center',
      },
      { text: 'Действия', value: 'actions', sortable: false, align: 'right' },
    ],
    options: { itemsPerPage: 10 },
    medics: null,
  }),

  methods: {
    dateTime,
  },
};
</script>
