<template>
  <ModernModalLayout
    size="medium"
    title="Уведомления после истечения срока"
    close-label="Отмена"
  >
    <div class="text-body-2 pb-2">
      Настроить уведомления по истечению срока действия ключа. <br />
      Если указать прошедшую дату, то уведомления возобновятся.<br />
      Время задаётся по Гринвичу (UTC+0).
    </div>

    <DatePickerWithTime v-if="expiresAt" v-model="expiresAt" dense outlined />

    <div class="text-body-2 pt-2">
      Уведомления будут <u>{{ isFutureTime ? 'включены' : 'отключены' }}</u>
    </div>

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :loading="$wait('muteByExpiredApiKey')"
        @click="submit"
      >
        Применить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import validation from '@/utils/validation';
import { required } from 'vuelidate/lib/validators';
import waitable from '@/utils/mixins/waitable';
import { compareDates, currentDateTime } from '@/utils/helpers';
import DatePickerWithTime from '@/components/controls/DatePickerWithTime';

export default {
  components: {
    ModernModalLayout,
    DatePickerWithTime,
  },

  mixins: [validation, waitable],

  props: {
    item: { type: Object, required: true },
  },

  data: () => ({
    expiresAt: null,
  }),

  computed: {
    isFutureTime() {
      return compareDates(currentDateTime(), this.expiresAt) > 0;
    },
  },

  validations: {
    expiresAt: { required },
  },

  created() {
    this.expiresAt = this.item?.ignoreExpiredErrorUntil || currentDateTime();
  },

  methods: {
    async submit() {
      if (!this.validate()) return;

      await this.$loadingNotify(
        this.$store.dispatch('ADMIN/APPLICATIONS_ITEM/muteByExpiredApiKey', {
          keyId: this.item.id,
          expiresAt: this.expiresAt,
        }),
        'muteByExpiredApiKey',
        'Произошла ошибка при настройке уведомлений',
        `Настройки уведомлений применены`,
      );

      this.$emit('close');
    },
  },
};
</script>
