var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('List',{attrs:{"available-actions":{
    showAction: _vm.$can('DOC_RD'),
  }},on:{"filters:resetHook":_vm.resetHook},scopedSlots:_vm._u([{key:"filters",fn:function(query){return [_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('SearchEmployee',{ref:"empSearch",attrs:{"store":"DOCUMENTS/INCOMING"},model:{value:(query.employeeIds),callback:function ($$v) {_vm.$set(query, "employeeIds", $$v)},expression:"query.employeeIds"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('Select',{attrs:{"label":"Тип документов","items":_vm.incomingDocumentTypes,"item-value":"key","dense":"","multiple":""},model:{value:(query.types),callback:function ($$v) {_vm.$set(query, "types", $$v)},expression:"query.types"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('Select',{attrs:{"label":"Статус документов","dense":"","items":_vm.filterStatuses},model:{value:(query.status),callback:function ($$v) {_vm.$set(query, "status", $$v)},expression:"query.status"}})],1)]}},{key:"table.item.status",fn:function(ref){
  var value = ref.value;
return [(value && _vm.documentStatus[value])?_c('v-tooltip',{attrs:{"top":"","content-class":"tooltip--show-white-space"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.documentStatus[value].color}},on),[_vm._v(" "+_vm._s(_vm.documentStatus[value].icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.documentStatus[value].title || '–'))])]):_vm._e()]}},{key:"table.item.name_type",fn:function(ref){
  var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.name))]),_c('br'),_vm._v(" "+_vm._s((_vm.getIncomingDocumentTypes(item.type) || {}).name || '–')+" "),_c('br'),_vm._v(" с "+_vm._s(_vm.date(item.issuedAt))+" "+_vm._s(item.dueDate ? ("по " + (_vm.date(item.dueDate))) : '(бессрочно)')+" ")]}},{key:"table.item.resolutionUser_resolutionDate",fn:function(ref){
  var item = ref.item;
return [(item.resolution)?[_vm._v(" "+_vm._s(_vm.accounts[item.resolution.approvedBy])+" "),_c('br'),_vm._v(" "+_vm._s(_vm.dateTime(item.resolution.date))+" ")]:_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }