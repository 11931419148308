<template>
  <Card title="Время на прохождение осмотра" dense>
    <AsyncWrapper :handler="fetch">
      <v-simple-table class="text-center" dense>
        <thead class="font-weight-bold">
          <tr>
            <th class="text-center py-3">Тип осмотра</th>
            <th class="text-center py-3">Время на прохождение осмотра (мин)</th>
            <th class="text-center py-3">Время на подпись осмотра (мин)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(val, key) in time" :key="key">
            <td class="grey--text px-4 py-3">
              {{ inspTypeName(key) }}
            </td>
            <td
              v-for="k in ['timeout', 'signingTimeout']"
              :key="k"
              class="px-4 py-3"
            >
              <span v-if="!editing">{{ val[k] }}</span>
              <MaskField
                v-else
                v-model="timeSettings[key][k]"
                style="max-width: 150px; margin: auto !important"
                :mask="maskPosNumber"
                class="ma-0 pa-0"
                :disabled="!$can('U_OST') || $wait('updatingTimeSettings')"
                outlined
                dense
                hide-details="auto"
                :error-messages="
                  getValidationErrors('timeSettings.$each.' + key + '.' + k)
                "
                @blur="validateField('timeSettings.$each.' + key + '.' + k)"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </AsyncWrapper>
    <template #action>
      <div>
        <CardActionBtn
          v-if="$can('U_OST') && !editing"
          label="Изменить"
          :loading="$wait('updatingTimeSettings')"
          :disabled="loading"
          @click="toggleEditing"
        />
        <template v-else-if="$can('U_OST') && editing">
          <v-btn
            color="success"
            outlined
            small
            class="mr-3"
            @click="handleTimeSettingsUpdate"
          >
            <v-icon size="16" class="mr-2">far fa-save</v-icon>
            <span>Сохранить</span>
          </v-btn>
          <v-btn outlined small class="mr-1" @click="toggleEditing">
            <v-icon size="16">fa-times</v-icon>
          </v-btn>
        </template>
      </div>
    </template>
  </Card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import { maskPosNumber } from '@/utils/masks';
import Vue from 'vue';
import { required, maxValue, minValue } from 'vuelidate/lib/validators';
import validationMixin from '@/utils/validation';
import cloneDeep from 'lodash/cloneDeep';

import AsyncWrapper from '@/components/AsyncWrapper';
import Card from '@/components/ui/Card';
import MaskField from '@/components/controls/MaskField';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';

export default {
  components: { AsyncWrapper, Card, MaskField, CardActionBtn },
  mixins: [validationMixin, waitable],

  data: () => ({
    editing: false,
    loading: false,
    timeSettings: {},
  }),

  validations() {
    return {
      timeSettings: {
        $each: {
          timeout: { required, minValue: minValue(1), maxValue: maxValue(10) },
          signingTimeout: {
            required,
            minValue: minValue(1),
            maxValue: maxValue(10),
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', ['time']),
    ...mapGetters('REGISTRY', ['inspectionTypes']),
    maskPosNumber: () => maskPosNumber,
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', [
      'fetchTimeSettings',
      'changeTimeSettings',
    ]),
    ...mapActions('REGISTRY', ['fetchInspectionTypes']),
    async fetch() {
      this.loading = true;
      Promise.all([this.fetchTimeSettings(), this.fetchInspectionTypes()]);
      this.loading = false;
    },

    inspTypeName(key) {
      return this.inspectionTypes?.find(type => type.key === key)?.name;
    },

    toggleEditing() {
      if (!this.editing) {
        Vue.set(this, 'timeSettings', cloneDeep(this.time));
        this.editing = true;
      } else {
        this.timeSettings = {};
        this.editing = false;
      }
    },

    handleTimeSettingsUpdate() {
      if (!this.validate()) return;

      this.$openModal('prompt', {
        yes: 'Да',
        no: 'Отмена',
        title: 'Подтверждение',
        description: 'Вы действительно хотите изменить время осмотра?',
        messages: {
          successfulAction: 'Время осмотра успешно изменено',
          errorAction: 'Возникла ошибка изменения времени осмотра',
        },
        onSubmit: () =>
          this.$loading(
            this.changeTimeSettings({ time: this.timeSettings }).then(() => {
              this.toggleEditing();
            }),
            'updatingTimeSettings',
          ),
      });
    },
  },
};
</script>
