import { merge, pickBy } from 'lodash';
import Vue from 'vue';

export default function queryTabSave(tabsVarName = 'currentTab') {
  return Vue.extend({
    async created() {
      // no tab query param? read it and set it
      if (!this.$route.query.tab) this.updateQueryTab(this[tabsVarName]);
      // if query param and component value differ - read query param and set it
      // in component tab value
      else if (this.$route.query.tab !== this[tabsVarName])
        this[tabsVarName] = this.$route.query.tab;

      // we set watcher here for 2 reasons:
      // 1. I don't know the way (and didn't find it) to pass dynamic string to
      // watch: {} object. But it is also good because
      // 2. we don't want to fire watcher when first syncing tab value with
      // query (which we r doing above)
      this.$watch(`${tabsVarName}`, function (val) {
        this.updateQueryTab(val);
      });
    },

    methods: {
      async updateQueryTab(tab) {
        let filteredQuery = { ...this.$route.query };

        // huge IF block below works ONLY on organization item page, which is
        // the only page where multiple lists exist and is dedicated to avoid
        // filters mixing / saving / interfering with each another
        const storeModule = this.embeddedTabFilters?.[tab]?.storeName;
        if (storeModule && tab !== 'general') {
          // first we reset filters on tab change
          this.$store.dispatch(storeModule + '/resetListFilters');
          // we need those default filters of THIS particular store
          filteredQuery = {
            ...this.$store.getters[storeModule + '/listQuery'],
          };

          // Below code is dedicated to find out which filters in current query
          // do not belong to it and clear them out. OPTION 2: we could just
          // pass 'oldVal' in watcher and see what was previous tab and clear
          // only its filters, avoiding all filtering and merging below, but it
          // wouldn't still let us avoid this ugly variable in org item.vue
          // called 'embeddedTabFilters'.. so there is not much point in doing
          // OPTION 2.

          // first we get an object with only OTHER tabs and their filters
          let otherTabsFilters = pickBy(
            this.embeddedTabFilters,
            (_, key) => key !== tab,
          );
          // now we merge filters of OTHER tabs
          otherTabsFilters = merge(
            ...Object.values(otherTabsFilters).map(obj => obj.filters),
          );
          // but filters can be same in those tabs and current one, so we
          // filter only those, which do not appear in filters of current tab
          otherTabsFilters = pickBy(
            otherTabsFilters,
            (_, key) => !this.embeddedTabFilters[tab][key],
          );

          filteredQuery = pickBy(
            filteredQuery,
            (_, key) => !otherTabsFilters[key],
          );
        }

        // this flag isn't needed in embedded urls so we make URL shorter..
        delete filteredQuery.orgIds;

        // this weird thing below prevents page from being reloaded when pushing
        // query tab param into query
        this.$router.replace(
          { query: Object.assign(filteredQuery, { tab }) },
          () => {},
        );
      },
    },
  });
}
