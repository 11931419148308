<template>
  <MultiStatisticBlock
    :value="groups"
    :active="active"
    @changeActive="changeActive"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import MultiStatisticBlock from '@/components/MultiStatisticBlock';

export default {
  components: {
    MultiStatisticBlock,
  },
  mixins: [waitable],

  data() {
    return {
      activeKey: 'inspections.total.unassigned',
    };
  },

  computed: {
    ...mapGetters('MEDCAB', ['statisticOnline', 'categories']),

    statisticFlat() {
      if (!this.statisticOnline) return [];

      const { inspections, medicsOnline } = this.statisticOnline;

      const joinByCategory = list => {
        if (!Object.keys(this.categories).length) return [];
        return list.reduce((agg, { categoryId, count }) => {
          agg.push({
            label: this.categories[categoryId]?.name || '–',
            value: count || '–',
          });
          return agg;
        }, []);
      };

      const stats = [
        {
          label: 'Назначены',
          key: 'inspections.total.assigned',
          value: inspections.total.assigned,
          detail: () => joinByCategory(inspections.assigned),
        },
        {
          label: 'В работе',
          key: 'inspections.total.inProgress',
          value: inspections.total.inProgress,
          detail: () => joinByCategory(inspections.inProgress),
        },
        {
          label: 'В очереди',
          key: 'inspections.total.unassigned',
          value: inspections.total.unassigned,
          detail: () => joinByCategory(inspections.unassigned),
        },
        {
          label: 'Всего онлайн',
          key: 'medics.total',
          value: medicsOnline.total,
          detail: () => joinByCategory(medicsOnline.byCategories),
        },
      ];
      return stats;
    },

    groups() {
      if (!this.statisticFlat.length) return [];

      const stats = this.statisticFlat.reduce((agg, el) => {
        agg[`${el.key}`] = el;
        return agg;
      }, {});

      const medicsGroup = {
        title: 'Медработники',
        list: [stats['medics.total']],
      };

      const inspectionsGroup = {
        title: 'Осмотры',
        list: [
          stats['inspections.total.assigned'],
          stats['inspections.total.inProgress'],
          stats['inspections.total.unassigned'],
        ],
      };

      return [medicsGroup, inspectionsGroup];
    },

    active() {
      const stats = this.statisticFlat;
      const active = stats.find(el => el.key === this.activeKey);

      return active || null;
    },
  },

  methods: {
    changeActive(key) {
      this.activeKey = key;
    },
  },
};
</script>
