import medrecApi from '@/api/services/medrec';

export default {
  state: {
    risks: [],
  },
  getters: {
    risks: state => state.risks,
  },
  mutations: {
    risks: (state, value) => (state.risks = value),
  },
  actions: {
    async fetchRiskGroupEmployee({ commit }, params) {
      const data = await medrecApi.getRiskGroupEmployee(params);
      commit('risks', data);
      return data;
    },
  },
};
