import {
  mergeReportFields,
  getAvailableFieldNames,
  getFieldDefinitionByName,
  reduceFieldName,
  prepareDate,
  prepareValues,
} from './utilities';
import { Field, FieldFunctionProp, FieldPresets } from './models';

const presets: FieldPresets = {
  PresetWithComponentName: {
    component: () => {},
    name: 'presetWithComponentName',
    cols: 5,
  },
  PresetWithProps: {
    props: {
      bar: 5,
      foo: 10,
    },
  },
  PresetWithHandlers: {
    watch: jest.fn(),
    prepareValue: jest.fn(),
    defaultValue: jest.fn(),
    is: jest.fn(),
  },
};

describe('utilities', () => {
  describe('mergeReportFields', () => {
    it('correctly merge fields with presets', () => {
      const fields = [
        { preset: 'PresetWithComponentName', cols: 10 },
        { component: () => {}, name: 'test', cols: 10 },
      ];

      const mergedFields = mergeReportFields(fields, presets);

      expect(mergedFields).toStrictEqual([
        {
          component: expect.any(Function),
          props: expect.any(Function),
          cols: 10,
          name: 'presetWithComponentName',
          preset: 'PresetWithComponentName',
        },
        { cols: 10, component: expect.any(Function), name: 'test' },
      ]);
    });

    it('log error because preset not found', () => {
      console.warn = jest.fn();
      const fields = [{ preset: 'NotExistPreset', cols: 10 }];

      const mergedFields = mergeReportFields(fields, presets);

      expect(mergedFields).toStrictEqual([
        { cols: 10, preset: 'NotExistPreset' },
      ]);
      expect(console.warn).toHaveBeenCalledWith(
        '(REPORTS WARN)',
        'Field preset with name "NotExistPreset" is not exist.',
        'Found in field with name "undefined".',
      );
    });

    it('log error because component and name not setted', () => {
      console.warn = jest.fn();
      const fields = [{ cols: 10 }];

      mergeReportFields(fields, presets);

      expect(console.warn).toHaveBeenCalledWith(
        '(REPORTS WARN)',
        'Required "field.name" is not exist.',
      );
      expect(console.warn).toHaveBeenCalledWith(
        '(REPORTS WARN)',
        'Required "field.component" is not exist.',
      );
    });

    it('correctly merge props with presets', () => {
      const fields = [
        {
          preset: 'PresetWithProps',
          component: () => {},
          name: 'test',
          props: {
            foo: 6,
            multiple: true,
          },
        },
        {
          preset: 'PresetWithProps',
          component: () => {},
          name: 'test2',
          props: () => ({ bar: 15, label: 'test' }),
        },
      ];

      const mergedFields = mergeReportFields(fields, presets);

      expect((mergedFields[0].props as FieldFunctionProp)({})).toStrictEqual({
        bar: 5,
        foo: 6,
        multiple: true,
      });
      expect((mergedFields[1].props as FieldFunctionProp)({})).toStrictEqual({
        bar: 15,
        foo: 10,
        label: 'test',
      });
    });

    it('correctly merge handlers with presets', () => {
      const fields = [
        {
          preset: 'PresetWithHandlers',
          component: () => {},
          name: 'test',
        },
        {
          preset: 'PresetWithHandlers',
          component: () => {},
          name: 'test2',
          watch: jest.fn(),
          is: jest.fn(),
        },
      ];

      const mergedFields = mergeReportFields(fields, presets);

      // Обработчик watch не был переопределен в поле, остался из пресета
      expect(mergedFields[0].watch).toStrictEqual(
        presets.PresetWithHandlers.watch,
      );
      // Обработчик watch был переопределен в поле
      expect(mergedFields[1].watch).toStrictEqual(fields[1].watch);
    });
  });

  describe('getAvailableFieldNames', () => {
    it('correctly get field names', () => {
      const fields = [
        { name: 'foo' },
        { name: 'bar' },
        { name: ['baz', 'gaz'] },
      ];

      const availableFieldNames = getAvailableFieldNames({ fields });

      expect(availableFieldNames).toStrictEqual(['foo', 'bar', 'baz', 'gaz']);
    });

    it('correctly get field names with fixedValues', () => {
      const fields = [{ name: 'foo' }, { name: ['baz', 'gaz'] }];
      const fixedValues = {
        bar: 1,
      };

      const availableFieldNames = getAvailableFieldNames({
        fields,
        fixedValues,
      });

      expect(availableFieldNames).toStrictEqual(['foo', 'baz', 'gaz', 'bar']);
    });
  });

  describe('getFieldDefinitionByName', () => {
    it('correctly get field by name', () => {
      const fields = [{ name: 'foo' }, { name: ['baz', 'gaz'] }];

      const fieldDefinition = getFieldDefinitionByName(fields, 'foo');

      expect(fieldDefinition).toStrictEqual(fields[0]);
    });

    it('correctly get field by name in array', () => {
      const fields = [{ name: 'foo' }, { name: ['baz', 'gaz'] }];

      const fieldDefinition = getFieldDefinitionByName(fields, 'baz');

      expect(fieldDefinition).toStrictEqual(fields[1]);
    });
  });

  describe('reduceFieldName', () => {
    it('reduce by string name', () => {
      const field = { name: 'foo' };
      const cb = jest.fn((acc, name) => name);

      const retrieved = reduceFieldName(field, cb);

      expect(retrieved).toStrictEqual('foo');
      expect(cb).toHaveBeenCalledWith(undefined, 'foo');
    });

    it('reduce by array name', () => {
      const field = { name: ['baz', 'gaz'] };
      const cb = jest.fn((acc, name) => ({ ...acc, [name]: true }));

      const retrieved = reduceFieldName(field, cb);

      expect(retrieved).toStrictEqual({ baz: true, gaz: true });
      expect(cb).toHaveBeenCalledWith(undefined, 'baz');
      expect(cb).toHaveBeenCalledWith({ baz: true }, 'gaz');
    });
  });

  describe('prepareDate', () => {
    it('set timezone', () => {
      const date = '2021-12-01T00:00:00.000';
      const timezone = 5;

      const retrieved = prepareDate(date, timezone);

      expect(retrieved).toStrictEqual('2021-12-01T00:00:00.000+05:00');
    });

    it('change timezone', () => {
      const date = '2021-12-01T00:00:00.000+03:00';
      const timezone = 5;

      const retrieved = prepareDate(date, timezone);

      expect(retrieved).toStrictEqual('2021-12-01T00:00:00.000+05:00');
    });

    it('ignore object', () => {
      const date = {};
      const timezone = 5;

      const retrieved = prepareDate(date, timezone);

      expect(retrieved).toStrictEqual(date);
    });
  });

  describe('prepareValues', () => {
    it('correctly prepare values ', () => {
      const values = {
        foo: 1,
        bar: 'test',
        baz: ['data'],
        bad: [],
        zoo: null as any,
        gaz: 4,
      };
      const fields: Field[] = [
        {
          name: 'foo',
          prepareValue: (name, value) => [name + 1, (value as number) * 2],
        },
        { name: ['bar', 'baz'] },
        { name: 'bad' },
        { name: 'zoo' },
      ];

      const received = prepareValues(values, { fields });

      expect(received).toStrictEqual({
        foo1: 2,
        bar: 'test',
        baz: ['data'],
        bad: [],
        zoo: null,
      });
    });

    it('remove prepared as empty value', () => {
      const values = {
        foo: 1,
        bar: 'test',
      };
      const fields: Field[] = [
        { name: 'foo' },
        {
          name: 'bar',
          prepareValue: () => undefined,
        },
      ];

      const received = prepareValues(values, { fields });

      expect(received).toStrictEqual({ foo: 1 });
    });

    it('batch by array value', () => {
      const values = {
        ids: [5, 2, 8],
        foo: 1,
        bar: 'test',
      };
      const fields: Field[] = [
        { name: 'ids' },
        { name: 'foo' },
        { name: 'bar' },
      ];
      const batchByField = 'ids';
      const batchedFieldName = 'id';

      const received = prepareValues(values, {
        fields,
        batchByField,
        batchedFieldName,
      });

      expect(received).toStrictEqual([
        { bar: 'test', foo: 1, id: 5 },
        { bar: 'test', foo: 1, id: 2 },
        { bar: 'test', foo: 1, id: 8 },
      ]);
    });

    it('batch by array single value', () => {
      const values = {
        ids: [5],
        foo: 1,
        bar: 'test',
      };
      const fields: Field[] = [
        { name: 'ids' },
        { name: 'foo' },
        { name: 'bar' },
      ];
      const batchByField = 'ids';
      const batchedFieldName = 'id';

      const received = prepareValues(values, {
        fields,
        batchByField,
        batchedFieldName,
      });

      expect(received).toStrictEqual({ bar: 'test', foo: 1, id: 5 });
    });
  });
});
