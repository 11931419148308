import { required } from 'vuelidate/lib/validators';
import env from '@/plugins/env';
import { ReportSchemes } from '../models';
import { MOSCOW_TIMEZONE } from '../constants';

export const schemes: ReportSchemes = {
  'inspections-failed-stats': {
    fields: [
      {
        preset: 'DateRange',
        validation: { required },
        cols: 8,
      },
      {
        preset: 'Timezone',
        validation: { required },
        cols: 4,
      },
    ],
    fixedValues: {
      timeDisplayMode: 'user',
    },
  },
  'inspections-openpoint-stats': {
    fields: [
      {
        preset: 'DateRange',
        validation: { required },
        cols: 8,
      },
    ],
  },
  'inspections-with-mistakes': {
    fields: [
      {
        preset: 'DateRange',
        validation: { required },
        cols: 8,
      },
    ],
    fixedValues: {
      timezone: MOSCOW_TIMEZONE,
      timeDisplayMode: 'system',
    },
  },
  'medics-work-stats': {
    fields: [
      {
        preset: 'Organization',
        props: { medical: true },
        watch(context) {
          // Сбросить выбранные группы медработников
          context.value.medicGroupIds = [];
          return context;
        },
      },
      {
        preset: 'MedicGroups',
        props: value => ({
          medOrgIds: value.orgId ? [value.orgId] : null,
        }),
      },
      {
        preset: 'ImportedInspections',
        is: () => !env.get('VUE_APP_EXCLUDE_IMPORTED_INSPECTIONS'),
      },
      {
        preset: 'DateRange',
        validation: { required },
        cols: 8,
      },
      {
        preset: 'Timezone',
        validation: { required },
        cols: 4,
      },
    ],
    fixedValues: {
      timeDisplayMode: 'user',
    },
  },
  'inspections-stats': {
    fields: [
      {
        preset: 'DateRange',
        validation: { required },
      },
      {
        preset: 'Timezone',
        validation: { required },
        cols: 3,
      },
      {
        preset: 'TimeDisplayMode',
        validation: { required },
        cols: 3,
      },
      {
        preset: 'IncludeTechnicalCheckbox',
        validation: { required },
      },
    ],
  },
  'inspections-hourly-stats': {
    fields: [
      {
        preset: 'DateRange',
        validation: { required },
        props: { withTime: true },
      },
      {
        preset: 'MedicGroups',
      },
      {
        preset: 'ImportedInspections',
        cols: 12,
        is: () => !env.get('VUE_APP_EXCLUDE_IMPORTED_INSPECTIONS'),
      },
    ],
    fixedValues: {
      timezone: MOSCOW_TIMEZONE,
      timeDisplayMode: 'system',
    },
  },
  'inspections-amount-stats': {
    fields: [
      { preset: 'Organizations', validation: { required } },
      { preset: 'InspectionTypes', validation: { required }, cols: 3 },
      { preset: 'ResolutionSuccess', cols: 3 },
      { preset: 'DateRange', validation: { required }, cols: 8 },
      { preset: 'Timezone', validation: { required }, cols: 4 },
      { preset: 'IncludeTestCheckbox', validation: { required }, cols: 12 },
      {
        preset: 'IncludeTechnicalCheckbox',
        validation: { required },
        cols: 12,
      },
    ],
    fixedValues: {
      timeDisplayMode: 'user',
    },
    chart: 'CountChart',
    formats: ['csv', 'json'],
  },
  'inspections-duration-stats': {
    fields: [
      {
        preset: 'Organizations',
        validation: { required },
      },
      {
        preset: 'InspectionTypes',
        validation: { required },
        props: {
          exclude: ['ALCOTESTING', 'PIROMETRY', 'PREVENTION'],
        },
        cols: 3,
      },
      {
        preset: 'ResolutionSuccess',
        cols: 3,
      },
      {
        preset: 'DateRange',
        validation: { required },
      },
      {
        preset: 'Timezone',
        validation: { required },
        cols: 3,
      },
      {
        preset: 'TimeDisplayMode',
        validation: { required },
        cols: 3,
      },
      {
        preset: 'IncludeTestCheckbox',
        validation: { required },
        cols: 12,
      },
      {
        preset: 'IncludeTechnicalCheckbox',
        validation: { required },
        cols: 12,
      },
    ],
    chart: 'TimeChart',
    formats: ['csv', 'json'],
  },
  'inspections-workflow-stats': {
    fields: [
      {
        preset: 'Organization',
        validation: { required },
        props: {
          medical: true,
        },
        watch(context) {
          // Сбросить выбранные группы медработников
          context.value.medicGroupIds = [];
          return context;
        },
      },
      {
        preset: 'MedicGroups',
        props: value => ({
          disabled: !value.orgId,
          medOrgIds: value.orgId ? [value.orgId] : null,
        }),
      },
      {
        preset: 'Date',
        validation: { required },
      },
    ],
    fixedValues: {
      timeDisplayMode: 'system',
      timezone: MOSCOW_TIMEZONE,
    },
  },
  'inspections-employees-release': {
    fields: [
      {
        preset: 'Choose',
        name: 'type',
        props: {
          label: 'Выберите способ формирования:',
          options: [
            { value: 'byOrgGroup', name: 'По группе организаций' },
            { value: 'byOrg', name: 'По организации' },
          ],
        },
        defaultValue: context => (context.value.orgId ? 'byOrg' : 'byOrgGroup'),
        cols: 12,
        watch: context =>
          context.$emit('input', {
            ...context.value,
            orgGroupId: undefined,
            orgId: undefined,
            pointIds: undefined,
          }),
      },
      {
        is: context => context.value.type === 'byOrgGroup',
        preset: 'OrganizationGroup',
        validation: { required },
        watch: context =>
          context.$emit('input', {
            ...context.value,
            pointIds: undefined,
          }),
        cols: 7,
      },
      {
        preset: 'Organization',
        validation: { required },
        is: context => context.value.type === 'byOrg',
        watch: context =>
          context.$emit('input', {
            ...context.value,
            pointIds: undefined,
          }),
        cols: 7,
      },
      { preset: 'Timezone', validation: { required }, cols: 5 },
      {
        preset: 'Points',
        props: value => ({
          disabled: value.type === 'byOrg' ? !value.orgId : !value.orgGroupId,
          orgIds: value.orgId ? [value.orgId] : null,
          orgGroupIds: value.orgGroupId ? [value.orgGroupId] : null,
        }),
        cols: 6,
      },
      { preset: 'DateRange', validation: { required }, cols: 6 },
    ],
    fixedValues: {
      timeDisplayMode: 'user',
    },
  },
};

export default schemes;
