import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives';
import ru from 'vuetify/es5/locale/ru';

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: {
      calendar: 'mdi-calendar',
      search: 'mdi-database-search',
    },
  },
  lang: {
    current: 'ru',
    locales: { ru },
  },
});
