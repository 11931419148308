export const messages = {
  resourceName: 'категорий организаций',
};

export const entity = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: 'Название', hideInTable: true },
  { key: 'name_belongsTo', label: 'Название / Организация', hideInCard: true },
  { key: 'description', label: 'Описание' },
  {
    key: 'weight',
    label: 'Вес',
    headerProps: {
      width: '80px',
      align: 'center',
    },
  },
  {
    key: 'sla',
    label: 'SLA (в минутах)',
    headerProps: {
      width: '90px',
      align: 'center',
    },
  },
  {
    key: 'orgsCount',
    label: 'Кол-во организаций',
    headerProps: {
      width: '110px',
      align: 'center',
    },
  },
];

export const filters = {
  medOrgIds: {
    type: Array,
    default: null,
  },
};
