import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import CABINET from './index.store.js';

export default {
  store: {
    CABINET,
  },
  routes: [
    {
      path: '/cabinet',
      name: 'cabinet',
      component: () => import('./index.vue'),
    },
  ],
} as ModuleDefinition;
