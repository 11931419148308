import { initStoreInstance } from '@/utils/tests';
import reportsApi from '@/api/services/reports';
import reportsStore from './reports';

// eslint-disable-next-line no-global-assign
Date = jest.fn(() => ({
  valueOf: () => 1,
}));

const ns = 'REPORTS';
let store;

beforeEach(() => {
  store = initStoreInstance(reportsStore, true, ns);
});

describe('store: reports', () => {
  describe('results', () => {
    it('expandResult expands existing result', () => {
      store.commit(`${ns}/addResult`, { id: 1 });
      store.commit(`${ns}/addResult`, { id: 2 });

      expect(store.getters[`${ns}/isExpandedResult`](1)).toBeFalsy();
      expect(store.getters[`${ns}/isExpandedResult`](2)).toBeFalsy();

      store.dispatch(`${ns}/expandResult`, 1);

      expect(store.getters[`${ns}/isExpandedResult`](1)).toBeTruthy();
      expect(store.getters[`${ns}/isExpandedResult`](2)).toBeFalsy();
    });

    it('unexpandResult unexpands existing expanded result', () => {
      store.commit(`${ns}/addResult`, { id: 1 });
      store.commit(`${ns}/addResult`, { id: 2 });
      store.commit(`${ns}/expandedResults`, [1, 2]);

      expect(store.getters[`${ns}/isExpandedResult`](1)).toBeTruthy();
      expect(store.getters[`${ns}/isExpandedResult`](2)).toBeTruthy();

      store.dispatch(`${ns}/unexpandResult`, 1);

      expect(store.getters[`${ns}/isExpandedResult`](1)).toBeFalsy();
      expect(store.getters[`${ns}/isExpandedResult`](2)).toBeTruthy();
    });

    it('clearResults removes all existing results', () => {
      store.commit(`${ns}/addResult`, { id: 1 });
      store.commit(`${ns}/addResult`, { id: 2 });
      store.commit(`${ns}/expandedResults`, [1, 2]);

      store.dispatch(`${ns}/clearResults`);

      expect(store.getters[`${ns}/results`]).toStrictEqual([]);
      expect(store.getters[`${ns}/isExpandedResult`](1)).toBeFalsy();
      expect(store.getters[`${ns}/isExpandedResult`](2)).toBeFalsy();
    });
  });

  describe('export', () => {
    it('sets correct statuses of execute report', async () => {
      reportsApi.execute.mockImplementation(() => ({}));

      const operation = store.dispatch(`${ns}/export`, {
        key: 'testReport',
        filters: { orgId: 1 },
        format: 'json',
      });

      expect(store.getters[`${ns}/results`]).toStrictEqual([
        {
          id: 1,
          timestamp: 1,
          format: 'json',
          reportId: 'testReport',
          isLoading: true,
          data: null,
          error: null,
          filters: {
            orgId: 1,
          },
        },
      ]);

      await operation;

      expect(store.getters[`${ns}/results`]).toStrictEqual([
        {
          id: 1,
          timestamp: 1,
          format: 'json',
          reportId: 'testReport',
          isLoading: false,
          data: {},
          error: null,
          filters: {
            orgId: 1,
          },
        },
      ]);
      expect(store.getters[`${ns}/isExpandedResult`](1)).toBeFalsy();
    });

    it('expands result if it is chart', async () => {
      reportsApi.execute.mockImplementation(() => ({
        result: { rows: [] },
      }));

      await store.dispatch(`${ns}/export`, {
        key: 'testReport',
        filters: { orgId: 1 },
        format: 'json',
      });

      expect(store.getters[`${ns}/results`]).toStrictEqual([
        {
          id: 1,
          timestamp: 1,
          format: 'json',
          reportId: 'testReport',
          isLoading: false,
          data: { result: { rows: [] } },
          error: null,
          filters: {
            orgId: 1,
          },
        },
      ]);
      expect(store.getters[`${ns}/isExpandedResult`](1)).toBeTruthy();
    });

    it('sets error status if job failed on', async () => {
      reportsApi.execute.mockImplementation(() => {
        throw new Error('expectedErrorMessage');
      });

      try {
        await store.dispatch(`${ns}/export`, {
          key: 'testReport',
          filters: { orgId: 1 },
          format: 'json',
        });
      } catch (e) {
        expect(e.message).toBe('expectedErrorMessage');
      }

      expect(store.getters[`${ns}/results`]).toStrictEqual([
        {
          id: 1,
          timestamp: 1,
          format: 'json',
          reportId: 'testReport',
          isLoading: false,
          data: null,
          error: new Error('expectedErrorMessage'),
          filters: {
            orgId: 1,
          },
        },
      ]);
      expect(store.getters[`${ns}/isExpandedResult`](1)).toBeFalsy();
    });
  });
});
