<template>
  <ModernModalLayout size="large" :title="title" :watch-for="data">
    <v-form :disabled="$wait('submit')">
      <v-alert
        v-if="item.id && !item.isTest && isTestInField"
        outlined
        type="warning"
      >
        При тестировании просьба создать отдельного тестового водителя
      </v-alert>
      <v-alert v-else-if="isTestInField" outlined type="warning">
        Обратите внимание, создание тестовых работников выполняется в другой
        форме
      </v-alert>

      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="data.surname"
            hide-details="auto"
            outlined
            label="Фамилия"
            :disabled="disabledFields.includes('surname')"
            :error-messages="getValidationErrors('data.surname')"
            @blur="handleFieldBlur('data.surname')"
            @input="val => checkTestInField(val, 'surname')"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="data.name"
            hide-details="auto"
            outlined
            label="Имя"
            :disabled="disabledFields.includes('name')"
            :error-messages="getValidationErrors('data.name')"
            @blur="handleFieldBlur('data.name')"
            @input="val => checkTestInField(val, 'name')"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="data.patronymic"
            hide-details="auto"
            outlined
            label="Отчество"
            :disabled="disabledFields.includes('patronymic')"
            :error-messages="getValidationErrors('data.patronymic')"
            @blur="handleFieldBlur('data.patronymic')"
            @input="val => checkTestInField(val, 'patronymic')"
          />
        </v-col>
        <v-col cols="6">
          <v-radio-group
            v-model="data.gender"
            class="mt-1"
            row="row"
            :disabled="disabledFields.includes('gender')"
            :error-messages="getValidationErrors('data.gender')"
            @blur="handleFieldBlur('data.gender')"
          >
            <v-radio value="MALE" label="Мужчина" />
            <v-radio value="FEMALE" label="Женщина" />
          </v-radio-group>
        </v-col>
        <v-col cols="6">
          <DatePicker
            v-model="data.dateOfBirth"
            hide-details="auto"
            outlined
            label="Дата рождения"
            :disabled="disabledFields.includes('dateOfBirth')"
            :error-messages="getValidationErrors('data.dateOfBirth')"
            @blur="handleFieldBlur('data.dateOfBirth')"
          />
        </v-col>
        <v-col cols="6">
          <MaskField
            v-model="data.snils"
            label="СНИЛС"
            outlined
            placeholder="000-000-000 00"
            first-format
            hide-details="auto"
            :mask="maskInputSnils"
            :disabled="disabledFields.includes('snils')"
            :error-messages="getClientValidationErrors('data.snils')"
            field-tooltip="Без указания СНИЛС ЭПЛ не будет сформирован"
            @blur="validateField('data.snils')"
          />
        </v-col>
        <v-col cols="6">
          <v-skeleton-loader
            v-if="!settingsLoaded"
            class="mx-auto"
            type="card"
            height="54px"
          />
          <MaskField
            v-else-if="Boolean(pnMask)"
            ref="pn"
            v-model="data.personnelNumber"
            label="Табельный номер"
            outlined
            first-format
            hide-details="auto"
            :placeholder="pnMaskPlaceholder"
            :mask="pnMask"
            :disabled="
              item.isTest || disabledFields.includes('personnelNumber')
            "
            :error-messages="getValidationErrors('data.personnelNumber')"
            @blur="handleFieldBlur('data.personnelNumber')"
          />
          <v-text-field
            v-else
            v-model="data.personnelNumber"
            hide-details="auto"
            outlined
            label="Табельный номер"
            :disabled="
              item.isTest || disabledFields.includes('personnelNumber')
            "
            :error-messages="getValidationErrors('data.personnelNumber')"
            @input="data.personnelNumber = uppercase(data.personnelNumber)"
            @blur="handleFieldBlur('data.personnelNumber')"
          />
        </v-col>
        <v-col cols="6">
          <PhoneField
            v-model="data.phone"
            hide-details="auto"
            outlined
            :disabled="disabledFields.includes('phone')"
            :error-messages="getValidationErrors('data.phone')"
            @blur="handleFieldBlur('data.phone')"
          />
        </v-col>
        <template v-if="!data.id">
          <v-col cols="6">
            <OrganizationSelect
              v-model="data.orgId"
              hide-details="auto"
              :hint="customRulesHint"
              :error-messages="getValidationErrors('data.orgId')"
              @blur="handleFieldBlur('data.orgId')"
            />
            <span
              v-if="createForbidden"
              class="error--text caption text-center mt-1"
              style="display: block; width: 100%"
            >
              В этой организации работники создаются автоматически
            </span>
          </v-col>
          <v-col cols="6">
            <EmployeeGroupSelect
              v-model="data.groupIds"
              access-level="full"
              multiple
              hide-details="auto"
              :type="(orgData || {}).isRestricted ? 'all' : 'public'"
              :disabled="!data.orgId"
              :org-ids="[data.orgId]"
              :error-messages="getValidationErrors('data.groupIds')"
              @blur="handleFieldBlur('data.groupIds')"
            />
          </v-col>
        </template>
        <v-col cols="6">
          <div class="mt-1 ml-1">Отправка осмотров в НИИАТ</div>
        </v-col>
        <v-col cols="6">
          <v-radio-group
            v-model="data.sendToNiiat"
            class="mt-1"
            row="row"
            :disabled="disabledFields.includes('sendToNiiat')"
            :error-messages="getValidationErrors('data.sendToNiiat')"
            @blur="handleFieldBlur('data.sendToNiiat')"
          >
            <v-radio :value="true" label="Да" />
            <v-radio :value="false" label="Нет" />
          </v-radio-group>
        </v-col>
      </v-row>
    </v-form>

    <template #actions:append>
      <v-btn
        class="px-16"
        depressed
        color="primary"
        :loading="$wait('submit') || !settingsLoaded"
        :disabled="createForbidden"
        @click="handleSubmit"
      >
        {{ item.id ? 'Сохранить' : 'Добавить' }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators';
import isEqual from 'lodash/isEqual';
import {
  cyrillicName,
  date,
  minAge,
  maxAge,
  phone,
  snils,
  personnelNumber,
} from '@/utils/validators';
import validationMixin from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import { unmaskPhone, maskInputSnils, unmaskSnils } from '@/utils/masks';
import ModernModalLayout from '@/components/layouts/ModernModalLayout.vue';
import DatePicker from '@/components/controls/DatePicker.vue';
import PhoneField from '@/components/controls/PhoneField.vue';
import MaskField from '@/components/controls/MaskField.vue';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';
import EmployeeGroupSelect from '@/components/controls/structures/EmployeeGroupSelect.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    ModernModalLayout,
    DatePicker,
    PhoneField,
    MaskField,
    OrganizationSelect,
    EmployeeGroupSelect,
  },
  mixins: [validationMixin, waitable],
  props: {
    item: { type: Object, required: true },
    // Используется в случае синхронизации работника с другим работником
    disabledFields: { type: Array, default: () => [] },
    onSubmit: { type: Function, required: true },
    fetchOrganizationSettings: { type: Function, required: true },

    // it is done extencibely, yes. But for now it will ONLY predefine
    // release point ID when host is being created from within a point
    predefinedValues: { type: Object, default: () => ({}) },

    /** При создании тестового работника выставляется флаг isTestEmployee */
    isTestEmployee: Boolean,
  },

  validations() {
    return {
      data: {
        name: { required, cyrillicName, maxSymbolsLength: maxLength(50) },
        surname: {
          required,
          cyrillicName,
          maxSymbolsLength: maxLength(50),
        },
        patronymic: { cyrillicName, maxSymbolsLength: maxLength(50) },
        dateOfBirth: {
          required,
          date,
          minAge: minAge(18),
          maxAge: maxAge(100),
        },
        snils: {
          required: requiredIf(
            () =>
              this.orgSettings?.employeeMustHave.rule.snils && !this.isTestForm,
          ),
          snils,
        },
        gender: { required },
        sendToNiiat: { required },
        personnelNumber: {
          rule(val) {
            const pattern = this.orgSettings?.pn?.pattern;
            const value = this.$refs.pn
              ? this.$refs.pn.getUnmaskedValue()
              : val;

            if (pattern) {
              const regex = new RegExp(pattern);
              return regex.test(value);
            }

            // Если валидация не пришла с сервера,
            // валидируем на основе дефолтного правила
            return personnelNumber(value);
          },
          required,
          maxSymbolsLength: maxLength(20),
        },
        phone: { phone },

        orgId: {
          rule() {
            if (!this.orgSettings) return true;
            return (
              this.orgSettings?.employeeSync?.rule?.employeeModify === true
            );
          },
          // only while create
          required: requiredIf(() => !this.item.id),
        },
        groupIds: {
          required: this.item.id
            ? {}
            : requiredIf(() => this.orgData?.isRestricted),
        },
      },
    };
  },

  data() {
    return {
      data: { groupIds: [], ...this.item },
      orgSettings: null,
      settingsLoaded: true,
      resultCheckTest: {},

      /** Информация о выбранной организации */
      orgData: null,
    };
  },

  computed: {
    maskInputSnils: () => maskInputSnils,

    pnMask() {
      const mask = this.orgSettings?.pn?.mask;
      if (!mask) return false;

      return { mask };
    },
    pnMaskPlaceholder() {
      return this.orgSettings?.pn?.mask;
    },

    isTestForm() {
      return !!(this.isTestEmployee || this.item?.isTest);
    },

    title() {
      const testText = this.isTestForm ? 'тестового ' : '';

      return this.item?.id
        ? `Редактировать ${testText} работника`
        : `Создать ${testText} работника`;
    },
    customRulesHint() {
      if (
        !this.orgSettings ||
        this.orgSettings?.employeeSync?.rule?.employeeModify === false
      ) {
        return;
      }
      return this.orgSettings?.pn?.name;
    },

    validationMessages() {
      // Если сообщение не задано на сервере,
      // выводим дефолтное сообщение
      return {
        'data.personnelNumber.rule':
          this.orgSettings?.pn?.explanation ||
          'Цифры или буквы (кириллица/латиница), а также символ "-"',
        'data.orgId.rule':
          'В данной организации действует запрет на создание и редактирование работников',
      };
    },

    createForbidden() {
      return (
        !this.item.id &&
        this.orgSettings?.employeeSync?.actions?.includes('create')
      );
    },

    isTestInField() {
      const values = Object.values(this.resultCheckTest);
      if (!values.length) return false;
      return values.some(el => el);
    },
  },

  watch: {
    'data.orgId': {
      immediate: true,
      handler(value) {
        // wo do not want to unset values that were set in 'created' cycle
        // from 'predefinedValues'
        if (
          this.predefinedValues.orgId !== this.data.orgId &&
          !isEqual(this.predefinedValues.groupIds, this.data.groupIds)
        ) {
          this.data.groupIds = [];
          this.fetchOrgById(this.predefinedValues?.orgId || value);
          this.fetchOrgSettings(this.predefinedValues?.orgId || value);
        }
      },
    },
  },

  async created() {
    if (this.item.id) this.fetchOrgSettings(this.item.organization.id);

    const predefinedValuesKeys = Object.keys(this.predefinedValues);
    predefinedValuesKeys.length &&
      predefinedValuesKeys.forEach(
        key => (this.data[key] = this.predefinedValues[key]),
      );
  },

  mounted() {
    ['surname', 'name', 'patronymic'].forEach(el =>
      this.checkTestInField(this.data[el], el),
    );
  },

  methods: {
    ...mapActions('STRUCTURES/EMPLOYEE_GROUPS', {
      changeCountOnEmpGroups: 'changeCounter',
    }),
    async fetchOrgById(orgId) {
      if (!orgId) {
        this.orgData = null;
        return;
      }
      await this.$loadingNotify(
        this.$store
          .dispatch('STRUCTURES/fetchOrganizationPreviewsBy', [orgId])
          .then(data => {
            this.orgData = data[0];
          }),
        'fetchOrgById',
        `Произошла ошибка получения данных по организации`,
      ).catch(err => {
        console.error(err);
        this.orgData = null;
      });
    },

    async fetchOrgSettings(orgId) {
      this.orgSettings = null;

      if (!orgId) {
        this.settingsLoaded = true;
        return;
      }

      try {
        this.settingsLoaded = false;
        this.orgSettings = await this.fetchOrganizationSettings(orgId);
      } finally {
        this.settingsLoaded = true;
      }
    },

    async handleSubmit() {
      if (!this.validate()) return;

      const preparedResponse = {
        ...this.data,
        phone: unmaskPhone(this.data.phone),
        snils: unmaskSnils(this.data.snils),
      };

      if (this.$refs.pn)
        preparedResponse.personnelNumber = this.$refs.pn.getUnmaskedValue();

      // convert empty fields ("") to "null"
      Object.keys(preparedResponse).forEach(key => {
        if (preparedResponse[key] === '') preparedResponse[key] = null;
      });

      // FIXME: duplicates error about personell number, idk how to fix it
      // testers otherwise create bug 'missing notification on employee change'
      await this.$loadingNotify(
        this.onSubmit(preparedResponse),
        'submit',
        `Произошла ошибка ${
          this.item.id ? 'редактирования' : 'добавления'
        } работника`,
        `Работник успешно ${this.item.id ? 'изменен' : 'добавлен'}`,
      )
        .then(() => {
          if (!this.item.id && preparedResponse.groupIds) {
            this.changeCountOnEmpGroups({
              itemIds: preparedResponse.groupIds,
              counterField: 'itemsCount',
              addCount: 1,
            });
          }
          this.$emit('close');
        })
        .catch(err => this.catchServerErrors(err, 'data'));
    },

    checkTestInField(value, field) {
      if (this.isTestForm) return false;

      let clearValue = '';
      (value || '').split('').forEach((letter, index, arr) => {
        if (letter !== arr[index - 1]) clearValue += letter;
      });
      const isLikeTest = !!~(clearValue || '').toLowerCase().indexOf('тест');

      this.resultCheckTest = { ...this.resultCheckTest, [field]: isLikeTest };
    },

    uppercase(val) {
      return val ? val.toUpperCase() : val;
    },
  },
};
</script>
