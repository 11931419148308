<template>
  <ColumnLayout :one-column="oneColumn">
    <template #col-view>
      <div
        class="d-flex justify-space-between flex-wrap py-3"
        style="gap: 12px"
      >
        <EmployeePreviewProfile :value="employee" />
        <CardActionBtn
          v-if="!isItemPage"
          label="Открыть карточку"
          :to="{
            name: 'documents:outgoing:item',
            params: { id: document.id },
          }"
          target="_blank"
        />
      </div>
      <v-divider />
      <DefinitionList :value="itemData" :data="document" dense />
    </template>
    <template #col-document>
      <div style="position: relative; height: 100%">
        <object
          v-if="document.url"
          :data="document.url"
          type="application/pdf"
          style="object-fit: contain"
          width="100%"
          height="735px"
        />
        <h2 v-else class="font-weight-light pa-5 text-center mt-6">
          Изображение отсутствует
        </h2>

        <v-btn
          v-if="document.url"
          class="mr-3 mt-3"
          style="background: white"
          color="primary"
          absolute
          top
          right
          icon
          elevation="13"
          target="_blank"
          :href="document.url"
        >
          <v-icon>fa-eye</v-icon>
        </v-btn>
      </div>
    </template>
  </ColumnLayout>
</template>

<script>
import { mapActions } from 'vuex';
import { entity } from './entity';

import ColumnLayout from '@/components/layouts/ColumnLayout.vue';
import EmployeePreviewProfile from '@/modules/employees/components/PreviewProfile';
import DefinitionList from '@/components/DefinitionList';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';
import LinkButton from '@/components/controls/buttons/LinkButton';

export default {
  components: {
    ColumnLayout,
    EmployeePreviewProfile,
    DefinitionList,
    CardActionBtn,
  },

  props: {
    document: { type: Object, required: true },
    employee: { type: Object, required: true },
    oneColumn: Boolean,
  },

  computed: {
    itemData() {
      return entity
        .filter(item => item.label)
        .map(item => {
          const res = {
            label: item.label,
            value: String(
              item.convert
                ? item.convert(this.document, this.$store.getters)
                : this.document[item.key],
            ),
          };

          if (item.key === 'inspectionId') {
            res.component = LinkButton;
            res.props = {
              text: res.value || '–',
              action: this.$can('I_RD') && !!res.value,
              dense: true,
              blank: true,
              to: {
                name: 'inspections:card',
                params: { id: res.value },
              },
            };
          }

          return res;
        });
    },

    isItemPage() {
      return this.$route.name === 'documents:outgoing:item';
    },
  },

  async created() {
    this.fetchOutgoingDocumentTypes();
  },

  methods: {
    ...mapActions('REGISTRY', ['fetchOutgoingDocumentTypes']),
  },
};
</script>
