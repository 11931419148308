<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        {{ value || '-' }}
      </div>
    </template>
    <span> Длительность осмотра на терминале </span>
  </v-tooltip>
</template>

<script>
export default {
  props: { value: { required: true, type: String } },
};
</script>
