<template>
  <v-list v-if="signMeTokens.length" class="pb-0">
    <v-radio-group v-model="active" :disabled="disabled" class="pa-0 ma-0">
      <v-radio
        v-for="{ data } in signMeTokens"
        :key="data.serial"
        :value="data.serial"
        :disabled="!data.isValid"
      >
        <template #label>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title style="white-space: normal">
                {{ name(data.ownerData.person) }} (SignMe)
              </v-list-item-title>
              <v-list-item-subtitle style="white-space: normal">
                Срок действия:
                {{ date(data.validFrom) }} – {{ date(data.validTo) }}
                <span v-if="!data.isValid">(истек)</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-radio>
    </v-radio-group>
  </v-list>
</template>

<script>
import { date, name } from '@/utils/convert';
import waitable from '@/utils/mixins/waitable';
import { SignMeToken } from '@/models/tokens/SignMeToken';

export default {
  mixins: [waitable],

  props: {
    value: {
      default: null,
      validator: value => {
        return typeof value === 'object' || typeof value === 'string';
      },
    },
    accountCertificates: {
      type: Array,
      default: () => [],
    },
    onSubmit: {
      type: Function,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    signMeTokens() {
      const list = this.accountCertificates.filter(el => el.type === 'signme');
      return list.map(el => new SignMeToken(el));
    },

    active: {
      get() {
        if (this.value?.data?.type !== 'signme') return null;
        return this.value?.data?.serial || null;
      },
      set(serial) {
        const el = this.signMeTokens.find(el => el.data.serial === serial);
        this.$emit('input', el);
      },
    },

    disabledLocal() {
      return this.$wait('login') || this.disabled;
    },
  },

  methods: {
    name,
    date,
  },
};
</script>
