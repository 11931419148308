<template>
  <v-expansion-panels class="mt-4 shadow--hide" accordion multiple>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span class="font-weight-bold">События</span>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th class="pa-1 text-caption text-left">
                  Дата отправки, время
                </th>
                <th class="pa-1 text-caption text-left">Действие</th>
                <th class="pa-1 text-caption text-left">Получатель</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(integration, index) in integrations"
                :key="index"
                :class="statusClass(integration.success)"
              >
                <td class="pa-1">
                  {{
                    dateTime(integration.occurredAt, {
                      showSeconds: true,
                      round: true,
                    })
                  }}
                </td>
                <td class="pa-1">
                  <div class="font-weight-bold">
                    {{ message(integration.message).title }}
                  </div>
                  <div v-if="message(integration.message).description">
                    {{ message(integration.message).description }}
                  </div>
                </td>
                <td class="pa-1">{{ type(integration) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { dateTime } from '@/utils/convert';
const INTEGRATION_TYPES = {
  niiat: () => 'НИИАТ',
  waybill: item => `Оператор ЭПЛ ${item.waybillOperatorName}`,
};
export default {
  props: {
    integrations: { type: Array, required: true },
  },
  computed: {
    statusClass() {
      return status => {
        if (!status && status !== false) {
          return null;
        }
        return status ? 'green lighten-2' : 'red';
      };
    },
    message() {
      return message => {
        const title =
          message.indexOf(':') > 0
            ? message.substring(0, message.indexOf(':'))
            : message;
        const description =
          message.indexOf(':') > 0
            ? message.substring(message.indexOf(':') + 1)
            : null;
        return {
          title,
          description,
        };
      };
    },
    type() {
      return item =>
        INTEGRATION_TYPES[item.integrationType]?.(item) || item.integrationType;
    },
  },

  methods: {
    dateTime,
  },
};
</script>
