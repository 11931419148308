<template>
  <ModernModalLayout
    :title="modalTitle"
    size="full"
    height="100vh"
    hide-bottom
    dense-content
  >
    <ColumnLayout grid-template-columns="300px 1fr">
      <template #col-selector>
        <v-tabs
          v-model="tab"
          height="40"
          grow
          :background-color="`grey ${themeIsDark ? 'darken-2' : 'lighten-4'}`"
        >
          <v-tab v-if="$can('DOC_RD')" href="#incoming">Входящие</v-tab>
          <v-tab v-if="$can('ODC_RD')" href="#outgoing">Исходящие</v-tab>
        </v-tabs>
        <v-divider />
        <v-tabs-items v-model="tab" style="height: 100%">
          <v-tab-item :transition="false" value="incoming" style="height: 100%">
            <AsyncScroller
              v-show="tab === 'incoming'"
              :class="`grey ${themeIsDark ? 'darken-2' : 'lighten-4'}`"
              :fetcher="fetchIncomingDocuments"
              dividers
              @fetched.once="$event[0] && fetchIncomingItem($event[0].id)"
            >
              <template #item="{ item }">
                <ListItem
                  :document="item"
                  :value="currentIncomingId"
                  @input="fetchIncomingItem"
                />
              </template>
            </AsyncScroller>
          </v-tab-item>
          <v-tab-item :transition="false" value="outgoing" style="height: 100%">
            <AsyncScroller
              v-show="tab === 'outgoing'"
              :class="`grey ${themeIsDark ? 'darken-2' : 'lighten-4'}`"
              :fetcher="fetchOutgoingDocuments"
              dividers
              @fetched.once="$event[0] && fetchOutgoingItem($event[0].id)"
            >
              <template #item="{ item }">
                <ListItem
                  :document="item"
                  :value="currentOutgoingId"
                  @input="fetchOutgoingItem"
                />
              </template>
            </AsyncScroller>
          </v-tab-item>
        </v-tabs-items>
      </template>

      <template #col-view>
        <v-tabs-items v-model="tab" style="height: 100%">
          <v-tab-item :transition="false" value="incoming" style="height: 100%">
            <Spinner v-if="$wait('fetchingIncomingItem')" />
            <ErrorBlock
              v-else-if="currentIncomingError"
              :error="currentIncomingError"
              @refresh="fetchIncomingItem(currentIncomingId)"
            />
            <IncomingItem
              v-else-if="currentIncomingId && currentIncomingItem"
              :document="currentIncomingItem"
              :employee="employee"
            />
          </v-tab-item>
          <v-tab-item :transition="false" value="outgoing" style="height: 100%">
            <Spinner v-if="$wait('fetchingOutgoingItem')" />
            <ErrorBlock
              v-else-if="currentOutgoingError"
              :error="currentOutgoingError"
              @refresh="fetchOutgoingItem(currentOutgoingId)"
            />
            <OutgoingItem
              v-else-if="currentOutgoingId && currentOutgoingItem.id"
              :document="currentOutgoingItem"
              :employee="employee"
            />
          </v-tab-item>
        </v-tabs-items>
      </template>
    </ColumnLayout>
  </ModernModalLayout>
</template>

<script>
import medrecApi from '@/api/services/medrec';
import { mapGetters, mapActions } from 'vuex';

import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import ColumnLayout from '@/components/layouts/ColumnLayout';
import AsyncScroller from '@/components/AsyncScroller';
import Spinner from '@/components/Spinner';

import IncomingItem from '@/modules/documents/incoming/components/IncomingItem';
import OutgoingItem from '@/modules/documents/outgoing/Item.component';
import ListItem from './ListItem.component';
import ErrorBlock from '@/components/ErrorBlock';

import waitable from '@/utils/mixins/waitable';
import { shortName } from '@/utils/convert';

export default {
  components: {
    ModernModalLayout,
    ColumnLayout,
    ListItem,
    AsyncScroller,
    Spinner,
    IncomingItem,
    OutgoingItem,
    ErrorBlock,
  },

  mixins: [waitable],

  props: {
    employee: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    tab: 0,

    currentIncomingId: null,
    currentIncomingError: null,

    currentOutgoingId: null,
    currentOutgoingError: null,
  }),

  computed: {
    ...mapGetters('DOCUMENTS/INCOMING_ITEM', {
      currentIncomingItem: 'singleItem',
    }),
    ...mapGetters('DOCUMENTS/OUTGOING_ITEM', {
      currentOutgoingItem: 'singleItem',
    }),
    ...mapGetters(['themeIsDark']),

    modalTitle() {
      return 'Документы сотрудника - ' + shortName(this.employee);
    },
  },
  methods: {
    ...mapActions('DOCUMENTS/INCOMING_ITEM', {
      fetchIncomingDoc: 'fetchSingle',
    }),
    ...mapActions('DOCUMENTS/OUTGOING_ITEM', {
      fetchOutgoingDoc: 'fetchSingle',
    }),

    fetchIncomingDocuments({ page, limit }) {
      return medrecApi.documentList({
        employeeIds: this.employee.id,
        page,
        limit,
      });
    },
    fetchOutgoingDocuments({ page, limit }) {
      return medrecApi.documentOutgoingList({
        employeeIds: this.employee.id,
        page,
        limit,
      });
    },

    async fetchIncomingItem(id) {
      this.currentIncomingId = id;

      try {
        this.currentIncomingError = null;
        await this.$loading(this.fetchIncomingDoc(id), 'fetchingIncomingItem');
      } catch (error) {
        this.currentIncomingError = error;
      }
    },

    async fetchOutgoingItem(id) {
      this.currentOutgoingId = id;

      try {
        this.currentOutgoingError = null;
        await this.$loading(this.fetchOutgoingDoc(id), 'fetchingOutgoingItem');
      } catch (error) {
        this.currentOutgoingError = error;
      }
    },
  },
};
</script>
