import integrationsApi from '@/api/services/integrations';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { createModule } from '@/utils/vuex/createModule';
import { filters } from '../entity';
import { changePages } from '@/utils/vuex/changePages';
import { currentDateTime } from '@/utils/helpers';
import { getUserTimeZone } from '@/utils/convert';

const state = {
  datePlaceholder: null,
};

const getters = {
  datePlaceholder: state => state.datePlaceholder,
};

const mutations = {
  datePlaceholder: (state, value) => (state.datePlaceholder = value),
};

const actions = {
  async fetchList({ commit, getters }, query) {
    await changePages(getters, commit, query);

    const params = { ...getters.listQuery };

    commit('datePlaceholder', null);
    // Если поиск по осмотру или УИД_ПЛ, то поиск только по ним
    if (!params.inspectionId && !params.documentFlowId) {
      // Задаем дефолтное значение на выборку за последние 24 часа
      if (!params?.shiftDateFrom && !params?.shiftDateTo) {
        const dateWithZone = currentDateTime().setZone(getUserTimeZone() * 60, {
          keepLocalTime: true,
        });
        params.shiftDateFrom = dateWithZone.minus({ days: 1 }).toISO();
        params.shiftDateTo = dateWithZone.toISO();

        commit('datePlaceholder', {
          dateStart: params.shiftDateFrom,
          dateEnd: params.shiftDateTo,
        });
      }
    }

    try {
      commit('listFetching');
      const response = await integrationsApi.getEplList(params);
      commit('listFetched', response);
    } catch (error) {
      commit('listErrorFetched', error);
      throw error;
    }
  },

  setDateFilters({ commit }, { dateStart, dateEnd }) {
    commit('shiftDateFrom', dateStart);
    commit('shiftDateTo', dateEnd);
  },
};

export default createModule(
  crudListFactory({
    filters,
  }),
  { state, getters, mutations, actions },
);
