<template>
  <div style="overflow-y: auto">
    <div style="height: 150px" class="pb-3">
      <UploaderBox
        label="Добавить изображение"
        :error-messages="errorMessages"
        @change="uploadImage"
      />
    </div>
    <div v-for="(image, index) in value" :key="index">
      <img
        max-height="100"
        class="multiple-image-uploader__image"
        :src="imageAsUrl(image)"
        contain
        eager
      />
      <div class="text-center mt-1 mb-1">
        <v-btn
          class="mr-1"
          color="primary"
          small
          text
          outlined
          @click="openEditor(index)"
        >
          Редактировать
        </v-btn>
        <v-btn color="primary" small text outlined @click="removeImage(index)">
          Удалить
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import UploaderBox from '@/components/controls/UploaderBox.vue';

export default {
  components: { UploaderBox },

  props: {
    value: {
      type: Array,
      required: true,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    imageAsUrl() {
      return image => URL.createObjectURL(image);
    },
  },

  methods: {
    async uploadImage(image) {
      this.$emit('input', [...this.value, image]);

      if (image) {
        await this.$nextTick();
        this.openEditor(this.value.length - 1);
      }
    },

    openEditor(index) {
      this.$openModal('imageEditor', {
        value: this.value[index],
        change: image => this.updateImage(index, image),
      });
    },

    removeImage(index) {
      this.$emit('input', [
        ...this.value.slice(0, index),
        ...this.value.slice(index + 1),
      ]);
    },

    updateImage(index, newImage) {
      this.$emit('input', [
        ...this.value.slice(0, index),
        newImage,
        ...this.value.slice(index + 1),
      ]);
    },
  },
};
</script>

<style lang="scss">
.multiple-image-uploader__image {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
