import pluralize from 'pluralize';

/**
 * Resource Service Factory
 * @param {AxiosInstance} service
 * @param {string | string[]} resource
 * @param {["status" | "groups" | "put"]} internals
 */
export default function resourceServiceFactory(
  service,
  resource = 'resource',
  internals = [],
) {
  resource = Array.isArray(resource) ? resource : [resource];

  const url = '/' + resource.map(name => pluralize(name)).join('/');
  const action = resource
    .reduce((acc, value) => {
      const res = value.indexOf('_') > -1 ? value.split('_') : [value];
      // special treatment just for 'template-sets'
      res[0] = res[0].replace('-', '_');
      acc.push(...res);
      return acc;
    }, [])
    .reduce((acc, value, index) => {
      if (index === 0) return value;
      else return acc + value[0].toUpperCase() + value.slice(1);
    });

  let routes = {
    [action + 'List'](params) {
      return service.get(url, { params }).then(res => res.data);
    },
    [action + 'Get'](id) {
      return service.get(`${url}/${id}`).then(res => res.data);
    },
    [action + 'Create'](data) {
      return service.post(url, data).then(res => res.data);
    },
    [action + 'Update'](data) {
      return service
        .patch(`${url}/${data.id || data.key}`, data)
        .then(res => res.data);
    },
    [action + 'Delete'](id) {
      return service.delete(`${url}/${id}`).then(res => res.data);
    },
  };

  if (internals.includes('put'))
    routes = {
      ...routes,
      [action + 'Change'](data) {
        return service
          .put(`${url}/${data.id || data.key}`, data)
          .then(res => res.data);
      },
    };

  if (internals.includes('status'))
    routes = {
      ...routes,
      [action + 'Enable'](id) {
        return service.post(`${url}/${id}/enable`);
      },

      [action + 'Disable'](id) {
        return service.post(`${url}/${id}/disable`);
      },
    };

  if (internals.includes('groups'))
    routes = {
      ...routes,
      [action + 'UpdateGroups'](id, groups) {
        return service
          .post(`${url}/${id}/groups`, { groupIds: groups })
          .then(res => res.data);
      },

      [action + 'DeleteGroup'](id, groupId) {
        return service.delete(`${url}/${id}/groups/${groupId}`);
      },
    };

  return routes;
}
