<template>
  <ModernModalLayout
    :title="data.id ? 'Редактирование группы ПАК' : 'Добавление группы ПАК'"
    close-label="Отмена"
    :watch-for="data"
  >
    <v-form ref="form" :disabled="isLoading || disabled || $wait('submit')">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="data.name"
            label="Название"
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('data.name')"
            @blur="validateField('data.name')"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="data.description"
            label="Описание"
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('data.description')"
            @blur="validateField('data.description')"
          />
        </v-col>
        <v-col cols="12">
          <OrganizationSelect
            v-if="!data.id"
            v-model="data.orgId"
            access-level="full"
            hide-details="auto"
            :error-messages="getValidationErrors('data.orgId')"
            @blur="validateField('data.orgId')"
          />
        </v-col>
      </v-row>
    </v-form>

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :disabled="$wait('submit')"
        @click="submit"
      >
        {{ data.id ? 'Сохранить' : 'Добавить' }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';

import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import { required, requiredIf } from 'vuelidate/lib/validators';

export default {
  components: {
    ModernModalLayout,
    OrganizationSelect,
  },
  mixins: [validation, waitable],

  props: {
    value: { type: Object, default: () => ({ orgId: null }) },
    disabled: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },

    predefinedValues: { type: Object, default: () => ({}) },
    storeForCreate: { type: String, default: 'STRUCTURES/HOST_GROUPS' },
  },

  data() {
    return {
      data: { ...this.value },
    };
  },

  validations() {
    return {
      data: {
        name: { required },
        description: { required },
        orgId: { required: requiredIf(() => !this.data.id) },
      },
    };
  },

  async created() {
    !this.value.id &&
      Object.keys(this.predefinedValues).length &&
      Object.keys(this.predefinedValues).forEach(
        key => (this.data[key] = this.predefinedValues[key]),
      );
  },

  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const actionName = this.data.id
        ? 'STRUCTURES/HOST_GROUPS_ITEM/updateSingleItem'
        : `${this.storeForCreate}/createListItem`;

      await this.$loadingNotify(
        this.$store.dispatch(actionName, this.data),
        'submit',
        `Произошла ошибка ${
          this.data.id ? 'изменения' : 'добавления'
        } группы ПАК`,
        `Группа ПАК успешно ${this.data.id ? 'изменена' : 'добавлена'}`,
      )
        .then(() => this.$emit('close'))
        .catch(err => this.catchServerErrors(err, 'data'));
    },
  },
};
</script>
