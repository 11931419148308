<template>
  <List
    search-filter
    :available-actions="{
      showAction: $can('PNT_RD'),
      createAction: $can('PNT_C'),
    }"
    @item:create="formModal"
  >
    <template #filters="query">
      <v-col cols="12" sm="4" md="2">
        <v-select
          v-model="query.isActive"
          label="Статус точки"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :items="statusItems"
          :menu-props="{ offsetY: true }"
        />
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <OrganizationSelect v-model="query.orgIds" multiple dense />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.isMobile"
          label="Мобильная"
          :items="booleanItems"
          item-value="value"
          item-text="name"
          hide-details
          outlined
          dense
          :menu-props="{ offsetY: true }"
        />
      </v-col>
    </template>

    <template #table.item.isActive="{ item }">
      <Indicator :active="item.isActive" />
    </template>

    <template #table.item.organization="{ item }">
      <v-tooltip v-if="item.organization.shortName" right>
        <template #activator="{ on }">
          <span v-on="on">Орг.: {{ item.organization.shortName }}</span>
        </template>
        {{ item.organization.name }}
      </v-tooltip>
      <template v-else>
        {{ item.organization.name }}
      </template>
    </template>

    <template #table.item.pointOnMap="{ item }">
      <div v-if="item.address">{{ item.address }}</div>
      <div
        v-if="
          (item.coordinates || {}).latitude ||
          (item.coordinates || {}).latitude === 0
        "
      >
        Координаты:
        {{ item.coordinates.latitude }};&nbsp;{{ item.coordinates.longitude }}
      </div>
      <v-chip v-if="item.isMobile" small>Мобильная</v-chip>
    </template>
  </List>
</template>

<script>
import { STATUS, BOOLEANS } from '@/utils/constants';
import Indicator from '@/components/Indicator.vue';

import ListFactory from '@/components/crud/ListFactory';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';

import { messages, entity, filters } from '../entity';

const List = ListFactory({
  storeModule: 'STRUCTURES/POINTS',
  messages,
  entity,
  filters,
  viewRoute: 'structures:points:item',
});

export default {
  components: { List, OrganizationSelect, Indicator },

  computed: {
    statusItems: () => STATUS,
    booleanItems: () => BOOLEANS,
  },

  methods: {
    formModal() {
      this.$openModal('structures/pointsForm');
    },
  },
};
</script>
