<template>
  <v-menu top offset-y>
    <template #activator="{ on, attrs }">
      <v-btn class="ml-4" v-bind="attrs" small v-on="on">Фейк</v-btn>
    </template>
    <v-card class="pa-3" width="300px">
      <div class="text-subtitle-2">Сгенерировать осмотр</div>
      <div class="text-caption text--disabled mb-2">
        Учтите, что медосмотр будет назначен на первого свободного медработника
      </div>
      <div v-for="(fake, type) in fakes" :key="type">
        <div class="text-caption">{{ type }}</div>
        <v-btn
          v-for="item in fake"
          :key="item.id"
          text
          @click="generateFakeInspection(item.id)"
        >
          {{ '#' + item.name }}
        </v-btn>
      </div>

      <div>
        <div class="text-caption">Создать пачку</div>
        <v-btn text @click="generateManyFakeInspection(10)">+ 10</v-btn>
        <v-btn text @click="generateManyFakeInspection(20)">+ 20</v-btn>
        <v-btn text @click="generateManyFakeInspection(30)">+ 30</v-btn>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
function randomInteger(min, max) {
  const rand = min + Math.random() * (max + 1 - min);
  return Math.floor(rand);
}

export default {
  computed: {
    ...mapGetters('MEDCAB', ['fakes']),
  },

  created() {
    this.fetchFakes();
  },

  methods: {
    ...mapActions('MEDCAB', ['generateFakeInspection', 'fetchFakes']),

    async generateManyFakeInspection(count) {
      const fakes = Object.entries(this.fakes).reduce(
        (acc, [_, el]) => [...acc, ...el],
        [],
      );

      for (let i = 0; i < count; i++) {
        const index = randomInteger(0, fakes.length - 1);
        this.generateFakeInspection(fakes[index].id);
      }
    },
  },
};
</script>
