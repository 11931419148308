<template>
  <Select
    label="Причина недопуска"
    dense
    multiple
    :value="value"
    :items="innerItems"
    item-text="name"
    item-value="id"
    :disabled="disabled"
    @input="$emit('input', $event)"
  />
</template>

<script>
import { REMARK_TYPES } from '@/utils/constants';
import Select from '@/components/controls/Select';

export default {
  components: {
    Select,
  },
  props: {
    value: {
      type: [Array, String],
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    disabled: Boolean,
  },
  computed: {
    innerItems() {
      return Object.entries(REMARK_TYPES)
        .map(([typeId, name]) => {
          const itemsForType = this.items.filter(
            ({ showAs }) => showAs === typeId,
          );
          if (!itemsForType.length) return [];

          return [{ header: name }, ...itemsForType, { divider: true }];
        })
        .flat();
    },
  },
};
</script>
