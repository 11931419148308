<template>
  <div>
    <Spinner v-if="loadingItem" />
    <div v-else>
      <v-tabs v-model="currentTab">
        <v-tab href="#details">Общая информация</v-tab>
        <v-tab href="#sessions">{{ sessionsName }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab">
        <v-tab-item value="details">
          <v-card class="my-4 ml-1 mr-4">
            <v-simple-table>
              <template #default>
                <tbody>
                  <tr v-for="(row, rowIndex) in itemTable" :key="rowIndex">
                    <td class="px-4 py-2">{{ row.label }}</td>
                    <td class="px-4 py-2">{{ row.text }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>

          <v-card class="my-4 ml-1 mr-4">
            <v-simple-table>
              <template #default>
                <tbody>
                  <tr v-for="(row, rowIndex) in timings" :key="rowIndex">
                    <td class="px-4 py-2">{{ row.label }}</td>
                    <td class="px-4 py-2">{{ row.text }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-tab-item>

        <v-tab-item value="sessions">
          <div v-if="type === 'active'" class="my-4 ml-1 mr-4">
            <v-alert v-if="!sessions.length" type="info" outlined>
              Отсутствуют
            </v-alert>
            <MedicStatsSession v-else :value="sessions" />
          </div>

          <template v-if="type === 'passive'">
            <v-alert v-if="!passiveEvents.length" type="info" outlined>
              Отсутствуют
            </v-alert>
            <Card v-else class="my-4 ml-1 mr-4">
              <MedicStatsEvents :value="passiveEvents" />
            </Card>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import plural from 'plural-ru';
import processingApi from '@/api/services/processing';
import { SHIFT_TYPES } from '@/modules/medcab/constants';
import { date, dateTime, secondsToWordTime } from '@/utils/convert';

import MedicStatsSession from './MedicStatsSession.vue';
import MedicStatsEvents from './MedicStatsEvents.vue';
import Card from '@/components/ui/Card.vue';
import Spinner from '@/components/Spinner.vue';

export default {
  components: {
    MedicStatsSession,
    MedicStatsEvents,
    Card,
    Spinner,
  },

  props: {
    value: {
      required: true,
      type: Number,
    },
    // Получить смены текущего пользователя-медика
    my: Boolean,
  },

  data: () => ({
    item: null,
    currentTab: 'details',
    loadingItem: false,
  }),

  computed: {
    type() {
      return this.item?.type;
    },

    sessionsName() {
      return this.type === 'active' ? 'Сессии' : 'События';
    },

    itemTable() {
      const item = this.item;
      if (!item) return [];

      const isActive = item.type === 'active';

      const data = [
        {
          label: 'Номер',
          text: item.id,
        },
        {
          label: 'Тип медкабинета',
          text: SHIFT_TYPES[item.type] || item.type,
        },
        {
          label: 'Осмотры',
          text: `всего ${item.summary.resolved}, положительных ${item.summary.positive}, отрицательных ${item.summary.negative}`,
        },
        {
          label: 'Начата',
          text: dateTime(item.openedAt),
        },
        {
          label: 'Завершена',
          text: item.closedAt ? dateTime(item.closedAt) : '–',
        },
        {
          label: 'На смене',
          text: secondsToWordTime(item.totalSec) || '–',
        },
      ];

      if (isActive)
        data.push({
          label: 'На линии',
          text: secondsToWordTime(item.onlineSec) || '–',
        });

      return data;
    },

    timings() {
      const item = this.item;
      if (!item) return [];

      const isActive = item.type === 'active';

      const data = [
        {
          label: 'Время истекло',
          text: `${item.timeouts} ${plural(
            item.timeouts,
            'раз',
            'раза',
            'раз',
          )}`,
        },
        {
          label: 'На принятие решения (среднее)',
          text: `${item.summary.activities.resolutions.averageSec || '–'} сек`,
        },
      ];

      if (isActive)
        data.push({
          label: 'На взятие в работу (среднее)',
          text: `${item.summary.activities.takes.averageSec || '–'} сек`,
        });

      return data;
    },

    sessions() {
      return this.item?.sessions || [];
    },

    passiveEvents() {
      // passive medcab included events outside sessions
      return this.item?.events || [];
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.fetchItem(val);
      },
    },
  },

  methods: {
    dateTime,

    isOneDay(start, finish) {
      return date(start) === date(finish);
    },

    handleFetch(shiftId) {
      if (this.my) return processingApi.getMyStatsShifts(shiftId);
      else return processingApi.getStatsMedicShifts(shiftId);
    },

    async fetchItem(shiftId) {
      if (!shiftId) {
        this.item = null;
        return;
      }

      this.loadingItem = true;

      try {
        const data = await this.handleFetch(shiftId);
        this.item = data;
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingItem = false;
      }
    },
  },
};
</script>
