import { render, staticRenderFns } from "./AccountBindingsPreview.component.vue?vue&type=template&id=21cca0c5&"
import script from "./AccountBindingsPreview.component.vue?vue&type=script&lang=js&"
export * from "./AccountBindingsPreview.component.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VAlert,VSimpleTable})
