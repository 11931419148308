<template>
  <div class="file-hash__container">
    <div v-if="isError && errorMessage" class="red--text">
      {{ errorMessage }}
    </div>
    <div v-else class="file-hash__buttons">
      <!-- Формат подписи -->
      <div v-if="signedText || helpLabel" class="d-flex align-items-center">
        <span v-if="signedText" class="mr-3">{{ signedText }}</span>
        <v-tooltip v-if="helpLabel" bottom>
          <template #activator="{ on, attrs }">
            <v-btn x-small v-bind="attrs" icon v-on="on">
              <v-icon size="16">far fa-question-circle</v-icon>
            </v-btn>
          </template>
          <div style="width: 300px">{{ helpLabel }}</div>
        </v-tooltip>
      </div>

      <!-- Кнопки скачивания -->
      <div>
        <v-btn
          v-if="file"
          class="mb-0"
          :class="[{ 'px-1': !dense, 'pa-0': dense }]"
          :style="[dense ? { height: 'auto' } : {}]"
          small
          text
          color="primary"
          @click="() => downloadFile(file)"
        >
          {{ fileLabel }}
        </v-btn>
        <template v-if="fileSignature">
          <span class="mx-1">/</span>
          <v-btn
            class="mb-0"
            :class="[{ 'px-1': !dense, 'pa-0': dense }]"
            :style="[dense ? { height: 'auto' } : {}]"
            small
            text
            color="primary"
            @click="() => downloadFile(fileSignature)"
          >
            {{ fileSignatureLabel }}
          </v-btn>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                x-small
                v-bind="attrs"
                icon
                class="ml-2 mb-0"
                target="_blank"
                href="https://www.gosuslugi.ru/pgu/eds"
                v-on="on"
              >
                <img src="@/assets/images/gosuslugi-icon.png" width="20" />
              </v-btn>
            </template>
            <div style="width: 350px">
              Вы можете проверить подпись на сайте "Госуслуги".<br />
              Выбирайте вариант "отсоединённая подпись".
            </div>
          </v-tooltip>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file: { type: String, default: null },
    fileLabel: { type: String, default: 'Скачать файл' },
    helpLabel: { type: String, default: null },
    fileSignature: { type: String, default: null },
    fileSignatureLabel: { type: String, default: 'Скачать подпись' },
    signedText: { type: String, default: null },
    errorMessage: { type: String, default: '' },
    isError: { type: Boolean, default: false },
    dense: Boolean,
  },

  methods: {
    downloadFile(url) {
      if (!url) return;
      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="scss">
.file-hash__container {
  padding: 3px 0;
}
</style>
