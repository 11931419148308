<template>
  <Card title="Настройка авторизации" dense>
    <AsyncWrapper :handler="fetchAuthSettings">
      <v-simple-table dense>
        <tbody>
          <tr v-for="type in authTypes" :key="type">
            <td class="grey--text px-4 py-3">
              {{ authTypeName(type) }}
            </td>
            <td class="px-4 py-3" style="width: 50px">
              <v-switch
                :input-value="settings.surveysEnabled[type]"
                class="ma-0 pa-0"
                inset
                :disabled="!$can('xxx')"
                hide-details
                :loading="$wait('handleAuthSettingsUpdate' + type)"
                @mousedown="handleAuthSettingsUpdate(type)"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </AsyncWrapper>
  </Card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import { AUTH_TYPES } from '@/utils/constants';

import AsyncWrapper from '@/components/AsyncWrapper';
import Card from '@/components/ui/Card';

export default {
  components: { AsyncWrapper, Card },
  mixins: [waitable],

  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', ['authTypes']),
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', [
      'fetchAuthSettings',
      'changeAuthSettings',
    ]),

    authTypeName(key) {
      return AUTH_TYPES[key];
    },

    handleAuthSettingsUpdate(key) {
      const newStatus = !this.authTypes[key];
      this.$openModal('prompt', {
        yes: 'Да',
        no: 'Отмена',
        title: 'Подтверждение',
        description: `Вы действительно хотите ${
          newStatus ? 'подключить' : 'отключить'
        } авторизацию '${this.authTypeName(key)}'?`,
        messages: {
          successfulAction: `Авторизация '${this.authTypeName(key)}' успешно ${
            newStatus ? 'подключена' : 'отключена'
          }`,
          errorAction: `Ошибка ${
            newStatus ? 'подключения' : 'отключения'
          } авторизации '${this.authTypeName(key)}'`,
        },
        onSubmit: () =>
          this.$loading(
            this.changeSurveyStatus(key),
            'handleAuthSettingsUpdate' + key,
          ),
      });
    },
  },
};
</script>
