<template>
  <ModernModalLayout
    :title="actionName"
    modal-class="resolve-modal"
    :size="size"
  >
    <v-form>
      <v-row>
        <v-col style="overflow-y: auto">
          <div
            v-for="(remarkType, index) in remarkTypes"
            :key="index"
            class="mb-4"
          >
            <div
              class="mb-2 pa-2 text-h6"
              :class="{ 'grey lighten-3': !$vuetify.theme.dark }"
            >
              {{ remarkTypeName(remarkType) }}
            </div>
            <v-row>
              <ResolveRemarkItem
                v-for="reason in remarksFor(remarkType)"
                :key="reason.id"
                :item="reason"
                :value="selectedRemarks.includes(reason.id)"
                :readonly="isRemarkBlocked(reason.id)"
                @input="toggleRemarks(reason.id)"
              />
            </v-row>
          </div>
          <v-textarea
            v-if="!assistantDisabled"
            v-model="comment"
            class="mt-4"
            filled="filled"
            auto-grow="auto-grow"
            rows="1"
            label="Комментарий"
            hide-details="auto"
            :error-messages="getValidationErrors('comment')"
            @blur="validateField('comment')"
          />
        </v-col>
        <v-col v-if="assistantDisabled" style="overflow-y: auto">
          <CommentAssistantDisable
            ref="commentAssistantDisable"
            :medcab-type="medcabType"
            :manual-measure="medcabType === 'passive'"
            @input="value => (comment = value)"
          />
        </v-col>
      </v-row>
    </v-form>
    <template #actions:append>
      <v-btn
        class="px-5"
        color="primary"
        data-sentry-mark="medcab:resolve"
        depressed
        :disabled="actionDisabled"
        @click="handleResolve"
      >
        {{ actionName }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import { maxLength } from 'vuelidate/lib/validators';
import validationMixin from '@/utils/validation';

import { REMARK_TYPES } from '@/utils/constants';
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import ResolveRemarkItem from '@/modules/medcab/components/ResolveRemarkItem';
import CommentAssistantDisable from './CommentAssistantDisable';

export default {
  components: { ModernModalLayout, ResolveRemarkItem, CommentAssistantDisable },

  mixins: [validationMixin],

  props: {
    onSubmit: { type: Function, required: true },
    medcabType: {
      type: String,
      default: 'active',
      validator: value => ['active', 'passive'].includes(value),
    },
  },

  data: () => ({
    selectedRemarks: [],
    comment: '',
  }),

  validations() {
    return {
      comment: { maxSymbolsLength: maxLength(1000) },
    };
  },

  computed: {
    ...mapGetters('REGISTRY', ['inspectionRemarks']),
    ...mapGetters('MEDCAB_INSPECTION', [
      'type',
      'assistance',
      'assistantDisabled',
    ]),

    size() {
      return this.assistantDisabled
        ? this.medcabType === 'passive'
          ? 'viewport'
          : 'xlarge'
        : 'large';
    },

    actionName() {
      const actions = this.type?.details?.actions || {};
      return actions.fail || actions.validate;
    },

    actionDisabled() {
      const actions = this.type?.details?.actions || {};

      if (actions.validate) return false;

      return !this.selectedRemarks.length;
    },

    remarkTypes() {
      const types = Object.keys(REMARK_TYPES);
      return (this.inspectionRemarks || [])
        .reduce((acc, item) => {
          if (!acc.includes(item.showAs)) return [...acc, item.showAs];
          return acc;
        }, [])
        .sort((a, b) => types.indexOf(a) - types.indexOf(b));
    },
    remarksFor() {
      return typeName =>
        this.inspectionRemarks?.filter(
          item =>
            item.showAs === typeName &&
            item.id !== 'odd_behavior' &&
            item.id !== 'inebriation',
        ) || [];
    },
    remarkTypeName() {
      return typeName => REMARK_TYPES[typeName] || typeName;
    },
    isRemarkBlocked() {
      return name =>
        this.assistantDisabled
          ? false
          : this.assistance?.remarks?.find(({ id }) => id === name)?.level ===
            'obligatory';
    },
    inspectionRemarksMapById() {
      return this.inspectionRemarks.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
    },
  },

  mounted() {
    (this.assistance?.remarks || []).forEach(
      ({ id, value }) => value && this.selectedRemarks.push(id),
    );
  },

  methods: {
    toggleRemarks(remarkId) {
      const foundIndex = this.selectedRemarks.findIndex(id => id === remarkId);
      if (foundIndex > -1) {
        this.selectedRemarks.splice(foundIndex, 1);
      } else {
        this.selectedRemarks.push(remarkId);
      }
    },

    async handleResolve() {
      if (
        this.assistantDisabled &&
        this.$refs.commentAssistantDisable?.validate
      ) {
        const valid = this.$refs.commentAssistantDisable.validate();

        if (!valid) {
          this.$notify({
            group: 'note',
            type: 'error',
            title: 'Обнаружены неверно заполнены поля',
            text: 'Внесите изменения и попробуйте снова',
          });
          return;
        }
      }

      if (!this.validate()) return;
      const selectedRemarksName = this.selectedRemarks.map(
        remarkId => this.inspectionRemarksMapById[remarkId].name,
      );
      this.$openModal(
        'prompt',
        {
          title: this.actionName + '?',
          onSubmit: async () => {
            this.$emit('close');
            this.onSubmit(this.comment, this.selectedRemarks);
          },
          yes: 'Подтвердить',
        },
        null,
        {
          description: () => {
            return (
              <div>
                {selectedRemarksName && !!selectedRemarksName.length && (
                  <div>
                    <b>Замечания:</b>
                    <ul class="my-3">
                      {selectedRemarksName.map(remarkName => (
                        <li>{remarkName}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {this.comment && (
                  <div>
                    <b>Комментарий: </b>
                    <span>{this.comment}</span>
                  </div>
                )}
              </div>
            );
          },
        },
      );
    },
  },
};
</script>

<style lang="scss">
// NOTE: not use scoped attr here!
.resolve-modal {
  position: absolute;
  top: 175px;
  bottom: 0px;
}
</style>
