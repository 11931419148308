import structApi from '@/api/services/structures';
import crudSingleFactory from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

export default createModule(
  crudSingleFactory({
    fetchMethod: structApi.hostGroupGet,
    updateMethod: structApi.hostGroupUpdate,
    linkedList: 'STRUCTURES/HOST_GROUPS',
  }),
);
