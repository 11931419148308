<template>
  <ModernModalLayout
    :title="`Просмотр документа - ${shortName(employee)}`"
    size="xlarge"
    hide-bottom
    dense-content
  >
    <AsyncWrapper :handler="fetch">
      <IncomingItem
        class="pl-5"
        :document="singleItem"
        :employee="employee"
        :employee-active-ban="employeeActiveBans[0]"
        @close="$emit('close')"
      />
    </AsyncWrapper>
  </ModernModalLayout>
</template>

<script>
import ModernModalLayout from '@/components/layouts/ModernModalLayout.vue';
import IncomingItem from '@/modules/documents/incoming/components/IncomingItem';
import AsyncWrapper from '@/components/AsyncWrapper.vue';

import waitable from '@/utils/mixins/waitable';
import { mapActions, mapGetters } from 'vuex';
import { shortName } from '@/utils/convert';

export default {
  components: { ModernModalLayout, IncomingItem, AsyncWrapper },
  mixins: [waitable],
  props: {
    id: { type: Number, required: true },
    employee: { type: Object, required: true },
  },

  computed: {
    ...mapGetters('DOCUMENTS/INCOMING_ITEM', [
      'singleItem',
      'employeeActiveBans',
    ]),
  },

  methods: {
    shortName,

    ...mapActions('DOCUMENTS/INCOMING_ITEM', [
      'fetchSingle',
      'fetchEmployeeBans',
    ]),

    async fetch() {
      await this.fetchSingle(this.id);

      // fir for phantom error when in medcab medic tries to open document
      // which was used to unban a user and for some reason there is no employee
      // id
      await this.$loadingNotify(
        await this.fetchEmployeeBans({
          employeeId: this.employee.id,
          documentType: this.singleItem.type,
        }),
        'fetchingEmployeeBans',
        'У вас нет доступа.',
      );
    },
  },
};
</script>
