<template>
  <ItemPageLayout
    back-route="structures:organization_groups"
    :title="singleItem.name"
  >
    <Card :title="item.isHolding ? 'Данные холдинга' : 'Данные группы'" dense>
      <DefinitionList :value="computedEntity" />
      <template #action>
        <v-btn
          v-if="$can('ORGG_U')"
          depressed
          small
          text
          color="primary"
          @click="editModal"
        >
          Редактировать
        </v-btn>
      </template>
    </Card>

    <template #aside>
      <ActionsMenu>
        <ActionsMenuDelete
          v-if="$can('ORGG_D')"
          :id="singleItem.id"
          store-module="STRUCTURES/ORGANIZATION_GROUPS"
          back-route="structures:organization_groups"
          :title="`Удалить ${
            item.isHolding ? 'холдинг' : 'группу организаций'
          }`"
          :description="`Вы уверены, что хотите удалить ${
            item.isHolding ? 'холдинг' : 'группу организаций'
          }?`"
          :disabled="!!singleItem.allMembersCount"
          :subtitle="
            singleItem.allMembersCount
              ? `Нельзя удалить, к ${
                  item.isHolding ? 'холдингу' : 'группе'
                } подключены организации`
              : ''
          "
        />
      </ActionsMenu>
    </template>

    <template v-if="$can('ORG_R')" #innerList>
      <Limits
        v-if="$can('LM_R') && !singleItem.isHolding"
        :org-or-group-id="singleItem.id"
        groupp
      />

      <List
        search-filter
        :available-actions="{
          showAction: $can('ORG_RD'),
          createAction: $can('ORG_C'),
        }"
        @item:create="openCreate"
      >
        <template #filters="query">
          <v-col cols="12" sm="4" md="3">
            <v-select
              v-model="query.isActive"
              label="Статус организаций"
              :items="statusOrgsItems"
              item-value="value"
              item-text="name"
              hide-details
              outlined
              dense
              :menu-props="{ offsetY: true }"
            />
          </v-col>
        </template>

        <template #table.item.nameShortName="{ item }">
          <b>{{ item.name }}</b>
          <div v-if="item.shortName">{{ `Сокр.: ${item.shortName}` }}</div>
        </template>

        <template #table.item.inn_kpp="{ item }">
          ИНН:&nbsp;{{ item.inn }}
          <div v-if="item.kpp">КПП:&nbsp;{{ item.kpp }}</div>
        </template>

        <template #table.item.isActive="{ item }">
          <Indicator :active="item.isActive" />
        </template>

        <template #table.item.isMedical="{ item }">
          <Round
            :type="item.isMedical ? 'green lighten-2' : 'grey lighten-1'"
          />
        </template>
      </List>
    </template>
  </ItemPageLayout>
</template>

<script>
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';
import ItemPageLayout from '@/components/layouts/ItemPageLayout';
import Card from '@/components/ui/Card';
import ActionsMenu from '@/components/ui/ActionsMenu';
import DefinitionList from '@/components/DefinitionList';
import ActionsMenuDelete from '@/components/ActionsMenuDelete';
import Limits from '@/components/limits/LimitList.vue';
import Round from '@/components/Round.vue';
import Indicator from '@/components/Indicator.vue';

import { entity } from '../entity';
import { STATUS } from '@/utils/constants.js';

// List section code
import ListFactory from '@/components/crud/ListFactory';
import {
  messages,
  entity as embedEntity,
  filters,
} from '@/modules/structures/organizations/entity';
const List = ListFactory({
  storeModule: 'STRUCTURES/ORGANIZATION_GROUPS_ORGANIZATION_LIST',
  messages,
  entity: embedEntity.filter(val => val.key !== 'groups'),
  filters,
  viewRoute: 'structures:organizations:item',
  embedded: true,
});

export default {
  components: {
    ItemPageLayout,
    DefinitionList,
    Card,
    ActionsMenu,
    ActionsMenuDelete,

    List,
    Limits,
    Round,
    Indicator,
  },
  mixins: [routeGuardMixin],

  computed: {
    computedEntity() {
      return this.singleItem
        ? entity.map(({ key, label, convert }) => ({
            label,
            value: convert ? convert(this.singleItem) : this.singleItem[key],
          }))
        : [];
    },

    statusOrgsItems: () => STATUS,
  },

  methods: {
    editModal() {
      this.$openModal('structures/orgGroupForm', {
        value: this.singleItem,
      });
    },
    openCreate() {
      const id = this.singleItem.id;

      this.$openModal('structures/organizationForm', {
        predefinedValues: this.item.isHolding
          ? { holdingId: this.singleItem.id }
          : { groupIds: [this.singleItem.id] },
        storeForCreating: 'STRUCTURES/ORGANIZATION_GROUPS_ORGANIZATION_LIST',
        onSubmit() {
          this.$store.dispatch(
            'STRUCTURES/ORGANIZATION_GROUPS_ITEM/singleItemUpdate',
            id,
          );
        },
      });
    },
  },
};
</script>
