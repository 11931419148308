import RouteGuard from '@/components/RouteGuard';
import { entity } from './entity';

export default [
  {
    path: '/employees',
    name: 'employees',
    component: () => import('@/views/Provider.vue'),
    redirect: { name: 'employees:list' },
    children: [
      {
        path: '',
        name: 'employees:list',
        component: () => import('@/modules/employees/views/list.vue'),
        meta: {
          title: 'Все работники',
          action: 'EMP_R',
        },
      },
      {
        path: ':id',
        name: 'employees:card',
        component: RouteGuard({
          storeModule: 'EMPLOYEE',
          component: () => import('./views/view.vue'),
          entity,
          useCache: false,
        }),
        meta: {
          title: 'Карточка работника',
          action: 'EMP_RD',
        },
      },
    ],
  },
];
