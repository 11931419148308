import { createHTTP } from '../api';
import axios from '@/plugins/axios';
import env from '@/plugins/env';

export const sign = createHTTP(env.get('VUE_APP_SIGN_API'));

export default {
  getTasks() {
    return sign.get('/v3/tasks').then(res => res.data);
  },

  takeTasks(payload) {
    return sign.post('/v3/tasks/take', payload).then(res => res.data);
  },

  triggerTasks(payload) {
    return sign.post('/v3/tasks/trigger', payload).then(res => res.data);
  },

  initialize(id, payload) {
    return sign
      .post(`/v3/certificates/qualified/${id}/initialize`, payload)
      .then(res => res.data);
  },

  loadFileForSign(url) {
    // Need to use "arraybuffer" so that cryptopro will receive the file
    // from the server without any changes
    return axios(url, { responseType: 'arraybuffer' }).then(res => res.data);
  },

  sendTaskComplete(taskId, payload) {
    return sign
      .post(`/v3/tasks/${taskId}/complete`, payload)
      .then(res => res.data);
  },

  sendTestTaskComplete(payload) {
    return sign.post(`/v3/cryptopro/test`, payload).then(res => res.data);
  },

  getCertificatesByAccountId(accountIds = []) {
    const params = {
      accountIds: accountIds.join(','),
    };

    return sign
      .get('/v3/certificates/qualified/previews', { params })
      .then(res => res.data);
  },
  getMyCertificates() {
    return sign.get(`/v3/accounts/my/certificates`).then(res => res.data);
  },
  addCertificate(params) {
    return sign
      .post('/v3/certificates/qualified', params)
      .then(res => res.data);
  },
  removeCertificate(id) {
    return sign
      .delete(`/v3/certificates/qualified/${id}`)
      .then(res => res.data);
  },
  verifyCertificate(data) {
    return sign
      .post('/v3/certificates/qualified/verify', data)
      .then(res => res.data);
  },
  invalidateCertificate(id) {
    return sign
      .delete(`/v3/certificates/qualified/${id}/invalidate`)
      .then(res => res.data);
  },
};
