<template>
  <AsyncSelect v-bind="computedProps" item-value="key" v-on="$listeners" />
</template>

<script>
import asyncSelectMixin from '@/components/controls/asyncSelectMixin';

import { mapActions } from 'vuex';
export default {
  extends: asyncSelectMixin,

  methods: {
    ...mapActions('AUTH', {
      fetcher: 'fetchGroups',
      fetcherBy: 'fetchGroupsPreviewsBy',
    }),
  },
};
</script>
