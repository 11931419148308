<template>
  <v-row>
    <v-col
      v-if="medRecordError"
      :cols="12"
      class="d-flex flex-column"
      style="gap: 24px"
    >
      <ErrorBlock :error="medRecordError" @refresh="fetchMedRecord" />
    </v-col>
    <v-col :cols="6" class="d-flex flex-column" style="gap: 24px">
      <Card
        v-if="$can('I_RMI')"
        :loading="!$wait('fetchMedRecord') && $wait('fetchMeasurementsHistory')"
        title="Средние медицинские показатели"
        dense
      >
        <ErrorBlock
          v-if="measurementsHistoryError"
          :error="measurementsHistoryError"
          @refresh="fetchMeasurements"
        />
        <Spinner v-if="$wait('fetchMedRecord')" title="Загрузка данных" />
        <template v-else-if="medRecord.boundaries">
          <BoundariesTable
            :boundaries="medRecord.boundaries"
            :average="measurementsHistory.average"
          />
        </template>

        <template #action>
          <v-menu v-if="$can('SETBND') && $can('DRPBND')">
            <template #activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon size="24">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :disabled="!isCertificateValid"
                @click="boundariesModal"
              >
                <v-list-item-title>
                  Редактировать границы
                  <v-list-item-subtitle v-if="!isCertificateValid">
                    Нет подтвержденных документов
                  </v-list-item-subtitle>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="resetBoundariesModal">
                <v-list-item-title>Сбросить границы</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <div v-else-if="$can('SETBND')">
            <CardActionBtn
              label="Редактировать границы"
              :disabled="!isCertificateValid"
              @click="boundariesModal"
            />
            <div v-if="!isCertificateValid" class="text-caption">
              Нет подтвержденных документов
            </div>
          </div>

          <CardActionBtn
            v-else-if="$can('DRPBND')"
            label="Сбросить границы"
            @click="resetBoundariesModal"
          />
        </template>
      </Card>

      <Card v-if="$can('I_RMI')" title="Документы" dense>
        <div
          v-if="documentsActive.length"
          style="max-height: 340px; overflow-y: auto"
        >
          <template v-for="document in documentsActive">
            <EmployeeDocumentItem
              :key="document.id"
              :value="document"
              :title-classes="['color-primary']"
              show-status
              :employee="employee"
            />
            <v-divider :key="'divider_' + document.id" />
          </template>
        </div>
        <NoDataAlert v-else class="ma-4">
          Нет активных или находящихся на проверке документов
        </NoDataAlert>

        <template #action>
          <v-menu v-if="$can('DOC_C')">
            <template #activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon size="24">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :disabled="$wait('refreshMedicalData')"
                @click="refreshMedicalData"
              >
                <v-list-item-title>Обновить список</v-list-item-title>
              </v-list-item>

              <v-divider />

              <v-list-item @click="documentsModal">
                <v-list-item-title>Посмотреть все</v-list-item-title>
              </v-list-item>

              <v-list-item v-if="$can('DOC_C')" @click="createDocumentModal">
                <v-list-item-title>Добавить документ</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </Card>
    </v-col>

    <v-col :cols="6" class="d-flex flex-column" style="gap: 24px">
      <Card v-if="$can('INS_LD')" title="Последний осмотр" dense>
        <AsyncWrapper :handler="fetchLastInspection">
          <ProcessingDetails v-if="lastInspection" :value="lastInspection" />
          <NoDataAlert v-else class="ma-4">
            Работник не проходил осмотры
          </NoDataAlert>
        </AsyncWrapper>
        <template #action>
          <CardActionBtn
            label="Обновить"
            :disabled="$wait('fetchLastInspection')"
            :loading="$wait('fetchLastInspection')"
            @click="fetchLastInspection"
          />
        </template>
      </Card>

      <Card v-if="$can('CMNT_R')" title="Комментарии" dense>
        <Spinner v-if="$wait('fetchMedRecord')" title="Загрузка комментариев" />
        <CommentsBlock
          v-else
          :height="
            medRecord.comments && medRecord.comments.length ? '240px' : '70px'
          "
          :value="medRecord.comments || []"
          :show-title="false"
          :can-add-comment="$can('CMNT_A')"
          :on-send-comment="sendComment"
          :current-account-id="currentAccount.id"
        />
      </Card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import waitable from '@/utils/mixins/waitable';

import Card from '@/components/ui/Card';
import AsyncWrapper from '@/components/AsyncWrapper';
import ProcessingDetails from '@/components/ProcessingDetails';
import Spinner from '@/components/Spinner';
import BoundariesTable from '@/components/BoundariesTable';
import EmployeeDocumentItem from '@/components/EmployeeDocumentItem';
import CommentsBlock from '@/modules/employees/components/CommentsBlock';
import ErrorBlock from '@/components/ErrorBlock';
import NoDataAlert from '@/components/ui/NoDataAlert';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';

export default {
  components: {
    ErrorBlock,
    Card,
    ProcessingDetails,
    Spinner,
    BoundariesTable,
    EmployeeDocumentItem,
    CommentsBlock,
    AsyncWrapper,
    NoDataAlert,
    CardActionBtn,
  },

  mixins: [waitable],

  props: {
    employee: { type: Object, required: true },
  },

  data() {
    return {
      measurementsHistoryError: null,
      medRecordError: null,
    };
  },

  computed: {
    ...mapGetters('EMPLOYEE', [
      'singleItem',
      'medRecord',
      'measurementsHistory',
      'documents',
      'processingEmployeeDetails',
    ]),
    ...mapGetters('AUTH', ['currentAccount']),

    isCertificateValid() {
      return this.medRecord.validCertificates?.length;
    },
    documentsActive() {
      return this.documents.filter(
        ({ status }) => status === 'PENDING' || status === 'VALID',
      );
    },

    lastInspection() {
      return this.processingEmployeeDetails?.inspection;
    },
  },

  async created() {
    this.fetchMeasurements();
    await this.fetchMedRecord();
  },

  methods: {
    ...mapActions('EMPLOYEE', [
      'fetchMeasurementsHistory',
      'resetBoundaries',
      'setBoundaries',
      'fetchMedrec',
      'fetchProcessingEmployeeDetails',
      'sendComment',
    ]),

    async fetchMeasurements() {
      try {
        this.measurementsHistoryError = null;
        await this.$loading(
          this.fetchMeasurementsHistory(this.employee.id),
          'fetchMeasurementsHistory',
        );
      } catch (err) {
        this.measurementsHistoryError = err;
      }
    },

    async fetchMedRecord() {
      try {
        this.medRecordError = null;
        await this.$loading(
          this.fetchMedrec(this.employee.id),
          'fetchMedRecord',
        );
      } catch (err) {
        this.medRecordError = err;
      }
    },

    boundariesModal() {
      this.$openModal('documents/boundaries', {
        employee: this.employee,
        onSubmit: (id, data) => {
          this.setBoundaries({ id, data });
        },
      });
    },

    resetBoundariesModal() {
      this.$openModal('prompt', {
        title: 'Сброс границ',
        description: 'Вы точно хотите установить границы по умолчанию?',
        messages: {
          successfulAction: 'Границы успешно сброшены',
          errorAction: 'Произошла ошибка сброса границ',
        },
        onSubmit: () => {
          this.resetBoundaries(this.employee.id);
        },
      });
    },

    refreshMedicalData() {
      this.$loadingNotify(
        this.fetchMedrec(this.employee.id),
        'refreshMedicalData',
        'Не удалось загрузить медицинские данные по работнику',
      );
    },

    documentsModal() {
      this.$openModal('documents/employee', {
        employee: this.singleItem,
      });
    },

    createDocumentModal() {
      this.$openModal('documents/add', { employee: this.employee });
    },

    fetchLastInspection() {
      if (this.$wait('fetchLastInspection')) return;

      try {
        this.$loading(
          this.fetchProcessingEmployeeDetails(this.employee.id),
          'fetchLastInspection',
        );
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
