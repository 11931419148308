<template>
  <ModernModalLayout
    :title="title"
    :size="size"
    :close-label="no"
    :dense-content="!assistantDisabled"
  >
    <v-alert v-if="warningText" class="ma-3" :type="warningType">
      {{ warningText }}
    </v-alert>

    <div v-if="assistantDisabled">
      <CommentAssistantDisable
        ref="commentAssistantDisable"
        :medcab-type="medcabType"
        :manual-measure="medcabType === 'passive'"
        @input="value => (comment = value)"
      />
    </div>
    <template #actions:append>
      <v-btn
        class="px-10"
        depressed
        color="primary"
        :loading="$wait('action')"
        @click="submit"
      >
        {{ yes }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import waitable from '@/utils/mixins/waitable';
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import CommentAssistantDisable from './CommentAssistantDisable';

export default {
  components: { ModernModalLayout, CommentAssistantDisable },
  mixins: [waitable],

  props: {
    onSubmit: { type: Function, required: true },
    medcabType: {
      type: String,
      default: 'active',
      validator: value => ['active', 'passive'].includes(value),
    },
    assistantDisabled: Boolean,
    title: { type: String, default: null },
    no: { type: String, default: 'Отмена' },
    yes: { type: String, default: 'Подтвердить' },
    messages: {
      type: Object,
      default: () => ({
        successfulAction: 'Успешно',
        errorAction: 'Произошла ошибка',
      }),
    },
    warningText: { type: String, default: null },
    warningType: { type: String, default: null },
  },

  data: () => ({
    comment: '',
  }),

  computed: {
    size() {
      return this.assistantDisabled && this.medcabType === 'passive'
        ? 'xlarge'
        : 'medium';
    },
  },

  mounted() {
    if (!this.assistantDisabled)
      document.addEventListener('keyup', this.onEnter);
  },

  beforeDestroy() {
    if (!this.assistantDisabled)
      document.removeEventListener('keyup', this.onEnter);
  },

  methods: {
    onEnter(e) {
      e.keyCode === 13 && this.submit();
    },

    changeComment(value) {
      this.comment = value;
    },

    async submit() {
      if (
        this.assistantDisabled &&
        this.$refs.commentAssistantDisable?.validate
      ) {
        const valid = this.$refs.commentAssistantDisable.validate();

        if (!valid) {
          this.$notify({
            group: 'note',
            type: 'error',
            title: 'Обнаружены неверно заполнены поля',
            text: 'Внесите изменения и попробуйте снова',
          });
          return;
        }
      }

      const result = this.onSubmit({
        comment: (this.comment || '').trim() || null,
      });

      if (result instanceof Promise)
        await this.$loadingNotify(
          result,
          'action',
          this.messages.errorAction,
          this.messages.successfulAction,
        )
          .catch(() => {})
          .finally(() => this.$emit('close'));
      else {
        this.$emit('close');
      }
    },
  },
};
</script>
