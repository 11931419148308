import gateApi from '@/api/services/gate';

export default {
  state: {
    configProfiles: [],
  },

  getters: {
    configProfiles: state => state.configProfiles,
  },

  mutations: {
    configProfiles: (state, value) => (state.configProfiles = value),
  },

  actions: {
    async fetchConfigProfiles({ getters, commit, dispatch }) {
      await dispatch('fetchFormFactors');
      const id = getters.id;

      // there's quite an overhead coming with this request, the actual fields
      // that are needed are:
      //   "key": "default-d",
      //   "isDefault": true,
      //   "formFactor": "desktop",
      //   "name": "Default desktop",
      const response = await gateApi.getOrgConfigs(id);
      commit('configProfiles', response);
    },

    async editConfigProfile({ getters, dispatch }, key) {
      const id = getters.id;
      await gateApi.editOrgConfig(id, key);

      // patch request above doesn't return config profiles, just success code
      // so we manually refresh config profiles
      dispatch('fetchConfigProfiles');
    },
  },
};
