var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PointsList',{attrs:{"search-filter":"","available-actions":{
    showAction: _vm.$can('PNT_RD'),
    createAction: _vm.$can('PNT_C'),
  }},on:{"item:create":_vm.openCreatePoint},scopedSlots:_vm._u([{key:"filters",fn:function(query){return [_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-select',{attrs:{"label":"Статус","items":_vm.statusItems,"item-value":"value","item-text":"name","hide-details":"","outlined":"","dense":"","menu-props":{ offsetY: true }},model:{value:(query.isActive),callback:function ($$v) {_vm.$set(query, "isActive", $$v)},expression:"query.isActive"}})],1)]}},{key:"table.item.isActive",fn:function(ref){
  var item = ref.item;
return [_c('Indicator',{attrs:{"active":item.isActive}})]}},{key:"table.item.organization",fn:function(ref){
  var item = ref.item;
return [(item.organization.shortName)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("Орг.: "+_vm._s(item.organization.shortName))])]}}],null,true)},[_vm._v(" "+_vm._s(item.organization.name)+" ")]):[_vm._v(" "+_vm._s(item.organization.name)+" ")]]}},{key:"table.item.pointOnMap",fn:function(ref){
  var item = ref.item;
return [(item.address)?_c('div',[_vm._v(_vm._s(item.address))]):_vm._e(),((item.coordinates || {}).latitude)?_c('div',[_vm._v(" Координаты: "+_vm._s(item.coordinates.latitude)+"; "+_vm._s(item.coordinates.longitude)+" ")]):_vm._e(),(item.isMobile)?_c('v-chip',{attrs:{"small":""}},[_vm._v("Мобильная")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }