<template>
  <v-autocomplete
    :value="value"
    label="Организация"
    :items="orgs"
    item-value="id"
    item-text="name"
    :disabled="$wait('loadingAvailableOrgIds')"
    outlined
    hide-details="auto"
    :loading="$wait('fetchingInspectionTypes')"
    @input="$emit('input', $event)"
  />
</template>

<script>
import medrecApi from '@/api/services/medrec';
import structApi from '@/api/services/structures';
import waitable from '@/utils/mixins/waitable';

export default {
  mixins: [waitable],

  props: {
    value: { type: Number, default: null },
  },

  data: () => ({
    orgs: [],
  }),

  async mounted() {
    await this.$loadingNotify(
      medrecApi.getUserEnabledRiskOrgs().then(async data => {
        const response = await structApi.getOrganizationPreviewsBy(data.items);
        this.orgs = response;
      }),
      'loadingAvailableOrgIds',
      'При загрузке доступных организаций произошла ошибка',
    );
  },
};
</script>
