<template>
  <div class="d-flex align-center" title="Время терминала">
    <img src="@/assets/images/terminal-icon.png" width="20" class="mr-1" />
    {{ dateTimeTerminal }}
  </div>
</template>

<script>
import { dateTime } from '@/utils/convert';
export default {
  props: {
    date: {
      type: String,
      default: null,
    },
    offset: {
      type: Number,
      default: null,
    },
  },

  computed: {
    dateTimeTerminal() {
      return dateTime(this.date, {
        timezone: this.offset / 60,
        showTimeZone: true,
      });
    },
  },
};
</script>
