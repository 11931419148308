<template>
  <div>
    <span>{{ contactName }}</span>
    <br />
    <span>{{ value.email }}</span>
  </div>
</template>

<script>
import { name } from '@/utils/convert';

export default {
  props: {
    value: { type: Object, required: true },
  },
  computed: {
    contactName() {
      return name(this.value);
    },
  },
};
</script>
