<template>
  <ModernModalLayout
    size="xlarge"
    :watch-for="data"
    :title="
      item.id ? 'Редактирование точки выпуска' : 'Добавление точки выпуска'
    "
  >
    <v-form :disabled="$wait('submit')" @submit.prevent>
      <v-row>
        <v-col v-if="!data.id" cols="6">
          <OrganizationSelect
            v-model="data.orgId"
            access-level="full"
            hide-details="auto"
            :error-messages="getValidationErrors('data.orgId')"
            @blur="validateField('data.orgId')"
          />
        </v-col>
        <v-col v-if="!data.id" :cols="data.id ? 12 : 6">
          <v-switch
            v-model="data.isMobile"
            class="mt-3"
            label="Мобильная"
            hide-details="auto"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="!data.isMobile && !data.id" class="pb-0" cols="12">
          <span>
            Если точка не мобильная, укажите либо ее адрес, либо широту и
            долготу:
          </span>
        </v-col>
        <v-col v-if="!data.isMobile" cols="6">
          <v-text-field
            v-model="data.address"
            label="Адрес"
            outlined
            hide-details="auto"
            :error-messages="getValidationErrors('data.address')"
            @blur="validateField('data.address')"
          />
        </v-col>
        <v-col class="pb-0" :cols="data.isMobile ? '12' : '6'">
          <div class="d-flex">
            <MaskField
              v-model="data.coordinates.latitude"
              label="Широта"
              :mask="latitude"
              :class="data.isMobile ? 'mr-6' : 'mr-3'"
              outlined
              hide-details="auto"
              :error-messages="getValidationErrorCoordinates('latitude')"
              @blur="validateCoordinatesField('latitude')"
            />
            <MaskField
              v-model="data.coordinates.longitude"
              label="Долгота"
              :mask="longitude"
              outlined
              hide-details="auto"
              :error-messages="getValidationErrorCoordinates('longitude')"
              @blur="validateCoordinatesField('longitude')"
            />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-select
            ref="timezone"
            v-model="data.timezone"
            label="Часовой пояс"
            :items="ruTimezones"
            outlined
            :persistent-hint="true"
            :hint="timezoneHint"
            :error-messages="getValidationErrors('data.timezone')"
            @blur="validateField('data.timezone')"
          />
        </v-col>
        <v-col>
          <v-btn
            depressed
            color="primary"
            class="mt-3"
            :loading="$wait('getTimezone')"
            @click="getTimezone"
          >
            Определить
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="data.comment"
            label="Комментарий"
            hide-details="auto"
            outlined
            :error-messages="getValidationErrors('data.comment')"
            @blur="validateField('data.comment')"
          />
        </v-col>
      </v-row>
    </v-form>
    <template #actions:append>
      <v-btn
        class="px-16"
        depressed
        color="primary"
        :loading="$wait('submit')"
        @click="openConfirmationModal"
      >
        {{ item.id ? 'Сохранить' : 'Добавить' }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  requiredIf,
} from 'vuelidate/lib/validators';
import { deepCopyWithNull } from '@/utils/helpers';
import { latitude, longitude } from '@/utils/masks';
import { RU_TIMEZONES } from '@/utils/constants';
import structApi from '@/api/services/structures';

import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';

import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';
import MaskField from '@/components/controls/MaskField';

export default {
  components: { ModernModalLayout, OrganizationSelect, MaskField },
  mixins: [validation, waitable],

  props: {
    item: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
    predefinedValues: { type: Object, default: () => ({}) },
    storeForCreate: { type: String, default: 'STRUCTURES/POINTS' },
  },

  validations() {
    return {
      data: {
        orgId: this.item.id ? {} : { required },
        address: this.getAddrValidation(),
        coordinates: {
          latitude: this.getCoorsValidation('lat'),
          longitude: this.getCoorsValidation('lon'),
        },
        comment: {
          minSymbolsLength: minLength(5),
          maxSymbolsLength: maxLength(1000),
        },
        timezone: { required },
      },
    };
  },

  data() {
    return {
      data: { ...this.item, coordinates: {}, isMobile: false },
      validations: null,
      getTimezoneError: false,
    };
  },

  computed: {
    latitude: () => latitude,
    longitude: () => longitude,

    getValidationErrorCoordinates() {
      return field => {
        return [
          // При ошибке координат с бэка приходит только поле coordinates
          ...this.getValidationErrors('data.coordinates'),
          ...this.getValidationErrors(`data.coordinates.${field}`),
        ];
      };
    },

    ruTimezones() {
      return RU_TIMEZONES;
    },

    timezoneHint() {
      if (this.getTimezoneError) {
        return 'Часовой пояс необходимо указать вручную';
      }
      let msg = 'Убедитесь в правильности выбора часового пояса';
      this.data.isMobile && (msg += '. Рекомендованный часовой пояс GMT+3');
      return msg;
    },
  },
  watch: {
    'data.isMobile': {
      handler() {
        this.getTimezoneError = false;
        this.data.address = '';
      },
    },
  },

  created() {
    if (this.item.coordinates)
      this.data.coordinates = { ...this.item.coordinates };
    this.data.isMobile = !!this.item?.isMobile;

    !this.item.id &&
      Object.keys(this.predefinedValues).length &&
      Object.keys(this.predefinedValues).forEach(
        key => (this.data[key] = this.predefinedValues[key]),
      );
  },

  methods: {
    validateCoordinatesField(field) {
      this.validateField('data.coordinates');
      this.validateField(`data.coordinates.${field}`);
    },
    openConfirmationModal() {
      if (!this.validate()) return;

      this.$openModal(
        'prompt',
        {
          title: 'Подтвердить действие',
          messages: {
            errorAction: `Произошла ошибка ${
              this.data.id ? 'изменения' : 'добавления'
            } точки`,
            successfulAction: `Точка успешно ${
              this.data.id ? 'изменена' : 'добавлена'
            }`,
          },
          onSubmit: this.handleSubmit,
        },
        null,
        {
          description: () => (
            <div>
              Подтвердите правильность заполнения:
              <br />
              <br />
              {!this.data.isMobile && (
                <div>
                  <b>Адрес:</b> {this.data.address || 'Не указан'}
                  <br />
                </div>
              )}
              <b>Широта:</b> {this.data.coordinates?.latitude ?? 'Не указана'}
              <br />
              <b>Долгота:</b> {this.data.coordinates?.longitude ?? 'Не указана'}
            </div>
          ),
        },
      );
    },

    async handleSubmit() {
      const preparedResponse = deepCopyWithNull(this.data);
      const isNew = !this.item.id;

      ((isNew && !preparedResponse.address) || preparedResponse.isMobile) &&
        delete preparedResponse.address;
      if (!preparedResponse.coordinates) {
        preparedResponse.coordinates = null;
      } else {
        preparedResponse.coordinates.latitude -= 0;
        preparedResponse.coordinates.longitude -= 0;
      }

      const actionName = !isNew
        ? 'STRUCTURES/POINTS_ITEM/updateSingleItem'
        : `${this.storeForCreate}/createListItem`;

      await this.$loading(
        this.$store.dispatch(actionName, preparedResponse),
        'submit',
      )
        .then(() => this.$emit('close'))
        .catch(err => this.catchServerErrors(err, 'data'));
    },

    async getTimezone() {
      this.getTimezoneError = false;
      // Для мобильной точки устанавливается часовой пояс по умолчанию (Москва)
      if (this.data.isMobile) {
        this.$refs.timezone.setValue(3);
        return;
      }

      // При отсутствии данных для определения таймзоны, нужно подсветить необходимые поля
      const lat = this.data.coordinates.latitude;
      const lon = this.data.coordinates.longitude;
      if (!this.data.address && !((lat || lat === 0) && (lon || lon === 0))) {
        this.validateField('data.address');
        this.validateField('data.coordinates.latitude');
        this.validateField('data.coordinates.longitude');
        return;
      }

      try {
        await this.$loading(
          structApi
            .getTimezone({
              address: this.data.address,
              coordinates: this.data.coordinates,
            })
            .then(data => {
              if (!data.value) {
                this.getTimezoneError = true;
                this.$refs.timezone.setValue(null);
                return;
              }
              this.$refs.timezone.setValue(data.value);
            }),
          'getTimezone',
        );
      } catch (err) {
        this.getTimezoneError = true;
        this.$refs.timezone.setValue(null);
      }
    },

    // --- Validation methods ---

    getAddrValidation() {
      const options = { maxSymbolsLength: maxLength(150) };
      const c = this.data.coordinates;
      if (
        this.data.isMobile ||
        c.latitude ||
        c.latitude === 0 ||
        c.longitude ||
        c.longitude === 0
      )
        return options;

      return { ...options, required };
    },

    getCoorsValidation(type) {
      const len = type === 'lat' ? 17 : 18;
      const options = { maxSymbolsLength: maxLength(len) };

      // if user started to fill one coordinate - require another
      const requireField =
        type === 'lat'
          ? this.data.coordinates.longitude
          : this.data.coordinates.latitude;

      if (!this.data.address) return { ...options, required };
      else return { ...options, required: requiredIf(() => requireField) };
    },
  },
};
</script>
