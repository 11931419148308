<template>
  <ModernModalLayout title="Разблокировать работника" :watch-for="data">
    <v-form :loading="$wait('unban')">
      <div>Работник: {{ name(employee) }}</div>
      <div>Блокировка: {{ dueDate }}</div>
      <div>
        На основании осмотра
        <v-btn
          class="px-1"
          text
          small
          color="primary"
          target="_blank"
          :to="{ name: 'inspections:card', params: { id: value.inspectionId } }"
        >
          {{ '#' + value.inspectionId }}
        </v-btn>
      </div>
      <div>Причина: {{ value.banScenario.reason.description }}</div>
      <div
        v-if="value.medicConclusion && value.medicConclusion.comment"
        class="mt-1"
      >
        Комментарий: {{ value.medicConclusion.comment }}
      </div>
      <v-divider class="my-2" />
      <template v-if="canUnban && !isLoading">
        <v-alert
          v-if="$can('UNBN_F') && unbanCondition === 'no_unban'"
          text
          type="error"
        >
          Исходя из типа блокировки вам НЕ СТОИТ её снимать
        </v-alert>
        <template v-if="!document && unbanCondition === 'with_document'">
          <v-alert v-if="$can('UNBN_F')" text type="info">
            Вы можете разблокировать пользователя без указания документа
          </v-alert>
          <v-autocomplete
            v-model="data.documentId"
            :items="documents"
            item-value="id"
            item-text="name"
            label="Документ"
            outlined
            dense
            persistent-hint
            :disabled="documents.length === 0"
            :hint="documentHint"
            :loading="$wait('fetchDocuments')"
            :error-messages="getValidationErrors('data.documentId')"
          >
            <template #item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  Дата выдачи: {{ item.issuedAt }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.diagnosis">
                  Диагноз: {{ item.diagnosis }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </template>
        <v-textarea
          v-model="data.comment"
          label="Комментарий"
          outlined
          dense
          :error-messages="getValidationErrors('data.comment')"
        />
      </template>

      <template v-else-if="!isLoading">
        <v-alert text type="error">
          У работника нет ни одной подтвержденной справки, позволяющей
          разблокировать по данному основанию.
        </v-alert>
      </template>
    </v-form>
    <template #actions:append>
      <v-btn
        class="px-5"
        color="primary"
        depressed
        :disabled="!canUnban"
        :loading="$wait('unban')"
        @click="handleUnban"
      >
        Разблокировать
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import Vue from 'vue';
import waitable from '@/utils/mixins/waitable';
import ModernModalLayout from '@/components/layouts/ModernModalLayout';

import validationMixin from '@/utils/validation';
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import { dateTime, duration, name } from '@/utils/convert';

export default Vue.extend({
  components: {
    ModernModalLayout,
  },
  mixins: [validationMixin, waitable],
  validations() {
    return {
      data: {
        documentId: {
          required: requiredIf(
            () =>
              this.unbanCondition === 'with_document' && !this.$can('UNBN_F'),
          ),
        },
        comment: { required, maxSymbolsLength: maxLength(1000) },
      },
    };
  },
  props: {
    value: { type: Object, required: true },
    employee: { type: Object, required: true },
    document: { type: Object, default: null },
  },
  data() {
    return {
      documents: [],
      data: {
        documentId: null,
        comment: '',
      },
    };
  },
  computed: {
    date() {
      return dateTime(this.value.issuedAt);
    },
    isLoading() {
      return this.$wait('fetchDocuments');
    },
    unbanCondition() {
      return this.value.banScenario.unbanCondition;
    },
    canUnban() {
      return (
        this.unbanCondition === 'free' ||
        this.document ||
        this.$can('UNBN_F') ||
        this.documents.length > 0
      );
    },
    dueDate() {
      return this.value.dueDate
        ? 'на ' + duration(this.value.issuedAt, this.value.dueDate)
        : 'до ручной разблокировки';
    },
    documentHint() {
      return this.documents
        ? 'Отсутствуют документы, позволяющие разблокировать работника'
        : '';
    },
  },

  async created() {
    if (this.document) this.data.documentId = this.document.id;
    else
      this.documents = await this.$loadingNotify(
        this.$store.dispatch('EMPLOYEE/getDocumentsForUnban', {
          id: this.employee.id,
          banReason: this.value.banScenario.reason.id,
        }),
        'fetchDocuments',
        'Ошибка загрузки документов',
      );
  },

  methods: {
    name,

    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },

    async handleUnban() {
      if (!this.validate()) return;

      this.$loadingNotify(
        this.$store
          .dispatch('EMPLOYEE/unban', {
            ...this.data,
            banId: this.value.id,
          })
          .then(_ => this.$emit('close')),
        'unban',
        'Произошла ошибка разблокировки работника',
        'Работник успешно разблокирован',
      );
    },
  },
});
</script>
