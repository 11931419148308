import gateApi from '@/api/services/gate';
import processingApi from '@/api/services/processing';
import structApi from '@/api/services/structures';

export default {
  state: {
    authTypes: null,
    time: null,
    waitretry: null,
    orgSettings: null,
    sign: null,
    agreements: null,
  },

  getters: {
    authTypes: state => state.authTypes || {},
    time: state => state.time || {},
    waitretry: state => state.waitretry || {},
    orgSettings: state => state.orgSettings || {},
    sign: state => state.sign || {},
    agreements: state => state.agreements || [],
    esiaEnabled: state => state.orgSettings?.esia?.rule?.isEnabled || false,
  },

  mutations: {
    authTypes: (state, data) => (state.authTypes = data),
    time: (state, data) => (state.time = data),
    waitretry: (state, data) => (state.waitretry = data),
    orgSettings: (state, data) => (state.orgSettings = data),
    sign: (state, data) => (state.sign = data),
    agreements: (state, data) => (state.agreements = data),
    esiaEnabled: (state, value) => {
      if (state.orgSettings?.esia?.rule) {
        state.orgSettings.esia.rule.isEnabled = value;
      }
    },
  },

  actions: {
    // auth methods settings

    async fetchAuthSettings({ getters, commit }) {
      const result = await gateApi.getOrgAuthSettings(getters.id);
      commit('authTypes', result);
      return result;
    },

    async changeAuthSettings({ getters, commit }, type) {
      let types = Object.assign({}, getters.authTypes);
      types[type] = !types[type]; // switch auth type value
      // for convenience we are receiving object of auth types , but must send
      // an array of the ones that are enabled
      types = Object.keys(types).filter(key => types[key]);
      if (!types.length) {
        if (type === 'personnell_number')
          throw new Error('Должен быть включен минимум один тип авторизации');
        types.push('personnell_number');
      }
      const result = await gateApi.setOrgAuthSettings(getters.id, {
        authorizationTypes: types,
      });
      commit('authTypes', result);
    },

    // Time settings

    async fetchTimeSettings({ getters, commit }) {
      const result = await gateApi.getOrgTimeSettings(getters.id);
      commit('time', result);
      return result;
    },

    async changeTimeSettings({ getters, commit }, payload) {
      const result = await gateApi.setOrgTimeSettings(getters.id, payload);
      commit('time', result);
    },

    // Waitretry settings

    async fetchWaitretrySettings({ getters, commit }) {
      const { settings } = await processingApi.getSettings(getters.id);
      commit('waitretry', settings);
      return settings;
    },

    async changeWaitretrySettings({ getters, commit }, payload) {
      const { settings } = await processingApi.setSettings(getters.id, payload);
      commit('waitretry', settings);
    },

    // snils settings
    async fetchOrgSettings({ getters, commit }) {
      const settings = await structApi.getOrganizationSettings(getters.id);
      commit('orgSettings', settings);
      return settings;
    },

    async toggleSnilsRequirement({ getters, dispatch }) {
      const action = getters.orgSettings.employeeMustHave.rule.snils
        ? 'disable'
        : 'enable';
      // in response there is an object {success: Boolean}
      await structApi.setOrgSnils(getters.id, action);
      dispatch('fetchOrgSettings');
    },

    // in response there is an object {success: Boolean}
    async toggleEmployeeRequirement({ getters, dispatch }) {
      const action = getters.orgSettings.employeeSync.rule.employeeModify
        ? 'disable'
        : 'enable';
      // in response there is an object {success: Boolean}
      await structApi.setOrgEmployee(getters.id, action);
      dispatch('fetchOrgSettings');
      dispatch('singleItemUpdate');
    },

    // sign settings
    async fetchSignSettings({ getters, commit }) {
      const result = await gateApi.getOrgSignSettings(getters.id);
      commit('sign', result);
      return result;
    },

    async setSignSettings({ getters, commit }, payload) {
      const result = await gateApi.setOrgSignSettings(getters.id, payload);
      commit('sign', result);
    },

    // agreements
    async fetchAgreements({ getters, commit }) {
      const result = await gateApi.getAgreements(getters.id);
      commit('agreements', result);
      return result;
    },

    async createAgreement({ getters }, { file, type }) {
      await gateApi.createAgreement(getters.id, file, type);
    },

    async changeAgreement({ getters }, { file, type }) {
      await gateApi.changeAgreement(getters.id, file, type);
    },

    // esia
    async toggleEsia({ getters, commit, dispatch }, status) {
      const action = status ? 'enable' : 'disable';
      // in response there is an object {success: Boolean}
      const { success } = await structApi.setEsiaStatus(getters.id, action);
      if (success) {
        dispatch('singleItemUpdate');
        commit('esiaEnabled', !getters.esiaEnabled);
      }
    },
  },
};
