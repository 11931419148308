<template>
  <Fragment>
    <BoundariesTable
      v-if="boundaries && (whatToShow === 'all' || whatToShow === 'averages')"
      :boundaries="boundaries"
      :average="average"
    />

    <v-divider v-if="whatToShow === 'all'" />
    <v-list
      v-if="whatToShow === 'all' || whatToShow === 'documents'"
      class="pa-0"
    >
      <v-card-title class="pb-2 d-flex flex-row justify-space-between">
        <span>{{ docTitle }}</span>
        <slot name="actions" />
      </v-card-title>
      <div style="max-height: 160px; overflow-y: auto">
        <EmployeeDocuments
          :incoming="incoming"
          :outgoing="outgoing"
          :show-tabs="incoming && outgoing"
        />
      </div>
    </v-list>
  </Fragment>
</template>

<script>
import BoundariesTable from './BoundariesTable.vue';
import EmployeeDocuments from './EmployeeDocuments.vue';

export default {
  components: {
    EmployeeDocuments,
    BoundariesTable,
  },
  props: {
    /* we'r passing Array when viewing only outgoing docs from inspection
       view page. In other cases it is 'medrec' Object */
    incoming: {
      type: Array,
      default: null,
    },
    outgoing: {
      type: Array,
      default: null,
    },
    boundaries: {
      type: Object,
      default: null,
    },
    docTitle: {
      type: String,
      default: 'Документы',
    },
    average: {
      type: Object,
      default: () => ({
        pressure: { diastolic: '-', systolic: '-' },
        pulse: '-',
      }),
    },
  },

  computed: {
    whatToShow() {
      if (!this.incoming && !this.outgoing) return 'averages';
      if (!this.boundaries) return 'documents';
      return 'all';
    },
  },
};
</script>
