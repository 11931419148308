<template>
  <Spinner v-if="$wait('fetchCurrentAccount')" />
  <ItemPageLayout v-else>
    <template #header>
      <Card dense hide-title>
        <v-tabs v-model="currentTab">
          <v-tab href="#general">Основное</v-tab>
          <v-tab v-if="isMedic" href="#statistic">Статистика</v-tab>
        </v-tabs>
      </Card>
    </template>

    <template v-if="currentTab === 'general'">
      <Card title="Мой профиль" dense>
        <DefinitionList :value="profileData" />
      </Card>

      <Card title="Роль">
        <h3>{{ role.name || '-' }}</h3>
      </Card>

      <Card title="Организация">
        <h3>
          <LinkButton
            v-if="org"
            :action="$can('ORG_RD')"
            :text="org.name"
            :to="{
              name: 'structures:organizations:item',
              params: { id: org.id },
            }"
          />
        </h3>
      </Card>

      <Card v-if="isMedic" title="Категории">
        <AsyncWrapper :handler="fetchMedicalCategories">
          <NoDataAlert v-if="!medicalCategories.length">Не указаны</NoDataAlert>
          <div v-else>
            <v-chip
              v-for="category in medicalCategories"
              :key="category.id"
              class="mr-1 mb-1"
            >
              {{ category.name || `– категория #${category.id} не найдена –` }}
            </v-chip>
          </div>
        </AsyncWrapper>
      </Card>

      <Card v-if="isMedic" title="Группы">
        <NoDataAlert v-if="!medicalGroups.length">Не указаны</NoDataAlert>
        <v-chip
          v-for="group in medicalGroups"
          v-else
          :key="group.id"
          class="mr-1 mb-1"
        >
          {{ group.name }}
        </v-chip>
      </Card>

      <Qrcode
        v-if="$can('QR_GET') && item.roleKey === 'service-eng'"
        :qrcode="qrcode"
        store="CABINET"
      />

      <Card title="Доступы" dense>
        <AccountBindingsPreview
          :bindings="item.bindings"
          :all-available="fullAccessForBindings"
        />

        <template #action>
          <v-menu
            v-if="!fullAccessForBindings"
            open-on-hover
            top
            max-width="700px"
          >
            <template #activator="{ on, attrs }">
              <v-btn small text icon v-bind="attrs" v-on="on">
                <v-icon small>far fa-question-circle</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text><BindingsHelp two-columns /></v-card-text>
            </v-card>
          </v-menu>
        </template>
      </Card>

      <Card v-if="$can('NF_US')" title="Мои подписки" dense>
        <AsyncWrapper :handler="fetchSubscriptions">
          <Subscriptions :value="subscriptions" self />
        </AsyncWrapper>
      </Card>

      <Card v-if="$can('SJ_UR')" title="Мои рассылки" dense>
        <AsyncWrapper :handler="fetchJobs">
          <Jobs :value="jobs" self />
        </AsyncWrapper>
      </Card>

      <Card
        v-if="$can('ACC_GS')"
        title="Звук уведомления в медкабе"
        dense
        class="pb-5"
      >
        <AsyncWrapper :handler="fetchSettings">
          <div class="d-flex align-center justify-space-around">
            <v-radio-group
              v-model="sound.name"
              class="mb-0 pb-0 ml-3"
              hide-details="auto"
              :disabled="$wait('updatingSetting')"
              row="row"
            >
              <v-radio label="Капля" value="V1" @click="playAudio1" />
              <v-radio label="Сонар" value="V3" @click="playAudio2" />
            </v-radio-group>

            <div>
              <v-checkbox
                v-model="sound.repeat"
                class="mb-0 pb-0"
                :disabled="$wait('updatingSetting')"
                label="Повторное воспроизведение до взятия в работу"
                hide-details="auto"
              />
              <v-checkbox
                v-model="sound.passiveMedCabBellEnabled"
                class="mb-0 pb-0"
                :disabled="$wait('updatingSetting')"
                label="Включить звук уведомления в пассивном медкабе"
                hide-details="auto"
              />
            </div>
          </div>
        </AsyncWrapper>
      </Card>

      <Card title="Доп. информация" dense>
        <DefinitionList :value="accountDetails" />
      </Card>
    </template>

    <template v-if="currentTab === 'statistic'" #innerList>
      <Card v-if="referenceId" title="История смен" dense>
        <MedicStats :key="keyShift" my />
        <template #action>
          <CardActionBtn label="Обновить" @click="keyShift = Math.random()" />
        </template>
      </Card>
    </template>

    <template v-if="currentTab === 'general'" #aside>
      <ActionsMenu>
        <ActionsMenuItem
          title="Сбросить себе пароль"
          subtitle="Письмо с паролем придет на вашу почту"
          :disabled="$wait('resettingPassword')"
          @click="resetPassword"
        >
          <template #icon><HowToReg /></template>
        </ActionsMenuItem>
      </ActionsMenu>
    </template>
  </ItemPageLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import validation from '@/utils/validation';
import { GENDER } from '@/utils/constants';
import { maskTextPhone } from '@/utils/masks';
import { date, dateTime } from '@/utils/convert';
import waitable from '@/utils/mixins/waitable';
import queryTabSave from '@/utils/mixins/queryTabSave';
import medicAlert1 from '@/modules/medcab/assets/medicAlert1.mp3';
import medicAlert2 from '@/modules/medcab/assets/medicAlert2.mp3';

import AsyncWrapper from '@/components/AsyncWrapper.vue';
import DefinitionList from '@/components/DefinitionList.vue';
import Spinner from '@/components/Spinner.vue';
import Subscriptions from '@/components/Subscriptions.vue';
import Jobs from '@/components/Jobs.vue';
import ItemPageLayout from '@/components/layouts/ItemPageLayout.vue';
import HowToReg from '@/components/icons/how-to-reg.vue';
import Card from '@/components/ui/Card.vue';
import ActionsMenu from '@/components/ui/ActionsMenu.vue';
import ActionsMenuItem from '@/components/ui/ActionsMenuItem.vue';
import LinkButton from '@/components/controls/buttons/LinkButton.vue';
import AccountBindingsPreview from '@/components/bindings/AccountBindingsPreview.component.vue';
import BindingsHelp from '@/components/bindings/BindingsHelp.vue';
import MedicStats from '@/modules/medblock/medics/components/stats/MedicStats.vue';
import NoDataAlert from '@/components/ui/NoDataAlert';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';
import Qrcode from '@/components/QRCode.vue';

export default {
  // NOTE: Вхождение "Item" в названии обязательно для обновления страницы
  // все из-за "keep-alive"
  name: 'CabinetItem',

  components: {
    ItemPageLayout,
    DefinitionList,
    Card,
    MedicStats,
    AsyncWrapper,
    ActionsMenu,
    ActionsMenuItem,
    HowToReg,
    Spinner,
    AccountBindingsPreview,
    BindingsHelp,
    LinkButton,
    Subscriptions,
    Jobs,
    NoDataAlert,
    CardActionBtn,
    Qrcode,
  },
  mixins: [validation, waitable, queryTabSave()],

  data: () => ({
    currentTab: 'general',
    keyShift: Math.random(),
    // will contain account sound settings once those are loaded
    // which will b of format :
    // {
    //    name: String ('V1' / 'V3'),
    //    repeat: Boolean,
    //    passiveMedCabBellEnabled: Boolean
    //  }
    sound: {},
  }),

  computed: {
    ...mapGetters('AUTH', [
      'accountId',
      'role',
      'isMedic',
      'referenceId',
      'roleTypeName',
      'fullAccessForBindings',
      'settings',
    ]),
    ...mapGetters('CABINET', [
      'item',
      'medic',
      'org',
      'medicalCategories',
      'medicalGroups',
      'subscriptions',
      'jobs',
      'qrcode',
    ]),

    profileData() {
      if (!this.item) return [];

      const { profile, email } = this.item;

      return [
        { label: 'Email', value: email },
        { label: 'Фамилия', value: profile.surname },
        { label: 'Имя', value: profile.name },
        { label: 'Отчество', value: profile.patronymic },
        {
          label: 'Дата рождения',
          value: date(this.medic.dateOfBirth),
        },
        {
          label: 'Пол',
          value: GENDER[profile.gender],
        },
        {
          label: 'Телефон',
          value: maskTextPhone(this.medic.phone),
        },
      ];
    },

    accountDetails() {
      return [
        {
          label: 'Последний вход',
          value: dateTime(this.item.lastLogin),
        },
        {
          label: 'ID аккаунта',
          value: this.accountId,
        },
        {
          label: 'ID медработника',
          value: this.referenceId,
        },
      ];
    },
  },

  watch: {
    settings: {
      deep: true,
      handler(val) {
        // set sound object only once when initial object was fetched
        if (!Object.keys(this.sound).length) this.sound = { ...val.sound };
      },
    },
    sound: {
      deep: true,
      async handler(_, oldVal) {
        // do not update settings on initial sound object set
        if (Object.keys(oldVal).length)
          await this.$loadingNotify(
            this.updateSettings({ ...this.settings, sound: this.sound }),
            'updatingSetting',
            'Произошла ошибка, при повторении перезагрузите страницу',
            'Настройки уведомления в медкабе успешно изменены',
          );
      },
    },
  },

  created() {
    this.$loading(this.fetchCurrentAccount(), 'fetchCurrentAccount');
  },

  methods: {
    ...mapActions('CABINET', [
      'fetchCurrentAccount',
      'fetchMedicalCategories',
      'fetchSubscriptions',
      'fetchJobs',
      'resetMyPassword',
    ]),
    ...mapActions('AUTH', ['fetchSettings', 'updateSettings']),

    resetPassword() {
      this.$openModal('prompt', {
        yes: 'Сбросить',
        no: 'Отмена',
        title: 'Сбросить пароль',
        description:
          'Вы уверены, что хотите сбросить себе пароль? ' +
          'Вам придет письмо с новым паролем.',
        messages: {
          successfulAction: 'Пароль успешно сброшен',
          errorAction: 'Произошла ошибка сброса пароля',
        },
        onSubmit: () => this.resetMyPassword(),
      });
    },

    playAudio1() {
      new Audio(medicAlert1).play();
    },

    playAudio2() {
      new Audio(medicAlert2).play();
    },
  },
};
</script>
