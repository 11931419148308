<template>
  <ModernModalLayout
    :title="value.key ? 'Редактирование группы' : 'Добавление группы'"
    close-label="Отмена"
  >
    <v-form ref="form" :disabled="isLoading || disabled || $wait('submit')">
      <v-text-field
        v-model="data.key"
        label="Уникальный ключ"
        outlined
        :disabled="Boolean(value.key)"
        :error-messages="getValidationErrors('data.key')"
        @blur="validateField('data.key')"
      />
      <v-text-field
        v-model="data.name"
        label="Название"
        outlined
        :error-messages="getValidationErrors('data.name')"
        @blur="validateField('data.name')"
      />
      <v-text-field
        v-model="data.description"
        label="Описание"
        outlined
        :error-messages="getValidationErrors('data.description')"
        @blur="validateField('data.description')"
      />
    </v-form>

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :disabled="$wait('submit')"
        @click="submit"
      >
        {{ value.key ? 'Сохранить' : 'Добавить' }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import ModernModalLayout from '@/components/layouts/ModernModalLayout';

import validation from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import { required, maxLength } from 'vuelidate/lib/validators';
import { uniqueStringKey } from '@/utils/validators';

export default {
  components: { ModernModalLayout },
  mixins: [validation, waitable],

  props: {
    value: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },

  data: () => ({
    data: {},
  }),

  validations() {
    return {
      data: {
        key: { required, uniqueStringKey },
        name: { required, maxSymbolsLength: maxLength(64) },
        description: { required, maxSymbolsLength: maxLength(512) },
      },
    };
  },

  async created() {
    this.data = { ...this.value };
  },

  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const actionName = this.value.key
        ? 'ADMIN/GROUPS/updateListItem'
        : 'ADMIN/GROUPS/createListItem';

      await this.$loadingNotify(
        this.$store.dispatch(actionName, this.data),
        'submit',
        `Произошла ошибка ${
          this.value.key ? 'изменения' : 'сохранения'
        } группы действий`,
        `Группа действий успешно ${this.value.key ? 'изменена' : 'добавлена'}`,
      )
        .then(() => this.$emit('close'))
        .catch(err => this.catchServerErrors(err, 'data'));
    },
  },
};
</script>
