// contains everything passive & active medcabs need to fetch/read and use
// info about sound (bell)

import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

import alert1 from './assets/medicAlert1.mp3';
import alert2 from './assets/medicAlert2.mp3';

let audio;

export default cabinetType =>
  Vue.extend({
    data: () => ({
      alertInterval: null,
    }),

    computed: {
      ...mapGetters('AUTH', ['accountId', 'settings']),
      ...mapGetters('MEDCAB_PASSIVE', ['playBell', 'isReadList']),
      ...mapGetters('MEDCAB', ['isAwaitingAcceptance']),
    },

    watch: {
      isAwaitingAcceptance(value) {
        this.handleSoundStateChange(value);
      },

      playBell(value) {
        this.handleSoundStateChange(value);
        // for passive medcab:
        // if 'repeat' setting is false - play bell once then set flag to false
        if (value && !this.settings?.sound?.repeat) this.stopBell();
      },

      // 'belling' makes sence only when in list view; otherwise stop it
      isReadList(value) {
        !value && this.stopBell();
      },

      // in case medic decides to change sound settings while using medcab
      'settings.sound.name': {
        handler(name, oldName) {
          if (name !== oldName)
            audio = new Audio(name === 'V1' ? alert1 : alert2);
        },
      },
    },

    async created() {
      // NOTE: i didn't bother figuring out who needs to get alert set correctly
      // for now it is everyone, in case something breaks - move strings below to
      // 'if' statement above
      // Also i am not sure whether to fetch setting on medcab index creation or
      // on user login to speedup medic work startup
      await this.fetchSettings(this.accountId);
      audio = new Audio(this.settings?.sound?.name === 'V1' ? alert1 : alert2);
    },

    beforeDestroy() {
      clearInterval(this.alertInterval);
      this.stopBell();
      try {
        if (audio && audio.stop) audio.stop();
        audio = undefined;
      } catch (e) {
        console.error(e);
      }
    },

    methods: {
      ...mapActions('AUTH', ['fetchSettings']),
      ...mapActions('MEDCAB_PASSIVE', ['stopBell']),

      handleSoundStateChange(value) {
        if (value) {
          if (
            cabinetType === 'passive' &&
            !this.settings?.sound?.passiveMedCabBellEnabled
          ) {
            this.stopBell();
            return;
          }
          this.playMedicAlert();
          if (!this.alertInterval && this.settings?.sound?.repeat)
            this.alertInterval = setInterval(this.playMedicAlert, 1000);
        } else {
          clearInterval(this.alertInterval);
          this.alertInterval = null; // clear the id of old interval
        }
      },

      playMedicAlert() {
        try {
          if (audio) audio.play();
        } catch (e) {
          console.error(e);
        }
      },
    },
  });
