<template>
  <ModernModalLayout
    title="Обслуживающая организация"
    close-label="Отмена"
    :watch-for="data"
  >
    <v-form ref="form" :disabled="disabledForm">
      <v-row>
        <v-col cols="12">
          <v-switch
            v-model="data.isSelfManaged"
            class="my-0"
            label="Обслуживается самостоятельно"
            :disabled="!value.isMedical"
            @change="changeIsSelfManaged"
          />
          <div v-if="!value.isMedical" class="text-sm-body-2">
            Организация без медработников не может обслуживаться самостоятельно
          </div>
        </v-col>

        <v-col v-if="errMessage !== ''" class="error--text">
          <span class="font-weight-bold">Ошибка:</span> {{ errMessage }}
        </v-col>

        <v-col cols="12">
          <OrganizationSelect
            v-model="data.medOrgId"
            medical
            hide-details="auto"
            access-level="full"
            :disabled="data.isSelfManaged"
            :error-messages="getValidationErrors('data.medOrgId')"
            @blur="validateField('data.medOrgId')"
          />
        </v-col>
        <v-col cols="12">
          <CategorySelect
            v-model="data.categoryId"
            hide-details="auto"
            :disabled="!data.medOrgId"
            :med-org-ids="[data.medOrgId]"
            :error-messages="getValidationErrors('data.categoryId')"
            @blur="validateField('data.categoryId')"
          />
        </v-col>
      </v-row>
    </v-form>

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :disabled="disabledForm"
        @click="submit"
      >
        Сохранить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import { requiredIf, helpers } from 'vuelidate/lib/validators';
import validation from '@/utils/validation';

import waitable from '@/utils/mixins/waitable';
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import CategorySelect from '@/components/controls/structures/CategorySelect';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect.vue';
import { mapActions } from 'vuex';

export default {
  components: { ModernModalLayout, CategorySelect, OrganizationSelect },
  mixins: [validation, waitable],

  props: {
    value: { type: Object, default: () => ({}) },
  },

  data: () => ({
    data: {},
    categoriesList: [],
    errMessage: '',
  }),

  validations() {
    const { withParams } = helpers;
    const noSameOrg = curId =>
      withParams({ type: 'sameOrgChosen' }, value =>
        this.data.isSelfManaged ? true : curId !== value,
      );

    return {
      data: {
        medOrgId: {
          required: requiredIf(() => !this.value.isSelfManaged),
          sameOrgChosen: noSameOrg(this.value.id),
        },
        categoryId: { required: requiredIf(() => !this.value.isSelfManaged) },
      },
    };
  },

  computed: {
    disabledForm() {
      return this.$wait('submit');
    },
  },

  watch: {
    'data.medOrgId': {
      handler(_, oldVal) {
        // we do this check to not reset category on component mount cuz latter
        // is setting medOrgId on created cycle
        oldVal && (this.data.categoryId = null);

        this.errMessage = '';
      },
    },
  },

  async created() {
    this.data = {
      isSelfManaged: this.value.medOrg?.id === this.value.id,
      medOrgId: this.value.medOrg?.id,
      categoryId: this.value.category?.id,
    };
  },

  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_CATEGORIES', {
      changeCountOnOrgCategories: 'changeCounter',
    }),
    changeIsSelfManaged(val) {
      if (val) {
        // исключить лишние запросы при переключении checkbox
        if (this.data.medOrgId === this.value.id) return;

        this.data.medOrgId = this.value.id;
        this.data.categoryId = null;
      }
    },

    setErrMessage(err) {
      this.errMessage = err.response.data.message;
    },

    changeCounter() {
      if (this.value.category?.id)
        this.changeCountOnOrgCategories({
          itemIds: [this.value.category.id],
          counterField: 'orgsCount',
          addCount: -1,
        });
      if (this.data.categoryId)
        this.changeCountOnOrgCategories({
          itemIds: [this.data.categoryId],
          counterField: 'orgsCount',
          addCount: 1,
        });
    },

    async submit() {
      if (!this.validate()) return;

      await this.$loadingNotify(
        this.$store
          .dispatch(
            'STRUCTURES/ORGANIZATION_ITEM/updateCategory',
            this.data.categoryId,
          )
          .then(() => {
            this.changeCounter();
            this.$emit('close');
          }),
        'submit',
        // there are huge error messages coming from backend on this request
        // so they don't quite fit with 'notify' logic. So instead we display
        // them in a separate block and suppress notification...
        this.setErrMessage,
        'Категория успешно обновлена',
      ).catch(_ => {}); // ... and console error
    },
  },
};
</script>
