// api
import medrecApi from '@/api/services/medrec';
import authApi from '@/api/services/auth';
import bansApi from '@/api/services/bans';
import structApi from '@/api/services/structures';

import { can } from '@/plugins/can';
import crudSingleFactory, {
  SINGLE_STATUSES,
} from '@/utils/vuex/crudSingleFactory';
import { createModule } from '@/utils/vuex/createModule';

const state = {
  employee: {},
  approvedByUser: {},
  employeeActiveBans: [],
};

const getters = {
  employee: state => state.employee,
  approvedByUser: state => state.approvedByUser,
  employeeActiveBans: state => state.employeeActiveBans,
};

const mutations = {
  singleFetched: (state, { document, employee }) => {
    state.singleItem = document;
    state.employee = employee;
    state.singleStatus = SINGLE_STATUSES[2];
    state.singleError = null;
  },
  employeeActiveBans: (state, value) => (state.employeeActiveBans = value),
  approvedByUser: (state, value) => (state.approvedByUser = value),
};

// TODO: "запиши пожалуйста в наши пожелания к API, чтобы я включил
// typeDefinition в объект документа, когда он запрашивается по ID"
//     (c) Серёга

const actions = {
  fetchSingle: async ({ commit, dispatch }, id) => {
    try {
      commit('singleFetching');
      const document = await medrecApi.documentGet(id);
      const employee = await structApi.getEmployeePreview(document.employeeId);
      commit('singleFetched', { document, employee });

      await dispatch('fetchUserInfo');
    } catch (error) {
      commit('singleErrorFetched', error);
      throw error;
    }
  },
  async fetchEmployeeBans({ commit }, { employeeId, documentType }) {
    const response = await bansApi.getEmployeeBans(employeeId, {
      isActive: true,
      documentTypes: [documentType],
      limit: 1,
      page: 1,
    });

    commit('employeeActiveBans', response?.items);
    return response?.items;
  },

  async approve({ commit, dispatch }, data) {
    try {
      const response = await medrecApi.documentApprove(data);

      // FIXME:right now after doc approve backend returns 'stripped' version
      // of the document, there is no comments array if there are no comments
      // while if we request doc directly by id we get just empty comments array
      // And there is no url for the doc image. After those things get fixed -
      // delete line below and just commit 'response' to 'singleItem'
      const document = await medrecApi.documentGet(response.id);
      commit('singleItem', document);

      await dispatch('fetchUserInfo');

      commit('DOCUMENTS/INCOMING/listUpdateItem', response, { root: true });
    } catch (error) {
      if (error.response?.status === 400) dispatch('fetchSingle', data.id);
      throw error;
    }
  },
  async fetchUserInfo({ getters, commit }) {
    let approvedByUser = null;
    if (can('AC_PRW') && getters.singleItem.resolution) {
      approvedByUser = await authApi
        .getAccountPreviewsBy([getters.singleItem.resolution.approvedBy])
        .then(([account]) => account.profile);

      commit('approvedByUser', approvedByUser);
    }
  },
  async decline({ commit, dispatch }, data) {
    try {
      await medrecApi.documentDecline(data);
      const response = await medrecApi.documentGet(data.id);
      dispatch('fetchSingle', data.id);
      commit('DOCUMENTS/INCOMING/listUpdateItem', response, { root: true });
    } catch (error) {
      if (error.response?.status === 400) dispatch('fetchSingle', data.id);
      throw error;
    }
  },
  async update({ commit }, { id, data }) {
    const response = await medrecApi.documentPatch({ id, data });
    commit('singleItem', response);
  },
};

export default createModule(
  crudSingleFactory({
    fetchMethod: medrecApi.documentGet,
    updateMethod: medrecApi.documentUpdate,
    deleteMethod: medrecApi.documentDelete,
    linkedList: 'DOCUMENTS/INCOMING',
  }),
  { state, getters, mutations, actions },
);
