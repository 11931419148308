<template>
  <div>
    <v-row
      v-for="(item, index) in value.recipients"
      :key="item.accountId || index"
    >
      <v-col cols="6">
        <AccountSelect
          :value="item.accountId"
          title="Получатель"
          :readonly="readonly"
          :clearable="!readonly"
          :error-messages="
            getValidationErrors(
              'value.recipients.$each.' + index + '.accountId',
            )
          "
          @blur="
            validateField('value.recipients.$each.' + index + '.accountId')
          "
          @input="changeRow(index, { ...item, accountId: $event })"
        />
      </v-col>
      <v-col cols="5">
        <Select
          v-model="item.channels"
          label="Тип связи"
          item-value="key"
          item-text="label"
          :items="typesForRecipient(item.accountId)"
          multiple
          hide-details="auto"
          :readonly="readonly"
          :error-messages="
            getValidationErrors('value.recipients.$each.' + index + '.channels')
          "
          @blur="validateField('value.recipients.$each.' + index + '.channels')"
        />
      </v-col>
      <v-col v-if="!readonly" cols="1" class="pt-6">
        <v-btn icon small color="error" @click="removeRow(index)">
          <v-icon size="18">fa-trash</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <div v-if="!readonly" class="mt-4 text-center">
      <v-btn depressed color="primary" @click="addRow">
        <v-icon size="14" class="fa-fw mr-2">fa-plus</v-icon>
        Добавить получателя
      </v-btn>
      <v-btn
        :disabled="hasSelfAsRecipient"
        color="primary"
        class="ml-3"
        outlined
        @click="() => addRow(true)"
      >
        <v-icon size="14" class="fa-fw mr-2">fa-plus</v-icon>
        Добавить себя как получателя
      </v-btn>
    </div>
  </div>
</template>

<script>
import { helpers, required } from 'vuelidate/lib/validators';

import validationMixin from '@/utils/validation';
import AccountSelect from '@/components/controls/structures/AccountSelect';
import Select from '@/components/controls/Select.vue';

import { RECIPIENT_TYPES } from '@/utils/constants';
import { mapGetters } from 'vuex';

export default {
  components: { AccountSelect, Select },
  mixins: [validationMixin],

  validations() {
    const { withParams } = helpers;
    // Если пользователь выбран ровно 1 раз, то валидно
    const uniqueAccountId = chosenIds =>
      withParams(
        { type: 'uniqueAccount' },
        value => chosenIds.filter(id => id === value).length === 1,
      );
    return {
      value: {
        recipients: {
          $each: {
            accountId: {
              required,
              uniqueAccount: uniqueAccountId(
                this.value.recipients.map(recipient => recipient.accountId),
              ),
            },
            channels: { required },
          },
        },
      },
    };
  },

  props: {
    value: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
  },

  data: () => ({}),

  computed: {
    ...mapGetters('AUTH_PREVIEWS', ['cachedAccs']),
    ...mapGetters('AUTH', ['accountId']),

    hasSelfAsRecipient() {
      return (
        this.value &&
        this.value.recipients
          .map(recipient => recipient.accountId)
          .includes(this.accountId)
      );
    },
  },

  methods: {
    addRow(self = false) {
      // NOTE: we always get 'self' as argument, but it might be just mouse
      // event obj so we need to check for its type and value explicitly
      const addSelf = typeof self === 'boolean' && self === true;

      const recipients = [...this.value.recipients];
      if (!this.value.recipients[0]?.accountId && addSelf)
        recipients[0] = { accountId: this.accountId, channels: ['email'] };
      else if (addSelf)
        recipients.push({ accountId: this.accountId, channels: ['email'] });
      else recipients.push({ accountId: null, channels: null });

      this.$emit('input', { ...this.value, recipients });
    },

    changeRow(index, currentRow) {
      this.$emit('input', {
        ...this.value,
        recipients: [
          ...this.value.recipients.slice(0, index),
          { accountId: currentRow.accountId || null, channels: null },
          ...this.value.recipients.slice(index + 1),
        ],
      });
    },

    typesForRecipient(id) {
      const allTypes = [...RECIPIENT_TYPES];
      const typesToInclude = [];
      this.cachedAccs[id]?.email && typesToInclude.push('email');
      this.cachedAccs[id]?.profile.phone && typesToInclude.push('sms');
      this.cachedAccs[id]?.slack && typesToInclude.push('slack');
      this.cachedAccs[id]?.telegram && typesToInclude.push('telegram');
      return allTypes.filter(type => typesToInclude.includes(type.key));
    },

    removeRow(index) {
      this.$emit('input', {
        ...this.value,
        recipients: [
          ...this.value.recipients.slice(0, index),
          ...this.value.recipients.slice(index + 1),
        ],
      });

      this.$nextTick(() => {
        // Всегда оставлять одну строку
        if (!this.value.recipients.length && !index) this.addRow();
      });
    },
  },
};
</script>
