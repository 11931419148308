<template>
  <div>
    <div class="text-h4 my-6">Список отчетов</div>

    <v-row>
      <v-col v-for="report in reports" :key="report.key" cols="6">
        <v-card class="d-flex flex-column" style="height: 100%">
          <v-card-title style="word-break: normal">
            {{ report.name }}
          </v-card-title>
          <v-card-text style="height: 100%">
            <span v-if="report.description">
              {{ report.description }}
              <br />
            </span>
            <span>{{ description(report) }}</span>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mx-auto"
              color="primary"
              text
              @click="changeReport(report.key)"
            >
              Открыть
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    changeReport: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('AVAILABLE_REPORTS', ['reports']),

    description() {
      return report => {
        const result = [];

        if (!report.fields || !report.fields.length)
          result.push('Без фильтров');
        if (report.chart) result.push('Можно построить график');

        if (report.formats) {
          const downloadableFormats = report.formats.filter(
            item => item !== 'json',
          );
          if (downloadableFormats.length)
            result.push('Можно скачать как .' + downloadableFormats.join(' .'));
        }

        return result.join('. ');
      };
    },
  },
};
</script>
