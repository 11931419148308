<template>
  <v-select
    :value="value"
    label="Результат осмотра"
    :items="resolutions"
    outlined
    hide-details="auto"
    :error-messages="errorMessages"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    resolutions() {
      return [
        { value: null, text: 'Любой результат' },
        { value: true, text: 'Допущен' },
        { value: false, text: 'Не допущен' },
      ];
    },
  },
};
</script>
