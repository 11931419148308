<template>
  <v-select
    :value="value"
    label="Часовой пояс"
    :items="ruTimezones"
    outlined
    hide-details="auto"
    @input="$emit('input', $event)"
  />
</template>

<script>
import { RU_TIMEZONES } from '@/utils/constants';

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ruTimezones() {
      return RU_TIMEZONES;
    },
  },
};
</script>
