<template>
  <div class="d-flex">
    <p class="mb-0">
      На ВСЕХ ПАК которые используют данный профиль произойдут изменения
      настроек. Вы уверены?
    </p>
    <v-tooltip right max-width="400px">
      <template #activator="{ on, attrs }">
        <v-btn small text icon v-bind="attrs" v-on="on">
          <v-icon small>far fa-question-circle</v-icon>
        </v-btn>
      </template>
      <span style="width: 200px">
        Если отключить тип или этап осмотра, то в настройках ПАК выбранный тип
        или этап осмотра отключится автоматически.
        <br />
        Если включить тип или этап осмотра, то в настройках ПАК необходимо
        включить этот тип или этап осмотра вручную если профиль ПАК - кастомный.
        Если он дефолтный, то тип или этап осмотра включится автоматически.
      </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'ApproveEditing',
};
</script>
