var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.value[_vm.field]),function(item,index){return _c('v-row',{key:item.parent.id || index},[_c('v-col',{attrs:{"cols":"3"}},[_c('OrgAndOrgGroupSelect',{attrs:{"value":item.parent,"readonly":_vm.readonly,"clearable":!_vm.readonly,"error-messages":_vm.getValidationErrors(
            'value.' + _vm.field + '.$each.' + index + '.parent'
          )},on:{"blur":function($event){return _vm.validateField('value.' + _vm.field + '.$each.' + index + '.parent')},"input":function($event){return _vm.handleChangeInspectionsBinding(index, Object.assign({}, item,
            {parent: $event,
            employeeGroups: null,
            hostGroups: null,
            points: null}))}}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('EmployeeGroupSelect',{attrs:{"id":item.parent.id,"id-type":item.parent.type,"multiple":"","chips":"","small-chips":"","deletable-chips":"","readonly":_vm.readonly,"clearable":!_vm.readonly,"disabled":!item.parent.id,"value":item.employeeGroups},on:{"input":function($event){return _vm.handleChangeInspectionsBinding(index, Object.assign({}, item,
            {employeeGroups: $event}))}}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('HostGroupAndPointSelect',{attrs:{"id":item.parent.id,"type":item.parent.type,"readonly":_vm.readonly,"clearable":!_vm.readonly,"multiple":"","chips":"","small-chips":"","deletable-chips":"","disabled":!item.parent.id,"value":{
          points: item.points,
          hostGroups: item.hostGroups,
        }},on:{"input":function($event){return _vm.handleChangeInspectionsBinding(index, Object.assign({}, item, $event))}}})],1),(!_vm.readonly)?_c('v-col',{staticClass:"pt-6",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.handleRemoveInspectionsBinding(index)}}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("fa-trash")])],1)],1):_vm._e()],1)}),(!_vm.readonly)?_c('div',{staticClass:"mt-4 text-center"},[_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.handleAddInspectionsBinding}},[_vm._v(" + "+_vm._s(_vm.addButtonText || 'Добавить')+" ")])],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }