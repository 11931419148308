<template>
  <AsyncWrapper :handler="fetchActualAnswers">
    <Spinner v-if="$wait('fetchActualAnswers')" title="Загрузка опросов" />
    <div v-else>
      <div v-if="actualAnswers && actualAnswers.length">
        <ActualAnswers
          class="my-4"
          :answers="actualAnswers"
          :organisation-id="organisationId"
        />
        <v-expansion-panels class="shadow--hide" accordion multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="font-weight-bold">История опросов</span>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <div style="max-height: 300px; overflow-y: auto">
                <SurveyItem
                  v-for="survey in surveysNoDrugs"
                  :key="survey.surveyId"
                  class="mb-4"
                  :date="survey.finishedAt"
                  :answers="orderHistoryAnswers(survey.answers)"
                />
                <div>
                  <Spinner
                    v-if="$wait('fetchEmployeeSurveys')"
                    title="Загрузка истории"
                    :size="30"
                  />
                  <div
                    v-if="!allItemsLoaded"
                    v-intersect="fetchNextSurveys"
                    class="mt-16"
                    style="height: 1px"
                  />
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <NoDataAlert v-else-if="!surveyEnabled" class="ma-4">
        Не подключены для организации
      </NoDataAlert>
      <NoDataAlert v-else class="ma-4"> Пока нет ни одного опроса </NoDataAlert>
    </div>
  </AsyncWrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AsyncWrapper from '@/components/AsyncWrapper';
import ActualAnswers from './ActualAnswers';
import SurveyItem from './SurveyItem';
import Spinner from '@/components/Spinner';
import waitable from '@/utils/mixins/waitable';
import NoDataAlert from '@/components/ui/NoDataAlert';

export default {
  name: 'SurveyBlock',

  components: {
    AsyncWrapper,
    ActualAnswers,
    Spinner,
    SurveyItem,
    NoDataAlert,
  },

  mixins: [waitable],

  props: {
    employeeId: { type: Number, required: true },
    organisationId: { type: Number, required: true },
  },

  computed: {
    ...mapGetters('EMPLOYEE', [
      'actualAnswers',
      'surveySettings',
      'surveys',
      'surveysNoDrugs',
      'surveysTotal',
    ]),

    allItemsLoaded() {
      return this.surveys.length === this.surveysTotal;
    },

    // NOTE: crutch - https://jira.medpoint24.ru/browse/SW-9021
    surveyEnabled() {
      return Object.entries(this.surveySettings)
        .filter(([key, _]) => key !== 'illegal_drugs')
        .some(([_, value]) => value.isEnabled);
    },
  },

  methods: {
    ...mapActions('EMPLOYEE', [
      'fetchEmployeeActualAnswers',
      'fetchEmployeeSurveys',
    ]),

    async fetchActualAnswers() {
      if (this.$wait('fetchActualAnswers')) return;
      await this.$loading(
        this.fetchEmployeeActualAnswers(),
        'fetchActualAnswers',
      );
    },

    async fetchNextSurveys() {
      if (this.allItemsLoaded || this.$wait('fetchEmployeeSurveys')) return;

      const params = {
        orderBy: 'finishedAt',
        orderType: 'DESC',
        refererId: this.employeeId,
        limit: 5,
      };

      await this.$loading(
        this.fetchEmployeeSurveys(params),
        'fetchEmployeeSurveys',
      );
      this.nextPage++;
    },

    // Sorts answers using the order of actual answers.
    // Any missing answers from actual answers are just appended to the result.
    orderHistoryAnswers(answers) {
      const result = this.actualAnswers
        .flatMap(answer => {
          const match = answers.find(a => a.key === answer.key);
          if (match) return match;
          else return answer;
        })
        .concat(
          answers.filter(
            answer => !this.actualAnswers.some(a => a.key === answer.key),
          ),
        );
      return result;
    },
  },
};
</script>
