import ratingsApi from '@/api/services/ratings';

export default {
  state: {
    rating: null,
  },
  getters: {
    rating: state => state.rating,
  },
  mutations: {
    rating: (state, value) => (state.rating = value),
  },
  actions: {
    // Rating logic
    async getRating({ commit }, id) {
      const { value } = await ratingsApi.getRating(id);
      commit('rating', value);
      return value;
    },
    // put not as 'put', but rather 'recalculate' rating of employee
    // XXX: to be deleted, just temporary functionality
    async putRating({ commit }, id) {
      const { value } = await ratingsApi.putRating(id);
      commit('rating', value);
      return value;
    },
    async deleteRating({ dispatch }, id) {
      const { value } = await ratingsApi.deleteRating(id);
      dispatch('getRating', id);
      return value;
    },
  },
};
