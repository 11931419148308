import medrecApi from '@/api/services/medrec';
import structApi from '@/api/services/structures';

import crudListFactory from '@/utils/vuex/crudListFactory';
import { createModule } from '@/utils/vuex/createModule';
import { filters } from '../entity';
import { changePages } from '@/utils/vuex/changePages';

const state = {
  employees: {},
};

const getters = {
  employees: state => state.employees,
};

const mutations = {
  employees: (state, value) => {
    value.forEach(employee => (state.employees[employee.id] = employee.name));
  },
};

const actions = {
  async fetchList({ commit, getters }, query) {
    await changePages(getters, commit, query);

    try {
      if (getters.listSearch?.length > 100)
        throw Error('Не более 100 символов в поле поиска');

      commit('listFetching');

      const params = { ...getters.listQuery };
      // Удаляем текстовую запись из запроса.
      // Передаются только IDs найденных работников
      delete params.employeeSearch;

      const response = await medrecApi.documentOutgoingList(params);

      // fetch employee previews to show employee names in list
      const missingIds = response.items
        .filter(item => !getters.employees[item.employeeId])
        .map(item => item.employeeId);
      const employees = missingIds.length
        ? await structApi.getEmployeePreviewsBy(missingIds)
        : [];
      commit('employees', employees);

      commit('listFetched', response);
    } catch (error) {
      commit('listErrorFetched', error);
      throw error;
    }
  },

  async setEmployeeSearch({ commit }, value) {
    commit('listQuery', { employeeSearch: value });
  },
};

export default createModule(
  crudListFactory({
    fetchMethod: medrecApi.documentOutgoingList,
    filters,
  }),
  { state, getters, mutations, actions },
);
