import store from '@/store';
import { can } from '@/plugins/can';

type canFunc = (a: string) => boolean;

interface Route {
  action: string | ((a: canFunc) => boolean);
  children: Route;
}

/** Проверить доступна ли пользователю для просмотра страница */
export function canRouteTo(item: Route): boolean {
  const { action, children } = item;

  // Если есть вложенные страницы, то проверяем есть ли хоть одно
  // разрешение на отображение, тогда выводим родительский элемент в меню
  if (Array.isArray(children)) return children.some(child => canRouteTo(child));

  if (typeof action === 'function') return !!action(can);
  // Если нет дочерних элементов и action отсутствует,
  // то пункт меню отображается всегда
  else if (!action) return true;
  return can(action);
}

interface RouteParams {
  name?: string;
  priority?: number;
}

/** Собрать список подменю по совпадениям в маршруте */
export function submenuForRoute(
  route: RouteParams,
  parentArray = store.getters.menu,
) {
  const { children } =
    parentArray.find((module: RouteParams) => module.name === route.name) || {};

  return (children || [])
    .filter(canRouteTo)
    .sort(
      (a: RouteParams, b: RouteParams) =>
        (a.priority || Infinity) - (b.priority || Infinity),
    );
}
