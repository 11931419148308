<template>
  <Card title="Интеграция с НИИАТ" dense>
    <v-simple-table dense>
      <tbody>
        <tr>
          <td class="grey--text px-4 py-3">
            Отправка осмотров в НИИАТ
            <v-tooltip right max-width="400px">
              <template #activator="{ on, attrs }">
                <v-btn small text icon v-bind="attrs" v-on="on">
                  <v-icon small>far fa-question-circle</v-icon>
                </v-btn>
              </template>
              <span style="width: 200px">
                Для отправки осмотров в НИИАТ медицинская организация должна
                быть зарегистрирована в НИИАТ
              </span>
            </v-tooltip>
          </td>
          <td class="px-4 py-3" style="width: 50px">
            <v-switch
              :input-value="niiatEnabled"
              class="ma-0 pa-0"
              inset
              :disabled="disabled"
              readonly
              hide-details
              :loading="$wait('changingStatus')"
              @mousedown="handlerChange"
            />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </Card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import Card from '@/components/ui/Card';

export default {
  name: 'NiiatIntegration',
  components: { Card },
  mixins: [waitable],
  computed: {
    ...mapGetters('STRUCTURES/ORGANIZATION_ITEM', [
      'singleItem',
      'niiatEnabled',
    ]),
    medOrgNiiatRegistered() {
      return this.singleItem.medOrg?.niiatStatus?.registered || false;
    },
    disabled() {
      return (
        !this.medOrgNiiatRegistered ||
        (this.niiatEnabled && !this.$can('NTD_ORG')) ||
        (!this.niiatEnabled && !this.$can('NTE_ORG'))
      );
    },
  },
  methods: {
    ...mapActions('STRUCTURES/ORGANIZATION_ITEM', ['toggleNiiat']),

    handlerChange() {
      if (this.disabled) {
        return;
      }
      const newStatus = !this.niiatEnabled;
      this.$openModal('prompt', {
        yes: 'Да',
        no: 'Отмена',
        title: 'Подтверждение',
        description: `Вы действительно хотите ${
          newStatus ? 'включить' : 'отключить'
        } отправку осмотров в НИИАТ?`,
        messages: {
          successfulAction: `Отправка осмотров в НИИАТ успешно ${
            newStatus ? 'включена' : 'отключена'
          }`,
          errorAction: `Ошибка ${
            newStatus ? 'включения' : 'отключения'
          } отправка осмотров в НИИАТ`,
        },
        onSubmit: () =>
          this.$loading(this.toggleNiiat(newStatus), 'changingStatus'),
      });
    },
  },
};
</script>
