import { initStoreInstance } from '@/utils/tests';
import accountsStore from './list';

const ns = 'ADMIN/USERS';
let store;

beforeEach(() => {
  store = initStoreInstance(accountsStore, true, ns);
});

describe('store: accounts', () => {
  describe('getters', () => {
    it('returns whole role obj if no field is provided', () => {
      const roles = [
        { id: 0, role: 'zero' },
        { id: 1, role: 'one' },
      ];
      store.commit(`${ns}/roles`, roles);
      expect(store.getters[`${ns}/role`](0)).toStrictEqual(roles[0]);
    });

    it("returns role obj field if it's provided", () => {
      const roles = [
        { id: 0, role: 'zero' },
        { id: 1, role: 'one' },
      ];
      store.commit(`${ns}/roles`, roles);
      expect(store.getters[`${ns}/role`](1, 'role')).toBe('one');
    });
  });
});
