<template>
  <v-radio-group
    :value="value"
    :label="label"
    row
    hide-details="auto"
    class="mt-0 pt-0"
    @change="$emit('input', $event)"
  >
    <v-radio
      v-for="option in options"
      :key="option.value"
      :label="option.name"
      :value="option.value"
    />
  </v-radio-group>
</template>

<script>
export default {
  props: {
    value: {
      type: [String],
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
