<template>
  <v-expansion-panels accordion dense style="height: 500px">
    <AsyncScroller
      full-width
      :fetcher="fetchFunction"
      no-data-message="Нет данных"
    >
      <template #item="{ item }">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div>
              <span class="text--secondary">
                {{ dateTime(item.date) }}
              </span>
              <span class="px-2">|</span>
              <span class="operation_row">
                {{ item.operation }}
              </span>
              <span v-if="item.actor && !hideActor">
                <span class="px-2">|</span>
                <span v-if="item.actor.type === 'USER'">
                  Пользователь
                  <LinkButton
                    :action="$can('ACC_R')"
                    :to="{
                      name: 'admin:users:item',
                      params: { id: item.actor.accountId },
                    }"
                  >
                    {{ item.actor.name }}
                  </LinkButton>
                </span>
                <span v-else-if="item.actor.type === 'APP'">
                  Приложение
                  <LinkButton
                    :action="$can('APP_RD')"
                    :to="{
                      name: 'admin:applications:item',
                      params: { id: item.actor.accountId },
                    }"
                  >
                    {{ item.actor.name }}
                  </LinkButton>
                </span>
                <span v-else-if="item.actor.type === 'CRON'">
                  По расписанию (из сервиса {{ item.actor.name }})
                </span>
                <span v-else-if="item.actor.type === 'TERMINAL'">
                  С терминала
                </span>
              </span>
            </div>
            <v-spacer />
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table dense>
              <tbody>
                <tr v-for="(change, dataIndex) in item.data" :key="dataIndex">
                  <td class="grey--text" style="width: 30%">
                    {{ change.label }}
                  </td>
                  <td>
                    <template v-if="change.oldValue">
                      <span class="grey--text text-decoration-line-through">
                        {{ change.oldValue }}
                      </span>
                    </template>
                    <span v-if="change.oldValue !== undefined" class="px-2">
                      ➔
                    </span>
                    <template v-if="change.type === 'url'">
                      <a target="_blank" :href="change.value">{{
                        change.value
                      }}</a>
                    </template>
                    <template v-else-if="change.type === 'image'">
                      <a target="_blank" :href="change.value">
                        <img :src="change.value" width="150" />
                      </a>
                    </template>
                    <template v-else-if="change.type === 'json'">
                      <json-viewer
                        :value="getJson(change.value)"
                        theme="jv-theme"
                        expanded
                        :expand-depth="0"
                        sort
                        :show-array-index="false"
                      />
                    </template>
                    <template v-else>
                      {{ change.value }}
                    </template>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
    </AsyncScroller>
  </v-expansion-panels>
</template>

<script>
import { dateTime } from '@/utils/convert';
import LinkButton from '@/components/controls/buttons/LinkButton.vue';
import AsyncScroller from '@/components/AsyncScroller';
import JsonViewer from '@/plugins/json-viewer';

export default {
  components: { LinkButton, AsyncScroller, JsonViewer },
  props: {
    hideActor: Boolean,
    fetchFunction: { type: Function, required: true },
  },
  methods: {
    dateTime,
    getJson(stringValue) {
      try {
        return JSON.parse(stringValue);
      } catch (err) {
        return {};
      }
    },
  },
};
</script>

<style lang="scss">
.operation_row {
  width: 280px;
  display: inline-block;
  vertical-align: middle;
}
</style>
