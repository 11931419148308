import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import GROUPS from './list.store';

export default {
  namespaced: true,
  store: {
    GROUPS,
  },
  modals: [
    {
      name: 'groupForm',
      component: () => import('@/modules/admin/groups/modals/Form.vue'),
    },
  ],
  routes: [
    {
      path: 'groups',
      name: 'groups',
      component: () => import('./list.view.vue'),
      meta: {
        action: 'GRP_R',
      },
    },
  ],
  menu: [
    {
      title: 'Группы',
      action: 'GRP_R',
      name: 'groups',
      priority: 4,
    },
  ],
} as ModuleDefinition;
