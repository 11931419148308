<template>
  <v-form ref="form" class="pt-0" :disabled="isLoading">
    <v-row>
      <v-col cols="12">
        <v-switch
          :input-value="value.pushMethod"
          class="ma-0"
          hide-details
          label="Push-метод"
          :disabled="!$can('WBS_S')"
          @change="$emit('input', { ...value, pushMethod: $event })"
        />
      </v-col>

      <v-col v-if="value.pushMethod" cols="6">
        <v-text-field
          :value="value.t2Callback"
          label="URL для отправки Т2"
          outlined
          hide-details="auto"
          :error-messages="getValidationErrors('value.t2Callback')"
          @blur="validateField('value.t2Callback')"
          @input="$emit('input', { ...value, t2Callback: $event })"
        />
      </v-col>

      <v-col v-if="value.pushMethod" cols="6">
        <v-text-field
          :value="value.t6Callback"
          label="URL для отправки Т6"
          outlined
          hide-details="auto"
          :error-messages="getValidationErrors('value.t6Callback')"
          @blur="validateField('value.t6Callback')"
          @input="$emit('input', { ...value, t6Callback: $event })"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {
  maxLength,
  minLength,
  requiredIf,
  url,
} from 'vuelidate/lib/validators';

import validation from '@/utils/validation';

export default {
  mixins: [validation],
  props: {
    value: { type: Object, required: true },
    isWaybillOperator: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },

  validations() {
    const callbackValidation = {
      required: requiredIf(() => this.value.pushMethod),
      minSymbolsLength: minLength(5),
      maxSymbolsLength: maxLength(2048),
      url,
    };

    return {
      value: {
        t2Callback: callbackValidation,
        t6Callback: callbackValidation,
      },
    };
  },
};
</script>
