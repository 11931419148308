import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { isXhrError, xhrErrorMessage } from '@/utils/helpers';

export default function resourceListFactory({
  storeModule,
  filters = {},
  resourceName = 'ресурсов',
  options = {
    autoWatch: true,
  },
}) {
  const queryFields = [
    'limit',
    'page',
    'orderBy',
    'orderType',
    'search',
    ...Object.keys(filters),
  ];

  return Vue.extend({
    computed: {
      ...mapGetters(storeModule, [
        'listIsLoading',
        'listItems',
        'listItem',
        'listTotal',
        'listTotalPages',
        'listShowPagination',
        'listSearch',
        'listExportLoading',
      ]),

      ...mapState(storeModule, ['listQuery']),

      // from the array below we generate an object containing computed
      // props with getters and setters
      ...[
        'listCurrentPage',
        'listLimit',
        'listOrderBy',
        'listOrderType',
        ...Object.keys(filters),
      ].reduce((acc, name) => {
        // 'name' - as name of filter / getter / mutation
        acc[name] = {
          get() {
            // each list has it's own getters and mutation, which is the
            // reason for such field access
            return this.$store._modulesNamespaceMap[storeModule + '/'].context
              .getters[name];
          },
          set(...args) {
            // do i really need to explain why we use apply below?
            return this.$store._modulesNamespaceMap[
              storeModule + '/'
            ].context.commit.apply(this.$store, [name].concat(args));
          },
        };
        return acc;
      }, {}),
    },

    async created() {
      const filtersArrayKeys = Object.keys(filters).reduce(
        (agg, key) => (filters[key].type === Array ? [...agg, key] : agg),
        [],
      );
      const query = decodeQueryObject(
        this.$route.query,
        queryFields,
        filtersArrayKeys,
      );

      await this.fetchList(query);

      this.$watch('listQuery', (newValue, oldValue) => {
        const newQuery = filterQueryObject(newValue, queryFields);
        const oldQuery = filterQueryObject(oldValue, queryFields);

        if (isEqual(newQuery, oldQuery)) return;

        // В случае с autoWatch:true обновление списка выполняется
        // при каждом изменении фильтров
        if (options.autoWatch) this.fetchList(newQuery);
        // В противном случае, нужно подсветить кнопку для применения фильтров
        else this.needApplyFilters = true;
      });
    },

    methods: {
      async fetchList(query) {
        try {
          await this.$store.dispatch(storeModule + '/fetchList', query);

          // NOTE: for a mystical reasons code *works* when we first fetch list
          // and only then update route, otherwise filters don't get set when
          // first loading page, so don't swap these 2 lines ↕

          // Перед каждым запросом обновляем query параметры в адресной строке
          await this.updateRoute(query);

          // Сбрасываем подсветку кнопки
          this.needApplyFilters = false;
        } catch (err) {
          this.$notify({
            group: 'note',
            type: 'error',
            title: `Произошла ошибка при загрузке ${resourceName}`,
            text: isXhrError(err) ? xhrErrorMessage(err) : err.message,
          });
        }
      },

      changePagination(page) {
        this.listCurrentPage = page;

        // При autoWatch:true запрос выполнится при изменении listQuery
        // (в состав которого входит listCurrentPage)
        if (options.autoWatch) return;

        // В ручном режиме - сами выполняем запрос
        this.$nextTick(() => {
          const newQuery = filterQueryObject(this.listQuery, queryFields);
          this.fetchList(newQuery);
        });
      },

      async updateRoute(query) {
        const allQueries = { ...this.$route.query, ...query };
        const filteredQueries = Object.keys(allQueries).reduce(
          (agg = {}, key) => {
            // Фильтруем при наличии дефолтного значения
            if (allQueries[key] === filters[key]?.default) return agg;

            // Убираем пустые массивы
            if (
              filters[key]?.type === Array &&
              (!allQueries[key] || !allQueries[key]?.length)
            )
              return agg;

            // Убираем пустые строки и null
            if (
              filters[key]?.type === String &&
              !filters[key]?.default &&
              (allQueries[key] === null || allQueries[key] === '')
            )
              return agg;

            // Убираем фиксированные параметры из query
            if (/limit|orderBy|orderType/.test(key)) return agg;

            agg[key] = allQueries[key];
            return agg;
          },
          {},
        );

        return this.$router
          .push({ name: this.$route.name, query: filteredQueries })
          .catch(() => {});
      },

      querySearchList: debounce.call(
        this,
        function (value) {
          this.$store.dispatch(storeModule + '/querySearchList', value);
        },
        1000,
      ),
    },
  });
}

function filterQueryObject(query, queryFields) {
  return Object.fromEntries(
    Object.entries(query).filter(item => queryFields.includes(item[0])),
  );
}

function decodeQueryObject(query, queryFields, filtersArrayKeys) {
  query = Object.entries(query)
    .map(([key, value]) => {
      if (!queryFields.includes(key)) return;

      if (value === 'true') value = true;
      else if (value === 'false') value = false;
      else if (Array.isArray(value))
        value = value.map(item => (isNumeric(item) ? +item : item));
      else if (value && filtersArrayKeys.includes(key)) {
        value = [isNumeric(value) ? +value : value];
      } else if (isNumeric(value)) value = +value;
      return [key, value];
    })
    .filter(item => item);

  return query.length !== 0 ? Object.fromEntries(query) : null;
}

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}
